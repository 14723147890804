/**
 * Returns black or white based on contrast.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contrastColor',
})
export class ContrastColor implements PipeTransform {
  constructor() {}

  public transform(color: string | null, args?: any): string | null {
    if (!color) {
      return null;
    }

    if (color.length === 4) {
      color = color + color.slice(1);
    }

    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? '#000' : '#fff';
  }
}

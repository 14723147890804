/**
 * Calculation instructions used for analysis.
 *
 * @unstable
 */

export class Calculations {
  static readonly NOTINCLUDEDCONSENSUS: string[] = [
    'time',
    'hashtags',
    'shareLink',
    'language',
    'outcome',
    'userSegments',
  ];

  static consensus(key: string) {
    return (
      key &&
      Calculations.NOTINCLUDEDCONSENSUS.indexOf(key) < 0 &&
      key.slice(0, 9) !== 'contacts-' &&
      key.slice(0, 7) !== 'fields-'
    );
  }

  static gridSizeFromHeightCeil(height: number) {
    return Math.ceil((height + 24) / 80);
  }

  static gridSizeFromHeightRound(height: number) {
    return Math.round((height + 24) / 80);
  }

  static gridHeightUnit(units: number) {
    return units ? 56 + ((units - 1) * 24) / units : 0;
  }
}

/**
 * Common module for the account menu.
 *
 * @stable
 */

import { NgModule } from '@angular/core';
import { InviteItem } from '@home/shared/dialogs/switch-team/inivte-item/invite-item.component';
import { SwitchTeam } from '@home/shared/dialogs/switch-team/switch-team.dialog';
import { TeamItem } from '@home/shared/dialogs/switch-team/team-item/team-item.component';
import { BillingModule } from '@home/shared/modules/billing.module';
import { LocalesModule } from '@shared/modules/locales.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  bootstrap: [],
  declarations: [InviteItem, TeamItem, SwitchTeam],
  exports: [SwitchTeam, SharedModule],
  imports: [SharedModule, LocalesModule, BillingModule],
  providers: [],
})
export class AccountModule {}

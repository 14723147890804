import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { NgxsModule } from '@ngxs/store';

import { QuillModule } from 'ngx-quill-wrapper';

import { BasicModule } from '@shared/modules/basic.module';
import { LindaModule } from '@shared/modules/linda.module';
import { SidenavModule } from '@shared/modules/sidenav/sidenav.module';

import { HelpIcon } from '@shared/modules/help-center/components/help-icon/help-icon.component';
import { HelpLink } from '@shared/modules/help-center/components/help-link/help-link.component';
import { HelpCenterState } from '@shared/modules/help-center/state/help-center.state';
import { HelpCenterSidenav } from '@shared/modules/help-center/components/help-sidenav/help-sidenav.component';
import { HelpArticleComponent } from '@shared/modules/help-center/components/help-article/help-article.component';
import {
  HelpTooltipDirective,
  HelpTooltipComponent,
} from '@shared/modules/help-center/components/help-tooltip/help-tooltip.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatButtonToggleModule,

    NgxsModule.forFeature([HelpCenterState]),

    QuillModule,

    BasicModule,
    LindaModule,
    SidenavModule,
  ],
  declarations: [
    HelpIcon,
    HelpLink,
    HelpTooltipDirective,
    HelpCenterSidenav,
    HelpTooltipComponent,
    HelpArticleComponent,
  ],
  exports: [HelpIcon, HelpLink, HelpCenterSidenav],
})
export class HelpCenterModule {}

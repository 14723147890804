<!--
  Switch dialog with team switching.

  @unstable
-->

<zef-dialog
  #zd
  [type]="'ask'"
  [logo]="identity?.avatar || 'assets/images/user-placeholder.png'"
  [logoType]="'round'"
  [title]="dialogTitle"
  [label]="identity?.name || identity?.email"
  [hideBorder]="true"
  [noActionPadding]="true"
>
  <div
    dialog-content
    *ngIf="{
      teams: (filteredTeams | async),
      invites: (filteredInvites | async),
      loaded: (dataLoaded$ | async)
    } as asyncData"
    class="z-fx-col z-fx-gap-16 z-fx-auto"
  >
    <ng-template #spinner>
      <zef-spinner size="large"></zef-spinner>
    </ng-template>

    <div *ngIf="asyncData.loaded; else spinner" class="z-fx-col z-fx-w100">
      <div *ngIf="asyncData.invites?.length > 0" class="invites-box z-fx-col z-fx-gap-24 fx-none">
        <div class="z-fx z-fx-gap-8">
          <mat-icon color="alert">bell</mat-icon>
          <span class="zef-text-p1" i18n>
            You have {{ asyncData.invites?.length }} new {asyncData.invites?.length, plural, one {invite} other
            {invites}}
          </span>
        </div>
        <div class="teams-grid">
          <ng-container
            *ngFor="let invite of asyncData.invites.slice(0, showAllInvites ? asyncData.invites.length : 4)"
          >
            <invite-item
              [invite]="invite"
              (accepted)="updateAccepted($event)"
              (rejected)="updateRejected($event)"
            ></invite-item>
          </ng-container>
        </div>

        <div *ngIf="!showAllInvites && asyncData.invites?.length > 4" class="z-fx-col z-fx-around-center">
          <button mat-raised-button color="secondary" class="zef-action-button" (click)="showAllInvites = true" i18n>
            Show All
          </button>
        </div>
      </div>

      <div *ngIf="asyncData.teams" class="teams-box z-fx-col z-fx-gap-24 z-fx-center-center">
        <zef-searchbox [autofocus]="true" [value]="null" (input)="updateSearch($event)">
          <span i18n>Search...</span>
        </zef-searchbox>
        <div class="teams-grid">
          <ng-container *ngFor="let userTeam of asyncData.teams.slice(0, showAllTeams ? asyncData.teams.length : 4)">
            <team-item *ngIf="userTeam" [team]="userTeam"></team-item>
          </ng-container>
        </div>

        <button
          *ngIf="!showAllTeams && asyncData.teams?.length > 4"
          mat-raised-button
          class="zef-action-button"
          color="secondary"
          (click)="showAllTeams = true"
          i18n
        >
          Show All
        </button>
      </div>
    </div>
  </div>
</zef-dialog>

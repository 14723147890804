import { shareReplay, map, takeUntil, filter } from 'rxjs/operators';
import { Subject, fromEvent, Observable } from 'rxjs';

import { Injectable, OnDestroy } from '@angular/core';

import { AnswersManager } from '@player/shared/services/answers-manager.service';
import {
  PlayerEventType,
  PlayerEventData,
  PlayerAnswer,
  PlayerAnswerEventData,
  PlayerRestartEventData,
  PlayerCompleteEventData,
  PlayerStartEventData,
  PlayerOutcomeEventData,
  PlayerOutcome,
  PlayerAnswers,
  PlayerOutcomeData,
  PlayerAnswerData,
} from '@player/shared/models/player.model';
import { Questions } from '@shared/enums/questions.enum';
import { QuestionData } from '@shared/models/survey.model';

interface IncomingMessageData {
  open?: true;
  restart?: true;
}

function incomingMessage(
  source: Observable<IncomingMessageData>,
  message: keyof IncomingMessageData,
): Observable<true> {
  return source.pipe(
    map((messages) => messages[message]),
    filter((value) => value),
  );
}

@Injectable()
export class CommunicationManager implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly message$: Observable<IncomingMessageData> = fromEvent(window, 'message').pipe(
    map((event: MessageEvent) => event.data as IncomingMessageData),
    takeUntil(this.destroy$),
    shareReplay(1),
  );

  readonly open$ = incomingMessage(this.message$, 'open');

  readonly restart$ = incomingMessage(this.message$, 'restart');

  constructor(private am: AnswersManager) {
    this.message$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  emitAnswerEvent(answer: PlayerAnswer): void {
    this.sendMessage(PlayerEventType.Answer, {
      answer: answer.value,
      question: answer.item,
      progress: answer.progress || 0,
      answerType: Questions.AnswerTypes[answer.item.type],
    });
  }

  emitRestartEvent(): void {
    this.sendMessage(PlayerEventType.Restart, {});
  }

  emitCompleteEvent(
    progress: number,
    questions: QuestionData[],
    answers: PlayerAnswers,
    outcomes: PlayerOutcome[],
  ): void {
    this.sendMessage(PlayerEventType.Complete, {
      progress,
      outcomes: this.parseOutcomes(outcomes),
      answers: this.parseQuestionsToAnswers(questions, answers),
    });
  }

  emitStartEvent(): void {
    this.sendMessage(PlayerEventType.Start, {});
  }

  emitOutcomeEvent(outcomes: PlayerOutcome[]): void {
    this.sendMessage(PlayerEventType.Outcome, {
      outcomes: this.parseOutcomes(outcomes),
    });
  }

  private prepareEventData(): PlayerEventData {
    return {
      survey: this.am.getSurveyKey(),
      linkKey: this.am.getLinkKey(),
      answererId: this.am.getCurrentAnswererId()?.toString(),
      timestamp: Date.now(),
    };
  }

  private parseOutcomes(outcomes: PlayerOutcome[]): PlayerOutcomeData[] {
    return outcomes.map((outcome) => ({
      score: outcome.score || 0,
      outcome: outcome.item,
    }));
  }

  private parseQuestionsToAnswers(questions: QuestionData[], answers: PlayerAnswers): PlayerAnswerData[] {
    return questions.map((question) => ({
      answer: answers[question.$key] ?? null,
      question,
      answerType: Questions.AnswerTypes[question.type],
    }));
  }

  private sendMessage(event: PlayerEventType.Answer, data: Partial<PlayerAnswerEventData>): void;
  private sendMessage(event: PlayerEventType.Restart, data: Partial<PlayerRestartEventData>): void;
  private sendMessage(event: PlayerEventType.Complete, data: Partial<PlayerCompleteEventData>): void;
  private sendMessage(event: PlayerEventType.Start, data: Partial<PlayerStartEventData>): void;
  private sendMessage(event: PlayerEventType.Outcome, data: Partial<PlayerOutcomeEventData>): void;

  private sendMessage<T extends PlayerEventData>(type: PlayerEventType, data: Partial<T>): void {
    data = {
      ...data,
      ...this.prepareEventData(),
    };

    if (window.ZEF?.origin) {
      window.parent.postMessage({ type, data }, window.ZEF.origin);
    }
  }
}

/**
 * Manages emails sending and listing.
 *
 * @unstable
 */

import firebase from 'firebase/compat/app';

import { combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Email } from '@shared/enums/email.enum';

import { EmailData, EmailDesign, EmailStatus, MessageKind } from '@shared/models/email.model';

import { AccountState } from '@shared/states/account.state';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { Commands } from '@shared/enums/commands.enum';
import { SearchApi } from '@shared/services/search-api.service';
import { EmailRecord, EmailSearchParams, SearchResult } from '@shared/models/search.model';
import { firebaseSafe } from '@shared/utilities/object.utilities';

@Injectable({
  providedIn: 'root',
})
export class EmailsManager {
  constructor(
    private store: Store,
    private cf: CloudFunctions,
    private db: DatabaseWrapper,
    private ns: SnackbarService,
    private sa: SearchApi,
  ) {}

  newEmail(data: Partial<EmailData>): string | null {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const newEmail = {
      anonymous: data.anonymous || false,

      name: data.name || '',
      type: data.type || '',

      parent: data.parent || null,

      status: Email.NEW,

      creator: data.creator || null,

      sender: userKey,

      survey: data.survey || null,

      created: firebase.database.ServerValue.TIMESTAMP as number,
      sending: null,

      subject: data.subject || '',
      preview: data.preview || null,

      // bodyText: data.bodyText || '',
      bodyHtml: data.bodyHtml || '',

      template: data.template || null,

      design: new EmailDesign(),

      recipients: data.recipients || [],
      recipientsLists: data.recipientsLists || [],
      recipientAddresses: data.recipientAddresses || null,
      language: data.language || null,
    } as Partial<EmailData>;

    return this.db.list<EmailData>(`/emails/${teamKey}/`).push(newEmail as EmailData).key as string;
  }

  loadEmail(emailKey: string): Observable<EmailData> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<EmailData>(`/emails/${teamKey}/${emailKey}`)
      .valueChanges()
      .pipe(
        map((email: EmailData | null) => email && ({ $key: emailKey, ...email, kind: 'email' } as EmailData)),
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  listEmails(params: EmailSearchParams, withStatus: boolean = true): Observable<SearchResult<EmailRecord>> {
    return this.sa.searchEmails(params).pipe(
      switchMap((response) =>
        response && response.result.length && withStatus
          ? merge(
              ...response.result.map((email) =>
                combineLatest(
                  ['sending', 'tracking', 'answering'].map((type) => this.getEmailStatus(email.key, type)),
                ).pipe(
                  map(([sending, tracking, answering]) => {
                    email = { ...email };

                    if (sending) {
                      return {
                        ...email,
                        status: sending.active ? 'sending' : 'sent',
                        recipients: sending.count,
                        delivered: sending.success,
                      };
                    }

                    if (tracking) {
                      return {
                        ...email,
                        recipients: tracking.count,
                        opened: tracking.processed,
                        clicked: tracking.success,
                        created: tracking.time / 1000,
                      };
                    }

                    if (answering) {
                      return {
                        ...email,
                        answered: answering.processed,
                        completed: answering.success,
                      };
                    }

                    return email;
                  }),
                ),
              ),
            ).pipe(map(() => response))
          : of(response),
      ),
    );
  }

  loadTemplates(language: string): Observable<Partial<EmailData>[]> {
    // TODO: Remove this when "feature-V9CvV8Wa" is merged
    const lang = language.replace('us', 'en');
    return this.db
      .list<Partial<EmailData>>(`templates/emails/${lang}`)
      .snapshotChanges()
      .pipe(
        map((snapshot) =>
          snapshot
            .map(({ key, payload }) => ({
              $key: key,
              ...payload.val(),
              kind: 'email' as MessageKind,
            }))
            .filter((email) => !!email),
        ),
      );
  }

  loadTemplate(language: string, id: string) {
    return this.db
      .object<Partial<EmailData>>(`templates/emails/${language}/${id}`)
      .valueChanges()
      .pipe(map((email) => ({ ...email, kind: 'email' as MessageKind })));
  }

  updateEmail(emailKey: string | EmailData, newData: Partial<EmailData>): Observable<void> {
    return (typeof emailKey === 'string' ? this.loadEmail(emailKey) : of(emailKey)).pipe(
      take(1),
      switchMap((email) => {
        if (!newData.status) {
          newData.status = 'draft';
        }

        const teamKey = this.store.selectSnapshot(AccountState.teamKey);

        return this.db.object<Partial<EmailData>>(`/emails/${teamKey}/${email.$key}/`).update(firebaseSafe(newData));
      }),
    );
  }

  getEmailStatus(emailKey: string, state: string): Observable<EmailStatus | null> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const url: string = `/statuses/emails/${state}/${teamKey}/${emailKey}`;

    return this.db
      .object<EmailStatus>(url)
      .valueChanges()
      .pipe(
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  getShareLinkUrl(emailKey: string): Observable<string | null> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const url: string = `/emails/${teamKey}/${emailKey}/shareLink/url`;

    return this.db
      .object<string>(url)
      .valueChanges()
      .pipe(
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  deleteEmail(teamKey: string, emailKey: string, deleteAnswers: boolean) {
    const params = `${teamKey}/${emailKey}?deleteAnswers=${deleteAnswers || 'false'}`;

    return this.cf.delete(Commands.DeleteEmail, params).pipe(
      catchError((error) => of(null)),
      tap((response: { deletedCount: number }) => {
        if (deleteAnswers && response && 'deletedCount' in response) {
          const title = $localize`:snackbar notification@@zef-i18n-00035:Successfully deleted ${response.deletedCount}:INTERPOLATION: answers.`;
          this.ns.open(title, { color: 'alert' });
        } else if (deleteAnswers && response === null) {
          const title = $localize`:snackbar notification@@zef-i18n-00036:No answers were deleted, please contact support.`;
          this.ns.open(title, { color: 'alert' });
        }
      }),
    );
  }
}

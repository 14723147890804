/**
 * Outcome card component with header and content support.
 *
 * @unstable
 */

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { InfoMediaCtaData } from '@shared/models/survey.model';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'media-cta',
  templateUrl: './media-cta.component.html',
  styleUrls: ['./media-cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaCtaComponent implements OnDestroy {
  @Input()
  data: InfoMediaCtaData;

  @Output()
  readonly newAnswerer = new EventEmitter<void>();

  readonly imageHeight = new ReplaySubject<number>(1);

  readonly imageWidth = new ReplaySubject<number>(1);

  private ro = new ResizeObserver(([{ contentRect }]: ResizeObserverEntry[]) => this.setImageSize(contentRect));

  constructor(readonly ss: SurveyStore, readonly el: ElementRef<HTMLElement>, readonly lm: LanguageManager) {
    this.ro.observe(this.el.nativeElement);
    this.setImageSize();
  }

  ngOnDestroy(): void {
    this.ro.disconnect();
  }

  showCtaButton(ctaText: string) {
    return Boolean(ctaText && (this.data.cta.action || this.data.cta.url));
  }

  backgroundImageStyles() {
    const styles = this.data?.image?.getStyles() || {};

    delete styles['background-color'];

    return styles;
  }

  openCtaLink(actionUrl: string) {
    if (actionUrl) {
      if (actionUrl === 'restart') {
        this.newAnswerer.emit();
      } else {
        const safeUrl = actionUrl.startsWith('http') || actionUrl.startsWith('//') ? actionUrl : '//' + actionUrl;

        window?.open(safeUrl, '_blank');
      }
    }
  }

  private setImageSize(contentRect?: DOMRect): void {
    const target = this.el.nativeElement.closest('.z-perfect-scroll');
    contentRect ||= (target || this.el.nativeElement).getBoundingClientRect();
    this.imageWidth.next(contentRect.width);

    if (target instanceof HTMLElement) {
      this.imageHeight.next(target.offsetHeight / 3);
    } else {
      this.imageHeight.next(window.innerHeight / 3);
    }
  }
}

import { Component, Directive, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import {
  LegacyTooltipComponent as TooltipComponent,
  MatLegacyTooltip as MatTooltip,
} from '@angular/material/legacy-tooltip';

@Component({
  template: `
    <div #tooltip class="mat-tooltip" [ngClass]="ngClass">
      <div class="tooltip-content">
        <ng-container *ngIf="template; else showMessage">
          <ng-container *ngTemplateOutlet="template"></ng-container>
        </ng-container>
        <ng-template #showMessage>{{ message }}</ng-template>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ZefTooltipComponent extends TooltipComponent {
  @Input()
  template?: TemplateRef<any>;

  @Input()
  ngClass?: string;
}

@Directive({
  selector: '[zefTooltip]',
  exportAs: 'zefTooltip',
})
export class ZefTooltipDirective extends MatTooltip {
  private templateRef: TemplateRef<any>;
  private ngClass = '';
  protected readonly _tooltipComponent = ZefTooltipComponent;

  @Input()
  set zefTooltip(t: TemplateRef<any> | string) {
    if (t instanceof TemplateRef) {
      this.templateRef = t;
      this.message = '-';
    } else {
      this.message = t;
    }
  }

  @Input()
  set zefTooltipClass(ngClass: string) {
    this.ngClass = ngClass;
  }

  show(delay?: number): void {
    super.show(delay);

    if (this._tooltipInstance) {
      const instance = this._tooltipInstance as ZefTooltipComponent;
      instance.template = this.templateRef;
      instance.ngClass = this.ngClass;
    }
  }
}

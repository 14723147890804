import { environment } from '@env/environment';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface DetectLanguage {
  language: string;
}

interface DetectLanguageResponse {
  data: { detections: DetectLanguage[][] };
}

@Injectable({
  providedIn: 'root',
})
export class TranslateApi {
  constructor(private http: HttpClient) {}

  detectLanguage(text: string[]): Observable<string> {
    return this.http
      .post<DetectLanguageResponse>('https://www.googleapis.com/language/translate/v2/detect/', null, {
        params: {
          key: environment.googleBrowserKey,
          q: text,
        },
      })
      .pipe(
        map(
          ({ data }: DetectLanguageResponse) =>
            Object.entries(
              data.detections
                .reduce((a, b) => a.concat(b), [])
                .filter((detection) => detection.language !== 'und')
                .reduce(
                  (a, b) => ({
                    ...a,
                    [b.language]: (a[b.language] || 0) + 1,
                  }),
                  {} as Record<string, number>,
                ),
            ).sort(([_, count1], [__, count2]) => count2 - count1)[0]?.[0],
        ),
        catchError((_) => of(void 0)),
      );
  }
}

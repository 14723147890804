import { PlanId } from '@shared/models/plan.model';

export enum LicenseFeature {
  DataExporting,
  ChatSupport,
  Integrations,
  InteractiveReports,
  LiveReports,
  ReportMerging,
  ReportSharing,
  SurveyCtaButton,
  WebPopup,
  Whitelabel,
  Workflows,
  SenderAddress,
}

/* eslint-disable @typescript-eslint/no-namespace */
export namespace LicenseFeature {
  type FeatureMapping = Record<LicenseFeature, PlanId[]>;

  const LicenseMap: Record<string, PlanId[]> = {
    Free: ['free_plan', 'parked_plan'],
    Trial: ['trial_plan'],
    Smart: ['plan_smart', 'plan_basic'],
    Genius: ['plan_genius', 'plan_pro'],
    Master: ['plan_master', 'plan_enterprise'],
    Einstein: ['plan_einstein'],
  };

  const FreeOnly = LicenseMap.Free;
  const FromEinStein = LicenseMap.Einstein;
  const FromMaster = LicenseMap.Master.concat(FromEinStein).concat(LicenseMap.Trial);
  const FromGenius = LicenseMap.Genius.concat(FromMaster);
  const FromSmart = LicenseMap.Smart.concat(FromGenius);

  const FeatureInPlans: FeatureMapping = {
    [LicenseFeature.ChatSupport]: FromSmart,
    [LicenseFeature.DataExporting]: FromSmart,
    [LicenseFeature.Integrations]: FromGenius,
    [LicenseFeature.InteractiveReports]: FromSmart,
    [LicenseFeature.LiveReports]: FromGenius,
    [LicenseFeature.ReportSharing]: FromGenius,
    [LicenseFeature.ReportMerging]: FromGenius,
    [LicenseFeature.SurveyCtaButton]: FromSmart,
    [LicenseFeature.WebPopup]: FromGenius,
    [LicenseFeature.Whitelabel]: FromMaster,
    [LicenseFeature.Workflows]: FromGenius,
    [LicenseFeature.SenderAddress]: FromGenius,
  };

  export function planHasFeature(plan: PlanId, feature: LicenseFeature): boolean {
    return FeatureInPlans[feature].includes(plan);
  }
}

import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { MultiSelect } from '@shared/components/multi-select/multi-select.component';
import { MultiSelectItem } from '@shared/components/multi-select/multi-select-item.component';
import { MultiSelectOption } from '@shared/components/multi-select/multi-select-option.component';
import { MultiSelectCategory } from '@shared/components/multi-select/multi-select-category.component';
import { MultiSelectLabel } from '@shared/components/multi-select/multi-select-label.directive';
import { MultiSelectOptions } from '@shared/components/multi-select/multi-select-options.directive';
import { MultiSelectOptionLabel } from '@shared/components/multi-select/multi-select-option-label.directive';
import { SpinnerModule } from '@shared/components/spinner/spinner.module';
import { BasicModule } from '@shared/modules/basic.module';

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    OverlayModule,
    NgScrollbarModule,

    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatButtonModule,
    MatProgressBarModule,

    BasicModule,
    SpinnerModule,
  ],
  declarations: [
    MultiSelect,
    MultiSelectItem,
    MultiSelectLabel,
    MultiSelectOption,
    MultiSelectOptionLabel,
    MultiSelectOptions,
    MultiSelectCategory,
  ],
  exports: [
    MultiSelect,
    MultiSelectLabel,
    MultiSelectOption,
    MultiSelectOptionLabel,
    MultiSelectOptions,
    MultiSelectCategory,
  ],
})
export class MultiSelectModule {}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import {
  templateAuthorIntroLink,
  templateAuthorVideoLink,
  TemplateModel,
  templatePlayerLink,
  templateReportLink,
  ViewState,
} from '@shared/models/survey.model';
import { SurveyView } from '@player/+survey/survey.component';
import { PlayerState, PlayerAnswers } from '@player/shared/models/player.model';
import { Select } from '@ngxs/store';
import { AccountState } from '@shared/states/account.state';
import { Observable, BehaviorSubject } from 'rxjs';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { takeUntil, filter, map, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { FontPickerService } from 'ngx-font-picker';
import { FontStyles } from '@shared/models/styles.model';
import { PlayerApi } from '@player/shared/services/player-api.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { PlayerStore } from '@player/shared/services/player-store.service';
import { PropertyStore } from '@shared/services/property-store.service';

type ViewMode = 'intro' | 'preview' | 'report';

@Component({
  selector: 'zef-template-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  providers: [LifecycleHooks, LanguageManager, PlayerStore, { provide: PropertyStore, useExisting: PlayerStore }],
})
export class TemplatePreview implements AfterViewInit, OnChanges, OnInit {
  public playerAnswers: PlayerAnswers = {};
  public playerState: PlayerState = {} as any;

  @Select(AccountState.teamKey)
  readonly teamKey$!: Observable<string>;

  @Input() template: TemplateModel;
  @Input() viewMode: ViewMode = 'intro';
  @Input() isMobile: boolean = false;
  @Output() select = new EventEmitter();

  @ViewChild(SurveyView) player: SurveyView;

  private fontStyles: BehaviorSubject<FontStyles | null> = new BehaviorSubject(null);

  constructor(private lh: LifecycleHooks, private fp: FontPickerService, private pa: PlayerApi) {}

  ngOnInit(): void {
    this.fontStyles
      .pipe(
        filter((fs) => !!fs),
        distinctUntilChanged((a, b) => a.url === b.url),
        map((fs) => this.fp.loadFont({ ...fs })),
        takeUntil(this.lh.destroy),
      )
      .subscribe();

    this.pa.uploadTrigger$
      .pipe(
        mergeMap((key) => this.pa.uploadFile({ question: key }, true)),
        takeUntil(this.lh.destroy),
      )
      .subscribe();

    this.pa.deleteTrigger$
      .pipe(
        mergeMap((key) => this.pa.deleteUpload({ question: key }, true)),
        takeUntil(this.lh.destroy),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    if (this.player) {
      this.player.init(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.template) {
      this.playerAnswers = {};

      if (this.template.survey) {
        const view = this.template.survey.welcome ? ViewState.Welcome : ViewState.Questions;
        this.playerState = { view };
      }

      if (this.template.design && this.template.design.font) {
        this.fontStyles.next(this.template.design.font);
      }
    }
  }

  reportUrl(template: TemplateModel) {
    return templateReportLink(template);
  }

  surveyUrl(template: TemplateModel) {
    return templatePlayerLink(template);
  }

  introContent(template: TemplateModel) {
    return templateAuthorIntroLink(template);
  }

  videoUrl(template: TemplateModel) {
    return templateAuthorVideoLink(template);
  }
}

/**
 * Lang changed guard.
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PrefsState } from '@shared/states/prefs.state';

import { GetCountry, GetLanguage } from '@shared/states/prefs.actions';

@Injectable()
export class LangChanged implements CanActivate {
  constructor(@Inject(LOCALE_ID) readonly locale: string, readonly store: Store) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const lang = this.locale.split('-')[0];

    return this.store.dispatch([new GetCountry(), new GetLanguage()]).pipe(
      map(() => {
        const newLang = this.store.selectSnapshot(PrefsState.language);

        if (newLang && lang !== newLang && !environment.website && window.location.href.indexOf('localhost') === -1) {
          console.log('Reloading due to language change:', lang, newLang);
          window.location.assign(state.url);

          return false;
        } else {
          return true;
        }
      }),
    );
  }
}

import { HttpParams } from '@angular/common/http';

import { VideoEmbedInfo } from '@shared/models/video-embed.model';
import { pickBy } from './object.utilities';

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function emailToName(email: string) {
  const name = email.split('@')[0].split('.')[0];
  return capitalize(name);
}

export function queryParamsString(params: any) {
  const nonEmptyParams = pickBy(params, (value) => !!value);

  return Object.keys(nonEmptyParams).length > 0
    ? '?' +
        Object.entries(nonEmptyParams)
          .map(([k, v]) => `${k}=${v}`)
          .join('&')
    : '';
}

export function encodeState<T>(state: T): string | null {
  try {
    return btoa(JSON.stringify(state));
  } catch {
    return null;
  }
}

export function decodeState<T>(state: string): T | null {
  try {
    return JSON.parse(atob(state)) as T;
  } catch {
    return null;
  }
}

export function searchQueryMatch(target: string, search: string): boolean {
  return searchQueryData(target, search).length > 0;
}

export function searchQueryData(target: string, search: string): { part: string; idx: number }[] {
  if (!search || !target) {
    return [];
  }

  search = search.toLocaleLowerCase();
  target = target.toLocaleLowerCase();

  const searches = search.split(' ').filter((part) => !!part);

  const matchData = searches
    .map((part) => {
      const indices: number[] = [];
      let i = -1;

      while ((i = target.indexOf(part, i + 1)) !== -1) {
        indices.push(i);
      }

      return indices;
    })
    .filter((matches) => !!matches.length);

  if (searches.length > matchData.length) {
    return [];
  }

  const matchIndices = matchData.reduce((matches, match, idx) => {
    const prevMatches = matches.slice(0, idx);

    const matchedIdx = prevMatches.length
      ? match.findIndex((matchIdx) =>
          prevMatches.every(
            (prevMatch, prevIdx) =>
              matchIdx + searches[idx].length <= prevMatch || matchIdx >= prevMatch + searches[prevIdx].length,
          ),
        )
      : 0;

    if (matchedIdx > -1) {
      matches.push(match[matchedIdx]);
    }

    return matches;
  }, []);

  if (searches.length > matchIndices.length) {
    return [];
  }

  return matchIndices.map((idx, i) => ({ part: searches[i], idx })).sort((a, b) => a.idx - b.idx);
}

export function isImageFile(file: File | string): boolean {
  file = file instanceof File ? file.name : file;

  const parts = file.split('.');

  return (
    parts.length > 1 && ['png', 'jpeg', 'jpg', 'gif', 'svg', 'webp', 'bmp', 'tiff'].includes(parts.pop().toLowerCase())
  );
}

export function getYouTubeVideoInfo(url: string): VideoEmbedInfo | null {
  const idRegExp = new RegExp('^[A-Za-z\\d-_]{11}$');

  if (idRegExp.test(url)) {
    return { videoId: url, provider: 'youtube' };
  }

  const regExp = new RegExp('(?:\\/|\\bv=\\b)([A-Za-z\\d-_]{11}\\b)');

  const matches = url.match(regExp);

  const videoId = matches?.[1];

  if (videoId) {
    const params = new URLSearchParams(url.split('?')[1]);
    const startTime = params.get('t') || params.get('start');
    const endTime = params.get('end') || params.get('stop');

    return {
      videoId,
      startTime: parseInt(startTime, 10) || 0,
      endTime: parseInt(endTime, 10) || 0,
      provider: 'youtube',
    };
  }

  return null;
}

export function getYouTubeEmbedUrl(info: VideoEmbedInfo): string {
  const url = `https://www.youtube.com/embed/${info.videoId}`;

  let params = new HttpParams();

  if (info.startTime) {
    params = params.set('start', info.startTime.toString());
  }

  if (info.endTime) {
    params = params.set('end', info.endTime.toString());
  }

  const query = params.toString();

  return url + (query ? `?${query}` : '');
}

export function addProtocolToUrl(url: string, protocol: 'http' | 'https' = 'https'): string {
  if (!url || url.startsWith('http:') || url.startsWith('https:')) {
    return url;
  }

  return `${protocol}:${url.startsWith('//') ? '' : '//'}${url}`;
}

export function trimStart(str: string, char: string = 's'): string {
  return str.replace(new RegExp(`^${char}*`), '');
}

export function randomString(length = 16): string {
  let result = '';

  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
}

export function ellipsis(str: string, maxLength: number, ellipsisChar = '…'): string {
  if (!str || str.length <= maxLength) {
    return str;
  }

  return `${str.substr(0, 99)}${ellipsisChar}`;
}

export function sanitizeHtml(str: string) {
  if (document) {
    const el = document.createElement('div');
    el.innerHTML = str;

    return el.innerText;
  } else {
    return str;
  }
}

export function assertString(target: unknown): string {
  return target?.toString() || '';
}

export function toLines(str: string): string[] {
  const lines = (str || '')
    .split(/\r\n|\r|\n/g)
    .map((c) => c.trim())
    .filter((c) => !!c);

  return lines;
}

export function isMultiline(str: string): boolean {
  return toLines(str).length > 1;
}

export function getStringValuesFromObject(obj: object, result?: string[]): string[] {
  result ||= [];

  if (obj != null) {
    if (typeof obj === 'string') {
      result.push(obj);
    } else if (Array.isArray(obj)) {
      const arrayResults = obj.map((item) => getStringValuesFromObject(item)).reduce((a, b) => a.concat(b));
      result.push(...arrayResults);
    } else if (typeof obj === 'object') {
      const objResults = Object.values(obj)
        .map((value) => getStringValuesFromObject(value))
        .reduce((a, b) => a.concat(b));
      result.push(...objResults);
    }
  }

  return result;
}

/**
 * Team card with invitation support.
 *
 * @unstable
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FadeInOut } from '@shared/animations/fade-in-out.anim';
import { TeamData } from '@shared/models/account.model';
import { SwitchTeam } from '@shared/states/account.actions';
import { AccountState } from '@shared/states/account.state';
import { teamLogoPlaceholder } from '@shared/utilities/assets.utilities';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'team-item',
  templateUrl: './team-item.component.html',
  styleUrls: ['./team-item.component.scss'],
  animations: [FadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamItem {
  public hovering: boolean = false;

  @Select(AccountState.teamKey)
  readonly teamKey$!: Observable<string>;
  readonly isActiveTeam$: Observable<boolean> = this.teamKey$.pipe(
    first(),
    map((teamKey) => this.team?.$key === teamKey),
  );

  @Input() team: TeamData | null = null;

  constructor(private store: Store) {}

  teamLogo(data: TeamData) {
    return data?.logo || teamLogoPlaceholder(+data?.created);
  }

  public switchTeam(): void {
    this.store.dispatch(new SwitchTeam(this.team.$key));
  }
}

import * as crossfilter from 'crossfilter';

import { environment } from '@env/environment';

import { AsyncSubject, BehaviorSubject, combineLatest, Observable, of, Subject, timer } from 'rxjs';

import { debounce, debounceTime, first, map, takeUntil, takeWhile } from 'rxjs/operators';

import { Select, Store } from '@ngxs/store';

import { Injectable } from '@angular/core';

import { SurveysManager } from '@shared/services/surveys-manager.service';
import { ReportManager } from '@shared/services/report-manager.service';

import { DataGenerator } from '@report/shared/services/data-generator.service';
import { DataPreparation } from '@report/shared/services/data-preparation.service';
import { FilterConverter } from '@report/shared/services/filter-converter.service';

import { BillingState } from '@shared/states/billing.state';

import { LicenseFeature } from '@shared/enums/license-feature.enum';

import {
  OutcomeData,
  QuestionData,
  ReleaseData,
  ShareLinkData,
  SliderLabelsData,
  SliderValuesData,
  Survey,
  SurveyData,
} from '@shared/models/survey.model';

import { LanguagesData, LocalesData, TranslationData, TranslationsData } from '@shared/models/locale.model';

import {
  ComparisonDimension,
  CrossfilterData,
  DimensionData,
  DimensionDataItem,
  FilterData,
  GridItem,
  GridItemData,
  GridItemDataItem,
  LocaleString,
  LocaleStrings,
  PreparedAnswersData,
  RespondentFields,
  StoredReport,
  TextData,
} from '@shared/models/report.model';
import { isField, isContactEntry } from '@report/shared/utilities/report.utilities';

import { Questions } from '@shared/enums/questions.enum';
import { Charts } from '@shared/enums/charts.enum';
import { Calculations } from '@report/shared/enums/calculations.enum';
import { Emotions } from '@report/shared/enums/emotions.enum';
import { TriggerFeedbackSurvey } from '@shared/modules/feedback/feedback.actions';
import { FeedbackSurvey } from '@shared/modules/feedback/feedback.models';
import { ContactEntryData } from '@shared/models/contact.model';
import { AccountState } from '@shared/states/account.state';

/**
 * This is crossfilter service which handles filters used in report and passed forward data used in charts & sheets.
 */
@Injectable({
  providedIn: 'root',
})
export class Crossfilter {
  public questions: { [survey: string]: QuestionData[] } = {};
  private outcomes: { [survey: string]: OutcomeData[] } = {};
  private reportGrid: GridItem[] = [];
  private survey: Survey | null = null;
  private locales: { [survey: string]: LocalesData } = {};
  private release: { [survey: string]: ReleaseData } = {};
  private respondentFields: { [survey: string]: RespondentFields[] } = {};

  private surveySub: Observable<
    [
      LocalesData,
      ReleaseData,
      QuestionData[],
      OutcomeData[],
      SurveyData,
      { [s: string]: any } | null,
      RespondentFields[],
    ]
  >;

  private answersRequestParameters: any;

  private stopTaking: Observable<boolean> | null;
  private resetEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public key: string = '';
  public isSavedReport: boolean = false;
  public isSharedReport: boolean = false;
  public connectedReportKey: string = '';

  private includedSurveys: SurveyData[] = [];

  private anonymityTreshold: number = null;
  private freezeTextTables: boolean = false;
  private safeReport: boolean = false;

  private answerData: PreparedAnswersData = {} as PreparedAnswersData;

  private base: CrossFilter.CrossFilter<any> = {} as CrossFilter.CrossFilter<any>;
  private baseDimension: CrossFilter.Dimension<any, any> = {} as CrossFilter.Dimension<any, any>;
  private baseGroup: CrossFilter.GroupAll<any, any> = {} as CrossFilter.GroupAll<any, any>;

  private baseDistributions: CrossFilter.Grouping<any, any>[] | null = null;

  private consensus: number | null = null;

  public dimensions: DimensionData = {};

  public filters: { [key: string]: any } = {};
  public cropFilters: any[] = [];

  private mergedSurveysKeys: string[] = [];
  private mergedSurveys: Survey[] = [];
  private mergedSurveyContents: Observable<{ [s: string]: any }>;
  private surveyMerged: Subject<boolean> = new Subject<boolean>();
  private mergeSettings: { [s: string]: any } = {};

  private customDimensions: any = {};

  public gridItems: GridItemData = {};

  public crossfilter = new BehaviorSubject<CrossfilterData | null>(null);
  public newChartCard = new BehaviorSubject<GridItemData | null>(null);
  public removeChartCard = new BehaviorSubject<GridItemData | null>(null);

  public dataChange: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public loading: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(false);

  public liveStatus: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(false);

  public newAnswersAvailable: boolean = false;

  private timePeriod: string = '';

  private lastIdGenerationTime: number = 0;

  private set comparisonModeOn(comparisonModeOn: boolean) {
    if (comparisonModeOn && this._comparisonModeOn !== comparisonModeOn) {
      this.st.dispatch(new TriggerFeedbackSurvey(FeedbackSurvey.ReportSurvey, 60));
    }

    this._comparisonModeOn = comparisonModeOn;
  }

  private get comparisonModeOn(): boolean {
    return this._comparisonModeOn;
  }

  private _comparisonModeOn: boolean = false;

  private comparisonDimension: ComparisonDimension = {} as ComparisonDimension;

  private trendAnalysisOn: boolean = false;

  private memoizedDistributions: any;

  private latestAnswerTime: number = 0;
  private liveBouncer: number = 0;

  private latestAnswerObservable: Observable<any> | null = null;
  private latestAnswerObservableSub: any;

  private currentReportSub: any;

  private updatingAnswers: AsyncSubject<boolean> = new AsyncSubject<boolean>();

  private updateNeeded: boolean = false;

  private updateToggledData: Subject<string> = new Subject<string>();
  private updateToggledDataSub: any;

  private publicSettings: { explorable: boolean; live: boolean } | null = null;

  private activeLocale: string = '';

  private filtersDemo: boolean = false;

  private getAnswersRequestMade: boolean = false;

  public latestTrackSurvey: string = '';
  public latestTrackTime: number = 0;

  private cachedTextSummaries: {
    [request: string]: { summary: string; translations: { language: string; summary: string }[] };
  } = {};

  @Select(BillingState.featureActive(LicenseFeature.LiveReports))
  readonly liveReportOn!: Observable<boolean>;

  constructor(
    private dg: DataGenerator,
    private dp: DataPreparation,
    private fc: FilterConverter,
    private rm: ReportManager,
    private sm: SurveysManager,
    private st: Store,
  ) {}

  /* INITIALIZATION & MAIN FUNCTIONS  */

  /**
   * Initializes new crossfilter using only the survey key.
   *
   * @param key    Survey key.
   */
  public initialize(key: string) {
    this.key = key;
    this.connectedReportKey = '';
    this.isSavedReport = false;
    this.survey = this.sm.connectSurvey(key);
    this.liveBouncer = 0;
    this.initLatestAnswerObservable(key);
    this.initUpdateToggledDataSubject();
    this.initMergedSurveysObservable();

    this.stopTaking = new Observable((observer) => {
      this.resetEvent.subscribe((reset) => {
        if (reset) {
          observer.next(true);
          observer.complete();
        }
      });
    });

    let previousMergeSettings: string = JSON.stringify(this.mergeSettings || {});

    this.surveySub = combineLatest([
      this.survey.locales,
      this.survey.release,
      this.survey.questions,
      this.survey.outcomes,
      this.survey.survey,
      this.mergedSurveyContents,
      this.rm.getRespondentFields(key),
    ]);

    this.surveySub
      .pipe(debounceTime(20), takeUntil(this.stopTaking))
      .subscribe(([locales, release, questions, outcomes, survey, mergedSurveys, respondentFields]) => {
        this.anonymityTreshold = survey?.anonymous || (this.safeReport && this.isSharedReport ? 5 : null);
        this.includedSurveys = [];
        this.includedSurveys.push(survey);
        this.locales[this.key] = locales;
        this.release[this.key] = release;
        this.questions[this.key] = (questions || []).map((question) => new QuestionData(question));
        this.outcomes[this.key] = (outcomes || []).map((outcome) => new OutcomeData(outcome));
        this.respondentFields[this.key] = respondentFields;

        for (let s = 0, lens = (mergedSurveys || []).length; s < lens; s++) {
          const surveyKey: string = mergedSurveys[s][4]['$key'];
          this.questions[surveyKey] = (mergedSurveys[s][0] || []).map((question) => new QuestionData(question));
          this.outcomes[surveyKey] = (mergedSurveys[s][1] || [])
            .map((outcome) => new OutcomeData(outcome))
            .filter(
              (outcome) =>
                this.mergeSettings &&
                this.mergeSettings[surveyKey] &&
                this.mergeSettings[surveyKey]['outcomes'] &&
                this.mergeSettings[surveyKey]['outcomes']['outcomes'] &&
                this.mergeSettings[surveyKey]['outcomes']['outcomes'].find(
                  (o) => o.orig && o.orig['$key'] === outcome['$key'],
                ) &&
                this.mergeSettings[surveyKey]['outcomes']['outcomes'].find(
                  (o) => o.orig && o.orig['$key'] === outcome['$key'],
                )['mappedTo'] &&
                this.mergeSettings[surveyKey]['outcomes']['outcomes'].find(
                  (o) => o.orig && o.orig['$key'] === outcome['$key'],
                )['mappedTo']['manualMerge'],
            );

          this.locales[surveyKey] = mergedSurveys[s][2];
          this.release[surveyKey] = mergedSurveys[s][3];
          this.includedSurveys.push(mergedSurveys[s][4]);
          this.respondentFields[surveyKey] = mergedSurveys[s][5];

          if (mergedSurveys[s][4]?.['anonymous'] > this.anonymityTreshold) {
            this.anonymityTreshold = mergedSurveys[s][4]?.['anonymous'];
          }
        }

        if (!this.activeLocale && survey.language) {
          this.activeLocale = survey.language;
        }
        this.setDimensions();

        let changesInMergedSurveys: boolean = previousMergeSettings !== JSON.stringify(this.mergeSettings);
        previousMergeSettings = JSON.stringify(this.mergeSettings);

        if (this.latestAnswerObservableSub) {
          this.latestAnswerObservableSub.unsubscribe();
        }

        if (changesInMergedSurveys) {
          this.liveBouncer = 0;
          this.initLatestAnswerObservable(key, this.mergedSurveysKeys);
        }

        this.latestAnswerObservableSub = this.latestAnswerObservable
          .pipe(takeUntil(this.stopTaking))
          .subscribe((observable) => {
            if (this.currentReportSub) {
              this.currentReportSub.unsubscribe();
            }
            this.currentReportSub = observable.subscribe((latestAnswerTime) => {
              if (this.newAnswersAvailable || (this.latestAnswerTime == null && latestAnswerTime != null)) {
                this.newAnswersAvailable = true;
                this.sendNewCrossfilter();
              } else {
                this.dataChange.next(
                  `Updating ${this.latestAnswerTime !== latestAnswerTime ? 'new answers' : ''}${
                    this.latestAnswerTime !== latestAnswerTime && changesInMergedSurveys ? ' and' : ''
                  }${changesInMergedSurveys ? ' other data changes' : ''}`,
                );
                this.getNewAnswers(
                  key,
                  null,
                  changesInMergedSurveys
                    ? null
                    : this.answerData && this.answerData.newestRecord
                    ? this.answerData.newestRecord
                    : null,
                  changesInMergedSurveys,
                  latestAnswerTime,
                );
              }
              this.latestAnswerTime = latestAnswerTime || null;
              changesInMergedSurveys = false;
            });
          });
      });
  }

  /**
   * Initializes observable that returns proper subscription to the latestAnswer status on Firebase.
   *
   * @param key    Survey key.
   */
  private initLatestAnswerObservable(surveyKey: string, mergedSurveysKeys: string[] = []): void {
    this.latestAnswerObservable = new Observable<any>((obs) => {
      combineLatest(this.liveReportOn, this.survey.release)
        .pipe(takeUntil(this.stopTaking))
        .subscribe(([liveReport, release]) => {
          if (liveReport) {
            if (
              release.online &&
              !(this.cropFilters && this.cropFilters.length > 0 && this.cropFilters.find((item) => item.id === 'time'))
            ) {
              this.liveStatus.next(true);
            } else {
              this.liveStatus.next(false);
            }
            obs.next(
              this.rm.getLatestAnswerTime(surveyKey, mergedSurveysKeys).pipe(
                debounce(
                  (val) =>
                    new Observable<any>((observer) => {
                      if (this.liveBouncer === 0) {
                        this.liveBouncer += 1;
                        observer.next('load');
                        observer.complete();
                      } else {
                        timer(15000)
                          .pipe(first())
                          .subscribe((time) => {
                            observer.next('load');
                            observer.complete();
                          });
                      }
                    }),
                ),
                map((times) => Math.max(...times)),
              ),
            );
          } else {
            this.liveStatus.next(false);
            obs.next(
              this.rm.getLatestAnswerTime(surveyKey).pipe(
                first(),
                map((times) => Math.max(...times)),
              ),
            );
          }
        });
    });
  }

  private initUpdateToggledDataSubject(): void {
    this.updateToggledDataSub = this.updateToggledData.pipe(debounceTime(2000)).subscribe(() => {
      this.gridItems = this.calculate(this.gridItems);
      this.sendNewCrossfilter();
    });
  }

  private initMergedSurveysObservable(): void {
    this.mergedSurveyContents = new Observable<
      [QuestionData[], LocalesData, ReleaseData, SurveyData, RespondentFields] | null
    >((observer) => {
      let subscription;
      let obs;
      observer.next(null);
      this.surveyMerged.subscribe(() => {
        if (subscription) {
          subscription.unsubscribe();
        }

        if (this.mergedSurveys && this.mergedSurveys.length > 0) {
          obs = combineLatest(
            this.mergedSurveys.map((item) =>
              combineLatest([
                item.questions,
                item.outcomes,
                item.locales,
                item.release,
                item.survey,
                this.rm.getRespondentFields(item.key),
              ]),
            ),
          ).pipe(takeUntil(this.stopTaking), debounceTime(300));

          subscription = obs.subscribe((result) => {
            observer.next(result);
          });
        } else {
          observer.next(null);
        }
      });
      // firing merge for saved reports
      if (this.mergedSurveysKeys && this.mergedSurveysKeys.length) {
        this.surveyMerged.next(true);
      }
    });
  }

  public changeSampleDataToRealOne() {
    this.loading.next(true);
    this.dataChange.next('Updating new answers');
    this.getNewAnswers(this.key, null, null);
    this.newAnswersAvailable = false;
  }

  /**
   * Gets new answers to analysis from backend.
   *
   * @param key    Survey key.
   */
  private getNewAnswers(
    surveyKey: string,
    reportKey: string,
    since: number | null = null,
    resetAnswers: boolean = false,
    latestAnswerTime: number = 0,
  ): void {
    this.updatingAnswers = new AsyncSubject<boolean>();
    this.updatingAnswers.next(true);
    this.answersRequestParameters = { survey: { key: surveyKey }, filters: this.cropFilters };

    if (this.cropFilters && this.cropFilters.length > 0 && this.cropFilters.find((item) => item.id === 'time')) {
      this.liveStatus.next(false);
    } else {
      combineLatest(this.liveReportOn, this.survey.release)
        .pipe(first())
        .subscribe(([liveReport, release]) => {
          if (liveReport && release.online) {
            this.liveStatus.next(true);
          } else {
            this.liveStatus.next(false);
          }
        });
    }

    this.rm
      .getAnswers(surveyKey, reportKey, since || null, this.cropFilters, this.mergedSurveysKeys, latestAnswerTime)
      .pipe(first())
      .subscribe(
        (response) => {
          this.getAnswersRequestMade = true;
          if (!this.timePeriod) {
            const startTimeArr =
              response.oldestAnsweringTime && response.newestAnsweringTime
                ? response.oldestAnsweringTime.match(/(\d+)/g)
                : [];
            const startTime = startTimeArr ? startTimeArr.map((val) => Number(val)) : [];
            const start = new Date(Date.UTC(startTime[0], startTime[1] - 1, startTime[2], 0, 0, 0)).valueOf() || 0;
            const endTimeArr =
              response.oldestAnsweringTime && response.newestAnsweringTime
                ? response.newestAnsweringTime.match(/(\d+)/g)
                : [];
            const endTime = endTimeArr ? endTimeArr.map((val) => Number(val)) : [];
            const end = new Date(Date.UTC(endTime[0], endTime[1] - 1, endTime[2], 0, 0, 0)).valueOf() || 0;
            const day = 1000 * 60 * 60 * 24;
            const days = 1 + (end - start) / day;

            if (days > 600) {
              this.timePeriod = 'year';
            } else if (days > 100) {
              this.timePeriod = 'month';
            } else if (days > 20) {
              this.timePeriod = 'week';
            } else {
              this.timePeriod = 'day';
            }
          }

          if (response && response.result.length > 0) {
            this.answerData = this.dp.prepare(
              this.answerData &&
                this.answerData.answers &&
                this.answerData.answers.length > 0 &&
                !this.answerData.sampleData &&
                !resetAnswers
                ? this.answerData
                : null,
              response.result,
              false,
              response.newestRecord,
              this.questions,
              this.dimensions,
              this.timePeriod,
              this.mergeSettings,
            );
            this.setCrossfilter(this.answerData.answers);
          } else if (since === null && (!this.cropFilters || this.cropFilters.length === 0)) {
            const randData = this.dg.generateAnswerers(
              1000,
              surveyKey,
              this.questions,
              this.outcomes,
              this.locales,
              this.release,
            );
            this.answerData = this.dp.prepare(
              null,
              randData,
              true,
              null,
              this.questions,
              this.dimensions,
              this.timePeriod,
              this.mergeSettings,
            );

            this.setCrossfilter(this.answerData.answers);
          } else if (this.cropFilters.length > 0) {
            this.answerData = this.dp.prepare(
              this.answerData &&
                this.answerData.answers &&
                this.answerData.answers.length > 0 &&
                !this.answerData.sampleData &&
                !resetAnswers
                ? this.answerData
                : null,
              response.result,
              false,
              response.newestRecord,
              this.questions,
              this.dimensions,
              this.timePeriod,
              this.mergeSettings,
            );
            this.setCrossfilter(this.answerData.answers);
          }

          if (since && response.result?.length > 1) {
            this.rm.trackReportOpen(
              response.result.length - 1,
              surveyKey,
              reportKey,
              null,
              this.isSharedReport ? 'update_shared_private' : 'update',
              !this.latestTrackSurvey ||
                !this.latestTrackTime ||
                this.latestTrackSurvey !== surveyKey ||
                new Date(this.latestTrackTime).setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0),
            );

            this.latestTrackSurvey = surveyKey;
            this.latestTrackTime = new Date().valueOf();
          }
        },
        (error) => {
          this.dataChange.next('Server error Get Answers');
          if (this.dg && (!this.answerData || !this.answerData.answers || this.answerData.answers.length === 0)) {
            const randData = this.dg.generateAnswerers(
              1000,
              surveyKey,
              this.questions,
              this.outcomes,
              this.locales,
              this.release,
            );
            this.answerData = this.dp.prepare(
              null,
              randData,
              true,
              null,
              this.questions,
              this.dimensions,
              this.timePeriod,
              this.mergeSettings,
            );

            if (error && error.status !== 403) {
              this.setCrossfilter(this.answerData.answers);
            }
          }
        },
        () => {},
      );
  }

  /**
   * Connects saved public analysis to crossfilter & updates data if it is not freezed.
   *
   * @param storedReport  Stored report from backend.
   */
  public connectPublic(storedReport: any = null, isSharedReport: boolean = false) {
    const grid = storedReport ? storedReport.charts : [];
    const freezed = false; // we need settings to check if answers & questions should be updated

    this.isSavedReport = true;
    this.isSharedReport = isSharedReport;
    this.reportGrid = grid;

    this.dimensions = Object.assign({}, storedReport.dimensions);
    this.answerData = storedReport.respondents;
    this.comparisonDimension = storedReport.comparison;
    this.latestAnswerTime = storedReport.latestAnswerTime || 0;
    this.publicSettings = storedReport.publicSettings;
    this.answersRequestParameters = storedReport.getAnswers;
    this.locales = storedReport.locales || {};
    this.activeLocale = storedReport.activeLocale || '';
    this.cropFilters = storedReport.getAnswers ? storedReport.getAnswers.filters || [] : [];
    this.timePeriod = storedReport.timePeriod || 'day';
    this.trendAnalysisOn = storedReport.trend || false;
    this.safeReport = storedReport.safeReport || !!storedReport.anonymityTreshold || false;
    this.anonymityTreshold = storedReport.anonymityTreshold || (this.safeReport && this.isSharedReport ? 5 : null);

    if (this.comparisonDimension && this.comparisonDimension.values && this.comparisonDimension.values.length > 0) {
      this.comparisonModeOn = true;
    }

    this.initUpdateToggledDataSubject();

    if (this.answerData != null && this.answerData.answers != null) {
      this.initUpdateToggledDataSubject();
      this.setCrossfilter(this.answerData.answers, grid);
    }
  }

  /**
   * Here we migrate data from old surveys to include surveyKeys as prefix in keys.
   *
   * @param storedReport  Stored report from backend.
   */
  private migrateStoredReportData(storedReport: StoredReport): StoredReport {
    const questions: string[] = [
      'info-text',
      'free-text',
      'slider-1d',
      'slider-1r',
      'slider-1v',
      'slider-2d',
      'slider-nps',
      'choice-text',
      'choice-image',
      'choice-radio',
      'choice-check',
      'choice-cloud',
      'choice-single',
      'choice-multi',
      'input-url',
      'input-email',
      'input-phone',
      'input-number',
      'input-string',
      'input-numeric',
      'input-address',
      'input-checkbox',
      'input-dropdown',
    ];
    const keysToBeMigrated: string[] = Object.keys(storedReport.dimensions).filter(
      (dim) =>
        storedReport.dimensions[dim] &&
        storedReport.dimensions[dim]['originalType'] &&
        questions.indexOf(storedReport.dimensions[dim]['originalType']) >= 0,
    );
    const migratedReport: StoredReport = Object.assign({}, storedReport);

    for (const dimKey in migratedReport.dimensions || {}) {
      const surveyKey: string = (migratedReport.dimensions[dimKey]['survey'] || this.key) + '/';

      if (questions.indexOf(migratedReport.dimensions[dimKey]['originalType']) >= 0) {
        const newKey: string = surveyKey + dimKey;

        if (dimKey.indexOf(surveyKey) === -1 && migratedReport.dimensions[dimKey]['key'].indexOf(surveyKey) === -1) {
          // Updating dimensions keys
          migratedReport.dimensions[dimKey]['key'] = newKey;

          // Updating choice keys
          if (
            (migratedReport.dimensions[dimKey]['originalType'].split('-')[0] === 'choice' &&
              migratedReport.dimensions[dimKey]['originalTypeSpecifier'] !== 'choice-comment') ||
            migratedReport.dimensions[dimKey]['originalType'] === Questions.INPUT_DROPDOWN
          ) {
            for (let c = 0, lenc = (migratedReport.dimensions[dimKey]['values'] || []).length; c < lenc; c++) {
              const choice: string | number = migratedReport.dimensions[dimKey]['values'][c];

              if (choice.toString().indexOf(surveyKey) === -1) {
                migratedReport.dimensions[dimKey]['values'][c] = surveyKey + choice;

                if (migratedReport.dimensions[dimKey]['labelsCategorical'][choice]) {
                  const newChoiceKey: string = surveyKey + choice;
                  Object.defineProperty(
                    migratedReport.dimensions[dimKey]['labelsCategorical'],
                    newChoiceKey,
                    Object.getOwnPropertyDescriptor(migratedReport.dimensions[dimKey]['labelsCategorical'], choice),
                  );
                  delete migratedReport.dimensions[dimKey]['labelsCategorical'][choice];
                }
              }
            }
          }

          // Updating local z scoring partners
          if (
            migratedReport.dimensions[dimKey]['localZComparison'] &&
            migratedReport.dimensions[dimKey]['localZComparison'].length
          ) {
            for (let k = 0, lenk = migratedReport.dimensions[dimKey]['localZComparison'].length; k < lenk; k++) {
              if (migratedReport.dimensions[dimKey]['localZComparison'][k].indexOf(surveyKey) === -1) {
                const newZKey: string = surveyKey + migratedReport.dimensions[dimKey]['localZComparison'][k];
                migratedReport.dimensions[dimKey]['localZComparison'][k] = newZKey;
              }
            }
          }

          // Updating global z scoring partners
          if (
            migratedReport.dimensions[dimKey]['globalZComparison'] &&
            migratedReport.dimensions[dimKey]['globalZComparison'].length
          ) {
            for (let k = 0, lenk = migratedReport.dimensions[dimKey]['globalZComparison'].length; k < lenk; k++) {
              if (migratedReport.dimensions[dimKey]['globalZComparison'][k].indexOf(surveyKey) === -1) {
                const newZKey: string = surveyKey + migratedReport.dimensions[dimKey]['globalZComparison'][k];
                migratedReport.dimensions[dimKey]['globalZComparison'][k] = newZKey;
              }
            }
          }

          // Updating dimensions object key
          Object.defineProperty(
            migratedReport.dimensions,
            newKey,
            Object.getOwnPropertyDescriptor(migratedReport.dimensions, dimKey),
          );
          delete migratedReport.dimensions[dimKey];

          // Updating indexes list value
          const index =
            migratedReport.respondents &&
            migratedReport.respondents.indexes &&
            migratedReport.respondents.indexes.length
              ? migratedReport.respondents.indexes.indexOf(dimKey)
              : -1;
          if (index >= 0) {
            migratedReport.respondents.indexes[index] = newKey;
          }

          // Updating text answers keys
          if (
            migratedReport.respondents &&
            migratedReport.respondents.textAnswers &&
            migratedReport.respondents.textAnswers[dimKey]
          ) {
            Object.defineProperty(
              migratedReport.respondents.textAnswers,
              newKey,
              Object.getOwnPropertyDescriptor(migratedReport.respondents.textAnswers, dimKey),
            );
            delete migratedReport.respondents.textAnswers[dimKey];
          }

          // Updating text labels keys
          if (
            migratedReport.respondents &&
            migratedReport.respondents.textLabels &&
            migratedReport.respondents.textLabels[dimKey]
          ) {
            Object.defineProperty(
              migratedReport.respondents.textLabels,
              newKey,
              Object.getOwnPropertyDescriptor(migratedReport.respondents.textLabels, dimKey),
            );
            delete migratedReport.respondents.textLabels[dimKey];
          }

          // Updating dimension keys in grid items data
          for (const chart of migratedReport.charts || []) {
            for (let d = 0, lend = ((chart && chart.data && chart.data.details) || []).length; d < lend; d++) {
              const chartDetKey = chart.data.details[d]['key'];

              if (chartDetKey === dimKey) {
                if (chartDetKey.indexOf(surveyKey) === -1) {
                  chart.data.details[d]['key'] = surveyKey + chartDetKey;
                }
              }
            }
          }
        }
      } else if (dimKey === 'outcome') {
        // Updating outcome keys
        for (let c = 0, lenc = (migratedReport.dimensions[dimKey]['values'] || []).length; c < lenc; c++) {
          const choice: string | number = migratedReport.dimensions[dimKey]['values'][c];
          if (choice.toString().indexOf(surveyKey) === -1) {
            migratedReport.dimensions[dimKey]['values'][c] = surveyKey + choice;

            if (migratedReport.dimensions[dimKey]['labelsCategorical'][choice]) {
              const newChoiceKey: string = surveyKey + choice;
              Object.defineProperty(
                migratedReport.dimensions[dimKey]['labelsCategorical'],
                newChoiceKey,
                Object.getOwnPropertyDescriptor(migratedReport.dimensions[dimKey]['labelsCategorical'], choice),
              );
              delete migratedReport.dimensions[dimKey]['labelsCategorical'][choice];
            }
          }
        }
      }
    }

    if (migratedReport.dimensions['userSegments']) {
      const dimKey: string = 'userSegments';
      const surveyKey: string = (migratedReport.dimensions[dimKey]['survey'] || this.key) + '/';
      // Updating keys in user segments origins
      if (migratedReport.dimensions[dimKey].values && migratedReport.dimensions[dimKey].values.length) {
        for (const item in migratedReport.dimensions[dimKey]['customOrigins']) {
          const renameArr = [];
          for (const filter in migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'] || {}) {
            if (keysToBeMigrated.indexOf(filter) >= 0 || filter === 'outcome') {
              if (filter.indexOf(surveyKey) === -1 && filter !== 'outcome') {
                renameArr.push(filter);
              }
              const dimData =
                migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][filter]['dimensionData'];
              const isChoiceQuestion: boolean =
                dimData &&
                dimData['originalType'] &&
                (dimData['originalType'].split('-')[0] === 'choice' ||
                  dimData['originalType'] === Questions.INPUT_DROPDOWN) &&
                dimData['originalTypeSpecifier'] !== 'choice-comment';

              if (isChoiceQuestion || filter === 'outcome') {
                const filterArr =
                  migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][filter]['filter'] || [];
                const valuesArr =
                  migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][filter]['values'] || [];

                for (let f = 0, lenf = filterArr.length; f < lenf; f++) {
                  if (filterArr[f].indexOf(surveyKey) === -1) {
                    migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][filter]['filter'][f] =
                      surveyKey + filterArr[f];
                  }
                }

                for (let f = 0, lenf = valuesArr.length; f < lenf; f++) {
                  if (valuesArr[f].indexOf(surveyKey) === -1) {
                    migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][filter]['values'][f] =
                      surveyKey + valuesArr[f];
                  }
                }
              }
            }
          }

          for (const oldKey of renameArr) {
            const newFilterKey: string = surveyKey + oldKey;
            Object.defineProperty(
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'],
              newFilterKey,
              Object.getOwnPropertyDescriptor(
                migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'],
                oldKey,
              ),
            );
            delete migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][oldKey];

            if (
              migratedReport.dimensions[newFilterKey] &&
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey] &&
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey]['dimensionData']
            ) {
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey]['dimensionData'] =
                migratedReport.dimensions[newFilterKey];
            }
          }
        }
      }
    }

    return migratedReport;
  }

  /**
   * Here we migrate old word clouds to new word analysis type.
   *
   * @param storedReport  Stored report from backend.
   */
  private migrateWordClouds(migratedReport: StoredReport): StoredReport {
    for (let i = 0, len = migratedReport.charts.length; i < len; i++) {
      if (
        migratedReport.charts[i] &&
        migratedReport.charts[i].chartSettings &&
        migratedReport.charts[i].chartSettings.type === Charts.WORDCLOUD
      ) {
        if (
          migratedReport.charts[i].data &&
          migratedReport.charts[i].data.details.length === 1 &&
          migratedReport.charts[i].data.details[0].scale === 'text'
        ) {
          const oldKey: string = migratedReport.charts[i].data.details[0].key;
          migratedReport.respondents.textLabels[oldKey + ':words'] = migratedReport.respondents.textLabels[oldKey];
          migratedReport.charts[i].chartSettings.type = Charts.WORDANALYSIS;
          migratedReport.charts[i].data.details[0].key += ':words';
          migratedReport.charts[i].data.details[0].originalTypeSpecifier = 'text-words';
          migratedReport.charts[i].data.details[0].scale = 'categorical';
          migratedReport.charts[i].data.scales[0] = 'categorical';
          migratedReport.dimensions[oldKey + ':words'] = migratedReport.charts[i].data.details[0];
        } else if (
          migratedReport.charts[i].data &&
          migratedReport.charts[i].data.details.length === 1 &&
          migratedReport.charts[i].data.details[0].scale === 'categorical'
        ) {
        } else {
          migratedReport.charts[i].chartSettings.type = Charts.TEXTANSWERTABLE;
        }
      }
    }

    return migratedReport;
  }

  /**
   * Here we migrate contact details into respondentFields on stored report data.
   *
   * @param storedReport  Stored report from backend.
   */
  private migrateContacts(storedReport: StoredReport): StoredReport {
    const migratedReport: StoredReport = Object.assign({}, storedReport);

    for (const dimKey in migratedReport.dimensions || {}) {
      if (dimKey.substring(0, 9) === 'contacts-' && migratedReport.dimensions[dimKey]) {
        const newKey: string = dimKey.replace('contacts-', 'fields-');

        // Updating dimensions keys
        migratedReport.dimensions[dimKey]['key'] = newKey;
        migratedReport.dimensions[dimKey]['values'] = [];
        migratedReport.dimensions[dimKey]['labelsCategorical'] = {};

        // Updating dimensions object key
        Object.defineProperty(
          migratedReport.dimensions,
          newKey,
          Object.getOwnPropertyDescriptor(migratedReport.dimensions, dimKey),
        );
        delete migratedReport.dimensions[dimKey];

        // Updating indexes list value
        const index = migratedReport.respondents?.indexes?.length
          ? migratedReport.respondents.indexes.indexOf(dimKey)
          : -1;
        if (index >= 0) {
          migratedReport.respondents.indexes[index] = newKey;
        }

        // Updating dimension keys in grid items data
        for (const chart of migratedReport.charts || []) {
          if (chart?.key?.substring(0, 9) === 'contacts-') {
            chart.key =
              chart.key !== 'contacts-summary' ? chart.key.replace('contacts-', 'fields-') : 'fields-entrySummary';
          }

          for (let d = 0, lend = (chart?.data?.details || []).length; d < lend; d++) {
            const chartDetKey = chart.data.details[d]?.['key'];

            if (chartDetKey === dimKey) {
              chart.data.details[d]['key'] = newKey;
            }
          }
        }

        // updating getAnswers filter keys
        migratedReport.getAnswers.filters = migratedReport.getAnswers.filters.map((filter) => {
          if (filter.id === dimKey) {
            filter.id = newKey;
            filter.survey = this.key;
          }
          return filter;
        });
      }
    }

    if (migratedReport.dimensions['userSegments']) {
      const dimKey: string = 'userSegments';

      // Updating keys in user segments origins
      if (migratedReport.dimensions[dimKey]?.values?.length) {
        for (const item in migratedReport.dimensions[dimKey]['customOrigins']) {
          const renameArr = [];
          for (const filter in migratedReport.dimensions[dimKey]['customOrigins'][item]?.['filters'] || {}) {
            if (filter.substring(0, 9) === 'contacts-') {
              renameArr.push(filter);
            }
          }

          for (const oldKey of renameArr) {
            const newFilterKey: string = oldKey.replace('contacts-', 'fields-');
            Object.defineProperty(
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'],
              newFilterKey,
              Object.getOwnPropertyDescriptor(
                migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'],
                oldKey,
              ),
            );
            delete migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][oldKey];

            if (
              migratedReport.dimensions[newFilterKey] &&
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey]?.['dimensionData']
            ) {
              migratedReport.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey]['dimensionData'] =
                migratedReport.dimensions[newFilterKey];
            }
          }
        }
      }
    }

    migratedReport.getAnswers.filters = migratedReport.getAnswers.filters.map((filter) => {
      if (filter.property) {
        filter.survey = this.key;
        filter.id = 'fields-' + filter.property;
        delete filter.property;
      }

      return filter;
    });

    return migratedReport;
  }

  /**
   * Here we migrate old respondentFields into new respondentFields on stored report data.
   *
   * @param storedReport  Stored report from backend.
   */
  private migrateRespondentFields(storedReport: StoredReport): void {
    const isCorrectRFKey: (key: string, surveyKey: string) => boolean = (key: string, surveyKey: string) => {
      return (this.respondentFields[surveyKey] || []).some((field) => field?.$key === key?.substring(7));
    };
    const newRFKey: (oldKey: string, surveyKey: string) => string = (oldKey: string, surveyKey: string) => {
      for (let i = 0, len = this.respondentFields[surveyKey]?.length; i < len; i++) {
        const field = this.respondentFields[surveyKey][i];

        if (field?.['name'] === oldKey?.substring(7) && field?.$key) {
          return 'fields-' + field?.$key;
        }
      }

      return '';
    };

    for (const dimKey in this.dimensions || {}) {
      if (
        isField(dimKey) &&
        !isContactEntry(dimKey) &&
        this.dimensions[dimKey] &&
        !isCorrectRFKey(dimKey, this.dimensions[dimKey]['survey'])
      ) {
        const oldKey: string = dimKey;
        const newKey: string = newRFKey(dimKey, this.dimensions[dimKey]['survey']);

        if (newKey) {
          // Updating dimensions keys
          this.dimensions[dimKey]['key'] = newKey;
          this.dimensions[dimKey]['values'] = [];
          this.dimensions[dimKey]['labelsCategorical'] = !this.anonymityTreshold
            ? {}
            : {
                __masked__: $localize`Small groups`,
              };

          // Updating dimensions object key
          Object.defineProperty(this.dimensions, newKey, Object.getOwnPropertyDescriptor(this.dimensions, dimKey));
          delete this.dimensions[dimKey];

          // Updating indexes list value
          const index = this.answerData?.indexes?.length ? this.answerData.indexes.indexOf(dimKey) : -1;
          if (index >= 0) {
            this.answerData.indexes[index] = newKey;
          }

          // Updating dimension keys in grid items data
          for (const chart of storedReport.charts || []) {
            let update: boolean = false;
            for (let d = 0, lend = (chart?.data?.details || []).length; d < lend; d++) {
              const chartDetKey = chart.data.details[d]?.['key'];

              if (chartDetKey === oldKey || chartDetKey === newKey) {
                chart.data.details[d]['key'] = newKey;
                update = true;
              }
            }

            if (update) {
              this.gridItems[chart.key] = this.setGridItems([chart])[chart.key];
            }
          }

          // updating getAnswers filter keys
          this.answersRequestParameters.filters = this.answersRequestParameters.filters.map((filter) => {
            if (filter.id === oldKey) {
              filter.id = newKey;
              filter.survey = this.key;
            }
            return filter;
          });
        }
      }
    }

    if (this.dimensions['userSegments']) {
      const dimKey: string = 'userSegments';

      // Updating keys in user segments origins
      if (this.dimensions[dimKey]?.values?.length) {
        for (const item in this.dimensions[dimKey]['customOrigins']) {
          const renameArr = [];
          for (const filter in this.dimensions[dimKey]['customOrigins'][item]?.['filters'] || {}) {
            const surveyKey =
              this.dimensions[dimKey]['customOrigins'][item]?.['filters']?.[filter]?.['dimensionData']?.['survey'];

            if (isField(filter) && surveyKey && !isCorrectRFKey(filter, surveyKey) && newRFKey(filter, surveyKey)) {
              renameArr.push([filter, surveyKey]);
            }
          }

          for (const filterItem of renameArr) {
            const newFilterKey: string = newRFKey(filterItem[0], filterItem[1]);
            Object.defineProperty(
              this.dimensions[dimKey]['customOrigins'][item]['filters'],
              newFilterKey,
              Object.getOwnPropertyDescriptor(this.dimensions[dimKey]['customOrigins'][item]['filters'], filterItem[0]),
            );
            delete this.dimensions[dimKey]['customOrigins'][item]['filters'][filterItem[0]];

            if (
              this.dimensions[newFilterKey] &&
              this.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey]?.['dimensionData']
            ) {
              this.dimensions[dimKey]['customOrigins'][item]['filters'][newFilterKey]['dimensionData'] =
                this.dimensions[newFilterKey];
            }
          }
        }
      }
    }
  }

  /**
   * Connects saved analysis to crossfilter & updates data if it is not freezed.
   *
   * @param key         Survey key.
   * @param reportKey   Key for the saved report.
   * @param storedReport  Stored report from backend.
   */
  public connect(key: string, reportKey: string, storedReport: any = null, isSharedReport: boolean = false) {
    const freezed = false; // we need settings to check if answers & questions should be updated
    let contactsMigrated: boolean = false;

    this.isSavedReport = true;
    this.isSharedReport = isSharedReport;
    this.key = key;
    this.survey = this.sm.connectSurvey(key);
    this.connectedReportKey = reportKey;

    let reportData: StoredReport;

    if (!storedReport.info || !storedReport.info.appVersion) {
      reportData = this.migrateStoredReportData(storedReport);
    } else {
      reportData = Object.assign({}, storedReport);
    }

    if (!reportData.info?.contactsMigrated) {
      reportData = this.migrateContacts(reportData);
      contactsMigrated = true;
    }

    if (reportData?.charts?.filter((item) => item?.chartSettings?.type === Charts.WORDCLOUD).length) {
      reportData = this.migrateWordClouds(Object.assign({}, reportData));
    }

    const grid = reportData ? reportData.charts : [];
    this.reportGrid = grid;
    this.dimensions = Object.assign({}, reportData.dimensions);
    this.answerData = reportData.respondents;
    this.comparisonDimension = reportData.comparison;

    this.latestAnswerTime = reportData.latestAnswerTime || 0;
    this.liveBouncer = 0;
    this.initLatestAnswerObservable(key);
    this.mergeSettings = reportData.mergedSurveys?.mergeSettings || {};
    this.mergedSurveysKeys = reportData.mergedSurveys?.mergedSurveysKeys || [];
    this.mergedSurveys = [];
    this.mergedSurveysKeys.forEach((surveyKey) => this.mergedSurveys.push(this.sm.connectSurvey(surveyKey)));
    this.initMergedSurveysObservable();

    this.answersRequestParameters = reportData.getAnswers;
    this.locales = reportData.locales || {};
    this.activeLocale = reportData.activeLocale || '';
    this.cropFilters = reportData.getAnswers?.filters || [];
    this.timePeriod = reportData.timePeriod || 'day';
    this.trendAnalysisOn = reportData.trend || false;
    this.freezeTextTables = reportData.freezeTextTables || !!storedReport.anonymityTreshold || false;
    this.safeReport = reportData.safeReport || !!storedReport.anonymityTreshold || false;
    this.anonymityTreshold = reportData.anonymityTreshold || (this.safeReport && this.isSharedReport ? 5 : null);

    this.stopTaking = new Observable((observer) => {
      this.resetEvent.subscribe((reset) => {
        if (reset) {
          observer.next(true);
          observer.complete();
        }
      });
    });

    if (this.comparisonDimension?.values?.length > 0) {
      this.comparisonModeOn = true;
    }

    if (this.answerData?.answers != null) {
      this.initUpdateToggledDataSubject();
      if (this.answerData.answers.length || contactsMigrated) {
        this.setCrossfilter(this.answerData.answers, grid);
      }
    }

    if (this.answerData?.answers != null && !freezed) {
      // Subscribing to questions and outcomes
      let previousMergeSettings: string;

      this.surveySub = combineLatest([
        this.survey.locales,
        this.survey.release,
        this.survey.questions,
        this.survey.outcomes,
        this.survey.survey,
        this.mergedSurveyContents,
        this.rm.getRespondentFields(key),
      ]);

      this.surveySub
        .pipe(debounceTime(20), takeUntil(this.stopTaking))
        .subscribe(([locales, release, questions, outcomes, survey, mergedSurveys, respondentFields]) => {
          if (!previousMergeSettings) {
            previousMergeSettings = JSON.stringify(this.mergeSettings);
          }
          this.anonymityTreshold = survey?.anonymous || (this.safeReport && this.isSharedReport ? 5 : null);
          this.includedSurveys = [];
          this.includedSurveys.push(survey);
          this.locales[this.key] = locales;
          this.release[this.key] = release;

          this.questions[this.key] = (questions || []).map((question) => new QuestionData(question));
          this.outcomes[this.key] = (outcomes || []).map((outcome) => new OutcomeData(outcome));

          this.respondentFields[this.key] = respondentFields;

          for (let s = 0, lens = (mergedSurveys || []).length; s < lens; s++) {
            const surveyKey: string = mergedSurveys[s][4]['$key'];
            this.questions[surveyKey] = (mergedSurveys[s][0] || []).map((question) => new QuestionData(question));
            this.outcomes[surveyKey] = (mergedSurveys[s][1] || [])
              .map((outcome) => new OutcomeData(outcome))
              .filter(
                (outcome) =>
                  this.mergeSettings?.[surveyKey]?.['outcomes']?.['outcomes']?.find(
                    (o) => o.orig && o.orig['$key'] === outcome['$key'],
                  ) &&
                  this.mergeSettings[surveyKey]['outcomes']['outcomes'].find(
                    (o) => o.orig && o.orig['$key'] === outcome['$key'],
                  )['mappedTo'] &&
                  this.mergeSettings[surveyKey]['outcomes']['outcomes'].find(
                    (o) => o.orig && o.orig['$key'] === outcome['$key'],
                  )['mappedTo']['manualMerge'],
              );
            this.locales[surveyKey] = mergedSurveys[s][2];
            this.release[surveyKey] = mergedSurveys[s][3];
            this.includedSurveys.push(mergedSurveys[s][4]);
            this.respondentFields[surveyKey] = mergedSurveys[s][5];

            if (mergedSurveys[s][4]?.['anonymous'] > this.anonymityTreshold) {
              this.anonymityTreshold = mergedSurveys[s][4]?.['anonymous'];
            }
          }

          const originalDimensions = JSON.parse(JSON.stringify(reportData.dimensions));
          this.setDimensions();

          let changesInQuestions: boolean = this.dp.detectMajorDimensionChanges(originalDimensions, this.dimensions);
          let missingData: boolean =
            this.answerData.answers.length === 0 ||
            !this.answerData.textLabels ||
            !this.answerData.textAnswers ||
            !this.answerData.textContacts ||
            !this.dimensions['time'].values ||
            this.dimensions['time'].values.length === 0;
          let changesInMergedSurveys: boolean = previousMergeSettings !== JSON.stringify(this.mergeSettings);
          previousMergeSettings = JSON.stringify(this.mergeSettings);

          if (contactsMigrated) {
            this.migrateRespondentFields(reportData);
          }

          if (this.latestAnswerObservableSub) {
            this.latestAnswerObservableSub.unsubscribe();
          }

          if (changesInMergedSurveys) {
            this.liveBouncer = 0;
            this.initLatestAnswerObservable(key, this.mergedSurveysKeys);
          }

          this.latestAnswerObservableSub = this.latestAnswerObservable
            .pipe(takeUntil(this.stopTaking))
            .subscribe((observable) => {
              if (this.currentReportSub) {
                this.currentReportSub.unsubscribe();
              }
              this.currentReportSub = observable.subscribe((latestAnswerTime) => {
                if (
                  this.latestAnswerTime !== (latestAnswerTime || 0) ||
                  changesInQuestions ||
                  missingData ||
                  changesInMergedSurveys ||
                  contactsMigrated
                ) {
                  if (changesInQuestions || missingData || changesInMergedSurveys || contactsMigrated) {
                    this.updateNeeded = true;
                  } else {
                    this.updateNeeded = false;
                  }
                  if (latestAnswerTime != null) {
                    this.latestAnswerTime = latestAnswerTime;
                  }

                  this.dataChange.next(
                    `Updating ${this.latestAnswerTime !== latestAnswerTime ? 'new answers' : ''}${
                      this.latestAnswerTime !== latestAnswerTime &&
                      (changesInQuestions || missingData || changesInMergedSurveys || contactsMigrated)
                        ? ' and'
                        : ''
                    }${
                      changesInQuestions || missingData || changesInMergedSurveys || contactsMigrated
                        ? ' other data changes'
                        : ''
                    }`,
                  );

                  this.getNewAnswers(
                    key,
                    reportKey,
                    changesInQuestions || missingData || changesInMergedSurveys || contactsMigrated
                      ? null
                      : this.answerData && this.answerData.newestRecord
                      ? this.answerData.newestRecord
                      : null,
                    changesInQuestions || missingData || changesInMergedSurveys || contactsMigrated,
                  );
                  changesInQuestions = false;
                  missingData = false;
                  changesInMergedSurveys = false;
                  contactsMigrated = false;
                } else if (mergedSurveys && mergedSurveys.length) {
                  this.sendNewCrossfilter();
                }
              });
            });
        });
    }
  }

  public mergeSurvey(key: string, questions: string[], mappings: { [questionKey: string]: any }): void {
    const survey = this.sm.connectSurvey(key);

    if (!this.mergeSettings) {
      this.mergeSettings = {};
    }

    if (!this.mergeSettings[key]) {
      this.mergeSettings[key] = {};
    }

    for (let i = 0, len = questions.length; i < len; i++) {
      this.mergeSettings[key][questions[i]] = mappings[questions[i]] || {};
    }

    if (mappings['outcomes']) {
      this.mergeSettings[key]['outcomes'] = mappings['outcomes'];
    }

    if (this.mergedSurveysKeys.indexOf(key) < 0) {
      this.mergedSurveysKeys.push(key);
    }
    if (this.mergedSurveys.findIndex((item) => item && item.key === key) < 0) {
      this.mergedSurveys.push(survey);
    }

    this.surveyMerged.next(true);
  }

  public removeMergedSurvey(survey: SurveyData): void {
    const key: string = survey.$key;
    const checkKey: string = key + '/';

    // remove filters
    const foundFilters: any[] = [];
    const removedFilters: any[] = [];

    for (const filter of Object.keys(this.filters)) {
      foundFilters.push({
        key: filter,
        label: this.dimensions[filter].title,
        filter: this.filters[filter].filter,
        values: this.filters[filter].values,
      });
    }
    for (const filter of foundFilters) {
      if (
        filter.key.indexOf(checkKey) >= 0 ||
        (filter.filter || []).findIndex((f) => f.toString().indexOf(checkKey) >= 0) >= 0
      ) {
        removedFilters.push({ key: filter.key, values: filter.values, filter: [] });
      }
    }

    if (removedFilters.length) {
      this.filter(removedFilters);
    }

    // remove survey
    if (this.includedSurveys.findIndex((mergedSurvey) => mergedSurvey['$key'] === key) >= 0) {
      this.includedSurveys.splice(
        this.includedSurveys.findIndex((mergedSurvey) => mergedSurvey['$key'] === key),
        1,
      );
    }

    if (this.mergedSurveysKeys.indexOf(key) >= 0) {
      this.mergedSurveysKeys.splice(this.mergedSurveysKeys.indexOf(key), 1);
    }

    if (this.mergedSurveys.findIndex((mergedSurvey) => mergedSurvey['key'] === key) >= 0) {
      this.mergedSurveys.splice(
        this.mergedSurveys.findIndex((mergedSurvey) => mergedSurvey['key'] === key),
        1,
      );
    }

    if (this.mergeSettings && this.mergeSettings[key]) {
      delete this.mergeSettings[key];
    }

    // remove cropfilters

    // remove userSegments
    if (
      this.dimensions['userSegments'] &&
      this.dimensions['userSegments']['values'] &&
      this.dimensions['userSegments']['values'].length
    ) {
      const removedItems = [];
      for (const item in this.dimensions['userSegments']['customOrigins']) {
        for (const filter in this.dimensions['userSegments']['customOrigins'][item]['filters'] || {}) {
          if (
            filter.indexOf(checkKey) === 0 ||
            (this.dimensions['userSegments']['customOrigins'][item]['filters'][filter]['filter'] || []).findIndex(
              (f) => f.toString().indexOf(checkKey) >= 0,
            ) >= 0
          ) {
            removedItems.push(item);
          }
        }
      }

      for (let i = 0, len = removedItems.length; i < len; i++) {
        if (this.dimensions['userSegments']['values'].indexOf(removedItems[i]) >= 0) {
          this.dimensions['userSegments']['values'].splice(
            this.dimensions['userSegments']['values'].indexOf(removedItems[i]),
            1,
          );
        }
        if (this.dimensions['userSegments']['customValuesOrder'].indexOf(removedItems[i]) >= 0) {
          this.dimensions['userSegments']['customValuesOrder'].splice(
            this.dimensions['userSegments']['customValuesOrder'].indexOf(removedItems[i]),
            1,
          );
        }
        if (this.dimensions['userSegments']['labelsCategorical'][removedItems[i]]) {
          delete this.dimensions['userSegments']['labelsCategorical'][removedItems[i]];
        }
        if (this.dimensions['userSegments']['customOrigins'][removedItems[i]]) {
          delete this.dimensions['userSegments']['customOrigins'][removedItems[i]];
        }
        if (this.dimensions['userSegments']['customColors'][removedItems[i]]) {
          delete this.dimensions['userSegments']['customColors'][removedItems[i]];
        }
      }
    }

    // remove gridItems
    const itemsToRemove: GridItemData = {};
    for (const item in this.gridItems) {
      if (item.indexOf(checkKey) === 0) {
        itemsToRemove[item] = this.gridItems[item];

        delete this.gridItems[item];
      }
    }

    this.removeChartCard.next(itemsToRemove);
    this.removeChartCard.next(null);

    // remove dimensions from custom griditems
    for (const item in this.gridItems) {
      for (let k = this.gridItems[item]['keys'].length - 1; k >= 0; k--) {
        if (this.gridItems[item]['keys'][k].indexOf(checkKey) === 0) {
          this.gridItems[item]['keys'].splice(k, 1);
          this.gridItems[item]['details'].splice(k, 1);
          this.gridItems[item]['distributions'].splice(k, 1);
          this.gridItems[item]['filters'].splice(k, 1);
          this.gridItems[item]['indexes'].splice(k, 1);
          this.gridItems[item]['scales'].splice(k, 1);
          this.gridItems[item]['stats'].splice(k, 1);
          this.gridItems[item]['totalAnswers'].splice(k, 1);
        }
      }
    }

    // remove dimensions
    for (const item in this.dimensions) {
      if (item.indexOf(checkKey) === 0) {
        delete this.dimensions[item];
      }
    }

    // remove outcomes, releases, questions, locales
    if (this.outcomes && this.outcomes[key]) {
      delete this.outcomes[key];
    }

    if (this.release && this.release[key]) {
      delete this.release[key];
    }

    if (this.questions && this.questions[key]) {
      delete this.questions[key];
    }

    if (this.locales && this.locales[key]) {
      delete this.locales[key];
    }

    this.surveyMerged.next(true);
  }

  public updateMergeSettings(surveyKey: string, questionKey: string, settings: { [s: string]: any }): void {
    for (const setting in settings) {
      this.mergeSettings[surveyKey][questionKey][setting] = settings[setting];
    }
  }

  public deleteRespondents(respondents: number[]): Observable<any> {
    return new Observable<any>((observer) => {
      this.rm
        .removeRespondents(respondents)
        .pipe(first())
        .subscribe(
          (response) => {
            this.loading.next(true);
            this.dataChange.next('Removed respondents');
            this.getNewAnswers(this.key, null, null, true);
            this.loading.pipe(takeWhile((status) => status)).subscribe(
              (status) => {},
              (error) => {},
              () => {
                observer.next();
                observer.complete();
              },
            );
          },
          (error) => {
            observer.error(error);
          },
          () => {},
        );
    });
  }

  /**
   * Resets crossfilter
   *
   * @param all    Boolean value. Use false to reset only filters and crossfilter base.
   * @returns      'Done'.
   */
  public reset(all: boolean = true) {
    if (all) {
      this.resetEvent.next(true);
      this.resetEvent.next(false);
      this.dataChange.next(null);
      this.liveStatus.next(false);

      if (this.currentReportSub) {
        this.currentReportSub.unsubscribe();
      }

      if (this.updateToggledDataSub) {
        this.updateToggledDataSub.unsubscribe();
      }

      this.liveBouncer = 0;
      this.latestAnswerTime = 0;
      this.getAnswersRequestMade = false;
    }

    for (const key in this.customDimensions) {
      this.customDimensions[key].group.dispose();
      this.customDimensions[key].dimension.dispose();
      delete this.customDimensions[key].dimension;
      delete this.customDimensions[key].group;
      delete this.customDimensions[key];
    }

    if (all) {
      this.isSavedReport = false;
      this.isSharedReport = false;
      delete this.answerData;
      this.reportGrid = [];
      this.comparisonModeOn = false;
      delete this.comparisonDimension;
      this.dimensions = {};
      this.customDimensions = {};
      this.gridItems = {};
      this.cropFilters = [];
      this.newAnswersAvailable = false;
      this.timePeriod = '';
      this.trendAnalysisOn = false;
      this.safeReport = false;
      this.anonymityTreshold = null;
      this.freezeTextTables = false;
      this.mergeSettings = {};
      this.mergedSurveys = [];
      this.mergedSurveysKeys = [];
      this.includedSurveys = [];
      this.questions = {};
      this.outcomes = {};
      this.release = {};
      this.locales = {};
      this.respondentFields = {};
      this.activeLocale = '';
    }

    this.filters = {};

    if (this.baseDimension && typeof this.baseDimension.dispose === 'function') {
      this.baseDimension.dispose();
    }

    if (this.baseGroup && typeof this.baseGroup.dispose === 'function') {
      this.baseGroup.dispose();
    }

    delete this.base;

    if (all) {
      delete this.baseDistributions;
      delete this.memoizedDistributions;
      this.crossfilter.next(null);
      this.loading.next(false);

      delete this.key;
      this.connectedReportKey = '';
    }

    return 'Done';
  }

  public getAnswerData(): PreparedAnswersData {
    return this.answerData;
  }

  public getDimensions(): DimensionData {
    return this.dimensions;
  }

  public getComparisonDetails(): ComparisonDimension {
    return this.comparisonDimension;
  }

  public baseExists(): boolean {
    return this.base && typeof this.base.size === 'function';
  }

  public getTextLabels(): TextData {
    return this.answerData.textLabels || {};
  }

  public getTextAnswers(): TextData {
    return this.answerData.textAnswers || {};
  }

  public getTextContacts(): TextData {
    return this.answerData.textContacts || {};
  }

  public getLatestAnswerTime(): number {
    return this.latestAnswerTime;
  }

  public updateNeed(): boolean {
    return this.updateNeeded;
  }

  public getAnswersParameters(): any {
    return this.answersRequestParameters;
  }

  public getIncludedSurveys(): SurveyData[] {
    return this.includedSurveys;
  }

  public getPublicSettings(): any {
    return this.publicSettings;
  }

  public getMergeSettings(): any {
    return this.mergedSurveysKeys && this.mergedSurveysKeys.length
      ? { mergedSurveysKeys: this.mergedSurveysKeys, mergeSettings: this.mergeSettings }
      : null;
  }

  public getLocales(): { [survey: string]: LocalesData } {
    return this.locales || {};
  }

  public getLocaleConfig(): LanguagesData {
    const configs: LanguagesData = {} as LanguagesData;
    for (const survey in this.locales) {
      if (this.locales[survey] && this.locales[survey]['config']) {
        for (const lang in this.locales[survey]['config']) {
          if (!configs[lang] && !this.locales[survey]['config'][lang]?.hidden) {
            configs[lang] = this.locales[survey]['config'][lang];
          }
        }
      }
    }
    return configs;
  }

  public getActiveLocale(): string {
    return this.activeLocale;
  }

  public getTimePeriod(): string {
    return this.timePeriod;
  }

  public getTrendAnalysisStatus(): boolean {
    return this.trendAnalysisOn;
  }

  public getTextFreezingStatus(): boolean {
    return this.freezeTextTables;
  }

  public getSafeReportStatus(): boolean {
    return this.safeReport;
  }

  public getAnonymityTreshold(): number {
    return this.anonymityTreshold;
  }

  public getTimeFormat(times: (number | string)[]) {
    const locale =
      this.getActiveLocale().length > 5 || !this.getActiveLocale() ? navigator.language : this.getActiveLocale();
    const vals = times.map((val) => Number(val));
    const min = vals.length > 0 ? Math.min(...vals) : null;
    const max = vals.length > 0 ? Math.max(...vals) : null;

    const years =
      min != null &&
      max != null &&
      (new Date(min).getFullYear() !== new Date(max).getFullYear() ||
        new Date(min).getFullYear() !== new Date().getFullYear());

    if (years) {
      if (this.timePeriod === 'year') {
        return new Intl.DateTimeFormat(locale, { year: 'numeric' });
      } else if (this.timePeriod === 'month') {
        return new Intl.DateTimeFormat(locale, { month: 'short', year: 'numeric' });
      } else {
        return new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'numeric', year: 'numeric' });
      }
    } else if (this.timePeriod === 'year') {
      return new Intl.DateTimeFormat(locale, { year: 'numeric' });
    } else if (this.timePeriod === 'month') {
      return new Intl.DateTimeFormat(locale, { month: 'long' });
    } else {
      return new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'short' });
    }
  }

  public getTextAnswersFor(
    dimensions: DimensionDataItem[],
    isSharedReport: boolean = false,
    bigTable: boolean = false,
    orderByTime: boolean = false,
    isAnonymityFreezed: boolean = false,
  ): any {
    if (this.answerData != null && this.answerData.answers != null && this.answerData.indexes != null) {
      const indexes = dimensions.map((dim) => this.answerData.indexes.indexOf(dim.key));
      const getTimeOrderedData: () => any[] = () => {
        const timeDimension = this.base.dimension((d) =>
          Number(this.dimensions?.['time']?.['values']?.[d?.[this.answerData.indexes.indexOf('time')]?.[0]]),
        );
        const timeOrderedData: any[] = timeDimension.top(Infinity);
        timeDimension.dispose();

        return timeOrderedData;
      };

      const data: any[] =
        (this.freezeTextTables && isSharedReport) || isAnonymityFreezed
          ? this.answerData.answers.sort((a, b) => b[0] - a[0])
          : orderByTime
          ? getTimeOrderedData()
          : this.baseDimension.top(Infinity);
      const processedData: any[] = [];
      const timeIndex: number = dimensions.findIndex((item) => item.key === 'time');
      const timeFormat =
        timeIndex >= 0 && dimensions[timeIndex]['values']
          ? this.getTimeFormat(dimensions[timeIndex]['values'])
          : this.getTimeFormat([]);
      const groupSeparator: string = '\u001D';

      for (let i = 0, len = data.length; i < len; i++) {
        const item = data[i];

        if (item) {
          const arr: any[] = [{ value: item[0], text: '' }];
          let comparison: boolean = false;
          let comparisonSelection: boolean = false;

          for (let d = 0, lend = dimensions.length; d < lend; d++) {
            const dimension = dimensions[d];
            const dimKey = dimensions[d].key;
            const value = item[indexes[d]];
            let language;

            if (
              value &&
              value.length > 0 &&
              !(
                ((this.freezeTextTables && isSharedReport) || isAnonymityFreezed) &&
                ((this.comparisonDimension && dimKey === this.comparisonDimension.key) ||
                  (this.trendAnalysisOn && dimKey === 'time'))
              )
            ) {
              let result: string | number = '';

              if (dimension.scale === 'categorical') {
                result = value[0]
                  .map(
                    (catItem) =>
                      (
                        (dimension.originalTypeSpecifier !== 'text-words'
                          ? dimension['localeStrings']?.[this.activeLocale]?.['labelsCategorical']?.[
                              dimension['values'][catItem]
                            ]
                            ? dimension['localeStrings'][this.activeLocale]['labelsCategorical']
                            : dimension['labelsCategorical']
                          : this.getTextLabels()[dimension.key])[dimension['values'][catItem]] || ''
                      ).split(groupSeparator)[0],
                  )
                  .join(', ');
              } else if (dimension.scale === 'linear') {
                result = value
                  .map((linItem) => (linItem != null && linItem[0] >= 0 ? dimension['values'][linItem[0]] : ''))[0]
                  .toString();
              } else if (dimension.scale === 'time') {
                result = value[0].map((catItem) =>
                  catItem >= 0 ? timeFormat.format(new Date(Number(dimension['values'][catItem]))) : '',
                )[0];
              } else if (dimension.scale === 'text') {
                result =
                  this.answerData.textAnswers && this.answerData.textAnswers[dimKey] && value[1] && value[1][0] >= 0
                    ? this.answerData.textAnswers[dimKey][value[1][0]]
                    : '';
                const languageIndex =
                  dimension.valueGroupValues &&
                  dimension.valueGroupValues.findIndex(
                    (val, index) => dimension.valueGroupTypes[index] === 'language' && val.indexOf(value[1][0]) >= 0,
                  );
                if (languageIndex >= 0) {
                  language = dimension.valueGroupKeys[languageIndex];
                }
              } else if (dimension.scale === 'contact-text') {
                result =
                  this.answerData.textContacts && this.answerData.textContacts[dimKey] && value[1] && value[1][0] >= 0
                    ? this.answerData.textContacts[dimKey][value[1][0]]
                    : '';
              }

              if (this.comparisonDimension && dimension.key === this.comparisonDimension.key) {
                comparison = true;
                comparisonSelection =
                  (value && value.length > 0 ? value[0] : [])
                    .map(
                      (val) =>
                        this.dimensions[this.comparisonDimension.key] &&
                        this.dimensions[this.comparisonDimension.key]['values'] &&
                        this.dimensions[this.comparisonDimension.key]['values'][val],
                    )
                    .filter(
                      (key) =>
                        this.comparisonDimension &&
                        this.comparisonDimension.values &&
                        this.comparisonDimension.values.indexOf(key) >= 0,
                    ).length > 0;
              }

              arr.push({
                value,
                text: result || '',
                ...(language && { language }),
                comparisonDim: this.comparisonDimension && dimension.key === this.comparisonDimension.key,
                trendDim: this.trendAnalysisOn && dimension.key === 'time',
              });
            } else if (dimension.key === 'id') {
              arr.push({ value, text: value.toString() });
            } else {
              if (
                !(this.freezeTextTables && isSharedReport) &&
                this.comparisonDimension &&
                dimension.key === this.comparisonDimension.key
              ) {
                comparison = true;
              }

              arr.push({
                value,
                text: '',
                ...(language && { language }),
                comparisonDim: this.comparisonDimension && dimension.key === this.comparisonDimension.key,
                trendDim: this.trendAnalysisOn && dimension.key === 'time',
              });
            }
          }

          if (
            arr
              .filter(
                (val) =>
                  (!comparison ||
                    bigTable ||
                    (comparison && comparisonSelection && !val.comparisonDim) ||
                    (comparison && isAnonymityFreezed)) &&
                  val.text &&
                  val.text.length > 0,
              )
              .filter(
                (val) =>
                  (!this.trendAnalysisOn ||
                    bigTable ||
                    (this.trendAnalysisOn && !val.trendDim) ||
                    (this.trendAnalysisOn && isAnonymityFreezed)) &&
                  val.text &&
                  val.text.length > 0,
              ).length > 0
          ) {
            processedData.push(arr);
          }
        }
      }

      return !this.anonymityTreshold || this.anonymityTreshold <= processedData.length ? processedData : [];
    } else {
      return [];
    }
  }

  public getTextSummaryFor(dimensions: DimensionDataItem[], isSharedReport: boolean = false): Observable<string> {
    return new Observable<any>((observer) => {
      let safeReportLock: boolean = false;

      if ((isSharedReport && this.safeReport) || this.anonymityTreshold) {
        for (const filterKey in this.filters || {}) {
          if (this.filters[filterKey]?.safeReportCount != null) {
            safeReportLock = true;
          }
        }
      }

      const newCropFilters =
        !(this.freezeTextTables && isSharedReport) && !safeReportLock
          ? this.fc.convert(this.filters, this.mergeSettings, this.answerData.textLabels)
          : [];

      for (const filter of this.cropFilters) {
        const exists = newCropFilters.find(
          (item) =>
            (item.id &&
              filter.id &&
              item.id === filter.id &&
              !(item.part != null && filter.part != null && item.part !== filter.part) &&
              item.filterType === filter.filterType) ||
            (item.property && filter.property && item.property === filter.property),
        );

        if (exists) {
          exists.values.concat(filter.values).filter((v, i, a) => a.filter((val) => val === v).length === 1);
        } else {
          newCropFilters.push(filter);
        }
      }

      const questions = dimensions.map((dim) => ({
        question: dim.key.split('/')[1],
        survey: dim.survey,
        filters: newCropFilters,
      }));
      const locales: string[] =
        this.locales && this.locales[this.key] && this.locales[this.key]['config']
          ? Object.keys(this.locales[this.key]['config'])
          : [];
      const request: string = JSON.stringify({
        questions,
        activeLocale: this.activeLocale,
        locales,
        latestAnswerTime: this.latestAnswerTime,
      });
      if (this.cachedTextSummaries[request]) {
        const text: string = (
          this.cachedTextSummaries[request]?.translations?.find(
            (translation) => translation?.language === this.activeLocale,
          ) || this.cachedTextSummaries[request]
        )?.summary;
        observer.next(text);
        observer.complete();
      } else {
        observer.next($localize`:@@zef-i18n-00533:Generating summary...`);
        this.rm.getTextSummary(questions, locales, this.connectedReportKey).subscribe((result) => {
          this.cachedTextSummaries[request] = result;
          const text: string = (
            result?.translations?.find((translation) => translation?.language === this.activeLocale) || result
          )?.summary;
          observer.next(text);
          observer.complete();
        });
      }
    });
  }

  public toggleFreezeTextTables($event: boolean) {
    this.freezeTextTables = $event;
  }

  public toggleSafeReport($event: boolean) {
    this.safeReport = $event;
  }

  public toggleGridItem(itemKey: string) {
    this.dataChange.next('Toggling chart: ' + itemKey);
    if (this.gridItems[itemKey]) {
      this.gridItems[itemKey].disabled = !this.gridItems[itemKey].disabled;
      this.sendNewCrossfilter();
      this.updateToggledData.next('Request data update');
    }
  }

  public downloadFile(survey: string, question: string, id: number) {
    const questionKey: string = question.indexOf('/') >= 0 ? question.split('/')[1] : question;
    let surveyKey: string;

    const surveyIndex: number = this.answerData.indexes.indexOf('survey');
    const answererIndex: number = this.answerData.answerers.indexOf(id);

    if (surveyIndex >= 0 && answererIndex >= 0) {
      const answererSurveyIndex: number = this.answerData.answers[answererIndex][surveyIndex];
      surveyKey =
        (answererSurveyIndex >= 0 &&
          this.dimensions['survey'] &&
          this.dimensions['survey']['values'] &&
          this.dimensions['survey']['values'][answererSurveyIndex].toString()) ||
        survey ||
        this.key;
    } else {
      surveyKey = survey || this.key;
    }

    this.rm.getFileLink(surveyKey, questionKey, id, this.connectedReportKey).subscribe((link) => {
      if (link && link.url) {
        window.open(link.url);
      }
    });
  }

  /**
   * Setting up crossfilter. Creates crossfilter, calculates distributions & statistics and updates gridItems
   *
   * @param data        Prepared and z-scored data for crossfilter
   * @param reportGrid  If reportGrid already exist i.e. saved report
   */
  setCrossfilter(data: any[], reportGrid: GridItem[] = []) {
    if (reportGrid.length <= 0 && !!this.dimensions['time']) {
      // Updating dimension values that rely on data i.e. time
      this.dimensions['time'].values = Array.from(new Set(this.dp.timeValues));
    }

    this.base = crossfilter(data);
    this.baseDimension = this.base.dimension((d) => d[0]);
    this.baseGroup = this.baseDimension.groupAll();
    this.customDimensions = {};

    this.memoizedDistributions = this.memoizeDistributions(this.calculateDistributions);

    if (reportGrid.length > 0) {
      this.gridItems = this.setGridItems(reportGrid);
    } else if (Object.keys(this.gridItems).length > 0) {
      if (!this.isSavedReport) {
        // adding new field dimensions
        const fieldEntrySummaryProperties: string[] = [
          'fields-email',
          'fields-phone',
          'fields-firstName',
          'fields-lastName',
          'fields-name',
        ];
        if (
          Object.keys(this.dimensions).some((dim) => fieldEntrySummaryProperties.includes(dim)) &&
          !this.gridItems['fields-entrySummary'] &&
          !this.anonymityTreshold
        ) {
          this.updateFieldsEntrySummary(this.gridItems);

          if (this.gridItems['fields-entrySummary']) {
            const summaryItem = {};
            summaryItem['fields-entrySummary'] = this.gridItems['fields-entrySummary'];
            this.newChartCard.next(this.calculate(summaryItem));
            this.newChartCard.next(null);
          }
        }

        for (const fieldDimension of Object.keys(this.dimensions).filter(
          (d) =>
            isField(d) &&
            !isContactEntry(d) &&
            !this.gridItems[d] &&
            this.dimensions[d]?.originalType === 'respondent-field' &&
            !!this.respondentFields?.[this.dimensions[d]?.['survey']]?.find((f) => f.$key === d.substring(7))
              ?.totalCount,
        )) {
          this.gridItems[fieldDimension] = this.gridItemGenerate([fieldDimension]);
          const item = {};
          item[fieldDimension] = this.gridItems[fieldDimension];
          this.newChartCard.next(this.calculate(item));
          this.newChartCard.next(null);
        }
      }
      if (Object.keys(this.mergeSettings).length) {
        // adding missing dimensions if values occur
        for (const dim of ['survey', 'lang', 'shareLink', 'hashtags', 'zefSurveyUserRating']) {
          if (
            this.dimensions[dim] &&
            this.dimensions[dim]['values'] &&
            this.dimensions[dim]['values'].length > 1 &&
            !this.gridItems[dim]
          ) {
            this.gridItems[dim] = this.gridItemGenerate([dim]);
            const item = {};
            item[dim] = this.gridItems[dim];
            this.newChartCard.next(this.calculate(item));
            this.newChartCard.next(null);
          }
        }

        // adding new merged survey questions
        for (const survey in this.mergeSettings) {
          for (const question of this.questions[survey] || []) {
            const key = question.$key;
            const longKey = survey + '/' + key;
            let hasSentimentValues: boolean = false;
            let hasEmotions: boolean = false;
            let hasSummaryRights: boolean = false;

            if (
              this.mergeSettings[survey][question['$key']] &&
              !this.mergeSettings[survey][question['$key']]['hide'] &&
              !this.mergeSettings[survey][question['$key']]['question'] &&
              !Object.keys(this.gridItems).find((existingKey) => existingKey.includes(survey + '/' + question['$key']))
            ) {
              const type = question.type;
              const dimensions: string[] = [];

              if (type === Questions.SLIDER_2D) {
                dimensions.push(longKey + ':0', longKey + ':1');
              } else if (type === Questions.SLIDER_1R) {
                dimensions.push(longKey + ':0', longKey + ':1');
              } else if (type === Questions.FREE_TEXT || type === Questions.INPUT_STRING) {
                const hasEmotionsRights =
                  this.st?.selectSnapshot(AccountState.extensions)?.indexOf('languageEmotion') >= 0;
                hasSummaryRights = this.st?.selectSnapshot(AccountState.extensions)?.indexOf('textSummary') >= 0;

                for (let i = 0, len = this.answerData?.answers?.length; i < len; i++) {
                  if (
                    this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':sentiment')] &&
                    this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':sentiment')][0] != null
                  ) {
                    hasSentimentValues = true;
                  }
                  if (
                    hasEmotionsRights &&
                    this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':emotions')] &&
                    this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':emotions')]?.[0]?.length
                  ) {
                    hasEmotions = true;
                  }
                  if (hasSentimentValues && (!hasEmotionsRights || hasEmotions)) {
                    break;
                  }
                }
                if (hasSentimentValues) {
                  dimensions.push(longKey + ':sentiment');
                }
                dimensions.push(longKey);
              } else if (Questions.category(question, Questions.GROUP)) {
                for (const groupCandidate of this.questions[survey]) {
                  if (groupCandidate.group === key && Questions.category(groupCandidate, Questions.GROUP) === false) {
                    if (groupCandidate.type === Questions.SLIDER_2D) {
                      dimensions.push(
                        survey + '/' + groupCandidate.$key + ':0',
                        survey + '/' + groupCandidate.$key + ':1',
                      );
                    } else if (groupCandidate.type === Questions.SLIDER_1R) {
                      dimensions.push(
                        survey + '/' + groupCandidate.$key + ':0',
                        survey + '/' + groupCandidate.$key + ':1',
                      );
                    } else {
                      dimensions.push(survey + '/' + groupCandidate.$key);
                    }
                  }
                }
              } else {
                dimensions.push(longKey);
              }

              if (dimensions.filter((dim) => this.dimensions[dim]).length > 0) {
                if (hasSentimentValues) {
                  // sentiment chart
                  this.gridItems[longKey + ':sentiment'] = this.gridItemGenerate(
                    dimensions.filter((dim) => this.dimensions[dim]),
                  );
                  const sentimentItem = {};
                  sentimentItem[longKey + ':sentiment'] = this.gridItems[longKey + ':sentiment'];
                  this.newChartCard.next(this.calculate(sentimentItem));
                  this.newChartCard.next(null);

                  // word cloud chart
                  this.gridItems[longKey + ':words'] = this.gridItemGenerate([longKey + ':words']);
                  const cloudItem = {};
                  cloudItem[longKey + ':words'] = this.gridItems[longKey + ':words'];
                  this.newChartCard.next(this.calculate(cloudItem));
                  this.newChartCard.next(null);

                  if (hasSummaryRights) {
                    this.gridItems[longKey] = this.gridItemGenerate(dimensions.filter((dim) => this.dimensions[dim]));
                    const item = {};
                    item[longKey] = this.gridItems[longKey];
                    this.newChartCard.next(this.calculate(item));
                    this.newChartCard.next(null);
                  }
                } else {
                  this.gridItems[longKey] = this.gridItemGenerate(dimensions.filter((dim) => this.dimensions[dim]));
                  const item = {};
                  item[longKey] = this.gridItems[longKey];
                  this.newChartCard.next(this.calculate(item));
                  this.newChartCard.next(null);
                }

                if (hasEmotions) {
                  // emotions chart
                  this.gridItems[longKey + ':emotions'] = this.gridItemGenerate([longKey + ':emotions', longKey]);
                  const emotionsItem = {};
                  emotionsItem[longKey + ':emotions'] = this.gridItems[longKey + ':emotions'];
                  this.newChartCard.next(this.calculate(emotionsItem));
                  this.newChartCard.next(null);
                }
              }
            }
          }
        }
      }
    } else {
      this.gridItems = this.setDefaultGridItems();
    }

    if (
      this.dimensions['userSegments'] &&
      this.dimensions['userSegments']['values'] &&
      this.dimensions['userSegments']['values'].length > 0 &&
      !this.gridItems['userSegments']
    ) {
      this.gridItems['userSegments'] = this.gridItemGenerate(['userSegments']);
    }

    if (this.comparisonModeOn) {
      this.updateComparisonList(this.comparisonDimension.values, this.comparisonDimension.key, true);
    }

    if (this.trendAnalysisOn) {
      this.switchTrendAnalysis(true, true);
    }

    if (this.gridItems['fields-entrySummary'] && !this.gridItems['fields-entrySummary'].disabled) {
      this.updateFieldsEntrySummary(this.gridItems);
    }

    this.gridItems = this.calculate(this.gridItems);

    let isFilters = false;
    let filters: FilterData[] = [];

    if (reportGrid && reportGrid.length > 0) {
      // for (const item in reportGrid) {
      //   if (!reportGrid[item].isHintCard && !reportGrid[item].isSubheader && reportGrid[item].data.filtersAll) {
      //     for (const i in reportGrid[item].data.filtersAll) {
      //       if (reportGrid[item].data.filtersAll[i]) {
      //         const filter = {
      //           'key': i,
      //           'values': reportGrid[item].data.filtersAll[i].values,
      //           'filter': reportGrid[item].data.filtersAll[i].filter };
      //         filters.push(filter);
      //         isFilters = true;
      //       }
      //     }
      //     break;
      //   }
      // }
    } else if (Object.keys(this.filters).length > 0) {
      isFilters = true;
      for (const filterKey in this.filters) {
        const filter = {
          key: filterKey,
          filter: this.filters[filterKey].filter,
          values: this.filters[filterKey].values,
          textFilter: this.filters[filterKey].textFilter || false,
        };
        if (this.filters[filterKey]['customLabels']) {
          filter['customLabels'] = this.filters[filterKey]['customLabels'];
        }
        filters.push(filter);
        this.removeCrossfilterDimension(filterKey);
      }
    }

    if (isFilters) {
      filters = filters.filter((filter, index, self) => self.findIndex((f) => f.key === filter.key) === index);
      this.filter(filters);
    } else {
      this.sendNewCrossfilter();
    }
  }

  sendNewCrossfilter() {
    this.loading.next(false);
    this.crossfilter.next({
      base: this.baseDimension,
      dimensions: this.dimensions,
      gridItems: this.gridItems,
      filters: this.filters,
      stats: {
        respondents: this.baseDimension.top(Infinity).length,
        respondentsAll: this.answerData && this.answerData.answers ? this.answerData.answers.length : 0,
        sampleData: this.answerData ? this.answerData.sampleData : true,
        comparison: this.comparisonModeOn,
        consensus: this.consensus,
      },
      other: {
        newAnswersAvailable: this.newAnswersAvailable,
        filtersDemo: this.filtersDemo,
      },
    });
    this.updatingAnswers.complete();
  }

  /**
   * Setting and parsing dimensions from questions
   */
  setDimensions() {
    for (const survey in this.questions) {
      const zLocalGroups = this.dp.generateLocalZScoreGroups(this.questions[survey]);
      const zGlobalGroups = this.dp.generateGlobalZScoreGroups(this.questions[survey]);
      const reducedSteps = this.dp.generateSliderStepReducer(this.questions[survey]);

      for (const question of this.questions[survey]) {
        const key = question.$key;
        const longKey = survey + '/' + key;
        const type = question.type;
        const otherOptions: number =
          Questions.isChoice(question) && question.choiceList
            ? question.choiceList.filter((item) => item.comment).length
            : 0;
        const isTextInput: boolean = type === Questions.FREE_TEXT || type === Questions.INPUT_STRING;
        const dimensions =
          type === Questions.SLIDER_2D || type === Questions.SLIDER_1R
            ? 2
            : otherOptions > 0
            ? 1 + otherOptions
            : isTextInput
            ? 4
            : 1;

        if (
          survey === this.key ||
          (this.mergeSettings[survey] &&
            this.mergeSettings[survey][key] &&
            !this.mergeSettings[survey][key]['question'])
        ) {
          for (let i = 0; i < dimensions; i++) {
            const dimension = {} as DimensionDataItem;

            dimension['key'] = longKey;
            dimension['originalType'] = type;
            dimension['order'] = question.order;
            dimension['title'] = question.title;
            dimension['survey'] = survey;
            dimension['locales'] =
              this.locales && this.locales[survey] && this.locales[survey]['config']
                ? Object.keys(this.locales[survey]['config'])
                : [];
            dimension['localeStrings'] = {};
            dimension['group'] = question.group ? survey + '/' + question.group : '';

            if (question.group) {
              for (const group of this.questions[survey]) {
                if (question.group === group.$key) {
                  dimension['groupIntelligent'] = group.type === Questions.GROUP_SCORED;
                  dimension['groupLabel'] = group.title;
                }
              }
            }

            const values: (string | number)[] = [];
            let labelsLinear: SliderLabelsData = new SliderLabelsData();
            let valueScaleLinear: SliderValuesData = new SliderValuesData();

            const labelsCategorical = {};
            const imagesCategorical = {};

            const groupIndex = this.questions[survey].findIndex((x) => x.$key === question.group);

            if (type === Questions.SLIDER_2D) {
              dimension['key'] += `:${i}`;
              dimension['scale'] = 'linear';
              dimension['originalType'] = Questions.SLIDER_2D;
              dimension['originalTypeSpecifier'] = i === 0 ? 'x-axis' : 'y-axis';

              if (
                question.group &&
                this.questions[survey][groupIndex] &&
                this.questions[survey][groupIndex].type === Questions.GROUP_SCORED
              ) {
                labelsLinear =
                  i === 0
                    ? this.questions[survey][groupIndex].sliderLabelsX
                      ? this.questions[survey][groupIndex].sliderLabelsX
                      : new SliderLabelsData()
                    : this.questions[survey][groupIndex].sliderLabelsY
                    ? this.questions[survey][groupIndex].sliderLabelsY
                    : new SliderLabelsData();
                valueScaleLinear =
                  i === 0
                    ? this.questions[survey][groupIndex].sliderValuesX
                      ? this.questions[survey][groupIndex].sliderValuesX
                      : new SliderValuesData()
                    : this.questions[survey][groupIndex].sliderValuesY
                    ? this.questions[survey][groupIndex].sliderValuesY
                    : new SliderValuesData();
              } else {
                labelsLinear =
                  i === 0
                    ? question.sliderLabelsX
                      ? question.sliderLabelsX
                      : new SliderLabelsData()
                    : question.sliderLabelsY
                    ? question.sliderLabelsY
                    : new SliderLabelsData();
                valueScaleLinear =
                  i === 0
                    ? question.sliderValuesX
                      ? question.sliderValuesX
                      : new SliderValuesData()
                    : question.sliderValuesY
                    ? question.sliderValuesY
                    : new SliderValuesData();
              }

              if (i === 0 && reducedSteps[key] && reducedSteps[key].x != null) {
                valueScaleLinear = Object.assign({}, valueScaleLinear);
                valueScaleLinear.step = reducedSteps[key].x;
              } else if (i === 1 && reducedSteps[key] && reducedSteps[key].y != null) {
                valueScaleLinear = Object.assign({}, valueScaleLinear);
                valueScaleLinear.step = reducedSteps[key].y;
              }

              dimension['labelsLinear'] = labelsLinear;
              dimension['valueScaleLinear'] = valueScaleLinear;

              // Here we parse Z-scoring comparison partners
              const groupId = this.dp.generateGroupId(question, this.questions[survey]);
              if (question.group) {
                if (zLocalGroups[question.group] && zLocalGroups[question.group][groupId]) {
                  dimension['localZComparison'] = [];
                  for (const item of zLocalGroups[question.group][groupId]) {
                    dimension['localZComparison']!.push(survey + '/' + item + `:${i}`);
                  }
                }
              }
              if (zGlobalGroups[groupId]) {
                dimension['globalZComparison'] = [];
                for (const item of zGlobalGroups[groupId]) {
                  dimension['globalZComparison']!.push(survey + '/' + item + `:${i}`);
                }
              }
            } else if (type === Questions.SLIDER_1R) {
              dimension['key'] += `:${i}`;
              dimension['scale'] = 'linear';
              dimension['originalType'] = Questions.SLIDER_1R;
              dimension['originalTypeSpecifier'] = i === 0 ? 'min' : 'max';

              labelsLinear = question.sliderLabels ? question.sliderLabels : new SliderLabelsData();
              valueScaleLinear = question.sliderValues ? question.sliderValues : new SliderValuesData();

              if (reducedSteps[key] && reducedSteps[key].x != null) {
                valueScaleLinear = Object.assign({}, valueScaleLinear);
                valueScaleLinear.step = reducedSteps[key].x;
              }

              dimension['labelsLinear'] = labelsLinear;
              dimension['valueScaleLinear'] = valueScaleLinear;
            } else if (
              type === Questions.SLIDER_1D ||
              type === Questions.SLIDER_1V ||
              type === Questions.INPUT_NUMERIC
            ) {
              dimension['scale'] = 'linear';
              dimension['originalType'] = type;

              if (
                question.group &&
                type !== Questions.INPUT_NUMERIC &&
                this.questions[survey][groupIndex] &&
                this.questions[survey][groupIndex].type === Questions.GROUP_SCORED
              ) {
                labelsLinear = this.questions[survey][groupIndex].sliderLabels
                  ? this.questions[survey][groupIndex].sliderLabels
                  : new SliderLabelsData();
                valueScaleLinear = this.questions[survey][groupIndex].sliderValues
                  ? this.questions[survey][groupIndex].sliderValues
                  : new SliderValuesData();
              } else {
                labelsLinear = question.sliderLabels ? question.sliderLabels : new SliderLabelsData();
                valueScaleLinear = question.sliderValues ? question.sliderValues : new SliderValuesData();
              }

              if (reducedSteps[key] && reducedSteps[key].x != null) {
                valueScaleLinear = Object.assign({}, valueScaleLinear);
                valueScaleLinear.step = reducedSteps[key].x;
              }

              dimension['labelsLinear'] = labelsLinear;
              dimension['valueScaleLinear'] = valueScaleLinear;

              // Here we parse Z-scoring comparison partners
              const groupId = this.dp.generateGroupId(question, this.questions[survey]);
              if (question.group && type !== Questions.INPUT_NUMERIC) {
                if (zLocalGroups[question.group] && zLocalGroups[question.group][groupId]) {
                  dimension['localZComparison'] = [];
                  for (const item of zLocalGroups[question.group][groupId]) {
                    dimension['localZComparison']!.push(survey + '/' + item);
                  }
                }
              }
              if (zGlobalGroups[groupId]) {
                dimension['globalZComparison'] = [];
                for (const item of zGlobalGroups[groupId]) {
                  dimension['globalZComparison']!.push(survey + '/' + item);
                }
              }
            } else if (type === Questions.SLIDER_NPS) {
              dimension['scale'] = 'linear';
              dimension['originalType'] = type;

              labelsLinear = question.sliderLabels ? question.sliderLabels : new SliderLabelsData();
              valueScaleLinear = {
                min: 0,
                max: 10,
                step: 1,
                initial: 0.5,
                visible: true,
              };

              dimension['labelsLinear'] = labelsLinear;
              dimension['valueScaleLinear'] = valueScaleLinear;
            } else if (Questions.category(question, Questions.CHOICE) || type === Questions.INPUT_DROPDOWN) {
              dimension['scale'] = i > 0 ? 'text' : 'categorical';
              if (i === 0) {
                for (let v = 0, lenv = question.choiceList ? question.choiceList.length : 0; v < lenv; v++) {
                  const val: string = survey + '/' + question.choiceList[v].$key;
                  values.push(val);
                  labelsCategorical[val] = question.choiceList[v].content;

                  if (type === Questions.CHOICE_PICTURE && question.choiceList[v].imageStyle) {
                    imagesCategorical[val] = {
                      mode: question.choiceList[v].imageStyle.mode,
                      thumb: question.choiceList[v].imageStyle.thumb,
                    };
                  }
                }

                for (const s in this.mergeSettings) {
                  for (const q in this.mergeSettings[s]) {
                    if (
                      this.mergeSettings[s][q]['choices'] &&
                      this.mergeSettings[s][q]['question'] &&
                      this.mergeSettings[s][q]['question']['$key'] === key &&
                      this.mergeSettings[s][q]['choices'].filter(
                        (item) => item && item.mappedTo && item.mappedTo.manualMerge,
                      ).length
                    ) {
                      const newItems = this.mergeSettings[s][q]['choices']
                        .filter((item) => item && item.mappedTo && item.mappedTo.manualMerge)
                        .map((item) => item.orig);

                      for (let v = 0, lenv = newItems.length; v < lenv; v++) {
                        const val: string = s + '/' + newItems[v].$key;
                        values.push(val);
                        labelsCategorical[val] = newItems[v].content;
                        if (type === Questions.CHOICE_PICTURE && newItems[v].imageStyle) {
                          imagesCategorical[val] = {
                            mode: newItems[v].imageStyle.mode,
                            thumb: newItems[v].imageStyle.thumb,
                          };
                        }
                      }
                    }
                  }
                }

                dimension['labelsCategorical'] = labelsCategorical;
                dimension['imagesCategorical'] = imagesCategorical;
              } else {
                const choiceKey: string = question.choiceList.filter((item) => item.comment)[i - 1]['$key'];
                const choiceContent: string = question.choiceList.filter((item) => item.comment)[i - 1]['content'];
                dimension['originalTypeSpecifier'] = 'choice-comment';
                dimension['key'] += `:${choiceKey}`;
                dimension['title'] = `${question.title} - ${choiceContent}`;
                dimension['order'] += 0.0000000001;
              }
            } else if (type === Questions.INPUT_CHECKBOX) {
              dimension['scale'] = 'categorical';
              values.push('true', 'false');
              dimension['labelsCategorical'] = { true: '☑', false: '☐' };
            } else if (type === Questions.INPUT_STRING || type === Questions.FREE_TEXT) {
              if (i === 3) {
                dimension['originalTypeSpecifier'] = 'text-sentiment';
                dimension['scale'] = 'linear';
                dimension['key'] += `:${'sentiment'}`;
                dimension['order'] += 0.0000000001;
                dimension['title'] = `${question.title} - ${$localize`:@@zef-i18n-00531:sentiment`}`;
                labelsLinear = new SliderLabelsData();
                labelsLinear['min'] = '-10';
                labelsLinear['max'] = '10';
                valueScaleLinear = {
                  min: -10,
                  max: 10,
                  step: 1,
                  initial: 0,
                  visible: true,
                };
                dimension['labelsLinear'] = labelsLinear;
                dimension['valueScaleLinear'] = valueScaleLinear;

                for (let sentimentVal = -10; sentimentVal < 11; sentimentVal++) {
                  values.push(sentimentVal);
                }
              } else if (i === 2) {
                dimension['originalTypeSpecifier'] = 'text-words';
                dimension['scale'] = 'categorical';
                dimension['key'] += `:${'words'}`;
                dimension['order'] += 0.0000000001;
                dimension['title'] = `${question.title} - ${$localize`:@@zef-i18n-00526:words`}`;

                if (!this.dimensions[dimension['key']] || !this.dimensions[dimension['key']]['valueGroupKeys']) {
                  dimension['valueGroupKeys'] = ['NOUN', 'ADJ', 'VERB', 'ADV'];
                }
                if (!this.dimensions[dimension['key']] || !this.dimensions[dimension['key']]['valueGroupTypes']) {
                  dimension['valueGroupTypes'] = ['PoS', 'PoS', 'PoS', 'PoS'];
                }
                if (!this.dimensions[dimension['key']] || !this.dimensions[dimension['key']]['valueGroupValues']) {
                  dimension['valueGroupValues'] = [[], [], [], []];
                }
                if (
                  !this.dimensions[dimension['key']] ||
                  (this.dimensions[dimension['key']] && !this.dimensions[dimension['key']]['labelsValueGroup'])
                ) {
                  dimension['labelsValueGroup'] = {
                    NOUN: $localize`:@@zef-i18n-00527:Nouns`,
                    ADJ: $localize`:@@zef-i18n-00528:Adjectives`,
                    VERB: $localize`:@@zef-i18n-00529:Verbs`,
                    ADV: $localize`:@@zef-i18n-00530:Adverbs`,
                  };
                }
              } else if (i === 1) {
                dimension['originalTypeSpecifier'] = 'text-emotions';
                dimension['scale'] = 'categorical';
                dimension['key'] += `:emotions`;
                dimension['order'] += 0.0000000001;
                dimension['title'] = `${question.title} - ${$localize`emotions`}`;
              } else {
                dimension['scale'] = 'text';
              }
            } else {
              dimension['scale'] = 'text';
            }

            if (Questions.category(question, Questions.SLIDER) || type === Questions.INPUT_NUMERIC) {
              const min = !valueScaleLinear || valueScaleLinear.min == null ? 0 : valueScaleLinear.min;
              const max = !valueScaleLinear || valueScaleLinear.max == null ? 100 : valueScaleLinear.max;
              const step = !valueScaleLinear || valueScaleLinear.step == null ? 1 : valueScaleLinear.step;

              const stepper = Math.round((max - min) / step);
              const rounder = Math.pow(10, 5);

              for (let val = 0; val <= stepper; val++) {
                const convertedVal = Math.round((Math.round(val * step * rounder) / rounder + min) * rounder) / rounder;
                values.push(convertedVal);
              }
            }

            if (
              (type === Questions.INPUT_STRING || type === Questions.FREE_TEXT) &&
              this.answerData &&
              this.answerData.textLabels &&
              this.answerData.textLabels[dimension['key']] &&
              this.answerData.textLabels[dimension['key']].length > 0
            ) {
              if (
                this.dimensions &&
                this.dimensions[dimension['key']] &&
                this.dimensions[dimension['key']]['values'] &&
                this.dimensions[dimension['key']]['values'].length > 0
              ) {
                dimension['values'] = this.dimensions[dimension['key']]['values'];
              } else {
                dimension['values'] = Object.keys(this.answerData.textLabels[dimension['key']]).map((index) =>
                  Number(index),
                );
              }
            } else {
              dimension['values'] = values;
            }

            if (Questions.category(question, Questions.GROUP) === false) {
              if (!this.dimensions[dimension['key']]) {
                this.dimensions[dimension['key']] = dimension;
              } else {
                for (const prop in dimension) {
                  this.dimensions[dimension['key']][prop] = dimension[prop];
                }
              }
            }
          }
        }
      }
    }

    /** Here we parse and create other dimensions needed **/

    // outcomes
    const outcomes: string[] = [];
    const outcomeLabels = {};

    for (const survey in this.outcomes) {
      for (const i in this.outcomes[survey]) {
        const longKey: string = survey + '/' + this.outcomes[survey][i].$key;
        outcomes.push(longKey);
        outcomeLabels[longKey] = this.outcomes[survey][i].title;
      }
    }

    if (!this.dimensions['outcome']) {
      this.dimensions['outcome'] = {} as DimensionDataItem;
    }
    this.dimensions['outcome'].values = outcomes;
    this.dimensions['outcome'].labelsCategorical = outcomeLabels;
    this.dimensions['outcome'].scale = 'categorical';
    this.dimensions['outcome'].title = $localize`:@@zef-i18n-00343:Outcomes`;
    this.dimensions['outcome'].originalType = 'outcome';
    this.dimensions['outcome'].survey = this.key;
    this.dimensions['outcome'].key = 'outcome';

    // sharelinks & hashtags
    const shareLinkValues: string[] = [];
    const shareLinkLabels: { [s: string]: string } = {};
    const hashtagValues: any = new Set();
    const hashtagLabels: { [s: string]: string } = {};

    for (const survey in this.release) {
      let defaultLinkKey: string = '';

      if (this.release[survey] && this.release[survey].linkKey) {
        defaultLinkKey = this.release[survey].linkKey;
      } else if (this.release[survey] && this.release[survey].url && this.release[survey].url.replace(/\/$/, '')) {
        defaultLinkKey = this.release[survey].url
          .replace(/\/$/, '')
          .replace(`${environment.publicUrl}/s/`, '')
          .replace(`${environment.surveyAddress}/`, '');
      }

      const shareLinks: ShareLinkData[] =
        this.release[survey] && this.release[survey].shareLinks ? this.release[survey].shareLinks : [];

      if (defaultLinkKey) {
        shareLinkValues.push(defaultLinkKey);
        shareLinkLabels[defaultLinkKey] =
          $localize`:@@zef-i18n-00344:Default link` +
          (survey !== this.key && (this.includedSurveys || []).find((s) => survey === s['$key'])
            ? ` (${this.includedSurveys.find((s) => survey === s['$key'])['name']})`
            : '');
      }

      for (let i = 0, len = shareLinks.length; i < len; i++) {
        const link: ShareLinkData = shareLinks[i];
        const hashtags: string[] = link.tags?.split(',') || [];
        const linkKey: string = link.linkKey
          ? link.linkKey
          : link.url
              .replace(/\/$/, '')
              .replace(`${environment.publicUrl}/s/`, '')
              .replace(`${environment.surveyAddress}/`, '');
        shareLinkValues.push(linkKey);
        shareLinkLabels[linkKey] =
          link.name +
          (survey !== this.key && (this.includedSurveys || []).find((s) => survey === s['$key'])
            ? ` (${this.includedSurveys.find((s) => survey === s['$key'])['name']})`
            : '');

        for (let h = 0, lenh = hashtags.length; h < lenh; h++) {
          if (hashtags[h]) {
            hashtagValues.add(hashtags[h]);
            hashtagLabels[hashtags[h]] = hashtags[h];
          }
        }
      }
    }

    if (!this.dimensions['shareLink']) {
      this.dimensions['shareLink'] = {} as DimensionDataItem;
    }
    this.dimensions['shareLink'].values = shareLinkValues;
    this.dimensions['shareLink'].labelsCategorical = shareLinkLabels;
    this.dimensions['shareLink'].scale = 'categorical';
    this.dimensions['shareLink'].title = $localize`:@@zef-i18n-00345:Share links`;
    this.dimensions['shareLink'].originalType = 'shareLink';
    this.dimensions['shareLink'].key = 'shareLink';
    this.dimensions['shareLink'].survey = this.key;

    if (!this.dimensions['hashtags']) {
      this.dimensions['hashtags'] = {} as DimensionDataItem;
      this.dimensions['hashtags'].values = Array.from(hashtagValues);
      this.dimensions['hashtags'].labelsCategorical = hashtagLabels;
      this.dimensions['hashtags'].scale = 'categorical';
      this.dimensions['hashtags'].title = 'Hashtags';
      this.dimensions['hashtags'].originalType = 'hashtags';
      this.dimensions['hashtags'].key = 'hashtags';
      this.dimensions['hashtags'].survey = this.key;
    }
    this.dimensions['hashtags'].survey = this.key;

    // languages
    const localesValues: string[] = [];
    const localesCategorical: { [s: string]: string } = {};

    for (const survey in this.locales) {
      const locales =
        this.locales[survey] && this.locales[survey].config ? Object.keys(this.locales[survey].config) : [];

      for (let i = 0, len = locales.length; i < len; i++) {
        if (localesValues.indexOf(locales[i]) < 0) {
          localesValues.push(locales[i]);
          localesCategorical[locales[i]] = this.locales[survey].config[locales[i]].name;
        }
      }
    }

    if (!this.dimensions['lang']) {
      this.dimensions['lang'] = {} as DimensionDataItem;
    }
    this.dimensions['lang'].values = localesValues;
    this.dimensions['lang'].labelsCategorical = localesCategorical;
    this.dimensions['lang'].scale = 'categorical';
    this.dimensions['lang'].title = $localize`:@@zef-i18n-00346:Language`;
    this.dimensions['lang'].originalType = 'lang';
    this.dimensions['lang'].key = 'lang';
    this.dimensions['lang'].survey = this.key;

    // time
    if (!this.dimensions['time']) {
      this.dimensions['time'] = {} as DimensionDataItem;
      this.dimensions['time'].scale = 'time';
      this.dimensions['time'].title = $localize`:@@zef-i18n-00347:Timeline`;
      this.dimensions['time'].originalType = 'time';
      this.dimensions['time'].key = 'time';
    }
    this.dimensions['time'].survey = this.key;

    // User segments
    if (!this.dimensions['userSegments']) {
      this.dimensions['userSegments'] = {} as DimensionDataItem;
      this.dimensions['userSegments'].values = [];
      this.dimensions['userSegments'].labelsCategorical = {};
      this.dimensions['userSegments'].scale = 'categorical';
      this.dimensions['userSegments'].title = $localize`:@@zef-i18n-00348:Segments`;
      this.dimensions['userSegments'].originalType = 'userSegments';
      this.dimensions['userSegments'].key = 'userSegments';
      this.dimensions['userSegments'].customOrigins = {};
      this.dimensions['userSegments'].customValuesOrder = [];
      this.dimensions['userSegments'].customColors = {};
    }
    this.dimensions['userSegments'].survey = this.key;

    // Surveys
    if (!this.dimensions['survey']) {
      this.dimensions['survey'] = {} as DimensionDataItem;
    }
    this.dimensions['survey'].values = [];
    this.dimensions['survey'].labelsCategorical = {};

    for (let s = 0, lens = this.includedSurveys.length; s < lens; s++) {
      const valKey = this.includedSurveys[s]['$key'];
      this.dimensions['survey'].values.push(valKey);
      this.dimensions['survey'].labelsCategorical[valKey] = this.includedSurveys[s]['name'];
    }

    this.dimensions['survey'].scale = 'categorical';
    this.dimensions['survey'].title = $localize`:@@zef-i18n-00349:Surveys`;
    this.dimensions['survey'].originalType = 'survey';
    this.dimensions['survey'].key = 'survey';
    this.dimensions['survey'].customOrigins = {};
    this.dimensions['survey'].customValuesOrder = [];
    this.dimensions['survey'].customColors = {};
    this.dimensions['survey'].survey = this.key;

    // Survey reviews
    if (!this.dimensions['zefSurveyUserRating'] && this.includedSurveys.find((survey) => survey?.funnel)) {
      this.dimensions['zefSurveyUserRating'] = {} as DimensionDataItem;
      this.dimensions['zefSurveyUserRating'].values = ['1', '0'];
      this.dimensions['zefSurveyUserRating'].labelsCategorical = { 0: '👎', 1: '👍' };
      this.dimensions['zefSurveyUserRating'].scale = 'categorical';
      this.dimensions['zefSurveyUserRating'].title = 'Did you like answering this survey?';
      this.dimensions['zefSurveyUserRating'].originalType = 'zefSurveyUserRating';
      this.dimensions['zefSurveyUserRating'].key = 'zefSurveyUserRating';
      this.dimensions['zefSurveyUserRating'].survey = this.key;
    } else if (this.includedSurveys.find((survey) => survey?.funnel)) {
      this.dimensions['zefSurveyUserRating'].survey = this.key;
    }

    this.setRespondentFieldDimensions();

    // Setting locale strings to dimensions
    const customTranslationDimensions: string[] = [
      'userSegments',
      'time',
      'lang',
      'shareLink',
      'outcome',
      'zefSurveyUserRating',
    ];
    for (const survey in this.locales) {
      if (
        this.locales[survey] &&
        this.locales[survey].strings &&
        Object.keys(this.locales[survey].strings).length > 0
      ) {
        for (const dim in this.dimensions) {
          if (this.dimensions[dim]['survey'] === survey || customTranslationDimensions.indexOf(dim) >= 0) {
            this.setDimensionLocaleStrings(this.dimensions[dim], this.locales[survey].strings, survey);
          }
        }
      }
    }
  }

  private setRespondentFieldDimensions() {
    // Setting dimensions for respondent fields
    if (!this.anonymityTreshold) {
      for (const key of ['email', 'phone', 'firstName', 'lastName', 'name']) {
        const entry: string = 'fields-' + key;
        if (!this.dimensions[entry]) {
          this.dimensions[entry] = {
            scale: 'contact-text',
            title:
              key === 'email'
                ? $localize`:@@zef-i18n-contact-email:Email`
                : key === 'phone'
                ? $localize`Phone`
                : key === 'firstName'
                ? $localize`First name`
                : key === 'lastName'
                ? $localize`Last name`
                : key === 'name'
                ? $localize`Name`
                : key,
            originalType: 'respondent-field',
            key: entry,
            survey: this.key,
            values: [],
            labelsCategorical: {},
          } as DimensionDataItem;
        }
      }
    }

    for (const survey in this.respondentFields) {
      for (let i = 0, len = this.respondentFields[survey]?.length; i < len; i++) {
        const field = this.respondentFields[survey][i];
        const fieldKey = 'fields-' + field?.$key;

        if (field?.$key) {
          if (!this.dimensions[fieldKey]) {
            this.dimensions[fieldKey] = {} as DimensionDataItem;
          }

          if (!this.dimensions[fieldKey].values) {
            this.dimensions[fieldKey].values = [];
          }

          if (!this.dimensions[fieldKey].labelsCategorical) {
            this.dimensions[fieldKey].labelsCategorical = {};
          }

          this.dimensions[fieldKey].scale = isContactEntry(field.$key)
            ? 'contact-text'
            : field.type === 'numeric'
            ? 'linear'
            : field.type === 'date'
            ? 'time'
            : !field.arrayCount &&
              ((field.uniqueCount > 100 && field.uniqueCount > field.totalCount * 0.8) ||
                (field.uniqueCount > 30 && field.uniqueCount > field.totalCount * 0.95))
            ? 'contact-text'
            : 'categorical';
          this.dimensions[fieldKey].title = field.name;
          this.dimensions[fieldKey].originalType = 'respondent-field';
          this.dimensions[fieldKey].key = fieldKey;
          this.dimensions[fieldKey].survey = survey;

          if (this.anonymityTreshold && this.dimensions[fieldKey].scale === 'categorical') {
            this.dimensions[fieldKey]['labelsCategorical']['__masked__'] = $localize`Small groups`;
          }

          if (field.type === 'numeric') {
            this.dimensions[fieldKey]['labelsLinear'] = new SliderLabelsData();
            this.dimensions[fieldKey]['labelsLinear']['axis'] = field.name;
            this.dimensions[fieldKey]['valueScaleLinear'] = {
              min: Math.max(...this.dimensions[fieldKey].values.map((val) => Number(val))),
              max: Math.min(...this.dimensions[fieldKey].values.map((val) => Number(val))),
              step: null,
              initial: 0,
              visible: true,
            };
          }
        }
      }
    }
  }

  private setDimensionLocaleStrings(dimension: DimensionDataItem, localeStrings: TranslationsData, survey: string) {
    const customTranslationKeys: { [s: string]: string } = {
      userSegments: 'reportSegments',
      time: 'reportTimeline',
      lang: 'reportLanguage',
      shareLink: 'reportShareLinks',
      outcome: 'reportOutcomes',
      zefSurveyUserRating: 'zefSurveyUserRatingQuestion',
    };
    const parseKey: (string) => string = (key) => (key && key.indexOf('/') >= 0 ? key.split('/')[1] : key);
    const questionKey: string = parseKey(dimension['key']).split(':')[0];
    const customKey: string = customTranslationKeys[questionKey] || '';

    if (!dimension['localeStrings']) {
      dimension['localeStrings'] = {} as LocaleStrings;
    }

    for (const locale in localeStrings) {
      if (!dimension['localeStrings'][locale]) {
        dimension['localeStrings'][locale] = {} as LocaleString;
      }

      const strings: TranslationData = localeStrings[locale];

      const otherOptionTitle: string =
        dimension['originalTypeSpecifier'] === 'choice-comment'
          ? strings[`choice-${questionKey}-${parseKey(dimension['key'].split(':')?.[1])}`]
          : '';

      // title
      const title: string = strings[customKey || `title-${questionKey}`];
      if (title) {
        dimension['localeStrings'][locale]['title'] = title + (otherOptionTitle ? ` - ${otherOptionTitle}` : '');
      }

      // labelsLinear
      if (dimension.labelsLinear) {
        const labelsLinear: SliderLabelsData = {} as SliderLabelsData;
        const specifier: string =
          dimension.originalTypeSpecifier === 'x-axis'
            ? '-X'
            : dimension.originalTypeSpecifier === 'y-axis'
            ? '-Y'
            : '';
        const linearKey: string =
          dimension.groupIntelligent && dimension.originalType !== Questions.SLIDER_NPS
            ? parseKey(dimension.group)
            : questionKey;

        for (const item of Object.keys(dimension.labelsLinear)) {
          if (item === 'min' || item === 'max' || item === 'axis') {
            const translation = strings[`${item + specifier}-${linearKey}`];
            if (translation) {
              labelsLinear[item] = translation;
            }
          }
        }

        if (Object.keys(labelsLinear).length > 0) {
          dimension['localeStrings'][locale]['labelsLinear'] = labelsLinear;
        }
      }

      // labelsCategorical
      if (dimension.labelsCategorical) {
        const labelsCategorical: { [key: string]: string } = {};

        for (const item of Object.keys(dimension.labelsCategorical)) {
          const choiceKey: string =
            questionKey === 'outcome' ? `title-${item}` : `choice-${questionKey}-${parseKey(item)}`;
          const defaultLinkKey: string =
            questionKey === 'shareLink' &&
            this.release &&
            this.release[survey] &&
            this.release[survey].url &&
            (this.release[survey].url.indexOf(item) >= 0 || item.indexOf(this.release[survey].url) >= 0)
              ? 'reportDefaultLink'
              : '';

          const isEmotion: boolean = dimension.key?.indexOf(':emotions') >= 0;
          const translation: string = !isEmotion
            ? strings[!defaultLinkKey ? choiceKey : defaultLinkKey]
            : Emotions[Emotions[locale] ? locale : 'en'][item] || item;
          if (translation) {
            labelsCategorical[item] = translation;
          }
        }

        if (Object.keys(labelsCategorical).length > 0) {
          if (!dimension['localeStrings'][locale]['labelsCategorical']) {
            dimension['localeStrings'][locale]['labelsCategorical'] = {};
          }

          for (const labelc in labelsCategorical) {
            dimension['localeStrings'][locale]['labelsCategorical'][labelc] = labelsCategorical[labelc];
          }
        }
      }

      // groupLabel
      if (dimension.group) {
        const groupLabel: string = strings[`title-${parseKey(dimension.group)}`];

        if (groupLabel) {
          dimension['localeStrings'][locale]['groupLabel'] = groupLabel;
        }
      }
    }
  }

  /**
   * Changing all translated texts to selected locale
   */
  public changeLocale(locale: string) {
    this.activeLocale = locale;

    for (const item in this.gridItems) {
      this.gridItems[item]['activeLocale'] = locale;
    }
    this.sendNewCrossfilter();
  }

  /**
   * Creating default report grid items through questions.
   *
   * @returns  gridItems.
   */
  setDefaultGridItems() {
    const gridItems = {};

    // looping questions
    for (const survey in this.questions) {
      const slider2dCount = (this.questions[survey] || []).filter((q) => q && q.type === Questions.SLIDER_2D).length;

      for (const question of this.questions[survey]) {
        const key = question.$key;
        const longKey = survey + '/' + key;
        const type = question.type;
        const dimensions: string[] = [];
        let hasSentimentValues: boolean = false;
        let hasEmotions: boolean = false;
        let hasSummaryRights: boolean = false;

        if (type === Questions.SLIDER_2D) {
          dimensions.push(longKey + ':0', longKey + ':1');
        } else if (type === Questions.SLIDER_1R) {
          dimensions.push(longKey + ':0', longKey + ':1');
        } else if (type === Questions.FREE_TEXT || type === Questions.INPUT_STRING) {
          const hasEmotionsRights = this.st?.selectSnapshot(AccountState.extensions)?.indexOf('languageEmotion') >= 0;
          hasSummaryRights = this.st?.selectSnapshot(AccountState.extensions)?.indexOf('textSummary') >= 0;

          for (let i = 0, len = this.answerData?.answers?.length; i < len; i++) {
            if (
              this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':sentiment')] &&
              this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':sentiment')][0] != null
            ) {
              hasSentimentValues = true;
            }
            if (
              hasEmotionsRights &&
              this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':emotions')] &&
              this.answerData.answers[i][this.answerData.indexes.indexOf(longKey + ':emotions')]?.[0]?.length
            ) {
              hasEmotions = true;
            }
            if (hasSentimentValues && (!hasEmotionsRights || hasEmotions)) {
              break;
            }
          }

          if (hasSentimentValues) {
            dimensions.push(longKey + ':sentiment');
          }

          dimensions.push(longKey);
        } else if (Questions.category(question, Questions.GROUP)) {
          for (const groupCandidate of this.questions[survey]) {
            if (groupCandidate.group === key && Questions.category(groupCandidate, Questions.GROUP) === false) {
              if (groupCandidate.type === Questions.SLIDER_2D) {
                dimensions.push(survey + '/' + groupCandidate.$key + ':0', survey + '/' + groupCandidate.$key + ':1');
              } else if (groupCandidate.type === Questions.SLIDER_1R) {
                dimensions.push(survey + '/' + groupCandidate.$key + ':0', survey + '/' + groupCandidate.$key + ':1');
              } else {
                dimensions.push(survey + '/' + groupCandidate.$key);
              }
            }
          }
        } else {
          dimensions.push(longKey);
        }
        if (dimensions.length > 0) {
          // For performance reasons we hide heatmap charts for Slider 2D questions if there is lots of them
          const hide = slider2dCount > 70 && type === Questions.SLIDER_2D;

          if (hasSentimentValues) {
            gridItems[longKey + ':sentiment'] = this.gridItemGenerate(dimensions, hide);
            gridItems[longKey + ':words'] = this.gridItemGenerate([longKey + ':words'], hide);

            if (hasSummaryRights) {
              gridItems[longKey] = this.gridItemGenerate([longKey], hide);
            }
          } else {
            gridItems[longKey] = this.gridItemGenerate(dimensions, hide);
          }

          if (hasEmotions) {
            gridItems[longKey + ':emotions'] = this.gridItemGenerate([longKey + ':emotions', longKey], hide);
          }
        }
      }
    }

    // looping custom dimensions
    const customDimensions: string[] = Object.keys(this.dimensions).filter((dim) => dim.split(':').length > 1);

    for (let i = 0, len = customDimensions.length; i < len; i++) {
      if (this.dimensions[customDimensions[i]].originalTypeSpecifier === 'choice-comment') {
        gridItems[customDimensions[i]] = this.gridItemGenerate([customDimensions[i]]);
      }
    }

    // looping contact property dimensions
    const fieldDimensions: string[] = Object.keys(this.dimensions).filter((dim) => isField(dim));
    const fieldEntrySummaryProperties: string[] = [
      'fields-email',
      'fields-phone',
      'fields-firstName',
      'fields-lastName',
      'fields-name',
    ];

    for (let i = 0, len = fieldDimensions.length; i < len; i++) {
      if (
        this.dimensions[fieldDimensions[i]]?.originalType === 'respondent-field' &&
        !isContactEntry(fieldDimensions[i]) &&
        !!this.respondentFields?.[this.dimensions[fieldDimensions[i]]?.['survey']]?.find(
          (f) => f.$key === fieldDimensions[i].substring(7),
        )?.totalCount
      ) {
        gridItems[fieldDimensions[i]] = this.gridItemGenerate([fieldDimensions[i]]);
      }
    }

    if (
      Object.keys(this.dimensions).some((dim) => fieldEntrySummaryProperties.includes(dim)) &&
      !this.anonymityTreshold
    ) {
      this.updateFieldsEntrySummary(gridItems);
    }

    // const summaries = this.setSummaryGridItem();
    //
    // for (let s = 0, len = summaries.length; s < len; s++) {
    //   gridItems[`summary${s}`] = this.gridItemGenerate(summaries[s]);
    // }

    gridItems['time'] = this.gridItemGenerate(['time']);

    for (const survey in this.outcomes) {
      if (
        this.outcomes[survey].filter((item) => item.type === 'outcome').length > 0 &&
        !(
          this.outcomes[survey].filter((item) => item.type === 'outcome').length === 1 &&
          !this.outcomes[survey].filter((item) => item.type === 'outcome')[0].title
        )
      ) {
        gridItems['outcome'] = this.gridItemGenerate(['outcome']);
        break;
      }
    }

    if (this.dimensions['hashtags']?.values?.length > 0) {
      gridItems['hashtags'] = this.gridItemGenerate(['hashtags']);
    }
    if (this.dimensions['lang']?.values?.length > 1) {
      gridItems['lang'] = this.gridItemGenerate(['lang']);
    }
    if (this.dimensions['shareLink']?.values?.length > 1) {
      gridItems['shareLink'] = this.gridItemGenerate(['shareLink']);
    }
    if (this.dimensions['survey'] && this.dimensions['survey'].values?.length > 1) {
      gridItems['survey'] = this.gridItemGenerate(['survey']);
    }
    if (this.dimensions['zefSurveyUserRating']) {
      gridItems['zefSurveyUserRating'] = this.gridItemGenerate(['zefSurveyUserRating']);
    }

    return gridItems;
  }

  // setSummaryGridItem(): Array<Array<string>> {
  //   if (this.zScorings && this.zScorings.zGlobalGroups && this.zScorings.zLocalGroups) {
  //     const arr = [];
  //     for (const globalGroup in this.zScorings.zGlobalGroups) {
  //       let count = 0;
  //       for (const group in this.zScorings.zLocalGroups) {
  //         for (const localGroup in this.zScorings.zLocalGroups[group]) {
  //           if (localGroup === globalGroup) {
  //             count++;
  //           }
  //         }
  //       }
  //       if (count > 1) {
  //         const items = [];
  //         for (const item of this.zScorings.zGlobalGroups[globalGroup]) {
  //           if (this.dimensions[item]) {
  //             items.push(item);
  //           } else {
  //             for (const q of this.questions) {
  //               if (q.$key === item) {
  //                 if (q.type === Questions.SLIDER_2D || q.type === Questions.SLIDER_1R) {
  //                   items.push(`${item}:0`, `${item}:1`);
  //                 }
  //               }
  //             }
  //           }
  //         }
  //         arr.push(items);
  //       }
  //     }
  //     return arr;
  //   } else {
  //     return [];
  //   }
  // }

  /**
   * Creating report grid items through existing dimensions i.e. from saved ones.
   *
   * @returns  gridItems.
   */
  setGridItems(items: any) {
    const gridItems = {};
    const getDimensions = (details) => {
      const dimensions: string[] = [];

      for (const detail of details) {
        dimensions.push(detail.key);
      }

      return dimensions;
    };

    for (const item of items) {
      if (!item.isHintCard && !item.isSubheader) {
        gridItems[item.key] = this.gridItemGenerate(getDimensions(item.data.details));
        if (item.data && item.data.comparison) {
          gridItems[item.key]['comparison'] = item.data.comparison;
          gridItems[item.key]['filtersAll'] = item.data.filtersAll;
        }
        if (item.hided) {
          gridItems[item.key]['disabled'] = true;
        }
      }
    }

    return gridItems;
  }

  /**
   * Creating griditem from given dimensions.
   *
   * @param dimensions  Dimension keys.
   * @returns           Grid item.
   */
  public gridItemGenerate(dimensions: string[], disabled: boolean = false) {
    const gridItem = {} as GridItemDataItem;
    gridItem['keys'] = [];
    gridItem['details'] = [];
    gridItem['scales'] = [];
    gridItem['filters'] = [];
    gridItem['filtersAll'] = {};
    gridItem['distributions'] = [];
    gridItem['stats'] = [];
    gridItem['totalAnswers'] = [];
    gridItem['dimension'] = null;
    gridItem['crossfilterGroup'] = null;
    gridItem['timestamp'] = new Date();
    gridItem['indexes'] = [];
    gridItem['activeLocale'] = this.activeLocale;

    if (disabled) {
      gridItem['disabled'] = disabled;
    }

    for (const dim of dimensions) {
      if (this.dimensions[dim]) {
        gridItem['keys'].push(dim);
        gridItem['details'].push(this.dimensions[dim]);
        gridItem['scales'].push(this.dimensions[dim].scale);
        gridItem['filters'].push(null);
      }
    }

    return gridItem;
  }

  /** Updating fields entry summary. */
  private updateFieldsEntrySummary(gridItems: GridItemData) {
    const getCount = (key: string) => this.answerData?.textContacts?.[key]?.length || 0;
    const dimensions: string[] = [];

    if (getCount('fields-email') > 0 || getCount('fields-phone') > 0) {
      if (getCount('fields-email') > 0 || getCount('fields-phone') === 0) {
        dimensions.push('fields-email');
      }
      if (getCount('fields-phone') > 0) {
        dimensions.push('fields-phone');
      }
      if (getCount('fields-firstName') <= getCount('fields-name')) {
        dimensions.push('fields-name');
      } else {
        dimensions.push('fields-firstName');
        dimensions.push('fields-lastName');
      }

      gridItems['fields-entrySummary'] = this.gridItemGenerate(dimensions);
    }
  }

  /**
   * Creating user segments grid item and updating user segments dimension.
   *
   * @returns      'Done'.
   */
  public userSegmentFromFilters() {
    const id = this.generateId();
    const index = this.answerData ? this.answerData.indexes.indexOf('userSegments') : '';
    const data = this.answerData ? this.answerData.answers : [];
    const filters = Object.assign({}, this.filters);
    const sortedColorIndexes: number[] = this.dimensions['userSegments']['customColors']
      ? Object.values(this.dimensions['userSegments']['customColors']).sort((a, b) => a - b)
      : [];
    let colorIndex: number = -1;

    for (let i = 0, len = sortedColorIndexes.length; i < len; i++) {
      if (sortedColorIndexes[i] !== i) {
        colorIndex = i;
        break;
      }
    }

    if (colorIndex === -1) {
      colorIndex = sortedColorIndexes.length;
    }

    // Converting user segment filters to original filters to avoid circular structure on JSON
    if (this.filters['userSegments']) {
      for (const item of this.filters['userSegments']['filter']) {
        for (const filter in this.dimensions['userSegments']['customOrigins'][item]['filters']) {
          if (filters[filter]) {
            const newFilters = this.dimensions['userSegments']['customOrigins'][item]['filters'][filter].filter;
            filters[filter]['filter'] = Array.from(new Set(filters[filter]['filter'].concat(newFilters)));
          } else {
            filters[filter] = this.dimensions['userSegments']['customOrigins'][item]['filters'][filter];
          }
        }
      }
      delete filters['userSegments'];
    }

    this.dimensions['userSegments']['values'].push(id);
    if (!this.dimensions['userSegments']['customValuesOrder']) {
      this.dimensions['userSegments']['customValuesOrder'] = [];
    }

    this.dimensions['userSegments']['customValuesOrder']!.push(id);

    if (!this.dimensions['userSegments']['customColors']) {
      this.dimensions['userSegments']['customColors'] = {};
    }

    this.dimensions['userSegments']['customColors']![id] = colorIndex;

    const generateLabel: () => string = (): string => {
      let labelText: string = '';
      let i: number = 0;
      for (const f in filters) {
        if (i > 0) {
          labelText += ' - ';
        }
        if (filters[f]['customName']) {
          labelText += filters[f]['customName'];
        } else if (filters[f].dimensionData.scale === 'linear') {
          labelText += `${filters[f].dimensionData.title}: ${filters[f].labels}`;
        } else {
          labelText += filters[f].labels;
        }

        i++;
      }
      return labelText;
    };
    this.dimensions['userSegments']['labelsCategorical'][id] = generateLabel();
    this.dimensions['userSegments']['customOrigins'][id] = { filters };

    this.gridItems['userSegments'] = this.gridItemGenerate(['userSegments']);

    // const usersRaw = this.baseDimension.top(Infinity);
    // const users: number[] = [];
    // const userSegmentIndex = this.dimensions['userSegments']['values'].indexOf(id);
    //
    // for (let i = 0, len = usersRaw.length; i < len; i++) {
    //   users.push(usersRaw[i][0]);
    // }
    //
    // for (let i = data.length - 1; i >= 0; i--) {
    //   const userId = data[i][0];
    //   for (let u = 0, len = users.length; u < len; u++) {
    //     if (users[u] === userId) {
    //       if (!data[i][index]) {
    //         data[i][index] = [[]];
    //       }
    //       data[i][index][0].push(userSegmentIndex);
    //       users.splice(u, 1);
    //       u = Infinity;
    //     } else if (users[u] < userId) {
    //       u = Infinity;
    //     }
    //   }
    // }

    for (const filterKey in this.filters) {
      this.removeCrossfilterDimension(filterKey);
    }

    for (let a = 0, len = data.length; a < len; a++) {
      if (data[a][index] != null) {
        data[a][index] = null;
      }
      this.dp.updateUserSegmentValues(data[a], index, this.answerData.indexes, this.dimensions);
    }

    this.dataChange.next('Added new comparison item');

    this.gridItems = this.calculate(this.gridItems);

    this.sendNewCrossfilter();

    if (this.comparisonModeOn) {
      this.updateComparisonList(this.comparisonDimension.values, this.comparisonDimension.key);
    }

    return 'Done';
  }

  /**
   * Deleting user segments from grid item and updating user segments dimension.
   */
  public deleteUserSegment(id: string) {
    const index: number =
      this.answerData && this.answerData.indexes ? this.answerData.indexes.indexOf('userSegments') : null;
    const dataAnswers: any[] = this.answerData && this.answerData.answers ? this.answerData.answers : [];
    const userSegmentIndex = this.dimensions['userSegments']['values'].indexOf(id);
    const userSegmentOrderIndex = (this.dimensions['userSegments']['customValuesOrder'] || []).indexOf(id);

    this.dimensions['userSegments']['values'].splice(userSegmentIndex, 1);
    (this.dimensions['userSegments']['customValuesOrder'] || []).splice(userSegmentOrderIndex, 1);
    delete this.dimensions['userSegments']['labelsCategorical'][id];
    delete this.dimensions['userSegments']['customColors'][id];

    if (index >= 0 && dataAnswers.length > 0) {
      for (let a = 0, len = dataAnswers.length; a < len; a++) {
        if (dataAnswers[a][index] != null) {
          dataAnswers[a][index] = null;
          this.dp.updateUserSegmentValues(dataAnswers[a], index, this.answerData.indexes, this.dimensions);
        }
      }
    }

    this.dataChange.next('Removed comparison item');
    const comparisonIndex =
      this.comparisonDimension && this.comparisonDimension.values ? this.comparisonDimension.values.indexOf(id) : null;

    if (this.comparisonModeOn && comparisonIndex >= 0) {
      const val = Array.from(this.comparisonDimension.values);
      val.splice(comparisonIndex, 1);
      this.updateComparisonList(val, this.comparisonDimension.key);
    } else if (this.comparisonModeOn) {
      this.updateComparisonList(Array.from(this.comparisonDimension.values), this.comparisonDimension.key);
    } else {
      this.gridItems = this.calculate(this.gridItems);
      this.sendNewCrossfilter();
    }
  }

  /**
   * Deleting user segments from grid item and updating user segments dimension.
   */
  public updateComparisonList(comparisonList: string[], dimension: string, noSend: boolean = false) {
    if (comparisonList.length > 0) {
      const comparison = comparisonList.map((item) => this.dimensions[dimension].values.indexOf(item));
      for (const i in this.gridItems) {
        let index: number | null = this.gridItems[i]['keys'].findIndex((key) => key === dimension);
        if (
          i !== dimension &&
          !this.gridItems[i]['keys'].find((key) => key === dimension) &&
          (!this.comparisonModeOn || (this.comparisonModeOn && !this.gridItems[i]['comparison']))
        ) {
          this.gridItems[i]['keys'].push(dimension);
          this.gridItems[i]['details'].push(this.dimensions[dimension]);
          this.gridItems[i]['scales'].push(this.dimensions[dimension].scale);
          this.gridItems[i]['filters'].push(null);
          index = this.gridItems[i]['keys'].length - 1;
        } else if (this.gridItems[i]['comparison'] && index === -1 && this.gridItems[i]['comparison']['index'] > -1) {
          index = this.gridItems[i]['comparison']['index'];
        }

        this.gridItems[i]['comparison'] = { key: dimension, values: comparison, index };
      }
      this.comparisonModeOn = true;
      this.comparisonDimension = { key: dimension, values: comparisonList };
    } else {
      this.comparisonModeOn = false;
      this.comparisonDimension = {} as ComparisonDimension;
      for (const i in this.gridItems) {
        if (
          this.gridItems[i]['keys'].length > 1 &&
          this.gridItems[i]['comparison'] &&
          this.gridItems[i]['comparison']['index'] !== null
        ) {
          const index = this.gridItems[i]['comparison']['index'];
          this.gridItems[i]['keys'].splice(index, 1);
          this.gridItems[i]['details'].splice(index, 1);
          this.gridItems[i]['scales'].splice(index, 1);
          this.gridItems[i]['filters'].splice(index, 1);
        }
        this.gridItems[i]['comparison'] = null;
      }
    }

    if (!noSend) {
      this.gridItems = this.calculate(this.gridItems);
      this.dataChange.next('Updated comparison');
      this.sendNewCrossfilter();
    }

    return true;
  }

  /**
   * Switching trend analysis on/off
   */
  public switchTrendAnalysis(enable: boolean, noSend: boolean = false) {
    this.loading.next(true);
    if (enable) {
      for (const i in this.gridItems) {
        if (
          i !== 'userSegments' &&
          i !== 'time' &&
          !this.gridItems[i]['keys'].find((key) => key === 'time') &&
          (!this.trendAnalysisOn || (this.trendAnalysisOn && !this.gridItems[i]['trend']))
        ) {
          this.gridItems[i]['keys'].unshift('time');
          this.gridItems[i]['details'].unshift(this.dimensions['time']);
          this.gridItems[i]['scales'].unshift(this.dimensions['time'].scale);
          this.gridItems[i]['filters'].unshift(null);
          if (this.gridItems[i]['comparison'] && this.gridItems[i]['comparison']['index'] != null) {
            ++this.gridItems[i]['comparison']['index'];
          }
        }
        this.gridItems[i]['trend'] = {
          key: 'time',
          index: this.gridItems[i]['keys'].findIndex((key) => key === 'time'),
          timePeriod: this.getTimePeriod(),
        };
      }
      this.trendAnalysisOn = true;
    } else {
      this.trendAnalysisOn = false;
      for (const i in this.gridItems) {
        if (
          i !== 'time' &&
          this.gridItems[i]['keys'].length > 1 &&
          this.gridItems[i]['trend'] &&
          this.gridItems[i]['trend']['index'] === 0
        ) {
          const index = this.gridItems[i]['trend']['index'];
          this.gridItems[i]['keys'].splice(index, 1);
          this.gridItems[i]['details'].splice(index, 1);
          this.gridItems[i]['scales'].splice(index, 1);
          this.gridItems[i]['filters'].splice(index, 1);

          if (this.gridItems[i]['comparison'] && this.gridItems[i]['comparison']['index'] != null) {
            --this.gridItems[i]['comparison']['index'];
          }
        }
        this.gridItems[i]['trend'] = null;
      }
    }

    if (!noSend) {
      this.gridItems = this.calculate(this.gridItems);
      this.dataChange.next('Switched trend analysis' + (enable ? ' on' : 'off'));
      this.sendNewCrossfilter();
    }

    return true;
  }

  /** CALCULATIONS **/

  /**
   * Calculating distributions and statistics.
   *
   * @param gridItems  GridItems to be updated with fresh data.
   * @returns          Updated grid items.
   */
  public calculate(gridItems: GridItemData) {
    const calculateList = this.parseDimensions(gridItems);
    const distributions = this.memoizedDistributions(
      this.baseGroup,
      calculateList.regular,
      calculateList.cross,
      calculateList.multiCross,
      this.dimensions,
      this.answerData.indexes,
      this.answerData.textLabels,
    );

    const stats = this.calculateStats(distributions, calculateList, this.answerData.indexes, this.dimensions);
    const customDistributions = {};

    for (const custom of calculateList.custom) {
      customDistributions[this.answerData.indexes[custom]] = this.calculateDistributions(
        this.customDimensions[this.answerData.indexes[custom]].group,
        [custom],
        calculateList.cross,
        calculateList.multiCross,
        this.dimensions,
        this.answerData.indexes,
        this.answerData.textLabels,
      );
    }

    return this.updateGridItems(gridItems, distributions, customDistributions, stats);
  }

  /**
   * Parsing dimensions to be used on calculations.
   *
   * @param gridItems  GridItems from where dimensions are parsed.
   * @returns          Dimension object containing arrays of dimensions for different purposes.
   */
  parseDimensions(gridItems: GridItemData) {
    const regularArr: number[] = [];
    const customArr: number[] = [];
    const multiCross = {};
    const multiCrossStats = {};
    const cross = {};
    const crossStats = {};
    const localZScores: any = {};
    const globalZScores: any = {};

    for (const item in gridItems) {
      if (gridItems[item] && !gridItems[item].disabled) {
        // Parse groups with categorical dimension
        if (gridItems[item].keys.length >= 2) {
          // categorize scales
          const others: number[] = [];
          const linear: number[] = [];
          const texts: number[] = [];
          const time: number[] = [];
          const sentiment: number[] = [];
          const words: number[] = [];
          const comparisonIndex: number = this.answerData.indexes.indexOf(gridItems[item]?.comparison?.key);

          for (const detail of gridItems[item].details) {
            const index = this.answerData.indexes.indexOf(detail.key);
            if (detail.scale === 'linear') {
              linear.push(index);
              if (detail.originalTypeSpecifier === 'text-sentiment') {
                sentiment.push(index);
              }
            } else if (detail.scale === 'categorical') {
              others.push(index);
              if (detail.originalTypeSpecifier === 'text-words') {
                words.push(index);
              }
            } else if (detail.scale === 'text' || detail.scale === 'contact-text') {
              texts.push(index);
            } else if (detail.scale === 'time') {
              time.push(index);
            }
          }

          if (time.length === 1) {
            if (others.length > 0 && linear.length > 0 && others.length + linear.length > 2) {
              // Check if there is a group with categorical dimension and 2 or more linear dimensions
              for (const other of others) {
                const indexX = time[0];

                if (!cross[other]) {
                  cross[other] = new Set();
                }
                if (!crossStats[other]) {
                  crossStats[other] = new Set();
                }
                if (!multiCross[indexX]) {
                  multiCross[indexX] = new Set();
                }
                if (!multiCrossStats[indexX]) {
                  multiCrossStats[indexX] = new Set();
                }

                cross[other].add(indexX);
                crossStats[other].add(indexX);

                for (const lin of linear) {
                  cross[other].add(lin);
                  crossStats[other].add(lin);
                  multiCross[indexX].add(lin);
                  multiCrossStats[indexX].add(lin);
                }

                multiCross[indexX].add(other);
              }
            } else if (others.length + linear.length === 2) {
              if (others.length === 1) {
                const indexX = time[0];
                const indexY = linear[0];
                const indexZ = others[0];

                if (!cross[indexZ]) {
                  cross[indexZ] = new Set();
                }
                if (!crossStats[indexZ]) {
                  crossStats[indexZ] = new Set();
                }
                if (!multiCross[indexX]) {
                  multiCross[indexX] = new Set();
                }
                if (!multiCrossStats[indexX]) {
                  multiCrossStats[indexX] = new Set();
                }

                cross[indexZ].add(indexX);
                crossStats[indexZ].add(indexX);
                multiCross[indexX].add(indexY);
                multiCrossStats[indexX].add(indexY);
              } else if (others.length === 2) {
                const indexX = time[0];
                const indexY = others[0];
                const indexZ = others[1];

                if (!cross[indexZ]) {
                  cross[indexZ] = new Set();
                }
                if (!multiCross[indexX]) {
                  multiCross[indexX] = new Set();
                }

                cross[indexZ].add(indexX);
                multiCross[indexX].add(indexY);
              } else if (linear.length === 2) {
                if (!cross[time[0]]) {
                  cross[time[0]] = new Set();
                }
                if (!crossStats[time[0]]) {
                  crossStats[time[0]] = new Set();
                }

                for (const lin of linear) {
                  cross[time[0]].add(lin);
                  crossStats[time[0]].add(lin);
                }
              }
            } else if (others.length + linear.length === 1 && texts.length === 0) {
              const index = this.answerData.indexes.indexOf(gridItems[item].keys[0]);
              const indexY = this.answerData.indexes.indexOf(gridItems[item].keys[1]);

              if (!cross[index]) {
                cross[index] = new Set();
              }
              cross[index].add(indexY);

              if (!cross[indexY]) {
                cross[indexY] = new Set();
              }

              cross[indexY].add(index);

              if (!crossStats[index]) {
                crossStats[index] = new Set();
              }
              crossStats[index].add(indexY);

              if (!crossStats[indexY]) {
                crossStats[indexY] = new Set();
              }
              crossStats[indexY].add(index);
            } else if (others.length === 0 && linear.length > 2) {
              for (const lin of linear) {
                const indexX = time[0];

                if (!cross[indexX]) {
                  cross[indexX] = new Set();
                }
                if (!crossStats[indexX]) {
                  crossStats[indexX] = new Set();
                }

                cross[indexX].add(lin);
                crossStats[indexX].add(lin);
              }
            } else if (others.length === 0 && linear.length === 1 && texts.length === 1 && sentiment.length === 1) {
              const index = time[0];
              const indexY = sentiment[0];

              if (!cross[index]) {
                cross[index] = new Set();
              }
              cross[index].add(indexY);

              if (!cross[indexY]) {
                cross[indexY] = new Set();
              }

              cross[indexY].add(index);

              if (!crossStats[index]) {
                crossStats[index] = new Set();
              }
              crossStats[index].add(indexY);

              if (!crossStats[indexY]) {
                crossStats[indexY] = new Set();
              }
              crossStats[indexY].add(index);
            } else if (others.length === 1 && linear.length === 0 && texts.length === 1) {
              const index = time[0];
              const indexY = others[0];

              if (!cross[index]) {
                cross[index] = new Set();
              }
              cross[index].add(indexY);

              if (!cross[indexY]) {
                cross[indexY] = new Set();
              }

              cross[indexY].add(index);

              if (!crossStats[index]) {
                crossStats[index] = new Set();
              }
              crossStats[index].add(indexY);

              if (!crossStats[indexY]) {
                crossStats[indexY] = new Set();
              }
              crossStats[indexY].add(index);
            }
          } else {
            if (others.length > 0 && linear.length > 0 && others.length + linear.length > 2) {
              // Check if there is a group with categorical dimension and 2 or more linear dimensions
              for (const other of others) {
                if (!cross[other]) {
                  cross[other] = new Set();
                }
                if (!crossStats[other]) {
                  crossStats[other] = new Set();
                }

                for (const lin of linear) {
                  cross[other].add(lin);
                  crossStats[other].add(lin);
                }
              }
            }

            if (comparisonIndex >= 0 && words.length > 0) {
              if (!cross[comparisonIndex]) {
                cross[comparisonIndex] = new Set();
              }
              if (!crossStats[comparisonIndex]) {
                crossStats[comparisonIndex] = new Set();
              }
              if (!cross[words[0]]) {
                cross[words[0]] = new Set();
              }
              if (!crossStats[words[0]]) {
                crossStats[words[0]] = new Set();
              }
              cross[comparisonIndex].add(words[0]);
              crossStats[comparisonIndex].add(words[0]);

              cross[words[0]].add(comparisonIndex);
              crossStats[words[0]].add(comparisonIndex);
            }

            if (others.length + linear.length === 3 && texts.length === 0) {
              // or 2 dimensional question
              if (others.length === 1 && linear.length === 2) {
                const indexX = linear[0];
                const indexY = linear[1];
                const indexZ = others[0];

                if (!cross[indexZ]) {
                  cross[indexZ] = new Set();
                }
                if (!multiCross[indexX]) {
                  multiCross[indexX] = new Set();
                }

                cross[indexZ].add(indexX);
                multiCross[indexX].add(indexY);
              } else if (linear.length === 1) {
                for (const other of others) {
                  if (!cross[other]) {
                    cross[other] = new Set();
                  }
                  if (!crossStats[other]) {
                    crossStats[other] = new Set();
                  }
                  for (const lin of linear) {
                    crossStats[other].add(lin);
                    cross[other].add(lin);
                  }
                }
              }
            } else if (others.length + linear.length === 2 && texts.length === 0) {
              // or 2 dimensional question
              const index = this.answerData.indexes.indexOf(gridItems[item].keys[0]);
              const indexY = this.answerData.indexes.indexOf(gridItems[item].keys[1]);

              if (!cross[index]) {
                cross[index] = new Set();
              }
              cross[index].add(indexY);

              if (linear.length === 1) {
                if (!cross[others[0]]) {
                  cross[others[0]] = new Set();
                }
                if (!crossStats[others[0]]) {
                  crossStats[others[0]] = new Set();
                }
                for (const lin of linear) {
                  crossStats[others[0]].add(lin);
                  cross[others[0]].add(lin);
                }
              } else if (linear.length === 0) {
                if (!cross[indexY]) {
                  cross[indexY] = new Set();
                }
                cross[indexY].add(index);
                if (!crossStats[indexY]) {
                  crossStats[indexY] = new Set();
                }
                crossStats[indexY].add(index);
              }
            } else if (others.length === 2 && texts.length === 1 && linear.length === 0) {
              if (!cross[others[1]]) {
                cross[others[1]] = new Set();
              }
              if (!crossStats[others[1]]) {
                crossStats[others[1]] = new Set();
              }
              if (!cross[others[0]]) {
                cross[others[0]] = new Set();
              }
              if (!crossStats[others[0]]) {
                crossStats[others[0]] = new Set();
              }
              cross[others[1]].add(others[0]);
              crossStats[others[1]].add(others[0]);

              cross[others[0]].add(others[1]);
              crossStats[others[0]].add(others[1]);
            } else if (others.length === 1 && linear.length === 1 && texts.length > 0) {
              if (!cross[others[0]]) {
                cross[others[0]] = new Set();
              }

              if (!crossStats[others[0]]) {
                crossStats[others[0]] = new Set();
              }

              cross[others[0]].add(linear[0]);
              crossStats[others[0]].add(linear[0]);
            } else if (others.length === 1 && linear.length === 0 && texts.length === 1) {
              if (!cross[others[0]]) {
                cross[others[0]] = new Set();
              }

              if (!crossStats[others[0]]) {
                crossStats[others[0]] = new Set();
              }

              if (!cross[texts[0]]) {
                cross[texts[0]] = new Set();
              }

              if (!crossStats[texts[0]]) {
                crossStats[texts[0]] = new Set();
              }

              cross[others[0]].add(texts[0]);
              crossStats[others[0]].add(texts[0]);

              cross[texts[0]].add(others[0]);
              crossStats[texts[0]].add(others[0]);
            }
          }
        }

        for (const dimension of gridItems[item].keys) {
          const index = this.answerData.indexes.indexOf(dimension);
          regularArr.push(index);

          if (this.customDimensions[dimension]) {
            customArr.push(index);
          }

          if (this.dimensions[dimension].localZComparison) {
            localZScores[index] = [];

            // Here we add z-scoring partners to ensure working z-scoring for all situations (i.e. creating new chart)
            for (const partner of this.dimensions[dimension].localZComparison || []) {
              if (this.answerData.indexes.indexOf(partner) >= 0) {
                regularArr.push(this.answerData.indexes.indexOf(partner));
                localZScores[index].push(this.answerData.indexes.indexOf(partner));

                // for (const mc in multiCross) {
                //   if (multiCross[mc].has(index)) {
                //     multiCross[mc].add(this.answerData.indexes.indexOf(partner));
                //   }
                // }
                // for (const mc in multiCrossStats) {
                //   if (multiCrossStats[mc].has(index)) {
                //     multiCrossStats[mc].add(this.answerData.indexes.indexOf(partner));
                //   }
                // }
              }
            }

            for (const cs in crossStats) {
              if (crossStats[cs].has(index)) {
                const partners = new Set();
                for (const partner of this.dimensions[dimension].localZComparison || []) {
                  if (this.answerData.indexes.indexOf(partner) >= 0) {
                    partners.add(this.answerData.indexes.indexOf(partner));
                  }
                }
                // const arr = new Set(cross[cs].concat(partners));
                partners.forEach(cross[cs].add, cross[cs]);
              }
            }
          }

          if (this.dimensions[dimension].globalZComparison) {
            // globalZScores[dimension] = this.dimensions[dimension].globalZComparison;
            globalZScores[index] = [];

            // Here we add z-scoring partners to ensure working z-scoring for all situations (i.e. creating new chart)
            for (const partner of this.dimensions[dimension].globalZComparison || []) {
              if (this.answerData.indexes.indexOf(partner) >= 0) {
                regularArr.push(this.answerData.indexes.indexOf(partner));
                globalZScores[index].push(this.answerData.indexes.indexOf(partner));

                // for (const mc in multiCross) {
                //   if (multiCross[mc].has(index)) {
                //     multiCross[mc].add(this.answerData.indexes.indexOf(partner));
                //   }
                // }
                // for (const mc in multiCrossStats) {
                //   if (multiCrossStats[mc].has(index)) {
                //     multiCrossStats[mc].add(this.answerData.indexes.indexOf(partner));
                //   }
                // }
              }
            }

            for (const cs in crossStats) {
              if (crossStats[cs].has(index)) {
                const partners = new Set();
                for (const partner of this.dimensions[dimension].globalZComparison || []) {
                  if (this.answerData.indexes.indexOf(partner) >= 0) {
                    partners.add(this.answerData.indexes.indexOf(partner));
                  }
                }
                // const arr = new Set(cross[cs].concat(partners));
                partners.forEach(cross[cs].add, cross[cs]);
              }
            }
          }
        }
      }
    }

    const regular = Array.from(new Set(regularArr));
    const custom = Array.from(new Set(customArr));

    for (const i in cross) {
      cross[i] = Array.from(cross[i]);
    }

    for (const i in crossStats) {
      crossStats[i] = Array.from(crossStats[i]);
    }

    for (const i in multiCross) {
      multiCross[i] = Array.from(multiCross[i]);
    }

    for (const i in multiCrossStats) {
      multiCrossStats[i] = Array.from(multiCrossStats[i]);
    }

    for (const i in localZScores) {
      for (const c of localZScores[i]) {
        if (!localZScores[c]) {
          localZScores[c] = localZScores[i];
        }
      }
    }

    for (const i in globalZScores) {
      for (const c of globalZScores[i]) {
        if (!globalZScores[c]) {
          globalZScores[c] = globalZScores[i];
        }
      }
    }

    return {
      regular,
      cross,
      crossStats,
      multiCross,
      multiCrossStats,
      custom,
      localZScores,
      globalZScores,
    };
  }

  /**
   * Calculating distributions.
   *
   * @param crossfilterGroup  Crossfilter group to be used in reducing.
   * @param regularList       List of dimensions in which distributions are to be reduced.
   * @param crossList         Object containing dimensions that need crosstabulated distributions.
   * @param dimensions        Dimensions to help calculation process.
   * @param indexes           Dimension idexes needed to calculate data.
   * @param answererIndexes           Dimension idexes needed to calculate data.
   * @returns                 Distribution object.
   */
  calculateDistributions(
    crossfilterGroup: CrossFilter.GroupAll<any, any>,
    regularList,
    crossList,
    multiCrossList,
    dimensions,
    indexes,
    textLabels,
  ) {
    const objectCheck: any = (obj: any[], a: any[]) => {
      if (!obj) {
        obj = [];
      }
      for (let i = 0, len = a.length; i < len; i++) {
        const c = a[i];
        const val = i === len - 1 ? 0 : [];

        if (c == null) {
          break;
        }

        switch (i) {
          case 0:
            if (!obj[c]) {
              obj[c] = val;
            }
            break;
          case 1:
            if (!obj[a[0]][c]) {
              obj[a[0]][c] = val;
            }
            break;
          case 2:
            if (!obj[a[0]][a[1]][c]) {
              obj[a[0]][a[1]][c] = val;
            }
            break;
          case 3:
            if (!obj[a[0]][a[1]][a[2]][c]) {
              obj[a[0]][a[1]][a[2]][c] = val;
            }
            break;
          case 4:
            if (!obj[a[0]][a[1]][a[2]][a[3]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][c] = val;
            }
            break;
          case 5:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][c] = val;
            }
            break;
          case 6:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][c] = val;
            }
            break;
          case 7:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][c] = val;
            }
            break;
          case 8:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][c] = val;
            }
            break;
          case 9:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][c] = val;
            }
            break;
          case 10:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][a[9]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][a[9]][c] = val;
            }
            break;
          case 11:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][a[9]][a[10]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][a[9]][a[10]][c] = val;
            }
            break;
          case 12:
            if (!obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][a[9]][a[10]][a[11]][c]) {
              obj[a[0]][a[1]][a[2]][a[3]][a[4]][a[5]][a[6]][a[7]][a[8]][a[9]][a[10]][a[11]][c] = val;
            }
        }
      }
    };
    const isIndexVal: (any) => boolean = (val: any) => val != null && val >= 0;

    /** Main reducing function used in calculation of distributions **/
    const reducing = (p, v, i, key, cross, multiCross, increment) => {
      const value = v[i] && v[i][0] ? v[i][0] : null;
      const localZValue = v[i] && v[i][1] ? v[i][1] : null;
      const globalZValue = v[i] && v[i][2] ? v[i][2] : null;
      // master dimension
      if (value) {
        // adding values to distributions
        for (let val = 0, len = value.length; val < len; ++val) {
          if (isIndexVal(value[val])) {
            objectCheck(p, [0, i, value[val], 0, 0]);
            p[0][i][value[val]][0][0] += increment;
          }
        }

        if (localZValue) {
          for (let val = 0, len = localZValue.length; val < len; ++val) {
            if (isIndexVal(localZValue[val])) {
              objectCheck(p, [0, i, localZValue[val], 0, 1]);
              p[0][i][localZValue[val]][0][1] += increment;
            }
          }
        }
        if (globalZValue) {
          for (let val = 0, len = globalZValue.length; val < len; ++val) {
            if (isIndexVal(globalZValue[val])) {
              objectCheck(p, [0, i, globalZValue[val], 0, 2]);
              p[0][i][globalZValue[val]][0][2] += increment;
            }
          }
        }
      }
      // child dimensions
      if (value && cross[i]) {
        for (let child = 0, clen = cross[i].length; child < clen; child++) {
          const childIndex = cross[i][child];
          // const childKey = indexes[childIndex];
          const childValue = v[childIndex] && v[childIndex][0] ? v[childIndex][0] : null;
          const childLocalZValue = v[childIndex] && v[childIndex][1] ? v[childIndex][1] : null;
          const childGlobalZValue = v[childIndex] && v[childIndex][2] ? v[childIndex][2] : null;

          if (childValue) {
            for (let valn = 0, len = value.length; valn < len; ++valn) {
              const val = value[valn];
              for (let cvaln = 0, lenc = childValue.length; cvaln < lenc; ++cvaln) {
                const childVal = childValue[cvaln];
                if (isIndexVal(val) && isIndexVal(childVal)) {
                  objectCheck(p, [0, i, val, 1, childIndex, childVal, 0, 0]);
                  p[0][i][val][1][childIndex][childVal][0][0] += increment;
                }
              }
              if (childLocalZValue) {
                for (let cvaln = 0, lenc = childLocalZValue.length; cvaln < lenc; ++cvaln) {
                  const childVal = childLocalZValue[cvaln];
                  if (isIndexVal(val) && isIndexVal(childVal)) {
                    objectCheck(p, [0, i, val, 1, childIndex, childVal, 0, 1]);
                    p[0][i][val][1][childIndex][childVal][0][1] += increment;
                  }
                }
              }
              if (childGlobalZValue) {
                for (let cvaln = 0, lenc = childGlobalZValue.length; cvaln < lenc; ++cvaln) {
                  const childVal = childGlobalZValue[cvaln];
                  if (isIndexVal(val) && isIndexVal(childVal)) {
                    objectCheck(p, [0, i, val, 1, childIndex, childVal, 0, 2]);
                    p[0][i][val][1][childIndex][childVal][0][2] += increment;
                  }
                }
              }
            }

            if (isIndexVal(value[0]) && isIndexVal(childValue[0])) {
              for (let valn = 0, len = value.length; valn < len; ++valn) {
                const val = value[valn];
                if (childValue.findIndex((item) => isIndexVal(item)) >= 0) {
                  objectCheck(p, [1, 0, i, childIndex, val]);
                  p[1][0][i][childIndex][val] += increment;
                }
              }
            }
          }
          // grand child dimensions
          if (multiCross[childIndex] && multiCross[childIndex].length > 0) {
            for (let grandChild = 0, gclen = multiCross[childIndex].length; grandChild < gclen; grandChild++) {
              const grandChildIndex = multiCross[childIndex][grandChild];
              // const grandChildKey = indexes[grandChildIndex];
              const grandChildValue = v[grandChildIndex] && v[grandChildIndex][0] ? v[grandChildIndex][0] : null;
              const grandChildLocalZValue = v[grandChildIndex] && v[grandChildIndex][1] ? v[grandChildIndex][1] : null;
              const grandChildGlobalZValue = v[grandChildIndex] && v[grandChildIndex][2] ? v[grandChildIndex][2] : null;

              if (childValue && grandChildValue) {
                for (let valn = 0, len = value.length; valn < len; ++valn) {
                  const val = value[valn];
                  for (let cvaln = 0, lenc = childValue.length; cvaln < lenc; ++cvaln) {
                    const childVal = childValue[cvaln];
                    for (let gcvaln = 0, glenc = grandChildValue.length; gcvaln < glenc; ++gcvaln) {
                      const grandChildVal = grandChildValue[gcvaln];
                      if (isIndexVal(val) && isIndexVal(childVal) && isIndexVal(grandChildVal)) {
                        objectCheck(p, [0, i, val, 1, childIndex, childVal, 1, grandChildIndex, grandChildVal, 0, 0]);
                        p[0][i][val][1][childIndex][childVal][1][grandChildIndex][grandChildVal][0][0] += increment;
                      }
                    }
                  }
                  if (grandChildLocalZValue) {
                    for (let cvaln = 0, lenc = childValue.length; cvaln < lenc; ++cvaln) {
                      const childVal = childValue[cvaln];
                      for (let gcvaln = 0, glenc = grandChildLocalZValue.length; gcvaln < glenc; ++gcvaln) {
                        const grandChildVal = grandChildLocalZValue[gcvaln];
                        if (isIndexVal(val) && isIndexVal(childVal) && isIndexVal(grandChildVal)) {
                          objectCheck(p, [0, i, val, 1, childIndex, childVal, 1, grandChildIndex, grandChildVal, 0, 1]);
                          p[0][i][val][1][childIndex][childVal][1][grandChildIndex][grandChildVal][0][1] += increment;
                        }
                      }
                    }
                  }
                  if (grandChildGlobalZValue) {
                    for (let cvaln = 0, lenc = childValue.length; cvaln < lenc; ++cvaln) {
                      const childVal = childValue[cvaln];
                      for (let gcvaln = 0, glenc = grandChildGlobalZValue.length; gcvaln < glenc; ++gcvaln) {
                        const grandChildVal = grandChildGlobalZValue[gcvaln];
                        if (isIndexVal(val) && isIndexVal(childVal) && isIndexVal(grandChildVal)) {
                          objectCheck(p, [0, i, val, 1, childIndex, childVal, 1, grandChildIndex, grandChildVal, 0, 2]);
                          p[0][i][val][1][childIndex][childVal][1][grandChildIndex][grandChildVal][0][2] += increment;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (value != null) {
        objectCheck(p, [1, 0, i, 0]);
        p[1][0][i][0] += increment;
      }
    };

    const l = regularList.length;
    const result = crossfilterGroup
      .reduce(
        (p: any, v: any) => {
          for (let i = 0; i < l; ++i) {
            reducing(p, v, regularList[i], indexes[regularList[i]], crossList, multiCrossList, 1);
          }
          p[1][0][0] += 1;
          return p;
        },
        (p: any, v: any) => {
          for (let i = 0; i < l; ++i) {
            reducing(p, v, regularList[i], indexes[regularList[i]], crossList, multiCrossList, -1);
          }
          p[1][0][0] -= 1;
          return p;
        },
        () => {
          const p = [[], [[0]]];
          return p;
        },
      )
      .value();

    return result;
  }

  /**
   * Calculating stats (i.e. averages, standard deviations).
   *
   * @param distributions     Distributions object to be used for calculating statistics.
   * @param calculateList     Dimensions lists in which stats are to be calculated.
   * @param indexes           Dimension idexes needed to calculate data.
   * @param dimensions        Dimensions to help calculation process.
   * @returns                 Stats object.
   */
  calculateStats(distributions: any, calculateList, indexes, details) {
    const baseStats = [];
    const localZAverages = [];
    const globalZAverages = [];
    const c = 'children';
    // const localZScores = {};

    // Calculating aggregate averages
    for (let i = 0, len = calculateList.regular.length; i < len; i++) {
      const item = calculateList.regular[i];
      const itemKey = indexes[item];
      const distribution = distributions[0][item] || [];
      const values = details && itemKey && details[itemKey] ? details[itemKey].values || [] : [];
      const totalAnswers = distributions[1][0][item] ? distributions[1][0][item][0] : 0;

      if (details && itemKey && details[itemKey]) {
        baseStats[item] = this.calculateAverages(
          distribution,
          values,
          0,
          totalAnswers,
          details[itemKey]?.scale,
          itemKey,
          Calculations.consensus(itemKey),
        );
        baseStats[item]['key'] = itemKey;

        if (calculateList.localZScores[item]) {
          for (const zItem of calculateList.localZScores[item]) {
            const zItemKey = indexes[zItem];
            if (!localZAverages[zItem] && details[zItemKey]) {
              localZAverages[zItem] = this.calculateAverages(
                distributions[0][zItem] || [],
                details[zItemKey].values,
                1,
                totalAnswers,
                details[zItemKey].scale,
                zItemKey,
                Calculations.consensus(zItemKey),
              );
            }
          }
        }
        if (calculateList.globalZScores[item]) {
          for (const zItem of calculateList.globalZScores[item]) {
            const zItemKey = indexes[zItem];
            if (!globalZAverages[zItem] && details[zItemKey]) {
              globalZAverages[zItem] = this.calculateAverages(
                distributions[0][zItem] || [],
                details[zItemKey].values,
                2,
                totalAnswers,
                details[zItemKey].scale,
                zItemKey,
                Calculations.consensus(zItemKey),
              );
            }
          }
        }
      }
    }

    // Calculating crosstabulated averages
    for (const ci in calculateList.crossStats) {
      const ciKey = indexes[ci];
      const comparisonChoices: string[] = [];
      if (ciKey && this.comparisonDimension && this.comparisonDimension.key === ciKey) {
        for (const choice of this.comparisonDimension.values) {
          comparisonChoices.push(choice);
        }
      }

      for (const choice in distributions[0][ci]) {
        const choiceKey = details[ciKey].values[choice];
        if (comparisonChoices.length === 0 || comparisonChoices.indexOf(choiceKey) >= 0) {
          for (const item of calculateList.crossStats[ci]) {
            const itemKey = indexes[item];
            const distribution =
              distributions[0] &&
              distributions[0][ci] &&
              distributions[0][ci][choice] &&
              distributions[0][ci][choice][1] &&
              distributions[0][ci][choice][1][item] != null
                ? distributions[0][ci][choice][1][item]
                : [];
            const values = details[itemKey].values;
            const totalAnswers =
              distributions[1][0] &&
              distributions[1][0][ci] &&
              distributions[1][0][ci][item] &&
              distributions[1][0][ci][item][choice]
                ? distributions[1][0][ci][item][choice]
                : 0;

            if (!baseStats[ci]) {
              baseStats[ci] = {};
            }
            if (!baseStats[ci][c]) {
              baseStats[ci][c] = [];
            }
            if (!baseStats[ci][c][choice]) {
              baseStats[ci][c][choice] = {};
            }
            if (!baseStats[ci][c][choice][c]) {
              baseStats[ci][c][choice][c] = [];
            }
            baseStats[ci][c][choice]['key'] = choiceKey;
            baseStats[ci][c][choice][c][item] = this.calculateAverages(
              distribution,
              values,
              0,
              totalAnswers,
              details[itemKey].scale,
              itemKey,
              Calculations.consensus(itemKey),
            );

            if (calculateList.localZScores[item]) {
              for (const zItem of calculateList.localZScores[item]) {
                const zItemKey = indexes[zItem];
                if (!localZAverages[ci]) {
                  localZAverages[ci] = {};
                }
                if (!localZAverages[ci][c]) {
                  localZAverages[ci][c] = [];
                }
                if (!localZAverages[ci][c][choice]) {
                  localZAverages[ci][c][choice] = {};
                }
                if (!localZAverages[ci][c][choice][c]) {
                  localZAverages[ci][c][choice][c] = [];
                }

                if (!localZAverages[ci][c][choice][c][zItem]) {
                  localZAverages[ci][c][choice][c][zItem] = this.calculateAverages(
                    distributions[0] &&
                      distributions[0][ci] &&
                      distributions[0][ci][choice] &&
                      distributions[0][ci][choice][1] &&
                      distributions[0][ci][choice][1][zItem] != null
                      ? distributions[0][ci][choice][1][zItem]
                      : [],
                    details[zItemKey].values,
                    1,
                    totalAnswers,
                    details[zItemKey].scale,
                    zItemKey,
                    Calculations.consensus(zItemKey),
                  );
                }
              }
            }
            if (calculateList.globalZScores[item]) {
              for (const zItem of calculateList.globalZScores[item]) {
                const zItemKey = indexes[zItem];
                if (!globalZAverages[ci]) {
                  globalZAverages[ci] = {};
                }
                if (!globalZAverages[ci][c]) {
                  globalZAverages[ci][c] = [];
                }
                if (!globalZAverages[ci][c][choice]) {
                  globalZAverages[ci][c][choice] = {};
                }
                if (!globalZAverages[ci][c][choice][c]) {
                  globalZAverages[ci][c][choice][c] = [];
                }

                if (!globalZAverages[ci][c][choice][c][zItem]) {
                  globalZAverages[ci][c][choice][c][zItem] = this.calculateAverages(
                    distributions[0] &&
                      distributions[0][ci] &&
                      distributions[0][ci][choice] &&
                      distributions[0][ci][choice][1] &&
                      distributions[0][ci][choice][1][zItem] != null
                      ? distributions[0][ci][choice][1][zItem]
                      : [],
                    details[zItemKey].values,
                    2,
                    totalAnswers,
                    details[zItemKey].scale,
                    zItemKey,
                    Calculations.consensus(zItemKey),
                  );
                }
              }
            }
            if (
              calculateList.multiCrossStats[item] &&
              distributions[0][ci][choice] &&
              distributions[0][ci][choice][1] &&
              distributions[0][ci][choice][1][item]
            ) {
              for (const mcc in distributions[0][ci][choice][1][item]) {
                const mccKey = details[itemKey].values[mcc];

                for (const mci of calculateList.multiCrossStats[item]) {
                  const mciKey = indexes[mci];

                  const mcdistribution =
                    distributions[0][ci][choice][1][item][mcc] &&
                    distributions[0][ci][choice][1][item][mcc][1] &&
                    distributions[0][ci][choice][1][item][mcc][1][mci]
                      ? distributions[0][ci][choice][1][item][mcc][1][mci]
                      : [];
                  const mcvalues = details[mciKey].values;
                  const mctotalAnswers =
                    distributions[0][ci][choice][1][item][mcc] && distributions[0][ci][choice][1][item][mcc][0]
                      ? distributions[0][ci][choice][1][item][mcc][0]
                      : 0;

                  if (!baseStats[ci][c][choice][c][item][c]) {
                    baseStats[ci][c][choice][c][item][c] = [];
                  }
                  if (!baseStats[ci][c][choice][c][item][c][mcc]) {
                    baseStats[ci][c][choice][c][item][c][mcc] = {};
                  }
                  if (!baseStats[ci][c][choice][c][item][c][mcc][c]) {
                    baseStats[ci][c][choice][c][item][c][mcc][c] = [];
                  }

                  baseStats[ci][c][choice][c][item][c][mcc]['key'] = mccKey;
                  baseStats[ci][c][choice][c][item][c][mcc][c][mci] = this.calculateAverages(
                    mcdistribution,
                    mcvalues,
                    0,
                    mctotalAnswers,
                    details[mciKey].scale,
                    mciKey,
                    Calculations.consensus(mciKey),
                  );

                  if (calculateList.localZScores[mci]) {
                    if (!localZAverages[ci]) {
                      localZAverages[ci] = {};
                    }
                    if (!localZAverages[ci][c]) {
                      localZAverages[ci][c] = [];
                    }
                    if (!localZAverages[ci][c][choice]) {
                      localZAverages[ci][c][choice] = {};
                    }
                    if (!localZAverages[ci][c][choice][c]) {
                      localZAverages[ci][c][choice][c] = [];
                    }
                    if (!localZAverages[ci][c][choice][c][item]) {
                      localZAverages[ci][c][choice][c][item] = {};
                    }
                    if (!localZAverages[ci][c][choice][c][item][c]) {
                      localZAverages[ci][c][choice][c][item][c] = [];
                    }
                    if (!localZAverages[ci][c][choice][c][item][c][mcc]) {
                      localZAverages[ci][c][choice][c][item][c][mcc] = {};
                    }
                    if (!localZAverages[ci][c][choice][c][item][c][mcc][c]) {
                      localZAverages[ci][c][choice][c][item][c][mcc][c] = [];
                    }

                    if (!localZAverages[ci][c][choice][c][item][c][mcc][c][mci]) {
                      localZAverages[ci][c][choice][c][item][c][mcc][c][mci] = this.calculateAverages(
                        mcdistribution,
                        mcvalues,
                        1,
                        mctotalAnswers,
                        details[mciKey].scale,
                        mciKey,
                        Calculations.consensus(mciKey),
                      );
                    }
                  }
                  if (calculateList.globalZScores[mci]) {
                    if (!globalZAverages[ci]) {
                      globalZAverages[ci] = {};
                    }
                    if (!globalZAverages[ci][c]) {
                      globalZAverages[ci][c] = [];
                    }
                    if (!globalZAverages[ci][c][choice]) {
                      globalZAverages[ci][c][choice] = {};
                    }
                    if (!globalZAverages[ci][c][choice][c]) {
                      globalZAverages[ci][c][choice][c] = [];
                    }
                    if (!globalZAverages[ci][c][choice][c][item]) {
                      globalZAverages[ci][c][choice][c][item] = {};
                    }
                    if (!globalZAverages[ci][c][choice][c][item][c]) {
                      globalZAverages[ci][c][choice][c][item][c] = [];
                    }
                    if (!globalZAverages[ci][c][choice][c][item][c][mcc]) {
                      globalZAverages[ci][c][choice][c][item][c][mcc] = {};
                    }
                    if (!globalZAverages[ci][c][choice][c][item][c][mcc][c]) {
                      globalZAverages[ci][c][choice][c][item][c][mcc][c] = [];
                    }

                    if (!globalZAverages[ci][c][choice][c][item][c][mcc][c][mci]) {
                      globalZAverages[ci][c][choice][c][item][c][mcc][c][mci] = this.calculateAverages(
                        mcdistribution,
                        mcvalues,
                        2,
                        mctotalAnswers,
                        details[mciKey].scale,
                        mciKey,
                        Calculations.consensus(mciKey),
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Calculating z-scored averages
    if (Object.keys(localZAverages).length > 0) {
      for (const item in localZAverages) {
        const itemKey = indexes[item];
        if (calculateList.localZScores[item]) {
          let sum = 0;
          let n = 0;
          let workData;
          let lastWorkData;
          let varianceSum;

          for (const zItem of calculateList.localZScores[item]) {
            if (localZAverages[zItem]) {
              const val = localZAverages[zItem]['average'];

              if (this.isNumeric(val)) {
                sum += val;
                n += 1;
                lastWorkData = workData == null ? null : workData;
                workData = workData == null ? val : workData + (val - workData) / n;
                varianceSum = varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData);
              }
            }
          }

          const average = sum / n;
          // let varianceSum1 = 0;

          // for (const zItem of calculateList.localZScores[item]) {
          //   if (this.isNumeric(localZAverages[zItem]['average'])) {
          //     varianceSum1 += Math.pow(Number(localZAverages[zItem]['average']) - average, 2);
          //   }
          // }

          const variance = varianceSum / n;
          const std = Math.sqrt(variance);

          if (!baseStats[item]) {
            baseStats[item] = {};
          }
          const zValue =
            localZAverages[item] && localZAverages[item]['average'] != null
              ? std !== 0
                ? (localZAverages[item]['average'] - average) / std
                : 0
              : null;
          const scaledZValue = this.dp.scaleZAverage(zValue, this.dimensions[itemKey].valueScaleLinear);

          baseStats[item]['zAverage'] = scaledZValue;
          baseStats[item]['zStd'] = localZAverages[item] && localZAverages[item]['std'];
        }
        if (calculateList.crossStats[item]) {
          for (const ci in calculateList.crossStats) {
            const ciKey = indexes[ci];
            const comparisonChoices: string[] = [];
            if (this.comparisonDimension && this.comparisonDimension.key === ciKey) {
              for (const choice of this.comparisonDimension.values) {
                comparisonChoices.push(choice);
              }
            }
            for (const choice in distributions[0][ci]) {
              const choiceKey = details[ciKey].values[choice];
              if (comparisonChoices.length === 0 || comparisonChoices.indexOf(choiceKey) >= 0) {
                for (const csi of calculateList.crossStats[ci]) {
                  const csiKey = indexes[csi];
                  if (calculateList.localZScores[csi]) {
                    let sum = 0;
                    let n = 0;
                    let workData;
                    let lastWorkData;
                    let varianceSum;

                    for (const zItem of calculateList.localZScores[csi]) {
                      const val =
                        localZAverages[ci][c][choice][c][zItem] && localZAverages[ci][c][choice][c][zItem]['average'];
                      if (this.isNumeric(val)) {
                        sum += val;
                        n += 1;
                        lastWorkData = workData == null ? null : workData;
                        workData = workData == null ? val : workData + (val - workData) / n;
                        varianceSum = varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData);
                      }
                    }

                    const average = sum / n;
                    // let varianceSum1 = 0;

                    // for (const zItem of calculateList.localZScores[csi]) {
                    //   varianceSum1 += Math.pow(
                    //     Number(localZAverages[ci][c][choice][c][zItem]['average']) - average,
                    //     2
                    //   );
                    // }

                    const variance = varianceSum / n;
                    const std = Math.sqrt(variance);

                    const zValue =
                      localZAverages[ci][c][choice][c][csi] && localZAverages[ci][c][choice][c][csi]['average'] != null
                        ? std !== 0
                          ? (localZAverages[ci][c][choice][c][csi]['average'] - average) / std
                          : 0
                        : null;
                    const scaledZValue = this.dp.scaleZAverage(zValue, this.dimensions[csiKey].valueScaleLinear);

                    baseStats[ci][c][choice][c][csi]['zAverage'] = scaledZValue;
                    baseStats[ci][c][choice][c][csi]['zStd'] =
                      localZAverages[ci][c][choice][c][csi] && localZAverages[ci][c][choice][c][csi]['std'];
                  }
                  if (
                    calculateList.multiCrossStats[csi] &&
                    distributions[0][ci] &&
                    distributions[0][ci][choice] &&
                    distributions[0][ci][choice][1] &&
                    distributions[0][ci][choice][1][csi]
                  ) {
                    for (const mcc in distributions[0][ci][choice][1][csi]) {
                      const mccKey = details[csiKey].values[mcc];

                      for (const mci of calculateList.multiCrossStats[csi]) {
                        const mciKey = indexes[mci];

                        if (calculateList.localZScores[mci]) {
                          let sum = 0;
                          let n = 0;
                          let workData;
                          let lastWorkData;
                          let varianceSum;

                          for (const zItem of calculateList.localZScores[mci]) {
                            const val =
                              localZAverages[ci][c][choice][c][csi][c][mcc][c][zItem] &&
                              localZAverages[ci][c][choice][c][csi][c][mcc][c][zItem]['average'];
                            if (this.isNumeric(val)) {
                              sum += val;
                              n += 1;
                              lastWorkData = workData == null ? null : workData;
                              workData = workData == null ? val : workData + (val - workData) / n;
                              varianceSum =
                                varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData);
                            }
                          }

                          const average = sum / n;
                          // let varianceSum1 = 0;

                          // for (const zItem of calculateList.localZScores[mci]) {
                          //   if (this.isNumeric(
                          //     localZAverages[ci][c][choice][c][csi][c][mcc][c][zItem]['average']
                          //   )) {
                          //     varianceSum1 += Math.pow(Number(
                          //       localZAverages[ci][c][choice][c][csi][c][mcc][c][zItem]['average']
                          //       ) - average, 2);
                          //   }
                          // }

                          const variance = varianceSum / n;
                          const std = Math.sqrt(variance);

                          const zValue =
                            localZAverages[ci][c][choice][c][csi][c][mcc][c][mci] &&
                            localZAverages[ci][c][choice][c][csi][c][mcc][c][mci]['average'] != null
                              ? std !== 0
                                ? (localZAverages[ci][c][choice][c][csi][c][mcc][c][mci]['average'] - average) / std
                                : 0
                              : null;
                          const scaledZValue = this.dp.scaleZAverage(zValue, this.dimensions[mciKey].valueScaleLinear);

                          baseStats[ci][c][choice][c][csi][c][mcc][c][mci]['zAverage'] = scaledZValue;
                          baseStats[ci][c][choice][c][csi][c][mcc][c][mci]['zStd'] =
                            localZAverages[ci][c][choice][c][csi][c][mcc][c][mci] &&
                            localZAverages[ci][c][choice][c][csi][c][mcc][c][mci]['std'];
                        }
                        if (calculateList.globalZScores[mci]) {
                          let sum = 0;
                          let n = 0;
                          let workData;
                          let lastWorkData;
                          let varianceSum;

                          for (const zItem of calculateList.globalZScores[mci]) {
                            const val =
                              globalZAverages[ci][c][choice][c][csi][c][mcc][c][zItem] &&
                              globalZAverages[ci][c][choice][c][csi][c][mcc][c][zItem]['average'];
                            if (this.isNumeric(val)) {
                              sum += val;
                              n += 1;
                              lastWorkData = workData == null ? null : workData;
                              workData = workData == null ? val : workData + (val - workData) / n;
                              varianceSum =
                                varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData);
                            }
                          }

                          const average = sum / n;
                          // let varianceSum1 = 0;

                          // for (const zItem of calculateList.globalZScores[mci]) {
                          //   if (this.isNumeric(
                          //     globalZAverages[ci][c][choice][c][csi][c][mcc][c][zItem]['average']
                          //   )) {
                          //     varianceSum1 += Math.pow(Number(
                          //       globalZAverages[ci][c][choice][c][csi][c][mcc][c][zItem]['average']
                          //       ) - average, 2);
                          //   }
                          // }

                          const variance = varianceSum / n;
                          const std = Math.sqrt(variance);

                          const zValue =
                            globalZAverages[ci][c][choice][c][csi][c][mcc][c][mci] &&
                            globalZAverages[ci][c][choice][c][csi][c][mcc][c][mci]['average'] != null
                              ? std !== 0
                                ? (globalZAverages[ci][c][choice][c][csi][c][mcc][c][mci]['average'] - average) / std
                                : 0
                              : null;
                          const scaledZValue = this.dp.scaleZAverage(zValue, this.dimensions[mciKey].valueScaleLinear);

                          baseStats[ci][c][choice][c][csi][c][mcc][c][mci]['globalZAverage'] = scaledZValue;
                          baseStats[ci][c][choice][c][csi][c][mcc][c][mci]['globalZStd'] =
                            globalZAverages[ci][c][choice][c][csi][c][mcc][c][mci] &&
                            globalZAverages[ci][c][choice][c][csi][c][mcc][c][mci]['std'];
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Calculating z-scored averages
    if (Object.keys(globalZAverages).length > 0) {
      for (const item in globalZAverages) {
        const itemKey = indexes[item];
        if (calculateList.globalZScores[item]) {
          let sum = 0;
          let n = 0;
          let workData;
          let lastWorkData;
          let varianceSum;

          for (const zItem of calculateList.globalZScores[item]) {
            if (globalZAverages[zItem]) {
              const val = globalZAverages[zItem]['average'];
              if (this.isNumeric(val)) {
                sum += globalZAverages[zItem]['average'];
                n += 1;
                lastWorkData = workData == null ? null : workData;
                workData = workData == null ? val : workData + (val - workData) / n;
                varianceSum = varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData);
              }
            }
          }

          const average = sum / n;
          // let varianceSum1 = 0;

          // for (const zItem of calculateList.globalZScores[item]) {
          //   if (this.isNumeric(globalZAverages[zItem]['average'])) {
          //     varianceSum1 += Math.pow(Number(globalZAverages[zItem]['average']) - average, 2);
          //   }
          // }

          const variance = varianceSum / n;
          const std = Math.sqrt(variance);

          if (!baseStats[item]) {
            baseStats[item] = {};
          }
          const zValue =
            globalZAverages[item] && globalZAverages[item]['average'] != null
              ? std !== 0
                ? (globalZAverages[item]['average'] - average) / std
                : 0
              : null;
          const scaledZValue = this.dp.scaleZAverage(zValue, this.dimensions[itemKey].valueScaleLinear);

          baseStats[item]['globalZAverage'] = scaledZValue;
          baseStats[item]['globalZStd'] = globalZAverages[item] && globalZAverages[item]['std'];
        }
        if (calculateList.crossStats[item]) {
          for (const ci in calculateList.crossStats) {
            const ciKey = indexes[ci];
            const comparisonChoices: string[] = [];
            if (this.comparisonDimension && this.comparisonDimension.key === ciKey) {
              for (const choice of this.comparisonDimension.values) {
                comparisonChoices.push(choice);
              }
            }
            for (const choice in distributions[0][ci]) {
              const choiceKey = details[ciKey].values[choice];
              if (comparisonChoices.length === 0 || comparisonChoices.indexOf(choiceKey) >= 0) {
                for (const csi of calculateList.crossStats[ci]) {
                  if (calculateList.globalZScores[csi]) {
                    const csiKey = indexes[csi];
                    let sum = 0;
                    let n = 0;
                    let workData;
                    let lastWorkData;
                    let varianceSum;

                    for (const zItem of calculateList.globalZScores[csi]) {
                      const val =
                        globalZAverages[ci][c][choice][c][zItem] && globalZAverages[ci][c][choice][c][zItem]['average'];
                      if (this.isNumeric(val)) {
                        sum += val;
                        n += 1;
                        lastWorkData = workData == null ? null : workData;
                        workData = workData == null ? val : workData + (val - workData) / n;
                        varianceSum = varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData);
                      }
                    }

                    const average = sum / n;
                    // let varianceSum1 = 0;

                    // for (const zItem of calculateList.globalZScores[csi]) {
                    //   varianceSum1 += Math.pow(
                    //     Number(globalZAverages[ci][c][choice][c][zItem]['average']) - average, 2
                    //   );
                    // }

                    const variance = varianceSum / n;
                    const std = Math.sqrt(variance);

                    const zValue =
                      globalZAverages[ci][c][choice][c][csi] &&
                      globalZAverages[ci][c][choice][c][csi]['average'] != null
                        ? std !== 0
                          ? (globalZAverages[ci][c][choice][c][csi]['average'] - average) / std
                          : 0
                        : null;
                    const scaledZValue = this.dp.scaleZAverage(zValue, this.dimensions[csiKey].valueScaleLinear);

                    baseStats[ci][c][choice][c][csi]['globalZAverage'] = scaledZValue;
                    baseStats[ci][c][choice][c][csi]['globalZStd'] =
                      globalZAverages[ci][c][choice][c][csi] && globalZAverages[ci][c][choice][c][csi]['std'];
                  }
                }
              }
            }
          }
        }
      }
    }

    return baseStats;
  }

  /**
   * Helper function to calculate averages from distributions.
   *
   * @param distribution  Distribution to be used in calculation.
   * @param values        Actual values for distribution indexes.
   * @param valueType     Valuetype. 0=absolute, 1=localzscore, 2=globalzscore
   * @param totalAnswers  Number of answers in total.
   * @returns             Object containing average, count, std and percentage.
   */
  calculateAverages(distribution, values, valueType, totalAnswers, scale, key, consensus: boolean = false) {
    let sum = 0;
    let n = 0;
    let consensusSum = 0;
    // const consensusTotalAnswers = comparisonCount != null ? comparisonCount : totalAnswers;
    let workData;
    let lastWorkData;
    let varianceSum;
    for (const v in distribution) {
      if (distribution[v] && distribution[v][0] && distribution[v][0][valueType] != null) {
        const val = values[v];
        const amount = distribution[v][0][valueType];
        sum += Number(val) * amount;
        n += amount;

        if (amount > 0) {
          lastWorkData = workData == null ? null : workData;
          workData = workData == null ? val : workData + ((val - workData) / n) * amount;
          varianceSum = varianceSum == null ? 0 : varianceSum + (val - lastWorkData) * (val - workData) * amount;
        }

        if (consensus && scale === 'categorical' && amount > 0) {
          consensusSum += amount * (amount / totalAnswers);
        } else if (consensus && scale === 'linear' && amount > 0 && valueType === 0) {
          for (const s in distribution) {
            const sAmount =
              distribution[s] && distribution[s][0] && distribution[s][0][valueType]
                ? distribution[s][0][valueType]
                : 0;

            if (sAmount > 0) {
              consensusSum +=
                ((1 -
                  (Number(v) - Number(s) < 0 ? (Number(v) - Number(s)) * -1 : Number(v) - Number(s)) /
                    (values.length - 1)) *
                  amount *
                  sAmount) /
                totalAnswers;
            }
          }
        }
      }
    }

    const average = n > 0 ? sum / n : null;
    // let varianceSum1 = 0;

    // for (const value in distribution) {
    //   const val = values[value];
    //   if (distribution[value] && distribution[value][0] && distribution[value][0][valueType]) {
    //     varianceSum1 += Math.pow(Number(val) - average, 2) * distribution[value][0][valueType];
    //   }
    // }

    const variance = n > 0 ? varianceSum / n : null;
    const std = variance !== null ? Math.sqrt(variance) : null;

    return {
      key,
      average,
      count: n,
      std,
      percentage: n > 0 && totalAnswers > 0 ? n / totalAnswers : 0,
      consensus: n > 0 && consensusSum > 0 ? consensusSum / n : null,
      consensusSum: n > 0 && consensusSum > 0 ? (consensusSum / n) * totalAnswers : null,
      totalConsensus: n > 0 && consensusSum > 0 ? totalAnswers : null,
      responses: totalAnswers,
    };
  }

  /**
   * Updating new distributions & statistics to actual grid items.
   *
   * @param gridItems            GridItems to be updated with fresh data.
   * @param distributions        Distributions object.
   * @param customDistributions  Custom distributions object (for filter dimensions).
   * @param stats                Stats object.
   * @returns                    Updated grid items.
   */
  updateGridItems(gridItems: GridItemData, distributions, customDistributions, stats) {
    // this.consensus = 0;
    let consensusSums: number = 0;
    let totalConsensuses: number = 0;
    // We do not take account consensus for those listed default dimensions
    const usedConsensusKeys: string[] = [];
    const checkScaleChanges: (gridItem: GridItemDataItem) => boolean = (gridItem) =>
      JSON.stringify(gridItem.details.map((det) => det.scale)) !== JSON.stringify(gridItem.scales);
    const isFreezed: (gridItem: GridItemDataItem, i: string) => boolean = (gridItem, i) =>
      this.freezeTextTables &&
      this.isSharedReport &&
      (gridItem['scales'][i] === 'text' ||
        gridItem['scales'][i] === 'contact-text' ||
        !!gridItem['details'].find((det) => det.originalTypeSpecifier === 'text-sentiment') ||
        !!gridItem['details'].find((det) => det.originalTypeSpecifier === 'text-words') ||
        !!gridItem['details'].find((det) => det.originalTypeSpecifier === 'text-emotions'));

    for (const item in gridItems) {
      if (gridItems[item] && !gridItems[item].disabled) {
        let crosstabMaster;

        if (checkScaleChanges(gridItems[item])) {
          for (let d = 0, lend = gridItems[item]['details'].length; d < lend; d++) {
            if (gridItems[item]['scales'] && gridItems[item]['details'][d]?.['scale']) {
              gridItems[item]['scales'][d] = gridItems[item]['details'][d]['scale'];
            }
          }
        }

        if (gridItems[item].keys.length !== gridItems[item].distributions.length) {
          gridItems[item].distributions = gridItems[item].distributions.filter(
            (d, i) => i < gridItems[item].keys.length,
          );
          gridItems[item].stats = gridItems[item].stats.filter((d, i) => i < gridItems[item].keys.length);
          gridItems[item].totalAnswers = gridItems[item].totalAnswers.filter((d, i) => i < gridItems[item].keys.length);
        }

        for (const i in gridItems[item].keys) {
          const key = gridItems[item].keys[i];
          const index = this.answerData.indexes.indexOf(key);
          const updateNotAllowed: boolean = isFreezed(gridItems[item], i);

          if (customDistributions[key] && !updateNotAllowed) {
            gridItems[item].distributions[i] = customDistributions[key][0][index];
            gridItems[item].totalAnswers[i] =
              customDistributions[key][1][0][index] && customDistributions[key][1][0][index][0]
                ? customDistributions[key][1][0][index][0]
                : 0;
            if (crosstabMaster && crosstabMaster[index] != null) {
              gridItems[item].totalCrosstabAnswers = crosstabMaster[index];
            }
            crosstabMaster = customDistributions[key][1][0][index];
          } else if (!updateNotAllowed) {
            gridItems[item].distributions[i] = distributions[0][index];

            gridItems[item].totalAnswers[i] =
              distributions[1][0][index] && distributions[1][0][index][0] ? distributions[1][0][index][0] : 0;
            if (crosstabMaster && crosstabMaster[index] != null) {
              gridItems[item].totalCrosstabAnswers = crosstabMaster[index];
            }
            crosstabMaster = distributions[1][0][index];
          } else if (Object.keys(this.filters).length === 0) {
            if (customDistributions[key]) {
              gridItems[item].distributions[i] = JSON.parse(JSON.stringify(customDistributions[key][0][index] || []));
              gridItems[item].totalAnswers[i] =
                customDistributions[key][1][0][index] && customDistributions[key][1][0][index][0]
                  ? JSON.parse(JSON.stringify(customDistributions[key][1][0][index][0] || 0))
                  : 0;
              if (crosstabMaster && crosstabMaster[index] != null) {
                gridItems[item].totalCrosstabAnswers = JSON.parse(JSON.stringify(crosstabMaster[index] || 0));
              }
              crosstabMaster = JSON.parse(JSON.stringify(customDistributions[key][1][0][index] || []));
            } else {
              gridItems[item].distributions[i] = JSON.parse(JSON.stringify(distributions[0][index] || []));

              gridItems[item].totalAnswers[i] =
                distributions[1][0][index] && distributions[1][0][index][0]
                  ? JSON.parse(JSON.stringify(distributions[1][0][index][0] || 0))
                  : 0;
              if (crosstabMaster && crosstabMaster[index] != null) {
                gridItems[item].totalCrosstabAnswers = JSON.parse(JSON.stringify(crosstabMaster[index] || 0));
              }
              crosstabMaster = JSON.parse(JSON.stringify(distributions[1][0][index] || []));
            }
          }

          if (!updateNotAllowed) {
            gridItems[item].stats[i] = stats[index];
          } else if (Object.keys(this.filters).length === 0) {
            gridItems[item].stats[i] = JSON.parse(JSON.stringify(stats[index]));
          }

          if (Calculations.consensus(key) && usedConsensusKeys.indexOf(key) < 0 && stats[index]) {
            consensusSums += stats[index]['consensusSum'] || 0;
            totalConsensuses += stats[index]['totalConsensus'] || 0;
            usedConsensusKeys.push(key);
          }

          if (this.filters[key]) {
            gridItems[item].filters[i] = this.filters[key]['filter'];
          } else {
            gridItems[item].filters[i] = null;
          }
          if (key === 'time') {
            // this.dimensions['time'].values = Object.keys(gridItems[item].distributions[1]);
            gridItems[item].details[i].values = this.dimensions['time'].values;
          }

          // if (this.trendAnalysisOn && gridItems[item]['trend'] && gridItems[item]['trend']['timePeriod']) {
          //   gridItems[item]['trend']['timePeriod'] = this.getTimePeriod();
          // }
          gridItems[item].indexes[i] = index; // here we update dataindexes to be used in data converter
        }
        gridItems[item].timestamp = new Date();
        gridItems[item].filtersAll = this.filters;
        gridItems[item].timePeriod = this.timePeriod;
      }
    }

    if (usedConsensusKeys.length > 0) {
      this.consensus = consensusSums / totalConsensuses;
    }

    return gridItems;
  }

  /* FILTERING */

  /**
   * Public filter function that creates new cut filters to cut data from api calls.
   */
  public saveCropFilters() {
    this.loading.next(true);
    const newCropFilters = this.fc.convert(this.filters, this.mergeSettings, this.answerData.textLabels);

    for (const filter of newCropFilters) {
      const exists = this.cropFilters.find(
        (item) =>
          (item.id &&
            filter.id &&
            item.id === filter.id &&
            !(item.part != null && filter.part != null && item.part !== filter.part) &&
            item.filterType === filter.filterType) ||
          (item.property && filter.property && item.property === filter.property),
      );

      if (exists) {
        exists.values = filter.values;
      } else {
        this.cropFilters.push(filter);
      }
    }

    for (const filterKey in this.filters) {
      this.removeCrossfilterDimension(filterKey);
    }
    this.dataChange.next('Added crop filter');
    this.getNewAnswers(this.key, null, null, true);
  }

  /**
   * Public filter function that adds new cut filters to cut data from api calls.
   */
  public addCropFilter(filter) {
    this.loading.next(true);
    this.cropFilters.push(filter);
    this.dataChange.next('Added crop filter');
    this.getNewAnswers(this.key, null, null, true);
  }

  /**
   * Public remove cut filters function that removes desirable cut filters from cuting data from api calls.
   */
  public removeCropFilters(removed: string[]) {
    this.loading.next(true);
    this.cropFilters = this.cropFilters.filter((item) =>
      item.id ? removed.indexOf(item.id) < 0 : item.property ? removed.indexOf(item.property) < 0 : false,
    );
    this.dataChange.next('Removed crop filter');
    this.getNewAnswers(this.key, null, null, true);
  }

  /**
   * Public set filters demo on function that allows report to go filters demo mode.
   */
  public setFiltersDemoOn() {
    this.filtersDemo = true;
    this.sendNewCrossfilter();
  }

  /**
   * Sets used time period.
   */
  public changeTimePeriod(period: string) {
    this.loading.next(true);
    this.timePeriod = period;
    this.dataChange.next('Changed time period');
    // this.getNewAnswers(this.key, null, null, true);
    if (!!this.answerData?.indexes) {
      const newData = this.dp.changeTimePeriod(this.answerData, period, this.dimensions);
      this.setCrossfilter(newData.answers);
    }
  }

  /**
   * Public filter function that creates new filtering dimensions and recalculates distributiosn & stats.
   *
   * @param filter  Array of filters to be applied.
   */
  public filter(filters: FilterData[]) {
    const safeReport: boolean = this.isSharedReport && this.safeReport;
    this.filtersDemo = false;
    if (this.baseExists()) {
      if (safeReport || this.anonymityTreshold) {
        const filtersToDelete: string[] = [];

        for (const fKey in this.filters) {
          if (this.filters[fKey]?.safeReportCount != null && !filters.find((newF) => newF.key === fKey)) {
            filtersToDelete.push(fKey);
          }
        }

        if (filtersToDelete.length) {
          for (let i = 0, len = filtersToDelete.length; i < len; i++) {
            delete this.filters[filtersToDelete[i]];
          }
        }
      }

      for (const filter of filters) {
        const scale = this.dimensions[filter.key].scale;
        const textFilter: boolean = filter.textFilter ? filter.textFilter : false;
        let newDimension: boolean = false;
        let safeReportCount: number = null;

        if (!this.customDimensions[filter.key]) {
          this.newCrossfilterDimension(filter.key, scale, filter.values.length, textFilter);
          newDimension = true;
          // this.dataChange.next('Added selection');
        }
        if (filter.filter.length > 0) {
          this.filterDimension(filter.key, filter.filter, scale, textFilter);

          if (safeReport || this.anonymityTreshold) {
            const respondentCount = this.baseDimension.top(Infinity).length;

            if (
              (safeReport && respondentCount < (this.anonymityTreshold || 5)) ||
              (this.anonymityTreshold && respondentCount < this.anonymityTreshold)
            ) {
              safeReportCount = respondentCount;
              this.removeCrossfilterDimension(filter.key);
            }
          }

          this.filters[filter.key] = {
            filter: filter.filter,
            values: filter.values,
            labels: this.parseLabels(filter.filter, this.dimensions[filter.key], textFilter),
            dimensionData: this.dimensions[filter.key],
          };
          if (textFilter) {
            this.filters[filter.key]['textFilter'] = textFilter;
          }
          if (filter.customName) {
            this.filters[filter.key]['customName'] = filter.customName;
          }
          if (filter.key === 'time') {
            this.filters[filter.key]['timePeriod'] = this.timePeriod;
          }
          if (safeReportCount != null || this.filters[filter.key]['safeReportCount'] != null) {
            this.filters[filter.key]['safeReportCount'] = safeReportCount;
          }
          if (!newDimension) {
            // this.dataChange.next('Edited current selection');
          }
        } else {
          this.removeCrossfilterDimension(filter.key);
          // this.dataChange.next('Removed selection');
        }
      }
      this.gridItems = this.calculate(this.gridItems);

      this.sendNewCrossfilter();
    }
  }

  /**
   * Set new custom crossfilter dimension.
   *
   * @param key      Dimension key.
   * @param scale    Scale type used in this dimension.
   * @param classes  Number of classes in dimension (used in linear scales).
   */
  newCrossfilterDimension(key, scale, classes, textFilter: boolean = false) {
    const origin = this.dimensions[key].valueScaleLinear;
    const index = this.answerData.indexes.indexOf(key);

    this.customDimensions[key] = {};
    this.customDimensions[key].dimension = this.base.dimension((d) => {
      if (scale === 'linear' && origin.step != null) {
        return this.scaledAnswer(d[index] ? this.dimensions[key].values[d[index][0]] : 'na', origin, classes);
      } else if ((textFilter && scale === 'text') || scale === 'contact-text') {
        return d[index] && d[index][1] ? d[index][1] : 'na';
      } else {
        return d[index] ? d[index][0] : 'na';
      }
    });

    this.customDimensions[key].group = this.customDimensions[key].dimension.groupAll();
  }

  /**
   * Set filter to custom crossfilter dimension.
   *
   * @param key      Dimension key.
   * @param values   Array of filtering values.
   * @param scale    Scale type used in this dimension.
   */
  filterDimension(key, values, scale, textFilter: boolean = false) {
    let filterArr;
    if (scale === 'linear' && this.dimensions[key]?.valueScaleLinear?.step != null) {
      filterArr = values.map((v) => Number(v));
    } else if ((textFilter && scale === 'text') || scale === 'contact-text') {
      filterArr = values.map((v) => Number(v));
    } else {
      filterArr = values.map((v) => this.dimensions[key].values.indexOf(v));
    }

    const filterFunction = (d) => {
      if (filterArr.length > 0) {
        let match = false;
        if (typeof d === 'object') {
          for (const item of d) {
            if (filterArr.indexOf(item) >= 0) {
              match = true;
            }
          }
        } else {
          match = filterArr.indexOf(d) >= 0;
        }

        return match;
      } else {
        return true;
      }
    };

    const memoized = this.memoize(filterFunction);

    this.customDimensions[key].dimension.filterFunction((d) => memoized(d));
  }

  /**
   * Remove custom dimension from crossfilter.
   */
  removeCrossfilterDimension(key) {
    // Remove custom dimension and add it to calculation list

    if (this.customDimensions[key]) {
      this.customDimensions[key].group.dispose();
      this.customDimensions[key].dimension.dispose();
      delete this.customDimensions[key].dimension;
      delete this.customDimensions[key].group;
      delete this.customDimensions[key];
      delete this.filters[key];

      for (const item in this.gridItems) {
        if (this.gridItems[item]['filtersAll'] && this.gridItems[item]['filtersAll'][key]) {
          delete this.gridItems[item]['filtersAll'][key];
        }
      }
    }
  }

  /** SCALING FUNCTIONS FOR LINEAR DIMENSIONS **/

  /**
   * Scaling original answer to match wanted classes.
   *
   * @param answer   Original answer.
   * @param origin   Scale origins for the answer.
   * @param classes  Number of classes in dimension.
   * @returns        Class value.
   */
  scaledAnswer(answer: string | number, origin: SliderValuesData, classes: number) {
    const min = !origin || origin.min == null ? 0 : origin.min;
    const max = !origin || origin.max == null ? 100 : origin.max;
    const step = !origin || origin.step == null ? 1 : origin.step;

    if (answer !== 'na') {
      const value = Math.floor((Number(answer) - min) / ((max + step - min) / classes));
      return !isNaN(value) && value !== undefined ? value : 'na';
    } else {
      return 'na';
    }
  }

  /** OTHER HELPER FUNCTIONS **/

  /**
   * Parsing labels for answer or class values.
   *
   * @param values     Array of values.
   * @param dimension  DimensionData to be used in parsing.
   * @returns          Labels string.
   */
  parseLabels(values, dimension, textFilter) {
    let labels = '';

    for (const v of values) {
      let label;

      if (dimension.scale === 'categorical' && dimension.originalTypeSpecifier !== 'text-words') {
        label = dimension.labelsCategorical[v];
      } else if (dimension.scale === 'time') {
        label = new Date(Number(v)).toLocaleDateString();
      } else if (dimension.scale === 'contact-text') {
        label =
          this.getTextContacts()[dimension.key] && this.getTextContacts()[dimension.key][v]
            ? this.getTextContacts()[dimension.key][v]
            : '';
      } else if (dimension.originalTypeSpecifier === 'text-words') {
        const groupSeparator: string = '\u001D';
        label = this.getTextLabels()[dimension.key]
          ? (this.getTextLabels()[dimension.key][v] || '').toString().split(groupSeparator)[0]
          : v.toString();
      } else if (dimension.scale === 'text') {
        label = this.getTextAnswers()[dimension.key] ? this.getTextAnswers()[dimension.key][v] : v.toString();
      } else {
        label = v != null ? v.toString() : '';
      }
      if (dimension.originalTypeSpecifier !== 'text-words' || labels.split(', ').indexOf(label) < 0) {
        labels += label + ', ';
      }
    }

    return labels.slice(0, -2);
  }

  /**
   * Generating firebase pushID style key: https://gist.github.com/mikelehen/3596a30bd69384624c1
   *
   * @returns   key string.
   */
  public generateId() {
    const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
    const lastRandChars: number[] = [];

    let now = new Date().getTime();
    const duplicateTime = now === this.lastIdGenerationTime;
    this.lastIdGenerationTime = now;

    const timeStampChars = new Array(8);
    for (let i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0) {
      throw new Error('We should have converted the entire timestamp.');
    }

    let id = timeStampChars.join('');

    if (!duplicateTime) {
      for (let i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      let inc;
      for (let i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
        inc = i;
      }
      lastRandChars[inc]++;
    }
    for (let i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length !== 20) {
      throw new Error('Length should be 20.');
    }

    return id;
  }

  /**
   * Memoization function for memoizing functions.
   *
   * @param fn  Function to be memoized.
   * @returns   Memoized function.
   */
  memoize(fn) {
    const cache = {};
    return (...args) => {
      const stringifiedArgs = JSON.stringify(args);
      const result = (cache[stringifiedArgs] = cache[stringifiedArgs] || fn(...args));

      return result;
    };
  }

  /**
   * Memoization function for memoizing functions.
   *
   * @param fn  Function to be memoized.
   * @returns   Memoized function.
   */
  memoizeDistributions(fn) {
    const cache = {};
    let previous = '';
    return (...args) => {
      const stringifiedArgs = JSON.stringify(args);
      const result = (cache[stringifiedArgs] =
        (stringifiedArgs === previous ? cache[stringifiedArgs] : false) || fn(...args));
      previous = stringifiedArgs;

      return result;
    };
  }

  isNumeric(value: any): boolean {
    return !isNaN(value - parseFloat(value));
  }
}

import { BehaviorSubject, concatMap, merge, Observable, of, scan, Subject, tap, throwError } from 'rxjs';
import {
  buffer,
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ZefApi } from '@shared/services/zef-api.service';
import { shareRef } from '@shared/operators/share-ref.operator';
import { randomString } from '@shared/utilities/string.utilities';
import { filterEmpty, hasEqualProperties } from '@shared/utilities/object.utilities';
import { Store } from '@ngxs/store';
import { ACCOUNT_STATE_TOKEN } from '@shared/states/account.models';
import { TeamData } from '@shared/models/account.model';

type AccessType = 'READ' | 'UPDATE' | 'SET' | 'REMOVE' | 'PUSH' | 'UI-LOAD';

type AuthType = 'LOGIN' | 'LOGOUT';

type AccessStatus = 'DISPATCHED' | 'SUCCESS' | 'FAIL';

interface AccessEntry {
  key?: string;
  path?: string;
  access: AccessType | AuthType;
  status: AccessStatus;
  timestamp?: string;
  data?: object | string;
  error?: string;
  teamKey?: string;
  console?: boolean;
}

interface AuthEntry {
  email: string;
  status: AccessStatus;
  auth: AuthType;
  error?: string;
}

interface DataAccess {
  access: AccessType | AuthType;
  path?: string;
  teamKey?: string;
  data?: object | string;
  fromConsole?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuditLogService {
  private activeCount = new Subject<number>();

  private active = new BehaviorSubject(false);

  private log$ = new Subject<AccessEntry>();

  readonly filteredPaths = ['/prefs/', '/invites', '/templates/', '/statuses/', '/translations', '/helpcenter'];

  readonly active$ = this.active.asObservable();

  private team$: Observable<TeamData> = this.store.select(ACCOUNT_STATE_TOKEN).pipe(
    map((state) => state?.team),
    filter((team) => !!team),
    distinctUntilChanged((a, b) => a.$key === b.$key),
  );

  private logs$: Observable<AccessEntry[]> = this.log$.pipe(
    map((entry) => this.entryToLog(entry)),
    buffer(this.log$.pipe(debounceTime(2000))),
    map((entries) =>
      entries.filter(
        (entry, i) =>
          i === entries.findIndex((target) => hasEqualProperties(entry, target, ['access', 'path', 'status', 'data'])),
      ),
    ),
    shareRef(),
  );

  private allReadLogs$: Observable<AccessEntry[]> = merge(
    this.team$.pipe(map(() => null)),
    this.logs$.pipe(map((logs) => logs.filter((log) => log.access === 'READ'))),
  ).pipe(
    scan((a, b: AccessEntry[] | null) => (b && a.concat(b)) || [], [] as AccessEntry[]),
    delay(1),
    startWith([]),
    shareRef(),
  );

  constructor(private za: ZefApi, private store: Store) {
    this.logs$
      .pipe(
        withLatestFrom(this.allReadLogs$),
        map(([newLogs, allLogs]) =>
          newLogs.filter((log) => log.access !== 'READ' || allLogs.every((allLog) => allLog.path !== log.path)),
        ),
        filter((logs) => logs.length > 0),
        mergeMap((entries) => this.sendLogRequest('audit', { entries })),
      )
      .subscribe();

    this.activeCount
      .pipe(
        scan((a, b) => a + b, 0),
        map((count) => !!count),
        distinctUntilChanged(),
      )
      .subscribe((active) => this.active.next(active));

    this.team$.pipe(filter((team) => team?.adminSettings?.auditLogging)).subscribe((team) => {
      this.queueDataLog({ status: 'SUCCESS', access: 'READ', path: `/teams/${team.$key}` });
    });
  }

  logDataAccess<T extends Observable<unknown> | Promise<void>>(target: T, data: DataAccess): T {
    if (data.path && this.filteredPaths.some((filteredPath) => data.path.startsWith(filteredPath))) {
      return target;
    }

    if (target instanceof Observable) {
      return this.logObservableAccess(target, data);
    } else {
      return this.logPromiseModifyAccess(target, data) as T;
    }
  }

  logAuthAccess<T>(target: Observable<T>, auth: AuthType, email?: string): Observable<T> {
    return target.pipe(
      tap((userEmail) =>
        this.sendAuthLog({ status: 'SUCCESS', auth, email: email || userEmail?.toString() }).subscribe(),
      ),
      catchError((e) =>
        this.sendAuthLog({ status: 'FAIL', auth, email: email || e.email, error: e.errorCode || e.code }).pipe(
          concatMap(() => throwError(e)),
        ),
      ),
    );
  }

  queueDataLog(entry: AccessEntry): void {
    this.log$.next(entry);
  }

  sendAuthLog(entry: Partial<AuthEntry>): Observable<unknown> {
    return this.sendLogRequest('auth', {
      status: entry.status,
      email: entry.email,
      error: entry.error,
      access: entry.auth,
    });
  }

  private sendLogRequest(type: 'audit' | 'auth', data: any): Observable<unknown> {
    this.activeCount.next(1);

    return this.za.post(type === 'auth' ? 'auth/log' : 'organization/auditLog', data).pipe(
      catchError(() => of(null)),
      finalize(() => this.activeCount.next(-1)),
    );
  }

  private logPromiseModifyAccess(target: Promise<void>, data: DataAccess): Promise<void> {
    const key = randomString(8);

    this.log$.next({ ...data, key, status: 'DISPATCHED' });

    return target
      .then(() => {
        this.log$.next({ ...data, key, status: 'SUCCESS' });
      })
      .catch((e) => {
        this.log$.next({ ...data, key, status: 'FAIL' });
        throw e;
      });
  }

  private logObservableAccess<T extends Observable<unknown>>(target: T, data: DataAccess): T {
    let firstResponse = true;

    return target.pipe(
      tap({
        next: () => {
          if (firstResponse) {
            firstResponse = false;
            this.log$.next({ ...data, status: 'SUCCESS' });
          }
        },
        error: () => {
          if (firstResponse) {
            firstResponse = false;
            this.log$.next({ ...data, status: 'FAIL' });
          }
        },
      }),
    ) as T;
  }

  private entryToLog(entry: Partial<AccessEntry & AuthEntry & DataAccess>): AccessEntry {
    return filterEmpty({
      access: entry.access || entry.auth,
      status: entry.status,
      key: entry.key,
      path: entry.path,
      error: entry.error,
      teamKey: entry.teamKey,
      console: entry.console || entry.fromConsole,
      data: entry.data && JSON.stringify(entry.data),
      timestamp: new Date().toISOString(),
    });
  }
}

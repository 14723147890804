<ng-container *ngIf="image?.url && ss.colors | async as colors; else noImage">
  <div
    class="z-picture-image"
    [style.background-image]="image.url | encodeURI : 'url' | safeStyle"
    [style.background-position]="image.align"
    [style.background-size]="image.mode"
    [style.background-color]="image.color"
    [unsplashAttribute]="image.url"
  ></div>

  <div *ngIf="zoom" class="z-zoom" (click)="$event.stopPropagation(); zoomImage()">
    <div class="z-zoom-box z-button-ghost" [style.background-color]="colors.mood80">
      <mat-icon class="z-zoom-icon" fontSet="zefplayer" [style.color]="colors.moodContrast">zoom</mat-icon>
    </div>
  </div>

  <ng-container *ngIf="ss.viewRect$ | async as rect">
    <div
      *cdkPortal
      [style.width.px]="rect.width"
      [style.height.px]="rect.height"
      [@zoom]="{ value: showZoom, params: zoomParams$ | async }"
      (@zoom.done)="onAnimationDone($event)"
      (click)="closeZoom()"
    >
      <div
        *ngIf="ss.viewSize | async as size"
        class="z-zoom-container"
        [ngClass]="'z-size-' + size"
        [style.background-color]="colors.mood"
        #container
      >
        <img
          class="z-zoom-picture"
          [src]="image.url"
          pictureZoom
          [pictureZoomContainer]="container"
          [ngClass]="rect.width / rect.height > imageProportion ? 'z-max-height' : 'z-max-width'"
          (load)="onLoad($any($event.target))"
        />
        <div
          class="z-zoom-close z-button-ghost"
          [class.z-size-big]="size !== 'lg' && size !== 'xl'"
          [style.background-color]="colors.mood80"
          (click)="closeZoom()"
        >
          <mat-icon class="z-zoom-icon" fontSet="zefplayer" [style.color]="colors.moodContrast">close</mat-icon>
        </div>

        <div class="z-zoom-controls" (pointerdown)="$event.stopPropagation()" (click)="$event.stopPropagation()">
          <div
            class="z-zoom-in z-button-ghost"
            [class.z-size-big]="size !== 'lg' && size !== 'xl'"
            [style.background-color]="colors.text80"
            (click)="pictureZoom?.zoomIn()"
          >
            <mat-icon class="z-zoom-icon" fontSet="zefplayer" [style.color]="colors.mood">plus</mat-icon>
          </div>

          <div
            class="z-zoom-out z-button-ghost"
            [class.z-size-big]="size !== 'lg' && size !== 'xl'"
            [style.background-color]="colors.text80"
            (click)="pictureZoom?.zoomOut()"
          >
            <mat-icon class="z-zoom-icon" fontSet="zefplayer" [style.color]="colors.mood">minus</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #noImage>
  <mat-icon class="z-no-image" fontSet="zefplayer" [style.color]="(ss.colors | async).text30">no_image</mat-icon>
</ng-template>

import { MessageData, MessageKind } from '@shared/models/email.model';
import { SurveyShareLink, SurveyMessage } from '@shared/models/survey-shares.model';

export class GetSurveyInvite {
  static type = '[SurveyShares] - Get survey invite';

  constructor(readonly inviteKey: string, readonly kind: MessageKind) {}
}

export class GetSurveyInviteReminders {
  static type = '[SurveyShares] - Get survey invite reminders';

  constructor(readonly inviteKey: string) {}
}

export class GetSurveyShares {
  static type = '[SurveyShares] - Get active survey shares';

  constructor(readonly surveyKey: string) {}
}

export class CreateSurveyShareLink {
  static type = '[SurveyShares] - Create survey shares link';

  constructor(readonly shareLink: Pick<SurveyShareLink, 'survey' | 'name' | 'tags' | 'type'>) {}
}

export class UpdateSurveyShareLink {
  static type = '[SurveyShares] - Update survey shares link';

  constructor(readonly shareLink: SurveyShareLink) {}
}

export class CreateSurveyInvite {
  static type = '[SurveyShares] - Create survey invite';

  constructor(readonly invite: Partial<MessageData>) {}
}

export class UpdateSurveyInvite {
  static type = '[SurveyShares] - Update survey invite';

  constructor(readonly inviteKey: string, readonly update: Partial<SurveyMessage>) {}
}

export class UpdateUniqueContactsCount {
  static type = '[SurveyShares] - Update unique contacts count';

  constructor(readonly inviteKey: string) {}
}

export class AddListToInvite {
  static type = '[SurveyShares] - Add List To Invite';

  constructor(readonly inviteKey: string, readonly surveyKey: string, readonly list: number) {}
}

export class UpdateInviteImportStatus {
  static type = '[SurveyShares] - Update import status';

  constructor(readonly inviteKey: string, readonly importing: boolean) {}
}

export class SendSurveyInvite {
  static type = '[SurveyShares] - Send survey invite';

  constructor(readonly inviteKey: string) {}
}

export class ScheduleSurveyInvite {
  static type = '[SurveyShares] - Schedule survey invite';

  constructor(readonly inviteKey: string, readonly skipOther?: boolean) {}
}

export class CancelScheduleSurveyInvite {
  static type = '[SurveyShares] - Cancel schedule survey invite';

  constructor(readonly inviteKey: string) {}
}

export class AutomateSurveyInvite {
  static type = '[SurveyShares] - Automate survey invite';

  constructor(readonly inviteKey: string) {}
}

export class PauseSurveyInviteAutomation {
  static type = '[SurveyShares] - Pause Automate survey invite';

  constructor(readonly inviteKey: string, readonly pause: boolean) {}
}

export class SwitchSurveyInvite {
  static type = '[SurveyShares] - Switch survey invite';

  constructor(readonly inviteKey: string, readonly kind: MessageKind) {}
}

export class DeleteSurveyInvite {
  static type = '[SurveyShares] - Delete survey invite';

  constructor(readonly inviteKey: string, readonly survey: string, readonly deleteResponses: boolean) {}
}

export class DeleteShareLink {
  static readonly type = '[SurveyShares] Delete share link';

  constructor(readonly shareLink: SurveyShareLink, readonly deleteResponses: boolean) {}
}

export class ShowDraftWarnings {
  static readonly type = '[SurveyShares] Show Draft Warnings';
}

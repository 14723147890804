import { Component, Input, OnInit } from '@angular/core';
import { random } from '@shared/utilities/array.utilities';

@Component({
  selector: 'random-background',
  templateUrl: './random-bg.component.html',
  styleUrls: ['./random-bg.component.scss'],
})
export class RandomBackground implements OnInit {
  public backgroundImage: string | null = null;
  @Input() images: string[] = [
    'assets/decos/banner-random-1.jpg',
    'assets/decos/banner-random-2.jpg',
    'assets/decos/banner-random-3.jpg',
    'assets/decos/banner-random-4.jpg',
    'assets/decos/banner-random-5.jpg',
    'assets/decos/banner-random-6.jpg',
    'assets/decos/banner-random-7.jpg',
    'assets/decos/banner-random-8.jpg',
  ];

  ngOnInit(): void {
    this.backgroundImage = random(this.images);
  }
}

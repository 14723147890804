import { BehaviorSubject, combineLatest, merge, Observable, timer } from 'rxjs';
import { delay, map, mapTo, switchMap, take, takeUntil, tap, withLatestFrom, shareReplay } from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { QuestionData } from '@shared/models/survey.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { raceFromEvent } from '@shared/operators/race-from-event.operator';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Questions } from '@shared/enums/questions.enum';

enum MoveState {
  Start,
  Move,
  End,
}

@Component({
  selector: 'questions-scrollbar',
  templateUrl: './questions-scrollbar.component.html',
  styleUrls: ['./questions-scrollbar.component.scss'],
  providers: [LifecycleHooks],
})
export class QuestionsScrollbar implements AfterViewInit {
  readonly length = this.ss.cardQuestions.pipe(
    map(({ length }) => length),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  @Input() answers: { [key: string]: any } = {};

  @Input() set index(index: number) {
    if (!this.dragging) {
      this.activeIndex.next(index);
    }

    this.draggingIndex = index;
  }

  @ViewChild('thumb')
  thumb?: ElementRef<HTMLElement>;
  thumbTop?: Observable<number>;
  thumbInit?: Observable<boolean>;

  activeIndex = new BehaviorSubject<number>(0);
  indexChange = this.activeIndex.asObservable().pipe(
    switchMap(() =>
      timer(0, 1000).pipe(
        take(2),
        map((i) => !i),
      ),
    ),
  );
  dragging = false;

  @ViewChild('bar')
  bar?: ElementRef<HTMLElement>;
  barRect?: ClientRect;
  offset = 0;

  indexLabel?: Observable<string>;

  private draggingIndex = 0;

  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() openMenu: EventEmitter<void> = new EventEmitter();

  constructor(
    readonly elRef: ElementRef,
    readonly lh: LifecycleHooks,
    readonly ss: SurveyStore,
    readonly cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.thumb) {
      this.thumbTop = merge(
        combineLatest([this.activeIndex, this.length]).pipe(map(([index, length]) => (index + 0.5) / length)),
        this.mergeEvents(this.thumb.nativeElement, ['mousedown', 'touchstart'], MoveState.Start).pipe(
          tap(() => (this.dragging = true)),
          switchMap(() =>
            this.mergeEvents(document, ['mousemove', 'touchmove'], MoveState.Move).pipe(
              takeUntil(
                this.mergeEvents(document, ['mouseup', 'touchend'], MoveState.End).pipe(
                  tap(() => {
                    this.dragging = false;
                    this.activeIndex.next(this.draggingIndex);
                  }),
                ),
              ),
            ),
          ),
        ),
      ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

      this.thumbInit = this.thumbTop.pipe(delay(1), mapTo(true));

      this.indexLabel = combineLatest([this.thumbTop, this.ss.cardQuestions, this.ss.normalQuestions]).pipe(
        map(([top, cardQuestions, normalQuestions]) => {
          const idx = this.topToIndex(top, cardQuestions.length);
          const question = cardQuestions[idx];

          if (!question) {
            return '';
          }

          const isInfo = Questions.group(question) || question.type === Questions.INFO_TEXT;

          if (isInfo) {
            return 'info';
          }

          const realIdx = normalQuestions.findIndex((q) => q.$key === question.$key);

          return `${realIdx + 1}/${normalQuestions.length}`;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );

      this.cd.markForCheck();
    }
  }

  gotoIndex(value: number) {
    this.length.pipe(take(1)).subscribe((length) => {
      if (0 <= value && value <= length) {
        this.change.emit(value);
      }
    });
  }

  isAnswered(question: QuestionData): boolean {
    return !!question && this.answers[question.$key] != null;
  }

  topToIndex(top: number, length: number): number {
    return Math.min(length - 1, Math.floor(top * length));
  }

  trackByKey(index: number, question: QuestionData): string {
    return question.$key;
  }

  private mergeEvents(element: Node, events: (keyof HTMLElementEventMap)[], state: MoveState): Observable<number> {
    return raceFromEvent(element, events).pipe(
      map((event: MouseEvent | TouchEvent) => (event instanceof MouseEvent ? event : event.touches[0])),
      map((event) => event || { clientY: 0 }),
      tap(({ clientY }) => {
        if (state === MoveState.Start && this.thumb && this.bar && element instanceof HTMLElement) {
          this.barRect = this.bar.nativeElement.getBoundingClientRect();
          const thumbRect = element.getBoundingClientRect();
          this.offset = clientY - thumbRect.top - thumbRect.height / 2;
        }
      }),
      map(({ clientY }) =>
        Math.min(1, Math.max(0, clientY - this.barRect.top - (this.offset || 0)) / this.barRect.height),
      ),
      withLatestFrom(this.length),
      tap(([top, length]) => (state !== MoveState.End ? this.gotoIndex(this.topToIndex(top, length)) : void 0)),
      map(([top]) => top),
      takeUntil(this.lh.destroy),
    );
  }
}

/**
 * Loader to load data from internal database. Used in editor
 *
 * @unstable
 */
import { Observable, of, combineLatest, switchMap, ReplaySubject } from 'rxjs';

import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { shareRef } from '@shared/operators/share-ref.operator';
import { SurveyState } from '@shared/states/survey.state';
import { TemplatesState } from '@shared/states/templates.state';
import { SurveysManager } from '@shared/services/surveys-manager.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { LoaderInterface } from '@shared/models/loader.model';
import { TranslationData } from '@shared/models/locale.model';

@Injectable()
export class DatabaseLoader implements LoaderInterface {
  private currentLanguage$ = new ReplaySubject<string>(1);

  constructor(private db: DatabaseWrapper, readonly store: Store, readonly sm: SurveysManager) {}

  readonly locale$: Observable<TranslationData> = combineLatest([
    this.store.select(SurveyState.activeSurveyKey),
    this.store.select(TemplatesState.previewActive),
    this.store.select(TemplatesState.activeTemplate),
    this.currentLanguage$,
  ]).pipe(
    switchMap(([surveyKey, preview, template, lang]) => {
      if (surveyKey && !preview) {
        return this.store.select(SurveyState.locales(surveyKey)).pipe(
          map((locales) => locales?.strings?.[lang] || {}),
          shareRef(),
        );
      } else if (template) {
        return combineLatest([
          this.db
            .object<TranslationData>(`/templates/surveys/${template.category}/${template.key}/locales/strings/${lang}`)
            .valueChanges(),
          this.db.object<TranslationData>(`templates/translations/${lang}`).valueChanges(),
        ]).pipe(
          map(([templateTranslations, defaultTranslations]) => ({
            ...(defaultTranslations || {}),
            ...(templateTranslations || {}),
          })),
          shareRef(),
        );
      } else {
        of({});
      }
    }),
    shareRef(),
  );

  fetchLocale(lcid: string): Observable<TranslationData> {
    this.currentLanguage$.next(lcid);

    return this.locale$;
  }

  setRootPath(path: string) {}
}

/**
 * Route animation for route changes.
 *
 * @unstable
 */

import { trigger, state, style, transition, animate } from '@angular/animations';

export const RouteAnimation = trigger('routeAnimation', [
  state(
    '*',
    style({
      opacity: 1,
    }),
  ),

  state(
    'void',
    style({
      opacity: 0,
    }),
  ),

  transition(':enter', [
    style({
      'position': 'absolute',
      'z-index': 10,
      'top': '0',
      'right': '0',
      'bottom': '0',
      'left': '0',
      'opacity': 0,
    }),
    animate(
      '250ms',
      style({
        opacity: 1,
      }),
    ),
  ]),

  transition(':leave', [
    style({
      'position': 'absolute',
      'z-index': 9,
      'top': '0',
      'right': '0',
      'bottom': '0',
      'left': '0',
      'opacity': 1,
    }),
    animate(
      '250ms',
      style({
        opacity: 0,
      }),
    ),
  ]),
]);

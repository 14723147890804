import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Directive } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import { Select } from '@ngxs/store';

import { shareRef } from '@shared/operators/share-ref.operator';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { SurveyIntegrationInstanceService } from '@shared/modules/survey-integration/services/survey-integration-instance.service';
import {
  IntegrationSurveyStatus,
  IntegrationSurveyFeature,
  IntegrationLabels,
  IntegrationFeatureSetting,
} from '@shared/models/integrations.model';

import { Status } from '@shared/models/status.model';
import { ActionsState } from '@shared/states/actions.state';
import { SyncSurveyIntegration } from '@shared/states/integrations.actions';

@Directive()
export abstract class SurveyIntegrationFeature<T extends IntegrationFeatureSetting = IntegrationFeatureSetting> {
  @Select(ActionsState.whileAction(SyncSurveyIntegration))
  readonly syncing$!: Observable<boolean>;

  abstract update$: Observable<void>;

  abstract feature: IntegrationSurveyFeature;

  readonly Status = IntegrationSurveyStatus;

  readonly StatusIcon = Status;

  readonly Feature = IntegrationSurveyFeature;

  readonly Subject = HelpSubject;

  readonly Labels = IntegrationLabels;

  readonly settings$: Observable<T> = this.sii.surveyIntegration$.pipe(
    map(({ features }) => features[this.feature] as unknown as T),
    shareRef(),
  );

  protected constructor(readonly sii: SurveyIntegrationInstanceService, protected fb: UntypedFormBuilder) {}

  abstract getSettings(): T;
}

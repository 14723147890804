import { fromEvent, Subject, merge, from, race, timer } from 'rxjs';
import { map, takeUntil, startWith, tap, switchMap, filter, mapTo, debounceTime } from 'rxjs/operators';

import { Directive, ElementRef, AfterViewInit, DoCheck, Input } from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { Router, NavigationEnd } from '@angular/router';

@Directive({
  selector: 'input[autoSize]',
  providers: [LifecycleHooks],
})
export class AutoSize implements AfterViewInit, DoCheck {
  private input = this.el.nativeElement;

  private ctx = document.createElement('canvas').getContext('2d');

  private check$ = new Subject<string>();

  private previousValue?: string;

  @Input()
  autoSizeOffset: number = 0;

  constructor(private el: ElementRef<HTMLInputElement>, private lh: LifecycleHooks, private router: Router) {}

  ngDoCheck(): void {
    const newValue = this.getValue();

    if (newValue !== this.previousValue) {
      this.check$.next(newValue);
    }
  }

  ngAfterViewInit(): void {
    this.setFontToCtx();

    race(from((document as any).fonts?.ready || Promise.resolve()), timer(2000))
      .pipe(
        startWith(0),
        switchMap(() =>
          merge(
            fromEvent(this.input, 'input').pipe(
              map(() => this.getValue()),
              startWith(this.getValue()),
            ),
            this.check$,
          ),
        ),
        tap((text) => (this.previousValue = text)),
        switchMap((text) =>
          this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            debounceTime(1),
            startWith(0),
            mapTo(text),
          ),
        ),
        map((text) => {
          this.setFontToCtx();

          return this.ctx.measureText(text).width;
        }),
        takeUntil(this.lh.destroy),
      )
      .subscribe((width) => (this.input.style.width = `${width + this.autoSizeOffset}px`));
  }

  private getValue(): string {
    return this.input.value || this.input.placeholder || this.input.dataset.placeholder || '';
  }

  private setFontToCtx(): void {
    const style = window.getComputedStyle(this.input);

    // twice because bug with font weight
    this.ctx.font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
    this.ctx.font = `${style.fontWeight} ${style.fontSize} ${style.fontFamily}`;
  }
}

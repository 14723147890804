<div class="container zef-no-mat-input-number" [class.disabled]="disabled">
  <input
    matInput
    [value]="parseValue(value)"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [class.zef-input-space]="space"
    (keydown.arrowUp)="onArrowKeyDown('increment', $event)"
    (keydown.arrowDown)="onArrowKeyDown('decrement', $event)"
    (keyup.arrowUp)="onArrowKeyUp('increment')"
    (keyup.arrowDown)="onArrowKeyUp('decrement')"
    (blur)="onBlur()"
    (input)="validateNumericValue($event.target.value)"
    (change)="$event.stopPropagation()"
  />

  <div class="z-fx-col z-fx-center-center input-arrows" matSuffix>
    <mat-icon class="input-arrow zef-icon-small" tabindex="-1" (pointerdown)="startIncrement()">pointer_up</mat-icon>

    <mat-icon class="input-arrow zef-icon-small" tabindex="-1" (pointerdown)="startDecrement()">pointer_down</mat-icon>
  </div>
</div>

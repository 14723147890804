import { Observable, of, combineLatest } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  mapTo,
  shareReplay,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import {
  EmailRecord,
  EmailSearchParams,
  SearchParams,
  SearchPath,
  SearchRecord,
  SearchResult,
  SearchTeamDataPath,
  SearchUserDataPath,
  SurveyRecord,
  SurveySearchParams,
  TeamRecord,
  UserRecord,
  UserSearchParams,
} from '@shared/models/search.model';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { AccountState } from '@shared/states/account.state';
import { assertStoreData } from '@shared/utilities/store.utilities';
import { GetOwner, GetSurvey, GetDesign } from '@shared/states/survey.actions';
import { DesignData, SurveyData } from '@shared/models/survey.model';
import { IdentityData } from '@shared/models/prefs.model';

@Injectable({
  providedIn: 'root',
})
export class SearchApi {
  constructor(private za: ZefApi, private store: Store, private db: DatabaseWrapper) {}

  searchUsers(params: UserSearchParams, admin?: boolean, teamKey?: string): Observable<SearchResult<UserRecord>> {
    return this.search<UserRecord>(SearchTeamDataPath.Users, params, admin, teamKey);
  }

  searchTeams(params: SearchParams, admin?: boolean): Observable<SearchResult<TeamRecord>> {
    return this.search<TeamRecord>(admin ? SearchTeamDataPath.Teams : SearchUserDataPath.Teams, params, admin);
  }

  searchSurveys(params: SurveySearchParams): Observable<SearchResult<SurveyRecord>> {
    return this.search<SurveyRecord>(SearchUserDataPath.Surveys, params).pipe(
      switchMap((response: SearchResult<SurveyRecord>) => {
        const userKey = this.store.selectSnapshot(AccountState.userKey);

        return (
          response.result?.length
            ? combineLatest(
                response.result.map(({ key }) =>
                  assertStoreData(this.store, (state) => state.survey[key]?.owner, new GetOwner(key)).pipe(
                    switchMap((owner: IdentityData) =>
                      !params.showAll && owner?.uid !== userKey
                        ? of(null)
                        : combineLatest([
                            assertStoreData(this.store, (state) => state.survey[key]?.survey, new GetSurvey(key)).pipe(
                              map((survey: SurveyData) => survey?.name || $localize`Untitled Survey`),
                            ),
                            assertStoreData(this.store, (state) => state.survey[key]?.design, new GetDesign(key)).pipe(
                              map((design: DesignData) => design?.background),
                              map((bg) => bg?.thumb || bg?.url || bg?.source || 'assets/images/survey-placeholder.png'),
                            ),
                          ]).pipe(
                            map(([name, image]) => ({
                              name,
                              key,
                              owner: owner?.name || $localize`Unknown`,
                              image,
                            })),
                          ),
                    ),
                  ),
                ),
              )
            : of([])
        ).pipe(
          map(
            (result: SurveyRecord[]) =>
              ({
                ...response,
                result: result.filter((survey) => !!survey),
              } as SearchResult<SurveyRecord>),
          ),
        );
      }),
    );
  }

  searchEmails(params: EmailSearchParams): Observable<SearchResult<EmailRecord>> {
    params.excludeTypes = Array.isArray(params.excludeTypes) ? params.excludeTypes.join(',') : params.excludeTypes;

    return this.search<EmailRecord>(SearchTeamDataPath.Emails, params);
  }

  private search<T extends SearchRecord>(
    path: SearchPath,
    params: SearchParams,
    admin?: boolean,
    key?: string,
  ): Observable<SearchResult<T>> {
    const url = `${admin ? 'admin/' : ''}search/${path}${key ? `/${key}` : ''}`;

    params.query = params.query?.trim() || '';

    return this.statusUpdate(path, key).pipe(
      switchMap(() => this.za.get<SearchResult<T>>(url, params)),
      catchError(() => of({ result: [], totalCount: 0, count: 0 })),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private statusUpdate(path: SearchPath, key?: string): Observable<boolean> {
    key =
      key ||
      (Object.values(SearchUserDataPath).includes(path as any)
        ? this.store.selectSnapshot(AccountState.userKey)
        : this.store.selectSnapshot(AccountState.teamKey));

    return this.db
      .object<any>(`/statuses/search/${path}/${key}/time`)
      .valueChanges()
      .pipe(skip(1), debounceTime(1000), distinctUntilChanged(), startWith(0), distinctUntilChanged(), mapTo(true));
  }
}

import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { arrayUnique } from '@shared/utilities/array.utilities';
import { isShallowEqual, firebaseObjectToArray } from '@shared/utilities/object.utilities';
import {
  IntegrationItemLink,
  Selectable,
  Integratable,
  ServiceIntegration,
  IntegrationConfig,
  IntegrationListLink,
  ServiceIntegrationList,
  IntegrationSurveyLink,
  IntegrationPropertyLink,
  IntegrationSurveyFeature,
  IntegrationFeatureSetting,
  IntegrationFeatureSettingNote,
  IntegrationFeatureSettingProperty,
} from '@shared/models/integrations.model';
import { PickByType } from '@shared/utilities/typescript.utilities';

export const linkMatcher = <T extends IntegrationItemLink>(link1: T, link2: T): boolean =>
  (link1.$key != null && link1.$key === link2.$key) ||
  (link1.itemId != null && link1.itemId === link2.itemId) ||
  (link1.integrationId != null && link1.integrationId === link2.integrationId);

export function hasNewItems(links: IntegrationItemLink[]): boolean {
  return !!links?.some((list) => !list.itemId);
}

export function hasNewIntegrationItems(links: IntegrationItemLink[]): boolean {
  return !!links?.some((list) => !list.integrationId);
}

export function filterChangeIntegrationLinks<T extends IntegrationItemLink>() {
  return (source: Observable<T[]>) =>
    source.pipe(
      map((links) => links || []),
      distinctUntilChanged((prev, next) => {
        if (prev.length !== next.length) {
          return false;
        }

        return prev.every((item) =>
          isShallowEqual(
            item,
            next.find((other) => other.$key === item.$key),
          ),
        );
      }),
      map((links: T[]) => links.map((link) => ({ ...link } as T))),
    );
}

export function categorizeItems<T extends Selectable>(
  items: T[],
  categoryProperty: string,
  sort: boolean = true,
): { category: string; items: T[] }[] | undefined {
  items = items ?? [];

  if (!categoryProperty) {
    return;
  }

  let categories = arrayUnique(items.map((item) => item[categoryProperty]).filter((cat) => !!cat));

  if (sort) {
    categories = categories.sort((a, b) => a.localeCompare(b, void 0, { sensitivity: 'base' }));
  }

  return categories.map((category) => ({
    category,
    items: items.filter((item) => item[categoryProperty] === category) as T[],
  }));
}

export function linkIntegrationToItem<Q extends IntegrationItemLink, T extends Integratable<Q>>(
  item: T,
  services: ServiceIntegration[],
  searchKeys: { link: keyof PickByType<IntegrationConfig, Q[]>; id: keyof T },
): T {
  let itemLink: Q;
  let integration: ServiceIntegration;

  for (const service of services) {
    itemLink = (service.config?.[searchKeys.link] as Q[])?.find(
      ({ itemId }) => itemId === (item[searchKeys.id] as any),
    );

    if (itemLink) {
      integration = service;
      break;
    }
  }

  return {
    ...item,
    integration: integration && { ...integration },
    itemLink: itemLink && !itemLink.invite ? { ...itemLink } : void 0,
    isAutomated: !!itemLink?.invite,
  };
}

export function assertValidListSettings(
  link: IntegrationListLink,
  lists: ServiceIntegrationList[],
): IntegrationListLink {
  const isDynamic = lists.find(({ id }) => id === link.integrationId)?.type === 'dynamic';

  link.addNewToIntegration = !isDynamic;
  link.addNewFromIntegration = true;
  link.removeOldToIntegration = !isDynamic;
  link.removeOldFromIntegration = true;

  if (link.$key) {
    link.importExisting = true;
    link.exportExisting = true;
  }

  if (isDynamic) {
    link.exportExisting = false;
  }

  return link;
}

function getPrefix(type: 'dynamic' | 'static'): string {
  const prefix = {
    dynamic: $localize`:integration@@zef-i18n-list-prefix-active:Active`,
    static: $localize`:integration@@zef-i18n-list-prefix-static:Static`,
  }[type];

  return prefix ? `${prefix}: ` : '';
}

export function getRawListName(list: ServiceIntegrationList): string {
  if (!list || !list.name) {
    return list?.id;
  }

  let name = list.name;

  const prefix = getPrefix(list.type);

  if (prefix) {
    name = name.replace(prefix, '');
  }

  return name;
}

export function getPrefixedListName(list: ServiceIntegrationList): string {
  return getPrefix(list.type) + list.name || list.id;
}

export function getPropertyLinksFromSurvey(surveyLink: IntegrationSurveyLink): IntegrationPropertyLink[] {
  return surveyLink?.features?.[IntegrationSurveyFeature.Property]?.propertyLinks || [];
}

export function parseIntegrationData(integration: ServiceIntegration): ServiceIntegration {
  return {
    ...integration,
    config: {
      ...(integration.config || {}),
      lists: firebaseObjectToArray(integration?.config?.lists || {}),
      properties: firebaseObjectToArray(integration?.config?.properties || {}),
      surveys: Object.entries(integration.config?.surveys || {}).reduce(
        (surveys, [key, link]: [string, IntegrationSurveyLink]) => ({
          ...surveys,
          [key]: {
            ...link,
            features: {
              ...link.features,
              [IntegrationSurveyFeature.Property]: {
                ...link.features?.[IntegrationSurveyFeature.Property],
                propertyLinks: firebaseObjectToArray(
                  link.features?.[IntegrationSurveyFeature.Property]?.propertyLinks || {},
                ),
              },
            },
          },
        }),
        {},
      ),
    },
  } as ServiceIntegration;
}

export function isValidFeatureSetting<T extends IntegrationFeatureSetting>(
  feature: IntegrationSurveyFeature,
  setting: T,
): boolean {
  if (isNoteFeature(feature, setting)) {
    return true;
  }

  if (isPropertyFeature(feature, setting)) {
    if (!setting.active) {
      return true;
    }

    if (setting.propertyLinks.length) {
      return true;
    }
  }
}

function isNoteFeature(
  feature: IntegrationSurveyFeature,
  setting: IntegrationFeatureSetting,
): setting is IntegrationFeatureSettingNote {
  return feature === IntegrationSurveyFeature.Note;
}

function isPropertyFeature(
  feature: IntegrationSurveyFeature,
  setting: IntegrationFeatureSetting,
): setting is IntegrationFeatureSettingProperty {
  return feature === IntegrationSurveyFeature.Property;
}

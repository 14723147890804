<div #input class="z-checkbox" (click)="answerChange.emit(!answer)">
  <div
    class="z-answer-box"
    [style.border-color]="(ss.colors | async).primary"
    [style.background-color]="(ss.colors | async).mood"
  >
    <mat-icon
      *ngIf="isChecked()"
      class="z-answered-icon"
      fontSet="zefplayer"
      [style.color]="(ss.colors | async).primary"
    >
      check
    </mat-icon>
  </div>
  <div
    class="z-label z-texts-option"
    [class.z-texts-answer]="isChecked()"
    [style.color]="isChecked() ? (ss.colors | async).primary : (ss.colors | async).text"
  >
    {{ placeholder }}
  </div>
</div>

<span *ngIf="!isValid" class="z-error-text">{{ errorText }}</span>

import {
  LocalesData,
  PlayerLocales,
  Translatable,
  TranslationData,
  TranslatorEntry,
} from '@shared/models/locale.model';
import {
  ChoiceItemData,
  InfoMediaCtaData,
  OutcomeData,
  QuestionData,
  SharesData,
  SharingData,
  SurveyData,
} from '@shared/models/survey.model';
import { Services } from '@shared/enums/services.enum';
import { Questions } from '@shared/enums/questions.enum';
import { assertArray } from '@shared/utilities/array.utilities';
import { AutoTranslateItem } from '@editor/shared/sidenavs/language-editor/language-editor.models';
import {
  UpdateOutcome,
  UpdateQuestion,
  UpdateQuestionChoiceContent,
  UpdateSurveyData,
} from '@shared/states/survey.actions';

// @todo keep in sync with functions/src/models/language.models.ts
export const DefaultTranslationMap = {
  title: 'title',
  content: 'content',
  image: {
    url: 'image',
  },
  cta: {
    text: 'ctaText',
    url: 'ctaLink',
  },
  videoEmbed: {
    videoId: 'videoId',
    startTime: 'startTime',
    endTime: 'endTime',
  },
};

export const QuestionTranslationMap = {
  ...DefaultTranslationMap,
  choiceList: [{ content: 'choice' }],
  fileUpload: {
    description: 'fileUploadDescription',
  },
  inputField: {
    hint: 'inputFieldHint',
  },
  sliderLabels: {
    min: 'min',
    max: 'max',
    axis: 'axis',
  },
  sliderLabelsX: {
    min: 'min-X',
    max: 'max-X',
    axis: 'axis-X',
  },
  sliderLabelsY: {
    min: 'min-Y',
    max: 'max-Y',
    axis: 'axis-Y',
  },
  keywords: 'keywords',
};

export const OutcomeTranslationMap = {
  ...DefaultTranslationMap,
};

function getMapEntries(map: unknown, entries: string[] = []): string[] {
  if (typeof map === 'string') {
    entries.push(map);
  } else if (Array.isArray(map)) {
    map.forEach((entry) => entries.push(...getMapEntries(entry)));
  } else if (map != null && typeof map === 'object') {
    Object.values(map).forEach((entry) => entries.push(...getMapEntries(entry)));
  }

  return entries;
}

export const allItemEntries = getMapEntries({
  ...OutcomeTranslationMap,
  ...QuestionTranslationMap,
});

export const SurveyTranslationMap = {
  title: 'surveyTitle',
  description: 'surveyDescription',
  start: 'startSurvey',
};

export const surveyEntries = getMapEntries(SurveyTranslationMap);

export const SharingTranslationMap = {
  buttonText: 'imageDataCta',
  titleText: 'imageDataTitle',
  ...sharingServicesToTranslationMap(),
  outcomeSharing: {
    buttonText: 'outcomeSharing-imageDataCta',
    titleText: 'outcomeSharing-imageDataTitle',
    ...sharingServicesToTranslationMap('outcomeSharing-'),
  },
};

export const sharingEntries = getMapEntries(SharingTranslationMap);

export function sharingServicesToTranslationMap(prefix = ''): object {
  return Services.socialIds.reduce(
    (serviceSharing, service) => ({
      ...serviceSharing,
      [service]: {
        shareTitle: `${prefix}${service}-shareTitle`,
        shareBody: `${prefix}${service}-shareBody`,
      },
    }),
    {},
  );
}

export function addLocaleEntry(localeArr: TranslatorEntry[], text: string, key: string) {
  if (key && text && localeArr) {
    localeArr.push({ text, readKeys: [key], writeKeys: [key] });
  }
}

export function updateLocaleStrings(locales: TranslatorEntry[], strings: Object): { [entryKey: string]: string } {
  let unsetStrings: any = null;

  if (!strings) {
    return null;
  }

  for (const locale of locales) {
    const readKeys = locale.readKeys || [];
    const writeKeys = locale.writeKeys || [];

    const texts = [...writeKeys, ...readKeys].map((key) => strings[key]).filter(Boolean);

    locale.value = texts[0] || '';

    if (locale.value) {
      const unsetKeys = locale.writeKeys.filter((key) => strings[key] !== locale.value);

      if (unsetKeys.length) {
        if (!unsetStrings) {
          unsetStrings = {};
        }

        for (const key of unsetKeys) {
          unsetStrings[key] = locale.value;
        }
      }
    }
  }

  return unsetStrings;
}

export function buildDefaultLocales(
  locale: PlayerLocales | null,
  survey: SurveyData,
  uiLocaleStrings: TranslationData,
): TranslatorEntry[] {
  return Object.keys(new PlayerLocales())
    .filter((key) => !isHiddenTranslationKey(key, survey))
    .map((key) => ({ text: locale?.[key] || uiLocaleStrings[key] || key, writeKeys: [key], readKeys: [key] }));
}

export function isHiddenTranslationKey(key: string, survey?: SurveyData): boolean {
  return (
    ['ctaLink', 'videoId', 'startTime', 'endTime', 'image', 'zef', 'promo'].some((start) => key.startsWith(start)) ||
    ['privacyPolicyLink', 'prevButton', 'seeMore'].includes(key) ||
    (!survey?.welcome &&
      [SurveyTranslationMap.title, SurveyTranslationMap.description, SurveyTranslationMap.start].includes(key))
  );
}

export function isRemovedTranslationKey(key: string, keys: string[]): boolean {
  return allItemEntries.some(
    (entry) =>
      key.startsWith(entryKey(entry, '')) && keys.every((targetKey) => !key.startsWith(entryKey(entry, targetKey))),
  );
}

export function isAutoTranslatableKey(key: string): boolean {
  return [DefaultTranslationMap.image.url, DefaultTranslationMap.cta.url].every(
    (entry) => !key.startsWith(entryKey(entry, '')),
  );
}

export function buildSurveyLocales(survey: SurveyData): TranslatorEntry[] {
  const localeArr: TranslatorEntry[] = [];

  if (survey?.welcome) {
    addLocaleEntry(localeArr, survey.title, SurveyTranslationMap.title);
    addLocaleEntry(localeArr, survey.description, SurveyTranslationMap.description);
    addLocaleEntry(localeArr, survey.start, SurveyTranslationMap.start);
  }

  return localeArr;
}

export function buildSurveySharingLocales(sharingData: SharingData): TranslatorEntry[] {
  const localeArr: TranslatorEntry[] = [];

  for (const service of Services.socialIds) {
    for (const key of ['shareTitle', 'shareBody']) {
      if (sharingData[service] && sharingData[service][key]) {
        addLocaleEntry(localeArr, sharingData[service][key], entryKey(service, key));
      }
    }
  }

  if (sharingData && sharingData.imageData) {
    addLocaleEntry(localeArr, sharingData.buttonText, `imageDataCta`);
    addLocaleEntry(localeArr, sharingData.titleText, `imageDataTitle`);
  }

  return localeArr;
}

export function buildOutcomeSharingLocales(sharingData: SharesData): TranslatorEntry[] {
  const localeArr: TranslatorEntry[] = [];

  for (const service of Services.socialIds) {
    for (const key of ['shareTitle', 'shareBody']) {
      if (sharingData[service] && sharingData[service][key]) {
        addLocaleEntry(localeArr, sharingData[service][key], entryKey('outcomeSharing', service, key));
      }
    }
  }

  if (sharingData && sharingData.imageData) {
    addLocaleEntry(localeArr, sharingData.buttonText, entryKey('outcomeSharing', 'imageDataCta'));
    addLocaleEntry(localeArr, sharingData.titleText, entryKey('outcomeSharing', 'imageDataTitle'));
  }

  return localeArr;
}

export function buildOutcomeLocales(outcomes: OutcomeData[]): TranslatorEntry[] {
  const localeArr: TranslatorEntry[] = [];

  outcomes
    .filter((outcome) => !outcome.archived)
    .forEach((outcome) => {
      [QuestionTranslationMap.title, QuestionTranslationMap.content].forEach((entry) => {
        if (outcome[entry] != null) {
          addLocaleEntry(localeArr, outcome[entry], entryKey(entry, outcome));
        }
      });

      addMediaCtaTranslations(outcome, localeArr);
    });

  return localeArr;
}

export function buildQuestionLocales(questions: QuestionData[]): TranslatorEntry[] {
  const localeArr: TranslatorEntry[] = [];

  questions.forEach((question) => {
    handleQuestionTranslations(question, localeArr);

    switch (question.type) {
      case Questions.INFO_TEXT:
      case Questions.GROUP_CARDS:
        addMediaCtaTranslations(question, localeArr);
        break;
      case Questions.GROUP_SCORED:
        handle1DTranslations(question, localeArr);
        handle2DTranslations(question, localeArr);
        addMediaCtaTranslations(question, localeArr);
        break;

      case Questions.SLIDER_1V:
      case Questions.SLIDER_NPS:
      case Questions.SLIDER_1D:
      case Questions.SLIDER_1R:
        handle1DTranslations(question, localeArr);
        break;

      case Questions.SLIDER_2D:
        handle2DTranslations(question, localeArr);
        break;

      case Questions.INPUT_DROPDOWN:
        handleInputTranslations(question, localeArr);
        handleChoiceTranslations(question, localeArr);
        break;

      case Questions.CHOICE_TEXT:
      case Questions.CHOICE_MULTI:
      case Questions.CHOICE_SINGLE:
      case Questions.CHOICE_PICTURE:
        handleChoiceTranslations(question, localeArr);
        break;

      case Questions.FREE_TEXT:
      case Questions.INPUT_ADDRESS:
      case Questions.INPUT_CHECKBOX:
      case Questions.INPUT_EMAIL:
      case Questions.INPUT_PHONE:
      case Questions.INPUT_URL:
      case Questions.INPUT_STRING:
      case Questions.INPUT_NUMBER:
      case Questions.INPUT_NUMERIC:
        handleInputTranslations(question, localeArr);
        break;

      case Questions.FILE_UPLOAD:
        handleFileUploadTranslations(question, localeArr);
        break;
    }
  });

  return localeArr;
}

export function handleQuestionTranslations(question: QuestionData, localeArr: TranslatorEntry[]) {
  let propertyText;

  for (const entry of [QuestionTranslationMap.title, QuestionTranslationMap.content]) {
    propertyText = question[entry];

    if (!propertyText) {
      continue;
    }

    addLocaleEntry(localeArr, propertyText, entryKey(entry, question));
  }
}

export function handle1DTranslations(question: QuestionData, localeArr: TranslatorEntry[]) {
  if (!question.sliderLabels) {
    return;
  }

  Object.entries(QuestionTranslationMap.sliderLabels).forEach(([key, entry]) => {
    const propertyText = question.sliderLabels[key];

    if (propertyText != null) {
      addLocaleEntry(localeArr, propertyText, entryKey(entry, question));
    }
  });
}

export function handle2DTranslations(question: QuestionData, localeArr: TranslatorEntry[]) {
  if (question.sliderLabelsX) {
    Object.entries(QuestionTranslationMap.sliderLabelsX).forEach(([key, entry]) => {
      const propertyText = question.sliderLabelsX[key];

      if (propertyText != null) {
        addLocaleEntry(localeArr, propertyText, entryKey(entry, question));
      }
    });
  }

  if (question.sliderLabelsY) {
    Object.entries(QuestionTranslationMap.sliderLabelsY).forEach(([key, entry]) => {
      const propertyText = question.sliderLabelsY[key];

      if (propertyText != null) {
        addLocaleEntry(localeArr, propertyText, entryKey(entry, question));
      }
    });
  }
}

export function handleChoiceTranslations(question: QuestionData, localeArr: TranslatorEntry[]) {
  let propertyText: string;
  const choiceList = question.choiceList || ([] as ChoiceItemData[]);

  for (const choice of choiceList) {
    if (!choice['$key']) {
      continue;
    }

    propertyText = choice.content;
    addLocaleEntry(localeArr, propertyText, entryKey(QuestionTranslationMap.choiceList[0].content, question, choice));
  }
}

export function handleInputTranslations(question: QuestionData, localeArr: TranslatorEntry[]) {
  let propertyText: string;

  if (question && question.inputField) {
    propertyText = question.inputField.hint;

    addLocaleEntry(localeArr, propertyText, entryKey(QuestionTranslationMap.inputField.hint, question));
  }
}

export function handleFileUploadTranslations(question: QuestionData, localeArr: TranslatorEntry[]) {
  let propertyText: string;

  if (question && question.fileUpload) {
    propertyText = question.fileUpload.description;

    addLocaleEntry(localeArr, propertyText, entryKey(QuestionTranslationMap.fileUpload.description, question));
  }
}

export function addMediaCtaTranslations(item: InfoMediaCtaData & Translatable, localeArr: TranslatorEntry[]): void {
  if (!item) {
    return;
  }

  if (item.image?.url) {
    addLocaleEntry(localeArr, item.image.url, entryKey(DefaultTranslationMap.image.url, item));
  }

  if (item.cta?.text) {
    addLocaleEntry(localeArr, item.cta.text, entryKey(DefaultTranslationMap.cta.text, item));

    if (item.cta?.url) {
      addLocaleEntry(localeArr, item.cta.url, entryKey(DefaultTranslationMap.cta.url, item));
    }
  }

  if (item.videoEmbed?.videoId) {
    addLocaleEntry(localeArr, item.videoEmbed.videoId, entryKey(DefaultTranslationMap.videoEmbed.videoId, item));
    addLocaleEntry(
      localeArr,
      item.videoEmbed.startTime?.toString() || '0',
      entryKey(DefaultTranslationMap.videoEmbed.startTime, item),
    );
    addLocaleEntry(
      localeArr,
      item.videoEmbed.endTime?.toString(),
      entryKey(DefaultTranslationMap.videoEmbed.endTime, item),
    );
  }
}

export function setQuestionTranslations(questions: QuestionData[], strings: TranslationData): QuestionData[] {
  if (!strings) {
    return questions;
  }

  return questions.map((question) => parseEntryMap(QuestionTranslationMap, question, [question], strings));
}

export function setOutcomeTranslations(outcomes: OutcomeData[], strings: TranslationData): OutcomeData[] {
  if (!strings) {
    return outcomes;
  }

  return outcomes.map((outcome) => parseEntryMap(OutcomeTranslationMap, outcome, [outcome], strings));
}

export function setSurveyTranslations(survey: SurveyData, strings: TranslationData): SurveyData {
  if (!strings) {
    return survey;
  }

  return parseEntryMap(SurveyTranslationMap, survey, [], strings);
}

export function setSharingTranslations(sharing: SharingData, strings: TranslationData): SharingData {
  if (!strings) {
    return sharing;
  }

  return parseEntryMap(SharingTranslationMap, sharing, [], strings);
}

export function entryKey(...args: (string | Translatable)[]): string {
  return args.map((entry) => (typeof entry === 'string' ? entry : entry.$key)).join('-');
}

export function parseEntryMap<T>(
  map: object,
  source: T,
  entries: (string | Translatable)[],
  strings: TranslationData,
): T {
  source = { ...source };
  Object.entries(map).forEach(([key, entry]) => {
    if (typeof entry === 'string') {
      source[key] = strings[entryKey(entry, ...entries)] || source[key];
    } else if (Array.isArray(entry)) {
      source[key] = source[key]?.map((sourceItem) =>
        parseEntryMap(entry[0], sourceItem, [...entries, sourceItem], strings),
      );
    } else if (typeof entry === 'object' && source[key]) {
      source[key] = parseEntryMap(entry, source[key], entries, strings);
    }
  });

  return source;
}

export function translatorEntriesToUpdate(
  entries: TranslatorEntry[] | TranslatorEntry,
  useText?: boolean,
): TranslationData {
  return assertArray(entries).reduce(
    (update, item) => ({
      ...update,
      ...item.writeKeys.reduce(
        (itemUpdate, key) => ({
          ...itemUpdate,
          [key]: item.value ?? (useText ? item.text : null),
        }),
        {},
      ),
    }),
    {},
  );
}

export function filterHiddenLanguages(locales: LocalesData): LocalesData {
  const config = Object.entries(locales.config)
    .filter(([_, data]) => !data?.hidden)
    .reduce(
      (merged, [key, data]) => ({
        ...merged,
        [key]: data,
      }),
      {},
    );

  return {
    ...locales,
    config,
  };
}

export function autoTranslateItemToEntry(item: AutoTranslateItem, newValue: string): TranslatorEntry {
  return {
    text: item.source,
    writeKeys: item.keys,
    readKeys: item.keys,
    value: newValue,
  };
}

function entryToUpdatePath(obj: object, item: string): string[] {
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      const result = entryToUpdatePath(obj[key], item);

      if (result) {
        result.unshift(key);
        return result;
      }
    } else if (obj[key] === item) {
      return [key];
    }
  }
}

function entryToUpdate(obj: object, item: string, value: string): object {
  return { [entryToUpdatePath(obj, item).join('/')]: value };
}

export function stringsToSurveyUpdate(
  updateStrings: TranslationData,
  questions: QuestionData[],
  outcomes: OutcomeData[],
): any[] {
  const questionKeys = questions.map(({ $key }) => $key);
  const outcomeKeys = outcomes.map(({ $key }) => $key);

  return Object.entries(updateStrings).reduce((actions, [key, value]) => {
    value ??= '';
    const itemEntry = allItemEntries
      .filter((entry) => key.startsWith(entryKey(entry, '')))
      .sort((a, b) => b.length - a.length)[0];

    if (itemEntry) {
      const questionKey = questionKeys.find((qKey) => key.includes(qKey));
      const outcomeKey = outcomeKeys.find((oKey) => key.includes(oKey));

      if (questionKey) {
        if (itemEntry === QuestionTranslationMap.choiceList[0].content) {
          const choice = questions.find((q) => q.$key === questionKey)?.choiceList?.find((c) => key.includes(c.$key));

          if (choice) {
            actions.push(new UpdateQuestionChoiceContent(questionKey, choice.$key, value));
          }
        } else {
          const update = {
            [entryToUpdatePath(QuestionTranslationMap, itemEntry).join('/')]: value,
          };
          actions.push(new UpdateQuestion(questionKey, update));
        }
      } else if (outcomeKey) {
        const update = entryToUpdate(OutcomeTranslationMap, itemEntry, value);

        actions.push(new UpdateOutcome(outcomeKey, update));
      }
    } else {
      const surveyEntry = surveyEntries.find((entry) => key.startsWith(entryKey(entry, '')));

      if (surveyEntry) {
        const update = entryToUpdate(SurveyTranslationMap, surveyEntry, value);
        actions.push(new UpdateSurveyData(undefined, update));
      }
      // else {
      //   const sharingEntry = sharingEntries.find((entry) => key.startsWith(entryKey(entry, '')));
      //
      //   if (sharingEntry) {
      //     const update = entryToUpdate(SharingTranslationMap, sharingEntry, value);
      //   }
      // }
    }

    return actions;
  }, []);
}

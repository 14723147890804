// import { ActivatedRoute } from '@angular/router';

import { Component, Input, AfterViewInit } from '@angular/core';
import { OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

// import { SurveysManager } from '@shared/services/surveys-manager.service';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { DataConverter } from '@report/shared/services/data-converter.service';

import { LanguagesData } from '@shared/models/locale.model';
import { DimensionData } from '@shared/models/report.model';
import { SurveyData } from '@shared/models/survey.model';

import { Rights } from '@shared/enums/rights.enum';

/**
 * This is a side pane component which handles showing of statistics and ready-made filters.
 */
@Component({
  selector: 'side-pane',
  templateUrl: './side-pane.component.html',
  styleUrls: ['./side-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePane implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() scrollState: number[] | null = null;
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() isTemplateReport: boolean = false;
  @Input() isFiltersApplied: boolean = false;
  @Input() isDrawer: boolean = false;
  readonly Rights = Rights;

  private cfSub: any;

  // public survey: Survey | null = null;
  public cropFilters: any;
  public stats: any;
  public filters: any;
  public filtersDemo: boolean = false;
  public userSegments: any;
  public dimensions: DimensionData = {} as DimensionData;
  public languages: LanguagesData = {} as LanguagesData;

  public stickyStyles = {};

  public showLanguages: boolean = false;
  public activeLanguage: string = '';
  public activeTrend: string = '';
  public trendAnalysisOn: boolean = false;
  public includedSurveys: SurveyData[] = [];
  public defaultSurvey: string = '';
  public anonymityTreshold: number = null;

  constructor(
    // private route: ActivatedRoute,
    private cf: Crossfilter,
    private dc: DataConverter,
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef, // private sm: SurveysManager
  ) {}

  ngOnInit() {
    // Subscribing to crossfilter to listen statistics
    this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
      if (crossfilter) {
        this.anonymityTreshold = this.cf.getAnonymityTreshold();
        this.stats = crossfilter.stats;
        this.filters = crossfilter.filters;
        this.cropFilters = Array.from(this.cf.cropFilters);
        this.dimensions = crossfilter.dimensions;
        if (crossfilter.gridItems['userSegments']) {
          this.userSegments = this.dc.BasicConverter(crossfilter.gridItems['userSegments']);
        }
        this.languages = this.cf.getLocaleConfig();
        this.showLanguages = this.languages && Object.keys(this.languages).length > 1;
        this.activeLanguage = this.cf.getActiveLocale();
        this.activeTrend = this.cf.getTimePeriod();
        this.trendAnalysisOn = this.cf.getTrendAnalysisStatus();
        this.includedSurveys = this.cf.getIncludedSurveys();
        this.defaultSurvey = this.cf.key;
      } else {
        delete this.userSegments;
      }
      if (crossfilter && crossfilter.other && crossfilter.other.filtersDemo) {
        this.filtersDemo = true;
      } else {
        this.filtersDemo = false;
      }
      this.cdRef.detectChanges();
    });

    // const params = this.route.snapshot.params;

    // this.survey = this.sm.connectSurvey(params['survey']);
  }

  ngOnChanges() {
    this.setStyles();
  }

  ngAfterViewInit(): void {
    this.setStyles();
  }

  ngOnDestroy() {
    this.cfSub.unsubscribe();
  }

  public closeComparison() {
    this.cf.updateComparisonList([], 'userSegments');
  }

  public removeCropFilters($event) {
    this.cf.removeCropFilters([$event]);
  }

  public changeLanguage($event: string) {
    this.cf.changeLocale($event);
  }

  public changeTrend($event: string) {
    this.cf.changeTimePeriod($event);
  }

  public trendSwitched($event: boolean) {
    this.cf.switchTrendAnalysis($event);
  }

  public removeSurvey($event: SurveyData) {
    this.cf.removeMergedSurvey($event);
  }

  private setStyles(): void {
    if (document.getElementById('zefReport') && this._element.nativeElement.parentElement?.parentElement) {
      const topMargin: number = this.scrollState ? this.scrollState[2] + (!this.isSharedReport ? 80 : 0) : 0;
      const width: string = this.isDrawer
        ? this._element.nativeElement.getBoundingClientRect().width - 24 + 'px'
        : 'null';
      const height: number =
        document.getElementById('zefReport').offsetHeight -
        10 +
        (this.isDrawer ? (this.isSharedReport ? 76 - 10 : topMargin - 80 - 10) : 0) -
        (this.isTemplateReport && this.isFiltersApplied ? 76 : 0);

      if (
        (this.scrollState && this.scrollState[3] > 80) ||
        this.isSharedReport ||
        (this.isTemplateReport && this.isFiltersApplied)
      ) {
        this.stickyStyles = {
          'position': 'sticky',
          'top':
            12 +
            (this.isTemplateReport && this.isFiltersApplied && !this.isDrawer
              ? 76
              : this.isSharedReport && this.isFiltersApplied && !this.isDrawer
              ? 12
              : 0) +
            'px',
          'right': null,
          width,
          'height': '100%',
          'max-height': height + 'px',
        };
      } else {
        this.stickyStyles = {
          'position': 'sticky',
          'top': 12 + 'px',
          'right': null,
          'max-height': null,
        };
      }
      this.cdRef.markForCheck();
      this.cdRef.detectChanges();
    }
  }
}

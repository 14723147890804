import { QuestionData } from '@shared/models/survey.model';
import { ZefThemePalette } from '@shared/models/color.model';
import { OtherProperty } from '@shared/enums/properties.enum';
import { Status } from '@shared/models/status.model';

export interface Integratable<T extends IntegrationItemLink> {
  integration?: ServiceIntegration;
  itemLink?: T;
  isAutomated?: boolean;
}

export interface ApiKey {
  $key: string;
  token: string;
  creator: string;
  uid: string;
  name: string;
  timestamp: number;
  integrationKey?: string;
}

export enum IntegrationService {
  HubSpot = 'hubspot',
  SalesForce = 'salesforce',
  autofutur = 'autofutur',
  dlsoftware = 'dlsoftware',
  easoft = 'easoft',
  mustalinja = 'mustalinja',
  pinja = 'pinja',
  sympa = 'sympa',
  talosofta = 'talosofta',
}

export enum IntegrationSurveyFeature {
  Note = 'contactNoteFromAnswer',
  Property = 'contactPropertyFromAnswer',
}

export type IntegrationConflictResolution = 'useIntegration' | 'useZeffi' | 'ignore';

export interface IntegrationSyncStatus {
  active: boolean;
}

export interface ServiceIntegration {
  $key: string;
  type: IntegrationService;
  connected?: boolean;
  interrupted?: boolean;
  authCode?: string;
  creator?: string;
  name?: string;
  config?: IntegrationConfig;
  status?: {
    global: IntegrationSyncStatus;
    automatic: IntegrationSyncStatus;
    [listLinkId: string]: IntegrationSyncStatus;
  };
}

export interface ServiceIntegrationItem {
  id: string | null;
  name: string;
  type: string;
  source: IntegrationService;
  sourceKey: string;
}

export interface ServiceIntegrationList extends ServiceIntegrationItem {
  type: 'dynamic' | 'static';
  size: number;
  importable?: number;
  exportable?: number;
  createdAt?: string;
}

export interface ServiceIntegrationProperty extends ServiceIntegrationItem {
  readonly: boolean;
  groupName: string;
}

export interface IntegrationItemLink {
  $key?: string;
  itemId: number | string;
  integrationId: string;
  readonly?: boolean;
  newItemName?: string;
  newIntegrationItemName?: string;
  invite?: string;
}

export interface IntegrationListLink extends IntegrationItemLink {
  itemId: number;
  addNewToIntegration?: boolean;
  addNewFromIntegration?: boolean;
  removeOldToIntegration?: boolean;
  removeOldFromIntegration?: boolean;
  importExisting?: boolean;
  exportExisting?: boolean;
}

export interface IntegrationPropertyLink extends IntegrationItemLink {
  itemId: string | OtherProperty;
}

export interface IntegrationQuestionPropertyLink {
  link: IntegrationPropertyLink;
  property: ServiceIntegrationProperty;
  service: ServiceIntegration;
  question?: QuestionData;
}

export interface IntegrationFeatureSetting {
  active: boolean;
}

export interface IntegrationFeatureSettingNote extends IntegrationFeatureSetting {
  createNotes: boolean;
  htmlTemplate?: string;
}

export interface IntegrationFeatureSettingProperty extends IntegrationFeatureSetting {
  exportCurrentRespondents: boolean;
  createNewContactOnCollection: boolean;
  propertyLinks: IntegrationPropertyLink[];
}

export interface IntegrationFeatureSettings {
  [IntegrationSurveyFeature.Note]: IntegrationFeatureSettingNote;
  [IntegrationSurveyFeature.Property]: IntegrationFeatureSettingProperty;
}

export enum IntegrationSurveyStatus {
  NotConnected = 1,
  NotIntegrated,
  Disconnected,
  Integrated,
  Inactive,
}

export interface IntegrationSurveyLink {
  active: boolean;
  features: IntegrationFeatureSettings;
}

export interface IntegrationConfig {
  lists: IntegrationListLink[];
  properties: IntegrationPropertyLink[];
  surveys: Record<string, IntegrationSurveyLink>;
  conflictResolution: IntegrationConflictResolution;
  options?: CustomIntegrationConfigurationOption[];
  custom?: Record<string, any>;
}

export const IntegrationLabels = {
  [IntegrationService.HubSpot]: 'HubSpot',
  [IntegrationService.SalesForce]: 'SalesForce',
};

export const IntegrationRelease = {
  [IntegrationService.HubSpot]: true,
  [IntegrationService.SalesForce]: false,
};

export const IntegrationFeatures = {
  [IntegrationService.HubSpot]: [IntegrationSurveyFeature.Note, IntegrationSurveyFeature.Property],
  [IntegrationService.SalesForce]: [],
};

export type Selectable = {
  id: string | number | null;
  icon?: string;
  iconClass?: string;
  iconColor?: ZefThemePalette;
  used?: boolean;
  disabled?: boolean;
} & ServiceIntegrationItem;

export interface IntegrationPropertyHistory {
  link: IntegrationPropertyLink;
  item: Selectable;
  question: QuestionData;
  property: ServiceIntegrationProperty;
  status: 'removed' | 'archived' | 'dependent' | 'unknown';
  statusWhen: 'after-publish' | 'history' | 'unknown';
}

export interface IntegrationSurveySyncParams {
  createNotes?: boolean;
  exportCurrentRespondents?: boolean;
}

export interface CustomIntegrationConfigurationOption {
  type: string;
  name: string;
  label: string;
  description: string;
  defaultValue?: any;
  shouldRefreshConfig?: boolean;
}

export interface CustomIntegration {
  $key?: string;
  creator?: string;
  type: IntegrationService;
  name: string;
  logo: string;
  partner: boolean;
  multiInstance?: boolean;
  active?: boolean;
  status?: Status;
  connected?: boolean;
  interrupted?: boolean;
  config?: Partial<IntegrationConfig>;
  auth?: Record<string, any>;
}

export interface IntegrationRequestForm {
  tools: string;
  comment?: string;
  email?: string;
  phone?: string;
  contact?: boolean;
}

import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { ResetTemplatesFilter } from '@shared/states/templates.actions';

@Injectable()
export class ResetFilter implements CanDeactivate<any> {
  constructor(readonly store: Store) {}

  canDeactivate() {
    this.store.dispatch(new ResetTemplatesFilter());

    return true;
  }
}

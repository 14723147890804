import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { CanvasContext } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawRoundRect } from '@shared/utilities/canvas.utilities';

/**
 * This is a horizontal single bar chart.
 */
@Directive({
  selector: '[singleBarChartH]',
})
export class SingleBarChartH implements OnChanges {
  @Input() value: number = 0;
  @Input() previousValue: number = null;
  @Input() scale: [number, number] = [0, 100];
  @Input() previousScale: [number, number] = [0, 100];
  @Input() colors: string[] = [];
  @Input() drawHelperLines: boolean = false;
  @Input() drawScale: boolean = true;
  @Input() showNumbers: boolean = true;
  @Input() percentageValues: boolean = false;
  @Input() previousPercentageValues: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() hover: boolean = false;
  @Input() update: Date = new Date();

  private base: any;
  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private scaleX: any;
  private colorScale: any;

  private width: number;
  private height: number;
  private margin: { [s: string]: number };
  private fontSize: number = 0;

  private previousHover: boolean = false;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value ||
      changes.scale ||
      changes.colors ||
      changes.drawHelperLine ||
      changes.drawScale ||
      changes.transitionDuration ||
      (changes.hover && this.previousHover !== this.hover) ||
      changes.update ||
      changes.showNumbers ||
      changes.previousValue ||
      changes.percentageValues ||
      changes.previousScale
    ) {
      this.updateChart(changes.value || changes.scale);
      this.previousHover = this.hover;
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'single-bar-chart-h')
      .style('position', 'relative');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = (this.hover ? 'bold ' : 'normal ') + (12 / 14) * this.fontSize + 'px Open Sans';
    const max = context.measureText('99999').width + 4;

    const sideMargin: number = Math.max(this.fontSize, max);

    this.margin = {
      top: 8,
      right: sideMargin,
      bottom: 8,
      left: this.scale[0] === 0 ? this.fontSize : sideMargin,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain(this.scale);

    const colorDomain: number[] = [];

    for (let i = 0, len = this.colors.length; i < len; i++) {
      colorDomain.push(this.scale[0] + (i * (this.scale[1] - this.scale[0])) / (len - 1));
    }

    this.colorScale = d3
      .scaleLinear<string>()
      .domain(
        this.colors && this.colors.length > 0 && this.colors.filter((item) => item != null).length > 0
          ? colorDomain
          : this.scale,
      )
      .range(
        this.colors && this.colors.length > 0 && this.colors.filter((item) => item != null).length > 0
          ? this.colors.length === 1 && this.colors[0]
            ? [this.colors[0], this.colors[0]]
            : this.colors
          : [Colors.DEFAULT, Colors.DEFAULT],
      );
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      const checkArraySimilarity: (arr1: number[], arr2: number[]) => boolean = (arr1: number[], arr2: number[]) =>
        JSON.stringify(arr1) === JSON.stringify(arr2);

      if (
        dataChanges &&
        __this.previousValue != null &&
        (__this.previousValue !== __this.value || !checkArraySimilarity(__this.previousScale, __this.scale)) &&
        __this.previousPercentageValues === __this.percentageValues &&
        __this.transitionDuration > 0
      ) {
        const interpolateValue = d3.interpolateNumber(__this.previousValue, __this.value);
        const interpolateMin = d3.interpolateNumber(__this.previousScale[0], __this.scale[0]);
        const interpolateMax = d3.interpolateNumber(__this.previousScale[1], __this.scale[1]);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scale;

          if (step >= 1) {
            data = interpolateValue(ease(1));
            scale = __this.scaleX;

            timer.stop();
          } else {
            data = interpolateValue(ease(step));
            scale = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([interpolateMin(ease(step)), interpolateMax(ease(step))]);
          }

          __this.setRects(context, data, scale);
        });
      } else {
        __this.setRects(context, d, __this.scaleX);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.single-bar-chart-h-canvas').data([this.value]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'single-bar-chart-h-canvas')
      .style('position', 'absolute')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setRects(context, value, scale) {
    const val: number = !isNaN(value) ? value : 0;

    context.clearRect(
      0,
      0,
      this.width + this.margin.right + this.margin.left,
      this.height + this.margin.top + this.margin.bottom,
    );

    context.strokeStyle = Colors.HELPERLINE;
    context.beginPath();
    context.moveTo(this.margin.left + scale(0), 0);
    context.lineTo(this.margin.left + scale(0), this.height + this.margin.top + this.margin.bottom);
    context.closePath();
    context.stroke();

    if (this.drawHelperLines) {
      const tickCount = this.percentageValues ? 4 : this.scale[1] > 3 ? 4 : this.scale[1] > 2 ? 2 : 1;
      const ticks = scale.ticks(tickCount);

      context.font = 10 / 14 + 'em Open Sans';
      context.fillStyle = Colors.TEXT;
      context.strokeStyle = Colors.HELPERLINE;
      context.lineWidth = 1;
      context.textAlign = 'center';
      context.textBaseline = 'top';
      ticks.forEach((d) => {
        if (!(this.percentageValues && d > 1)) {
          const x = this.margin.left + scale(d);
          context.beginPath();
          context.moveTo(x, 0);
          context.lineTo(x, this.height + this.margin.top + this.margin.bottom);
          context.stroke();
        }
      });
    }

    context.fillStyle = this.colorScale(val);
    if (this.hover) {
      context.lineWidth = 2;
      context.strokeStyle = Colors.HIGHLIGHT;
    }

    if (val > 0) {
      drawRoundRect(
        context,
        scale(0) + this.margin.left,
        this.margin.top,
        scale(val) - scale(0),
        this.height,
        { tl: 0, tr: 5, bl: 0, br: 5 },
        true,
        this.hover,
      );
    } else if (val < 0) {
      drawRoundRect(
        context,
        scale(val) + this.margin.left,
        this.margin.top,
        scale(0) - scale(val),
        this.height,
        { tl: 5, tr: 0, bl: 5, br: 0 },
        true,
        this.hover,
      );
    }

    if (this.showNumbers || this.hover) {
      context.font = (this.hover ? 'bold ' : 'normal ') + 12 / 14 + 'em Open Sans';
      context.textBaseline = 'middle';
      context.textAlign = val >= 0 ? 'start' : 'end';
      context.fillStyle = Colors.TEXT;

      const textPos: number = scale(val) + this.margin.left + (val >= 0 ? 4 : -4);
      context.fillText(
        !isNaN(value) ? (this.percentageValues ? (value * 100).toFixed(1) + '%' : Math.round(value)) : '-',
        textPos,
        this.margin.top + this.height / 2,
      );
    }
  }
}

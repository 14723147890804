/**
 * Emits the navigation (loading) state of the router.
 *
 * @unstable
 */

import { takeUntil } from 'rxjs/operators';

import { Directive, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RouteConfigLoadStart,
} from '@angular/router';

import { LifecycleHooks } from '../services/lifecycle-hooks.service';

@Directive({
  selector: '[navigationState]',
  providers: [LifecycleHooks],
})
export class NavigationState implements AfterViewInit, OnDestroy {
  private timeout: number | undefined;

  @Output() navigationState = new EventEmitter<boolean>();

  constructor(readonly router: Router, readonly lh: LifecycleHooks) {
    router.events.pipe(takeUntil(this.lh.destroy)).subscribe((event: Event) => {
      this.setNavigationState(event);
    });
  }

  ngAfterViewInit(): void {
    if (!this.timeout) {
      this.navigationState.emit(false);
    }
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }

  private setNavigationState(event: Event): void {
    if (event instanceof NavigationStart && !this.timeout) {
      this.timeout = window.setTimeout(() => this.navigationState.emit(true), 100);
    } else if (event instanceof RouteConfigLoadStart && this.timeout) {
      this.clearAndEmit(true);
    } else if ([NavigationEnd, NavigationCancel, NavigationError].some((navEvent) => event instanceof navEvent)) {
      this.clearAndEmit(false);
    }
  }

  private clearAndEmit(emit: boolean): void {
    window.clearTimeout(this.timeout);
    this.timeout = 0;
    this.navigationState.emit(emit);
  }
}

<select-item
  *ngIf="selectOptionLabel?.template || ss.selectLabel || selectContentLabel?.template as label"
  class="z-fx-flex"
  [class.selected]="selected"
  [prefix]="prefix"
  [label]="label"
  [value]="value"
  [radioType]="radioType"
  [isMultiSelect]="ss.isMultiSelect$ | async"
></select-item>

<ng-container *selectLabel>{{ viewValue }}</ng-container>

<div *ngIf="!viewValue" #viewValueContent>
  <ng-content></ng-content>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'zef-plan-slider',
  templateUrl: './plan-slider.component.html',
  styleUrls: ['./plan-slider.component.scss'],
})
export class PlanSlider implements OnInit {
  @Input() color: 'emails' | 'contacts' | 'answers' = 'contacts';
  @Input() labels: string = '';
  @Input() values: string = '';
  @Input() minValue: string = '';
  @Input() value: any | null = null;

  @Output() changed: EventEmitter<any> = new EventEmitter();

  public sliderClass: string;
  public sliderSettings = new BehaviorSubject(null);

  constructor() {}

  ngOnInit() {
    this.sliderClass = `zef-plan-${this.color}`;

    const labels = this.labels.length > 0 ? JSON.parse(this.labels.replace(/'/g, '"')) : [];
    const values = this.values.length > 0 ? JSON.parse(this.values) : [];
    const value = this.value.length > 0 ? JSON.parse(this.value) : null;
    const minValue = this.value.minValue > 0 ? JSON.parse(this.value) : values[0];

    this.sliderSettings.next({
      labels,
      values,
      value,
      minValue,
    });
  }
}

<zef-promo-dialog backgroundImage="assets/images/promo-shareable-reports.png" requiredPlan="plan_genius">
  <span dialog-title i18n>Sharable reports</span>

  <div dialog-list>
    <span i18n>Shareable privately or publically</span>
    <span i18n>Interactive charts that the viewer can play with</span>
    <span i18n>Live data that auto updates</span>
  </div>

  <span dialog-button i18n>Get Sharable reports</span>
</zef-promo-dialog>

import { takeUntil, delay } from 'rxjs/operators';

import {
  Component,
  Input,
  HostBinding,
  HostListener,
  ElementRef,
  OnInit,
  ContentChild,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { MultiSelectService } from '@shared/components/multi-select/multi-select.service';
import { MultiSelectOptionLabel } from '@shared/components/multi-select/multi-select-option-label.directive';

@Component({
  selector: 'zef-multi-select-option',
  templateUrl: './multi-select-option.component.html',
  styleUrls: ['./multi-select-option.component.scss'],
  providers: [LifecycleHooks],
})
export class MultiSelectOption<T> implements OnInit, OnChanges, AfterViewInit {
  @Input()
  value?: T;

  @Input()
  overrideValue?: T;

  @Input()
  radioType?: boolean;

  @Input()
  @HostBinding('class.disabled')
  disabled?: boolean;

  @HostBinding('class.option-selected')
  selected: boolean;

  @ContentChild(MultiSelectOptionLabel, { static: false })
  selectOptionLabel?: MultiSelectOptionLabel;

  @HostBinding('class.zef-interactive')
  get isInteractive(): boolean {
    return !this.disabled;
  }

  constructor(
    readonly ss: MultiSelectService,
    private el: ElementRef<HTMLElement>,
    private lh: LifecycleHooks,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && !changes.value.firstChange) {
      this.setTemplates();
    }
  }

  ngOnInit(): void {
    this.ss.value$
      .pipe(takeUntil(this.lh.destroy))
      .subscribe((value) => (this.selected = !!value?.length && value.indexOf(this.value) >= 0));

    this.ss.beforeOpened$.pipe(delay(1), takeUntil(this.lh.destroy)).subscribe(() => {
      if (this.selected) {
        this.ss.scrollToElement(this.el.nativeElement);
      }
    });
  }

  ngAfterViewInit(): void {
    this.setTemplates();
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  @HostListener('click')
  select(): void {
    if (!this.disabled) {
      if (this.radioType) {
        this.ss.radioSelected$.next(true);
      }

      this.ss.optionSelected$.next(this.overrideValue || this.value);

      if (
        !this.radioType &&
        this.ss.radioSelected$.value &&
        (!this.ss.defaultSelection$.value ||
          (Array.isArray(this.ss.value$.value) && this.ss.defaultSelection$.value !== this.ss.value$.value.join()))
      ) {
        this.ss.radioSelected$.next(false);
      }
    }
  }

  private setTemplates(): void {
    if (this.selectOptionLabel?.template) {
      if (this.value != null) {
        this.ss.updateOptionLabels(this.value, this.selectOptionLabel?.template);
      }
    } else {
      this.ss.updateOptionLabels(this.value);
    }

    if (this.radioType && this.selected && !this.ss.radioSelected$.value) {
      this.ss.radioSelected$.next(true);
    }

    if (this.radioType && !this.ss.defaultSelection$.value) {
      this.ss.defaultSelection$.next(this.overrideValue || this.value);
    }
  }
}

<div class="z-fx-col z-fx-gap-48 z-fx-auto">
  <div class="z-fx-col z-fx-gap-8">
    <ng-container *ngIf="!!inviteData?.email">
      <span class="zef-text-h2" i18n>{{ inviteData?.email }}</span>
      <span class="zef-text-p2-muted" i18n>Create new account</span>
    </ng-container>

    <ng-container *ngIf="!inviteData?.email">
      <span class="zef-text-h1" i18n>Create new account</span>
      <div class="z-fx z-fx-gap-8">
        <span class="zef-text-p2" i18n>Already have an account?</span>
        <a class="zef-text-p2 zef-hyper-link" (click)="changeMethod()" i18n>Sign In</a>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!!inviteData?.teamKey" class="z-fx-col z-fx-gap-8">
    <team-tile
      variant="small"
      [teamName]="inviteData.teamName"
      [teamLogo]="inviteData.teamLogo"
      [teamCreated]="0"
    ></team-tile>

    <div class="mat-typography">
      <p i18n>
        You were invited by
        <strong>{{ inviteData.userName }}</strong>
        to join their organization.
      </p>
    </div>
  </div>

  <div class="z-fx-col z-fx-gap-24">
    <button
      mat-raised-button
      color="secondary"
      class="zef-stretch-button auth-google-button"
      [disabled]="select | async"
      (click)="signUpWithGoogle()"
    >
      <div class="z-fx z-fx-center-center z-fx-gap-8">
        <img [src]="'https://www.gstatic.com/firebasejs/staging/3.0.0/auth/images/google.svg'" />
        <strong class="zef-text-p2" i18n>
          Sign up with
          <span>Google</span>
        </strong>
      </div>
    </button>

    <div *ngIf="googleError" class="zef-web-p1 error-text">
      {{ googleError }}
    </div>

    <div class="auth-or-divider">
      <hr />
      <span i18n>or</span>
      <hr />
    </div>

    <button
      mat-raised-button
      i18n
      color="ink"
      class="zef-stretch-button email-signup"
      [disabled]="select | async"
      (click)="signUpWithEmail()"
    >
      Create account with Email
    </button>

    <div *ngIf="emailError" class="zef-web-p1 error-text">
      {{ emailError }}
    </div>
  </div>
</div>

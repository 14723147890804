<!--
  Team card element with team switching.

  @unstable
-->

<div
  *ngIf="{ active: (isActiveTeam$ | async) } as asyncData"
  class="container-box z-fx-col z-fx-gap-8"
  [class.active]="asyncData.active"
  [class.focused]="hovering"
>
  <div
    [class.zef-card-z0]="!hovering"
    [class.zef-card-z2]="!asyncData.active && hovering"
    [class.zef-card-z-2-soft]="asyncData.active"
    (mouseover)="hovering = true"
    (mouseleave)="hovering = false"
  >
    <div
      class="wrapper"
      [class.active]="asyncData.active"
      [class.zef-cursor-pointer]="!asyncData.active"
      (click)="!asyncData.active ? switchTeam() : null"
    >
      <team-tile
        [noBackground]="asyncData.active"
        [teamName]="team?.name"
        [teamLogo]="team?.logo"
        [teamCreated]="team?.created"
      ></team-tile>
    </div>
  </div>
</div>

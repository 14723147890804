import { AfterViewInit, ChangeDetectionStrategy, Component, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { Question } from '@player/+survey/question/question-base';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';

import { Questions } from '@shared/enums/questions.enum';

interface OptionItem {
  content: any;
  $key: string;
}

@Component({
  selector: 'input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDropdown extends Question implements OnChanges, AfterViewInit, OnDestroy {
  public options: OptionItem[] = [];
  public selectedOption: OptionItem | null = null;

  constructor(readonly tt: TranslateText, ss: SurveyStore) {
    super(ss);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.questionData && this.questionData) {
      const isNumeric = this.questionData.type === Questions.INPUT_NUMERIC;
      this.options = isNumeric ? this.numericOptions() : this.questionData.choiceList;
    } else if (!this.questionData) {
      this.options = [];
    }

    if (changes.answer) {
      this.answer = changes.answer.currentValue;
      this.isAnswered = this.answer != null;
      this.selectedOption = this.isAnswered ? this.selectedOption : null;

      if (this.answer != null && this.options != null) {
        let answer: string | number = this.answer;

        if (this.questionData && this.questionData.type === Questions.INPUT_NUMERIC) {
          answer = Number.parseFloat(this.answer);
          answer = Number.isNaN(answer) ? null : answer;
        }

        this.answer = answer as string;
      }
    }

    if (changes.answer || changes.questionData) {
      this.selectedOption =
        (this.answer != null && this.options.find((option) => option['$key'] === this.answer?.toString())) || null;

      if (!this.selectedOption) {
        this.resetAnswer();
      }
    }
  }

  private numericOptions(): OptionItem[] {
    return Questions.generateNumericValues(this.questionData.sliderValues).map((value) => ({
      content: value,
      $key: value.toString(),
    }));
  }

  resetInput(value): void {
    super.resetInput(value);

    this.selectedOption = null;
  }

  onSelectOption(option: OptionItem): void {
    this.selectedOption = option;
    this.isAnswered = true;

    this.submitAnswer(this.preparedAnswer);

    this.answerReady.emit();
  }

  get preparedAnswer() {
    if (this.selectedOption === null) {
      return null;
    }

    return this.selectedOption['$key'];
  }

  trackByOption = (index: number, option: OptionItem): string => option.$key;

  filterOptions = (option: OptionItem, query: string) => {
    if (!this.questionData) {
      return false;
    }

    const content =
      this.questionData.type === Questions.INPUT_NUMERIC
        ? option.content
        : this.tt.transform(option.content, 'choice', this.questionData, option);

    return content.toLocaleLowerCase().includes(query.toLocaleLowerCase());
  };
}

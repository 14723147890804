/**
 * Locale names and types.
 *
 * @unstable
 */

export class Locales {
  public static de = 'German';
  public static en = 'English';
  public static fi = 'Finnish';
  public static se = 'Swedish';
  public static us = 'English';

  public static getFlag(locale: string): string {
    return locale && locale.length === 2
      ? `assets/flags/${locale.replace('en', 'gb')}.png`
      : `assets/images/other-language.png`;
  }
}

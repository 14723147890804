<!--
  Full screen chart dialog.

  @unstable
-->

<div #container class="container z-fx-col">
  <div class="z-fx z-fx-flex">
    <div class="z-fx z-fx-center-center arrows">
      <mat-icon class="back-icon" [class.disabled]="selectedChart === 0" (click)="back()">keyboard_arrow_left</mat-icon>
    </div>
    <ng-scrollbar class="z-fx-col z-fx-auto">
      <div class="z-fx z-fx-auto">
        <chart-card
          *ngIf="charts.length > 0 && charts[selectedChart] as chart"
          class="z-fx-col z-fx-auto grid-item"
          [style.height]="calcHeight(chart.gridSettings)"
          [settings]="chart"
          [data]="chart.data"
          [height]="chart.gridSettings.sizey"
          [width]="chart.gridSettings.sizex"
          [row]="chart.gridSettings.row"
          [update]="chart.data.timestamp"
          [visibleArea]="[0, 1, 2]"
          [comparison]="chart.data.comparison"
          [surveyRights]="data.surveyRights"
          [userRights]="data.userRights"
          [sampleData]="data.sampleData"
          [onResize]="onResize"
          [fullscreen]="true"
          [explorable]="data.explorable"
          [isSharedReport]="data.isSharedReport"
          [crossfilter]="data.cf"
          (openBuilder)="openBuilder($event)"
          (pinnedChart)="pin($event)"
          (removedChart)="openRemoveChartDialog($event)"
          (toggleFullscreen)="closeDialog($event)"
        >
          {{ chart.title }}
        </chart-card>
      </div>
    </ng-scrollbar>

    <div class="z-fx z-fx-center-center arrows">
      <mat-icon class="next-icon" [class.disabled]="selectedChart === charts.length - 1" (click)="next()">
        keyboard_arrow_right
      </mat-icon>
    </div>
  </div>
</div>

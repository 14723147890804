import { Observable, merge } from 'rxjs';
import { map, scan } from 'rxjs/operators';

import { shareRef } from '@shared/operators/share-ref.operator';

const defaultMatcher = (item1: any, item2: any) => item1 === item2;

export const crud = <T>(
  init$?: Observable<T[]>,
  add$?: Observable<T>,
  remove$?: Observable<T>,
  update$?: Observable<T>,
  matcher: ((item1: T, item2: T) => boolean) | keyof T = defaultMatcher,
  prependAdd?: boolean,
) => {
  let matcherFunc: (item1: T, item2: T) => boolean;

  if (typeof matcher === 'string') {
    matcherFunc = (item1: T, item2: T): boolean => item1[matcher as keyof T] === item2[matcher as keyof T];
  } else if (matcher == null) {
    matcherFunc = defaultMatcher;
  } else {
    matcherFunc = matcher as (item1: T, item2: T) => boolean;
  }

  return merge(
    ...[
      ...(init$ ? [init$.pipe(map((init) => ({ init })))] : []),
      ...(add$ ? [add$.pipe(map((add) => ({ add })))] : []),
      ...(remove$ ? [remove$.pipe(map((remove) => ({ remove })))] : []),
      ...(update$ ? [update$.pipe(map((update) => ({ update })))] : []),
    ],
  ).pipe(
    scan((acc: T[], { init, add, remove, update }: { init?: T[]; add?: T; remove?: T; update?: T }) => {
      if (init) {
        return [...init];
      }

      acc = [...acc];

      const getIndex = (item: T): number => acc.findIndex((subject: T) => matcherFunc(item, subject));

      if (add) {
        const idx = getIndex(add);

        if (idx === -1) {
          if (prependAdd) {
            acc.unshift(add);
          } else {
            acc.push(add);
          }
        } else {
          acc[idx] = { ...add };
        }
      } else if (remove) {
        const idx = getIndex(remove);

        if (idx !== -1) {
          acc.splice(idx, 1);
        }
      } else if (update) {
        const idx = getIndex(update);

        if (idx !== -1) {
          acc[idx] = { ...update };
        }
      }

      return acc;
    }, []),
    shareRef(),
  );
};

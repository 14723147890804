/**
 * Formats date to human readable form.
 *
 * @unstable
 */

import { Store } from '@ngxs/store';

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { PrefsState } from '@shared/states/prefs.state';

@Pipe({
  name: 'formatDate',
})
export class FormatDate implements PipeTransform {
  private datePipe: DatePipe;

  constructor(readonly store: Store) {
    this.datePipe = new DatePipe('en-US');
  }

  public transform(timestamp: string | number | null, timezone?: string, locale?: string): any {
    if (!timestamp) {
      return null;
    }

    if (!isNaN(+timestamp) && timestamp < 10000000000) {
      timestamp = +timestamp * 1000;
    }

    if (!locale) {
      locale = this.store.selectSnapshot(PrefsState.locale);
    }

    if (typeof timestamp === 'string') {
      timestamp = timestamp.replace(/([+\-]\d\d)(\d\d)$/, '$1:$2');
    }

    const now = new Date();
    const date = new Date(timestamp);

    if (timestamp === 'Invalid Date') {
      return '';
    }

    const yesterday = ((today) => {
      today.setDate(now.getDate() - 1);

      return today;
    })(new Date());

    if (
      now.getDate() === date.getDate() &&
      now.getMonth() === date.getMonth() &&
      now.getFullYear() === date.getFullYear()
    ) {
      return this.datePipe.transform(date, 'HH:mm', timezone, locale);
    } else if (
      yesterday.getDate() === date.getDate() &&
      yesterday.getMonth() === date.getMonth() &&
      yesterday.getFullYear() === date.getFullYear()
    ) {
      const format = $localize`:format date pipe format@@zef-i18n-00014:\'Yesterday\' HH:mm`;
      return this.datePipe.transform(date, format, timezone, locale);
    } else {
      return this.datePipe.transform(date, 'dd.MM.yyyy', timezone, locale);
    }
  }
}

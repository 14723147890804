import { defer, switchMap, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  InjectFlags,
  Injector,
  Input,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';

import { NgScrollbar } from 'ngx-scrollbar';

import { SidenavRef } from '@shared/modules/sidenav/models/sidenav.models';
import { SidenavMenu } from '@shared/modules/sidenav/directives/sidenav-menu.directive';
import { SidenavTitle } from '@shared/modules/sidenav/directives/sidenav-title.directive';
import { SidenavContent } from '@shared/modules/sidenav/directives/sidenav-content.directive';
import { SidenavDirective } from '@shared/modules/sidenav/directives/sidenav.directive';
import { SidenavDiscardDialog } from '@shared/modules/sidenav/directives/sidenav-discard-dialog.directive';
import { SidenavButtonPrimary } from '@shared/modules/sidenav/directives/sidenav-button-primary.directive';
import { SidenavButtonSecondary } from '@shared/modules/sidenav/directives/sidenav-button-secondary.directive';
import { SidenavHeaderDirective } from '@shared/modules/sidenav/directives/sidenav-header.directive';
import { InlineDialog, InlineDialogTriggerFor } from '@shared/components/inline-dialog/inline-dialog.component';

@Component({
  selector: 'zef-sidenav',
  exportAs: 'zefSidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('toggle', [
      state('*', style({ transform: 'translateX(0)' })),
      transition('left => void', animate('200ms ease-in-out', style({ transform: 'translateX(-100%)' }))),
      transition('void => left', [style({ transform: 'translateX(-100%)' }), animate('200ms ease-in-out')]),
      transition('right => void', animate('200ms ease-in-out', style({ transform: 'translateX(100%)' }))),
      transition('void => right', [style({ transform: 'translateX(100%)' }), animate('200ms ease-in-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Sidenav {
  @Input()
  set confirmClose(confirmClose: boolean) {
    this._confirmClose = confirmClose;

    this.sn.confirmClose = this.confirmClose
      ? defer(() => {
          if (!this.confirmMenu.open) {
            this.confirmDialogTrigger.openDialog();
          }

          return timer(1).pipe(
            switchMap(() => this.confirmMenu.confirm.pipe(takeUntil(this.confirmMenu.close))),
            map(() => true),
          );
        })
      : undefined;
  }

  get confirmClose(): boolean {
    return this._confirmClose;
  }

  private _confirmClose = false;

  @Input()
  loading?: boolean = false;

  @Input()
  withScroll: boolean = true;

  @ContentChild(SidenavContent)
  content?: SidenavContent;

  @ContentChild(SidenavTitle)
  _title?: SidenavTitle;

  @ContentChild(SidenavMenu)
  _menu?: SidenavMenu;

  @ContentChild(SidenavDiscardDialog)
  _discardDialog?: SidenavDiscardDialog;

  @ContentChild(SidenavButtonPrimary)
  _buttonPrimary?: SidenavButtonPrimary;

  @ContentChild(SidenavButtonSecondary)
  _buttonSecondary?: SidenavButtonSecondary;

  @ContentChild(SidenavHeaderDirective)
  header?: SidenavHeaderDirective;

  @ViewChild(NgScrollbar)
  ngScrollbar: NgScrollbar;

  @ViewChild('trigger', { read: InlineDialogTriggerFor })
  confirmDialogTrigger?: InlineDialogTriggerFor;

  @ViewChild('confirmMenu')
  confirmMenu?: InlineDialog;

  @Output()
  readonly close = this.sidenavRef?.sidenav.close;

  @Output()
  readonly afterClosed = this.sidenavRef?.sidenav.afterClosed;

  get sn(): SidenavDirective {
    return this.injector.get(SidenavDirective, null, InjectFlags.Optional);
  }

  get title(): SidenavTitle {
    return this._title || this.header?.title;
  }

  get menu(): SidenavMenu {
    return this._menu || this.header?.menu;
  }

  get buttonPrimary(): SidenavButtonPrimary {
    return this._buttonPrimary || this.header?.buttonPrimary;
  }

  get buttonSecondary(): SidenavButtonSecondary {
    return this._buttonSecondary || this.header?.buttonSecondary;
  }

  get discardDialog(): SidenavDiscardDialog {
    return this._discardDialog || this.header?.discardDialog;
  }

  constructor(private injector: Injector, @Optional() private sidenavRef: SidenavRef) {}

  closeSidenav(): void {
    if (this.sn) {
      this.sn.resetClosing();
      this.sn.closeSidenav();
    }
  }
}

import { BehaviorSubject, fromEvent, Observable, timer, of } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  delayWhen,
  scan,
  skip,
} from 'rxjs/operators';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  HostListener,
  ContentChild,
  TemplateRef,
  NgModule,
  Directive,
  ContentChildren,
  QueryList,
  Host,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { shareRef } from '@shared/operators/share-ref.operator';
import { isShallowEqual } from '@shared/utilities/object.utilities';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Directive({
  selector: '[stepSliderLabel]',
})
export class StepSliderLabel {
  constructor(readonly tr: TemplateRef<any>) {}
}

@Directive({
  selector: '[stepSliderStep]',
})
export class StepSliderStep {
  constructor(readonly tr: TemplateRef<any>) {}
}

@Component({
  selector: 'zef-step-slider',
  templateUrl: './step-slider.component.html',
  styleUrls: ['./step-slider.component.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepSlider implements AfterViewInit, OnChanges {
  steps_: any[] = [];

  private isDragging: boolean = false;

  @Input() set steps(value: number | string) {
    this.steps_ = Array.from({ length: +value }, (_, i) => i);
  }

  @Input() thumb1: number | null = null;
  @Input() thumb2: number = 0;
  @Input() offset: number = 0;
  @Input() color: string = 'primary';
  @Input() funnelMode: boolean = false;
  @Input()
  @HostBinding('class.disabled')
  disabled: boolean = false;

  @HostBinding('class')
  get theme() {
    return `mat-${this.color}`;
  }

  @ContentChild(StepSliderLabel)
  sliderLabelTmpl?: StepSliderLabel;

  @ContentChildren(StepSliderStep)
  sliderStepsTmpl?: QueryList<StepSliderStep>;

  @ViewChild('sliderLabel')
  sliderLabel?: ElementRef<HTMLDivElement>;

  @ViewChild('wrapper')
  wrapper: ElementRef<HTMLElement>;

  thumbs$: BehaviorSubject<{ thumb1: number; thumb2: number }> = new BehaviorSubject({ thumb1: null, thumb2: 0 });

  @Output()
  change: EventEmitter<{ thumb1: number; thumb2: number }> = new EventEmitter();

  @Output()
  commit: EventEmitter<{ thumb1: number; thumb2: number }> = new EventEmitter(true);

  readonly progressStyle$: Observable<{ width: string; left: string }> = this.thumbs$.pipe(
    filter((thumbs) => thumbs !== null),
    map(({ thumb1, thumb2 }) => ({
      width: Math.abs(thumb1 - thumb2) * 100 + '%',
      left: Math.min(thumb1, thumb2) * 100 + '%',
    })),
  );

  readonly thumb1Left$ = this.thumbs$.pipe(map((thumbs) => thumbs?.thumb1 * 100));

  readonly thumb2Left$ = this.thumbs$.pipe(map((thumbs) => thumbs?.thumb2 * 100));

  readonly labelStyle$ = this.thumbs$.pipe(
    startWith({ thumb1: null, thumb2: 0 }),
    scan((a, b) => ({ update: (a.pos.thumb1 === a.pos.thumb2) !== (b.thumb1 === b.thumb2), pos: b }), {
      update: true,
      pos: { thumb1: 0, thumb2: 0 },
    }),
    delayWhen(({ update }) => (update ? timer(1) : of(void 0))),
    map(() => this.getLabelStyle(this.thumbs$.getValue())),
    shareRef(),
  );

  constructor(private lh: LifecycleHooks) {}

  @HostListener('dragstart.dsc')
  noop = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    const hasThumbChanges = 'thumb1' in changes || 'thumb2' in changes;

    if (hasThumbChanges) {
      const thumb1 = this.thumb1;
      const thumb2 = this.thumb2 || 0;

      this.thumbs$.next({ thumb1, thumb2 });
    }
  }

  ngAfterViewInit(): void {
    const node = this.wrapper?.nativeElement;

    if (!node) {
      return;
    }

    let rect: ClientRect;

    const start$ = fromEvent<MouseEvent>(node, 'mousedown').pipe(
      map((event) => {
        rect = node.getBoundingClientRect();

        const start = event.clientX + this.offset;
        const percent = this.getPercent(start, rect);

        const { thumb1, thumb2 } = this.thumbs$.value;
        const thumb: 'thumb1' | 'thumb2' =
          thumb1 != null && Math.abs(thumb1 - percent) < Math.abs(thumb2 - percent) ? 'thumb1' : 'thumb2';

        return {
          start,
          thumb,
        };
      }),
      takeUntil(this.lh.destroy),
      shareRef(),
    );

    const end$ = fromEvent<MouseEvent>(document, 'mouseup', { passive: true }).pipe(
      tap(() => (this.isDragging = false)),
      map(() => this.endPosition),
      map((pos) => {
        this.thumbs$.next(pos);
        this.commit.emit(pos);
      }),
      takeUntil(this.lh.destroy),
    );

    const current$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
      tap(() => (this.isDragging = true)),
      map((event) => event.clientX + this.offset),
      takeUntil(end$),
    );

    start$
      .pipe(
        switchMap(({ start, thumb }) =>
          current$.pipe(
            startWith(start),
            map((end) => this.moveThumbs(thumb, end, rect || node.getBoundingClientRect())),
          ),
        ),
        takeUntil(this.lh.destroy),
      )
      .subscribe();

    this.thumbs$
      .asObservable()
      .pipe(
        map(() => this.endPosition),
        distinctUntilChanged(isShallowEqual),
        skip(1),
        map((result) => this.change.emit(result)),
        takeUntil(this.lh.destroy),
      )
      .subscribe();
  }

  isStepActive(idx: number): boolean {
    const percent = idx / this.steps_.length;

    const { thumb1, thumb2 } = this.endPosition;

    return thumb1 <= percent && percent < thumb2;
  }

  onStepClick(index: number): void {
    let { thumb1, thumb2 } = this.endPosition;
    const increment = 1 / this.steps_.length;

    const idx1 = Math.round(thumb1 / increment);
    const idx2 = Math.round(thumb2 / increment);
    const diff1 = idx1 - index;
    const diff2 = idx2 - index;
    const newPos = index * increment;

    if (index >= idx1 && index >= idx2) {
      if (thumb1 > thumb2 && thumb1 != null) {
        thumb1 = newPos + increment;
      } else {
        thumb2 = newPos + increment;
      }
    } else if (index < idx1 && index < idx2) {
      if (thumb1 < thumb2 && thumb1 != null) {
        thumb1 = newPos;
      } else {
        thumb2 = newPos;
      }
    } else if (Math.abs(idx1 - idx2) === 2 && index >= Math.min(idx1, idx2) && index < Math.max(idx1, idx2)) {
      if (index === idx1) {
        thumb2 = newPos + increment;
      } else if (index === idx2 && thumb1 != null) {
        thumb1 = newPos + increment;
      } else {
        if (thumb1 < thumb2 && thumb1 != null) {
          thumb1 = newPos;
        } else {
          thumb2 = newPos;
        }
      }
    } else if (index > idx1 && index < idx2 && idx1 < idx2) {
      if (Math.abs(diff1) < diff2 - 1 && thumb1 != null) {
        thumb1 = newPos;
      } else {
        thumb2 = newPos + increment;
      }
    } else if (index > idx2 && index < idx1 && idx2 < idx1) {
      if (Math.abs(diff2) < diff1 - 1 && thumb1 != null) {
        thumb2 = newPos;
      } else {
        thumb1 = newPos + increment;
      }
    }

    this.thumbs$.next({ thumb1, thumb2 });
    this.commit.emit({ thumb1, thumb2 });
  }

  private getPercent(start: number, rect: ClientRect): number {
    const { left, right, width } = rect;

    const current = Math.max(left, Math.min(start, right));
    return (current - left) / width;
  }

  private moveThumbs(thumb, end, rect) {
    const percent = this.getPercent(end, rect);
    const thumbs = this.thumbs$.value;

    thumbs[thumb] = percent;

    this.thumbs$.next({ ...thumbs, [thumb]: percent });
  }

  private get endPosition() {
    const previous = this.thumbs$.value;
    const step = 1 / this.steps_.length;

    const thumb1 = previous.thumb1 && Math.round(previous.thumb1 / step) * step;
    const thumb2 = Math.round(previous.thumb2 / step) * step;

    return {
      thumb1: previous.thumb1 != null ? (thumb1 < thumb2 ? thumb1 : thumb2) : null,
      thumb2: previous.thumb1 == null || thumb1 < thumb2 ? thumb2 : thumb1,
    };
  }

  private getLabelStyle(thumbs: { thumb1: number; thumb2: number }): { left?: string; right?: string } {
    const container = this.wrapper?.nativeElement.offsetWidth || 0;
    const mid = (thumbs.thumb1 + thumbs.thumb2) / 2;
    const diff = mid * container;
    const labelHalf = (this.sliderLabel?.nativeElement.offsetWidth || 0) / 2;

    if (diff - labelHalf < -8) {
      return { left: `${labelHalf - 8}px` };
    } else if (diff + labelHalf > container + 8) {
      return { right: `-${labelHalf + 8}px` };
    } else {
      return { left: `${mid * 100}%` };
    }
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [StepSlider, StepSliderStep, StepSliderLabel],
  exports: [StepSlider, StepSliderStep, StepSliderLabel],
})
export class StepSliderModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { Spinner } from '@shared/components/spinner/spinner.component';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [Spinner],
  exports: [Spinner],
})
export class SpinnerModule {}

import { StateToken } from '@ngxs/store';

import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';

export const HelpSubjects = Object.values(HelpSubject);

export const HELP_CENTER_STATE = new StateToken<HelpCenterStateModel>('helpCenter');

export type HelpItemSubject = HelpSubject | (typeof HelpSubjects)[0];

export type HelpCenterLanguage = 'en' | 'fi';

export const HelpCenterToolTipMaxChar = 160;

export enum HelpCenterMode {
  Read,
  Preview,
  Edit,
}

export enum HelpCenterItemStatus {
  Missing,
  Partial,
  Complete,
}

export interface HelpCenterItemData {
  title?: string;
  quickTip?: string;
  externalLink?: string;
  article?: string;
  parsedArticle?: string;
}

export interface HelpCenterItemStatistics {
  hovers: number;
  clicks: number;
  externalClicks: number;
}

export interface HelpCenterItem {
  status?: HelpCenterItemStatus;
  data?: Record<HelpCenterLanguage, HelpCenterItemData>;
  stats?: Record<HelpCenterLanguage, HelpCenterItemStatistics>;
}

export type HelpCenterItems = Partial<Record<HelpSubject, HelpCenterItem>>;

export interface HelpCenterStateModel {
  items: HelpCenterItems;
  language?: HelpCenterLanguage;
  mode?: HelpCenterMode;
}

import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';

import { Actions, Store } from '@ngxs/store';

import { ImageUploadService } from '@shared/modules/image-upload/services/image-upload.service';
import {
  ImageDefaultValue,
  ImageGalleryItem,
  ImageUploadDirectory,
  ImageUrlSource,
} from '@shared/modules/image-upload/models/image-upload.models';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { ImageUploadDefaultDirective } from '@shared/modules/image-upload/directive/image-upload-default.directive';
import { FileUploadMode, FileUploadSize } from '@shared/modules/file-upload/file-upload.models';

@Component({
  selector: 'zef-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [{ provide: ImageUploadService, useExisting: ImageUploadComponent }, LifecycleHooks],
})
export class ImageUploadComponent extends ImageUploadService {
  @Input()
  shape: FileUploadMode = 'image';

  @Input()
  size: FileUploadSize = 'medium';

  @Input()
  directory: ImageUploadDirectory;

  @Input()
  source: string = ImageDefaultValue;

  @Input()
  gallery: boolean = false;

  @Input()
  color?: string;

  @Input()
  placeholder: string = '';

  @Input()
  customGallery?: boolean;

  @Input()
  disableClick?: boolean;

  @Input()
  urlSource: ImageUrlSource = 'regular';

  @ContentChild(ImageUploadDefaultDirective, { static: true })
  defaultDirective?: ImageUploadDefaultDirective;

  @Output()
  readonly sourceChange = new EventEmitter<{ image: ImageGalleryItem; source: string } | null>();

  readonly trackByImage = (i: number, image: ImageGalleryItem) => image.name;

  constructor(store: Store, lh: LifecycleHooks, actions: Actions) {
    super(store, lh, actions);
  }
}

/**
 * Dialog for showing basic information.
 *
 * @unstable
 */

import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  TemplateRef,
  ContentChild,
  OnInit,
} from '@angular/core';

import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { SourceType } from '@shared/models/utility.model';

@Component({
  selector: 'zef-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Dialog implements OnInit {
  // Types: '','ask','info','warn','error','failed','success'
  @Input() type: string = '';

  @Input() logo: string = '';
  @Input() sourceType?: SourceType;
  @Input() sourceKey?: string;
  @Input() title: string = '';
  @Input() label: string = '';

  @Input() action: string = '';
  @Input() cancel: string = '';

  @Input() header: string = '';
  @Input() content: string = '';

  @Input() background: string = '';

  @Input() disabled: boolean = false;

  @Input() logoType: string = '';
  @Input() showInput: boolean = false;
  @Input() hideHeader: boolean = false;
  @Input() hideContent: boolean = false;
  @Input() noActionPadding: boolean = false;
  @Input() hideActions: boolean = false;
  @Input() hideBorder: boolean = false;
  @Input() transparentBackground: boolean = false;

  @Input() inputValue: string = '';
  @Input() inputIcon: string = '';
  @Input() inputIconColor: string = '';
  @Input() inputPlaceholder: string = '';

  @Input() helpSubject?: HelpSubject;

  @Output() actionClick = new EventEmitter<any>();

  @ContentChild('dialogImage', { static: false, read: TemplateRef })
  dialogImage?: TemplateRef<any>;

  get iconColorClass(): string {
    const inputIcon = this.inputIcon === 'email' ? 'emails' : this.inputIcon;
    return `zef-color-${inputIcon}`;
  }

  constructor(readonly dialogRef: MatDialogRef<Dialog>) {}

  ngOnInit(): void {
    this.logoType = this.sourceType === 'survey' ? 'rectangle' : this.logoType;

    if (this.transparentBackground) {
      this.dialogRef.addPanelClass('transparent-dialog');
    }
  }

  public getActionColor(): string {
    if (this.type === 'ask') {
      return 'primary';
    } else if (this.type === 'info') {
      return 'stealth';
    } else if (this.type === 'warn') {
      return 'warning';
    } else if (this.type === 'error') {
      return 'alert';
    } else if (this.type === 'failed') {
      return 'primary';
    } else if (this.type === 'success') {
      return 'primary';
    }

    return this.type;
  }

  onInputEnter(event: KeyboardEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    this.close(this.action);
  }

  public close(action?: string) {
    if (this.showInput) {
      this.actionClick.emit({ action, value: this.inputValue });

      this.dialogRef.close({ action, value: this.inputValue });
    } else {
      this.actionClick.emit(action);

      this.dialogRef.close(action);
    }
  }
}

import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { User } from 'firebase/auth';

import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { Params } from '@angular/router';

import { LocalStorageService } from 'ngx-webstorage';

import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { AuthError } from '@auth/auth.enum';

import { environment } from '@env/environment';

import { ClearStream } from '@shared/decorators/clear-stream.decorator';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { InviteData } from '@shared/models/account.model';
import { UserInfo } from '@shared/models/auth.model';
import { shareRef } from '@shared/operators/share-ref.operator';
import { AuditLogService } from '@shared/services/audit-log.service';
import { AuthManager } from '@shared/services/auth-manager.service';
import { RouterState } from '@shared/states/router.state';
import { pickBy } from '@shared/utilities/object.utilities';
import { queryParamsString } from '@shared/utilities/string.utilities';
import {
  AuthenticationError,
  CancelEmailSignup,
  CancelGoogleSignup,
  GetInvite,
  GoToLogoutUrl,
  GoToSignIn,
  GoToSignUp,
  InitAuthentication,
  LinkGoogle,
  LinkPassword,
  PasswordUpdated,
  RemoveUserOnAuthTokenChange,
  ResetUserInfo,
  SaveClaimsOnAuthTokenChange,
  SaveTokenOnAuthTokenChange,
  SendPasswordResetEmail,
  SendVerificationEmail,
  SignOutWithoutRedirect,
  SignOutWithRedirect,
  UnlinkGoogle,
  UnlinkPassword,
  UpdateUserOnAuthTokenChange,
} from '@shared/states/auth.actions';

export interface AuthStateModel {
  locked?: boolean | null;
  invite?: InviteData | null;
  userInfo: UserInfo | null;

  authToken: string | null;
  authError?: AuthError | null;
  authClaims?: Object | null;

  loginUrl?: string | null;
  logoutUrl?: string | null;

  trialToken?: string | null;
}

@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    invite: null,
    userInfo: null,
    authToken: null,
    authClaims: null,
  },
})
export class AuthState {
  @Selector()
  static userUid(state: AuthStateModel): string {
    return state.userInfo?.uid;
  }

  @Selector()
  static isSSOLogin(state: AuthStateModel): boolean {
    const claims = state.authClaims as any;

    const authConfig = claims?.ory || claims?.firebase;

    return Boolean(authConfig?.sign_in_provider?.startsWith('saml.'));
  }

  @Selector()
  static isAccountOk(state: AuthStateModel): boolean {
    const isAnonymous = Boolean(state.userInfo?.isAnonymous);
    const isDataLoaded = !!state.userInfo;

    return isAnonymous || isDataLoaded;
  }

  @Selector()
  static isAnonymous(state: AuthStateModel): boolean {
    return Boolean(state.userInfo?.isAnonymous);
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return state.userInfo !== null;
  }

  @Selector()
  static isUserVerified(state: AuthStateModel): boolean {
    const claims = state.authClaims as any;

    // We allow email missing for ORY SAML authentications for now so it can be masked from Firebase auth logging

    return Boolean(
      (state.userInfo?.email && state.userInfo?.emailVerified) || claims?.ory?.sign_in_provider.startsWith('saml.'),
    );
  }

  @Selector()
  static isNewUser(state: AuthStateModel): boolean {
    return Boolean(state.userInfo?.isNewUser);
  }

  @Selector()
  static authToken(state: AuthStateModel): string {
    return state.authToken || '';
  }

  @Selector()
  static authClaims(state: AuthStateModel): any {
    return state.authClaims || {};
  }

  @Selector()
  static authScope(state: AuthStateModel): string {
    return state.authClaims ? (state.authClaims as any).scope : '';
  }

  @Selector()
  static authError(state: AuthStateModel): string {
    return state.authError;
  }

  @Selector()
  static invite(state: AuthStateModel): InviteData {
    return state.invite;
  }

  @Selector([RouterState.routeParams])
  static isInviteRoute(state: AuthStateModel, params: Params): boolean {
    return Boolean(state.invite?.$key) && state.invite?.$key === params?.inviteKey;
  }

  @Selector()
  static info(state: AuthStateModel): UserInfo {
    return state.userInfo;
  }

  @Selector()
  static isGoogleConnected(state: AuthStateModel): boolean {
    const hasGoogle = state?.userInfo?.providerData?.some((data) => data.providerId === 'google.com');

    return hasGoogle || false;
  }

  @Selector()
  static allowPublicComments(state: AuthStateModel): boolean {
    const authClaims = state.authClaims as any;

    const authConfig = authClaims?.ory || authClaims?.firebase;

    return Boolean(
      authConfig?.sign_in_provider === 'saml.zef' &&
        (authConfig?.sign_in_attributes?.reportPublicComments ||
          authConfig?.sign_in_attributes?.groups?.includes('Report admins')),
    );
  }

  @Selector()
  static userInfoForGoogleProvider(state: AuthStateModel): Partial<UserInfo | null> {
    const userInfo = state?.userInfo?.providerData?.find((data) => data.providerId === 'google.com');

    return userInfo || null;
  }

  @Selector()
  static userInfoForPasswordProvider(state: AuthStateModel): Partial<UserInfo | null> {
    const userInfo = state?.userInfo?.providerData?.find((data) => data.providerId === 'password');

    return userInfo || null;
  }

  @Selector()
  static isEmailConnected(state: AuthStateModel): boolean {
    const hasEmail = state?.userInfo?.providerData?.some((data) => data.providerId === 'password');

    return hasEmail || false;
  }

  @Selector()
  static loginUrl(state: AuthStateModel): string {
    return state.loginUrl || '';
  }

  @Selector()
  static isZefAdmin(state: AuthStateModel) {
    return state.userInfo?.email?.endsWith('@zef.fi') || false;
  }

  @Selector()
  static isZefDeveloper(state: AuthStateModel) {
    return [
      'aleksandar.milosevic@zef.fi',
      'elena.kruijt@zef.fi',
      'janne.julkunen@zef.fi',
      'juha.koskela@zef.fi',
      'markku.alasaarela@zef.fi',
      'poul.kruijt@zef.fi',
      'saad.chaudhry@zef.fi',
    ].includes(state.userInfo?.email);
  }

  @Selector()
  static isInviteAccepted({ invite }: AuthStateModel) {
    return Boolean(invite?.accepted);
  }

  constructor(
    @Inject(LOCALE_ID) readonly locale: string,
    @Optional() private ls: LocalStorageService,
    private store: Store,
    private am: AuthManager,
    private al: AuditLogService,
  ) {}

  @Action(InitAuthentication)
  initAuthentication() {
    return this.am.init();
  }

  @Action(UpdateUserOnAuthTokenChange)
  login({ getState, patchState }: StateContext<AuthStateModel>, { userInfo }: UpdateUserOnAuthTokenChange) {
    const state = getState();

    const signedIn = state.userInfo ? state.userInfo.uid !== userInfo.uid : false;

    const userChanged = state.userInfo ? state.userInfo.uid !== userInfo.uid : true;

    const isReportSignin = userInfo?.email?.includes('+report') || false;

    if (signedIn && !isReportSignin && !environment.website) {
      console.log('External sign in detected');

      patchState({ locked: true });

      return this.al.active$.pipe(
        filter((active) => !active),
        tap(() => window.location.reload()),
      );
    } else if (userChanged) {
      console.log('Active user changed', state.userInfo?.uid, userInfo.uid);
    }

    patchState({ userInfo });
  }

  @Action(RemoveUserOnAuthTokenChange)
  logout({ getState, setState, patchState, dispatch }: StateContext<AuthStateModel>) {
    const state = getState();

    const signedOut = !!state.userInfo;

    const authClaims = state.authClaims as any;
    const trialToken = state.authToken || null;

    const wasOryLogin = authClaims?.ory?.sign_in_provider;
    const wasAnonymous = state.userInfo && state.userInfo.isAnonymous;

    if (!!this.ls) {
      this.ls.clear('report-team');
    }

    // default AuthStateModel
    setState({
      userInfo: null,
      invite: null,
      authToken: null,
      authClaims: null,
    });

    if (signedOut && !environment.website) {
      console.log('External sign out detected');

      if (wasOryLogin) {
        this.am.oryLogout().subscribe((logoutRes: any) => {
          console.log('ORY logout response', logoutRes);

          const logoutUrl = state.logoutUrl || 'https:' + environment.publicUrl;

          if (logoutRes?.logout_url) {
            dispatch(new GoToLogoutUrl(logoutRes.logout_url + '&return_to=' + logoutUrl));
          } else {
            dispatch(new GoToLogoutUrl(logoutUrl));
          }
        });
      }

      // patchState({ locked: true });
      // dispatch(new Navigate(['/login']));
      // window.location.reload();
    } else if (trialToken && wasAnonymous) {
      patchState({ trialToken });
    }
  }

  @Action(SaveTokenOnAuthTokenChange)
  updateToken({ getState, patchState }: StateContext<AuthStateModel>, { authToken }: SaveTokenOnAuthTokenChange) {
    const state = getState();

    const tokenChanged = state.authToken ? state.authToken !== authToken : true;

    if (tokenChanged) {
      console.log('Auth token updated', state.userInfo);
    }

    patchState({ authToken });
  }

  @StreamAction(SaveClaimsOnAuthTokenChange)
  updateClaims({ patchState }: StateContext<AuthStateModel>, { authClaims }: SaveClaimsOnAuthTokenChange) {
    console.log('Custom token claims', authClaims);

    patchState({ authClaims });

    const authConfig = authClaims?.ory || authClaims?.firebase;

    return !authConfig.sign_in_provider?.startsWith('saml.')
      ? of(null)
      : this.am.getSSOSettings(authConfig.sign_in_provider.slice(5)).pipe(
          map((loginSettings: any) => {
            console.log('SSO login settings', loginSettings);

            return patchState({ logoutUrl: loginSettings.logoutUrl || 'https:' + environment.publicUrl });
          }),
          shareRef(),
        );
  }

  @Action(SendVerificationEmail)
  SendVerificationEmail({ getState }: StateContext<AuthStateModel>, { redirect }: SendVerificationEmail) {
    const state = getState();
    const token = state.authToken;

    return this.am.sendInvite(token, true, redirect);
  }

  @StreamAction(GetInvite)
  getInvite({ patchState }: StateContext<AuthStateModel>, { inviteKey }: GetInvite) {
    return !inviteKey
      ? of(null)
      : this.am.getInvite(inviteKey).pipe(tap((invite: InviteData) => patchState({ invite })));
  }

  @Action(SignOutWithRedirect)
  @ClearStream()
  signOut({ dispatch, getState }: StateContext<AuthStateModel>, { signIn }: SignOutWithRedirect) {
    const state = getState();

    console.log('User initiated sign-out');

    if (!state.locked || signIn) {
      this.ls.clear('signup');
      return this.am
        .signOut()
        .pipe(
          switchMap(() =>
            signIn
              ? state.logoutUrl
                ? dispatch(new GoToLogoutUrl(state.logoutUrl))
                : dispatch(new GoToSignIn())
              : of(null),
          ),
        );
    }
  }

  @Action(SignOutWithoutRedirect)
  @ClearStream()
  signOut2(ctx: StateContext<AuthStateModel>) {
    return this.am.signOut();
  }

  @Action(CancelGoogleSignup)
  cancelGoogleSignup({ dispatch }: StateContext<AuthStateModel>) {
    return this.am.deleteUser().pipe(tap(() => dispatch(new SignOutWithoutRedirect())));
  }

  @Action(CancelEmailSignup)
  cancelEmailSignup({ dispatch, setState }: StateContext<AuthStateModel>) {
    return this.am.unlinkPassword().pipe(catchError((error) => of(null)));
  }

  @Action(ResetUserInfo)
  signIn({ patchState }: StateContext<AuthStateModel>) {
    console.log('User initiated sign-in');

    patchState({ userInfo: null });
  }

  @Action(SendPasswordResetEmail)
  sendPasswordResetEmail({ patchState }: StateContext<AuthStateModel>, { email }: SendPasswordResetEmail) {
    return this.am.sendEmailPasswordResetEmail(email);
  }

  @Action(GoToSignIn)
  goToSignIn({ dispatch }: StateContext<AuthStateModel>, { mode, redirect, activate, lang }: GoToSignIn) {
    const redirectParams = pickBy({ activate, redirect, mode, lang }, (value) => !!value);
    const url = this.store.selectSnapshot(RouterState.url);

    console.log('Starting sign-in process', redirectParams, url);

    if (environment.website) {
      window.location.assign(`https:${environment.webAddress}/login` + queryParamsString(redirectParams));
    } else if (url !== '/login') {
      dispatch(new Navigate(['/login'], redirectParams));
    }
  }

  @Action(GoToSignUp)
  goToSignUp({ dispatch }: StateContext<AuthStateModel>, { redirect, activate }: GoToSignUp) {
    const signup = this.store.selectSnapshot(RouterState.routeParams).signup;

    const redirectParams = pickBy({ activate: activate || signup, redirect, signup: true }, (value) => !!value);

    console.log('Starting sign-up process', redirectParams);

    if (environment.website || activate) {
      const locale = this.locale.split('-')[0] || 'en';

      const url = locale !== 'fi' ? '/surveys/#/surveys' : '/fi/kyselyt/#/surveys';

      window.location.assign(`https:${environment.wwwAddress}${url}` + queryParamsString(redirectParams));
    } else {
      dispatch(new Navigate(['/surveys'], redirectParams));
    }
  }

  @Action(GoToLogoutUrl)
  goToLogoutUrl(ctx: StateContext<AuthStateModel>, { logoutUrl }: GoToLogoutUrl) {
    console.log('Logout url', logoutUrl);

    window.location.href = logoutUrl;
  }

  @Action(UnlinkPassword)
  unlinkPassword(ctx: StateContext<AuthStateModel>) {
    return this.am.unlinkPassword();
  }

  @Action(LinkPassword)
  linkPassword({ getState, dispatch }: StateContext<AuthStateModel>, { newPassword, oldPassword }: LinkPassword) {
    const state = getState();
    const email = state.userInfo?.email;

    if (email) {
      if (!oldPassword) {
        return this.am.reAuthenticateWithGoogle().pipe(
          switchMap((ok) => (!ok ? of(null) : this.am.linkPassword(email, newPassword))),
          map((ok) => (!ok ? null : dispatch(new PasswordUpdated()))),
          catchError((error) => of(null)),
        );
      } else {
        return this.am.reAuthenticateWithEmailPassword(email, oldPassword).pipe(
          catchError((error) => of(null)),
          switchMap((user: User) => (!user ? of(null) : this.am.updatePassword(newPassword))),
          map((ok) => (!ok ? null : dispatch(new PasswordUpdated()))),
        );
      }
    }
  }

  @Action(LinkGoogle)
  linkGoogle(ctx: StateContext<AuthStateModel>) {
    return this.am.linkGoogle().pipe(catchError((error) => of(null)));
  }

  @Action(UnlinkGoogle)
  unlinkGoogle(ctx: StateContext<AuthStateModel>) {
    return this.am.unlinkGoogle();
  }

  @Action(AuthenticationError)
  authenticationError({ patchState }: StateContext<AuthStateModel>, { code }: AuthenticationError) {
    patchState({ authError: code || undefined });
  }
}

import { map, distinctUntilChanged, startWith } from 'rxjs/operators';

import { ContentObserver } from '@angular/cdk/observers';
import { Directive, ElementRef, Output, Input } from '@angular/core';

import { shareRef } from '@shared/operators/share-ref.operator';

@Directive({
  selector: '[textOverflow]',
})
export class TextOverflow {
  // to pass on without subscribing
  @Input('textOverflow')
  textOverflowInput: any;

  @Output()
  readonly textOverflow = this.co.observe(this.el).pipe(
    startWith(0),
    map(() => this.el.nativeElement.scrollWidth - this.el.nativeElement.clientWidth > 0),
    distinctUntilChanged(),
    shareRef(),
  );

  constructor(private el: ElementRef<HTMLElement>, private co: ContentObserver) {}
}

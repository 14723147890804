/**
 * State container for report related data.
 *
 * @unstable
 */

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { State, StateContext, Store } from '@ngxs/store';

import { ReportData } from '@shared/models/survey.model';

import { ReportManager } from '@shared/services/report-manager.service';

import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { Injectable } from '@angular/core';

export class GetReports {
  static readonly type = '[Report] Get Reports';

  constructor(readonly surveyKey: string | null) {}
}

export interface ReportStateModel {
  reports: ReportData[] | null;
}

@Injectable()
@State<ReportStateModel>({
  name: 'report',
  defaults: {
    reports: null,
  },
})
export class ReportState {
  constructor(readonly store: Store, readonly rm: ReportManager) {}

  @StreamAction(GetReports)
  getReports({ patchState }: StateContext<ReportStateModel>, { surveyKey }: GetReports): Observable<void> {
    return (surveyKey ? this.rm.listReports(surveyKey) : of(null)).pipe(
      map((reports) => {
        patchState({ reports });
      }),
    );
  }
}

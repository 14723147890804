<div
  class="z-fx z-fx-start-center z-fx-gap-4 container"
  [style.position]="isTemplateReport && (filters.length > 0 || filtersDemo) ? 'absolute' : 'fixed'"
  [ngClass]="{ active: filters.length > 0 || filtersDemo, inactive: !filters.length && !filtersDemo }"
  [class.safe-report-lock]="safeReportLock"
>
  <div class="z-fx z-fx-auto z-fx-start-center z-fx-gap-8 selection-info">
    <mat-icon class="z-fx-none icon">contact</mat-icon>
    <p class="z-fx-none zef-text-h2 responses">
      {{ !filtersDemo ? (!safeReportLock ? responses : safeReportCount) : 0 }}
    </p>
    <p class="zef-text-p2 text" i18n>
      respondents selected
      <span *ngIf="!filtersDemo">with {{ filters?.length || 0 }} filters</span>
    </p>
  </div>
  <button
    *ngIf="
      !filtersDemo &&
      !sampleData &&
      (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
      !isSharedReport
    "
    mat-raised-button
    class="z-fx z-fx-start-center zef-combo-button zef-ghost-button crop-button"
    [class.disabled]="disableCrop"
    matTooltip="Cropping is not currently allowed for text inputs."
    i18n-matTooltip
    matTooltipClass="zef-tooltip-warning"
    matTooltipPosition="above"
    [matTooltipDisabled]="!disableCrop || (disableCrop && anonymityTreshold)"
    (click)="cropFilters()"
  >
    <mat-icon
      matTooltip="Anonymous survey"
      i18n-matTooltip
      matTooltipClass="zef-tooltip-space"
      matTooltipPosition="above"
      [matTooltipDisabled]="!(disableCrop && anonymityTreshold)"
    >
      {{ !(disableCrop && anonymityTreshold) ? 'crop' : 'anonymous_on' }}
    </mat-icon>
    <span i18n>Crop from selection</span>
  </button>
  <button
    *ngIf="!filtersDemo && !safeReportLock"
    mat-raised-button
    class="z-fx z-fx-start-center zef-combo-button zef-ghost-button save-button"
    (click)="saveFilters()"
  >
    <mat-icon>group_new</mat-icon>
    <span i18n>Add to comparison</span>
  </button>
  <button
    *ngIf="safeReportLock"
    mat-raised-button
    class="z-fx z-fx-start-center zef-combo-button zef-ghost-button clear-filters-button"
    (click)="removeFilters()"
  >
    <mat-icon>remove</mat-icon>
    <span i18n>Clear filters</span>
  </button>
  <div *ngIf="filtersDemo" class="z-fx z-fx-start-center filters-demo-texts">
    <span i18n class="help-text">Make a selection by highlighting on a chart</span>
    <span i18n class="action-link" (click)="exampleFilters()">Show an example</span>
  </div>
  <button mat-icon-button class="z-fx z-fx-center-center remove-button" (click)="removeFilters()">
    <mat-icon class="icon close">close</mat-icon>
  </button>
</div>

<div
  *ngIf="(isAnonymous$ | async) === false && { identity: (identity$ | async) } as asyncData"
  class="zef-visual z-fx z-fx-end-center"
>
  <div
    class="z-fx z-fx-center-center z-fx-none z-fx-gap-16"
    title="Open user menu"
    i18n-title
    [matMenuTriggerFor]="userMenu"
    (onMenuOpen)="cdRef.detectChanges()"
  >
    <zef-avatar
      size="small"
      [emblem]="true"
      [identity]="identity$ | async"
      [notifications]="(invites$ | async)?.length"
    ></zef-avatar>

    <div class="z-fx z-fx-center-center">
      <span class="label-text">
        {{ asyncData?.identity?.firstName || (asyncData?.identity?.email | parseEmail) }}
      </span>

      <mat-icon fontSet="md">keyboard_arrow_down</mat-icon>
    </div>
  </div>
</div>

<mat-menu #userMenu xPosition="before" yPosition="below">
  <nav-bar-menu></nav-bar-menu>
</mat-menu>

/**
 * Handles and abstracts sending analytics data to different destinations
 * Also handles user consent
 *
 */

import { environment } from '@env/environment';

import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { combineLatest, Subject, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, take, throttleTime } from 'rxjs/operators';

import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { LocalStorage } from 'ngx-webstorage';

import {
  AnalyticsTracks,
  GroupProperties,
  HubSpotTraits,
  IdentifyProperties,
  urlToPage,
} from '@shared/models/analytics.model';

import { AuthManager } from '@shared/services/auth-manager.service';

import { Consent } from '@shared/components/consent/consent.component';
import { AccountState } from '@shared/states/account.state';
import { PrefsState } from '@shared/states/prefs.state';
import { BillingState } from '@shared/states/billing.state';
import { ZefApi } from '@shared/services/zef-api.service';
import { DOCUMENT_REFERRER, DOCUMENT_SOURCE } from '@shared/tokens/tracking-token.tokens';
import { RemoveUserOnAuthTokenChange, UpdateUserOnAuthTokenChange } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { isShallowEqual } from '@shared/utilities/object.utilities';
import { SetVisitorId } from '@shared/states/account.actions';
import { SegmentAnalyticsService } from '@shared/services/segment-analytics.service';

declare const analytics: SegmentAnalytics.AnalyticsJS;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsMediator {
  private overlayRef: OverlayRef = null;

  public consentTracking: Subject<boolean> = new Subject();

  @LocalStorage('consent') trackingAllowed: boolean | undefined;

  // NOTE: Currently Segment only loads HubSpot & data is sent using native library
  // TODO: Verify that important properties are not being overwritten for HubSpot
  readonly analyticsOptions: SegmentAnalytics.SegmentOpts = { integrations: { HubSpot: false } };

  constructor(
    private router: Router,
    private store: Store,
    private auth: AuthManager,
    private overlay: Overlay,
    private actions: Actions,
    private za: ZefApi,
    private sa: SegmentAnalyticsService,
    @Inject(DOCUMENT_SOURCE) private source: string,
    @Inject(DOCUMENT_REFERRER) private referrer: string,
  ) {}

  initialize(): void {
    this.consentTracking
      .pipe(
        filter((allowed) => allowed && !!environment.segmentClientKey),
        switchMap(() => this.store.select(AccountState.adminSettings)),
        map((settings) => !!settings && !settings.disableAnalytics),
        filter((enabled) => enabled),
        take(1),
      )
      .subscribe(() => {
        this.sa.initSegment();
        analytics.load(environment.segmentClientKey);
        analytics.ready(() => this.startTracking());
        this.sendZefTrack();
      });

    this.getTrackingConsent();

    this.actions.pipe(ofActionDispatched(UpdateUserOnAuthTokenChange, RemoveUserOnAuthTokenChange)).subscribe(() => {
      this.sendZefTrack();
    });
  }

  /**
   * Gets tracking consent from user
   *
   * For anonymous users a consent banner is shown
   */
  public getTrackingConsent() {
    const isAnon = this.store.selectSnapshot(AuthState.isAnonymous);

    if (!isAnon) {
      this.trackingAllowed = true;
      this.consentTracking.next(true);
    } else if (this.trackingAllowed != null) {
      this.consentTracking.next(this.trackingAllowed);
    } else if (environment.website) {
      this.consentTracking.next(false);
    } else {
      this.askForBannerConsent();
    }
  }

  /**
   * Ask for consent through overlay banner
   */
  private askForBannerConsent() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
      const portal = new ComponentPortal(Consent);
      const compRef = this.overlayRef.attach(portal);

      compRef.instance.consentResult.subscribe((trackingAllowed: boolean) => {
        this.overlayRef.detach();
        this.trackingAllowed = trackingAllowed;
        this.consentTracking.next(trackingAllowed);
      });
    }
  }

  private startTracking(): void {
    console.log('%cANALYTICS ENABLED', 'color: green; font-weight: bold;');

    this.trackUserIdentity();
    this.trackPageViews();
    this.trackUserTeam();

    this.trackAuthEvents();
  }

  protected sendZefTrack(): void {
    const data: { referrer?: string; source?: string } = {};

    if (this.referrer) {
      data.referrer = this.referrer;
    }

    if (this.source) {
      data.source = this.source;
    }

    type TrackPageResponse = { vid: number };
    this.za.get('track/page', data).subscribe((response: TrackPageResponse) => {
      this.store.dispatch(new SetVisitorId('' + response?.vid || ''));
    });
  }

  /**
   * Tracks page views & sends correct page view events (either url or labeled)
   */
  trackPageViews(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        throttleTime(200),
      )
      .subscribe((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        const pageName = urlToPage(url);

        if (pageName) {
          analytics.page('App', pageName);
        } else {
          analytics.page();
        }
      });
  }

  /**
   * Tracks user identity data (User info & preferences)
   */
  trackUserIdentity(): void {
    const userPrefs = this.store.select(PrefsState).pipe(filter((x) => !!x));
    const userInfo = this.store.select(AuthState.info).pipe(filter((x) => !!x));

    // User Info & preferences
    combineLatest([userInfo, userPrefs])
      .pipe(
        map(([user, prefs]) => {
          let { identity, language } = prefs;
          // TODO: Find why changing the default state value doesn't change it here
          identity = identity || {};
          language = language || 'en';

          return {
            avatar: identity.avatar || user.photoURL,
            firstName: identity.firstName,
            lastName: identity.lastName,
            email: identity.email || user.email,
            name: identity.name || user.displayName,
            userId: user.$key,
            createdAt: new Date(user.creationTime).getTime(),
            lastSignInAt: new Date(user.lastSignInTime).getTime(),
            language,
          };
        }),
        distinctUntilChanged(isShallowEqual),
        debounceTime(100),
      )
      .subscribe((traits: Partial<IdentifyProperties>) => {
        this.identify({ userId: traits.userId, traits });

        this.sendDataToHubSpot({
          email: traits.email,
          new_version_language: traits.language,
        });
      });
  }

  /**
   * Tracks and set user's team related data (billing, teamn prefs)
   */
  trackUserTeam(): void {
    this.store
      .select(AccountState.teamKey)
      .pipe(filter(Boolean), debounceTime(100))
      .subscribe((teamKey: string) => {
        const traits: Partial<IdentifyProperties> = {
          defaultTeam: teamKey,
        };

        this.identify({ traits });
      });

    this.store
      .select(AccountState.team)
      .pipe(
        filter((team) => !!team),
        map((team) => ({
          id: team.$key,
          activeTeam: team.$key,
          activeTeamName: team.name || '', // TODO: Somehow previous login name stays
          activeTeamPlan: team.adminSettings?.plan,
          employees: Object.keys(team.users || {}).length,
          avatar: team.logo,
          createdAt: team.created,
          name: team.name,
          plan: team.adminSettings?.plan,
          website: team.website,
        })),
        distinctUntilChanged(isShallowEqual),
        debounceTime(100),
      )
      .subscribe((props: Partial<GroupProperties & IdentifyProperties>) => {
        const traits: Partial<IdentifyProperties> = {
          activeTeam: props.activeTeam,
          activeTeamName: props.activeTeamName,
          activeTeamPlan: props.activeTeamPlan,
        };

        this.identify({ traits });

        this.group(props.id, {
          id: props.id,
          avatar: props.avatar,
          createdAt: props.createdAt,
          name: props.name,
          plan: props.plan,
          employees: props.employees,
          website: props.website,
        });
      });

    this.store
      .select(BillingState.activePlan)
      .pipe(
        filter((activePlan) => !!activePlan),
        map((plan) => {
          const billingDate = plan.paymentPlan.nextBillingDate?.getTime() ?? null;

          return {
            billingPlan: plan.paymentPlan.period,
            billingDate,
            trialEnds: plan.plan.id === 'trial_plan' ? billingDate : null,
          };
        }),
        distinctUntilChanged(isShallowEqual),
        debounceTime(100),
      )
      .subscribe((traits) => {
        this.identify({ traits });
      });
  }

  /**
   * Track auth events such as Login, Logout & Signup
   */
  trackAuthEvents(): void {
    // Signup completion, we only send this event once
    // Currently you can signup using email or provider so the event is emitted
    // inside auth manager and sign-up view just to be sure
    this.auth.onSignupComplete.pipe(first()).subscribe((_) => {
      this.identify({
        traits: {
          signUpComplete: Date.now(),
        },
      });

      this.track(AnalyticsTracks.AUTH_SIGNUP_COMPLETE);
    });
  }

  /**
   * Sends traits to HubSpot contacts
   *
   * NOTE: Currently Segment only loads HubSpot but doesn't send any
   * data to it, since it could overwrite existing Contact data (identity, company etc.)
   *
   * This is a temporary way to solve the situation.
   *
   * TODO: Find a clean way to include HubSpot in Segment
   *
   * @param traits
   */
  sendDataToHubSpot(traits: HubSpotTraits) {
    try {
      if (window['_hsq']) {
        const _hsq = window['_hsq'];
        _hsq.push(['identify', traits]);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  /**
   * Identify current user or set personal traits
   *
   * @param param userId & traits to send
   */
  identify({ userId, traits }: { userId?: string; traits?: Partial<IdentifyProperties> }) {
    if (!analytics) {
      console.warn('Analytics not enabled');
      return;
    }

    if (userId && traits) {
      analytics.identify?.(userId, traits, this.analyticsOptions);
    } else if (traits) {
      analytics.identify?.(traits, this.analyticsOptions);
    } else {
      analytics.identify?.();
    }
  }

  /**
   * Identify current user as part of a group (Organization)
   *
   * @param groupId Firebase UID of Org
   * @param traits Traits to send
   */
  group(groupId, traits: Partial<GroupProperties>) {
    analytics.group?.(groupId, traits, this.analyticsOptions);
  }

  /**
   *
   * @param event Tracked event
   * @param payload
   */
  public track(event: AnalyticsTracks, properties: object = {}) {
    analytics.track?.(event, { ...properties, category: 'App' }, this.analyticsOptions);
  }
}

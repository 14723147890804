import { Component, Input } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'card-scroll',
  templateUrl: './card-scroll.component.html',
  styleUrls: ['./card-scroll.component.scss'],
})
export class CardScroll {
  @Input()
  first?: boolean;

  @Input()
  last?: boolean;

  @Input()
  fixed: boolean = false;

  readonly maxHeight = this.ss.playerDimensions.pipe(
    map(({ height, scrollable }) => {
      if (this.fixed) {
        return `${height}px`;
      } else if (scrollable) {
        return 'none';
      }
    }),
  );

  constructor(readonly ss: SurveyStore) {}
}

import { Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BasicModule } from '@shared/modules/basic.module';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { Observable } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

export type HintDialogCtx = { $implicit?: any; [key: string]: any };

export interface HintDialogRef {
  close: Observable<void>;

  openDialog(connectedTo: ElementRef<HTMLElement>, ctx: HintDialogCtx): void;
  closeDialog(): void;
}

@Directive({
  selector: '[hintDialogTriggerFor]',
  exportAs: 'hintDialogTrigger',
  providers: [LifecycleHooks],
})
export class HintDialogTriggerFor implements OnChanges {
  @Input()
  hintDialogTriggerFor?: HintDialogRef;

  @Input()
  hintDialogCtx?: HintDialogCtx;

  @Input()
  hintDialogDisabled?: boolean;

  constructor(private element: ElementRef<HTMLElement>, private lh: LifecycleHooks) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hintDialogTriggerFor) {
      this.hintDialogTriggerFor?.close.pipe(takeUntil(this.lh.destroy.pipe(delay(1)))).subscribe(() => {});
    }
  }

  @HostListener('mouseenter', ['$event'])
  onEnter(event: MouseEvent): void {
    if (!this.hintDialogDisabled && this.hintDialogTriggerFor) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.openDialog();
    }
  }

  @HostListener('mouseleave', ['$event'])
  onLeave(event: MouseEvent): void {
    if (!this.hintDialogDisabled && this.hintDialogTriggerFor) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();

      this.closeDialog();
    }
  }

  openDialog(): void {
    this.hintDialogTriggerFor.openDialog(this.element, this.hintDialogCtx);
  }

  closeDialog(): void {
    this.hintDialogTriggerFor.closeDialog();
  }
}

@Component({
  selector: 'zef-hint-dialog',
  templateUrl: './hint-dialog.component.html',
  styleUrls: ['./hint-dialog.component.scss'],
  exportAs: 'hintDialog',
})
export class HintDialog implements OnDestroy, HintDialogRef {
  @ViewChild(CdkPortal, { static: false })
  portal?: CdkPortal;

  @Output()
  readonly close = new EventEmitter<void>();

  get open(): boolean {
    return !!this.overlayRef?.hasAttached();
  }

  get ctx(): HintDialogCtx {
    return this._ctx;
  }

  private overlayRef: OverlayRef;

  private _ctx: HintDialogCtx;

  constructor(private overlay: Overlay, private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.close.emit();
    this.overlayRef?.dispose();
  }

  openDialog(connectedTo: ElementRef<HTMLElement>, ctx?: HintDialogCtx): void {
    if (this.open) {
      return;
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    const config = new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(connectedTo)
        .withPositions([
          { offsetX: 0, offsetY: -32, originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' },
          { offsetX: 0, offsetY: -32, originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
          { offsetX: 0, offsetY: 32, originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' },
          { offsetX: 0, offsetY: 32, originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' },
        ])
        .withFlexibleDimensions(true),
      hasBackdrop: false,
      disposeOnNavigation: true,
    });

    this.overlayRef = this.overlay.create(config);
    this._ctx = ctx;
    this.overlayRef.attach(this.portal);

    setTimeout(() => this.overlayRef?.updatePosition());
  }

  closeDialog(): void {
    if (this.open) {
      this.close.emit();
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.cd.markForCheck();
    }
  }
}

@NgModule({
  imports: [CommonModule, PortalModule, OverlayModule, BasicModule],
  declarations: [HintDialog, HintDialogTriggerFor],
  exports: [HintDialog, HintDialogTriggerFor],
})
export class HintDialogModule {}

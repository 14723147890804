import { Observable, EMPTY, concat } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { shareRef } from '@shared/operators/share-ref.operator';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import {
  HelpCenterItemStatus,
  HelpItemSubject,
  HelpCenterItemData,
  HelpCenterItemStatistics,
  HelpCenterLanguage,
} from '@shared/modules/help-center/help-center.models';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { Commands } from '@shared/enums/commands.enum';

@Injectable({
  providedIn: 'root',
})
export class HelpCenterService {
  constructor(private db: DatabaseWrapper, private cf: CloudFunctions) {}

  getItemStatus(subject: HelpItemSubject): Observable<HelpCenterItemStatus> {
    return this.get<HelpCenterItemStatus>(`${subject}/status`, HelpCenterItemStatus.Missing);
  }

  getItemTip(subject: HelpItemSubject, lang: HelpCenterLanguage): Observable<string> {
    return this.get<string>(`${subject}/data/${lang}/quickTip`, '');
  }

  getItemData(subject: HelpItemSubject, lang: HelpCenterLanguage): Observable<HelpCenterItemData> {
    return this.get<HelpCenterItemData>(`${subject}/data/${lang}`, {}).pipe(
      map((data) => ({
        title: data.title || '',
        article: data.article || '',
        quickTip: data.quickTip || '',
        externalLink: data.externalLink || '',
      })),
    );
  }

  getItemStatistics(subject: HelpItemSubject): Observable<Record<HelpCenterLanguage, HelpCenterItemStatistics>> {
    return this.get<Record<HelpCenterLanguage, HelpCenterItemStatistics>>(
      `${subject}/stats`,
      {} as Record<HelpCenterLanguage, HelpCenterItemStatistics>,
    );
  }

  updateItemData(
    subject: HelpItemSubject,
    data: Partial<Record<HelpCenterLanguage, Partial<HelpCenterItemData>>>,
  ): Observable<unknown> {
    const dataEntries = Object.entries(data || {});

    if (!dataEntries.length) {
      return EMPTY;
    }

    return concat(
      ...dataEntries.map(([lang, update]) =>
        this.db.object<HelpCenterItemData>(`helpcenter/${subject}/data/${lang}`).update(update),
      ),
    );
  }

  updateItemStats(
    subject: HelpItemSubject,
    property: keyof HelpCenterItemStatistics,
    language: HelpCenterLanguage,
  ): Observable<void> {
    return this.cf.post(Commands.HelpCenterIncrement, undefined, { subject, property, language });
  }

  private get<T>(path: string, fallback: T): Observable<T> {
    return this.db
      .object<T>(`/helpcenter/${path}`)
      .valueChanges()
      .pipe(
        map((value?: T) => value ?? fallback),
        distinctUntilChanged(),
        shareRef(),
      );
  }
}

/**
 * Stopwords used for text parsing.
 *
 * @unstable
 */

export class StopWords {
  static readonly us: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|about|above|after|again|against|all|am|an|and|any|are|aren't|as|at|` +
      `be|because|been|before|being|below|between|both|but|by|can't|cannot|could|couldn't|did|didn't|do|does|doesn't|` +
      `doing|don't|down|during|each|few|for|from|further|had|hadn't|has|hasn't|have|haven't|having|he|he'd|he'll|` +
      `he's|her|here|here's|hers|herself|him|himself|his|how|how's|i|i'd|i'll|i'm|i've|if|in|into|is|isn't|it|` +
      `it's|its|itself|let's|me|more|most|mustn't|my|myself|no|nor|not|of|off|on|once|only|or|other|ought|our|ours|` +
      `ourselves|out|over|own|same|shan't|she|she'd|she'll|she's|should|shouldn't|so|some|such|than|that|that's|` +
      `the|their|theirs|them|themselves|then|there|there's|these|they|they'd|they'll|they're|they've|this|those|` +
      `through|to|too|under|until|up|very|was|wasn't|we|we'd|we'll|we're|we've|were|weren't|what|what's|when|` +
      `when's|where|where's|which|while|who|who's|whom|why|why's|with|won't|would|wouldn't|you|you'd|you'll|` +
      `you're|you've|your|yours|yourself|yourselves` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly en: any = StopWords.us;

  static readonly fi: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `aiemmin|aika|aikaa|aikaan|aikaisemmin|aikaisin|aikajen|aikana|` +
      `aikoina|aikoo|aikovat|aina|ainakaan|ainakin|ainoa|ainoat|aiomme|aion|aiotte|aist|aivan|ajan|älä|alas|alemmas|` +
      `älköön|alkuisin|alkuun|alla|alle|aloitamme|aloitan|aloitat|aloitatte|aloitattivat|aloitettava|aloitettevaksi|` +
      `aloitettu|aloitimme|aloitin|aloitit|aloititte|aloittaa|aloittamatta|aloitti|aloittivat|alta|aluksi|alussa|` +
      `alusta|annettavaksi|annetteva|annettu|antaa|antamatta|antoi|aoua|apu|asia|asiaa|asian|asiasta|asiat|asioiden|` +
      `asioihin|asioita|asti|avuksi|avulla|avun|avutta|edellä|edelle|edelleen|edeltä|edemmäs|edes|edessä|edestä|` +
      `ehkä|ei|eikä|eilen|eivät|eli|ellei|elleivät|ellemme|ellen|ellet|ellette|emme|en|enää|enemmän|eniten|ennen|` +
      `ensi|ensimmäinen|ensimmäiseksi|ensimmäisen|ensimmäisenä|ensimmäiset|ensimmäisiä|ensimmäisiksi|ensimmäisinä|` +
      `ensimmäistä|ensin|entinen|entisen|entisiä|entistä|entisten|eräät|eräiden|eräs|eri|erittäin|erityisesti|esi|` +
      `esiin|esillä|esimerkiksi|et|eteen|etenkin|että|ette|ettei|halua|haluaa|haluamatta|haluamme|haluan|haluat|` +
      `haluatte|haluavat|halunnut|halusi|halusimme|halusin|halusit|halusitte|halusivat|halutessa|haluton|hän|` +
      `häneen|hänellä|hänelle|häneltä|hänen|hänessä|hänestä|hänet|he|hei|heidän|heihin|heille|heiltä|heissä|` +
      `heistä|heitä|helposti|heti|hetkellä|hieman|huolimatta|huomenna|hyvä|hyvää|hyvät|hyviä|hyvien|hyviin|` +
      `hyviksi|hyville|hyviltä|hyvin|hyvinä|hyvissä|hyvistä|ihan|ilman|ilmeisesti|itse|itseään|itsensä|ja|jää|` +
      `jälkeen|jälleen|jo|johon|joiden|joihin|joiksi|joilla|joille|joilta|joissa|joista|joita|joka|jokainen|jokin|` +
      `joko|joku|jolla|jolle|jolloin|jolta|jompikumpi|jonka|jonkin|jonne|joo|jopa|jos|joskus|jossa|josta|jota|` +
      `jotain|joten|jotenkin|jotenkuten|jotka|jotta|jouduimme|jouduin|jouduit|jouduitte|joudumme|joudun|joudutte|` +
      `joukkoon|joukossa|joukosta|joutua|joutui|joutuivat|joutumaan|joutuu|joutuvat|juuri|kahdeksan|kahdeksannen|` +
      `kahdella|kahdelle|kahdelta|kahden|kahdessa|kahdesta|kahta|kahteen|kai|kaiken|kaikille|kaikilta|kaikkea|` +
      `kaikki|kaikkia|kaikkiaan|kaikkialla|kaikkialle|kaikkialta|kaikkien|kaikkin|kaksi|kannalta|kannattaa|kanssa|` +
      `kanssaan|kanssamme|kanssani|kanssanne|kanssasi|kauan|kauemmas|kautta|kehen|keiden|keihin|keiksi|keillä|` +
      `keille|keiltä|keinä|keissä|keistä|keitä|keittä|keitten|keneen|keneksi|kenellä|kenelle|keneltä|kenen|kenenä|` +
      `kenessä|kenestä|kenet|kenettä|kennessästä|kerran|kerta|kertaa|kesken|keskimäärin|ketä|ketkä|kiitos|kohti|` +
      `koko|kokonaan|kolmas|kolme|kolmen|kolmesti|koska|koskaan|kovin|kuin|kuinka|kuitenkaan|kuitenkin|kuka|kukaan|` +
      `kukin|kumpainen|kumpainenkaan|kumpi|kumpikaan|kumpikin|kun|kuten|kuuden|kuusi|kuutta|kyllä|kymmenen|kyse|` +
      `lähekkäin|lähellä|lähelle|läheltä|lähemmäs|lähes|lähinnä|lähtien|läpi|liian|liki|lisää|lisäksi|luo|` +
      `mahdollisimman|mahdollista|me|meidän|meillä|meille|melkein|melko|menee|meneet|menemme|menen|menet|menette|` +
      `menevät|meni|menimme|menin|menit|menivät|mennessä|mennyt|menossa|mihin|mikä|mikään|mikäli|mikin|miksi|` +
      `milloin|minä|minne|minun|minut|missä|mistä|mitä|mitään|miten|moi|molemmat|mones|monesti|monet|moni|moniaalla|` +
      `moniaalle|moniaalta|monta|muassa|muiden|muita|muka|mukaan|mukaansa|mukana|mutta|muu|muualla|muualle|` +
      `muualta|muuanne|muulloin|muun|muut|muuta|muutama|muutaman|muuten|myöhemmin|myös|myöskään|myöskin|myötä|` +
      `näiden|näin|näissä|näissähin|näissälle|näissältä|näissästä|näitä|nämä|ne|neljä|neljää|neljän|niiden|niin|` +
      `niistä|niitä|noin|nopeammin|nopeasti|nopeiten|nro|nuo|nyt|ohi|oikein|ole|olemme|olen|olet|olette|oleva|olevan|` +
      `olevat|oli|olimme|olin|olisi|olisimme|olisin|olisit|olisitte|olisivat|olit|olitte|olivat|olla|olleet|olli|` +
      `ollut|oma|omaa|omaan|omaksi|omalle|omalta|oman|omassa|omat|omia|omien|omiin|omiksi|omille|omilta|omissa|` +
      `omista|on|onkin|onko|ovat|päälle|paikoittain|paitsi|pakosti|paljon|paremmin|parempi|parhaillaan|parhaiten|` +
      `peräti|perusteella|pian|pieneen|pieneksi|pienellä|pienelle|pieneltä|pienempi|pienestä|pieni|pienin|puolesta|` +
      `puolestaan|runsaasti|saakka|sadam|sama|samaa|samaan|samalla|samallalta|samallassa|samallasta|saman|samat|` +
      `samoin|sata|sataa|satojen|se|seitsemän|sekä|sen|seuraavat|siellä|sieltä|siihen|siinä|siis|siitä|sijaan|siksi|` +
      `sillä|silloin|silti|sinä|sinne|sinua|sinulle|sinulta|sinun|sinussa|sinusta|sinut|sisäkkäin|sisällä|sitä|siten|` +
      `sitten|suoraan|suuntaan|suuren|suuret|suuri|suuria|suurin|suurten|taa|täällä|täältä|taas|taemmas|tähän|` +
      `tahansa|tai|takaa|takaisin|takana|takia|tällä|tällöin|tämä|tämän|tänä|tänään|tänne|tapauksessa|tässä|tästä|` +
      `tätä|täten|tavalla|tavoitteena|täysin|täytyvät|täytyy|te|tietysti|todella|toinen|toisaalla|toisaalle|` +
      `toisaalta|toiseen|toiseksi|toisella|toiselle|toiselta|toisemme|toisen|toisensa|toisessa|toisesta|toista|` +
      `toistaiseksi|toki|tosin|tuhannen|tuhat|tule|tulee|tulemme|tulen|tulet|tulette|tulevat|tulimme|tulin|tulisi|` +
      `tulisimme|tulisin|tulisit|tulisitte|tulisivat|tulit|tulitte|tulivat|tulla|tulleet|tullut|tuntuu|tuo|tuolla|` +
      `tuolloin|tuolta|tuonne|tuskin|tykö|usea|useasti|useimmiten|usein|useita|uudeksi|uudelleen|uuden|uudet|uusi|` +
      `uusia|uusien|uusinta|uuteen|uutta|vaan|vähän|vähemmän|vähintään|vähiten|vai|vaiheessa|vaikea|vaikean|vaikeat|` +
      `vaikeilla|vaikeille|vaikeilta|vaikeissa|vaikeista|vaikka|vain|välillä|varmasti|varsin|varsinkin|varten|` +
      `vasta|vastaan|vastakkain|verran|vielä|vierekkäin|vieri|viiden|viime|viimeinen|viimeisen|viimeksi|viisi|` +
      `voi|voidaan|voimme|voin|voisi|voit|voitte|voivat|vuoden|vuoksi|vuosi|vuosien|vuosina|vuotta|yhä|yhdeksän|` +
      `yhden|yhdessä|yhtä|yhtäällä|yhtäälle|yhtäältä|yhtään|yhteen|yhteensä|yhteydessä|yhteyteen|yksi|yksin|` +
      `yksittäin|yleensä|ylemmäs|yli|ylös|ympäri` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly de: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `aber|als|am|an|auch|auf|aus|bei|bin|bis|bist|da|dadurch|daher|darum|` +
      `das|daß|dass|dein|deine|dem|den|der|des|dessen|deshalb|die|dies|dieser|dieses|doch|dort|du|durch|ein|` +
      `eine|einem|einen|einer|eines|er|es|euer|eure|für|hatte|hatten|hattest|hattet|hier|hinter|ich|ihr|ihre|` +
      `im|in|ist|ja|jede|jedem|jeden|jeder|jedes|jener|jenes|jetzt|kann|kannst|können|könnt|machen|mein|meine|` +
      `mit|muß|mußt|musst|müssen|müßt|nach|nachdem|nein|nicht|nun|oder|seid|sein|seine|sich|sie|sind|soll|sollen|` +
      `sollst|sollt|sonst|soweit|sowie|und|unser|unsere|unter|vom|von|vor|wann|warum|was|weiter|weitere|wenn|` +
      `wer|werde|werden|werdet|weshalb|wie|wieder|wieso|wir|wird|wirst|wo|woher|wohin|zu|zum|zur|über` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly fr: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `alors|au|aucuns|aussi|autre|avant|avec|avoir|bon|car|ce|cela|ces|ceux|` +
      `chaque|ci|comme|comment|dans|des|du|dedans|dehors|depuis|devrait|doit|donc|dos|début|elle|elles|en|encore|` +
      `essai|est|et|eu|fait|faites|fois|font|hors|ici|il|ils|je|juste|la|le|les|leur|là|ma|maintenant|mais|mes|` +
      `mine|moins|mon|mot|même|ni|nommés|notre|nous|ou|où|par|parce|pas|peut|peu|plupart|pour|pourquoi|quand|que|` +
      `quel|quelle|quelles|quels|qui|sa|sans|ses|seulement|si|sien|son|sont|sous|soyez|sujet|sur|ta|tandis|tellement|` +
      `tels|tes|ton|tous|tout|trop|très|tu|voient|vont|votre|vous|vu|ça|étaient|état|étions|été|être` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly es: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `un|una|unas|unos|uno|sobre|todo|también|tras|otro|algún|alguno|alguna|` +
      `algunos|algunas|ser|es|soy|eres|somos|sois|estoy|esta|estamos|estais|estan|como|en|para|atras|porque|por qué|` +
      `estado|estaba|ante|antes|siendo|ambos|pero|por|poder|puede|puedo|podemos|podeis|pueden|fui|fue|fuimos|fueron|` +
      `hacer|hago|hace|hacemos|haceis|hacen|cada|fin|incluso|primero|desde|conseguir|consigo|consigue|consigues|` +
      `conseguimos|consiguen|ir|voy|va|vamos|vais|van|vaya|gueno|ha|tener|tengo|tiene|tenemos|teneis|tienen|el|la|` +
      `lo|las|los|su|aqui|mio|tuyo|ellos|ellas|nos|nosotros|vosotros|vosotras|si|dentro|solo|solamente|saber|sabes|` +
      `sabe|sabemos|sabeis|saben|ultimo|largo|bastante|haces|muchos|aquellos|aquellas|sus|entonces|tiempo|verdad|` +
      `verdadero|verdadera|cierto|ciertos|cierta|ciertas|intentar|intento|intenta|intentas|intentamos|intentais|` +
      `intentan|dos|bajo|arriba|encima|usar|uso|usas|usa|usamos|usais|usan|emplear|empleo|empleas|emplean|` +
      `ampleamos|empleais|valor|muy|era|eras|eramos|eran|modo|bien|cual|cuando|donde|mientras|quien|con|entre|` +
      `sin|trabajo|trabajar|trabajas|trabaja|trabajamos|trabajais|trabajan|podria|podrias|podriamos|podrian|podriais|` +
      `yo|aquel` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly hy: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `այդ|այլ|այն|այս|դու|դուք|եմ|են|ենք|ես|եք|է|էի|էին|էինք|էիր|էիք|էր|` +
      `ըստ|թ|ի|ին|իսկ|իր|կամ|համար|հետ|հետո|մենք|մեջ|մի|ն|նա|նաև|նրա|նրանք|որ|որը|որոնք|որպես|ու|ում|` +
      `պիտի|վրա|և` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly eu: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `al|anitz|arabera|asko|baina|bat|batean|batek|bati|batzuei|batzuek|` +
      `batzuetan|batzuk|bera|beraiek|berau|berauek|bere|berori|beroriek|beste|bezala|da|dago|dira|ditu|du|dute|edo|` +
      `egin|ere|eta|eurak|ez|gainera|gu|gutxi|guzti|haiei|haiek|haietan|hainbeste|hala|han|handik|hango|hara|hari|` +
      `hark|hartan|hau|hauei|hauek|hauetan|hemen|hemendik|hemengo|hi|hona|honek|honela|honetan|honi|hor|hori|horiei|` +
      `horiek|horietan|horko|horra|horrek|horrela|horretan|horri|hortik|hura|izan|ni|noiz|nola|non|nondik|nongo|nor|` +
      `nora|ze|zein|zen|zenbait|zenbat|zer|zergatik|ziren|zituen|zu|zuek|zuen|zuten` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly bn: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `অবশ্য|অনেক|অনেকে|অনেকেই|অন্তত|অথবা|অথচ|অর্থাত|অন্য|আজ|আছে|আপনার|আপনি|` +
      `আবার|আমরা|আমাকে|আমাদের|আমার|আমি|আরও|আর|আগে|আগেই|আই|অতএব|আগামী|অবধি|অনুযায়ী|আদ্যভাগে|এই|একই|একে|একটি|এখন|` +
      `এখনও|এখানে|এখানেই|এটি|এটা|এটাই|এতটাই|এবং|একবার|এবার|এদের|এঁদের|এমন|এমনকী|এল|এর|এরা|এঁরা|এস|এত|এতে|এসে|একে|এ|ঐ|` +
      ` ই|ইহা|ইত্যাদি|উনি|উপর|উপরে|উচিত|ও|ওই|ওর|ওরা|ওঁর|ওঁরা|ওকে|ওদের|ওঁদের|ওখানে|কত|কবে|করতে|কয়েক|কয়েকটি|করবে|` +
      `করলেন |করার|কারও|করা |করি|করিয়ে|করার|করাই|করলে|করলেন|করিতে |করিয়া|করেছিলেন|করছে|করছেন|করেছেন|করেছে |করেন|` +
      `করবেন|করায়|করে|করেই|কাছ|কাছে|কাজে|কারণ|কিছু|কিছুই |কিন্তু|কিংবা|কি|কী |কেউ |কেউই|কাউকে|কেন |কে |কোনও |কোনো|` +
      `কোন |কখনও|ক্ষেত্রে|খুব|গুলি|গিয়ে|গিয়েছে|গেছে |গেল|গেলে|গোটা|চলে|ছাড়া|ছাড়াও|ছিলেন |ছিল|জন্য|জানা |ঠিক|তিনি|` +
      `তিনঐ|তিনিও|তখন |তবে |তবু|তাঁদের |তাঁাহারা|তাঁরা |তাঁর|তাঁকে|তাই|তেমন|তাকে |তাহা|তাহাতে|তাহার|তাদের |তারপর |` +
      `তারা |তারৈ|তার|তাহলে|তিনি|তা|তাও|তাতে|তো |তত|তুমি |তোমার |তথা|থাকে|থাকা |থাকায়|থেকে|থেকেও|থাকবে|থাকেন|থাকবেন|` +
      `থেকেই |দিকে |দিতে |দিয়ে|দিয়েছে|দিয়েছেন|দিলেন|দু|দুটি|দুটো|দেয়|দেওয়া|দেওয়ার|দেখা |দেখে|দেখতে|দ্বারা |ধরে|ধরা|` +
      `নয় |নানা |না|নাকি|নাগাদ|নিতে|নিজে|নিজেই|নিজের|নিজেদের|নিয়ে|নেওয়া|নেওয়ার|নেই|নাই|পক্ষে|পর্যন্ত|পাওয়া |পারেন |` +
      `পারি|পারে|পরে|পরেই|পরেও|পর|পেয়ে |প্রতি |প্রভৃতি |প্রায়|ফের|ফলে |ফিরে|ব্যবহার |বলতে |বললেন|বলেছেন|বলল |বলা |` +
      `বলেন |বলে |বহু|বসে|বার|বা|বিনা|বরং|বদলে|বাদে|বার|বিশেষ|বিভিন্ন|বিষয়টি |ব্যবহার|ব্যাপারে|ভাবে|ভাবেই|মধ্যে|` +
      `মধ্যেই|মধ্যেও|মধ্যভাগে|মাধ্যমে|মাত্র |মতো|মতোই|মোটেই|যখন |যদি |যদিও|যাবে |যায়|যাকে|যাওয়া|যাওয়ার|যত|যতটা|যা|` +
      `যযার|যারা|যাঁর|াঁরা|যাদের|যান|যাচ্ছে |যেতে|যাতে |যেন |যেমন |যেখানে|যিনি|যে|রেখে|রাখা|রয়েছে|রকম|শুধু |সঙ্গে|` +
      `সঙ্গেও|সমস্ত|সব|সবার|সহ |সুতরাং |সহিত|সেই|সেটা|সেটি|সেটাই|সেটাও|সম্প্রতি|সেখান|সেখানে |সে|স্পষ্ট |স্বয়ং|হইতে |` +
      `হইবে |হৈলে|হইয়া |হচ্ছে |হত|হতে |হতেই|হবে |হবেন|হয়েছিল |হয়েছে|হয়েছেন|হয়ে|হয়নি|হয়|হয়েই|হয়তো|হল|হলে|হলেই|হলেও|` +
      `হলো|হিসাবে|হওয়া|হওয়ার|হওয়ায়|হন|হোক|জন|জনকে|জনের|জানতে|জানায়|জানিয়ে|জানানো|জানিয়েছে|জন্য|জন্যওজে|জে|বেশ|দেন|` +
      `চতুলে|ছিলেন|ান|চায়|চেয়ে|মোট|যথেষ্ট|টি` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ptBR: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|ainda|alem|ambas|ambos|antes|ao|aonde|aos|apos|aquele|aqueles|as|` +
      `assim|com|como|contra|contudo|cuja|cujas|cujo|cujos|da|das|de|dela|dele|deles|demais|depois|desde|desta|deste|` +
      `dispoe|dispoem|diversa|diversas|diversos|do|dos|durante|e|ela|elas|ele|eles|em|entao|entre|essa|essas|esse|` +
      `esses|esta|estas|este|estes|ha|isso|isto|logo|mais|mas|mediante|menos|mesma|mesmas|mesmo|mesmos|na|nas|nao|` +
      `nas|nem|nesse|neste|nos|o|os|ou|outra|outras|outro|outros|pelas|pelas|pelo|pelos|perante|pois|por|porque|` +
      `portanto|proprio|propios|quais|qual|qualquer|quando|quanto|que|quem|quer|se|seja|sem|sendo|seu|seus|sob|` +
      `sobre|sua|suas|tal|tambem|teu|teus|toda|todas|todo|todos|tua|tuas|tudo|um|uma|umas|uns` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly bg: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `а|автентичен|аз|ако|ала|бе|без|беше|би|бивш|бивша|бившо|бил|била|били|` +
      `било|благодаря|близо|бъдат|бъде|бяха|в|вас|ваш|ваша|вероятно|вече|взема|ви|вие|винаги|внимава|време|все|всеки|` +
      `всички|всичко|всяка|във|въпреки|върху|г|ги|главен|главна|главно|глас|го|година|години|годишен|д|да|дали|два|` +
      `двама|двамата|две|двете|ден|днес|дни|до|добра|добре|добро|добър|докато|докога|дори|досега|доста|друг|друга|` +
      `други|е|евтин|едва|един|една|еднаква|еднакви|еднакъв|едно|екип|ето|живот|за|забавям|зад|заедно|заради|засега|` +
      `заспал|затова|защо|защото|и|из|или|им|има|имат|иска|й|каза|как|каква|какво|както|какъв|като|кога|когато|което|` +
      `които|кой|който|колко|която|къде|където|към|лесен|лесно|ли|лош|м|май|малко|ме|между|мек|мен|месец|ми|много|` +
      `мнозина|мога|могат|може|мокър|моля|момента|му|н|на|над|назад|най|направи|напред|например|нас|не|него|нещо|нея|` +
      `ни|ние|никой|нито|нищо|но|нов|нова|нови|новина|някои|някой|няколко|няма|обаче|около|освен|особено|от|отгоре|` +
      `отново|още|пак|по|повече|повечето|под|поне|поради|после|почти|прави|пред|преди|през|при|пък|първата|първи|` +
      `първо|пъти|равен|равна|с|са|сам|само|се|сега|си|син|скоро|след|следващ|сме|смях|според|сред|срещу|сте|съм|със|` +
      `също|т|тази|така|такива|такъв|там|твой|те|тези|ти|т.н.|то|това|тогава|този|той|толкова|точно|три|трябва|тук|` +
      `тъй|тя|тях|у|утре|харесва|хиляди|ч|часа|че|често|чрез|ще|щом|юмрук|я|як` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ca: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `de|es|i|a|o|un|una|unes|uns|un|tot|també|altre|algun|alguna|alguns|algunes|` +
      `ser|és|soc|ets|som|estic|està|estem|esteu|estan|com|en|per|perquè|per que|estat|estava|ans|abans|éssent|` +
      `ambdós|però|per|poder|potser|puc|podem|podeu|poden|vaig|va|van|fer|faig|fa|fem|feu|fan|cada|fi|inclòs|primer|` +
      `des de|conseguir|consegueixo|consigueix|consigueixes|conseguim|consigueixen|anar|haver|tenir|tinc|te|tenim|` +
      `teniu|tene|el|la|les|els|seu|aquí|meu|teu|ells|elles|ens|nosaltres|vosaltres|si|dins|sols|solament|saber|saps|` +
      `sap|sabem|sabeu|saben|últim|llarg|bastant|fas|molts|aquells|aquelles|seus|llavors|sota|dalt|ús|molt|era|eres|` +
      `erem|eren|mode|bé|quant|quan|on|mentre|qui|amb|entre|sense|jo|aquell` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly zh: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `的|一|不|在|人|有|是|为|以|于|上|他|而|后|之|来|及|了|因|下|可|到|由|这|与|也|此|但|并|个|其|已|无|小|我|` +
      `们|起|最|再|今|去|好|只|又|或|很|亦|某|把|那|你|乃|它|吧|被|比|别|趁|当|从|到|得|打|凡|儿|尔|该|各|给|跟|和|何|还|即|几|既|` +
      `看|据|距|靠|啦|了|另|么|每|们|嘛|拿|哪|那|您|凭|且|却|让|仍|啥|如|若|使|谁|虽|随|同|所|她|哇|嗡|往|哪|些|向|沿|哟|用|于|咱|` +
      `则|怎|曾|至|致|着|诸|自` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly hr: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|ako|ali|bi|bih|bila|bili|bilo|bio|bismo|biste|biti|bumo|da|do|duž|ga|` +
      `hoće|hoćemo|hoćete|hoćeš|hoću|i|iako|ih|ili|iz|ja|je|jedna|jedne|jedno|jer|jesam|jesi|jesmo|jest|jeste|jesu|` +
      `jim|joj|još|ju|kada|kako|kao|koja|koje|koji|kojima|koju|kroz|li|me|mene|meni|mi|mimo|moj|moja|moje|mu|na|nad|` +
      `nakon|nam|nama|nas|naš|naša|naše|našeg|ne|nego|neka|neki|nekog|neku|nema|netko|neće|nećemo|nećete|nećeš|neću|` +
      `nešto|ni|nije|nikoga|nikoje|nikoju|nisam|nisi|nismo|niste|nisu|njega|njegov|njegova|njegovo|njemu|njezin|` +
      `njezina|njezino|njih|njihov|njihova|njihovo|njim|njima|njoj|nju|no|o|od|odmah|on|ona|oni|ono|ova|pa|pak|po|` +
      `pod|pored|prije|s|sa|sam|samo|se|sebe|sebi|si|smo|ste|su|sve|svi|svog|svoj|svoja|svoje|svom|ta|tada|taj|tako|` +
      `te|tebe|tebi|ti|to|toj|tome|tu|tvoj|tvoja|tvoje|u|uz|vam|vama|vas|vaš|vaša|vaše|već|vi|vrlo|za|zar|će|ćemo|` +
      `ćete|ćeš|ću|što` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly cs: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `dnes|cz|timto|budes|budem|byli|jses|muj|svym|ta|tomto|tohle|tuto|tyto|` +
      `jej|zda|proc|mate|tato|kam|tohoto|kdo|kteri|mi|nam|tom|tomuto|mit|nic|proto|kterou|byla|toho|protoze|asi|ho|` +
      `nasi|napiste|re|coz|tim|takze|svych|jeji|svymi|jste|aj|tu|tedy|teto|bylo|kde|ke|prave|ji|nad|nejsou|ci|pod|` +
      `tema|mezi|pres|ty|pak|vam|ani|kdyz|vsak|ne|jsem|tento|clanku|clanky|aby|jsme|pred|pta|jejich|byl|jeste|az|bez|` +
      `take|pouze|prvni|vase|ktera|nas|novy|tipy|pokud|muze|design|strana|jeho|sve|jine|zpravy|nove|neni|vas|jen|` +
      `podle|zde|clanek|uz|email|byt|vice|bude|jiz|nez|ktery|by|ktere|co|nebo|ten|tak|ma|pri|od|po|jsou|jak|dalsi|` +
      `ale|si|ve|to|jako|za|zpet|ze|do|pro|je|na` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly da: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `af|alle|andet|andre|at|begge|da|de|den|denne|der|deres|det|dette|dig|din|dog|` +
      `du|ej|eller|en|end|ene|eneste|enhver|et|fem|fire|flere|fleste|for|fordi|forrige|fra|få|før|god|han|hans|har|` +
      `hendes|her|hun|hvad|hvem|hver|hvilken|hvis|hvor|hvordan|hvorfor|hvornår|i|ikke|ind|ingen|intet|jeg|jeres|kan|` +
      `kom|kommer|lav|lidt|lille|man|mand|mange|med|meget|men|mens|mere|mig|ned|ni|nogen|noget|ny|nyt|nær|næste|` +
      `næsten|og|op|otte|over|på|se|seks|ses|som|stor|store|syv|ti|til|to|tre|ud|var` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly nl: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `aan|af|al|als|bij|dan|dat|die|dit|een|en|er|had|heb|hem|` +
      `het|hij|hoe|hun|ik|in|is|je|kan|me|men|met|mij|nog|nu|of|ons|ook|te|tot|uit|van|was|wat|we|wel|` +
      `wij|zal|ze|zei|zij|zo|zou` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly gl: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|aínda|alí|aquel|aquela|aquelas|aqueles|aquilo|aquí|ao|aos|as|así|á|ben|` +
      `cando|che|co|coa|comigo|con|connosco|contigo|convosco|coas|cos|cun|cuns|cunha|cunhas|da|dalgunha|dalgunhas|` +
      `dalgún|dalgúns|das|de|del|dela|delas|deles|desde|deste|do|dos|dun|duns|dunha|dunhas|e|el|ela|elas|eles|en|era|` +
      `eran|esa|esas|ese|eses|esta|estar|estaba|está|están|este|estes|estiven|estou|eu|é|facer|foi|foron|fun|había|` +
      `hai|iso|isto|la|las|lle|lles|lo|los|mais|me|meu|meus|min|miña|miñas|moi|na|nas|neste|nin|no|non|nos|nosa|` +
      `nosas|noso|nosos|nós|nun|nunha|nuns|nunhas|o|os|ou|ó|ós|para|pero|pode|pois|pola|polas|polo|polos|por|que|se|` +
      `senón|ser|seu|seus|sexa|sido|sobre|súa|súas|tamén|tan|te|ten|teñen|teño|ter|teu|teus|ti|tido|tiña|tiven|túa|` +
      `túas|un|unha|unhas|uns|vos|vosa|vosas|voso|vosos|vós` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly el: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `μή|ἑαυτοῦ|ἄν|ἀλλ’|ἀλλά|ἄλλοσ|ἀπό|ἄρα|αὐτόσ|δ’|δέ|δή|διά|δαί|δαίσ|ἔτι|ἐγώ|` +
      `ἐκ|ἐμόσ|ἐν|ἐπί|εἰ|εἰμί|εἴμι|εἰσ|γάρ|γε|γα^|ἡ|ἤ|καί|κατά|μέν|μετά|μή|ὁ|ὅδε|ὅσ|ὅστισ|ὅτι|οὕτωσ|οὗτοσ|οὔτε|οὖν|` +
      `οὐδείσ|οἱ|οὐ|οὐδέ|οὐκ|περί|πρόσ|σύ|σύν|τά|τε|τήν|τῆσ|τῇ|τι|τί|τισ|τίσ|τό|τοί|τοιοῦτοσ|τόν|τούσ|τοῦ|τῶν|τῷ|` +
      `ὑμόσ|ὑπέρ|ὑπό|ὡσ|ὦ|ὥστε|ἐάν|παρά|σόσ` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly hi: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `के|का|एक|में|की|है|यह|और|से|हैं|को|पर|इस|होता|कि|जो|कर|मे|गया|करने|किया|लिये|` +
      `अपने|ने|बनी|नहीं|तो|ही|या|एवं|दिया|हो|इसका|था|द्वारा|हुआ|तक|साथ|करना|वाले|बाद|लिए|आप|कुछ|सकते|किसी|ये|इसके|` +
      `सबसे|इसमें|थे|दो|होने|वह|वे|करते|बहुत|कहा|वर्ग|कई|करें|होती|अपनी|उनके|थी|यदि|हुई|जा|ना|इसे|कहते|जब|होते|कोई|` +
      `हुए|व|न|अभी|जैसे|सभी|करता|उनकी|तरह|उस|आदि|कुल|एस|रहा|इसकी|सकता|रहे|उनका|इसी|रखें|अपना|पे|उसके` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly hu: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|az|egy|be|ki|le|fel|meg|el|át|rá|ide|oda|szét|össze|vissza|de|hát|és|` +
      `vagy|hogy|van|lesz|volt|csak|nem|igen|mint|én|te|õ|mi|ti|õk|ön` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly id: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `ada|adanya|adalah|adapun|agak|agaknya|agar|akan|akankah|akhirnya|aku|` +
      `akulah|amat|amatlah|anda|andalah|antar|diantaranya|antara|antaranya|diantara|apa|apaan|mengapa|apabila|apakah|` +
      `apalagi|apatah|atau|ataukah|ataupun|bagai|bagaikan|sebagai|sebagainya|bagaimana|bagaimanapun|sebagaimana|` +
      `bagaimanakah|bagi|bahkan|bahwa|bahwasanya|sebaliknya|banyak|sebanyak|beberapa|seberapa|begini|beginian|` +
      `beginikah|beginilah|sebegini|begitu|begitukah|begitulah|begitupun|sebegitu|belum|belumlah|sebelum|sebelumnya|` +
      `sebenarnya|berapa|berapakah|berapalah|berapapun|betulkah|sebetulnya|biasa|biasanya|bila|bilakah|bisa|bisakah|` +
      `sebisanya|boleh|bolehkah|bolehlah|buat|bukan|bukankah|bukanlah|bukannya|cuma|percuma|dahulu|dalam|dan|dapat|` +
      `dari|daripada|dekat|demi|demikian|demikianlah|sedemikian|dengan|depan|di|dia|dialah|dini|diri|dirinya|terdiri|` +
      `dong|dulu|enggak|enggaknya|entah|entahlah|terhadap|terhadapnya|hal|hampir|hanya|hanyalah|harus|haruslah|` +
      `harusnya|seharusnya|hendak|hendaklah|hendaknya|hingga|sehingga|ia|ialah|ibarat|ingin|inginkah|inginkan|ini|` +
      `inikah|inilah|itu|itukah|itulah|jangan|jangankan|janganlah|jika|jikalau|juga|justru|kala|kalau|kalaulah|` +
      `kalaupun|kalian|kami|kamilah|kamu|kamulah|kan|kapan|kapankah|kapanpun|dikarenakan|karena|karenanya|ke|kecil|` +
      `kemudian|kenapa|kepada|kepadanya|ketika|seketika|khususnya|kini|kinilah|kiranya|sekiranya|kita|kitalah|kok|` +
      `lagi|lagian|selagi|lah|lain|lainnya|melainkan|selaku|lalu|melalui|terlalu|lama|lamanya|selama|selama|` +
      `selamanya|lebih|terlebih|bermacam|macam|semacam|maka|makanya|makin|malah|malahan|mampu|mampukah|mana|manakala|` +
      `manalagi|masih|masihkah|semasih|masing|mau|maupun|semaunya|memang|mereka|merekalah|meski|meskipun|semula|` +
      `mungkin|mungkinkah|nah|namun|nanti|nantinya|nyaris|oleh|olehnya|seorang|seseorang|pada|padanya|padahal|paling|` +
      `sepanjang|pantas|sepantasnya|sepantasnyalah|para|pasti|pastilah|per|pernah|pula|pun|merupakan|rupanya|serupa|` +
      `saat|saatnya|sesaat|saja|sajalah|saling|bersama|sama|sesama|sambil|sampai|sana|sangat|sangatlah|saya|sayalah|` +
      `se|sebab|sebabnya|sebuah|tersebut|tersebutlah|sedang|sedangkan|sedikit|sedikitnya|segala|segalanya|segera|` +
      `sesegera|sejak|sejenak|sekali|sekalian|sekalipun|sesekali|sekaligus|sekarang|sekarang|sekitar|sekitarnya|sela|` +
      `selain|selalu|seluruh|seluruhnya|semakin|sementara|sempat|semua|semuanya|sendiri|sendirinya|seolah|seperti|` +
      `sepertinya|sering|seringnya|serta|siapa|siapakah|siapapun|disini|disinilah|sini|sinilah|sesuatu|sesuatunya|` +
      `suatu|sesudah|sesudahnya|sudah|sudahkah|sudahlah|supaya|tadi|tadinya|tak|tanpa|setelah|telah|tentang|tentu|` +
      `tentulah|tentunya|tertentu|seterusnya|tapi|tetapi|setiap|tiap|setidaknya|tidak|tidakkah|tidaklah|toh|waduh|` +
      `wah|wahai|sewaktu|walau|walaupun|wong|yaitu|yakni|yang` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ga: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|ach|ag|agus|an|aon|ar|arna|as|b'|ba|beirt|bhúr|caoga|ceathair|ceathrar|` +
      `chomh|chtó|chuig|chun|cois|céad|cúig|cúigear|d'|daichead|dar|de|deich|deichniúr|den|dhá|do|don|dtí|dá|dár|dó|` +
      `faoi|faoin|faoina|faoinár|fara|fiche|gach|gan|go|gur|haon|hocht|i|iad|idir|in|ina|ins|inár|is|le|leis|lena|` +
      `lenár|m'|mar|mo|mé|na|nach|naoi|naonúr|ná|ní|níor|nó|nócha|ocht|ochtar|os|roimh|sa|seacht|seachtar|seachtó|` +
      `seasca|seisear|siad|sibh|sinn|sna|sé|sí|tar|thar|thú|triúr|trí|trína|trínár|tríocha|tú|um|ár|é|éis|í|ó|ón|óna|` +
      `ónár` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly it: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|adesso|ai|al|alla|allo|allora|altre|altri|altro|anche|ancora|avere|aveva|avevano|` +
      `ben|buono|che|chi|cinque|comprare|con|consecutivi|consecutivo|cosa|cui|da|del|della|dello|dentro|deve|` +
      `devo|di|doppio|due|e|ecco|fare|fine|fino|fra|gente|giu|ha|hai|hanno|ho|il|indietro|invece|io|la|lavoro|le|` +
      `lei|lo|loro|lui|lungo|ma|me|meglio|molta|molti|molto|nei|nella|no|noi|nome|nostro|nove|nuovi|nuovo|o|oltre|` +
      `ora|otto|peggio|pero|persone|piu|poco|primo|promesso|qua|quarto|quasi|quattro|quello|questo|qui|quindi|quinto|` +
      `rispetto|sara|secondo|sei|sembra|sembrava|senza|sette|sia|siamo|siete|solo|sono|sopra|soprattutto|sotto|stati|` +
      `stato|stesso|su|subito|sul|sulla|tanto|te|tempo|terzo|tra|tre|triplo|ultimo|un|una|uno|va|vai|` +
      `voi|volte|vostro` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ja: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `これ|それ|あれ|この|その|あの|ここ|そこ|あそこ|こちら|どこ|だれ|なに|なん|何|私|貴方|貴方方|我々|` +
      `私達|あの人|あのかた|彼女|彼|です|あります|おります|います|は|が|の|に|を|で|え|から|まで|より|も|どの|と|し|それで|しかし` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ko: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `아|휴|아이구|아이쿠|아이고|어|나|우리|저희|따라|의해|을|를|에|의|가|으로|로|에게|뿐이다|의거하여|` +
      `근거하여|입각하여|기준으로|예하면|예를 들면|예를 들자면|저|소인|소생|저희|지말고|하지마|하지마라|다른|물론|또한|그리고|비길수 없다|해서는 안된다|` +
      `뿐만 아니라|만이 아니다|만은 아니다|막론하고|관계없이|그치지 않다|그러나|그런데|하지만|든간에|논하지 않다|따지지 않다|설사|비록|더라도|아니면|` +
      `만 못하다|하는 편이 낫다|불문하고|향하여|향해서|향하다|쪽으로|틈타|이용하여|타다|오르다|제외하고|이 외에|이 밖에|하여야|비로소|한다면 몰라도|외에도|` +
      `이곳|여기|부터|기점으로|따라서|할 생각이다|하려고하다|이리하여|그리하여|그렇게 함으로써|하지만|일때|할때|앞에서|중에서|보는데서|으로써|로써|까지|해야한다|` +
      `일것이다|반드시|할줄알다|할수있다|할수있어|임에 틀림없다|한다면|등|등등|제|겨우|단지|다만|할뿐|딩동|댕그|대해서|대하여|대하면|훨씬|얼마나|얼마만큼|얼마큼|` +
      `남짓|여|얼마간|약간|다소|좀|조금|다수|몇|얼마|지만|하물며|또한|그러나|그렇지만|하지만|이외에도|대해 말하자면|뿐이다|다음에|반대로|반대로 말하자면|` +
      `이와 반대로|바꾸어서 말하면|바꾸어서 한다면|만약|그렇지않으면|까악|툭|딱|삐걱거리다|보드득|비걱거리다|꽈당|응당|해야한다|에 가서|각|각각|여러분|각종|` +
      `각자|제각기|하도록하다|와|과|그러므로|그래서|고로|한 까닭에|하기 때문에|거니와|이지만|대하여|관하여|관한|과연|실로|아니나다를가|생각한대로|진짜로|` +
      `한적이있다|하곤하였다|하|하하|허허|아하|거바|와|오|왜|어째서|무엇때문에|어찌|하겠는가|무슨|어디|어느곳|더군다나|하물며|더욱이는|어느때|언제|야|이봐|` +
      `어이|여보시오|흐흐|흥|휴|헉헉|헐떡헐떡|영차|여차|어기여차|끙끙|아야|앗|아야|콸콸|졸졸|좍좍|뚝뚝|주룩주룩|솨|우르르|그래도|또|그리고|바꾸어말하면|` +
      `바꾸어말하자면|혹은|혹시|답다|및|그에 따르는|때가 되어|즉|지든지|설령|가령|하더라도|할지라도|일지라도|지든지|몇|거의|하마터면|인젠|이젠|된바에야|` +
      `된이상|만큼|어찌됏든|그위에|게다가|점에서 보아|비추어 보아|고려하면|하게될것이다|일것이다|비교적|좀|보다더|비하면|시키다|하게하다|할만하다|의해서|연이서|` +
      `이어서|잇따라|뒤따라|뒤이어|결국|의지하여|기대여|통하여|자마자|더욱더|불구하고|얼마든지|마음대로|주저하지 않고|곧|즉시|바로|당장|하자마자|밖에 안된다|` +
      `하면된다|그래|그렇지|요컨대|다시 말하자면|바꿔 말하면|즉|구체적으로|말하자면|시작하여|시초에|이상|허|헉|허걱|바와같이|해도좋다|해도된다|게다가|더구나|` +
      `하물며|와르르|팍|퍽|펄렁|동안|이래|하고있었다|이었다|에서|로부터|까지|예하면|했어요|해요|함께|같이|더불어|마저|마저도|양자|모두|습니다|가까스로|하려고하다|` +
      `즈음하여|다른|다른 방면으로|해봐요|습니까|했어요|말할것도 없고|무릎쓰고|개의치않고|하는것만 못하다|하는것이 낫다|매|매번|들|모|어느것|어느|로써|` +
      `갖고말하자면|어디|어느쪽|어느것|어느해|어느 년도|라 해도|언젠가|어떤것|어느것|저기|저쪽|저것|그때|그럼|그러면|요만한걸|그래|그때|저것만큼|그저|` +
      `이르기까지|할 줄 안다|할 힘이 있다|너|너희|당신|어찌|설마|차라리|할지언정|할지라도|할망정|할지언정|구토하다|게우다|토하다|메쓰겁다|옆사람|퉤|쳇|의거하여|` +
      `근거하여|의해|따라|힘입어|그|다음|버금|두번째로|기타|첫번째로|나머지는|그중에서|견지에서|형식으로 쓰여|입장에서|위해서|단지|의해되다|하도록시키다|` +
      `뿐만아니라|반대로|전후|전자|앞의것|잠시|잠깐|하면서|그렇지만|다음에|그러한즉|그런즉|남들|아무거나|어찌하든지|같다|비슷하다|예컨대|이럴정도로|어떻게|` +
      `만약|만일|위에서 서술한바와같이|인 듯하다|하지 않는다면|만약에|무엇|무슨|어느|어떤|아래윗|조차|한데|그럼에도 불구하고|여전히|심지어|까지도|조차도|` +
      `하지 않도록|않기 위하여|때|시각|무렵|시간|동안|어때|어떠한|하여금|네|예|우선|누구|누가 알겠는가|아무도|줄은모른다|줄은 몰랏다|하는 김에|겸사겸사|` +
      `하는바|그런 까닭에|한 이유는|그러니|그러니까|때문에|그|너희|그들|너희들|타인|것|것들|너|위하여|공동으로|동시에|하기 위하여|어찌하여|무엇때문에|붕붕|` +
      `윙윙|나|우리|엉엉|휘익|윙윙|오호|아하|어쨋든|만 못하다|하기보다는|차라리|하는 편이 낫다|흐흐|놀라다|상대적으로 말하자면|마치|아니라면|쉿|그렇지 않으면|` +
      `그렇지 않다면|안 그러면|아니었다면|하든지|아니면|이라면|좋아|알았어|하는것도|그만이다|어쩔수 없다|하나|일|일반적으로|일단|한켠으로는|오자마자|이렇게되면|` +
      `이와같다면|전부|한마디|한항목|근거로|하기에|아울러|하지 않도록|않기 위해서|이르기까지|이 되다|로 인하여|까닭으로|이유만으로|이로 인하여|그래서|이 때문에|` +
      `그러므로|그런 까닭에|알 수 있다|결론을 낼 수 있다|으로 인하여|있다|어떤것|관계가 있다|관련이 있다|연관되다|어떤것들|에 대해|이리하여|그리하여|여부|` +
      `하기보다는|하느니|하면 할수록|운운|이러이러하다|하구나|하도다|다시말하면|다음으로|에 있다|에 달려 있다|우리|우리들|오히려|하기는한데|어떻게|어떻해|` +
      `어찌됏어|어때|어째서|본대로|자|이|이쪽|여기|이것|이번|이렇게말하자면|이런|이러한|이와 같은|요만큼|요만한 것|얼마 안 되는 것|이만큼|이 정도의|` +
      `이렇게 많은 것|이와 같다|이때|이렇구나|것과 같이|끼익|삐걱|따위|와 같은 사람들|부류의 사람들|왜냐하면|중의하나|오직|오로지|에 한하다|하기만 하면|` +
      `도착하다|까지 미치다|도달하다|정도에 이르다|할 지경이다|결과에 이르다|관해서는|여러분|하고 있다|한 후|혼자|자기|자기집|자신|우에 종합한것과같이|` +
      `총적으로 보면|총적으로 말하면|총적으로|대로 하다|으로서|참|그만이다|할 따름이다|쿵|탕탕|쾅쾅|둥둥|봐|봐라|아이야|아니|와아|응|아이|참나|년|월|` +
      `일|령|영|일|이|삼|사|오|육|륙|칠|팔|구|이천육|이천칠|이천팔|이천구|하나|둘|셋|넷|다섯|여섯|일곱|여덟|아홉|령|영` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ku: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `ئەم|ئەو|ئەو|ئەوان|ئەوەی|ئێمە|ئێوە|بە|بەبێ|بەپێی|` +
      `بەدەم|بەردەم|بەرلە|بەرەوە|بەرەوی|بەلای|بۆ|بێ|` +
      `بێجگە|پاش|پێ|پێش|تۆ|تێ|جگە|چەند|دە|دەکات|دەگەڵ|` +
      `دوای|دوو|سەر|کرد|کە|لە|لەبابەت|لەباتی|لەبارەی|لەبرێتی|` +
      `لەبن|لەبەر|لەبەینی|لەپێناوی|لەدەم|لەرەوی|لەرێ|لەرێگا|` +
      `لەژێر|لەسەر|لەگەڵ|لەلایەن|لەناو|لەنێو|لەو|لێ|من|ناو|نێوان|هەر|هەروەها|و|وەک|ی` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly he: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `|את|לא|של|אני|על|זה|עם|כל|הוא|אם|או|גם|יותר|יש|לי|מה|` +
      `אבל|פורום|אז|טוב|רק|כי|שלי|היה|בפורום|אין|עוד|` +
      `היא|אחד|ב|ל|עד|לך|כמו|להיות|אתה|כמה|אנחנו|הם|כבר|אנשים|אפשר|תודה|שלא|אותו|ה|מאוד|הרבה|ולא|ממש|` +
      `לו|א|מי|חיים|בית|שאני|יכול|שהוא|כך|הזה|איך|היום|קצת|עכשיו|שם|בכל|יהיה|תמיד|י|שלך|הכי|ש|בו|לעשות|` +
      `צריך|כן|פעם|לכם|ואני|משהו|אל|שלו|שיש|ו|וגם|אתכם|אחרי|בנושא|כדי|פשוט|לפני|שזה|אותי|אנו|למה|דבר|כ|` +
      `כאן|אולי|טובים|רוצה|שנה|בעלי|החיים|למען|אתם|מ|בין|יום|זאת|איזה|ביותר|לה|אחת|הכל|הפורומים|לכל|אלא|` +
      `פה|יודע|שלום|דקות|לנו|השנה|דרך|אדם|נראה|זו|היחידה|רוצים|בכלל|טובה|שלנו|האם|הייתי|הלב|היו|ח|שדרות|` +
      `בלי|להם|שאתה|אותה|מקום|ואתם|חלק|בן|בואו|אחר|האחת|אותך|כמובן|בגלל|באמת|מישהו|ילדים|אותם|הפורום|` +
      `טיפוח|וזה|ר|שהם|אך|מזמין|ישראל|כוס|זמן|ועוד|הילדים|עדיין|כזה|עושה|שום|לקחת|העולם|תפוז|לראות|לפורום|` +
      `וכל|לקבל|נכון|יוצא|לעולם|גדול|אפילו|ניתן|שני|אוכל|קשה|משחק|ביום|ככה|אמא|בת|השבוע|נוספים|לגבי|בבית|` +
      `אחרת|לפי|ללא|שנים|הזמן|שמן|מעט|לפחות|אף|שוב|שלהם|במקום|כולם|נועית|הבא|מעל|לב|המון|לדבר|ע|אוהב|` +
      `מוסיפים|חצי|בעיקר|כפות|לפעמים|שהיא|הנהלת|ועל|ק|אוהבים|ת|יודעת|ד|גרוע|שאנחנו|מים|לילדים|בארץ|מודיע|` +
      `אשמח|שלכם|פחות|לכולם|די|אהבה|יכולה|דברים|הקהילה|לעזור|פרטים|בדיוק|מלח|קל|הראשי|שלה|להוסיף|השני|` +
      `לדעתי|בר|למרות|שגם|מוזמנים|לאחר|במה|חושב|מאד|יפה|להגשים|חדש|קטן|מחפשים|שמח|מדברים|ואם|במיוחד|` +
      `עבודה|מדי|ואז|חשוב|שאם|אוהבת|פעמים|מנהלת|אומר|מול|קשר|מנהל|שיהיה|שאין|שאנו|האהבה|ס|הצטרפו|` +
      `כפית|בשביל|החגים|אופן|לתת|כף|בתוך|סוכר|גיל|בהצלחה|והוא|מקווה|סתם|ויש|נגד|כמעט|שאת|עולה|אי|` +
      `מספר|ראשון|לדרך|נהיה|לעצב|עושים|` +
      `ולנהל|היתה|עליו|מזה|הייתה|בא|בפרס|חלות|ראש|מזמינים|טיפים|מכבי|רבה|הורים|‡|` +
      `מקרה|קרן|המוצלח|להגיע|גדולה|כנראה|החמשיר|הראשון|פלפל|המשחק|וכאן|לדעת|ואת|גרועים|ספר|אגב|אחרים|` +
      `להגיד|בתפוז|והעולם|אופנה|דווקא|מספיק|שעות|תמונות|כשאנחנו|שוקולד|ולכן|ג|לקרוא|לניהול|שבוע|` +
      `ויופי|חלום|בה|שהיה|שאלה|מקומה|הזו|בפורומים|החדש|מתאמצים|שחקן|שמזינים|נשמת|בערך|` +
      `מכל|ומה|רגל|כסף|רואה|קטנה|בצל|בעולם|אינטרנט|חוץ|ברור|הולך|חושבת|לזה|כלום|הן|` +
      `כאלה|בטוח|הדבר|תהיה|מגיע|סוף|האמת|ממנו|מיכל|החדשה|לתרום|האנשים|ועד|בדרך|` +
      `אצלי|ההורים|בני|מתוך|כאשר|לבד|ראיתי|מצב|מלא|לבחור|נשמח|החג|רע|עוף|מן|להביא|` +
      `מצאתי|כתובות|מעניין|צריכה|להכנס|לחלוטין|שעה|מתכון|קודם|תשובות|מדובר|ניהול|` +
      `מזל|כדאי|יהיו|ההודעות|בוקר|נילוות|איפה|בעיה|קמח|ללכת|פורומים|אמר|נושא|` +
      `ההכנה|בבקשה|שכל|הזאת|למשחק|פנינה|תחרות|חבר|לקנות|מהם|רגע|גרם|אלו|` +
      `עצמו|מראש|הכלב|כולנו|עדיף|איתו|למשל|לבשל|למי|רעיונות|הבלוג|רוב|אביב|` +
      `כרגע|בסוף|אלה|לחג|ערוץ|שווה|באופן|מאמין|לבן|בזה|` +
      `הכבוד|לראש|ם|ימי|שחור|בצורה|בעמוד|ועם|וחצי|האלה|תמונה|בשלב|משחקים|נו` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ar: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `فى|في|كل|لم|لن|له|من|هو|هي|قوة|كما|لها|منذ|وقد|ولا|` +
      `لقاء|مقابل|هناك|وقال|وكان|وقالت|وكانت|فيه|لكن|وفي|ولم|ومن|وهو|وهي|` +
      `يوم|فيها|منها|يكون|يمكن|حيث|االا|اما|االتى|التي|اكثر|` +
      `ايضا|الذى|الذي|الان|الذين|ابين|ذلك|دون|حول|حين|الى|انه|اول|انها|ف|و|و6|قد|لا|ما|` +
      `مع|هذا|واحد|واضاف|واضافت|فان|قبل|قال|كان|لدى|نحو|هذه|وان|واكد|كانت|` +
      `واوضح||ب|ا|أ|،|عن|عند|عندما|على|عليه|عليها|تم|ضد|` +
      `بعد|بعض|حتى|اذا|احد|بان|اجل|غير|بن|به|ثم|اف|ان|او|اي|بها` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly lv: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `ārpus|šaipus|aiz|ap|apakš|apakšpus|arī|ar|ar|augšpus|būšu|būs|būsi|būsiet|` +
      `būsim|būt|bet|bez|bijām|bijāt|bija|biji|biju |caur|dēļ|diemžēl|diezin|droši|esam|esat|esi|esmu|gan|gar|iekām|` +
      `iekāms|iekš|iekšpus|iekam|iekams|ik|ir|it|itin|iz|jā|ja|jau|jebšu|jeb|jel|jo|kā|kļūšu|kļūs|kļūsi|kļūsiet|` +
      `kļūsim|kļūst|kļūstam|kļūstat|kļūsti|kļūstu|kļūt|kļuvām|kļuvāt|kļuva|kļuvi|kļuvu|ka|kamēr|kaut|kolīdz|kopš|` +
      `līdz|līdzko|labad|lai|lejpus|nē|ne|nebūt|nedz|nekā|nevis|nezin|no|nu|otrpus|pār|pēc|pa|par|pat|pie|pirms|` +
      `pret|priekš|starp|tā|tādēļ|tālab|tāpēc|taču|tad|tak|tapāt|tapšu|tapi|taps|tapsi|tapsiet|tapsim|tapt|te|` +
      `tiec|tiek|tiekam|tiekat|tieku|tikām|tikāt|tikšu|tik|tika|tikai|tiki|tikko|tiklīdz|tiklab|tiks|tiksiet|` +
      `tiksim|tikt|tiku|tikvien|tomēr|topat|turpretī|turpretim|un|uz|vai|varēšu|varējām|varējāt|varēja|varēji|` +
      `varēju|varēs|varēsi|varēsiet|varēsim|varēt|var|varat|viņpus|vien|vien|virs|virspus|vis|zem` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly lt: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `á|ákypai|ástriþai|ðájá|ðalia|ðe|ðiàjà|ðiàja|ðiàsias|ðiøjø|ðiøjø|ði|ðiaisiais|ðiuoju|ðiajai|ðiajam|` +
      `ðiajame|ðiapus|ðiedvi|ðieji|ðiesiems|ðioji|ðiojo|ðiojoje|ðiokia|ðioks|ðiosiomis|ðiosioms|ðiosios|ðiosios|` +
      `ðiosiose|ðis|ðisai|ðit|ðita|ðitas|ðitiedvi|ðitokia|ðitoks|ðituodu|ðiuodu|ðiuosiuose|ðiuosius|ðtai|þemiau|` +
      `að|abi|abidvi|abiejø|abiejose|abiejuose|abiem|abigaliai|abipus|abu|abudu|ai|anàjà|anàjá|anàja|anàsias|` +
      `anøjø|anøjø|ana|ana|anaiptol|anaisiais|anajai|anajam|anajame|anapus|anas|anasai|anosiose|anot|ant|antai|` +
      `anasis|anei|aniedvi|anieji|aniesiems|anoji|anojo|anojoje|anokia|anoks|anosiomis|anosioms|anosios|anosios|` +
      `anuodu|anuoju|anuosiuose|anuosius|apie|aplink|ar|ar|arba|argi|arti|aukðèiau|be|be|bei|beje|bemaþ|bent|bet|` +
      `betgi|beveik|dëka|dël|dëlei|dëlto|dar|dargi|daugmaþ|deja|ech|et|gal|galbût|galgi|gan|gana|gi|greta|ið|iðilgai|` +
      `iðvis|idant|iki|iki|ir|irgi|it|itin|jàjà|jàja|jàsias|jájá|jøjø|jøjø|jûsø|jûs|jûsiðkë|jûsiðkis|jaisiais|` +
      `jajai|jajam|jajame|jei|jeigu|ji|jiedu|jiedvi|jieji|jiesiems|jinai|jis|jisai|jog|joji|jojo|jojoje|jokia|` +
      `joks|josiomis|josioms|josios|josios|josiose|judu|judvi|juk|jumis|jums|jumyse|juodu|juoju|juosiuose|juosius|` +
      `jus|kaþin|kaþkas|kaþkatra|kaþkatras|kaþkokia|kaþkoks|kaþkuri|kaþkuris|kad|kada|kadangi|kai|kaip|kaip|kaipgi|` +
      `kas|katra|katras|katriedvi|katruodu|kiaurai|kiek|kiekvienas|kieno|kita|kitas|kitokia|kitoks|kodël|kokia|` +
      `koks|kol|kolei|kone|kuomet|kur|kurgi|kuri|kuriedvi|kuris|kuriuodu|lai|lig|ligi|link|lyg|mûsø|mûsiðkë|mûsiðkis|` +
      `maþdaug|maþne|manàjà|manàjá|manàja|manàsias|manæs|manøjø|manøjø|man|manaisiais|manajai|manajam|manajame|manas|` +
      `manasai|manasis|mane|maniðkë|maniðkis|manieji|maniesiems|manim|manimi|mano|manoji|manojo|manojoje|manosiomis|` +
      `manosioms|manosios|manosios|manosiose|manuoju|manuosiuose|manuosius|manyje|mat |mes|mudu|mudvi|mumis|mums|` +
      `mumyse|mus|në|na|nagi|ne|nebe|nebent|nebent|negi|negu|nei|nei|nejau|nejaugi|nekaip|nelyginant|nes|net|netgi|` +
      `netoli|neva|nors|nors|nuo|o|ogi|ogi|oi|paèiø|paèiais|paèiam|paèiame|paèiu|paèiuose|paèius|paeiliui|pagal|` +
      `pakeliui|palaipsniui|palei|pas|pasak|paskos|paskui|paskum|patá|pat|pati|patiems|paties|pats|patys|per|per|` +
      `pernelyg|pirm|pirma|pirmiau|po|prieð|prieðais|prie|pro|pusiau|rasi|rodos|sau|savàjà|savàjá|savàja|savàsias|` +
      `savæs|savøjø|savøjø|savaisiais|savajai|savajam|savajame|savas|savasai|savasis|save|saviðkë|saviðkis|savieji|` +
      `saviesiems|savimi|savo|savoji|savojo|savojoje|savosiomis|savosioms|savosios|savosios|savosiose|savuoju|` +
      `savuosiuose|savuosius|savyje|skersai|skradþiai|staèiai|su|sulig|tàjà|tàjá|tàja|tàsias|tøjø|tøjø|tûlas|taèiau|` +
      `ta|tad|tai|tai|taigi|taigi|taip|taipogi|taisiais|tajai|tajam|tajame|tamsta|tarp|tarsi|tarsi|tartum|tartum|` +
      `tarytum|tas|tasai|tau|tavàjà|tavàjá|tavàja|tavàsias|tavæs|tavøjø|tavøjø|tavaisiais|tavajai|tavajam|tavajame|` +
      `tavas|tavasai|tavasis|tave|taviðkë|taviðkis|tavieji|taviesiems|tavimi|tavo|tavoji|tavojo|tavojoje|tavosiomis|` +
      `tavosioms|tavosios|tavosios|tavosiose|tavuoju|tavuosiuose|tavuosius|tavyje|te|tegu|tegu|tegul|tegul|tiedvi|` +
      `tieji|ties|tiesiems|tiesiog|tik|tik|tikriausiai|tiktai|tiktai|toji|tojo|tojoje|tokia|toks|tol|tolei|toliau|` +
      `tosiomis|tosioms|tosios|tosios|tosiose|tu|tuodu|tuoju|tuosiuose|tuosius|turbût|uþ|uþtat|uþvis|vël|vëlgi|va|` +
      `vai|viduj|vidury|vien|vienas|vienokia|vienoks|vietoj|virð|virðuj|virðum|vis|vis dëlto|visa|visas|visgi|` +
      `visokia|visoks|vos|vos|ypaè` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly mr: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `आहे|या|आणि|व|नाही|आहेत|यानी|हे|तर|ते|असे|होते|केली|हा|ही|पण|करणयात|काही|केले|` +
      `एक|केला|अशी|मात्र|त्यानी|सुरू|करून|होती|असून|आले|त्यामुळे|झाली|होता|दोन|झाले|मुबी|होत|त्या|आता|असा|याच्या|` +
      `त्याच्या|ता|आली|की|पम|तो|झाला|त्री|तरी|म्हणून|त्याना|अनेक|काम|माहिती|हजार|सागित्ले|दिली|आला|आज|ती|तसेच|एका|` +
      `याची|येथील|सर्व|न|डॉ|तीन|येथे|पाटील|असलयाचे|त्याची|काय|आपल्या|म्हणजे|याना|म्हणाले|त्याचा|असलेल्या|मी|गेल्या|` +
      `याचा|येत|म|लाख|कमी|जात|टा|होणार|किवा|का|अधिक|घेऊन|परयतन|कोटी|झालेल्या|निर्ण्य|येणार|व्यकत` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly no: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `å|alle|at|av|både|båe|bare|begge|ble|blei|bli|blir|blitt|då|da|de|deg|dei|deim|` +
      `deira|deires|dem|den|denne|der|dere|deres|det|dette|di|din|disse|ditt|du|dykk|dykkar|eg|ein|eit|eitt|eller|` +
      `elles|en|enn|er|et|ett|etter|før|for|fordi|fra|ha|hadde|han|hans|har|hennar|henne|hennes|her|hjå|ho|hoe|honom|` +
      `hoss|hossen|hun|hva|hvem|hver|hvilke|hvilken|hvis|hvor|hvordan|hvorfor|i|ikke|ikkje|ingen|ingi|inkje|inn|inni|` +
      `ja|jeg|kan|kom|korleis|korso|kun|kunne|kva|kvar|kvarhelst|kven|kvi|kvifor|man|mange|me|med|medan|meg|meget|` +
      `mellom|men|mi|min|mine|mitt|mot|mykje|nå|når|ned|no|noe|noen|noka|noko|nokon|nokor|nokre|og|også|om|opp|oss|` +
      `over|på|så|sånn|samme|seg|selv|si|sia|sidan|siden|sin|sine|sitt|sjøl|skal|skulle|slik|so|som|somme|somt|til|` +
      `um|upp|ut|uten|vår|være|vært|var|vart|varte|ved|vere|verte|vi|vil|ville|vore|vors|vort` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly fa: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `|و|در|به|از|كه|مي|اين|است|را|با|هاي|براي|آن|يك|شود|شده|خود|ها|` +
      `كرد|شد|اي|تا|كند|بر|بود|گفت|نيز|وي|هم|كنند|دارد|ما|كرده|يا|اما|بايد|دو|اند|هر|` +
      `خواهد|او|مورد|آنها|باشد|ديگر|مردم|نمي|بين|پيش|پس|اگر|همه|صورت|يكي|` +
      `هستند|بي|من|دهد|هزار|نيست|استفاده|داد|داشته|راه|داشت|چه|همچنين|كردند|داده|بوده|دارند|همين|ميليون|` +
      `سوي|شوند|بيشتر|بسيار|روي|گرفته|هايي|تواند|اول|نام|هيچ|چند|جديد|بيش|شدن|` +
      `كردن|كنيم|نشان|حتي|اينكه|ولی|توسط|چنين|برخي|نه|ديروز|دوم|درباره|بعد|مختلف|` +
      `گيرد|شما|گفته|آنان|بار|طور|گرفت|دهند|گذاري|بسياري|طي|بودند|ميليارد|بدون|تمام|كل|` +
      `تر|براساس|شدند|ترين|امروز|باشند|ندارد|چون|قابل|گويد|ديگري|همان|خواهند|قبل|آمده|اكنون|` +
      `تحت|طريق|گيري|جاي|هنوز|چرا|البته|كنيد|سازي|سوم|كنم|بلكه|زير|توانند|ضمن|فقط|بودن|حق|آيد|وقتي|` +
      `اش|يابد|نخستين|مقابل|خدمات|امسال|تاكنون|مانند|تازه|آورد|فكر|آنچه|نخست|نشده|` +
      `شايد|چهار|` +
      `جريان|پنج|ساخته|زيرا|نزديك|برداري|كسي|ريزي|رفت|گردد|مثل|آمد|ام|بهترين|دانست|كمتر|` +
      `دادن|تمامي|جلوگيري|بيشتري|ايم|ناشي|چيزي|آنكه|بالا|بنابراين|ايشان|بعضي|دادند|داشتند|` +
      `برخوردار|نخواهد|هنگام|نبايد|غير|نبود|ديده|وگو|داريم|چگونه|بندي|خواست|` +
      `فوق|ده|نوعي|هستيم|ديگران|همچنان|سراسر|ندارند|گروهي|سعي|روزهاي|آنجا|يكديگر|كردم|بيست|بروز|سپس|` +
      `رفته|آورده|نمايد|باشيم|گويند|زياد|خويش|همواره|گذاشته|شش|نداشته|شناسي|` +
      `خواهيم|آباد|داشتن|نظير|همچون|باره|نكرده|شان|سابق|هفت|دانند|جايي|بی|جز|زیرِ|رویِ|سریِ|تویِ|جلویِ|` +
      `پیشِ|عقبِ|بالایِ|خارجِ|وسطِ|بیرونِ|سویِ|کنارِ|پاعینِ|نزدِ|نزدیکِ|دنبالِ|حدودِ|برابرِ|` +
      `طبقِ|مانندِ|ضدِّ|هنگامِ|برایِ|مثلِ|بارة|اثرِ|تولِ|علّتِ|سمتِ|عنوانِ|قصدِ|روب|جدا|کی|که|چیست|هست|کجا|کجاست|` +
      `کَی|چطور|کدام|آیا|مگر|چندین|یک|چیزی|دیگر|کسی|بعری|هیچ|` +
      `چیز|جا|کس|هرگز|یا|تنها|بلکه|خیاه|بله|بلی|آره|آری|مرسی|البتّه|لطفه|انکه|وقتیکه|همین|پیش|مدّتی|` +
      `هنگامی|مان|تان` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly pl: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `ach|aj|albo|bardzo|bez|bo|być|ci|cię|ciebie|co|czy|daleko|dla|dlaczego|` +
      `dlatego|do|dobrze|dokąd|dość|dużo|dwa|dwaj|dwie|dwoje|dziś|dzisiaj|gdyby|gdzie|go|ich|ile|im|inny|ja|ją|jak|` +
      `jakby|jaki|je|jeden|jedna|jedno|jego|jej|jemu|jeśli|jest|jestem|jeżeli|już|każdy|kiedy|kierunku|kto|ku|lub|` +
      `ma|mają|mam|mi|mną|mnie|moi|mój|moja|moje|może|mu|my|na|nam|nami|nas|nasi|nasz|nasza|nasze|natychmiast|nią|` +
      `nic|nich|nie|niego|niej|niemu|nigdy|nim|nimi|niż|obok|od|około|on|ona|one|oni|ono|owszem|po|pod|ponieważ|` +
      `przed|przedtem|są|sam|sama|się|skąd|tak|taki|tam|ten|to|tobą|tobie|tu|tutaj|twoi|twój|twoja|twoje|ty|wam|` +
      `wami|was|wasi|wasz|wasza|wasze|we|więc|wszystko|wtedy|wy|żaden|zawsze|że` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly pt: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `último|é|acerca|agora|algmas|alguns|ali|ambos|antes|apontar|aquela|` +
      `aquelas|aquele|aqueles|aqui|atrás|bem|bom|cada|caminho|cima|com|como|comprido|conhecido|corrente|das|debaixo|` +
      `dentro|desde|desligado|deve|devem|deverá|direita|diz|dizer|dois|dos|e|ela|ele|eles|em|enquanto|então|está|` +
      `estão|estado|estar|estará|este|estes|esteve|estive|estivemos|estiveram|eu|fará|faz|fazer|fazia|fez|fim|foi|` +
      `fora|horas|iniciar|inicio|ir|irá|ista|iste|isto|ligado|maioria|maiorias|mais|mas|mesmo|meu|muito|muitos|nós|` +
      `não|nome|nosso|novo|o|onde|os|ou|outro|para|parte|pegar|pelo|pessoas|pode|poderá|podia|por|porque|povo|` +
      `promeiro|quê|qual|qualquer|quando|quem|quieto|são|saber|sem|ser|seu|somente|têm|tal|também|tem|tempo|` +
      `tenho|tentar|tentaram|tente|tentei|teu|teve|tipo|tive|todos|trabalhar|trabalho|tu|um|uma|umas|uns|usa|usar|` +
      `valor|veja|ver|verdade|verdadeiro|você` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ro: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `acea|aceasta|această|aceea|acei|aceia|acel|acela|acele|acelea|acest|` +
      `acesta|aceste|acestea|aceşti|aceştia|acolo|acord|acum|ai|aia|aibă|aici|al|ăla|ale|alea|ălea|altceva|altcineva|` +
      `am|ar|are|aş|aşadar|asemenea|asta|ăsta|astăzi|astea|ăstea|ăştia|asupra|aţi|au|avea|avem|aveţi|azi|bine|bucur|` +
      `bună|ca|că|căci|când|care|cărei|căror|cărui|cât|câte|câţi|către|câtva|caut|ce|cel|ceva|chiar|cinci|cînd|cine|` +
      `cineva|cît|cîte|cîţi|cîtva|contra|cu|cum|cumva|curând|curînd|da|dă|dacă|dar|dată|datorită|dau|de|deci|deja|` +
      `deoarece|departe|deşi|din|dinaintea|dintr-|dintre|doi|doilea|două|drept|după|ea|ei|el|ele|eram|este|eşti|eu|` +
      `face|fără|fata|fi|fie|fiecare|fii|fim|fiţi|fiu|frumos|graţie|halbă|iar|ieri|îi|îl|îmi|împotriva|în |înainte|` +
      `înaintea|încât|încît|încotro|între|întrucât|întrucît|îţi|la|lângă|le|li|lîngă|lor|lui|mă|mai|mâine|mea|mei|` +
      `mele|mereu|meu|mi|mie|mîine|mine|mult|multă|mulţi|mulţumesc|ne|nevoie|nicăieri|nici|nimeni|nimeri|nimic|nişte|` +
      `noastră|noastre|noi|noroc|noştri|nostru|nouă|nu|opt|ori|oricând|oricare|oricât|orice|oricînd|oricine|oricît|` +
      `oricum|oriunde|până|patra|patru|patrulea|pe|pentru|peste|pic|pînă|poate|pot|prea|prima|primul|prin|puţin|` +
      `puţina|puţină|rog|sa|să|săi|sale|şapte|şase|sau|său|se|şi|sînt|sîntem|sînteţi|spate|spre|ştiu|sub|sunt|suntem|` +
      `sunteţi|sută|ta|tăi|tale|tău|te|ţi|ţie|timp|tine|toată|toate|tot|toţi|totuşi|trei|treia|treilea|tu|un|una|` +
      `unde|undeva|unei|uneia|unele|uneori|unii|unor|unora|unu|unui|unuia|unul|vă|vi|voastră|voastre|voi|voştri|` +
      `vostru|vouă|vreme|vreo|vreun|zece|zero|zi|zice` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ru: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `а|е|и|ж|м|о|на|не|ни|об|но|он|мне|мои|мож|она|они|оно|мной|много|многочисленное|` +
      `многочисленная|многочисленные|многочисленный|мною|мой|мог|могут|можно|может|можхо|мор|моя|моё|мочь|над|` +
      `нее|оба|нам|нем|нами|ними|мимо|немного|одной|одного|менее|однажды|однако|меня|нему|меньше|ней|наверху|низко|` +
      `начала|него|ниже|мало|надо|один|одиннадцать|одиннадцатый|назад|наиболее|недавно|миллионов|недалеко|между|` +
      `меля|нельзя|нибудь|непрерывно|наконец|никогда|никуда|нас|наш|нет|нею|неё|них|мира|наша|наше|наши|ничего|` +
      `нередко|несколько|обычно|опять|около|мы|ну|нх|от|отовсюду|особенно|нужно|очень|отсюда|в|во|вон|вниз|внизу|` +
      `вокруг|вот|восемнадцать|восемнадцатый|восемь|восьмой|вверх|вам|вами|важное|важная|важные|важный|вдали|везде|` +
      `ведь|вас|ваш|ваша|ваше|ваши|впрочем|весь|вдруг|вы|все|второй|всем|всеми|времени|время|всему|всего|всегда|` +
      `всех|всею|всю|вся|всё|всюду|г|год|говорил|говорит|года|году|где|да|ее|за|из|ли|же|им|до|по|ими|под|иногда|` +
      `довольно|именно|долго|позже|более|должно|пожалуйста|значит|иметь|больше|пока|ему|имя|пор|пора|потом|потому|` +
      `после|почему|почти|посреди|ей|два|две|двенадцать|двенадцатый|двадцать|двадцатый|двух|его|дел|или|без|день|` +
      `занят|занята|занято|заняты|действительно|давно|девятнадцать|девятнадцатый|девять|девятый|даже|алло|жизнь|` +
      `далеко|близко|здесь|дальше|для|лет|зато|даром|первый|перед|затем|зачем|лишь|десять|десятый|ею|её|их|бы|еще|` +
      `при|был|про|процентов|против|просто|бывает|бывь|если|люди|была|были|было|будем|будет|будете|будешь|прекрасно|` +
      `буду|будь|будто|будут|ещё|пятнадцать|пятнадцатый|друго|другое|другой|другие|другая|других|есть|пять|быть|` +
      `лучше|пятый|к|ком|конечно|кому|кого|когда|которой|которого|которая|которые|который|которых|кем|каждое|каждая|` +
      `каждые|каждый|кажется|как|какой|какая|кто|кроме|куда|кругом|с|т|у|я|та|те|уж|со|то|том|снова|тому|совсем|того|` +
      `тогда|тоже|собой|тобой|собою|тобою|сначала|только|уметь|тот|тою|хорошо|хотеть|хочешь|хоть|хотя|свое|свои|твой|` +
      `своей|своего|своих|свою|твоя|твоё|раз|уже|сам|там|тем|чем|сама|сами|теми|само|рано|самом|самому|самой|самого|` +
      `семнадцать|семнадцатый|самим|самими|самих|саму|семь|чему|раньше|сейчас|чего|сегодня|себе|тебе|сеаой|человек|` +
      `разве|теперь|себя|тебя|седьмой|спасибо|слишком|так|такое|такой|такие|также|такая|сих|тех|чаще|четвертый|` +
      `через|часто|шестой|шестнадцать|шестнадцатый|шесть|четыре|четырнадцать|четырнадцатый|сколько|сказал|сказала|` +
      `сказать|ту|ты|три|эта|эти|что|это|чтоб|этом|этому|этой|этого|чтобы|этот|стал|туда|этим|этими|рядом|тринадцать|` +
      `тринадцатый|этих|третий|тут|эту|суть|чуть|тысяч` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly sk: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `a|aby|aj|ak|ako|ale|alebo|and|ani|áno|asi|až|bez|bude|budem|budeš|budeme|budete|` +
      `budú|by|bol|bola|boli|bolo|byť|cez|čo|či|ďalší|ďalšia|ďalšie|dnes|do|ho|ešte|for|i|ja|je|jeho|jej|ich|iba|` +
      `iné|iný|som|si|sme|sú|k|kam|každý|každá|každé|každí|kde|keď|kto|ktorá|ktoré|ktorou|ktorý|ktorí|ku|lebo|len|ma|` +
      `mať|má|máte|medzi|mi|mna|mne|mnou|musieť|môcť|môj|môže|my|na|nad|nám|náš|naši|nie|nech|než|nič|niektorý|nové|` +
      `nový|nová|nové|noví|o|od|odo|of|on|ona|ono|oni|ony|po|pod|podľa|pokiaľ|potom|práve|pre|prečo|preto|pretože|` +
      `prvý|prvá|prvé|prví|pred|predo|pri|pýta|s|sa|so|si|svoje|svoj|svojich|svojím|svojími|ta|tak|takže|táto|teda|` +
      `te|tě|ten|tento|the|tieto|tým|týmto|tiež|to|toto|toho|tohoto|tom|tomto|tomuto|toto|tu|tú|túto|tvoj|ty|tvojími|` +
      `už|v|vám|váš|vaše|vo|viac|však|všetok|vy|z|za|zo|že` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly sv: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `aderton|adertonde|adjö|aldrig|alla|allas|allt|alltid|alltså|än|andra|andras|` +
      `annan|annat|ännu|artonde|artonn|åtminstone|att|åtta|åttio|åttionde|åttonde|av|även|båda|bådas|bakom|bara|` +
      `bäst|bättre|behöva|behövas|behövde|behövt|beslut|beslutat|beslutit|bland|blev|bli|blir|blivit|bort|borta|bra|` +
      `då|dag|dagar|dagarna|dagen|där|därför|de|del|delen|dem|den|deras|dess|det|detta|dig|din|dina|dit|ditt|dock|` +
      `du|efter|eftersom|elfte|eller|elva|en|enkel|enkelt|enkla|enligt|er|era|ert|ett|ettusen|få |fanns|får|fått |` +
      `fem|femte|femtio|femtionde|femton|femtonde|fick|fin|finnas|finns|fjärde|fjorton|fjortonde|fler|flera|flesta|` +
      `följande|för|före|förlåt|förra|första|fram|framför|från|fyra|fyrtio|fyrtionde|gå|gälla|gäller|gällt|går|` +
      `gärna|gått|genast|genom|gick|gjorde|gjort|god|goda|godare|godast|gör|göra|gott|ha|hade|haft|han|hans|har|här|` +
      `heller|hellre|helst|helt|henne|hennes|hit|hög|höger|högre|högst|hon|honom|hundra|hundraen|hundraett|hur|i|` +
      `ibland|idag|igår|igen|imorgon|in|inför|inga|ingen|ingenting|inget|innan|inne|inom|inte|inuti|ja|jag|jämfört|` +
      `kan|kanske|knappast|kom|komma|kommer|kommit|kr|kunde|kunna|kunnat|kvar|länge|längre|långsam|långsammare|` +
      `långsammast|långsamt|längst|långt|lätt|lättare|lättast|legat|ligga|ligger|lika|likställd|likställda|lilla|` +
      `lite|liten|litet|man|många|måste|med|mellan|men|mer|mera|mest|mig|min|mina|mindre|minst|mitt|mittemot|möjlig|` +
      `möjligen|möjligt|möjligtvis|mot|mycket|någon|någonting|något|några|när|nästa|ned|nederst|nedersta|nedre|nej|` +
      `ner|ni|nio|nionde|nittio|nittionde|nitton|nittonde|nödvändig|nödvändiga|nödvändigt|nödvändigtvis|nog|noll|nr|` +
      `nu|nummer|och|också|ofta|oftast|olika|olikt|om|oss|över|övermorgon|överst|övre|på|rakt|rätt|redan|så|sade|` +
      `säga|säger|sagt|samma|sämre|sämst|sedan|senare|senast|sent|sex|sextio|sextionde|sexton|sextonde|sig|sin|sina|` +
      `sist|sista|siste|sitt|sjätte|sju|sjunde|sjuttio|sjuttionde|sjutton|sjuttonde|ska|skall|skulle|slutligen|små|` +
      `smått|snart|som|stor|stora|större|störst|stort|tack|tidig|tidigare|tidigast|tidigt|till|tills|tillsammans|tio|` +
      `tretton|tionde|tjugo|tjugoen|tjugoett|tjugonde|tjugotre|tjugotvå|tjungo|tolfte|tolv|tre|tredje|trettio|` +
      `trettionde|trettonde|två|tvåhundra|under|upp|ur|ursäkt|ut|utan|utanför|ute|vad|vänster|vänstra|var|vår|vara|` +
      `våra|varför|varifrån|varit|varken|värre|varsågod|vart|vårt|vem|vems|verkligen|vi|vid|vidare|viktig|viktigare|` +
      `viktigast|viktigt|vilka|vilken|vilket|vill` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly th: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `ไว้|ไม่|ไป|ได้|ให้|ใน|โดย|แห่ง|แล้ว|และ|แรก|แบบ|แต่|เอง|เห็น|เลย|เริ่ม|` +
      `เรา|เมื่อ|เพื่อ|เพราะ|เป็นการ|เป็น|เปิดเผย|เปิด|เนื่องจาก|เดียวกัน|เดียว|เช่น|เฉพาะ|เคย|เข้า|เขา|อีก|อาจ|อะไร|` +
      `ออก|อย่าง|อยู่|อยาก|หาก|หลาย|หลังจาก|หลัง|หรือ|หนึ่ง|ส่วน|ส่ง|สุด|สําหรับ|ว่า|วัน|ลง|ร่วม|ราย|รับ|ระหว่าง|รวม|` +
      `ยัง|มี|มาก|มา|พร้อม|พบ|ผ่าน|ผล|บาง|น่า|นี้|นํา|นั้น|นัก|นอกจาก|ทุก|ที่สุด|ที่|ทําให้|ทํา|ทาง|ทั้งนี้|้ง|ถ้า|` +
      `ถูก|ถึง|ต้อง|ต่างๆ|ต่าง|ต่อ|ตาม|ตั้งแต่|ตั้ง|ด้าน|ด้วย|ดัง|ซึ่ง|ช่วง|จึง|จาก|จัด|จะ|คือ|ความ|ครั้ง|คง|ขึ้น|` +
      `ของ|ขอ|ขณะ|ก่อน|ก็|การ|กับ|กัน|กว่า|กล่าว` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly tr: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `acaba|altmýþ|altý|ama|bana|bazý|belki|ben|benden|beni|benim|beþ|` +
      `bin|bir|biri|birkaç|birkez|birþey|birþeyi|biz|bizden|bizi|bizim|bu|buna|bunda|bundan|bunu|bunun|da|daha|dahi|` +
      `de|defa|diye|doksan|dokuz|dört|elli|en|gibi|hem|hep|hepsi|her|hiç|iki|ile|INSERmi|ise|için|katrilyon|kez|ki|` +
      `kim|kimden|kime|kimi|kýrk|milyar|milyon|mu|mü|mý|nasýl|ne|neden|nerde|nerede|nereye|niye|niçin|on|ona|ondan|` +
      `onlar|onlardan|onlari|onlarýn|onu|otuz|sanki|sekiz|seksen|sen|senden|seni|senin|siz|sizden|sizi|sizin|trilyon|` +
      `tüm|ve|veya|ya|yani|yedi|yetmiþ|yirmi|yüz|çok|çünkü|üç|þey|þeyden|þeyi|þeyler|þu|þuna|` +
      `þunda|þundan|þunu` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly uk: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      `з|й|що|та|Із|але|цей|коли|як|чого|хоча|нам|яко╞|чи|це|в╡д|` +
      `про|╡|╞х|╙|Інших|ти|вІн|вона|воно|ми|ви|вони` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );

  static readonly ur: any = new RegExp(
    `\(^|[^a-zA-ZäöüßÄÖÜ])(` +
      ` ثھی|خو|گی|اپٌے|گئے|ثہت|طرف|ہوبری|پبئے|اپٌب|دوضری|` +
      `گیب|کت|گب|ثھی|ضے|ہر|پر|` +
      `اش|دی|گے|لگیں|ہے|ثعذ|ضکتے|وٍ|تھی|اى|دیب|لئے|والے|یہ|` +
      `ثدبئے|ضکتی|ًے|تھب|اًذر|رریعے|لگی|ہوبرا|ہوًے|ثبہر|ضکتب  ` +
      `ًہیں|تو|اور|رہب|لگے|ہوضکتب|ہوں|کب|ہوبرے|توبم|کیب|ایطے|` +
      `رہی|` +
      `هگر|ہوضکتی|ہیں|کریں|ہو|تک|کی|ایک|رہے|هیں|ہوضکتے|کیطے|ہوًب|تت|کہ|ہوا|آئے|ضبتھ|ًب|تھے|کیوں|ہوتب` +
      `ہ|خت|کے|پھر|ثغیر|خبرہے|رکھ|کیطب|کوئی|رریعے|ثبرے|خب|اضطرذ|ثلکہ|خجکہ|رکھتب|کیطرف|ثراں|` +
      `خبرہب|رریعہ|کطی|اضکب|ثٌذ|خص|رکھتبہوں|کیلئے|ثبئیں|توہیں|دوضرے|کررہی|` +
      `اضکی|ثیچ|خوکہ|رکھتی|کیوًکہ|دوًوں|کررہے|خبرہی|ثرآں|اضکے|پچھلا|خیطب|رکھتے|کےثعذ|توہی|دوراى|کررہب|یہبںٓ` +
      ` آش|اًہیں|ثي|پطٌذ|تھوڑا|چکے|زکویہ|دوضروں|ضکب|اة|اوًچب|ثٌب|پل|تھوڑی|چلا|خبهوظ|` +
      `دیتب|ضکٌب|اخبزت|اوًچبئی|ثٌبرہب|پوچھب|تھوڑے|چلو|ختن|دیتی|ضکی|اچھب|اوًچی|ثٌبرہی|پوچھتب|تیي|چلیں|در|` +
      `دیتے|ضکے|اچھی|اوًچے|ثٌبرہے|پوچھتی|خبًب|چلے|درخبت|دیر|ضلطلہ|اچھے|` +
      `اٹھبًب|ثٌبًب|پوچھتے|خبًتب|چھوٹب|درخہ|دیکھٌب|ضوچ|اختتبم|اہن|ثٌذ|پوچھٌب|خبًتی|چھوٹوں|درخے|دیکھو|ضوچب|ادھر|` +
      `آئی|ثٌذکرًب|پوچھو|خبًتے|چھوٹی|درزقیقت|دیکھی|ضوچتب|` +
      `ارد|آئے|ثٌذکرو|پوچھوں|خبًٌب|چھوٹے|درضت|دیکھیں|ضوچتی|اردگرد|آج|ثٌذی|پوچھیں|خططرذ|چھہ|دش|دیٌب|ضوچتے|ارکبى|آخر|` +
      `ثڑا|پورا|خگہ|چیسیں|دفعہ|دے|ضوچٌب|اضتعوبل|آخرکبر|ثڑوں|پہلا|خگہوں|زبصل|دکھبئیں|راضتوں|ضوچو|` +
      `اضتعوبلات|آدهی|ثڑی|پہلی|خگہیں|زبضر|دکھبتب|راضتہ|ضوچی|` +
      `اغیب|آًب|ثڑے|پہلےضی|خلذی|زبل|دکھبتی|راضتے|ضوچیں|اطراف|آٹھ|ثھر|پہلےضے|خٌبة|زبل|` +
      `دکھبتے|رکي|ضیذھب|افراد|آیب|ثھرا|پہلےضےہی|خواى|زبلات|دکھبًب|رکھب|ضیذھی|` +
      `اکثر|ثب|ثھراہوا|پیع|خوًہی|زبلیہ|دکھبو|رکھی|ضیذھے|اکٹھب|ثبترتیت|ثھرپور|تبزٍ|خیطبکہ|زصوں|` +
      `دکھبیب|رکھے|ضیکٌڈ|اکٹھی|ثبری|ثہتر|تر|چبر|زصہ|دلچطپ|زیبدٍ|غبیذ|` +
      `اکٹھے|ثبلا|ثہتری|ترتیت|چبہب|زصے|دلچطپی|ضبت|غخص|اکیلا|ثبلترتیت|ثہتریي|تریي|چبہٌب|زقبئق|` +
      `دلچطپیبں|ضبدٍ|غذ|اکیلی|ثرش|پبش|تعذاد|چبہے|زقیتیں|هٌبضت|ضبرا|غروع|` +
      `اکیلے|ثغیر|پبًب|اً|ب|ج|ی|ر|ق|ت|چکب|زقیقت|دو|ضبرے|غروعبت|اگرچہ|ثلٌذ|پبًچ|تن|چکی|زکن|دور|ضبل|غے|` +
      `الگ|ثلٌذوثبلا|پراًب|تٌہب|چکیں|اً|ب|و|ک|ز|دوضرا|ضبلوں|صبف|صسیر|قجیلہ|کوًطے|لازهی|هطئلے` +
      `یب|طریق|کرتی|کہتے|صفر|قطن|کھولا|لگتب|هطبئل|وار|طریقوں|کرتے|کہٌب|صورت|` +
      `کئی|کھولٌب|لگتی|هطتعول|وار|طریقہ|کرتےہو|کہٌب|صورتسبل|کئے|کھولو|لگتے|هػتول|ٹھیک|طریقے|کرًب|کہو|صورتوں|` +
      `کبفی|کھولی|لگٌب|هطلق|ڈھوًڈا|طور|کرو|کہوں|صورتیں|کبم|کھولیں|لگی|هعلوم|ڈھوًڈلیب|طورپر|کریں|` +
      `کہی|ضرور|کجھی|کھولے|لگے|هکول|ڈھوًڈًب|ظبہر|کرے|کہیں|ضرورت|کرا|کہب|لوجب|` +
      `هلا|ڈھوًڈو|عذد|کل|کہیں|اً|ب|ت|ر|و|ر|ض|کرتب|کہتب|لوجی|هوکي|ڈھوًڈی|عظین|کن|کہے|ضروری|کرتبہوں|کہتی|لوجے|` +
      `هوکٌبت|ڈھوًڈیں|علاقوں|کوتر|کیے||لوسبت|هوکٌہ|ہن|لے|ًبپطٌذ|ہورہے|علاقہ|کورا|کےرریعے|لوسہ|هڑا|ہوئی|هتعلق` +
      `ًبگسیر|ہوگئی|علاقے|کوروں|گئی|لو|هڑًب|ہوئے|هسترم|ًطجت|ہوگئے|علاوٍ|کورٍ|گرد|لوگ|هڑے|ہوتی|هسترهہ|ًقطہ|ہوگیب|` +
      `اً|ب|ه|و|و|ع|کورے|گروپ|لوگوں|هہرثبى|ہوتے|هسطوش|ًکبلٌب|ہوًی|عووهی|کوطي|` +
      `گروٍ|لڑکپي|هیرا|ہوچکب|هختلف|ًکتہ|ہی|فرد|کوى|گروہوں|لی|هیری|ہوچکی|هسیذ|ًو|اً|ب|ی|ق|ی|فی|کوًطب|گٌتی|لیب|هیرے|` +
      `ہوچکے|هطئلہ|ًوخواى|یقیٌی|قجل|کوًطی|اً|ب|ه|ز|لا|لیٌب|ًئی|ہورہب|لیں|ًئے|ہورہی|ثبعث|ضت` +
      `)\(?![a-zA-ZäöüßÄÖÜ])`,
    'g',
  );
}

<!--
  Raw data view dialog.

  @unstable
-->

<div class="z-fx-col main-container">
  <div
    *ngIf="{ survey: (survey$ | async).survey, design: (survey$ | async).design } as asyncData"
    class="z-fx z-fx-none z-fx-gap-16 header-box"
    [class.z-fx-start-center]="asyncData?.design?.background?.url || 'assets/images/survey-placeholder.png'"
    [class.z-fx-center-center]="!(asyncData?.design?.background?.url || 'assets/images/survey-placeholder.png')"
  >
    <div
      *ngIf="!!(asyncData?.design?.background?.url || 'assets/images/survey-placeholder.png')"
      class="zef-logo-image {{ 'rectangle' }}"
      [style.background-image]="
        asyncData?.design?.background?.url || 'assets/images/survey-placeholder.png' | encodeURI : 'url' | safeStyle
      "
    ></div>

    <div class="z-fx-col z-fx-center-start z-fx-gap-4">
      <h1 matDialogTitle i18n>All data</h1>

      <h4 class="mat-subheading-1">{{ asyncData.survey?.name || dialogLabel }}</h4>
    </div>
    <div class="z-fx z-fx-auto z-fx-end-start z-fx-gap-8 buttons">
      <div *ngIf="loadingExport" class="loading-text z-fx z-fx-center-center z-fx-gap-4">
        <zef-spinner size="small"></zef-spinner>
        <div class="zef-text-p2-muted" i18n>Generating...</div>
      </div>
      <div *ngIf="exportError" class="loading-text z-fx z-fx-center-center z-fx-gap-4">
        <div class="zef-text-p2 mat-error" i18n>Something went wrong, please try again later</div>
      </div>
      <button mat-icon-button color="secondary" [matMenuTriggerFor]="exportMenu">
        <mat-icon>more</mat-icon>
      </button>
      <button class="close-button" mat-icon-button color="secondary" matDialogClose><mat-icon>close</mat-icon></button>
    </div>
    <mat-menu #exportMenu xPosition="before">
      <div mat-menu-item i18n (click)="exportAs('csv')">Export as CSV</div>
      <div mat-menu-item i18n (click)="exportAs('xlsx')">Export as XLSX</div>
    </mat-menu>
  </div>
  <div *ngIf="filters && filters.length > 0" class="z-fx z-fx-start-center remove-selections" i18n>
    <mat-icon class="remove-icon" (click)="removeSelections()">close</mat-icon>
    {{ responses }} Responses selected with {{ filters?.length || 0 }} filters
  </div>

  <div class="z-fx-col z-fx-auto table-container" (elementResize)="tableSizeChange($event)">
    <text-answer-table
      #textAnswerTable
      class="z-fx-col z-fx-auto text-answer-table"
      [details]="data?.dimensions ? data?.dimensions : []"
      [filterInput]="filterInput"
      [filtering]="true"
      [bigTable]="true"
      [size]="tableHeight"
      [update]="update"
      [crossfilter]="data?.cf"
      [surveyRights]="data?.surveyRights"
      [userRights]="data?.userRights"
    ></text-answer-table>
  </div>
</div>

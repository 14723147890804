import { of, ReplaySubject } from 'rxjs';
import { map, distinctUntilChanged, tap, takeUntil } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { NavBarInstance } from '@home/shared/modules/nav-bar/nav-bar.models';

import { crud } from '@shared/operators/crud.operator';
import { isShallowEqual } from '@shared/utilities/object.utilities';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Injectable()
export class NavBarService {
  private addLayer$ = new ReplaySubject<NavBarInstance>(1);

  private updateLayer$ = new ReplaySubject<NavBarInstance>(1);

  private removeLayer$ = new ReplaySubject<NavBarInstance>(1);

  readonly currentLayer$ = new ReplaySubject<NavBarInstance>(1);

  constructor(lh: LifecycleHooks) {
    crud(of([]), this.addLayer$, this.removeLayer$, this.updateLayer$, 'content')
      .pipe(
        map((layers) => layers[layers.length - 1]),
        distinctUntilChanged(isShallowEqual),
        tap((layer) => this.currentLayer$.next({ ...layer })),
        takeUntil(lh.destroy),
      )
      .subscribe();
  }

  updateLayer(layer: NavBarInstance): void {
    this.updateLayer$.next(layer);
  }

  addLayer(layer: NavBarInstance): void {
    this.addLayer$.next(layer);
  }

  removeLayer(layer: NavBarInstance): void {
    this.removeLayer$.next(layer);
  }
}

import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { ChartDistribution, ChartDomain, ChartLegend, CanvasContext } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, drawRoundRect, shortenText } from '@shared/utilities/canvas.utilities';
import { isField, sortByValue } from '@report/shared/utilities/report.utilities';

/**
 * This is a horizontal bar chart.
 */
@Directive({
  selector: '[multiBarChartV]',
})
export class MultiBarChartV implements OnChanges {
  @Input() data: ChartDistribution[][] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() totalAnswers: number = 0;
  @Input() touchDevice: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private max: number = 0;
  private previousMax: number = 0;

  private responses: number[] = [];
  private previousResponses: number[] = [];

  private scaleX: any;
  private scaleY: any;

  private sortScaleX: boolean = false;

  private brush: any;
  private brushArea: any;
  private brushing: boolean = false;

  private legends: ChartLegend[] = [];

  private tooltip: any;
  private tooltipData: any;

  private width: any;
  private height: any;
  private margin: any;
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number = 0;

  private filter: any;

  private selections: any = [new Set(), new Set()];

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.stats
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setNumbers();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  constructBody() {
    this.marginBottomUnits = 9;
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'multi-bar-chart-v');
    this.tooltip = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'item-tooltip')
      .style('display', 'none');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 3 * this.unit,
      bottom: this.marginBottomUnits * this.unit,
      left: 3 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;

    this.sortScaleX = isField(this.domain.key) && this.domain.scale === 'linear';
  }

  setScales() {
    const overScale = 1.2;

    const maxs: number[] = [];
    this.data[0].forEach((d) => {
      let max = 0;

      for (const c of d.children) {
        const value = this.scale === 'percentage' ? c.percentage_all : c.value;
        max = max > value ? max : value;
      }

      maxs.push(max);
    });

    this.previousMax = this.max;
    this.max = (d3.max(maxs, (d: any) => d) || 1) * overScale;

    this.scaleY = d3.scaleLinear().rangeRound([this.height, 0]).domain([0, this.max]);

    this.scaleX = d3
      .scaleBand()
      .rangeRound([0, this.width])
      .domain(!this.sortScaleX ? this.domain.keys : this.domain.keys.slice().sort(sortByValue))
      .paddingInner(0.3)
      .paddingOuter(0.1);
  }

  setNumbers() {
    this.previousResponses = this.responses;
    this.responses = [];

    (this.data[1] || []).forEach((d, i) => {
      const statItem = ((this.stats && this.stats[1] && this.stats[1]['children']) || []).find(
        (item) => item && item.key === d.key,
      );
      this.responses[i] =
        statItem &&
        statItem['children'] &&
        statItem['children'][this.domain.index] &&
        statItem['children'][this.domain.index]['responses'] != null
          ? statItem['children'][this.domain.index]['responses']
          : null;
    });
  }

  colorScale(index) {
    if (this.domain.scaleY === 'linear') {
      return Colors.COMPARISONLINEAR[index];
    } else {
      return Colors.getComparisonColor(index);
    }
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      __this.setLegends(context);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              value: d3.interpolateNumber(a[i]['value'], b[i]['value']),
              percentage: d3.interpolateNumber(a[i]['percentage'], b[i]['percentage']),
              percentage_all: d3.interpolateNumber(a[i]['percentage_all'], b[i]['percentage_all']),
              children: [],
            };
            for (let child = 0, lenc = c[i]['children'].length; child < lenc; child++) {
              const aVal: number =
                a[i] && a[i]['children'] && a[i]['children'][child] ? a[i]['children'][child]['value'] : 0;
              const bVal: number =
                b[i] && b[i]['children'] && b[i]['children'][child] ? b[i]['children'][child]['value'] : 0;
              const aPer: number =
                a[i] && a[i]['children'] && a[i]['children'][child] ? a[i]['children'][child]['percentage'] : 0;
              const bPer: number =
                b[i] && b[i]['children'] && b[i]['children'][child] ? b[i]['children'][child]['percentage'] : 0;
              const aPerAll: number =
                a[i] && a[i]['children'] && a[i]['children'][child] ? a[i]['children'][child]['percentage_all'] : 0;
              const bPerAll: number =
                b[i] && b[i]['children'] && b[i]['children'][child] ? b[i]['children'][child]['percentage_all'] : 0;
              x[i]['children'][child] = {
                value: d3.interpolateNumber(aVal, bVal),
                percentage: d3.interpolateNumber(aPer, bPer),
                percentage_all: d3.interpolateNumber(aPerAll, bPerAll),
              };
            }
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['value'] = x[i]['value'](t);
              c[i]['percentage'] = x[i]['percentage'](t);
              c[i]['percentage_all'] = x[i]['percentage_all'](t);

              for (let child = 0, lenc = c[i]['children'].length; child < lenc; child++) {
                c[i]['children'][child]['value'] = x[i]['children'][child]['value'](t);
                c[i]['children'][child]['percentage'] = x[i]['children'][child]['percentage'](t);
                c[i]['children'][child]['percentage_all'] = x[i]['children'][child]['percentage_all'](t);
              }
            }
            return c;
          };
        };
        const interpolator = interpolateArray(dataObj, d[0]);
        const interpolateMax = d3.interpolateNumber(__this.previousMax, __this.max);
        const interpolateResponses = d3.interpolateArray(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scaleY;
          let responses;

          if (step >= 1) {
            data = interpolator(ease(1));
            responses = interpolateResponses(ease(1));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(1))]);
            timer.stop();
          } else {
            data = interpolator(ease(step));
            responses = interpolateResponses(ease(step));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(step))]);
          }

          __this.setRects(context, data, scaleY, [], null, responses);
        });
      } else {
        __this.setRects(context, d[0], __this.scaleY, [], null, __this.responses);
      }

      __this.context = { context, data: d[0] };
    };

    this.canvas = this.base.selectAll('.multi-bar-chart-v-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'multi-bar-chart-v-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setLegends(context) {
    this.legends = [];
    const y = this.margin.top + this.height + 7 * this.unit;
    const width = this.width + this.margin.left + this.margin.right;
    const height = 2 * this.unit;
    let usedSpace = 0;
    let arr: any[] = [];
    let currentX = 0;
    let currentY = 0;
    let margin;

    context.clearRect(0, y - 1, width, this.margin.bottom);

    context.textBaseline = 'top';
    context.textAlign = 'left';
    context.font = 10 / 14 + 'em Open Sans';

    for (let i = 0, length = this.domain.keysY.length; i < length; i++) {
      const key = this.domain.keysY[i];
      const text = shortenText(context, this.domain.labelsY[key], width, 3 * this.unit);
      const elW = context.measureText(text).width;

      if (usedSpace + elW + 2 * this.unit <= width) {
        usedSpace += elW + 2 * this.unit;
        arr.push([key, this.domain.colorsY && this.domain.colorsY[key] != null ? this.domain.colorsY[key] : i, text]);
      } else {
        margin = (width - usedSpace) / 2;
        currentX = margin;

        for (let a = 0, len = arr.length; a < len; a++) {
          const itemText = arr[a][2];
          const itemColor = arr[a][1];
          const itemKey = arr[a][0];
          currentX += this.unit;

          context.fillStyle = Colors.TEXT;
          context.fillText(itemText, currentX, currentY + y);

          context.beginPath();
          context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
          context.closePath();
          context.fillStyle = this.colorScale(itemColor);
          context.fill();
          this.legends.push({
            key: itemKey,
            x: currentX,
            y: currentY + y,
            width: context.measureText(itemText).width,
            height,
          });
          currentX += context.measureText(itemText).width + this.unit;
        }

        currentY += height;
        usedSpace = elW + 2 * this.unit;

        arr = [[key, this.domain.colorsY && this.domain.colorsY[key] != null ? this.domain.colorsY[key] : i, text]];
      }
    }

    margin = (width - usedSpace) / 2;
    currentX = margin;

    for (let a = 0, len = arr.length; a < len; a++) {
      const itemText = arr[a][2];
      const itemColor = arr[a][1];
      const itemKey = arr[a][0];
      currentX += this.unit;

      context.fillStyle = Colors.TEXT;
      context.fillText(itemText, currentX, currentY + y);

      context.beginPath();
      context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = this.colorScale(itemColor);
      context.fill();
      this.legends.push({
        key: itemKey,
        x: currentX,
        y: currentY + y,
        width: context.measureText(itemText).width,
        height,
      });
      currentX += context.measureText(itemText).width + this.unit;
    }

    const spaceNeeded = currentY / this.unit + 9;

    if (!isNaN(spaceNeeded) && this.marginBottomUnits !== spaceNeeded) {
      this.marginBottomUnits += spaceNeeded - this.marginBottomUnits;
      this.setEnvironment();
      this.setScales();
      this.setLegends(context);
    }
  }

  setRects(
    context,
    data: any[] = [],
    scaleY,
    filter: any[] | null = [],
    highlight: any[] | null = [],
    responses: number[] = [],
  ) {
    context.clearRect(this.margin.left - 1, this.margin.top - 1, this.width + this.margin.right + 1, this.height + 2);
    this.setTexts(context, responses);
    this.setYAxis(context, scaleY);
    this.selections = [new Set(), new Set()];

    for (let x = 0, lenx = data.length; x < lenx; x++) {
      const keyParent = data[x]['key'];
      const posParent = this.scaleX(keyParent) + this.margin.left;
      for (let y = 0, leny = data[x].children.length; y < leny; y++) {
        const keyY = data[x]['children'][y]['key'];
        const value = data[x]['children'][y]['value'];
        const percentage = data[x]['children'][y]['percentage_all'];

        const height = this.height - scaleY(this.scale === 'percentage' ? percentage : value);
        const width = this.scaleX.bandwidth() / leny - 1;
        const yPos = scaleY(this.scale === 'percentage' ? percentage : value) + this.margin.top;
        const xPos = posParent + y * (width + 1);

        context.fillStyle = this.colorScale(
          this.domain.colorsY && this.domain.colorsY[keyY] != null ? this.domain.colorsY[keyY] : y,
        );

        if (filter != null && filter.length === 2) {
          const xFi = this.filterInput[1] && this.filterInput[1].indexOf(keyY) > -1;
          const parent =
            filter &&
            filter[0] <= posParent - this.margin.left + this.scaleX.bandwidth() / 2 &&
            filter[1] >= posParent - this.margin.left + this.scaleX.bandwidth() / 2;

          if (!parent) {
            context.globalAlpha = 0.2;
          } else if (parent) {
            this.selections[0].add(keyParent);
            context.globalAlpha = 1;
          }
          if (xFi) {
            this.selections[1].add(keyY);
          }
        } else if (
          this.filterInput &&
          (this.filterInput[0] || this.filterInput[1]) &&
          ((this.filterInput[0] && this.filterInput[0].length > 0) ||
            (this.filterInput[1] && this.filterInput[1].length > 0))
        ) {
          const xFi = this.filterInput[1] && this.filterInput[1].indexOf(keyY) > -1;
          const parent = this.filterInput[0] && this.filterInput[0].indexOf(keyParent) > -1;

          if (!parent) {
            if (!this.filterInput[0] || this.filterInput[0].length === 0) {
              context.globalAlpha = 1;

              if (this.filterInput[1] && !xFi) {
                context.globalAlpha = 0.2;
              } else if (xFi) {
                this.selections[1].add(keyY);
              }
            } else {
              context.globalAlpha = 0.2;
            }
          } else {
            if (this.filterInput[1] && !xFi) {
              context.globalAlpha = 0.2;
            }
            if (xFi) {
              this.selections[1].add(keyY);
            }
            if (filter != null && parent) {
              this.selections[0].add(keyParent);
            }
          }
        }

        context.strokeStyle = 'transparent';
        context.lineWidth = 2;
        let highlightNumber = false;
        let highlightBoldNumber = false;

        if (
          highlight &&
          highlight.length > 0 &&
          ((highlight[0] && highlight[0].length > 0) || (highlight[1] && highlight[1].length > 0))
        ) {
          const xHi = highlight[0] && highlight[0].indexOf(keyY) > -1;
          const parent = highlight[1] && highlight[1].indexOf(keyParent) > -1;

          if (xHi && parent) {
            highlightNumber = true;
            highlightBoldNumber = true;
            context.strokeStyle = Colors.HIGHLIGHT;
          } else if (xHi) {
            highlightNumber = highlight[0] && highlight[0].length === 1;
          } else {
            context.globalAlpha = 0.2;
          }
        }

        drawRoundRect(context, xPos, yPos, width, height, { tl: 5, tr: 5, bl: 0, br: 0 }, context.fillStyle, true);

        if (this.showNumbers || highlightNumber) {
          context.font = (highlightBoldNumber ? 'bold ' : 'normal ') + 10 / 14 + 'em Open Sans';
          context.textBaseline = 'bottom';
          context.textAlign = 'center';
          context.fillStyle = Colors.TEXT;
          context.fillText(
            this.scale === 'percentage' ? (percentage * 100).toFixed(1) + '%' : Math.round(value),
            xPos + width / 2,
            yPos - 5,
          );
        }
        context.globalAlpha = 1;
      }
    }
  }

  setTexts(context, responses) {
    const height = 6 * this.unit;
    context.clearRect(0, this.height + this.margin.top, this.width + this.margin.right + this.margin.left, height + 2);
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    const h = this.margin.top / 2;
    const wIcon: number[] = [];
    const wNumber: number[] = [];
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    for (let i = 0, len = responses.length; i < len; i++) {
      wIcon.push(this.fontSize + 0.4 * this.unit);

      context.font = 10 / 14 + 'em Open Sans';
      wNumber.push(context.measureText(Math.round(responses[i])).width + 0.8 * this.unit);
    }

    for (let i = 0, len = responses.length; i < len; i++) {
      const key = this.data[1][i] ? this.data[1][i]['key'] : '';

      const startPointResponses =
        (this.margin.left + this.margin.right + this.width) / 2 +
        (wIcon.reduce((t, v) => t + v) + wNumber.reduce((t, v) => t + v)) * (1 / len) * (i - len / 2);

      context.fillStyle = Colors.TEXT;
      context.fillStyle =
        key && this.domain.colorsY && this.domain.colorsY[key] != null
          ? this.colorScale(this.domain.colorsY[key])
          : this.colorScale(i);
      drawContactIcon(context, this.fontSize, startPointResponses, h, context.fillStyle);

      context.font = 10 / 14 + 'em Open Sans';
      context.fillStyle =
        key && this.domain.colorsY && this.domain.colorsY[key] != null
          ? this.colorScale(this.domain.colorsY[key])
          : this.colorScale(i);
      context.fillStyle = Colors.TEXT;
      context.fillText(Math.round(responses[i]), startPointResponses + wIcon[i], h);
    }

    context.beginPath();
    context.moveTo(this.margin.left + 8, this.height + this.margin.top + this.unit);
    context.lineTo(this.margin.left + this.width - 8, this.height + this.margin.top + this.unit);
    context.closePath();
    context.strokeStyle = Colors.HELPERLINE;
    context.stroke();

    if (this.domain.scale === 'linear') {
      context.beginPath();
      context.arc(this.margin.left + this.width / 2, this.height + this.margin.top + this.unit, 5, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = Colors.BACKGROUND;
      context.stroke();
      context.fill();

      context.font = 12 / 14 + 'em Open Sans';
      context.textBaseline = 'top';
      context.fillStyle = Colors.TEXT;

      context.textAlign = 'start';
      const xMinLabel = shortenText(context, this.domain.labelsLinear.min, this.width / 2, 10);
      context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + height / 4);

      context.textAlign = 'end';
      const xMaxLabel = shortenText(context, this.domain.labelsLinear.max, this.width / 2, 10);
      context.fillText(xMaxLabel, this.margin.left + this.width, this.height + this.margin.top + height / 4);

      context.font = 'normal bold ' + 12 / 14 + 'em Open Sans';
      context.textAlign = 'center';
      const xAxisLabel = shortenText(context, this.domain.labelsLinear.axis, this.width, 10);
      context.fillText(
        xAxisLabel,
        this.margin.left + this.width / 2,
        this.height + this.margin.top + (2.2 * height) / 4,
      );
    } else {
      context.font = 10 / 14 + 'em Open Sans';
      context.fillStyle = Colors.TEXT;
      context.textAlign = 'center';
      context.textBaseline = 'top';
      const bandwidth = this.scaleX.bandwidth();

      this.domain.keys.forEach((d) => {
        context.save();
        context.font = (this.domain.labels[d] === '☑' || this.domain.labels[d] === '☐' ? 20 : 10) / 14 + 'em Open Sans';
        if (bandwidth < 65) {
          context.textBaseline = 'middle';
          context.textAlign = 'right';
          context.translate(
            this.margin.left + this.scaleX(d) + bandwidth / 2,
            this.margin.top + this.height + height / 4,
          );
          context.rotate((315 * Math.PI) / 180);
          context.fillText(
            shortenText(
              context,
              this.domain.labels[d],
              Math.sqrt(2 * Math.pow(height - 1.5 * this.unit, 2)),
              0.5 * this.unit,
            ),
            0,
            0.8 * this.unit,
          );
        } else {
          context.translate(
            this.margin.left + this.scaleX(d) + bandwidth / 2,
            this.margin.top + this.height + height / 4,
          );
          context.fillText(shortenText(context, this.domain.labels[d], bandwidth, 5), 0, 0);
        }

        context.restore();
      });
    }
  }

  setYAxis(context, scaleY) {
    context.clearRect(0, this.margin.top, this.margin.left, this.height + this.unit);

    const __this = this;
    const tickCount = this.scale === 'percentage' ? 4 : this.max > 3 ? 4 : this.max > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleY.ticks(tickCount);
    const tickFormat = scaleY.tickFormat(tickCount, this.scale === 'percentage' ? ',.1%' : 'd');

    context.font = 10 / 14 + 'em Open Sans';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'right';
    context.textBaseline = 'middle';
    ticks.forEach((d) => {
      const x = this.margin.left - tickPadding;
      const y = this.margin.top + scaleY(d);
      context.fillText(tickFormat(d), x, y);
      if (d > 0) {
        context.beginPath();
        context.moveTo(x + tickPadding + 8, y);
        context.lineTo(this.margin.left + this.width, y);
        context.stroke();
      }
    });
  }

  setBrush() {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area);
      }
    };

    if (this.filtering && !this.anonymityLock) {
      this.brush = d3
        .brushX()
        .on('brush', function (event, d) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = true;

            const area = this.parentElement ? d3.pointer(event, this.parentElement) : [];
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.multi-bar-chart-v-canvas').each(function (da) {
              __this.setRects(__this.context.context, da[0], __this.scaleY, sel, null, __this.responses);
            });

            if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
              __this.selectForHoverParent(event, area);
            }
          }
        })
        .on('end', function (event, d) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = false;
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.multi-bar-chart-v-canvas').each(function (da) {
              __this.setRects(__this.context.context, da[0], __this.scaleY, sel, null, __this.responses);
            });

            __this.callFilter();
          }
        });

      const callBrush = function (d) {
        if (__this.filterInput && __this.filterInput[0] && __this.filterInput[0].length > 0) {
          const barW = __this.scaleX.bandwidth();
          const padding = (__this.scaleX.paddingInner() * __this.scaleX.step()) / 2;
          const filInpMin = !__this.sortScaleX ? __this.filterInput[0][0] : Math.min(...__this.filterInput[0]);
          const filInpMax = !__this.sortScaleX
            ? __this.filterInput[0][__this.filterInput.length - 1]
            : Math.max(...__this.filterInput[0]);

          const minX = __this.scaleX(filInpMin) - padding;
          const maxX = __this.scaleX(filInpMax) + barW + padding;

          const brushArea = [minX > 0 ? minX : 0, maxX];

          d3.select(this).call(__this.brush).call(__this.brush.move, brushArea);
        } else {
          const brushOn = d3.brushSelection(d3.select(this).node()) != null;

          if (__this.filterInput && !__this.filterInput[0] && brushOn) {
            d3.select(this).call(__this.brush.move, null);
          } else {
            d3.select(this).call(__this.brush);
          }
        }
      };

      this.brushArea = this.base.selectAll('.svg-brush').data([this.data]);

      this.brushArea.exit().remove();

      this.brushArea
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + 60)
        .select('.brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);

      this.brushArea
        .enter()
        .append('svg')
        .attr('class', 'svg-brush')
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + 60)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .on('mousemove', hoverFunction)
        .on('mouseout', function (event, d) {
          __this.setTooltip(d3.pointer(event, __this._element.nativeElement));
        })
        .append('g')
        .attr('class', 'brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);
    }

    this.base
      .selectAll('.multi-bar-chart-v-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event, d) {
        __this.setTooltip(d3.pointer(event));
      })
      .on('click', function (event, d) {
        if (__this.filtering && !__this.anonymityLock) {
          const area = d3.pointer(event);

          __this.selectFromBars(area);
          __this.selectFromLegends(area);

          __this.callFilter();
        }
      });
  }

  setTooltip(position, data: any[] = [], parentList: any[] = []) {
    const __this = this;

    if (data.length > 0 && data[0]) {
      if (JSON.stringify([data, parentList]) !== JSON.stringify(this.tooltipData)) {
        this.tooltip.html(
          `
        <div class="question">
            ${parentList.length === 1 ? this.domain.labels[parentList[0].key] + ': ' : ''}
            ${data.length === 1 ? this.domain.labelsY[data[0].key] : ''}</div>
            <div class="stats">
              <span class="icon">contact</span>
            ${data.length > 1 ? parentList[0].value : data[0].value} (${
            data.length > 1 ? (parentList[0].percentage * 100).toFixed(1) : (data[0].percentage_all * 100).toFixed(1)
          }%)
            </div>
          `,
        );
      }
      this.tooltip.style('display', 'block').style('transform', function (d) {
        return `
              translate(${position[0] - this.offsetWidth / 2}px,${position[1] - this.offsetHeight - 24}px)`;
      });
    } else {
      this.tooltip.html('').style('display', 'none');
    }

    this.tooltipData = [data, parentList];

    // adding hovering effect
    this.base.select('.multi-bar-chart-v-canvas').each(function (d) {
      const highlight = [data.map((item) => item.key), parentList.map((item) => item.key)];
      if (!__this.brushing) {
        __this.setRects(__this.context.context, d[0], __this.scaleY, [], highlight, __this.responses);
      }
    });

    if (data.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.select('.multi-bar-chart-v-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.multi-bar-chart-v-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];

      const filterX = {
        key: this.domain.key,
        values: this.domain.keys,
        filter: Array.from(this.selections[0]),
      };

      this.filter.push(filterX);

      const filterY = {
        key: this.domain.keyY,
        values: this.domain.keysY,
        filter: Array.from(this.selections[1]),
      };

      this.filter.push(filterY);

      const filterInput = JSON.stringify(this.filterInput.slice(0, 2).map((item) => (item == null ? [] : item)));
      const filter = JSON.stringify(this.filter.map((item) => item.filter));

      if (filter !== filterInput) {
        this.cf.filter(this.filter);
      } else {
        const brushOn =
          this.brushArea.select('.brush').node() != null &&
          d3.brushSelection(this.brushArea.select('.brush').node()) != null;

        if (!this.filterInput[0] && brushOn) {
          this.brushArea.select('.brush').call(this.brush.move, null);
        }
      }
    }
  }

  // Helpers
  multiLineWrap(context, text, width, height, lineheight) {
    const textLength = context.measureText(text).width;
    const room = Math.floor(height / lineheight);

    if (textLength > width - 10 && text.length > 0) {
      const words = text.split(/\s+/).reverse();
      const lines: string[] = [];
      let limitReached = false;
      let line = '';
      let word;

      while ((word = words.pop())) {
        const testLine = line + word + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > width - 10 && line.length > 0) {
          if (lines.length + 1 === room) {
            lines.push(line + '...');
            limitReached = true;
            break;
          } else {
            lines.push(line);
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      if (!limitReached) {
        lines.push(line);
      }

      return lines;
    } else {
      return [text];
    }
  }

  selectForHover(event, area) {
    const itemsBelow = this.itemsBelow(area);
    const parents = itemsBelow.parents;
    const childs = itemsBelow.childs.length > 0 ? itemsBelow.childs : parents.length > 0 ? parents[0]['children'] : [];
    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild), childs, parents);
    } else if (legends.length > 0) {
      const legendItems = this.data[1].filter((item) => legends.find((legend) => legend.key.toString() === item.key));

      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild), legendItems, this.data[0]);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild));
    }
  }

  selectForHoverParent(event, area) {
    const itemsBelow = this.itemsBelow(area);
    const parents = itemsBelow.parents;

    if (parents.length > 0) {
      this.setTooltip(
        d3.pointer(event, this._element.nativeElement.firstElementChild),
        parents.length > 0 ? parents[0]['children'] : [],
        parents,
      );
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild));
    }
  }

  selectFromBars(area) {
    const itemsBelow = this.itemsBelow(area);
    const parents = itemsBelow.parents;
    const childs = itemsBelow.childs;

    for (let s = 0, len = childs.length; s < len; s++) {
      if (this.selections[1].has(childs[s].key)) {
        this.selections[1].delete(childs[s].key);
      } else {
        this.selections[1].add(childs[s].key);
      }
    }

    for (let s = 0, len = parents.length; s < len; s++) {
      if (this.selections[0].has(parents[s].key)) {
        if (childs.length === 0 || this.selections[1].size === 0) {
          this.selections[0].delete(parents[s].key);
        }
      } else {
        this.selections[0].add(parents[s].key);
      }
    }

    if (parents.length > 0 && this.selections[0].size === 0) {
      this.selections[1] = new Set();
    }
  }

  selectFromLegends(area) {
    const items = this.itemsBelow(area).legends;

    for (let s = 0, len = items.length; s < len; s++) {
      if (this.selections[1].has(items[s].key)) {
        this.selections[1].delete(items[s].key);
      } else {
        this.selections[1].add(items[s].key);
        // this.selections[0] = new Set();
      }
    }
  }

  itemsBelow(area) {
    const parents = this.data[0].filter(
      (item) =>
        area[0] < this.scaleX(item.key) + this.scaleX.bandwidth() + this.margin.left &&
        area[0] > this.scaleX(item.key) + this.margin.left &&
        area[1] < this.margin.top + this.height + 60,
    );

    const childs =
      parents.length > 0
        ? parents[0]['children'].filter((child, index) => {
            const left = this.scaleX(parents[0]['key']) + this.margin.left;
            const value = this.scale === 'percentage' ? child.percentage_all : child.value;
            return (
              area[0] < left + ((index + 1) * this.scaleX.bandwidth()) / parents[0]['children'].length &&
              area[0] > left + (index * this.scaleX.bandwidth()) / parents[0]['children'].length &&
              area[1] < this.margin.top + this.height &&
              area[1] > this.margin.top + this.scaleY(value)
            );
          })
        : [];

    const legends = this.legends.filter(
      (item) =>
        area[0] > item.x - 15 && area[0] < item.x + item.width && area[1] > item.y && area[1] < item.y + item.height,
    );

    return { parents, childs, legends };
  }
}

import { CdkPortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  Component,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  TemplateRef,
  ViewChild,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';

// @deprecated use <zef-select>

@Component({
  selector: 'zef-dropdown-deprecated',
  templateUrl: './dropdown-deprecated.component.html',
  styleUrls: ['./dropdown-deprecated.component.scss'],
})
export class DropdownDeprecated<T = any> implements OnDestroy {
  private overlayRef?: OverlayRef;

  private connectedTo: HTMLElement = this.elementRef.nativeElement;

  @ViewChild(CdkPortal)
  portal?: CdkPortal;

  @Output()
  readonly change = new EventEmitter<T>();

  @Output()
  readonly dropdownClose = new EventEmitter<void>();

  @Output()
  readonly dropdownOpen = new EventEmitter<void>();

  @Input()
  value?: T;

  @Input()
  options: T[] = [];

  @Input()
  inputTemplate?: TemplateRef<T>;

  @Input()
  optionTemplate?: TemplateRef<T>;

  @Input()
  inputTextTemplate?: TemplateRef<T>;

  @Input()
  optionTextTemplate?: TemplateRef<T>;

  @Input()
  placeholder = 'select option...';

  @Input()
  disabled?: boolean;

  @Input()
  displayWith: (a?: T, i?: number) => string = (a: T) => (a != null ? a.toString() : '');

  @Input()
  selectedWith: (a: T, b: T) => boolean = () => false;

  @Input()
  headerWith: (a?: T, i?: number) => boolean = () => false;

  @Input()
  canSelect: (a?: T, i?: number) => boolean = () => true;

  @Input()
  trackBy: (i: number, a: T) => T = (i, a) => a;

  @HostListener('window:resize')
  onWindowResize = (): void => {
    if (this.overlayRef && this.open) {
      this.overlayRef.updatePosition();

      this.overlayRef.updateSize({
        width: this.connectedTo.offsetWidth,
      });
    }
  };

  @HostListener('document:keydown.escape', ['$event']) private onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    this.closeDropdown();
  }

  public get hasValue(): boolean {
    return this.value != null;
  }

  get open(): boolean {
    return !!this.overlayRef && this.overlayRef.hasAttached();
  }

  constructor(private overlay: Overlay, private elementRef: ElementRef<HTMLElement>, private cd: ChangeDetectorRef) {}

  toggle(): void {
    if (this.open) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  selectOption(option: T): void {
    if (this.canSelect(option)) {
      this.value = option;
      this.change.emit(option);
      this.closeDropdown();
    }
  }

  isSelected(option: T): boolean {
    return (option != null && option === this.value) || this.selectedWith(option, this.value);
  }

  isHeader(option: T): boolean {
    return this.headerWith(option);
  }

  openDropdown(): void {
    if (this.disabled) {
      return;
    }

    if (!this.overlayRef) {
      const config = new OverlayConfig({
        width: this.connectedTo.offsetWidth,
        positionStrategy: this.overlay
          .position()
          .flexibleConnectedTo(this.connectedTo)
          .withPositions([
            { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
            { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
            { originX: 'start', originY: 'center', overlayX: 'start', overlayY: 'center' },
          ])
          .withFlexibleDimensions(true),
        backdropClass: 'cdk-overlay-transparent-backdrop',
        hasBackdrop: true,
      });

      this.overlayRef = this.overlay.create(config);

      this.overlayRef.backdropClick().subscribe(() => this.closeDropdown());

      // scrolling while opening messes up position
      setTimeout(() => {
        if (this.open) {
          this.overlayRef.updatePosition();
        }
      }, 500);
    }

    if (!this.open) {
      this.overlayRef.attach(this.portal);
    }
  }

  closeDropdown(): void {
    if (this.open) {
      this.overlayRef.detach();
      this.cd.markForCheck();
    }
  }
}

import { scan, shareReplay, startWith } from 'rxjs/operators';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';

import { Injectable, OnDestroy, TemplateRef } from '@angular/core';

import { NgScrollbar } from 'ngx-scrollbar';

@Injectable()
export class MultiSelectService<T = any> implements OnDestroy {
  readonly beforeOpened$ = new ReplaySubject<void>(1);

  readonly afterOpened$ = new ReplaySubject<void>(1);

  readonly optionSelected$ = new Subject<T | null>();

  readonly radioSelected$ = new BehaviorSubject<boolean>(void 0);

  readonly defaultSelection$ = new BehaviorSubject<T | undefined>(void 0);

  readonly value$ = new BehaviorSubject<T | undefined>(void 0);

  readonly search$ = new BehaviorSubject<string>('');

  scroller?: NgScrollbar;

  selectLabel?: TemplateRef<T>;

  private readonly updateOptionLabels$ = new Subject<{ value; template }>();

  readonly optionLabelPrefixes = new Map<T, TemplateRef<T>>();

  private optionLabels = new Map<T, TemplateRef<T>>();

  readonly optionLabels$ = this.updateOptionLabels$.pipe(
    scan((a, { value, template }) => a.set(value, template), this.optionLabels),
    startWith(this.optionLabels),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  scrollToElement(element: HTMLElement): void {
    if (this.scroller?.state.isVerticallyScrollable) {
      const elRect = element.getBoundingClientRect();
      const { top, height } = this.scroller.viewport.nativeElement.getBoundingClientRect();
      const currentPos = this.scroller.viewport.scrollTop || 0;

      const position = elRect.top - top + currentPos - height / 2 + elRect.height;
      this.scroller.scrollTo({ top: position, duration: 0 });
    }
  }

  updateScroll(): void {
    if (this.scroller) {
      this.scroller.update();
      this.scroller.nativeElement.style.height = null;
    }
  }

  updateOptionLabels(value: T, template?: TemplateRef<T>): void {
    this.optionLabels.set(value, template);
    this.updateOptionLabels$.next({ value, template });
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.updateOptionLabels$.complete();
    this.afterOpened$.complete();
    this.beforeOpened$.complete();
    this.optionSelected$.complete();
  }
}

import { Observable, merge } from 'rxjs';
import { map, filter, mapTo, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { Component, ChangeDetectorRef } from '@angular/core';

import { ActionCompletion } from '@ngxs/store/src/operators/of-action';
import { Actions, ofActionDispatched, ofActionCompleted } from '@ngxs/store';
import { ImageUploadService } from '@shared/modules/image-upload/services/image-upload.service';
import { UploadImage, UploadImageProgress } from '@shared/modules/image-upload/image-upload.actions';
import { isImageFile } from '@shared/utilities/string.utilities';

@Component({
  selector: 'image-upload-area',
  templateUrl: './image-upload-area.component.html',
  styleUrls: ['./image-upload-area.component.scss'],
})
export class ImageUploadAreaComponent {
  hasError?: boolean;

  readonly uploading$: Observable<boolean> = merge(
    this.actions.pipe(
      ofActionDispatched(UploadImage),
      filter(({ image }: UploadImage) => image === this.currentFile),
      mapTo(true),
    ),
    this.actions.pipe(
      ofActionCompleted(UploadImage),
      filter(({ action: { image } }: ActionCompletion<UploadImage>) => image === this.currentFile),
      mapTo(false),
    ),
  ).pipe(startWith(false), shareReplay({ refCount: true, bufferSize: 1 }));

  readonly progress$: Observable<number> = this.uploading$.pipe(
    filter((uploading) => uploading),
    switchMap(() =>
      this.actions.pipe(
        ofActionDispatched(UploadImageProgress),
        filter(({ image }: UploadImageProgress) => image === this.currentFile),
        map(({ progress }: UploadImageProgress) => (progress === 1 ? 0 : progress)),
        takeUntil(this.uploading$.pipe(filter((uploading) => !uploading))),
      ),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private currentFile: File | undefined;

  constructor(private cd: ChangeDetectorRef, private actions: Actions, readonly iu: ImageUploadService) {}

  getImageSrc(): string {
    return this.iu.gallery || this.hasError || this.iu.customGallery ? '' : this.iu.source;
  }

  onFileChange(file: File): void {
    this.currentFile = void 0;

    if (!file) {
      return;
    }

    this.hasError = !isImageFile(file);

    if (!this.hasError) {
      this.currentFile = file;

      this.iu.uploadImage(file).subscribe();
    }

    this.cd.markForCheck();
  }
}

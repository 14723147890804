/**
 * Used to select language for the player.
 *
 * @unstable
 */

import { map } from 'rxjs/operators';

import { Component, Input, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';

import { LanguageData } from '@shared/models/locale.model';

import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelect implements AfterViewInit {
  public itemStyles = this.ss.colors.pipe(
    map((colors) => ({
      backgroundColor: colors.buttonMuted,
      color: colors.buttonContrast,
    })),
  );

  public activeStyles = this.ss.colors.pipe(
    map((colors) => ({
      backgroundColor: colors.button,
      color: colors.buttonContrast,
    })),
  );

  public checkmarkStyles = this.ss.colors.pipe(
    map((colors) => ({
      backgroundColor: colors.buttonDesaturated,
      color: colors.buttonContrast,
    })),
  );

  public selectedCheckmarkStyles = this.ss.colors.pipe(
    map((colors) => ({
      backgroundColor: colors.buttonContrast,
      color: colors.button,
    })),
  );

  @Input() locales: {
    [lcid: string]: LanguageData;
  };

  @Input() selected: string = null;

  @Output() select = new EventEmitter<string>();

  @ViewChild(NgScrollbar)
  sb: NgScrollbar;

  hover: string | null = null;
  click: string | null = null;

  constructor(readonly lm: LanguageManager, readonly ss: SurveyStore) {}

  ngAfterViewInit() {
    if (this.sb) {
      const { nativeElement } = this.sb.viewport;
      const languagesCount = Object.keys(this.locales || {}).length;
      nativeElement.style.minHeight = `${languagesCount * 48}px`;
      nativeElement.style.maxHeight = `${6 * 48}px`;
    }
  }

  flagClass(locale: string) {
    return locale && locale.length !== 2 ? 'zef-lang-other' : `zef-lang-${locale}`;
  }
}

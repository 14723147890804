import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NavBarAvatarDirective } from '@home/shared/modules/nav-bar/nav-bar-avatar.directive';
import { NavBarButtonComponent } from '@home/shared/modules/nav-bar/nav-bar-button.component';
import { NavBarContentDirective } from '@home/shared/modules/nav-bar/nav-bar-content.directive';
import { NavBarIconDirective } from '@home/shared/modules/nav-bar/nav-bar-icon.directive';
import { NavBarMenuComponent } from '@home/shared/modules/nav-bar/nav-bar-menu.component';
import { NavBarComponent } from '@home/shared/modules/nav-bar/nav-bar.component';
import { BasicModule } from '@shared/modules/basic.module';
import { LindaModule } from '@shared/modules/linda.module';

@NgModule({
  declarations: [
    NavBarComponent,
    NavBarMenuComponent,
    NavBarButtonComponent,

    NavBarIconDirective,
    NavBarAvatarDirective,
    NavBarContentDirective,
  ],
  exports: [
    NavBarComponent,
    NavBarMenuComponent,
    NavBarButtonComponent,

    NavBarIconDirective,
    NavBarAvatarDirective,
    NavBarContentDirective,
  ],
  imports: [CommonModule, LindaModule, MatIconModule, MatDividerModule, BasicModule, RouterModule, MatMenuModule],
})
export class NavBarModule {}

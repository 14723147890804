<div
  *ngIf="isMultiple() && questionData?.showStatus !== false && questionData.choiceLimit > 1"
  class="z-status-text z-texts-paragraph"
  [style.color]="(ss.colors | async).text"
>
  {{ 'Select up to ' + questionData.choiceLimit | translateText : 'choiceSelectUpTo' : [questionData.choiceLimit] }}
</div>

<div
  *ngIf="questionData && ss.colors | async as colors"
  class="z-choices-list"
  [ngClass]="'z-layout-' + questionData.choiceLayout"
>
  <ng-container *ngFor="let choice of choices; trackBy: trackByChoice; index as i">
    <div
      *ngIf="choice.$key !== 'other'"
      class="z-choice-picture"
      [class.selected]="isSelected(choice)"
      [class.z-disabled]="
        !isSelected(choice) &&
        isMultiple() &&
        questionData?.choiceLimit > 1 &&
        selectedChoices.length >= questionData?.choiceLimit
      "
      [style.background-color]="
        isSelected(choice)
          ? colors.primary
          : hovering === choice
          ? colors.primaryParityPrimary2050
          : colors.moodContrastMix5010
      "
      (click)="selectChoice(choice)"
      (mouseover)="onMouseOver(choice)"
      (mouseout)="hovering = null"
    >
      <div
        class="z-choice-picture-inner"
        [style.background-color]="isSelected(choice) || hovering === choice ? colors.mood50 : 'transparent'"
        [style.height.px]="questionData.choiceLayout === layout.Wide ? (ss.viewRect$ | async)?.height / 3 - 48 : null"
      >
        <div class="z-choice-picture-inner-content">
          <div
            *ngIf="isSelected(choice) || isMultiple()"
            class="z-answer"
            [class.z-checkbox]="isMultiple()"
            [class.z-radio]="!isMultiple()"
          >
            <div class="z-answer-box" [style.background-color]="colors.primaryParity">
              <mat-icon
                *ngIf="isSelected(choice)"
                class="z-answered-icon"
                fontSet="zefplayer"
                [style.color]="colors.primary"
                (click)="resetInput()"
              >
                check
              </mat-icon>
            </div>
          </div>

          <picture-image
            class="z-choice-picture-image"
            [image]="choice.imageStyle"
            [zoom]="questionData.choiceZoom !== false"
          ></picture-image>
        </div>
      </div>

      <div
        *ngIf="choice.content?.trim() && !choice.hideContent"
        class="z-choice-picture-title z-texts-option"
        [style.color]="isSelected(choice) || hovering === choice ? colors.primaryParity : colors.text"
      >
        {{ choice.content | translateText : 'choice' : questionData : choice }}
      </div>
    </div>
  </ng-container>
</div>

<span *ngIf="!isValid" class="z-error-text">{{ errorText }}</span>

import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Select, Store } from '@ngxs/store';
import { Rights } from '@shared/enums/rights.enum';
import { TeamData } from '@shared/models/account.model';
import { IdentityData } from '@shared/models/prefs.model';
import { PrefsManager } from '@shared/services/prefs-manager.service';
import { AccountState } from '@shared/states/account.state';
import { PaymentStatusRead } from '@shared/states/billing.actions';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export enum PaymentFailedResponse {
  Cancel = 10,
  Review,
}

@Component({
  templateUrl: './payment-failed.dialog.html',
  styleUrls: ['./payment-failed.dialog.scss'],
})
export class PaymentFailed {
  @Select(AccountState.team)
  readonly team$!: Observable<TeamData>;

  @Select(AccountState.teamLogo)
  teamLogo$: Observable<string>;

  readonly owner$: Observable<IdentityData | null> = this.store.select(AccountState.isTeamOwner).pipe(
    concatMap((isOwner) => (isOwner ? of(null) : this.store.select(AccountState.team))),
    concatMap((team: TeamData | null) => {
      if (team) {
        const [owner] = Object.entries(team.users).find(([_, rt]) => Rights.hasRights(Rights.OWNER, rt)) || [''];

        if (owner) {
          return this.pm.loadIdentity(owner);
        }
      }

      return of(null);
    }),
  );
  dialogTitle = $localize`:dialog title@@zef-i18n-00167:Payment Failed`;
  dialogLabel = $localize`:dialog label@@zef-i18n-00098:Unnamed organization`;
  dialogHeader = $localize`:dialog header@@zef-i18n-00168:Your payment could not be processed.`;
  dialogActionConfirm = $localize`:dialog button label@@zef-i18n-00169:Review`;
  constructor(private store: Store, private pm: PrefsManager, private md: MatDialogRef<PaymentFailed>) {
    this.md.afterClosed().subscribe(() => {
      const isOwner = this.store.selectSnapshot(AccountState.isTeamOwner);
      this.store.dispatch(new PaymentStatusRead(isOwner ? PaymentFailedResponse.Review : PaymentFailedResponse.Cancel));
    });
  }
}

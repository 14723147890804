import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';
import { Select, Store } from '@ngxs/store';
import { ActionsState } from '@shared/states/actions.state';
import { SendVerificationEmail } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';
import { Observable } from 'rxjs';
import { filter, mapTo, shareReplay, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'auth-check-email',
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss'],
})
export class CheckEmail {
  @Input() email: string;
  @Input() login: string;
  @Input() isEmail = false;
  @Input() isInvite = false;
  @Input() isDesktop = true;

  isWebsite = environment.website;
  @Output() submit: EventEmitter<string | null> = new EventEmitter();

  @Select(AuthState.loginUrl)
  readonly loginUrl$!: Observable<string>;

  loading$?: Observable<boolean>;

  done$?: Observable<boolean>;

  constructor(readonly store: Store) {}

  public onLogin() {
    const url = this.store.selectSnapshot(AuthState.loginUrl);
    const lang = this.store.selectSnapshot(PrefsState.language);
    window.location.href = url + (url.includes('?') ? '&' : '?') + `lang=${lang}`;
  }

  resendEmail(): void {
    this.store.dispatch(new SendVerificationEmail());

    this.loading$ = this.store.select(ActionsState.whileAction(SendVerificationEmail));

    this.done$ = this.loading$.pipe(
      filter((loading) => loading),
      switchMap(() =>
        this.loading$.pipe(
          filter((loading) => !loading),
          take(1),
          mapTo(true),
        ),
      ),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}

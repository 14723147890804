<div class="z-progress-wrapper">
  <div class="z-progress-steps">
    <figure
      *ngFor="let question of ss.cardQuestions | async; trackBy: trackByKey; index as i"
      (click)="gotoIndex(i)"
      [style.background-color]="isAnswered(question) ? (ss.colors | async).primary : (ss.colors | async).text05"
    ></figure>
  </div>

  <div class="z-progress-bar" #bar>
    <div
      class="z-progress-current"
      [style.top.%]="(thumbTop | async) * 100"
      [class.z-dragging]="dragging"
      [class.z-index-changed]="indexChange | async"
      [class.z-no-init]="(thumbInit | async) === false"
    >
      <figure class="z-progress-thumb" [style.background-color]="(ss.colors | async).button" #thumb></figure>
      <figure class="z-progress-counter">{{ indexLabel | async }}</figure>
    </div>
  </div>
</div>

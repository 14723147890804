/**
 * Raw data view dialog.
 *
 * @unstable
 */

import { Observable } from 'rxjs';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { Select, Store } from '@ngxs/store';

import { SurveyModel } from '@shared/models/survey.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { SurveyState } from '@shared/states/survey.state';

import { ExportService } from '@report/shared/services/export.service';

import { TextAnswerTable } from '@report/+charts/chart-card/chart/text-answer-table/text-answer-table.component';

@Component({
  selector: 'raw-data-view',
  templateUrl: './raw-data-view.dialog.html',
  styleUrls: ['./raw-data-view.dialog.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RawDataView implements OnInit, OnDestroy {
  public update: Date = new Date();
  public filters: any[] = [];
  public filterInput: any[] = [];
  public loadingExport: boolean = false;
  public exportError: boolean = false;
  public responses: number = 0;
  public tableHeight: string = '0px';
  dialogLabel = $localize`:dialog label@@zef-i18n-00269:Untitled survey`;

  private cfSub: any;

  @ViewChild('textAnswerTable') textAnswerTableRef: TextAnswerTable | undefined;

  @Select(SurveyState.activeSurvey())
  readonly survey$: Observable<SurveyModel>;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: any,
    readonly es: ExportService,
    readonly store: Store,
    readonly hooks: LifecycleHooks,
    readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.cfSub =
      this.data &&
      this.data.cf &&
      this.data.cf.crossfilter &&
      this.data.cf.crossfilter.subscribe((crossfilter) => {
        if (crossfilter != null) {
          this.filters = [];
          this.filterInput = this.data?.dimensions?.map((dim) => crossfilter.filters?.[dim?.key]?.['filter'] || null);
          this.responses = crossfilter.stats.respondents;
          for (const filter of Object.keys(crossfilter.filters)) {
            this.filters.push({
              key: filter,
              label: crossfilter.dimensions[filter].title,
              filter: crossfilter.filters[filter].filter,
              values: crossfilter.filters[filter].values,
            });
          }
          this.update = new Date();
          this.cdRef.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    if (this.cfSub) {
      this.cfSub.unsubscribe();
    }
  }

  public removeSelections() {
    const removedFilters: any[] = [];

    for (const filter of this.filters) {
      removedFilters.push({ key: filter.key, values: filter.values, filter: [] });
    }

    this.data.cf.filter(removedFilters);
  }

  public exportAs(type: string) {
    const dataTable = this.textAnswerTableRef?.getDataAsArray();
    const survey = this.store.selectSnapshot(SurveyState.activeSurvey()).survey;
    const surveyKey = survey['$key'];
    const surveyName = `${survey.name} ${new Date().getUTCDate()}.${
      new Date().getUTCMonth() + 1
    }.${new Date().getFullYear()}`;

    this.loadingExport = true;
    this.exportError = false;

    this.es.exportDataTable(type, dataTable, surveyKey, surveyName).subscribe((status) => {
      this.loadingExport = false;
      this.exportError = status === 'failed';
    });
  }

  public tableSizeChange($event) {
    this.tableHeight = $event?.dimensions?.height + 'px';
  }
}

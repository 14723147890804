import { AppProject, CloudLocation } from '@env/environment.models';

export const environment = {
  config: '',

  debug: true,

  website: false,

  production: false,

  firebase: {
    appId: 'zefApp',
    apiKey: 'AIzaSyBpXu4EnXxz6aVt15I49JvHJe4EAbLj-us',
    authDomain: `${AppProject.Testing}.firebaseapp.com`,
    databaseURL: `https://${AppProject.Testing}.firebaseio.com`,
  },

  publicUrl: '//test.zeffi.dev',

  apiServer: '//api.test.zeffi.dev',
  bckServer: '//proxy.api.test.zeffi.dev',

  webAddress: '//app.test.zeffi.dev',
  wwwAddress: '//www.test.zeffi.dev',

  surveyAddress: '//survey.test.zeffi.dev',
  reportAddress: '//report.test.zeffi.dev',
  storageAddress: '//storage.test.zeffi.dev',

  // cloudFunctions: 'https://app.test.zeffi.dev',
  cloudFunctions: `https://${CloudLocation.Europe}-${AppProject.Testing}.cloudfunctions.net`,

  activityServer: 'https://cases.zef.fi/zef/zef-platform/activity-log/',

  segmentClientKey: '',

  stripePublishableKey: 'pk_test_yieMgAEwuLiCqbwTHri3kmJr',

  googleBrowserKey: 'AIzaSyBpXu4EnXxz6aVt15I49JvHJe4EAbLj-us',

  googleClientId: '595229186990-ct8ogavj8o91eoccj25udhbsrt4i8e3a.apps.googleusercontent.com',

  unsplash: {
    appId: 185789,
  },

  zendesk: {
    helpDeskApi: 'https://zefhelp.zendesk.com/api/v2/help_center/',
  },
};

environment.website =
  typeof window === 'undefined'
    ? false
    : window.location.href.includes('playground.test.zeffi.dev') ||
      !!(window as any)['__NUXT__'] ||
      !!(window as any)['$nuxt'];

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Component, ChangeDetectionStrategy, ViewChild, OnInit } from '@angular/core';

import { environment } from '@env/environment';

import { Select, Actions, ofActionDispatched } from '@ngxs/store';

import { NavBarService } from '@home/shared/modules/nav-bar/nav-bar.service';

import { InviteData } from '@shared/models/account.model';
import { AccountState } from '@shared/states/account.state';
import { IdentityData } from '@shared/models/prefs.model';
import { OpenUserMenu } from '@shared/states/interaction.actions';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { OpenUserMenuOptions } from '@shared/states/interaction.models';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LifecycleHooks],
})
export class NavBarComponent implements OnInit {
  @Select(AccountState.identity)
  identity$!: Observable<IdentityData | null>;

  @Select(AccountState.invites)
  invites$!: Observable<InviteData[]>;

  @ViewChild(MatMenuTrigger)
  menuTrigger?: MatMenuTrigger;

  menuOptions: OpenUserMenuOptions = {};

  readonly website = environment.website;

  constructor(readonly nb: NavBarService, private actions: Actions, private lh: LifecycleHooks) {}

  ngOnInit(): void {
    this.actions
      .pipe(ofActionDispatched(OpenUserMenu), takeUntil(this.lh.destroy))
      .subscribe((action: OpenUserMenu) => {
        if (this.menuTrigger) {
          this.menuOptions = action.options;
          this.menuTrigger.openMenu();
        }
      });
  }
}

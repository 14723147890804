<!--
  Slider 2D state and widget elements.

  @unstable
-->

<div class="z-center-container" #container>
  <div
    class="z-container-box"
    [class.z-disabled]="disabled"
    [class.z-answered]="!!answerValue"
    [class.z-hovering]="hovering.getValue()"
    [class.z-dragging]="answering.getValue()"
    [class.z-without-y-title]="!data.sliderLabelsY?.axis"
    [class.z-without-y-min-max]="!data.sliderLabelsY?.min && !data.sliderLabelsY?.max"
    [class.z-without-x-title]="!data.sliderLabelsX?.axis"
    [class.z-without-x-min-max]="!data.sliderLabelsX?.min && !data.sliderLabelsX?.max"
    [class.z-intro]="showIntro | async"
    [ngClass]="'z-intro-' + introPhase.getValue()"
  >
    <div class="z-top-part">
      <slider-label
        [data]="data"
        [sliderLabels]="data.sliderLabelsY"
        [view]="view"
        [color]="introPhase.getValue() === 'y' ? (ss.colors | async).primary : (ss.colors | async).text"
        [shadow]="introPhase.getValue() === 'y' ? (ss.colors | async).text : ''"
        axis="Y"
      ></slider-label>

      <div
        #answerArea
        class="z-answer-area"
        [style.border-color]="(ss.colors | async).primary"
        (dragstart.cdn)="noop()"
        (touchmove.n)="onTouchMove($event)"
      >
        <div *ngIf="position | async as pos">
          <div
            *ngFor="let side of lineSides"
            ngClass="z-line z-line-{{ side }}"
            [style.border-color]="
              introPhase.getValue() === 'y' || introPhase.getValue() === 'x'
                ? (ss.colors | async).text
                : (ss.colors | async).primary
            "
          ></div>

          <ng-container *ngFor="let answer of groupAnswers | async">
            <slider-thumb
              *ngIf="getPositionFromValue(answer.val) as pos"
              class="z-scored-thumb"
              [style.top.%]="pos.top * 100"
              [style.left.%]="pos.left * 100"
              [style.background-color]="(ss.colors | async).text50"
              [style.color]="(ss.colors | async).textContrast"
              [questionNumber]="answer.no"
            ></slider-thumb>
          </ng-container>

          <ng-container *ngIf="(hovering.getValue() || answering.getValue()) && (currentValue | async) as cv">
            <div
              *ngFor="let axis of axes; let i = index"
              ngClass="z-indicator-wrapper-{{ axis }}"
              [style.transform]="getTransformPos(pos, axis)"
            >
              <div ngClass="z-thumb-line z-thumb-line-{{ axis }}" [style.border-color]="(ss.colors | async).primary50">
                <div
                  class="z-thumb-indicator"
                  [class.z-show-value]="data.sliderAnswer !== false"
                  [style.background-color]="(ss.colors | async).button"
                  [style.color]="(ss.colors | async).buttonContrast"
                >
                  <ng-container *ngIf="data.sliderAnswer !== false">{{ cv[i] }}</ng-container>
                </div>

                <img
                  *ngIf="data.sliderSmileys && getSmiley(axis, pos) as smiley"
                  ngClass="z-smiley z-smiley-{{ axis }}"
                  [src]="smiley"
                />
              </div>
            </div>
          </ng-container>

          <div class="z-thumb-wrapper" [style.transform]="getTransformPos(pos)">
            <div
              *ngIf="(showIntro | async) && (introPhase | async) === 'thumb'"
              class="z-thumb-bounce"
              [style.backgroundColor]="(ss.colors | async).primary50"
              [style.width.px]="thumb?.nativeElement.offsetWidth"
              [style.height.px]="thumb?.nativeElement.offsetHeight"
              @introThumb
              (@introThumb.done)="introPhase.getValue() === 'thumb' && introPhase.next('x')"
            ></div>

            <slider-thumb
              #SliderThumb
              class="z-thumb-knob"
              [color]="(ss.colors | async).primary"
              [checked]="!!answerValue && !answering.getValue()"
            ></slider-thumb>
          </div>

          <ng-container *ngIf="!website && (showIntro | async) && introPhase.getValue() as phase">
            <ng-container *ngFor="let axis of axes">
              <div
                *ngIf="phase === axis"
                @introAxes
                (@introAxes.done)="phase === axis && introPhase.next(axis === 'x' ? 'y' : 'thumb')"
              >
                <div
                  *ngFor="let side of axis === 'x' ? sidesX : sidesY"
                  ngClass="z-thumb-arrow z-thumb-arrow-{{ side }}"
                >
                  <div *ngFor="let num of arrows" ngClass="z-thumb-arrow-side z-thumb-arrow-{{ num }}">
                    <div
                      *ngFor="let head of heads"
                      [style.border-color]="(ss.colors | async).primary"
                      [style.background-color]="(ss.colors | async).primary"
                      ngClass="z-thumb-arrow-side-head z-thumb-arrow-side-head-{{ head }}"
                    ></div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="z-bottom-part">
      <div class="z-bottom-spacer"></div>
      <slider-label
        [data]="data"
        [sliderLabels]="data.sliderLabelsX"
        [color]="(introPhase | async) === 'x' ? (ss.colors | async).primary : (ss.colors | async).text"
        [shadow]="(introPhase | async) === 'x' ? (ss.colors | async).text : ''"
        [view]="view"
        axis="X"
      ></slider-label>
    </div>
  </div>
</div>

<slider-images [images]="smileysX || smileysY"></slider-images>

<!--
  Offline view with static content.

  @unstable
-->

<div
  class="z-fx-col z-fx-fill z-fx-between-center z-container-box"
  [style.background-image]="'assets/decos/shape-decoration.png' | encodeURI : 'url'"
>
  <div class="z-fx-col z-fx-center-center z-fx-gap-24 z-header-box">
    <img width="120px" height="120px" src="assets/images/broken-link.png" />

    <span class="z-title-text" i18n>This report is not available.</span>
  </div>

  <div class="z-fx-col z-fx-gap-24 z-fx-between-center z-content-box">
    <div i18n>Create your own survey</div>

    <a class="z-fx z-fx-center-center z-start-button" href="{{ Environment.webAddress }}/surveys" target="_blank" i18n>
      Get started free
    </a>

    <a class="z-fx z-fx-center-center z-fx-gap-8 z-zeffi-logo" href="http://zef.fi" target="_blank">
      <mat-icon>zef_logo</mat-icon>
    </a>
  </div>
</div>

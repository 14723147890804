/**
 * Content state change animations.
 *
 * @stable
 */

import { animate, style, transition, trigger } from '@angular/animations';

export const ContentAnimations = trigger('contentState', [
  transition('switching => outcomes', [
    style({
      opacity: 0,
    }),
    animate(
      250,
      style({
        opacity: 1,
      }),
    ),
  ]),

  transition('switching => questions', [
    style({
      opacity: 0,
    }),
    animate(
      250,
      style({
        opacity: 1,
      }),
    ),
  ]),

  transition('outcomes => switching', [
    style({
      opacity: 1,
    }),
    animate(
      250,
      style({
        opacity: 0,
      }),
    ),
  ]),

  transition('questions => switching', [
    style({
      opacity: 1,
    }),
    animate(
      250,
      style({
        opacity: 0,
      }),
    ),
  ]),
]);

import { AppProject, CloudLocation } from '@env/environment.models';

export const environment = {
  config: '',

  debug: true,

  website: false,

  production: true,

  firebase: {
    appId: 'zefApp',
    apiKey: 'AIzaSyDhIW_5hcUDcdBVFkM5G-X6pgVkvswsQgg',
    authDomain: `${AppProject.Staging}.firebaseapp.com`,
    databaseURL: `https://${AppProject.Staging}.firebaseio.com`,
  },

  publicUrl: '//zeffi.dev',

  apiServer: '//api.zeffi.dev',
  bckServer: '//proxy.api.zeffi.dev',

  webAddress: '//app.zeffi.dev',
  wwwAddress: '//www.zeffi.dev',

  surveyAddress: '//survey.zeffi.dev',
  reportAddress: '//report.zeffi.dev',
  storageAddress: '//storage.zeffi.dev',

  // cloudFunctions: 'https://app.zeffi.dev',
  cloudFunctions: `https://${CloudLocation.US}-${AppProject.Staging}.cloudfunctions.net`,

  activityServer: 'https://cases.zef.fi/zef/zef-platform/activity-log/',

  segmentClientKey: '4fRy4vdTesXy0vyo2SIZmlFB0kNcnsmU',

  stripePublishableKey: 'pk_test_yieMgAEwuLiCqbwTHri3kmJr',

  googleBrowserKey: 'AIzaSyDhIW_5hcUDcdBVFkM5G-X6pgVkvswsQgg',

  googleClientId: '698057269977-vv4b2f6h7sp3a6su9b63nna3vt4ujpm1.apps.googleusercontent.com',

  unsplash: {
    appId: 185789,
  },

  zendesk: {
    helpDeskApi: 'https://zefhelp.zendesk.com/api/v2/help_center/',
  },
};

environment.website =
  typeof window === 'undefined'
    ? false
    : window.location.href.includes('playground.zeffi.dev') ||
      !!(window as any)['__NUXT__'] ||
      !!(window as any)['$nuxt'];

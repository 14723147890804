import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  scan,
  shareReplay,
  startWith,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  Directive,
  HostListener,
  ContentChild,
  ViewContainerRef,
  NgModule,
  TemplateRef,
  NgZone,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostBinding,
  EmbeddedViewRef,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Directive({
  selector: '[zefAccordionClosedContent]',
})
export class AccordionClosedContent {}

@Directive({
  selector: '[zefAccordionOpenedContent]',
})
export class AccordionOpenedContent {}

@Directive({
  selector: '[zefAccordionHeaderContent]',
})
export class AccordionHeaderContent {}

type Content<T = any> = TemplateRef<T> | null;

type AccordionContents = { header: Content; closed: Content; opened: Content };

@Directive({
  selector: '[zefAccordion]',
  exportAs: 'zefAccordion',
  providers: [LifecycleHooks],
})
export class Accordion implements AfterViewInit {
  @ContentChild(AccordionHeaderContent, { read: TemplateRef })
  set headerContent(header: Content) {
    this.newContent$.next({ header });
  }

  @ContentChild(AccordionClosedContent, { read: TemplateRef })
  set closedContent(closed: Content) {
    this.newContent$.next({ closed });
  }

  @ContentChild(AccordionOpenedContent, { read: TemplateRef })
  set openedContent(opened: Content) {
    this.newContent$.next({ opened });
  }

  private readonly newContent$ = new Subject<Partial<AccordionContents>>();

  private readonly availableContent$ = this.newContent$.pipe(
    startWith({}),
    scan((a, b) => ({ ...a, ...b }), { header: null, closed: null, opened: null }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<{ header: Content; closed: Content; opened: Content }>;

  @Input()
  set active(active: boolean) {
    this.active$.next(!!active);
  }

  get open(): boolean {
    return this.open$.getValue();
  }
  @Input()
  set open(open: boolean) {
    this.open$.next(!!open);
  }

  @Input()
  transitionSpeed: number = 150;

  @Input()
  transitionFunction: string = 'ease-in-out';

  @Output()
  readonly toggled = new EventEmitter<boolean>();

  private readonly active$ = new BehaviorSubject(true);
  private readonly open$ = new BehaviorSubject(false);

  private get parent() {
    return this.vr.element.nativeElement.parentElement;
  }

  private animationTimer: number = 0;

  private headerRef: EmbeddedViewRef<any> | void;

  private firstShow = true;

  constructor(
    readonly vr: ViewContainerRef,
    readonly zone: NgZone,
    readonly cd: ChangeDetectorRef,
    readonly lh: LifecycleHooks,
  ) {
    combineLatest([this.open$.pipe(distinctUntilChanged()), this.active$.pipe(distinctUntilChanged())])
      .pipe(
        debounceTime(1),
        withLatestFrom(this.availableContent$),
        tap(([[open, active], { header, opened, closed }]) => {
          const firstShow = this.firstShow;

          if (this.headerRef && header) {
            for (let i = this.vr.length - 1; i > 0; i--) {
              const ref = this.vr.get(i);

              if (ref && ref !== this.headerRef) {
                this.vr.remove(i);
              }
            }
          } else {
            this.vr.clear();
          }

          if (!firstShow) {
            this.zone.runOutsideAngular(() => this.animateHeight());
          } else {
            this.firstShow = false;
          }

          if (header && !this.headerRef) {
            this.headerRef = this.vr.createEmbeddedView(header);
          } else if (!header) {
            this.headerRef = null;
          }

          if (active) {
            if (open && opened) {
              this.vr.createEmbeddedView(opened);
            } else if (!open && closed) {
              this.vr.createEmbeddedView(closed);
            }
          }

          if (!firstShow) {
            this.toggled.emit(active && open);
          }

          this.cd.detectChanges();
        }),
        takeUntil(this.lh.destroy),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    const parent = this.parent;
    getComputedStyle(parent);
    parent.style.boxSizing = 'border-box';
  }

  toggle(): void {
    this.open$.next(!this.open$.getValue());
  }

  private animateHeight(): void {
    const parent = this.parent;

    const style = getComputedStyle(parent);
    const oldHeight = style.height;
    parent.style.height = oldHeight;
    parent.style.overflow = 'hidden';

    setTimeout(() => {
      clearTimeout(this.animationTimer);

      parent.style.transition = 'none';
      parent.style.height = '0px';

      const newHeight = parent.scrollHeight + 'px';
      parent.style.height = oldHeight;

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      parent.offsetHeight;

      parent.style.transition = `height ${this.transitionSpeed}ms ${this.transitionFunction}`;
      parent.style.height = newHeight;

      this.animationTimer = window.setTimeout(() => {
        parent.style.height = '';
        parent.style.overflow = '';
      }, this.transitionSpeed);
    });
  }
}

@Directive({
  selector: '[zefAccordionToggle]',
})
export class AccordionToggle {
  @HostListener('click', ['$event']) onClick = (event: MouseEvent) => {
    event.stopPropagation();
    this.accordion.toggle();
  };

  @HostBinding('class.open')
  get isOpen() {
    return this.accordion.open;
  }

  constructor(readonly accordion: Accordion) {}
}
@NgModule({
  imports: [CommonModule],
  declarations: [Accordion, AccordionToggle, AccordionHeaderContent, AccordionClosedContent, AccordionOpenedContent],
  exports: [Accordion, AccordionToggle, AccordionHeaderContent, AccordionClosedContent, AccordionOpenedContent],
})
export class AccordionModule {}

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Select, Store } from '@ngxs/store';

import { ActivatedRoute } from '@angular/router';
import {
  Component,
  OnChanges,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { trigger, transition, animate } from '@angular/animations';

import { SurveyState } from '@shared/states/survey.state';
import { SurveyModel, SurveyData } from '@shared/models/survey.model';

import { UnlockAnswers } from '@home/shared/dialogs/unlock-answers/unlock-answers.dialog';

import { totalLocked } from '@shared/states/billing.functions';
import { GetUsage } from '@shared/states/survey.actions';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';

import { Rights } from '@shared/enums/rights.enum';
import { OpenDialog } from '@shared/states/dialog.actions';
import { LockedResource } from '@shared/models/billing.model';

/**
 * This is stats view component which handles showing of statistics.
 */
@Component({
  selector: 'stats-view',
  templateUrl: './stats-view.component.html',
  styleUrls: ['./stats-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('allRespondents', [transition(':enter', [animate('750ms')]), transition(':leave', [animate('750ms')])]),
    trigger('selected', [transition('inactive <=> active', [animate('750ms')])]),
  ],
})
export class StatsView implements OnChanges {
  readonly subjects = HelpSubject;
  readonly Rights = Rights;

  @Input() stats: any;
  @Input() filters: any;
  @Input() filtersDemo: boolean = false;
  @Input() defaultSurvey: string = '';
  @Input() includedSurveys: SurveyData[] = [];
  @Input() surveyRights: number = 0;
  @Input() userRights: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() anonymityTreshold: number = null;
  @Output() closeComparison = new EventEmitter<any>();
  @Output() removeSurvey = new EventEmitter<SurveyData>();

  public selectedSurvey: string = '';
  public selectedReport: string = '';

  public respondents: number | null = null;
  public respondentsAll: number | null = null;
  public sampleData: boolean | null = null;
  public consensus: number | null = null;

  public safeReportLock: boolean = false;

  public showSurveys: boolean = false;

  @Select(SurveyState.activeSurvey())
  survey$!: Observable<SurveyModel>;

  surveyUsage = this.survey$.pipe(
    map((data) => (data ? data.usage : null)),
    filter((usage) => !!usage),
    map((usage) => ({
      lockedAnswers: totalLocked(usage.answers),
    })),
  );

  constructor(private route: ActivatedRoute, readonly store: Store, private cdRef: ChangeDetectorRef) {
    this.selectedSurvey = this.route.snapshot.params['survey'];
    this.store.dispatch(new GetUsage(this.selectedSurvey));
  }

  ngOnChanges() {
    if (this.stats && this.stats.respondents != null) {
      this.respondents = !this.filtersDemo ? this.stats.respondents : 0;
      this.respondentsAll = this.stats.respondentsAll;
      this.sampleData = this.stats.sampleData;
      this.consensus = Math.round(this.stats.consensus * 100);
    }

    this.checkAnonymity();

    this.selectedSurvey = this.route.snapshot.params['survey'];
    this.selectedReport = this.route.snapshot.params['report'] || '';
    this.cdRef.detectChanges();
  }

  private checkAnonymity() {
    this.safeReportLock = false;

    for (const filterKey in this.filters || {}) {
      if (this.filters[filterKey]?.safeReportCount != null) {
        this.safeReportLock = true;
        this.respondents = this.filters[filterKey]?.safeReportCount;
      }
    }
  }

  public closeComp() {
    this.closeComparison.emit(true);
  }

  public unlockAnswers(unlock: number) {
    this.store.dispatch(
      new OpenDialog(UnlockAnswers, {
        data: {
          lockSource: this.selectedSurvey,
          sourceType: 'survey',
          lockedAnswers: unlock,
        } as LockedResource,
      }),
    );
  }
}

import {
  IntegrationService,
  IntegrationConfig,
  IntegrationListLink,
  ApiKey,
  IntegrationPropertyLink,
  IntegrationSurveyLink,
  IntegrationSurveySyncParams,
  IntegrationRequestForm,
  CustomIntegration,
  ServiceIntegration,
} from '@shared/models/integrations.model';

export class GetApiKeys {
  static readonly type = '[Integrations] - Get API Keys';
}

export class GenerateApiKey {
  static readonly type = '[Integrations] - Generate API Key';
}

export class DeleteApiKey {
  static readonly type = '[Integrations] - Delete API Key';

  constructor(readonly key: string) {}
}

export class UpdateApiKey {
  static readonly type = '[Integrations] - Update API Key';

  constructor(readonly key: string, readonly update: Partial<ApiKey>) {}
}

export class GetServiceIntegrations {
  static readonly type = '[Integrations] - Get Service Integrations';
}

export class GetIntegrationsTemplates {
  static readonly type = '[Integrations] - Get Integrations Templates';
}

export class RequestIntegration {
  static readonly type = '[Integrations] - Request Service Integration';

  constructor(readonly data: IntegrationRequestForm) {}
}

export class AuthenticateIntegration {
  static readonly type = '[Integrations] - Authenticate Service Integration';

  constructor(readonly service: any) {}
}

export class AuthenticateIntegrationTask {
  static readonly type = '[Integrations] - Authenticate Integration Task';

  constructor(readonly service: string, readonly integrationId: string) {}
}

export class CreateCustomIntegration {
  static readonly type = '[Integrations] - Create Custom Integration';

  constructor(readonly service: IntegrationService) {}
}

export class FinalizeAuthentication {
  static readonly type = '[Integrations] - Finalize Service Integration Authentication';

  constructor(readonly integrationId: string, readonly code: string) {}
}

export class ToggleIntegrationConnection {
  static readonly type = '[Integrations] - Toggle Service Integration Connection';

  constructor(readonly integrationId: string, readonly connected: boolean) {}
}

export class DeleteIntegration {
  static readonly type = '[Integrations] - Delete Service Integration';

  constructor(readonly integrationId: string) {}
}

export class GetIntegrationLists {
  static readonly type = '[Integrations] - Get Integration Lists';

  constructor(readonly integrationId: string) {}
}

export class GetIntegrationProperties {
  static readonly type = '[Integrations] - Get Integration Contact Properties';

  constructor(readonly integrationId: string) {}
}

export class SaveSyncIntegration {
  static readonly type = '[Integrations] - Save Sync Integration';

  constructor(readonly integrationId: string, readonly config: IntegrationConfig) {}
}

export class UpdateListIntegration {
  static readonly type = '[Integrations] - Update List Integration';

  constructor(readonly integrationId: string, readonly link: IntegrationListLink, readonly createList?: boolean) {}
}

export class UpdatePropertyIntegration {
  static readonly type = '[Integrations] - Update Property Integration';

  constructor(readonly integrationId: string, readonly link: IntegrationPropertyLink) {}
}

export abstract class RemoveLinkIntegration {
  constructor(readonly integrationId: string, readonly linkId: string) {}
}

export class RemoveListIntegration extends RemoveLinkIntegration {
  static readonly type = '[Integrations] - Remove List Integration';
}

export class RemovePropertyIntegration extends RemoveLinkIntegration {
  static readonly type = '[Integrations] - Remove Property Integration';
}

export class CancelSyncIntegration {
  static readonly type = '[Integrations] - Cancel Sync Integration';

  constructor(readonly integrationId: string) {}
}

export class ImportListFromIntegration {
  static readonly type = '[Integrations] - Import Missing List Contacts From Integration';

  constructor(readonly integrationId: string, readonly linkListId: string) {}
}

export class ExportListFromIntegration {
  static readonly type = '[Integrations] - Export Missing List Contacts To Integration';

  constructor(readonly integrationId: string, readonly linkListId: string) {}
}

export class SaveSurveyIntegration {
  static readonly type = '[Integrations] - Save survey integration';

  constructor(readonly integrationId: string, readonly surveyKey: string, readonly link: IntegrationSurveyLink) {}
}

export class SyncSurveyIntegration {
  static readonly type = '[Integrations] - Sync survey integration';

  constructor(
    readonly integrationId: string,
    readonly surveyKey: string,
    readonly params?: IntegrationSurveySyncParams,
  ) {}
}

export class ToggleSurveyIntegration {
  static readonly type = '[Integrations] - Toggle survey integration';

  constructor(readonly integrationId: string, readonly surveyKey: string, readonly active: boolean) {}
}

export class DeleteSurveyIntegration {
  static readonly type = '[Integrations] - Delete survey integration';

  constructor(readonly integrationId: string, readonly surveyKey: string) {}
}

export class DeleteSurveyIntegrationPropertyLink {
  static readonly type = '[Integrations] - Delete survey integration property link';

  constructor(readonly integrationId: string, readonly surveyKey: string, readonly linkKey: string) {}
}

export class ConfigureCustomService {
  static readonly type = '[Integrations] - Configure Custom Service';

  constructor(readonly service: CustomIntegration) {}
}

export class ConnectPartnerService {
  static readonly type = '[Integrations] - Connect Partner Service';

  constructor(readonly service: CustomIntegration) {}
}

export class DisconnectPartnerService {
  static readonly type = '[Integrations] - Disconnect Partner Service';

  constructor(readonly service: CustomIntegration) {}
}

export class RecreateApiKey {
  static readonly type = '[Integrations] - Recreate Api Key';

  constructor(readonly service: CustomIntegration) {}
}

export class SetToken {
  static readonly type = '[Integrations] Set Token';
  constructor(readonly token: string | null) {}
}

export class UpdateCustomIntegrationConfig {
  static readonly type = '[Integrations] Update Custom Integration Config';
  constructor(readonly integrationId: string, readonly update: Record<string, any>) {}
}

export class UpdateCustomIntegration {
  static readonly type = '[Integrations] Update Custom Integration';
  constructor(readonly integrationId: string, readonly update: Partial<IntegrationConfig>) {}
}

export class AuthorizeCustomIntegration {
  static readonly type = '[Integrations] Authorize Custom Integration';
  constructor(readonly integrationId: string, readonly update: Partial<IntegrationConfig>) {}
}

export class RenameIntegration {
  static readonly type = '[Integrations] RenameIntegration Integration';
  constructor(readonly integrationId: string, readonly name: string) {}
}

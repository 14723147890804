import { Observable } from 'rxjs';

import { Router } from '@angular/router';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Dialog, DialogConfig } from '@angular/cdk/dialog';

import {
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { BaseOverlay } from '@shared/components/base-overlay/base-overlay.component';
import { SidenavConfig } from '@shared/modules/sidenav/models/sidenav.models';

@Directive({
  selector: '[zefSidenav]',
})
export class SidenavDirective<T = any> extends BaseOverlay<T> implements OnChanges, OnInit {
  private readonly defaultWidth = 800;

  private readonly defaultPosition: 'left' | 'right' = 'right';

  private readonly defaultPanelClass = 'zef-sidenav';

  private readonly defaultBackdropClass = 'zef-sidenav-backdrop';

  private readonly scrimBackdropClass = 'cdk-overlay-dark-backdrop';

  private readonly transparentBackdropClass = 'cdk-overlay-transparent-backdrop';

  protected config: DialogConfig = {
    hasBackdrop: true,
    positionStrategy: new GlobalPositionStrategy().top('0').bottom('0'),
    maxWidth: '100vw',
    height: '100%',
    panelClass: this.defaultPanelClass,
    backdropClass: this.defaultBackdropClass as any,
    autoFocus: true,
    disableClose: true,
  };

  @Output('zefSidenavClose')
  readonly close = new EventEmitter<T>();

  @Output('zefSidenavAfterClosed')
  readonly afterClosed = new EventEmitter<void>();

  @Input('zefSidenavPosition')
  position: 'left' | 'right' = this.defaultPosition;

  @Input('zefSidenavWidth')
  width: number | string = this.defaultWidth;

  @Input('zefSidenavScrim')
  scrim: boolean = true;

  @Input('zefSidenavBackdrop')
  backdrop: boolean = true;

  @Input('zefSidenav')
  open?: boolean;

  @Input('zefWaitClose')
  waitClose?: Observable<boolean>;

  @Input('zefSidenavConfig')
  sidenavConfig?: SidenavConfig;

  get content(): TemplateRef<any> {
    return this.tr;
  }

  constructor(private tr: TemplateRef<any>, dialog: Dialog, vc: ViewContainerRef, router: Router) {
    super(dialog, vc, router);
  }

  closeSidenav(value?: T): void {
    this.closeDialog(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.config.viewContainerRef = this.sidenavConfig.viewContainerRef;
    }

    if (changes.width) {
      this.setWidth();
    }

    if (changes.position) {
      this.setPosition();
    }

    if (changes.scrim) {
      this.setScrim();
    }

    if (changes.backdrop) {
      this.setBackdrop();
    }

    super.ngOnChanges(changes);
  }

  ngOnInit(): void {
    this.setWidth();
    this.setPosition();
    this.setScrim();
  }

  private setWidth(): void {
    this.width = this.width ?? this.defaultWidth;
    this.config.width = typeof this.width === 'number' ? `${this.width}px` : this.width;
  }

  private setPosition(): void {
    this.position = this.position || this.defaultPosition;

    this.config.positionStrategy = new GlobalPositionStrategy().top('0')[this.position]('0');
    this.setClasses();
  }

  private setScrim(): void {
    this.setClasses();
  }

  private setBackdrop(): void {
    this.config.hasBackdrop = !!this.backdrop;
  }

  private setClasses(): void {
    this.config.backdropClass = [
      this.defaultBackdropClass,
      this.scrim ? this.scrimBackdropClass : this.transparentBackdropClass,
    ] as any;

    this.config.panelClass = [this.defaultPanelClass, this.position];
  }
}

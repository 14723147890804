<div *ngIf="stats && !stats.comparison" class="z-fx-col z-fx-auto">
  <div class="z-fx-col z-fx-auto respondents-container" [class.safe-report-lock]="safeReportLock">
    <div class="z-fx z-fx-start-start totalAnswers">
      <span
        numberTransition
        class="selected"
        [class.active]="respondentsAll > respondents"
        [data]="respondents"
        [duration]="750"
        [@selected]="respondentsAll > respondents ? 'active' : 'inactive'"
      ></span>
      <div *ngIf="respondentsAll > respondents" class="all" [@allRespondents]>/ {{ respondentsAll }}</div>
    </div>
    <div class="z-fx z-fx-start-center zef-text-p2 label">
      <ng-container *ngIf="sampleData" i18n>Sample Respondents</ng-container>
      <ng-container *ngIf="!sampleData" i18n>Respondents</ng-container>
    </div>

    <ng-container *ngIf="surveyUsage | async as usage">
      <a
        *ngIf="usage.lockedAnswers"
        (click)="unlockAnswers(usage.lockedAnswers)"
        class="zef-text-p2-link-muted locked-answers"
      >
        <ng-container *ngIf="sampleData" i18n>Unlock {{ usage.lockedAnswers }} Responses</ng-container>
        <ng-container *ngIf="!sampleData" i18n>Unlock {{ usage.lockedAnswers }} more</ng-container>
      </a>
    </ng-container>
    <ng-container *ngIf="!isSharedReport && includedSurveys && includedSurveys.length > 1">
      <div class="surveys-content">
        <div (click)="showSurveys = !showSurveys" class="zef-text-p2-link-muted toggle-link" i18n>
          from {{ includedSurveys.length }} surveys
        </div>
        <div *ngIf="showSurveys" class="z-fx-col z-fx-gap-8 surveys-container">
          <div *ngFor="let survey of includedSurveys" class="z-fx z-fx-start-center z-fx-gap-8 survey">
            <div
              class="z-fx-auto survey-name"
              [matTooltip]="(survey?.name || '').length > 40 ? survey.name : ''"
              matTooltipPosition="above"
            >
              {{ survey?.name }}
            </div>
            <mat-icon
              *ngIf="
                survey['$key'] !== defaultSurvey &&
                (Rights.hasRights(Rights.EDIT, surveyRights || 0) || Rights.hasRights(Rights.ADMIN, userRights || 0)) &&
                !isSharedReport
              "
              class="delete-icon"
              (click)="removeSurvey.emit(survey)"
              matTooltip="Remove survey"
            >
              action_delete
            </mat-icon>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <mat-divider *ngIf="!safeReportLock && consensus"></mat-divider>

  <div *ngIf="safeReportLock" class="z-fx z-fx-gap-16 anonymity-info">
    <div class="z-fx-col z-fx-gap-8">
      <div class="zef-text-h3 title" i18n>Select {{ anonymityTreshold || 5 }} or more respondents.</div>
      <div class="zef-text-p2 description" i18n>Cross-filtering is disabled to protect respondents’ anonymity.</div>
    </div>
    <mat-icon class="anonymity-icon">anonymous_on</mat-icon>
  </div>

  <div *ngIf="!safeReportLock && consensus" class="z-fx-auto consensus-container">
    <div class="zef-text-b2 consensus" [class.filtered]="respondentsAll > respondents && !safeReportLock">
      <span numberTransition [data]="consensus" [duration]="750"></span>
      %
    </div>
    <div class="z-fx z-fx-start-center zef-text-p2 label">
      <span i18n>Consensus</span>

      <zef-help-icon [subject]="subjects.AnalyzeReportConsensus"></zef-help-icon>
    </div>
  </div>
</div>

<!-- <zef-panel #addRespondentData [width]="'800px'" [scrim]="true" [align]="'right'">
  <report-merge-respondents></report-merge-respondents>
</zef-panel> -->

<div class="container-box z-fx-col z-fx-auto">
  <ng-scrollbar [autoHeightDisabled]="false">
    <div class="content-box" [class.z-mobile]="isMobile$ | async">
      <div *ngIf="templateFilter$ | async as filter" class="z-templates zef-content-box z-fx-col z-fx-gap-40">
        <header class="z-fx z-fx-around-center">
          <h1 class="zef-text-h1" i18n>Ready Surveys</h1>
          <div class="z-search-box z-fx z-fx-gap-8">
            <zef-searchbox style="width: 400px" [space]="false" (input)="filterByTerm($event)">
              <span i18n>Find...</span>
            </zef-searchbox>

            <button
              *ngIf="!filter.visible"
              mat-raised-button
              color="primary"
              class="zef-combo-button"
              (click)="toggleFilter(true)"
            >
              <mat-icon>filter</mat-icon>
              <span i18n>Filter</span>
            </button>

            <button
              *ngIf="filter.visible"
              mat-raised-button
              color="stealth"
              class="zef-combo-button"
              (click)="toggleFilter(false)"
            >
              <mat-icon>close</mat-icon>
              <span i18n>Filter</span>
            </button>
          </div>
        </header>

        <div *ngIf="filter.visible" class="z-filter-box z-fx z-fx-gap-8">
          <div class="z-slider z-fx z-fx-gap-8 z-fx-grow">
            <div class="z-fx z-fx-start-center">
              <strong class="zef-text-h3" i18n>Short & Sweet</strong>
              <zef-help-icon [subject]="subjects.DiscoverShortSweet"></zef-help-icon>
            </div>

            <mat-slider
              [style.max-width.px]="300"
              color="primary"
              min="-100"
              max="100"
              [value]="filter.complexity || 0"
              (input)="filterByComplexity($event.value)"
            ></mat-slider>

            <div class="z-fx z-fx-start-center">
              <strong class="zef-text-h3" i18n>Detailed & Deep</strong>
              <zef-help-icon [subject]="subjects.DiscoverDetailedDeep"></zef-help-icon>
            </div>
          </div>

          <zef-select [value]="filter.language || ''" (change)="filterByLanguage($event)">
            <zef-select-option value="">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <mat-icon>world</mat-icon>
                <strong class="zef-text-p2" i18n>Any</strong>
              </div>
            </zef-select-option>

            <zef-select-option value="en">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <figure class="zef-flag-small zef-lang-en"></figure>
                <strong class="zef-text-p2" i18n>English</strong>
              </div>
            </zef-select-option>

            <zef-select-option value="fi">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <figure class="zef-flag-small zef-lang-fi"></figure>
                <strong class="zef-text-p2" i18n>Finnish</strong>
              </div>
            </zef-select-option>

            <zef-select-option value="sv">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <figure class="zef-flag-small zef-lang-sv"></figure>
                <strong class="zef-text-p2" i18n>Swedish</strong>
              </div>
            </zef-select-option>
          </zef-select>

          <zef-select [value]="filter.category || ''" (change)="filterByCategory($event)">
            <zef-select-option value="">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <strong class="zef-text-p2" i18n>All Categories</strong>
              </div>
            </zef-select-option>

            <zef-select-option *ngFor="let cat of categories$ | async" [value]="cat.$key">
              <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
                <strong class="zef-text-p2" i18n>{{ cat.name }}</strong>
              </div>
            </zef-select-option>
          </zef-select>
        </div>

        <div class="discover-grid">
          <discover-thumb
            class="auto-width"
            *ngFor="let template of templates$ | async as templates; trackBy: trackByFn"
            [template]="template"
            (click)="discoverTemplate(template.key, templates)"
          ></discover-thumb>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>

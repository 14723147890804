/**
 * Switch dialog with invites handling.
 *
 * @unstable
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Select } from '@ngxs/store';
import { Rights } from '@shared/enums/rights.enum';
import { InviteData, TeamData, UserData } from '@shared/models/account.model';
import { IdentityData } from '@shared/models/prefs.model';
import { AccountManager } from '@shared/services/account-manager.service';
import { AccountState } from '@shared/states/account.state';
import { sortByTime } from '@shared/utilities/array.utilities';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { first, map, pairwise, tap } from 'rxjs/operators';

export interface SwitchTeamDialogData {
  activeTeam: string;
  identityData: IdentityData;
  userData: UserData;
  redirect?: string;
}

@Component({
  selector: 'switch-team',
  templateUrl: './switch-team.dialog.html',
  styleUrls: ['./switch-team.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchTeam implements OnInit {
  readonly Rights = Rights;

  showAllTeams = false;
  showAllInvites = false;

  public userTeams: string[] = [];

  public acceptedInvites: InviteData[] = [];
  public rejectedInvites: InviteData[] = [];
  public teamsData: Observable<TeamData[]> | null = null;
  public filteredTeams: Observable<TeamData[]> | null = null;
  public filteredInvites: Observable<InviteData[]> | null = null;
  public identity: IdentityData | null = null;
  search = new BehaviorSubject('');

  @Select(AccountState.invites)
  readonly invitesData$: Observable<InviteData[]>;
  public dataLoaded$: Observable<boolean> | null = null;

  dialogTitle = $localize`:dialog title@@zef-i18n-00164:Switch Organization`;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SwitchTeamDialogData,
    private cdRef: ChangeDetectorRef,
    private am: AccountManager,
  ) {}

  ngOnInit(): void {
    this.identity = this.data.identityData;

    const userTeams = Object.entries(this.data.userData?.teams || {})
      .map(([teamKey, teamRight]) => (teamRight > 0 ? teamKey : null))
      .filter((teamKey) => teamKey !== null);

    const teamsData: Observable<TeamData[]> = forkJoin(
      userTeams.map((teamKey) => this.am.loadTeam(teamKey).pipe<TeamData>(first())),
    ).pipe(
      map((teams: TeamData[]) => {
        const teamsWithTime = teams.map((data) => {
          const teamKey = this.data.userData.identities?.[data.$key];
          const time = teamKey?.lastLogin || 0;
          return { ...data, time };
        });

        const teamsByTime = sortByTime(teamsWithTime).filter((data) => data.$key !== this.data.activeTeam);
        const activeTeam = teamsWithTime.find((data) => data.$key === this.data.activeTeam);

        return [activeTeam, ...teamsByTime];
      }),
    );

    this.filteredTeams = combineLatest([teamsData, this.search]).pipe(
      map(([teams, query]) => teams.filter((team) => (team?.name || '').toLowerCase().includes(query.toLowerCase()))),
    );

    this.filteredInvites = combineLatest([this.invitesData$.pipe(first()), this.search]).pipe(
      map(([invites]) => invites.filter((invite) => !this.rejectedInvites.includes(invite))),
      map((invites: InviteData[]) => sortByTime(invites)),
    );

    this.dataLoaded$ = combineLatest([teamsData, this.invitesData$]).pipe(map(() => true));
  }

  public onRefresh(): void {
    this.cdRef.markForCheck();
  }

  updateAccepted(data: InviteData) {
    this.acceptedInvites.push(data);
    this.search.next('');
  }

  updateRejected(data: InviteData) {
    this.rejectedInvites.push(data);
    this.search.next('');
  }

  updateSearch(query: string) {
    this.showAllTeams = true;
    this.search.next(query);
  }
}

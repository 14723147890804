import { Injectable } from '@angular/core';

/**
 * This service will convert filters from chart to filters used in data backend API queries.
 */
@Injectable({
  providedIn: 'root',
})
export class InfoBanner {
  public survey: string | null = null;
  public showOpenLastReportBanner: boolean = true;

  constructor() {}

  public setParams(params: any) {
    if (params.survey !== this.survey) {
      this.survey = params.survey;

      if (params.report) {
        this.showOpenLastReportBanner = false;
      } else {
        this.showOpenLastReportBanner = true;
      }
    } else {
      if (params.report) {
        this.showOpenLastReportBanner = false;
      }
    }
  }

  public resetParams() {
    this.survey = null;
    this.showOpenLastReportBanner = true;
  }
}

import { delay, map, startWith, switchMap, tap } from 'rxjs/operators';
import { combineLatest, merge, Observable, ReplaySubject } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, QueryList, ViewChildren } from '@angular/core';

import { Select } from '@ngxs/store';

import {
  IntegrationFeatures,
  IntegrationFeatureSettings,
  IntegrationSurveyFeature,
  IntegrationSurveyStatus,
} from '@shared/models/integrations.model';
import {
  DeleteSurveyIntegration,
  DeleteSurveyIntegrationPropertyLink,
  SaveSurveyIntegration,
  SyncSurveyIntegration,
  ToggleSurveyIntegration,
} from '@shared/states/integrations.actions';
import { SidenavRef } from '@shared/modules/sidenav/models/sidenav.models';
import { isDeepEqual } from '@shared/utilities/object.utilities';
import { ActionsState } from '@shared/states/actions.state';
import { isValidFeatureSetting } from '@shared/utilities/integration.utilities';
import { getLastValue, shareRef } from '@shared/operators/share-ref.operator';
import { SurveyIntegrationFeature } from '@shared/modules/survey-integration/components/features/survey-integration-feature.component';
import { SurveyIntegrationInstanceService } from '@shared/modules/survey-integration/services/survey-integration-instance.service';

@Component({
  templateUrl: './survey-integration-sidenav.component.html',
  styleUrls: ['./survey-integration-sidenav.component.scss'],
  providers: [SurveyIntegrationInstanceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyIntegrationSidenav {
  readonly Status = IntegrationSurveyStatus;

  readonly Feature = IntegrationSurveyFeature;

  readonly features$: Observable<IntegrationSurveyFeature[]> = this.sii.serviceIntegration$.pipe(
    map((service) => IntegrationFeatures[service.type]),
    shareRef(),
  );

  @Select(ActionsState.whileAction(DeleteSurveyIntegration))
  readonly loadingDelete$!: Observable<boolean>;

  @Select(ActionsState.whileAction(SaveSurveyIntegration))
  readonly loadingSave$!: Observable<boolean>;

  @Select(
    ActionsState.whileAction([
      DeleteSurveyIntegrationPropertyLink,
      ToggleSurveyIntegration,
      DeleteSurveyIntegration,
      SaveSurveyIntegration,
      SyncSurveyIntegration,
    ]),
  )
  readonly loading$!: Observable<boolean>;

  readonly sidenavLoading$: Observable<boolean> = combineLatest([this.sii.initLoading$, this.loading$]).pipe(
    map(([init, loading]) => init || loading),
    shareRef(),
  );

  @ViewChildren(SurveyIntegrationFeature)
  set featureInstances(instances: QueryList<SurveyIntegrationFeature>) {
    this.featureInstances$.next(instances?.toArray() || []);
  }

  readonly featureInstances$ = new ReplaySubject<SurveyIntegrationFeature[]>(1);

  readonly canSave$: Observable<boolean> = this.featureInstances$.pipe(
    switchMap((instances) => merge(...instances.map((instance) => instance.update$))),
    map(() => {
      let settings = this.getFeatureSettings();
      let currentSettings = getLastValue(this.sii.surveyIntegration$)!.features;

      if (!Object.values(settings).some((setting) => setting.active)) {
        return false;
      }

      const isValid = Object.entries(settings).some(
        ([feature, setting]) => setting.active && isValidFeatureSetting(feature as IntegrationSurveyFeature, setting),
      );

      currentSettings = this.sortPropertyLinks(currentSettings);
      settings = this.sortPropertyLinks(settings);

      return isValid && !isDeepEqual(currentSettings, settings, true);
    }),
    startWith(false),
    // same issue as survey thumb for some reason the change doesn't get picked up when switching pages
    // @todo check if still needed in angular 15 update
    tap(() => setTimeout(() => this.cd.markForCheck())),
    shareRef(),
  );

  constructor(readonly sii: SurveyIntegrationInstanceService, private sr: SidenavRef, private cd: ChangeDetectorRef) {}

  onSave(): void {
    this.sii
      .saveIntegration(this.getFeatureSettings())
      .pipe(delay(1))
      .subscribe({
        next: () => {
          this.sr.close();
          this.sii.displaySnackbar($localize`Survey integration saved`, 'success');
        },
        error: () => {
          this.sii.displaySnackbar($localize`Something went wrong, please try again later`, 'alert');
        },
      });
  }

  onDelete(): void {
    this.sii.deleteIntegration().subscribe({
      next: () => {
        this.sr.close();
        this.sii.displaySnackbar($localize`Survey integration removed`, 'alert');
      },
      error: () => {
        this.sii.displaySnackbar($localize`Something went wrong, please try again later`, 'alert');
      },
    });
  }

  private getFeatureSettings(): IntegrationFeatureSettings {
    return getLastValue(this.featureInstances$, []).reduce(
      (features, featureInstance) => ({
        ...features,
        [featureInstance.feature]: featureInstance.getSettings(),
      }),
      {},
    ) as IntegrationFeatureSettings;
  }

  private sortPropertyLinks(settings: IntegrationFeatureSettings): IntegrationFeatureSettings {
    return {
      ...settings,
      [IntegrationSurveyFeature.Property]: {
        ...settings?.[IntegrationSurveyFeature.Property],
        propertyLinks: [...settings?.[IntegrationSurveyFeature.Property]?.propertyLinks].sort((a, b) =>
          a.$key < b.$key ? 1 : -1,
        ),
      },
    };
  }
}

<ng-scrollbar [autoHeightDisabled]="false">
  <div class="z-fx-col z-fx-gap-16">
    <div class="z-fx-col z-fx-gap-8">
      <ng-container *ngIf="showAll === true">
        <team-tile
          *ngFor="let inviteData of invites"
          variant="small"
          [teamName]="inviteData.teamName"
          [teamLogo]="inviteData.teamLogo"
          [teamCreated]="0"
        >
          <button mat-raised-button color="ink" (click)="submitInvite(inviteData.$key)" i18n>Join</button>
        </team-tile>

        <button mat-raised-button class="zef-placeholder-button" color="primary" (click)="submitInvite(null)">
          <div class="z-fx z-fx-gap-16 z-fx-w100 z-fx-sc">
            <div class="avatar">
              <mat-icon>plus</mat-icon>
            </div>
            <span i18n>New Organization</span>
          </div>
        </button>
      </ng-container>

      <team-tile
        *ngIf="showAll === false"
        variant="small"
        [teamName]="invites[0].teamName"
        [teamLogo]="invites[0].teamLogo"
        [teamCreated]="0"
      >
        <button mat-raised-button color="ink" (click)="submitInvite(invites[0].$key)" i18n>Join</button>
      </team-tile>
    </div>

    <span *ngIf="!showAll" class="zef-text-p1-link" (click)="showAll = true" i18n>
      {{ invites.length }} more {invites?.length, plural, one {option} other {options}}...
    </span>
  </div>
</ng-scrollbar>

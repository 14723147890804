import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'zef-code-block',
  templateUrl: './code-block.component.html',
  styleUrls: ['./code-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeBlock {
  @Input()
  copyContent?: string;

  @Input()
  multiLine?: boolean;

  @Input()
  copyToClipboardNotice?: TemplateRef<any> | string;
}

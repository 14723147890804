import { combineLatest, concat, defer, Observable, of } from 'rxjs';
import { catchError, delay, filter, map, mapTo, startWith, switchMap, take } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { SIDENAV_DATA, SidenavRef } from '@shared/modules/sidenav/models/sidenav.models';

import {
  DeleteSurveyIntegration,
  DeleteSurveyIntegrationPropertyLink,
  GetIntegrationProperties,
  SaveSurveyIntegration,
  SyncSurveyIntegration,
  ToggleSurveyIntegration,
} from '@shared/states/integrations.actions';
import {
  IntegrationFeatureSettings,
  IntegrationPropertyLink,
  IntegrationSurveyFeature,
  IntegrationSurveyLink,
  IntegrationSurveyStatus,
  IntegrationSurveySyncParams,
  ServiceIntegration,
  ServiceIntegrationProperty,
} from '@shared/models/integrations.model';
import { ZefApi } from '@shared/services/zef-api.service';
import { Questions } from '@shared/enums/questions.enum';
import { SurveyState } from '@shared/states/survey.state';
import { ActionsState } from '@shared/states/actions.state';
import { AccountState } from '@shared/states/account.state';
import { SnackbarColor } from '@shared/models/notice.models';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { DisplaySnackbar } from '@shared/states/dialog.actions';
import { assertStoreData } from '@shared/utilities/store.utilities';
import { IntegrationsState } from '@shared/states/integrations.state';
import { getLastValue, shareRef } from '@shared/operators/share-ref.operator';
import { ToggleArchivedQuestions } from '@shared/states/editor.actions';
import { QuestionData } from '@shared/models/survey.model';
import { getPropertyLinksFromSurvey } from '@shared/utilities/integration.utilities';
import { hasItemArchivedDependencies, isItemArchived } from '@shared/utilities/survey-data.utilities';
import {
  GetOwner,
  GetQuestions,
  GetSurvey,
  GetSurveyQuestionHistory,
  RestoreQuestion,
} from '@shared/states/survey.actions';

@Injectable()
export class SurveyIntegrationInstanceService {
  readonly surveyKey = this.data.surveyKey;

  readonly activeQuestions = this.data.activeQuestions;

  @Select(AccountState.isTeamAdmin)
  readonly canEdit$!: Observable<boolean>;

  readonly surveyOwner$: Observable<string> = assertStoreData(
    this.store,
    SurveyState.surveyOwner(this.surveyKey, ''),
    new GetOwner(this.surveyKey),
    (owner: string) => owner !== '',
    SurveyState.surveyOwner(this.surveyKey),
  );

  readonly surveyName$: Observable<string> = assertStoreData(
    this.store,
    SurveyState.surveyName(this.surveyKey, ''),
    new GetSurvey(this.surveyKey),
    (surveyName: string) => surveyName !== '',
    SurveyState.surveyName(this.surveyKey),
  );

  readonly surveyQuestions$: Observable<QuestionData[]> = assertStoreData(
    this.store,
    SurveyState.questions(this.surveyKey),
    new GetQuestions(this.surveyKey),
    'length',
  );

  readonly questionsHistory$: Observable<QuestionData[]> = assertStoreData(
    this.store,
    SurveyState.questionsHistory(this.surveyKey),
    new GetSurveyQuestionHistory(this.surveyKey),
    'length',
  );

  readonly visibleQuestions$: Observable<QuestionData[]> = this.surveyQuestions$.pipe(
    map((questions) =>
      questions.filter(
        (question) =>
          Questions.integratable(question) &&
          !isItemArchived(question, questions) &&
          !hasItemArchivedDependencies(question, questions),
      ),
    ),
    shareRef(),
  );

  readonly integrationStatus$: Observable<IntegrationSurveyStatus> = this.store
    .select(IntegrationsState.surveyIntegrationStatus(this.surveyKey))
    .pipe(
      switchMap((integration) => this.emitWhileNotProcessing(integration)),
      shareRef(),
    );

  readonly serviceIntegration$: Observable<ServiceIntegration | undefined> = combineLatest([
    this.store.select(IntegrationsState.integrationBySurvey(this.surveyKey)),
    this.store.select(IntegrationsState.authenticatedServices),
  ]).pipe(
    map(
      ([surveyIntegration, authenticated]) =>
        surveyIntegration ||
        authenticated.find((service) => service.connected || service.interrupted) ||
        authenticated[authenticated.length - 1],
    ),
    shareRef(),
  );

  readonly surveyIntegration$: Observable<IntegrationSurveyLink> = this.store
    .select(IntegrationsState.surveyIntegration(this.surveyKey))
    .pipe(
      map((integration) => ({
        active: true,
        features: {
          [IntegrationSurveyFeature.Note]: { active: false },
          [IntegrationSurveyFeature.Property]: {
            active: false,
            propertyLinks: [],
            createNewContactOnCollection: true,
          },
          ...integration?.features,
        },
        ...integration,
      })),
      switchMap((integration) => this.emitWhileNotProcessing(integration)),
      shareRef(),
    );

  readonly properties$: Observable<ServiceIntegrationProperty[]> = this.serviceIntegration$.pipe(
    switchMap((service) =>
      service
        ? this.store.select(IntegrationsState.getIntegrationProperties(service.$key)).pipe(
            switchMap((properties) => {
              const links = getPropertyLinksFromSurvey(service.config?.surveys[this.surveyKey]);

              const hasMissing = !links.every(
                (link) => !link.integrationId || properties.find(({ id }) => link.integrationId === id),
              );

              if (!properties.length || hasMissing) {
                return this.refreshProperties(service.$key);
              }

              return of(properties);
            }),
          )
        : of([]),
    ),
    shareRef(),
  );

  readonly contactRespondentsCount$: Observable<number> = this.za
    .get<{ respondentCount: number }>(`integrations/respondent_count/${this.surveyKey}`)
    .pipe(
      catchError(() => of({ respondentCount: 0 })),
      map(({ respondentCount }) => respondentCount),
      shareRef(),
    );

  readonly initLoading$: Observable<boolean> = combineLatest([
    this.surveyQuestions$,
    this.properties$,
    this.contactRespondentsCount$,
  ]).pipe(take(1), mapTo(false), startWith(true), shareRef());

  constructor(
    private store: Store,
    private za: ZefApi,
    private db: DatabaseWrapper,
    private sr: SidenavRef,
    @Inject(SIDENAV_DATA) readonly data: { surveyKey: string; activeQuestions: string[] },
  ) {}

  getNewKey(): string {
    return this.db.createPushId();
  }

  goToDeletedQuestion(question: QuestionData): void {
    concat(
      defer(() => this.store.dispatch(new RestoreQuestion(question)).pipe(delay(500))),
      this.goToInvalidQuestion(question.$key),
    ).subscribe();
  }

  goToArchivedQuestion(key: string): void {
    this.goToInvalidQuestion(key).subscribe();
  }

  saveIntegration(features: IntegrationFeatureSettings): Observable<void> {
    const serviceIntegration = getLastValue(this.serviceIntegration$)!;
    const surveyIntegration = {
      ...getLastValue(this.surveyIntegration$),
      features,
    };

    const propSettings = surveyIntegration.features[IntegrationSurveyFeature.Property];

    if (propSettings?.active && !propSettings.propertyLinks?.length) {
      propSettings.active = false;
    }

    return this.store.dispatch(new SaveSurveyIntegration(serviceIntegration.$key, this.surveyKey, surveyIntegration));
  }

  syncIntegration(params: IntegrationSurveySyncParams): Observable<void> {
    const serviceIntegration = getLastValue(this.serviceIntegration$)!;

    return this.store.dispatch(new SyncSurveyIntegration(serviceIntegration.$key, this.surveyKey, params));
  }

  toggleIntegration(active: boolean): void {
    const serviceIntegration = getLastValue(this.serviceIntegration$)!;

    this.store.dispatch(new ToggleSurveyIntegration(serviceIntegration.$key, this.surveyKey, active)).subscribe(() => {
      this.displaySnackbar(
        active ? $localize`Integration switched On` : $localize`Integration switched Off`,
        active ? 'primary' : 'warning',
      );
    });
  }

  deleteIntegration(): Observable<void> {
    const serviceIntegration = getLastValue(this.serviceIntegration$)!;

    return this.store.dispatch(new DeleteSurveyIntegration(serviceIntegration.$key, this.surveyKey));
  }

  removeMapping(link: IntegrationPropertyLink): void {
    const serviceIntegration = getLastValue(this.serviceIntegration$)!;

    this.store.dispatch(new DeleteSurveyIntegrationPropertyLink(serviceIntegration.$key, this.surveyKey, link.$key));
  }

  displaySnackbar(title: string, color?: SnackbarColor): void {
    this.store.dispatch(new DisplaySnackbar(title, { color }));
  }

  emitWhileNotProcessing<T>(value: T): Observable<T> {
    return this.store.select(ActionsState.whileAction([SaveSurveyIntegration, DeleteSurveyIntegration])).pipe(
      filter((processing) => !processing),
      mapTo(value),
    );
  }

  private goToInvalidQuestion(question: string): Observable<void> {
    this.sr.close();

    return concat(
      defer(() => this.store.dispatch(new Navigate(['/surveys/edit', this.surveyKey, 'build']))),
      defer(() => this.store.dispatch(new ToggleArchivedQuestions(true))),
      defer(() => this.store.dispatch(new Navigate(['/surveys/edit', this.surveyKey, 'build'], { object: question }))),
    );
  }

  private refreshProperties(integration: string): Observable<ServiceIntegrationProperty[]> {
    return this.store
      .dispatch(new GetIntegrationProperties(integration))
      .pipe(switchMap(() => this.store.select(IntegrationsState.getIntegrationProperties(integration))));
  }
}

import { NgModule } from '@angular/core';
import { AuthErrorMessage } from '@auth/auth-error-message/auth-error-message.component';
import { AuthErrorShake } from '@auth/auth-error-message/auth-error-shake.directive';
import { AuthForgotPassword } from '@auth/auth-forgot-password/auth-forgot-password.component';
import { AuthPasswords } from '@auth/auth-passwords/auth-passwords.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [AuthPasswords, AuthErrorMessage, AuthErrorShake, AuthForgotPassword],
  imports: [SharedModule],
  exports: [AuthPasswords, AuthErrorMessage, AuthErrorShake, AuthForgotPassword],
  providers: [],
})
export class AuthSharedModule {}

import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { ChartDistribution, ChartDomain, CanvasContext } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a horizontal line chart.
 */
@Directive({
  selector: '[lineChart]',
})
export class LineChart implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() totalAnswers: number = 0;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private max: number = 0;
  private previousMax: number = 0;

  private scaleX: any;
  private scaleY: any;

  private brush: any;
  private brushArea: any;
  private brushing: boolean = false;

  private tooltip: any;

  private width: any;
  private height: any;
  private margin: any;
  private fontSize: number = 0;
  private unit: number = 0;

  private filter: any;

  private selections: any = [];

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.filtersDemo
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'line-chart');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 3 * this.unit,
      bottom: 6 * this.unit,
      left: 3 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.scaleX = d3
      .scaleBand()
      .rangeRound([0, this.width])
      .domain(this.domain.keys)
      .paddingInner(0.2)
      .paddingOuter(0.1);

    this.previousMax = this.max;
    this.max = (d3.max(this.data, (d) => (this.scale === 'percentage' ? d.percentage : d.value)) || 1) * 1.2;

    this.scaleY = d3.scaleLinear().rangeRound([this.height, 0]).domain([0, this.max]);
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      __this.setTexts(context);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              value: d3.interpolateNumber(a[i]['value'], b[i]['value']),
              percentage: d3.interpolateNumber(a[i]['percentage'], b[i]['percentage']),
              percentage_all: d3.interpolateNumber(a[i]['percentage_all'], b[i]['percentage_all']),
            };
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['value'] = x[i]['value'](t);
              c[i]['percentage'] = x[i]['percentage'](t);
              c[i]['percentage_all'] = x[i]['percentage_all'](t);
            }
            return c;
          };
        };
        const interpolator = interpolateArray(dataObj, d);
        const interpolateMax = d3.interpolateNumber(__this.previousMax, __this.max);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scaleY;

          if (step >= 1) {
            data = interpolator(ease(1));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(1))]);
            timer.stop();
          } else {
            data = interpolator(ease(step));
            scaleY = d3
              .scaleLinear()
              .rangeRound([__this.height, 0])
              .domain([0, interpolateMax(ease(step))]);
          }

          __this.setLine(context, data, scaleY);
        });
      } else {
        __this.setLine(context, d, __this.scaleY);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.line-chart-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'line-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setLine(context, data: any[] = [], scaleY, filter: any[] | null = [], highlight: any[] | null = []) {
    context.clearRect(this.margin.left, this.margin.top, this.width + 1, this.height + 1);
    this.setYAxis(context, scaleY);
    this.selections = new Set();
    const lines: any[] = [];

    for (let x = 0, lenx = data.length; x < lenx; x++) {
      const key = data[x]['key'];
      const value = data[x]['value'];
      const percentage = data[x]['percentage'];

      const xPos = this.scaleX(key) + this.margin.left + this.scaleX.bandwidth() / 2;
      const yPos = scaleY(this.scale === 'percentage' ? percentage : value) + this.margin.top;

      lines.push({
        key,
        xPos,
        yPos,
        value,
        percentage,
      });
    }

    const line = d3
      .line()
      .x(function (d) {
        return d['xPos'];
      })
      .y(function (d) {
        return d['yPos'];
      })
      .curve(d3.curveMonotoneX)
      .context(context);

    context.lineWidth = 1.5;
    context.fillStyle = this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    context.strokeStyle = this.selectionExists
      ? Colors.SELECTED
      : this.filtersDemo
      ? Colors.UNSELECTED
      : Colors.DEFAULT;
    context.globalAlpha = 1;

    context.beginPath();
    line(lines);
    context.stroke();

    for (let l = 0, len = lines.length; l < len; l++) {
      context.lineWidth = 1.5;
      context.fillStyle = this.selectionExists
        ? Colors.SELECTED
        : this.filtersDemo
        ? Colors.UNSELECTED
        : Colors.DEFAULT;
      context.strokeStyle = this.selectionExists
        ? Colors.SELECTED
        : this.filtersDemo
        ? Colors.UNSELECTED
        : Colors.DEFAULT;
      context.globalAlpha = 1;

      const key = lines[l]['key'];
      const xPos = lines[l]['xPos'];
      const yPos = lines[l]['yPos'];
      const value = lines[l]['value'];
      const percentage = lines[l]['percentage'];

      if (filter != null && filter.length === 2) {
        const area = this.scaleX.bandwidth() / 2 > 6 ? 6 : this.scaleX.bandwidth() / 2;
        const xFi =
          filter && filter[0] <= xPos - this.margin.left + area && filter[1] >= xPos - this.margin.left + area;

        if (!xFi) {
          context.globalAlpha = 0.2;
        } else if (xFi) {
          this.selections.add(key);
          context.fillStyle = Colors.FILTER;
        }
      } else if (this.filterInput && this.filterInput.length > 0) {
        const xFi = this.filterInput.indexOf(key) > -1;

        if (!xFi) {
          context.globalAlpha = 0.2;
        } else {
          context.fillStyle = Colors.FILTER;
        }
      }

      context.strokeStyle = 'transparent';
      context.lineWidth = 1;
      if (highlight && highlight.length > 0) {
        const xHi = highlight && highlight.indexOf(key) > -1;

        if (xHi) {
          context.lineWidth = 2;
          context.strokeStyle = Colors.HIGHLIGHT;
        }
      }

      context.beginPath();
      context.arc(xPos, yPos, 5, 0, 2 * Math.PI);
      context.closePath();

      context.fill();
      context.stroke();

      if (this.showNumbers) {
        context.font = 10 / 14 + 'em Open Sans';
        context.textBaseline = 'bottom';
        context.textAlign = 'center';
        context.fillStyle = Colors.TEXT;
        context.fillText(
          this.scale === 'percentage' ? (percentage * 100).toFixed(1) + '%' : Math.round(value),
          xPos,
          yPos - 5,
        );
      }
    }
    context.clearRect(this.margin.left, this.margin.top + this.height, this.width + this.margin.right, 8);
  }

  setTexts(context) {
    context.clearRect(0, this.height + this.margin.top, this.width + this.margin.right, this.margin.bottom);
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = this.margin.top / 2;

    const wIcon = this.fontSize + 4;

    context.font = 10 / 14 + 'em Open Sans';
    const wNumber = context.measureText(this.totalAnswers).width + 8;

    context.font = 12 / 14 + 'em Open Sans';
    const title = this.title ? shortenText(context, this.title, this.width, 8 + (wIcon + wNumber) / 2) : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

    context.font = 10 / 14 + 'em Open Sans';
    context.fillText(this.totalAnswers, startPoint + wTitle + wIcon, h);

    context.beginPath();
    context.moveTo(this.margin.left + 8, this.height + this.margin.top + this.unit);
    context.lineTo(this.margin.left + this.width - 8, this.height + this.margin.top + this.unit);
    context.closePath();
    context.strokeStyle = Colors.HELPERLINE;
    context.stroke();

    if (this.domain.scale === 'linear') {
      context.beginPath();
      context.arc(this.margin.left + this.width / 2, this.height + this.margin.top + this.unit, 5, 0, 2 * Math.PI);
      context.closePath();
      context.fillStyle = Colors.BACKGROUND;
      context.stroke();
      context.fill();

      context.font = 12 / 14 + 'em Open Sans';
      context.textBaseline = 'top';
      context.fillStyle = Colors.TEXT;

      context.textAlign = 'start';
      const xMinLabel = shortenText(context, this.domain.labelsLinear.min, this.width / 2, 10);
      context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + this.margin.bottom / 4);

      context.textAlign = 'end';
      const xMaxLabel = shortenText(context, this.domain.labelsLinear.max, this.width / 2, 10);
      context.fillText(
        xMaxLabel,
        this.margin.left + this.width,
        this.height + this.margin.top + this.margin.bottom / 4,
      );

      context.font = 'normal bold ' + 12 / 14 + 'em Open Sans';
      context.textAlign = 'center';
      const xAxisLabel = shortenText(context, this.domain.labelsLinear.axis, this.width, 10);
      context.fillText(
        xAxisLabel,
        this.margin.left + this.width / 2,
        this.height + this.margin.top + (2.2 * this.margin.bottom) / 4,
      );
    } else {
      context.font = 10 / 14 + 'em Open Sans';
      context.fillStyle = Colors.TEXT;
      context.textAlign = 'center';
      context.textBaseline = 'top';
      const bandwidth = this.scaleX.bandwidth();

      this.domain.keys.forEach((d) => {
        context.save();

        if (bandwidth < 65) {
          context.textBaseline = 'middle';
          context.textAlign = 'right';
          context.translate(
            this.margin.left + this.scaleX(d) + bandwidth / 2,
            this.margin.top + this.height + this.margin.bottom / 4,
          );
          context.rotate((315 * Math.PI) / 180);
          context.fillText(
            shortenText(
              context,
              this.domain.labels[d],
              Math.sqrt(Math.pow(this.margin.bottom, 2) + Math.pow(this.margin.bottom, 2)),
              5,
            ),
            0,
            0.8 * this.unit,
          );
        } else {
          context.translate(
            this.margin.left + this.scaleX(d) + bandwidth / 2,
            this.margin.top + this.height + this.margin.bottom / 4,
          );
          context.fillText(shortenText(context, this.domain.labels[d], bandwidth, 5), 0, 0);
        }

        context.restore();
      });
    }
  }

  setYAxis(context, scaleY) {
    context.clearRect(0, 0, this.margin.left, this.height + this.margin.top + 10);
    context.globalAlpha = 1;

    const __this = this;
    const tickCount = this.scale === 'percentage' ? 4 : this.max > 3 ? 4 : this.max > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleY.ticks(tickCount);
    const tickFormat = scaleY.tickFormat(tickCount, this.scale === 'percentage' ? ',.1%' : 'd');

    context.font = 10 / 14 + 'em Open Sans';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'right';
    context.textBaseline = 'middle';
    ticks.forEach((d) => {
      const x = this.margin.left - tickPadding;
      const y = this.margin.top + scaleY(d);
      context.fillText(tickFormat(d), x, y);
      if (d > 0) {
        context.beginPath();
        context.moveTo(x + tickPadding + 8, y);
        context.lineTo(this.margin.left + this.width, y);
        context.stroke();
      }
    });
  }

  setBrush() {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area);
      }
    };

    if (this.filtering && !this.anonymityLock) {
      this.brush = d3
        .brushX()
        .on('brush', function (event, d) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = true;
            const area = this.parentElement ? d3.pointer(event, this.parentElement) : [];
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.line-chart-canvas').each(function (da) {
              __this.setLine(__this.context.context, da, __this.scaleY, sel);
            });

            if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
              __this.selectForHover(event, area);
            }
          }
        })
        .on('end', function (event, d) {
          if (event.sourceEvent) {
            // learn more from https://github.com/d3/d3-selection/issues/122
            __this.brushing = false;
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;

            __this.base.select('.line-chart-canvas').each(function (da) {
              __this.setLine(__this.context.context, da, __this.scaleY, sel);
            });

            __this.callFilter();
          }
        });

      const callBrush = function (d) {
        if (__this.filterInput && __this.filterInput.length > 0) {
          const lineW = __this.scaleX.bandwidth();
          const padding = (__this.scaleX.paddingInner() * __this.scaleX.step()) / 2;

          const minX = __this.scaleX(__this.filterInput[0]) - padding;
          const maxX = __this.scaleX(__this.filterInput[__this.filterInput.length - 1]) + lineW + padding;

          const brushArea = [minX > 0 ? minX : 0, maxX];

          d3.select(this).call(__this.brush).call(__this.brush.move, brushArea);
        } else {
          const brushOn = d3.brushSelection(d3.select(this).node()) != null;

          if (!__this.filterInput && brushOn) {
            d3.select(this).call(__this.brush.move, null);
          } else {
            d3.select(this).call(__this.brush);
          }
        }
      };

      this.brushArea = this.base.selectAll('.svg-brush').data([this.data]);

      this.brushArea.exit().remove();

      this.brushArea
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .select('.brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);

      this.brushArea
        .enter()
        .append('svg')
        .attr('class', 'svg-brush')
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top + this.margin.bottom)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .on('mousemove', hoverFunction)
        .on('mouseout', function (event, d) {
          __this.setTooltip(d3.pointer(event, __this._element.nativeElement));
        })
        .append('g')
        .attr('class', 'brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);
    } else {
      this.brushArea = this.base.selectAll('.svg-brush').data([]);

      this.brushArea.exit().remove();

      this.base
        .selectAll('.line-chart-canvas')
        .on('mousemove', hoverFunction)
        .on('mouseout', function (event, d) {
          __this.setTooltip(d3.pointer(event, __this._element.nativeElement));
        });
    }
  }

  setTooltip(position, data: any[] = []) {
    const __this = this;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    // adding hovering effect
    this.base.selectAll('.line-chart-canvas').each(function (d) {
      const highlight = data.map((item) => item.key);
      if (!__this.brushing) {
        __this.setLine(__this.context.context, d, __this.scaleY, [], highlight);
      }
    });
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.cf.filter(this.filter);
      }
    }
  }

  // Helpers
  selectForHover(event, area) {
    const items = this.itemsBelow(area);

    if (items.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement), items);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    }
  }

  itemsBelow(area) {
    const items = this.data.filter(
      (item) =>
        area[0] < this.scaleX(item.key) + this.scaleX.bandwidth() + this.margin.left &&
        area[0] > this.scaleX(item.key) + this.margin.left,
    );

    return items;
  }
}

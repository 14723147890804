/**
 * Contacts export and download dialog.
 *
 * @unstable
 */

import { Observable, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';

import { SurveyModel } from '@shared/models/survey.model';
import { ExportItem, ExportSettings } from '@shared/models/report.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { SurveyState } from '@shared/states/survey.state';
import { BillingState } from '@shared/states/billing.state';
import { LicenseFeature } from '@shared/enums/license-feature.enum';

import { Exports } from '@shared/enums/exports.enum';

import { ExportService } from '@report/shared/services/export.service';
import { UpdateReportExportSettings } from '@shared/states/prefs.actions';
import { PrefsState } from '@shared/states/prefs.state';

@Component({
  selector: 'report-export',
  templateUrl: './report-export.dialog.html',
  styleUrls: ['./report-export.dialog.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportExport implements OnInit {
  public exportTypes = Exports;

  public sampleData: boolean = false;

  public exports: ExportItem[] = [];

  public exportType: string = 'raw';
  public exportFormat: string = 'CSV';

  public exportOnProgress: boolean = false;
  public formatChange: boolean = false;
  public typeChange: boolean = false;
  public includeComments: boolean = false;
  public includeDatatables: boolean = false;

  public selectedExportTab: number | null = null;
  public selectedExportTypeIndex: number | null = null;

  public showExportTypeList: boolean = false;
  public showAdvancedOptions: boolean = false;

  public reportName: string = '';

  public anonymityTreshold: number = null;

  proExportTypes = ['PPTX', 'DOCX', 'XLSX', 'PDF'];
  dialogLabel = $localize`:@@zef-i18n-00173:Unnamed survey`;

  private isDefaultExportType: boolean = true;

  @Select(SurveyState.activeSurvey())
  readonly survey$: Observable<SurveyModel>;

  @Select(BillingState.featureActive(LicenseFeature.DataExporting))
  canExport$: Observable<boolean>;

  @Select(PrefsState.reportExportSettings)
  readonly reportExportSettings$!: Observable<ExportSettings>;

  readonly canExport$$: Observable<boolean>;

  @ViewChild('dialog') dialogRef: MatDialogRef<ReportExport> | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: any,
    readonly cdRef: ChangeDetectorRef,
    readonly es: ExportService,
    readonly store: Store,
    readonly hooks: LifecycleHooks,
    private router: Router,
  ) {
    this.canExport$$ = this.canExport$.pipe(
      tap((can) => {
        if (this.isDefaultExportType) {
          this.selectedExportTypeIndex = can
            ? this.selectedExportTypeIndex || 0
            : Exports.ExportAll.indexOf(Exports.CSV);
          this.isDefaultExportType = false;
        }
      }),
    );
  }

  ngOnInit() {
    this.exports = [];
    this.sampleData = this.data.sampleData;

    this.anonymityTreshold = this.data.cf.getAnonymityTreshold();

    if (this.anonymityTreshold) {
      this.exportType = 'summary';
    }

    if (this.data.reportData && this.data.reportData.title) {
      this.reportName = this.data.reportData.title;
    }

    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurvey()).survey['$key'];

    this.reportExportSettings$.pipe(takeUntil(this.hooks.destroy)).subscribe((exportSettings) => {
      this.includeComments = !!exportSettings?.comments;
      this.includeDatatables = !!exportSettings?.datatables;
      this.cdRef.detectChanges();
    });

    this.es
      .listExports(surveyKey)
      .snapshotChanges()
      .pipe(
        takeUntil(this.hooks.destroy),
        catchError((error) => of(null)),
      )
      .subscribe((exports) => {
        this.exportOnProgress = false;
        this.exports = [];

        if (exports) {
          exports.forEach((e) => {
            if (e.payload.val()) {
              this.formatChange = false;
              this.typeChange = false;
              const item = { $key: e.key, ...(e.payload.val() as ExportItem) };
              this.exports.push(item);
              if (item.format) {
                this.selectedExportTypeIndex = Exports.ExportAll.indexOf(item.format);
              }
              if (item.type && Exports.ExportRaw.indexOf(Exports.ExportAll[this.selectedExportTypeIndex]) >= 0) {
                this.exportType = item.type;
              }
            }
          });
        }
        this.cdRef.detectChanges();
      });
  }

  dialogTitle(reportName: string) {
    return $localize`:dialog title@@zef-i18n-00276:Exporting ${reportName ? reportName : 'All Results'}:INTERPOLATION:`;
  }

  onCreateExport() {
    this.exportOnProgress = true;
    this.formatChange = false;

    const survey = this.store.selectSnapshot(SurveyState.activeSurvey()).survey;
    const surveyKey = survey['$key'];
    const surveyName = `${survey.name} ${new Date().getUTCDate()}.${
      new Date().getUTCMonth() + 1
    }.${new Date().getFullYear()}`;
    const respondentCount: number = !this.data.cf.getAnswerData()?.sampleData
      ? this.data.cf.getAnswerData()?.answers?.length || 0
      : 0;

    if (this.exports.length > 0) {
      this.formatChange = true;
      for (let e = 0, len = this.exports.length; e < len; e++) {
        this.onDeleteExport(this.exports[e]);
      }
    }

    this.data.cm.imageSubj.next(Exports.ExportAll[this.selectedExportTypeIndex]);
    if (Exports.ExportRaw.indexOf(Exports.ExportAll[this.selectedExportTypeIndex]) >= 0 && this.exportType === 'raw') {
      this.es.createRaw(
        surveyKey,
        Exports.ExportAll[this.selectedExportTypeIndex],
        surveyName,
        this.data.cf.getDimensions(),
        survey?.resultsOptions?.score,
        respondentCount,
        this.data.cf.getTextContacts(),
      );
      this.data.cm.imageSubj.next('');
    } else {
      setTimeout(() => {
        this.es.create(
          this.data.cm,
          surveyName,
          Exports.ExportAll[this.selectedExportTypeIndex],
          surveyKey,
          this.sampleData,
          respondentCount,
          this.includeComments,
          this.includeDatatables,
        );
        this.data.cm.imageSubj.next('');
        this.store.dispatch(
          new UpdateReportExportSettings({ comments: this.includeComments, datatables: this.includeDatatables }),
        );
      }, 100); // Wait for images to be updated... To be fixed later...
    }
  }

  onDownloadExport(exportInfo: ExportItem) {
    const surveyKey = this.store.selectSnapshot(SurveyState.activeSurvey()).survey['$key'];
    this.typeChange = false;
    this.formatChange = false;

    this.es.download(exportInfo['$key'], surveyKey, exportInfo.downloadToken);
  }

  onDeleteExport(exportInfo: ExportItem) {
    if (exportInfo['$key']) {
      this.es.deleteExport(exportInfo['$key']);
    }
  }

  onExportTabChange(event: any) {}

  onExportTypeChange($event) {
    if (this.exports && this.exports[0] && this.exports[0]['type'] !== $event.value) {
      this.typeChange = true;
    } else {
      this.typeChange = false;
    }
  }

  onShowExportTypes() {
    this.showExportTypeList = true;
    this.cdRef.detectChanges();
  }

  onShowAdvancedOptions() {
    this.showAdvancedOptions = true;
    this.cdRef.detectChanges();
  }

  onClickLink() {
    this.dialogRef.close();
    this.router.navigate(['/plans']);
  }
}

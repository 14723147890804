/**
 * Question card component with header and content support.
 *
 * @unstable
 */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Questions } from '@shared/enums/questions.enum';
import { QuestionData } from '@shared/models/survey.model';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionCard implements OnChanges, AfterViewInit {
  readonly Questions = Questions;

  private answerTimer: number = 300;
  private answerTimeout: number = null;

  readonly groupData = this.ss.questions.pipe(
    map((questions) => Questions.getGroup(this.questionData, questions)),
    filter((group) => group && group.type === Questions.GROUP_SCORED),
  );

  @Input() no: number = 0;

  @Input() active: boolean = false;
  @Input() visible: boolean = true;

  @Input() isLast: boolean = false;
  @Input() isFirst: boolean = false;

  @Input() answerValue: string = null;

  @Input() questionData: QuestionData = null;

  @Output() readonly nextQuestion = new EventEmitter<any>();
  @Output() readonly prevQuestion = new EventEmitter<any>();

  @Output() readonly answerReady = new EventEmitter<any>();
  @Output() readonly answerChanged = new EventEmitter<any>();
  @Output() readonly activeChanged = new EventEmitter<any>();

  @Output() readonly dragChange = new EventEmitter<boolean>();

  @Output() readonly waitNext = new EventEmitter<number>();

  @ViewChildren(QuestionCard) questionCards: QueryList<QuestionCard>;

  constructor(readonly cdRef: ChangeDetectorRef, readonly elRef: ElementRef<HTMLElement>, readonly ss: SurveyStore) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['active'] && !changes['active'].firstChange) {
      if (this.questionData) {
        this.activeChanged.emit({ item: this.questionData });
      }

      window.clearTimeout(this.answerTimeout);
    }
  }

  ngAfterViewInit() {
    const questionWrapHeight = (this.elRef.nativeElement.closest('.z-card-wrapper') as HTMLElement)?.offsetHeight;
    const playerHeight = this.ss.playerDimensions.value.height;
    const scrollable = playerHeight < questionWrapHeight;

    this.ss.updateDimensions({ scrollable });
  }

  hasMediaCta(): boolean {
    return (
      (Questions.group(this.questionData) || !!Questions.category(this.questionData, Questions.INFO)) &&
      !!(this.questionData.cta || this.questionData.videoEmbed || this.questionData.image?.url)
    );
  }

  onAnswerReady(): void {
    this.answerReady.emit({
      item: this.questionData,
    });

    this.ss.survey.pipe(take(1)).subscribe((survey) => {
      if (survey.autojump) {
        if (this.answerTimeout) {
          window.clearTimeout(this.answerTimeout);
        }

        this.answerTimeout = window.setTimeout(() => this.nextQuestion.emit(), this.answerTimer);
      }
    });
  }

  onAnswerChange(value: string): void {
    this.answerChanged.emit({
      value,
      item: this.questionData,
    });

    if (this.answerTimeout) {
      window.clearTimeout(this.answerTimeout);
    }
  }
}

<div class="z-fx z-fx-start-center z-fx-gap-8">
  <mat-icon *ngIf="!radioType" class="select-item-checkbox">check</mat-icon>
  <div *ngIf="radioType" class="select-item-radio">
    <div class="select-item-radio-circle"></div>
  </div>
  <div
    *ngIf="label; else valueAsLabel"
    class="select-item-label zef-text-ellipsis z-fx-flex"
    [class.placeholder]="placeholder"
    textOverflow
  >
    <ng-container [ngTemplateOutlet]="label" [ngTemplateOutletContext]="{ $implicit: value }"></ng-container>

    <ng-container *ngIf="placeholder">...</ng-container>
  </div>
  <ng-template #valueAsLabel>
    <div class="select-item-label zef-text-ellipsis z-fx-flex" [class.placeholder]="placeholder" textOverflow>
      {{ value }}
    </div>
  </ng-template>
</div>

<div
  *ngIf="sliderLabels?.axis || sliderLabels?.min || sliderLabels?.max"
  ngClass="z-label z-label-{{ axis }}"
  [class.z-without-title]="!sliderLabels?.axis"
  [class.z-without-min-max]="!sliderLabels?.min && !sliderLabels?.max"
>
  <div *ngIf="sliderLabels?.min || sliderLabels?.max" class="z-label-value">
    <div
      *ngFor="let side of ['min', 'max']"
      [style.color]="color"
      [style.text-shadow]="textShadow | safeStyle"
      ngClass="z-label-value-{{ side }} z-texts-label"
      labelOverflow="axis"
    >
      {{ (sliderLabels ? sliderLabels[side] : '') | translateText : side + (axis ? '-' + axis : '') : data }}
    </div>
  </div>

  <div
    *ngIf="sliderLabels?.axis"
    [style.color]="color"
    [style.text-shadow]="textShadow | safeStyle"
    class="z-label-title z-texts-label-bold"
    labelOverflow="title"
  >
    {{ sliderLabels?.axis | translateText : 'axis' + (axis ? '-' + axis : '') : data }}
  </div>
</div>

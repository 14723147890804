<div class="auth-sidebar-content z-fx-col z-fx-auto z-fx-gap-32">
  <div class="z-fx-col z-fx-gap-48 z-fx-auto">
    <div class="z-fx-col z-fx-gap-8">
      <span class="zef-text-h2" i18n>Log in to existing account</span>

      <div class="z-fx z-fx-gap-8">
        <span class="zef-text-p2" i18n>Don’t have an account?</span>
        <a class="zef-text-p2 zef-hyper-link" (click)="changeMethod()" i18n>Sign up</a>
      </div>
    </div>

    <div class="z-fx-col z-fx-gap-24">
      <div class="z-fx-col z-fx-gap-16">
        <div
          *ngIf="authError?.code === AuthError.NO_PASSWORD_PROVIDER"
          class="zef-text-p1 zef-highlight mat-primary"
          i18n
        >
          Looks like this account was created with Google Sign In
        </div>

        <button
          mat-raised-button
          color="secondary"
          class="zef-stretch-button auth-google-button"
          (click)="signInWithGoogle()"
        >
          <div class="z-fx z-fx-center-center z-fx-gap-8">
            <img src="https://www.gstatic.com/firebasejs/staging/3.0.0/auth/images/google.svg" />
            <strong class="zef-text-p2" i18n>Sign in with Google</strong>
          </div>
        </button>

        <div *ngIf="authError?.code === AuthError.ACCOUNT_NOT_EXIST" class="zef-text-p1 auth-error-text" i18n>
          Account with {{ authError.message }} does not exist
        </div>
        <div
          *ngIf="authError?.code === AuthError.GOOGLE_PROVIDER_ERROR && authError.message"
          class="zef-text-p1 auth-error-text"
        >
          {{ authError.message }}
        </div>
      </div>

      <div class="auth-or-divider">
        <hr />
        <span i18n>or</span>
        <hr />
      </div>

      <form
        #signInForm="ngForm"
        class="signin-form z-fx-col z-fx-gap-16 z-fx-between-stretch"
        (ngSubmit)="signInWithEmail(email, password)"
      >
        <div
          *ngIf="authError?.code === AuthError.NO_GOOGLE_PROVIDER"
          class="zef-text-p1 zef-highlight mat-primary"
          i18n
        >
          Looks like this account was created with Email & Password
        </div>

        <div class="signup-inputs z-fx-col z-fx-gap-8 z-fx-auto">
          <mat-form-field class="auth-input-field zef-input-combo" [authErrorShake]="AuthError.INVALID_EMAIL">
            <mat-icon matPrefix [class.zef-color-ink-lightest]="!email">email_address</mat-icon>
            <input
              matInput
              name="email"
              type="email"
              placeholder="Email..."
              i18n-placeholder="placeholder|sign-in email input"
              [(ngModel)]="email"
              (input)="clearError()"
            />
          </mat-form-field>

          <auth-error-message [error]="AuthError.INVALID_EMAIL" i18n>Invalid email address</auth-error-message>

          <mat-form-field class="auth-input-field zef-input-combo" [authErrorShake]="AuthError.NO_PASSWORD_MATCH">
            <mat-icon matPrefix [class.zef-color-ink-lightest]="!password">lock</mat-icon>
            <input
              matInput
              name="password"
              type="password"
              placeholder="Password..."
              i18n-placeholder="placeholder|sign-in password input"
              [(ngModel)]="password"
              (input)="clearError()"
            />
          </mat-form-field>

          <auth-error-message [error]="AuthError.NO_PASSWORD_MATCH" i18n>
            Email and password do not match
          </auth-error-message>

          <span
            *ngIf="authError?.code === AuthError.PASSWORD_PROVIDER_ERROR && authError.message"
            class="zef-text-p1 auth-error-text"
          >
            {{ authError.message }}
          </span>
        </div>

        <button mat-raised-button color="ink" type="submit" class="zef-stretch-button email-signin" i18n>
          Log in with email
        </button>

        <auth-forgot-password [email]="email"></auth-forgot-password>
      </form>

      <!--
      <div class="auth-or-divider">
        <hr />
        <span i18n>or</span>
        <hr />
      </div>

      <div class="z-fx-col z-fx-gap-24">
        <div class="z-fx-col z-fx-gap-16">
          <button mat-raised-button color="secondary" class="zef-stretch-button" (click)="signInWithSSO()">
            <div class="z-fx z-fx-center-center z-fx-gap-8">
              <strong class="zef-text-p2" i18n>SSO (ZEF test login)</strong>
            </div>
          </button>
        </div>
      </div>
      -->
    </div>
  </div>
</div>

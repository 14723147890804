import { DeleteDataAnswer } from '@plans/shared/models/plans.model';
import { SMSPackage } from '@shared/models/billing.model';
import { PlanStateModel } from './plan.state';

export class ClearCart {
  static readonly type = '[Cart] Clear Cart';
}

export class GetCart {
  static readonly type = '[Cart] Get Cart';
}

export class UpdateCart {
  static readonly type = '[Cart] Update Cart';
}

export class AddPlan {
  static readonly type = '[Cart] Add Plan';

  constructor(readonly chosenPlan: PlanStateModel, readonly removeData?: DeleteDataAnswer) {}
}

export class Checkout {
  static readonly type = '[Cart] Checkout';

  constructor() {}
}

export class SetParked {
  static readonly type = '[Cart] Set Parked';
}

export class ExtraAnswers {
  static readonly type = '[Cart] Extra Answers';

  constructor(readonly amount: number) {}
}

export class ExtraSMS {
  static readonly type = '[Cart] Extra SMS';

  constructor(readonly smsPackage: SMSPackage) {}
}

export class CheckoutComplete {
  static readonly type = '[Cart] Checkout Complete';
}

export class CheckoutCanceled {
  static readonly type = '[Cart] Checkout Canceled';
}

export class CheckoutSMSRefill {
  static readonly type = '[Cart] Checkout SMS Refill';
  constructor(readonly enabled: boolean, readonly amount: number) {}
}

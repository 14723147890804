/**
 * Survey state change animations.
 *
 * @stable
 */

import { animate, style, transition, trigger } from '@angular/animations';

export const SurveyAnimations = trigger('surveyState', [
  transition('* => *', [
    style({
      opacity: 0,
    }),
    animate(
      '500ms ease-in-out',
      style({
        opacity: 1,
      }),
    ),
  ]),
]);

const CardAnim = '250ms ease-in-out';

export const CardScroll = trigger('cardScroll', [
  transition('1 <=> void', [
    animate(
      CardAnim,
      style({
        transform: 'translateY(-{{height}}px)',
      }),
    ),
  ]),
  transition('-1 <=> void', [
    style({
      transform: 'translateY(-{{height}}px)',
    }),
    animate(
      CardAnim,
      style({
        transform: 'translateY(0)',
      }),
    ),
  ]),
]);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { FileUploadMode, FileUploadSize } from '@shared/modules/file-upload/file-upload.models';

@Component({
  selector: 'zef-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnChanges {
  hovering?: boolean;

  dropping?: boolean;

  imageSrc?: string;

  fileName?: string;

  useContain?: boolean;
  useCover?: boolean;

  @Input()
  file?: File | string;

  @Input()
  @HostBinding('class.disabled')
  disabled?: boolean;

  @Input()
  disableClick?: boolean;

  @Input()
  uploading?: boolean;

  @Input()
  progress?: number;

  @Input()
  error?: boolean | TemplateRef<any>;

  @Input()
  mode: FileUploadMode = 'file';

  @Input()
  size: FileUploadSize = 'medium';

  @Input()
  active?: boolean;

  @Output()
  readonly fileChange = new EventEmitter<File>();

  get isLarge() {
    return this.mode === 'avatar' || this.mode === 'upload';
  }

  get isLarger() {
    return this.mode !== 'avatar';
  }

  get showText() {
    return this.mode === 'upload' || this.mode === 'file' || this.mode === 'image';
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file) {
      this.setFileName();
      this.setImageSource();
      this.setImageDirection();
    }
  }

  onZoneDrop(drop: DragEvent): void {
    this.changeFile(drop.dataTransfer?.files?.[0]);
  }

  onFileInputChange(fileInput: HTMLInputElement): void {
    this.hovering = false;
    this.changeFile(fileInput.files?.item(0));
  }

  public setImageDirection(): void {
    this.useContain = false;
    this.useCover = false;

    if (this.mode === 'team' && this.imageSrc) {
      const img = new Image();
      img.onload = () => {
        this.useCover = img.width > img.height;
        this.useContain = img.width < img.height;
        this.cdRef.detectChanges();
      };
      img.src = this.imageSrc;
    }
  }

  private changeFile(file: File): void {
    if (file instanceof File) {
      this.fileChange.emit(file);
      this.setFileName(file);
      this.setImageSource(file);
      this.setImageDirection();
    }
  }

  private setFileName(file?: File | string | { source: string }): void {
    file = file || this.file;

    this.fileName = (file instanceof File ? file.name : typeof file === 'string' ? file : file?.source) || '';

    this.fileName = this.fileName.split(/[\\/]/).pop();
  }

  private setImageSource(file?: File | string): void {
    file = file || this.file;

    if (!file) {
      this.imageSrc = '';
    } else if (file instanceof File) {
      const reader = new FileReader();
      reader.onload = () => (this.imageSrc = reader.result as string);
      reader.readAsDataURL(file);
    } else {
      this.imageSrc = file;
    }
  }
}

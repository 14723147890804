import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[fitFontSize]',
})
export class FitFontSize implements AfterViewInit {
  @Input('fitFontSize')
  enabled = true;
  constructor(readonly elRef: ElementRef, readonly renderer: Renderer2) {}

  private computedStyle() {
    const el: HTMLElement = this.elRef.nativeElement;
    return window.getComputedStyle(el);
  }

  private setSize(value: number) {
    const el: HTMLElement = this.elRef.nativeElement;
    el.style.fontSize = `${value}px`;
  }

  ngAfterViewInit() {
    if (this.enabled) {
      setTimeout(() => this.fixOverflow());
    }
  }

  @HostListener('window:resize')
  checkOverflow() {
    if (this.enabled && this.hasOverflow(this.elRef.nativeElement)) {
      this.fixOverflow();
    }
  }

  fixOverflow() {
    if (!this.enabled) {
      return;
    }
    const el: HTMLElement = this.elRef.nativeElement;

    if (this.hasOverflow(el)) {
      this.renderer.removeClass(el, 'no-overflow');
      const size = parseFloat(this.computedStyle().fontSize);
      this.setSize(Math.max(1, size - 1));
      this.fixOverflow();
    } else {
      this.renderer.addClass(el, 'no-overflow');
    }
  }

  hasOverflow(el: HTMLElement) {
    const height = parseFloat(this.computedStyle().height);
    return el.scrollHeight > Math.ceil(height);
  }
}

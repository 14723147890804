<div *ngIf="settings" class="slider-wrapper">
  <div class="ticks-wrapper">
    <span *ngFor="let _ of settings.values.slice(1)" class="tick"></span>
  </div>

  <div *ngIf="settings.labels" class="labels-wrapper">
    <span *ngFor="let label of settings.labels" class="label">{{ label }}</span>
  </div>

  <mat-slider
    #slider
    [min]="0"
    [max]="settings.values.length - 1"
    [step]="1"
    [value]="toIndex(settings.value, slider.value)"
    (change)="updateThumb($event.value)"
    (input)="updateSlider($event.value)"
  ></mat-slider>

  <div #thumb *ngIf="settings.text" class="thumb" [ngStyle]="thumbStyle" [innerHtml]="thumbLabel"></div>
</div>

/**
 * Manages authentication data for the user.
 *
 * AuthManager methods should accept "firebase.User"
 * and return data from @shared/models/account.model
 *
 *
 * @unstable
 */

import { environment } from '@env/environment';

import firebase from 'firebase/compat/app';

import { BehaviorSubject, combineLatest, concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { runOutsideZone } from '@shared/utilities/angular.utilities';
import { UserInfo } from '@shared/models/auth.model';
import {
  RemoveUserOnAuthTokenChange,
  ResetUserInfo,
  SaveClaimsOnAuthTokenChange,
  SaveTokenOnAuthTokenChange,
  SignOutWithRedirect,
  UpdateUserOnAuthTokenChange,
} from '@shared/states/auth.actions';
import { AuthDoneFetchAccountData } from '@shared/states/account.actions';
import { LocalStorage } from 'ngx-webstorage';
import { ServerError } from '@shared/states/error.actions';
import { InviteData } from '@shared/models/account.model';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { mapObjectKey } from '@shared/operators/map-object-key.operator';
import { PrefsState } from '@shared/states/prefs.state';
import { RouterState } from '@shared/states/router.state';
import { AuditLogService } from '@shared/services/audit-log.service';
import { AuthWrapper } from '@shared/services/auth-wrapper.service';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { Requests } from '@shared/enums/requests.enum';

type User = firebase.User;

@Injectable({
  providedIn: 'root',
})
export class AuthManager {
  @LocalStorage('consent')
  trackingAllowed: boolean | undefined;

  readonly idTokenChange: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  // TODO: Remove this and update Analytics to use different data
  public readonly onSignupComplete = new Subject<User>();

  constructor(
    private db: DatabaseWrapper,
    private auth: AuthWrapper,
    private zone: NgZone,
    private za: ZefApi,
    private store: Store,
    private al: AuditLogService,
    readonly http: HttpClient,
  ) {}

  private currentUser(): Observable<User> {
    return this.auth.user.pipe(take(1));
  }

  private currentAuth(): firebase.auth.Auth {
    return firebase.auth(firebase.app(environment.firebase.appId));
  }

  init() {
    const init = this.auth.idTokenResult.pipe(
      withLatestFrom(this.auth.user, this.auth.getRedirectResult()),
      switchMap(([idToken, user, redirect]) => {
        const emailDomain = (user?.email || '').split('@')[1];

        const authConfig = idToken?.claims?.ory || idToken?.claims?.firebase;
        const isSSOLogin = authConfig?.sign_in_provider?.startsWith('saml.');

        return (isSSOLogin || !emailDomain ? of(false) : this.checkSSODomain(emailDomain)).pipe(
          switchMap((ssoLoginNeeded) => {
            if (idToken?.token === this.store.selectSnapshot(({ auth }) => auth?.authToken)) {
              return of(void 0);
            }

            console.log('Authenticated user:', user);
            console.log('Redirect result user:', redirect);
            console.log('SSO login information:', isSSOLogin, ssoLoginNeeded);

            if (redirect?.user && redirect?.additionalUserInfo?.isNewUser === true) {
              const info: UserInfo = this.userInfo(redirect.user);

              this.idTokenChange.next(idToken?.token);

              this.store.dispatch([new UpdateUserOnAuthTokenChange({ ...info, isNewUser: true })]);

              return of(void 0);
            } else if (user !== null) {
              const info: UserInfo = this.userInfo(user);

              const isLoginRoute = (location?.pathname || '').includes('/login/');
              const isPrivateRoute = (location?.pathname || '').includes('/private/');

              this.store.dispatch(new UpdateUserOnAuthTokenChange(info));

              this.store.dispatch([
                new SaveTokenOnAuthTokenChange(idToken?.token),
                new SaveClaimsOnAuthTokenChange(idToken?.claims),
              ]);

              if (ssoLoginNeeded) {
                this.store.dispatch(new SignOutWithRedirect(true));
              } else if (!isLoginRoute && !isPrivateRoute) {
                this.store.dispatch(new AuthDoneFetchAccountData(info?.$key));
              }

              this.idTokenChange.next(idToken?.token);

              return of(void 0);
            } else {
              this.store.dispatch(new RemoveUserOnAuthTokenChange());

              this.idTokenChange.next(null);

              return this.signInAnonymously();
            }
          }),
        );
      }),
    );

    init.subscribe();

    return init.pipe(take(1));
  }

  private userInfo(user: User): UserInfo {
    return {
      $key: user.uid,
      uid: user.uid,
      email: user.email,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      isAnonymous: user.isAnonymous,
      displayName: user.displayName,
      providerData: user.providerData,
      emailVerified: user.emailVerified,
      creationTime: new Date(user.metadata.creationTime).getTime(),
      lastSignInTime: new Date(user.metadata.lastSignInTime).getTime(),
    };
  }

  signOut() {
    const email = this.currentAuth().currentUser?.email;

    return concat(
      ...(email ? [this.al.sendAuthLog({ status: 'SUCCESS', auth: 'LOGOUT', email })] : []),
      this.runWithAuth((auth) => auth.signOut()),
    );
  }

  signInAnonymously() {
    console.log('Signing in anonymously');

    return this.runWithAuth((auth) => auth.signInAnonymously());
  }

  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'consent' });

    return this.al.logAuthAccess(
      this.runWithAuth((auth) => auth.signInWithPopup(provider)).pipe(map((user) => user.user?.email)),
      'LOGIN',
    );
  }

  signInMethods(email: string) {
    return this.runWithAuth((auth) => auth.fetchSignInMethodsForEmail(email));
  }

  refreshToken() {
    return this.runWithUser((user) => user.getIdToken(true));
  }

  unlinkPassword() {
    return this.runWithUser((user) => user.unlink('password')).pipe(
      map((user) => this.userInfo(user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  linkPassword(email: string, password: string) {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);

    return this.runWithUser((user) => user.linkWithCredential(credential)).pipe(
      map((cred) => this.userInfo(cred?.user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  resetPassword(code: string, password: string) {
    return this.runWithAuth((auth) => auth.confirmPasswordReset(code, password));
  }

  unlinkGoogle() {
    return this.runWithUser((user) => user.unlink('google.com')).pipe(
      map((user) => this.userInfo(user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  linkGoogle(login_hint?: string) {
    const provider = new firebase.auth.GoogleAuthProvider();

    if (login_hint) {
      provider.setCustomParameters({ login_hint, prompt: 'consent' });
    }

    return this.runWithUser((user) => user.linkWithPopup(provider)).pipe(
      map((cred) => this.userInfo(cred?.user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  ssoLogin(ssoDomain?: string) {
    const provider = new firebase.auth.SAMLAuthProvider(`saml.${ssoDomain || 'zef'}`);

    firebase
      .auth(firebase.app('zefApp'))
      .signInWithRedirect(provider)
      .catch(() => {
        this.store.dispatch(new Navigate(['/login']));
      });
  }

  oryLogin(ssoDomain?: string) {
    return this.http
      .request(Requests.Get, 'https://ory.zef.fi/sessions/whoami', {
        responseType: 'json',
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.log('ORY check error', error);

          return this.http
            .request(Requests.Get, 'https://ory.zef.fi/self-service/login/browser', {
              responseType: 'json',
              withCredentials: true,
              params: new HttpParams({ fromObject: { return_to: window.location.href } }),
            })
            .pipe(
              catchError((e) => {
                console.log('ORY login error', e);

                return of(null);
              }),
            );
        }),
      )
      .subscribe((response: any) => {
        console.log('ORY login response', response);

        if (ssoDomain && response && !response.active) {
          const params: any = { method: 'saml' };

          response.ui.nodes
            .filter((n: any) => n.group === 'saml')
            .forEach((n: any) => {
              if (n.attributes.name !== 'saml_provider' || n.attributes.value === ssoDomain) {
                params[n.attributes.name] = n.attributes.value;
              }
            });

          console.log('ORY login params', params);

          const urlPrams: string = new URLSearchParams(Object.entries(params)).toString();

          console.log('ORY login query params', urlPrams);

          window.location.href = response.ui.action + '&' + urlPrams;
        } else if (response && response.active) {
          const queryParams: any = this.store.selectSnapshot(RouterState.queryParams);

          this.http
            .request(Requests.Get, `https:${environment.webAddress}/oryLogin`, {
              responseType: 'json',
              withCredentials: true,
              params: new HttpParams({
                fromObject: { organization: queryParams.zefOrg || '' },
              }),
            })
            .pipe(
              catchError((e) => {
                console.log('ORY firebase login error', e);

                return of(null);
              }),
            )
            .subscribe((tokenRes: any) => {
              this.signInWithToken(tokenRes.token).subscribe((user) => {
                console.log('ORY signed into firebase', user);

                this.store.dispatch(new Navigate(['/']));
              });
            });
        } else {
          this.store.dispatch(new Navigate(['/login']));
        }
      });
  }

  oryLogout() {
    return this.http
      .request(Requests.Get, 'https://ory.zef.fi/self-service/logout/browser', {
        responseType: 'json',
        withCredentials: true,
      })
      .pipe(
        catchError((error) => {
          console.log('ORY logout error', error);

          return of(null);
        }),
      );
  }

  deleteUser() {
    return this.runWithUser((user) => user?.delete());
  }

  /**
   * General account management helper functions.
   */

  public updatePassword(password: string): Observable<boolean> {
    return this.runWithUser((user) => user.updatePassword(password)).pipe(
      mapTo(true),
      catchError((error) => {
        console.error('Password update failed', error);

        return throwError(error);
      }),
    );
  }

  public signInWithToken(token: string): Observable<User | null> {
    return this.runWithAuth((auth) => auth.signInWithCustomToken(token)).pipe(
      map((cred) => cred?.user || null),
      catchError((error) => {
        console.error('Failed to sign in with custom token', error);

        return throwError(null);
      }),
    );
  }

  public reAuthenticateWithGoogle(): Observable<boolean> {
    const provider = new firebase.auth.GoogleAuthProvider();

    return this.runWithUser((user) => user.reauthenticateWithPopup(provider)).pipe(mapTo(true));
  }

  public signInWithEmailPassword(email: string, password: string): Observable<User | null> {
    return this.al
      .logAuthAccess(
        this.runWithAuth((auth) => auth.signInWithEmailAndPassword(email, password)),
        'LOGIN',
        email,
      )
      .pipe(
        map((cred) => cred?.user || null),
        catchError((error) => {
          console.error('Email sign-in failed', error);

          return throwError(error);
        }),
      );
  }

  public reAuthenticateWithEmailPassword(email: string, password: string): Observable<User | null> {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);

    return this.runWithUser((user) => user.reauthenticateWithCredential(credential)).pipe(
      map((cred) => cred?.user || null),
      catchError((error) => {
        console.error('Email sign-in failed', error);

        return throwError(error);
      }),
    );
  }

  public sendEmailPasswordResetEmail(email: string): Observable<boolean> {
    return this.runWithAuth((auth) => auth.sendPasswordResetEmail(email)).pipe(
      mapTo(true),
      catchError((error) => {
        console.error('Email sending failed', error);

        return throwError(error);
      }),
    );
  }

  public verifyEmailPasswordResetCode(actionCode: any): Observable<string> {
    return this.runWithAuth((auth) => auth.verifyPasswordResetCode(actionCode)).pipe(
      catchError((error) => {
        console.error('Code verification failed', error);

        return throwError(error);
      }),
    );
  }

  public verifyEmailPasswordEmailAddress(actionCode: any): Observable<boolean> {
    return this.runWithAuth((auth) => auth.applyActionCode(actionCode)).pipe(
      mapTo(true),
      catchError((error) => {
        console.error('Code verification failed', error);

        return throwError(error);
      }),
    );
  }

  public confirmEmailPasswordEmailRecovery(actionCode: any): Observable<string> {
    return this.runWithAuth((auth) =>
      auth.checkActionCode(actionCode).then((info) => auth.applyActionCode(actionCode).then(() => info?.data?.email)),
    ).pipe(
      catchError((error) => {
        console.error('Code confirmation failed', error);

        return throwError(error);
      }),
    );
  }

  public confirmEmailPasswordResetPassword(actionCode: any, newPassword: string): Observable<void> {
    return this.runWithAuth((auth) => auth.confirmPasswordReset(actionCode, newPassword)).pipe(
      catchError((error) => {
        console.error('Reset confirmation failed', error);

        return throwError(error);
      }),
    );
  }

  private runOutside<T>(retriever: () => Promise<T>): Observable<T> {
    return runOutsideZone(this.zone)(retriever);
  }

  private runWithUser<T>(run: (user: User) => Promise<T>): Observable<T> {
    return this.currentUser().pipe(switchMap((user) => this.runOutside<T>(() => run(user))));
  }

  private runWithAuth<T>(run: (auth: firebase.auth.Auth) => Promise<T>): Observable<T> {
    const auth = this.currentAuth();

    return this.runOutside<T>(() => run(auth));
  }

  public sendInvite(token: string, verify?: boolean, redirect?: string): Observable<any> {
    const locale = this.store.selectSnapshot(PrefsState.language);

    return this.za
      .post(
        'invite/signup',
        {},
        {
          type: verify ? 'verify' : 'login',
          locale,
          redirect: redirect || null,
        },
      )
      .pipe(
        catchError((error: any) => {
          this.store.dispatch(new ServerError(error));

          return of(error);
        }),
      );
  }

  public checkInvites(email: string): Observable<InviteData[]> {
    return this.db
      .list<InviteData>(`/invites`, (ref) => ref.orderByChild('email').equalTo(email))
      .snapshotChanges()
      .pipe(take(1), mapListKeys());
  }

  public getInvite(inviteKey: string): Observable<InviteData> {
    const teamInvite$ = this.db
      .object<InviteData>(`/invites/${inviteKey}`)
      .snapshotChanges()
      .pipe(
        mapObjectKey(),
        switchMap((inviteData: InviteData) => {
          if (!inviteData) {
            return of(null);
          } else if (!!inviteData.email) {
            return this.signInMethods(inviteData.email).pipe(map((providers) => ({ ...inviteData, providers })));
          } else {
            return of(inviteData);
          }
        }),
      );

    const funnelInvite$ = this.db
      .object<InviteData>(`/funnelinvites/${inviteKey}`)
      .snapshotChanges()
      .pipe(
        mapObjectKey(),
        switchMap((inviteData: InviteData) => {
          if (!inviteData) {
            return of(null);
          } else {
            return of(inviteData);
          }
        }),
      );

    return combineLatest([teamInvite$, funnelInvite$]).pipe(
      map(([teamInvite, funnelInvite]) => teamInvite || funnelInvite),
    );
  }

  verifyCustomLogin(verifyKey: string): Observable<boolean | null> {
    type AuthCustomResponse = {
      token?: string;
    };

    return this.za.post<AuthCustomResponse>('auth/custom', { verifyKey }).pipe(
      switchMap((response: AuthCustomResponse) =>
        !response || !response.token
          ? of(null)
          : this.store.dispatch(new ResetUserInfo()).pipe(switchMap(() => this.signInWithToken(response.token))),
      ),
      map((user) => Boolean(user)),
    );
  }

  verifyScaKey(scaKey: string) {
    type AuthCustomResponse = {
      token?: string;
    };

    return this.za.post<AuthCustomResponse>('auth/custom', { scaKey }).pipe(
      switchMap((response: AuthCustomResponse) =>
        !response || !response.token
          ? of(null)
          : this.store.dispatch(new ResetUserInfo()).pipe(switchMap(() => this.signInWithToken(response.token))),
      ),
      map((user) => Boolean(user)),
    );
  }

  verifyPrivateLink(linkKey: string, email?: string) {
    type AuthCustomResponse = {
      token?: string;
      share?: { surveyKey: string; reportKey: string; teamKey: string };
    };

    return this.za
      .post<AuthCustomResponse>('auth/custom', { linkKey, email })
      .pipe(map((response) => ({ ...response?.share, token: response.token })));
  }

  getSSOSettings(ssoDomain: string) {
    return this.db.object<any>(`/sso/${ssoDomain}/loginSettings`).snapshotChanges().pipe(mapObjectKey());
  }

  checkSSODomain(emailDomain: string) {
    return !emailDomain
      ? of(null)
      : this.db
          .object<any>(`/domains/${emailDomain.replace(/\./g, ',')}`)
          .valueChanges()
          .pipe(
            take(1),
            map((value) => !!value),
          );
  }
}

/**
 * Manages prefs data of the user.
 *
 * @unstable
 */

import { Observable, of, from, combineLatest } from 'rxjs';

import { catchError, map, shareReplay, switchMap, distinctUntilChanged } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { CardData, StatesData, IdentityData, PropertyData, UserItem } from '@shared/models/prefs.model';

import { AccountState } from '@shared/states/account.state';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { SurveySearchPrefs } from '@shared/models/survey.model';
import { ContactColumn, ContactsPagePrefs, ImportPrefs, ChatPrefs } from '@shared/models/contact.model';
import { SortDirection } from '@shared/components/table/models/table.models';
import { SurveySharesOrderState } from '@shared/models/survey-shares.model';
import { isArrayShallowEqual } from '@shared/utilities/array.utilities';
import { EditorPrefs } from '@shared/models/editor.model';
import { shareRef } from '@shared/operators/share-ref.operator';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { TeamData } from '@shared/models/account.model';
import { ExportSettings } from '@shared/models/report.model';

@Injectable({
  providedIn: 'root',
})
export class PrefsManager {
  private syncedProps: (keyof IdentityData & keyof PropertyData)[] = ['firstName', 'lastName', 'email'];

  constructor(private db: DatabaseWrapper, private store: Store) {}

  public getCountry(user?: string, team?: string): Observable<string | null> {
    const userKey = user || this.store.selectSnapshot(AccountState.userKey);
    const teamKey = team || this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<string | null>(`/prefs/${teamKey}/${userKey}/country`)
      .valueChanges()
      .pipe(
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public setCountry(country: string): Promise<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/prefs/${teamKey}/${userKey}/country`).set(country);
  }

  public getLanguage(user?: string, team?: string): Observable<string | null> {
    const userKey = user || this.store.selectSnapshot(AccountState.userKey);
    const teamKey = team || this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<string | null>(`/prefs/${teamKey}/${userKey}/language`)
      .valueChanges()
      .pipe(
        map((lang) => lang.replace('us', 'en')),
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public setLanguage(language: string): Promise<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/prefs/${teamKey}/${userKey}/language`).set(language);
  }

  public loadIdentity(user?: string, team?: string): Observable<IdentityData | null> {
    const userKey = user || this.store.selectSnapshot(AccountState.userKey);
    const teamKey = team || this.store.selectSnapshot(AccountState.teamKey);

    // TODO: Check if user can have multiple identities ??
    return this.db
      .object<IdentityData>(`/prefs/${teamKey}/${userKey}/identity`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as any)),
        catchError(() => of({} as any)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public loadTeamIdentities(teamData?: TeamData): Observable<UserItem[]> {
    const teamKey = teamData?.$key || this.store.selectSnapshot(AccountState.teamKey);
    const userList = Object.entries(teamData?.users || {}).map(([key, rights]) => ({ key, rights }));

    return this.db
      .list<IdentityData>(`/prefs/${teamKey}/`)
      .snapshotChanges()
      .pipe(
        mapListKeys(),
        map((identities) => identities.map((object: any) => ({ key: object?.$key, ...(object?.identity || {}) }))),
        map((list) =>
          userList.map((item) => ({ ...item, ...(list.find((identity) => identity.key === item.key) || {}) })),
        ),
        shareRef(),
        catchError(() => of([])),
      );
  }

  public async updateIdentity(newData: Partial<IdentityData>, syncProps: boolean = true): Promise<void> {
    if (!Object.keys(newData).length) {
      return;
    }

    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    if (!userKey || !teamKey) {
      return;
    }

    if (typeof newData.firstName === 'string' && typeof newData.lastName === 'string') {
      newData.name = `${newData.firstName} ${newData.lastName}`;
    }

    if (syncProps) {
      await this.updateProperties(this.getSyncObject(newData));
    }

    return this.db.object<IdentityData>(`/prefs/${teamKey}/${userKey}/identity`).update(newData);
  }

  public loadProperties(user?: string): Observable<PropertyData | null> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = user || this.store.selectSnapshot(AccountState.userKey);

    return this.db
      .object<PropertyData>(`/prefs/${teamKey}/${userKey}/properties`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as any)),
        catchError((error) => of({} as any)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public async updateProperties(newData: Partial<PropertyData>): Promise<void> {
    if (!Object.keys(newData).length) {
      return;
    }

    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object<PropertyData>(`/prefs/${teamKey}/${userKey}/properties`).update(newData);
  }

  public loadColumns(table: string): Observable<Record<string, ContactColumn>> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<Record<string, ContactColumn>>(`/prefs/${teamKey}/${userKey}/columns/${table}`)
      .valueChanges()
      .pipe(
        map((data) => data || {}),
        catchError(() => of({})),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public updateColumns(table: string, column: string, data: any) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.list(`/prefs/${teamKey}/${userKey}/columns/${table}`).update(column, data);
  }

  public loadSurveySearch() {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/prefs/${teamKey}/${userKey}/surveySearch`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as any)),
        catchError((error) => of({} as any)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public loadReportExportSettings() {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/prefs/${teamKey}/${userKey}/reportExportSettings`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as any)),
        catchError((error) => of({} as any)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public getContactsPagePrefs(): Observable<ContactsPagePrefs> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<ContactsPagePrefs>(`/prefs/${teamKey}/${userKey}/contactsPage`)
      .valueChanges()
      .pipe(
        catchError(() => of({})),
        map((data) => ({
          contactsSortDir: 'desc' as SortDirection,
          contactsSortCol: 'created',
          listsSortDir: 'asc' as SortDirection,
          listsSortProp: 'name' as 'name',
          view: 'contacts' as 'contacts',
          ...(data || {}),
        })),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public updateContactsPagePrefs(update: Partial<ContactsPagePrefs>): Observable<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(this.db.object<ContactsPagePrefs>(`/prefs/${teamKey}/${userKey}/contactsPage`).update(update));
  }

  public getImportPrefs(): Observable<ImportPrefs> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const lang = this.store.selectSnapshot((state) => state.prefs.language);

    return this.db
      .object<ContactsPagePrefs>(`/prefs/${teamKey}/${userKey}/import`)
      .valueChanges()
      .pipe(
        catchError(() => of({})),
        map((data) => ({
          defaultRepairCountry: lang === 'en' ? 'us' : lang,
          ...(data || {}),
        })),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public getChatPrefs(): Observable<ChatPrefs> {
    return combineLatest([this.store.select(AccountState.userKey), this.store.select(AccountState.teamKey)]).pipe(
      switchMap(([user, team]) =>
        this.db
          .object<ChatPrefs>(`/prefs/${team}/${user}/chat`)
          .valueChanges()
          .pipe(catchError(() => of({ helpHidden: false }))),
      ),
      shareRef(),
    );
  }

  public updateImportPrefs(update: Partial<ImportPrefs>): Observable<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(this.db.object<ImportPrefs>(`/prefs/${teamKey}/${userKey}/import`).update(update));
  }

  public updateEditorPrefs(update: Partial<EditorPrefs>): Observable<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(this.db.object<EditorPrefs>(`/prefs/${teamKey}/${userKey}/editor`).update(update));
  }

  public getEditorPrefs(): Observable<EditorPrefs> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<EditorPrefs>(`/prefs/${teamKey}/${userKey}/editor`)
      .valueChanges()
      .pipe(
        catchError(() => of({})),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public updateSurveySearch(data: Partial<SurveySearchPrefs>) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    if (!!userKey && !!teamKey) {
      return this.db.object(`/prefs/${teamKey}/${userKey}/surveySearch`).update(data);
    } else {
      return Promise.resolve();
    }
  }

  public updateReportExportSettings(data: Partial<ExportSettings>) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    if (!!userKey && !!teamKey) {
      return this.db.object(`/prefs/${teamKey}/${userKey}/reportExportSettings`).update(data);
    } else {
      return Promise.resolve();
    }
  }

  public getSurveySharesOrderState(surveyKey: string): Observable<SurveySharesOrderState> {
    return this.store.select(AccountState.teamKeyUserKey).pipe(
      distinctUntilChanged(isArrayShallowEqual),
      switchMap(([teamKey, userKey]) =>
        this.db
          .object<SurveySharesOrderState>(`/prefs/${teamKey}/${userKey}/surveySharesOrder/${surveyKey}`)
          .valueChanges(),
      ),
    );
  }

  public updateSurveySharesOrderState(surveyKey: string, data: Partial<SurveySharesOrderState>): Observable<void> {
    return this.store
      .selectOnce(AccountState.teamKeyUserKey)
      .pipe(
        switchMap(([teamKey, userKey]) =>
          this.db.object(`/prefs/${teamKey}/${userKey}/surveySharesOrder/${surveyKey}`).update(data),
        ),
      );
  }

  public updateChatPrefs(data: Partial<ChatPrefs>): Observable<void> {
    return this.store
      .selectOnce(AccountState.teamKeyUserKey)
      .pipe(switchMap(([teamKey, userKey]) => this.db.object(`/prefs/${teamKey}/${userKey}/chat`).update(data)));
  }

  public loadStatesData(user?: string): Observable<StatesData> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = user || this.store.selectSnapshot(AccountState.userKey);

    return this.db
      .object<StatesData>(`/prefs/${teamKey}/${userKey}/states`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as any)),
        catchError((error) => of({} as any)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public updateStatesData(newData: Object): Promise<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/prefs/${teamKey}/${userKey}/states`)
      .update(newData)
      .catch(() => {});
  }

  public loadPinnedCards(user?: string): Observable<CardData[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = user || this.store.selectSnapshot(AccountState.userKey);

    return this.db
      .list<CardData>(`/prefs/${teamKey}/${userKey}/pinned/`)
      .snapshotChanges()
      .pipe(
        map((data) => data || ([] as any[])),
        catchError((error) => of([] as any[])),
        map((list: any[]) => list.map((object: any) => ({ $key: object.key, ...object.payload.val() }))),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public appendPinnedCard(newData: CardData): string | null {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    newData.gridSettings.col = 1;
    newData.gridSettings.row = 999;

    return this.db.list<CardData>(`/prefs/${teamKey}/${userKey}/pinned/`).push(newData).key;
  }

  public removePinnedCard(cardKey: string): Promise<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.list(`/prefs/${teamKey}/${userKey}/pinned/`).remove(cardKey);
  }

  public updatePinnedCard(cardKey: string, newData: Object): Promise<void> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.list(`/prefs/${teamKey}/${userKey}/pinned/`).update(cardKey, newData);
  }

  private getSyncObject(newObject: Partial<IdentityData | PropertyData>): Partial<IdentityData & PropertyData> {
    const syncObject: Partial<IdentityData> & Partial<PropertyData> = {};

    this.syncedProps.forEach((prop: string) => {
      if (newObject.hasOwnProperty(prop)) {
        syncObject[prop] = newObject[prop];
      }
    });

    return syncObject;
  }
}

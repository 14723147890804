/**
 * Pipe for fetching & updating translations.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

import { LanguageManager } from '@player/shared/services/language-manager.service';

import { TranslateText } from '@player/shared/pipes/translate-text.pipe';

@Pipe({
  name: 'translateObject',
})
export class TranslateObject extends TranslateText implements PipeTransform {
  constructor(translator: LanguageManager) {
    super(translator);
  }

  transform<T extends object>(original: string | T, objectKeys: (keyof T)[], ...args: any[]): T | undefined {
    if (typeof original === 'object') {
      return {
        ...original,
        ...objectKeys.reduce(
          (a, key) => ({
            ...a,
            [key]: super.transform(original[key]?.toString(), key, ...args),
          }),
          {},
        ),
      };
    }
  }
}

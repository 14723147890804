/**
 * Contacts export and download dialog.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';

import { AppState } from '@report/shared/models/state.model';
import { SurveyModel } from '@shared/models/survey.model';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { SurveyState } from '@shared/states/survey.state';

import { GridItem } from '@shared/models/report.model';

import { Calculations } from '@report/shared/enums/calculations.enum';

@Component({
  selector: 'fullscreen-chart',
  templateUrl: './fullscreen-chart.dialog.html',
  styleUrls: ['./fullscreen-chart.dialog.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenChart implements OnInit, OnDestroy {
  public selectedChart: number = 0;
  public charts: GridItem[] = [];
  private originalSize: number = 5;
  private customPos: boolean = false;

  @Select(SurveyState.activeSurvey())
  readonly survey$: Observable<SurveyModel>;

  @Select(({ prefs }: AppState) => prefs.language)
  readonly language$: Observable<string>;

  @ViewChild('container') containerRef?: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: any,
    private dialogRef: MatDialogRef<FullscreenChart>,
    readonly store: Store,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly hooks: LifecycleHooks,
    readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.data.charts.pipe(takeUntil(this.hooks.destroy)).subscribe((charts) => {
      this.charts = charts.filter((chart) => !chart.isHintCard && !chart.isSubheader && !chart.hided);
      if (!this.customPos) {
        this.selectedChart = this.charts.findIndex((item) => item.gridId === this.data.chart);
      }
      this.originalSize = this.charts[this.selectedChart].gridSettings.sizey;
      this.cdRef.detectChanges();
    });
    if (this.data.chart) {
      this.selectedChart = this.charts.findIndex((item) => item.gridId === this.data.chart);
      this.originalSize = this.charts[this.selectedChart].gridSettings.sizey;
    }
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.originalSize !== this.charts[this.selectedChart].gridSettings.sizey) {
      this.charts[this.selectedChart].gridSettings.sizey = this.originalSize;
    }
  }

  public next() {
    this.customPos = true;
    this.data.cm.closeMenusSubj.next(true);
    if (this.selectedChart < this.charts.length - 1) {
      this.selectedChart += 1;
      this.originalSize = this.charts[this.selectedChart].gridSettings.sizey;
    }
  }

  public back() {
    this.customPos = true;
    this.data.cm.closeMenusSubj.next(true);
    if (this.selectedChart > 0) {
      this.selectedChart -= 1;
      this.originalSize = this.charts[this.selectedChart].gridSettings.sizey;
    }
  }

  public calcHeight(details) {
    const height: number =
      this.containerRef && this.containerRef.nativeElement && this.containerRef.nativeElement.clientHeight
        ? this.containerRef.nativeElement.clientHeight
        : 0;

    const originalHeight: number = (details.sizey || 0) * Calculations.gridHeightUnit(details.sizey || 0);

    return Math.max(originalHeight, height) + 'px';
  }

  public closeDialog($event) {
    this.dialogRef.close();
  }

  public openBuilder($event) {
    this.data.events.emit({ action: 'openBuilder', content: $event });
    this.closeDialog($event);
  }

  public pin($event) {
    this.data.events.emit({ action: 'pin', content: $event });
  }

  public openRemoveChartDialog($event) {
    this.data.events.emit({ action: 'openRemoveChartDialog', content: $event });
  }
}

/**
 * Service for getting and storing player translations
 *
 * @unstable
 */

import { delay, filter, take, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, of, BehaviorSubject } from 'rxjs';

import { Injectable, Inject, ApplicationRef, Optional } from '@angular/core';

import { LocalStorage } from 'ngx-webstorage';

import { LanguagesData } from '@shared/models/locale.model';
import { LoaderInterface } from '@shared/models/loader.model';

import { HistoryState } from '@player/shared/services/history-state.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageManager {
  public loadingLanguage = new BehaviorSubject<boolean>(false);

  public currentLocales: Map<string, string>;

  // This contains an observable map of each locale by lcid
  public localeMap = new Map<string, ReplaySubject<Map<string, string>>>();

  public autodetectLanguage: boolean = false;
  public defaultLanguage: string;
  public currentLanguage: string;
  public paramsLanguage: string;
  public surveyLanguage: string;

  public languages: LanguagesData = {};

  public changeLocale = new ReplaySubject<string>();

  @LocalStorage('language') selectedLanguage: string | null;

  constructor(
    readonly hs: HistoryState,
    readonly app: ApplicationRef,
    @Optional() @Inject('LocaleLoader') readonly ll: LoaderInterface,
    @Optional() @Inject('isPublished') readonly allowStoring: boolean,
  ) {}

  public init(locales: LanguagesData, surveyLanguage: string, releaseLocation: string): void {
    this.localeMap.forEach((obs) => obs.complete());
    this.localeMap = new Map<string, ReplaySubject<Map<string, string>>>();
    this.setDefaultLanguage(locales, surveyLanguage);
    this.currentLanguage ??=
      this.paramsLanguage ||
      this.hs.getParam('lang') ||
      (this.allowStoring ? this.selectedLanguage : null) ||
      this.defaultLanguage;

    if (this.ll) {
      this.ll.setRootPath(releaseLocation);
    }

    this.updateLocales(locales, surveyLanguage);
  }

  public setDefaultLanguage(locales: LanguagesData, surveyLanguage: string): void {
    const browserLanguages = ((navigator && navigator.languages) || []).map((ls) => ls.substring(0, 2)) || [];
    const langaugeCodes = Object.keys(locales || {});
    const browserLanguage = browserLanguages.find((lc) => langaugeCodes.includes(lc));
    const firstLanguage = langaugeCodes[0];

    this.surveyLanguage = surveyLanguage;
    this.defaultLanguage = (this.autodetectLanguage && browserLanguage) || surveyLanguage || firstLanguage || 'en';
  }

  public updateLocales(locales: LanguagesData, surveyLanguage: string): void {
    this.setDefaultLanguage(locales, surveyLanguage);

    this.languages = locales || {};

    Object.keys(this.languages).forEach((lcid) => {
      this.localeMap.set(lcid, new ReplaySubject(1));
    });

    if (!this.localeMap.has(this.currentLanguage)) {
      this.currentLanguage = this.defaultLanguage;
    }

    this.setLanguage(this.currentLanguage, !locales);
  }

  public get(value: string): string {
    return this.currentLocales ? this.currentLocales.get(value) : null;
  }

  public showLanguageSelect(): boolean {
    return this.languages && Object.keys(this.languages).length > 1;
  }

  get currentLanguageData() {
    const current = this.languages[this.currentLanguage] && {
      ...this.languages[this.currentLanguage],
      code: this.currentLanguage,
    };

    const first = Object.keys(this.languages)[0] && {
      ...Object.values(this.languages)[0],
      code: Object.keys(this.languages)[0],
    };

    return current || first || null;
  }

  get currentLanguageName() {
    return this.currentLanguageData?.native || this.currentLanguageData?.name || 'English';
  }

  get currentLanguageCode() {
    return this.currentLanguageData?.code || 'en';
  }

  public getLocaleData(lcid: string): Observable<Map<string, string>> {
    if (this.localeMap.has(lcid)) {
      return this.localeMap.get(lcid).pipe(filter((x) => x != null));
    }

    return of(new Map());
  }

  public setLanguage(lcid: string, isDefault?: boolean) {
    this.changeLocale.next(lcid);

    if (this.allowStoring && this.selectedLanguage !== lcid) {
      this.selectedLanguage = lcid;
    }

    if (this.ll && !isDefault) {
      this.loadingLanguage.next(true);

      this.ll
        .fetchLocale(lcid)
        .pipe(
          take(1),
          tap((strings) => {
            const stringMap = new Map<string, string>();

            for (const key in strings) {
              if (strings.hasOwnProperty(key)) {
                const translation = strings[key];
                stringMap.set(key, translation);
              }
            }

            // Add the data to lcid observable map
            if (this.localeMap.has(lcid)) {
              const localeObs = this.localeMap.get(lcid);

              if (localeObs) {
                localeObs.next(stringMap);
              }
            }

            this.currentLocales = stringMap;

            this.currentLanguage = lcid;
          }),
          delay(1),
          tap(() => {
            this.loadingLanguage.next(false);
            this.app.tick();
          }),
        )
        .subscribe({ error: () => this.loadingLanguage.next(false) });
    }
  }

  reset() {
    this.changeLocale.next(null);

    if (this.currentLocales) {
      this.currentLocales.clear();
    }

    this.languages = {};
    this.defaultLanguage = null;
    this.currentLanguage = null;
  }
}

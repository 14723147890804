import { Questions } from '@shared/enums/questions.enum';
import { OutcomeData, SurveyModel } from '@shared/models/survey.model';
import { hasItemArchivedDependencies, isItemArchived } from '@shared/utilities/survey-data.utilities';

export function getOutcomeScoringProgress(survey: SurveyModel, outcome: OutcomeData): [number, number] {
  if (!survey || !outcome) {
    return [0, 0];
  }

  const questionKeys = survey.questions
    .filter(
      (question) =>
        Questions.scorable(question) &&
        !isItemArchived(question, survey.questions) &&
        !hasItemArchivedDependencies(question, survey.questions),
    )
    .map(({ $key }) => $key);

  const scoredKeys = Object.keys((survey.scoring || {})[outcome.$key] || {}).filter((key) =>
    questionKeys.includes(key),
  );

  return [scoredKeys.length, questionKeys.length];
}

export function getScoringProgress(survey: SurveyModel, outcome: OutcomeData): number {
  const [scored, scorable] = getOutcomeScoringProgress(survey, outcome);

  return scored / scorable || 0;
}

<zef-promo-dialog backgroundImage="assets/images/promo-integrations.png" requiredPlan="plan_genius">
  <span dialog-title i18n>Integrations</span>

  <div dialog-list>
    <span i18n>Native HubSpot integration</span>
    <span i18n>Many custom integrations</span>
    <span i18n>Sync Contacts, send invitations, and more...</span>
  </div>

  <span dialog-button i18n>Get integrations</span>
</zef-promo-dialog>

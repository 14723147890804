<ng-container *ngIf="surveyIntegrationStatus$ | async as status">
  <ng-container *ngIf="shouldShow(status)">
    <button
      *ngIf="shouldShowIcon(status); else raisedButtonTmpl"
      [ngSwitch]="status"
      mat-icon-button
      [matTooltip]="locales[status]"
      matTooltipPosition="above"
      [disabled]="writeAccessDenied$ | async"
      (click)="openIntegration($event)"
    >
      <mat-icon *ngSwitchCase="Status.NotIntegrated" color="ink-lightest">integration_new</mat-icon>
      <mat-icon *ngSwitchCase="Status.Integrated" color="success">integration</mat-icon>
      <mat-icon *ngSwitchCase="Status.Inactive" color="ink-light">integration</mat-icon>
      <mat-icon *ngSwitchCase="Status.Disconnected" color="ink-light">integration</mat-icon>
    </button>

    <ng-template #raisedButtonTmpl>
      <button
        class="zef-ghost-button"
        mat-raised-button
        [disabled]="writeAccessDenied$ | async"
        [color]="status === Status.NotConnected ? 'primary' : 'ink'"
        (click)="openIntegration($event)"
      >
        <mat-icon>integration_new</mat-icon>
        <span i18n>Integrate</span>
      </button>
    </ng-template>
  </ng-container>
</ng-container>

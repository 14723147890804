/**
 * Locale strings related data models.
 *
 * @unstable
 */

export interface Translatable {
  $key: string;
}

export interface LocalesData {
  config: LanguagesData;

  strings: TranslationsData;
}

export interface LanguageData {
  rtl: boolean;
  locale?: string;
  name: string;
}

export interface LanguageData2 extends LanguageData {
  code: string;
  native: string;
  noFlag?: boolean;
  autoTranslatedUi?: boolean;
  autoTranslatedSurvey?: boolean;
  autoTranslatedSharing?: boolean;
  migratedFromDefault?: boolean;
  hidden?: boolean;
  hasMissingTranslations?: boolean;
  otherIcon?: string;
}

export interface LanguagesData {
  [lcid: string]: LanguageData2;
}

export interface TranslationsData {
  [lcid: string]: TranslationData;
}

export type TranslationData = Record<string, string>;

export interface TranslatorEntry {
  /** Text of the entry group */
  text: string;

  /** Keys that values can be read from */
  readKeys: string[];

  /** Keys that have to be updated */
  writeKeys: string[];

  /** Value (translation) of the field */
  value?: string;

  referenceValue?: string;
  targetValue?: string;
  defaultValue?: string;
}

export class PlayerLocales {
  accessibilityMode: string | null = null;
  exitAccessibilityMode: string | null = null;
  enterAccessibilityMode: string | null = null;

  surveyOffline: string | null = null;
  noQuestionsAvailable: string | null = null;
  genericThankYou: string | null = null;

  nextButton: string | null = null;
  backButton: string | null = null;
  reviewButton: string | null = null;
  reviewSingle: string | null = null;
  reviewMulti: string | null = null;
  choiceSelectUpTo: string | null = null;

  okButton: string | null = null;
  finishButton: string | null = null;
  continueButton: string | null = null;

  restartSurvey: string | null = null;

  selectLanguage: string | null = null;
  privacyPolicyText: string | null = null;
  privacyPolicy: string | null = null;
  privacyPolicyLink: string | null = null;
  requiredQuestion: string | null = null;

  donePercentage: string | null = null;
  changeAnswers: string | null = null;
  searchPlaceholder: string | null = null;
  share: string | null = null;
  promoText1: string | null = null;
  promoText2: string | null = null;
  promoTextLink: string | null = null;
  inputEmailErrorText: string | null = null;
  inputPhoneErrorText: string | null = null;
  inputNumberErrorText: string | null = null;
  inputURLErrorText: string | null = null;

  fileUploadDrag: string | null = null;
  fileUploadMax: string | null = null;
  fileUploadMaxAllowed: string | null = null;
  fileUploadExtension: string | null = null;
  fileUploadError: string | null = null;
  fileImageType: string | null = null;
  fileAudioType: string | null = null;
  fileVideoType: string | null = null;

  otherOption: string | null = null;

  reportLanguage: string | null = null;
  reportOutcomes: string | null = null;
  reportShareLinks: string | null = null;
  reportDefaultLink: string | null = null;
  reportTimeline: string | null = null;
  reportSegments: string | null = null;

  zefSurveyUserRatingQuestion: string | null = null;
  zefSurveyUserRatingSignup1: string | null = null;
  zefSurveyUserRatingSignup2: string | null = null;
  zefSurveyUserRatingEmailQuestion: string | null = null;
  zefSurveyUserRatingConfirmationText: string | null = null;
  zefSurveyUserRatingEmailPlaceholderDesktop: string | null = null;
  zefSurveyUserRatingEmailPlaceholderMobile: string | null = null;
}

import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

import { SnackbarService } from '@shared/services/snackbar.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { ChartDistribution, ChartDomain, ChartSettings, DimensionDataItem } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { rotateAnimation } from '@shared/animations/rotate.anim';

import { Charts } from '@shared/enums/charts.enum';

/**
 * This is a word analysis.
 */
@Component({
  selector: 'word-analysis',
  templateUrl: './word-analysis.component.html',
  styleUrls: ['./word-analysis.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotateAnimation],
})
export class WordAnalysis implements OnInit, OnChanges {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() distributions: ChartDistribution[][] = [];
  @Input() stats: any[] = [];
  @Input() filterInput: any[] = [];
  @Input() crossfilter: Crossfilter | null = null;
  @Input() comparison: any;
  @Input() trend: any;
  @Input() totalAnswers: any;

  @Input() trendHoverInput: string = '';
  @Input() update: Date = new Date();

  @Input() chartSettings: ChartSettings = {} as ChartSettings;
  @Input() size: string = '0px';
  @Input() transitionDuration: number = 0;
  @Input() isSharedReport: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() touchDevice: boolean = false;

  @Input() anonymityTreshold: number = null;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() hover: EventEmitter<string> = new EventEmitter<string>();

  readonly chartTypes = Charts;

  public forcedChartSettings: ChartSettings = {
    sortKey: 'value',
    sortDirection: 'desc',
  } as ChartSettings;
  public chartMode: string = 'gauge';

  public comparisonData: any[] = [];

  readonly groups: string[] = ['NOUN', 'ADJ', 'VERB', 'ADV'];
  public groupObjs: { key: string; label: string; distribution: ChartDistribution[]; rows: (string | number)[] }[] = [];
  public grouped: boolean = false;
  public hasGroups: boolean = false;
  public words: ChartDistribution[] = [];
  public wordCloudSizes: { [key: string]: number } = {};
  public languages: any[] = [];
  public langFilter: string = '';
  public rows: (string | number)[] | null = null;

  public sizeUpdate: Date = new Date();
  public isPoweredBy: boolean = false;
  public responses: number = 0;
  public wordsIndex: number = null;
  public timeIndex: number = null;
  public availableHeight: number = 0;

  private dataService: Crossfilter | null = this.cf;

  get Math() {
    return Math;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    readonly ns: SnackbarService,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.wordsIndex = this.details.findIndex((item) => item.originalTypeSpecifier === 'text-words');

    const languages = [];
    if (
      this.details[this.wordsIndex] &&
      this.details[this.wordsIndex]['valueGroupKeys'] &&
      this.details[this.wordsIndex]['valueGroupKeys'].length
    ) {
      for (let i = 0, len = this.details[this.wordsIndex]['valueGroupKeys'].length; i < len; i++) {
        if (this.details[this.wordsIndex]['valueGroupTypes'][i] === 'language') {
          const key = this.details[this.wordsIndex]['valueGroupKeys'][i];
          languages.push(key);
        }
      }
    }
    this.languages = languages;

    if (this.languages.indexOf(this.langFilter) < 0) {
      this.langFilter = '';
    }

    this.isPoweredBy =
      languages.filter((lang) => lang === 'fi' || lang === 'sv' || lang === 'da' || lang === 'no').length > 0;

    if (changes.chartSettings && changes.chartSettings.firstChange) {
      this.chartMode = this.chartSettings.wordChartMode || 'cloud';
      this.grouped = !!this.chartSettings.wordChartGrouped;
    }

    for (const setting in this.chartSettings) {
      if (setting !== 'sortKey' && setting !== 'sortDirection') {
        this.forcedChartSettings[setting] = this.chartSettings[setting];
      }
    }

    if (changes.size) {
      if (this.size) {
        this.availableHeight = parseFloat(this.size);

        this.sizeUpdate = new Date();
      }
    }

    this.responses =
      this.stats && this.stats[this.wordsIndex] && this.stats[this.wordsIndex]['responses'] != null
        ? this.stats[this.wordsIndex]['responses']
        : this.totalAnswers;

    this.updateData();
  }

  private updateData(): void {
    this.groupObjs = [];

    for (const group of this.groups) {
      this.groupObjs.push({
        key: group,
        label:
          this.details[this.wordsIndex] &&
          this.details[this.wordsIndex]['labelsValueGroup'] &&
          this.details[this.wordsIndex]['labelsValueGroup'][group],
        distribution: this.filterDistributionRows(this.distributions[this.wordsIndex], this.getRows(group)),
        rows: this.getRows(group),
      });
    }
    this.hasGroups = this.groupObjs.map((item) => item.rows.length).reduce((a, b) => a + b) > 0;

    if (
      this.isNotFreezed() &&
      this.chartMode !== 'bar' &&
      this.comparison &&
      this.comparison.values &&
      this.comparison.values.length
    ) {
      this.setComparisonData();
    } else {
      this.setData();
      this.comparisonData = [];
    }
    this.cdRef.detectChanges();
  }

  private setData(): void {
    this.rows = this.getRows();
    this.words = this.filterDistributionRows(this.distributions[this.wordsIndex], this.rows);

    this.wordCloudSizes = this.setCloudSizes(
      this.words,
      this.groupObjs.map((group) => group.distribution),
      this.responses,
    );
  }

  private setComparisonData(): void {
    const domain = this.domain[this.comparison.index];
    const wordsDomIndex = this.domain[this.wordsIndex].index;
    const allStats = this.stats[this.comparison.index];
    const allDistributions = this.distributions[this.comparison.index];
    const table = [];
    const wordCloudSizes = [];

    for (let i = 0, len = domain?.keys?.length; i < len; i++) {
      const key = domain.keys[i];
      const label = domain.labels[key];
      const color = domain.colors[key] != null ? Colors.getComparisonColor(domain.colors[key]) : '';
      const statsBase = allStats && allStats.children && allStats.children.find((child) => child && child.key === key);
      const stats = statsBase && statsBase['children'] && statsBase['children'][wordsDomIndex];
      const count = stats && stats.count;
      const average = stats && stats.average;
      const distributionsBase = allDistributions && allDistributions.find((child) => child && child.key === key);
      const distributions =
        distributionsBase &&
        distributionsBase['children'] &&
        this.filterDistributionRows(distributionsBase['children'], this.getRows());
      const responses = stats && stats['responses'];
      const groups = {};

      for (const group of this.groups) {
        groups[group] = this.filterDistributionRows(distributions, this.getRows(group));
      }

      wordCloudSizes.push(this.setCloudSizes(distributions, Object.values(groups), responses));
      table.push({ key, color, label, count, average, stats, distributions, responses, groups });
    }

    const widestWordIndex = wordCloudSizes
      .map((item) => item.widestWordWidth * item.widestWordSize)
      .indexOf(Math.max(...wordCloudSizes.map((item) => item.widestWordWidth * item.widestWordSize)));

    this.wordCloudSizes = {
      count: Math.max(...wordCloudSizes.map((item) => item.count)),
      averageLength: Math.max(...wordCloudSizes.map((item) => item.averageLength)),
      averagePercentage: Math.max(...wordCloudSizes.map((item) => item.averagePercentage)),
      widestWordWidth: wordCloudSizes[widestWordIndex]?.widestWordWidth,
      widestWordSize: wordCloudSizes[widestWordIndex]?.widestWordSize,
    };
    this.comparisonData = table;
  }

  private setCloudSizes(words: ChartDistribution[], groupDistributions, totalAnswers): { [key: string]: number } {
    let count: number = 0;
    let sum: number = 0;
    let wordLengthSum: number = 0;
    let widestWordWidth: number = 0;
    let widestWordSize: number = 0;

    const groupSeparator: string = '\u001D';
    const labels: any = this.dataService.getTextLabels()[this.domain[this.wordsIndex].key] || {};

    for (let i = 0, len = (words || []).length; i < len; i++) {
      const d: any = words[i];
      const percentage = d.value / totalAnswers;
      const word: string = (labels[d.key] || '').split(groupSeparator)[0] || '';

      if (d.value > 0) {
        sum += percentage;
        count += percentage > 0 && word.length > 0 ? 1 : 0;
        wordLengthSum += word.length;

        if (word.length * percentage > widestWordWidth * widestWordSize) {
          widestWordWidth = word.length;
          widestWordSize = percentage;
        }
      }
    }

    const averagePercentage: number = sum / count;
    const averageLength: number = wordLengthSum / count;

    return {
      count: Math.max(
        ...(groupDistributions || []).map(
          (item) => (item || []).map((distr) => distr.value).filter((val) => val).length,
        ),
        !this.grouped ? count : 0,
      ),
      ...(!isNaN(averageLength) ? { averageLength } : { averageLength: 0 }),
      ...(!isNaN(averagePercentage) ? { averagePercentage } : { averagePercentage: 0 }),
      widestWordWidth,
      widestWordSize,
    };
  }

  private getRows(groupKey: string = ''): (string | number)[] | null {
    const groupIndex: number =
      groupKey &&
      this.details[this.wordsIndex] &&
      this.details[this.wordsIndex]['valueGroupKeys'] &&
      this.details[this.wordsIndex]['valueGroupValues'] &&
      this.details[this.wordsIndex]['valueGroupKeys'].indexOf(groupKey) >= 0
        ? this.details[this.wordsIndex]['valueGroupKeys'].indexOf(groupKey)
        : -1;

    const languageIndex: number =
      this.langFilter &&
      this.details[this.wordsIndex] &&
      this.details[this.wordsIndex]['valueGroupKeys'] &&
      this.details[this.wordsIndex]['valueGroupValues'] &&
      this.details[this.wordsIndex]['valueGroupKeys'].indexOf(this.langFilter) >= 0
        ? this.details[this.wordsIndex]['valueGroupKeys'].indexOf(this.langFilter)
        : -1;

    return groupIndex >= 0 || languageIndex >= 0
      ? groupIndex >= 0 && languageIndex >= 0
        ? this.details[this.wordsIndex]['valueGroupValues'][languageIndex].filter((element) =>
            this.details[this.wordsIndex]['valueGroupValues'][groupIndex].includes(element),
          )
        : groupIndex >= 0 && languageIndex < 0
        ? this.details[this.wordsIndex]['valueGroupValues'][groupIndex]
        : this.details[this.wordsIndex]['valueGroupValues'][languageIndex]
      : null;
  }

  private filterDistributionRows(data: ChartDistribution[], rows: (string | number)[] | null = null) {
    const groupFilter = (row) => rows.indexOf(Number(row.key)) >= 0;

    return rows == null || !data ? data : data.filter(groupFilter);
  }

  trackByTextItem(i: number, item: any): string {
    return item.text;
  }

  trackByKey(i: number, item: any): string {
    return item.key;
  }

  trackByRow(i: number, row: any[]): number {
    return (row && row[0] && row[0]['value']) || null;
  }

  public changeChartMode(mode: string): void {
    this.chartMode = mode;
    this.updateData();
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    this.settingsChanged();
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      wordChartMode: this.chartMode,
      wordChartGrouped: this.grouped,
    });
  }

  public isNotFreezed(): boolean {
    return !(this.dataService.getTextFreezingStatus() && this.isSharedReport) && !this.anonymityTreshold;
  }

  public groupWords($event) {
    this.grouped = $event && $event.checked;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    this.settingsChanged();
  }

  public filterByLanguage(lang: string): void {
    this.langFilter = lang;
    this.updateData();
  }
}

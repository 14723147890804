<zef-sidenav
  *ngIf="{
    status: sii.integrationStatus$ | async,
    integration: sii.surveyIntegration$ | async,
    service: sii.serviceIntegration$ | async,
    canEdit: sii.canEdit$ | async
  } as survey"
  [loading]="sidenavLoading$ | async"
  [confirmClose]="canSave$ | async"
>
  <ng-container *zefSidenavTitle i18n>Export survey respondents</ng-container>

  <div *zefSidenavButtonPrimary class="z-fx-sc z-fx-gap-16">
    <ng-container *ngIf="survey.status !== Status.NotConnected">
      <mat-slide-toggle
        labelPosition="before"
        [checked]="survey.status === Status.NotIntegrated || survey.status === Status.Integrated"
        [disabled]="survey.status === Status.NotIntegrated || survey.status === Status.Disconnected || !survey.canEdit"
        matTooltip="Integration is disconnected"
        [matTooltipDisabled]="survey.status !== Status.Disconnected"
        i18n-matTooltip
        color="primary"
        (change)="sii.toggleIntegration($event.checked)"
        i18n
      >
        Active
      </mat-slide-toggle>

      <mat-divider [vertical]="true" class="zef-divider-16"></mat-divider>

      <button
        mat-raised-button
        [disabled]="!survey.canEdit || (canSave$ | async) !== true"
        [loading]="loadingSave$ | async"
        [color]="survey.status === Status.NotIntegrated ? 'success' : 'primary'"
        (click)="onSave()"
      >
        <ng-container *ngIf="survey.status === Status.NotIntegrated" i18n>Create integration</ng-container>
        <ng-container *ngIf="survey.status !== Status.NotIntegrated" i18n>Save</ng-container>
      </button>
    </ng-container>
  </div>

  <mat-menu
    *ngIf="survey.status !== Status.NotIntegrated && survey.status !== Status.NotConnected"
    zefSidenavMenu
    class="zef-menu-combo"
  >
    <button
      mat-menu-item
      [inlineDialogTriggerFor]="confirmDeleteDialog"
      class="zef-menu-item-alert"
      [disabled]="!survey.canEdit"
    >
      <mat-icon>action_delete</mat-icon>

      <span i18n>Delete survey integration...</span>

      <zef-inline-dialog #confirmDeleteDialog [loading]="loadingDelete$ | async" (confirm)="onDelete()">
        <ng-container *dialogTitle i18n>Confirm delete?</ng-container>

        <ng-container *dialogContent i18n>
          This will permanently delete this integration and its settings. Previous exports are not affected.
        </ng-container>

        <ng-container *dialogAction>Delete survey integration</ng-container>
      </zef-inline-dialog>
    </button>
  </mat-menu>

  <div *zefSidenavContent>
    <survey-integration-sidenav-header></survey-integration-sidenav-header>

    <mat-divider class="zef-divider-thick"></mat-divider>

    <ng-container *ngIf="survey.status !== Status.NotConnected">
      <div *ngIf="!survey.canEdit" class="integration-rights zef-text-p2-muted" i18n>
        An
        <user-role role="owner"></user-role>
        or
        <user-role role="admin"></user-role>
        can edit survey integration settings
      </div>

      <ng-container *ngFor="let feature of features$ | async" [ngSwitch]="feature">
        <survey-integration-feature-note *ngSwitchCase="Feature.Note"></survey-integration-feature-note>

        <survey-integration-feature-property *ngSwitchCase="Feature.Property"></survey-integration-feature-property>
      </ng-container>
    </ng-container>
  </div>
</zef-sidenav>

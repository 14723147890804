import * as d3 from 'd3';

import { combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  OnChanges,
  Output,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { DataConverter } from '@report/shared/services/data-converter.service';

import { ChartDomain, DimensionDataItem, ChartDistribution, NPSData, ChartSettings } from '@shared/models/report.model';

/**
 * This is a NPS Chart.
 */
@Component({
  selector: 'nps-chart',
  templateUrl: './nps-chart.component.html',
  styleUrls: ['./nps-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NPSChart implements OnInit, OnChanges {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() distribution: ChartDistribution[][] = [];
  @Input() filterInput: any[] = [];
  @Input() scale: any;
  @Input() showNumbers: boolean = false;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() anonymityTreshold: number = null;
  @Input() hideDistribution: boolean = false;
  @Input() size: string = '0px';
  @Input() bigTable: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() update: Date = new Date();
  @Input() transition: number = 0;
  @Input() comparison: any;
  @Input() chartHeight: string = '';
  @Input() chartSettings: ChartSettings = {} as ChartSettings;
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  // BehaviorSubjects
  public searchUpdate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sortKey$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sortDirection$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public NPSdata$: BehaviorSubject<NPSData[]> = new BehaviorSubject<NPSData[]>([]);
  public NPStable$: BehaviorSubject<NPSData[]> = new BehaviorSubject<NPSData[]>([]);

  // Variables used in HTML template
  public hoveredElement: number | null = null;
  public npsDomain: ChartDomain = {} as ChartDomain;
  public npsDomIndex: number = 0;
  public sizeUpdate: Date = new Date();
  public hoveredContent: string = '';
  public tooltipPosition: number[] = [];
  public exportChart: boolean = false;

  // Settings
  public chartMode: string = 'gauge';
  public displayedColumns: string[] = ['title', 'count', 'npsScore'];
  public listModeOn: boolean = false;
  public showSearchBar: boolean = false;
  public wideModeOn: boolean = false;

  @ViewChild('tooltip') tooltipEl!: ElementRef;

  constructor(
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
    readonly dc: DataConverter,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    combineLatest(this.NPSdata$, this.searchUpdate$, this.sortKey$, this.sortDirection$)
      .pipe(takeUntil(this.hooks.destroy))
      .subscribe(([npsData, searchTerm, sortKey, sortDirection]) => {
        const npsArray = npsData.slice();
        let filteredList: any[];

        if (!searchTerm) {
          filteredList = npsArray;
        } else {
          const filteredResults = npsArray.filter(
            (item) =>
              item && item.title != null && item.title.toString().toLowerCase().includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        const numberCheck: (val: string | number, count: number) => number = (val: string | number, count: number) =>
          !isNaN(Number(val)) && (!this.anonymityTreshold || count >= this.anonymityTreshold) ? Number(val) : -200;

        const sortedList = filteredList.sort((a, b) => {
          if (sortKey === 'detractor' && a?.['distribution']?.[0] && b?.['distribution']?.[0]) {
            if (
              numberCheck(a['distribution'][0]['value'], a['count']) >
              numberCheck(b['distribution'][0]['value'], b['count'])
            ) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (
              numberCheck(a['distribution'][0]['value'], a['count']) <
              numberCheck(b['distribution'][0]['value'], b['count'])
            ) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else if (sortKey === 'passive' && a?.['distribution']?.[1] && b?.['distribution']?.[1]) {
            if (
              numberCheck(a['distribution'][1]['value'], a['count']) >
              numberCheck(b['distribution'][1]['value'], b['count'])
            ) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (
              numberCheck(a['distribution'][1]['value'], a['count']) <
              numberCheck(b['distribution'][1]['value'], b['count'])
            ) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else if (sortKey === 'promoter' && a?.['distribution']?.[2] && b?.['distribution']?.[2]) {
            if (
              numberCheck(a['distribution'][2]['value'], a['count']) >
              numberCheck(b['distribution'][2]['value'], b['count'])
            ) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (
              numberCheck(a['distribution'][2]['value'], a['count']) <
              numberCheck(b['distribution'][2]['value'], b['count'])
            ) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else if (sortKey === 'npsScore') {
            if (numberCheck(a[sortKey], a['count']) > numberCheck(b[sortKey], b['count'])) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (numberCheck(a[sortKey], a['count']) < numberCheck(b[sortKey], b['count'])) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          } else {
            if (a[sortKey] > b[sortKey]) {
              return sortDirection === 'asc' ? 1 : -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return sortDirection === 'asc' ? -1 : 1;
            }
          }

          return 0;
        });

        this.hoveredElement = null;
        this.NPStable$.next(sortedList);
        this.cdRef.markForCheck();
      });

    const base = d3.select(this._element.nativeElement);

    base
      .on('mousemove', (event) => {
        const pos = d3.pointer(event);

        if (this.hoveredElement != null) {
          const w = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetWidth) || 0;
          const h = (this.tooltipEl && this.tooltipEl.nativeElement && this.tooltipEl.nativeElement.offsetHeight) || 0;

          this.tooltipPosition[0] = pos[0] - w / 2;
          this.tooltipPosition[1] = pos[1] - h - 16;
          this.cdRef.detectChanges();
        }
      })
      .on('mouseout', () => {
        this.hoveredElement = null;
        this.cdRef.detectChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setEnvironment();

    if (changes.chartSettings && changes.chartSettings.firstChange) {
      this.sortKey$.next(this.chartSettings.sortKey || '');
      this.sortDirection$.next(this.chartSettings.sortDirection || '');
      this.chartMode = this.chartSettings.npsChartMode || 'gauge';
    }

    if (
      changes.distribution ||
      changes.domain ||
      changes.details ||
      changes.filterInput ||
      changes.update ||
      changes.comparison ||
      changes.chartHeight
    ) {
      this.setData();
    }
  }

  private setEnvironment(): void {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');
    this.wideModeOn = this._element.nativeElement.clientWidth > 700;
    this.displayedColumns = this.wideModeOn
      ? ['title', 'count', 'npsScore', 'detractor', 'passive', 'promoter']
      : ['title', 'count', 'npsScore'];
  }

  private setData(): void {
    let catIndex: number;

    for (let dom = 0, len = this.domain.length; dom < len; dom++) {
      if (this.domain[dom].scale === 'categorical') {
        catIndex = dom;
      } else if (this.domain[dom].scale === 'linear') {
        this.npsDomIndex = dom;
        this.npsDomain = this.domain[dom];
      }
    }

    const NPSdata: NPSData[] = this.dc.calculateNPS(
      this.distribution[catIndex != null ? catIndex : this.npsDomIndex],
      this.domain[catIndex],
    );

    this.listModeOn = catIndex != null;
    this.showSearchBar = NPSdata.length > 4;

    if (this.chartMode === 'bar' && this.listModeOn && this.anonymityLock) {
      this.chartMode = 'gauge';
    }

    this.NPSdata$.next(NPSdata);
  }

  private hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }

  public onSortColumn(column: string): void {
    if (this.sortKey$.value === column && this.sortDirection$.value) {
      if (column === 'title') {
        if (this.sortDirection$.value === 'asc') {
          this.sortDirection$.next('desc');
        } else {
          this.sortDirection$.next('');
          this.sortKey$.next('');
        }
      } else {
        if (this.sortDirection$.value === 'desc') {
          this.sortDirection$.next('asc');
        } else {
          this.sortDirection$.next('');
          this.sortKey$.next('');
        }
      }
    } else {
      this.sortKey$.next(column);
      this.sortDirection$.next(column === 'title' ? 'asc' : 'desc');
    }
    this.settingsChanged();
  }

  public changeChartMode(mode: string): void {
    this.chartMode = mode;
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
    this.settingsChanged();
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      npsChartMode: this.chartMode,
    });
  }

  public onHover(element: NPSData | null): void {
    const isSafe: boolean = !this.anonymityTreshold || element?.count >= this.anonymityTreshold;
    const score = isSafe
      ? `<span class="icon">chart_nps</span> ${element?.npsScore}`
      : `<span class="icon zef-color-ink">anonymous_on</span>`;

    if (element?.index !== this.hoveredElement) {
      this.hoveredElement = element?.index;
      this.hoveredContent = `<div class="question">${element?.title}</div>
      <div class="stats"><span class="icon">contact</span> ${
        isSafe ? element?.count : $localize`under ` + this.anonymityTreshold
      } ${score}</div>`;

      this.cdRef.detectChanges();
    }
  }

  public isAnonymitySafe(distr) {
    return !this.anonymityLock || distr?.every((d) => d?.value >= this.anonymityTreshold);
  }
}

import { FeedbackSurvey } from '@shared/modules/feedback/feedback.models';

export class GetFeedbackStatus {
  static readonly type = '[Feedback] Get Status';
}

export class TriggerFeedbackSurvey {
  static readonly type = '[Feedback] Trigger Survey';

  constructor(readonly survey: FeedbackSurvey, readonly delay: number = 0) {}
}

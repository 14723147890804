import * as d3 from 'd3';

import * as cloud from 'd3-cloud';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { ChartDistribution, ChartDomain, CanvasContext } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Word Cloud.
 */
@Directive({
  selector: '[wordCloud]',
})
export class WordCloud implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() color: string = '';
  @Input() hideRespondentCounts: boolean = false;
  @Input() totalAnswers: number = 0;
  @Input() crossfilter: Crossfilter | null = null;
  @Input() touchDevice: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() wordCloudSizes: { [key: string]: number } = {};

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private cloud: any[] = [];

  private responses: number = 0;
  private previousResponses: number = 0;

  private dataService: Crossfilter | null = null;

  private tooltip: any;

  private width: any;
  private height: number = 0;
  private margin: any = { top: 20, right: 20, bottom: 20, left: 20 };
  private fontSize: number = 0;
  private unit: number = 0;

  private filter: any;

  private selections: any = new Set();

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.totalAnswers ||
      changes.hideRespondentCounts
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'word-cloud');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 3 * this.unit,
      right: 2 * this.unit,
      bottom: 2 * this.unit,
      left: 2 * this.unit,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;

    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }
  }

  setScales() {
    const groupSeparator: string = '\u001D';

    if (this.height > 0 && this.width > 0) {
      const data: any[] = [];
      const labels: any =
        this.domain.key.indexOf(':words') >= 0 || this.domain.scale === 'text'
          ? this.dataService.getTextLabels()[this.domain.key] || []
          : this.domain.labels;

      let wordsToShow: number = 0;
      let sum: number = 0;
      let wordLengthSum: number = 0;
      let widestWord: { width: number; size: number } = { width: 0, size: 0 };

      for (let i = 0, len = (this.data || []).length; i < len; i++) {
        const d: any = this.data[i];
        const key = this.domain.key.indexOf(':words') >= 0 ? Number(d.key) : d.key;
        const percentage = d.value / this.totalAnswers;
        if (d.value > 0) {
          let word: string = (labels[key] || '').split(groupSeparator)[0] || '';
          const existingIndex = data.findIndex((item) => item.word === word);

          if (existingIndex >= 0) {
            if (!data[existingIndex]['otherKeysIncluded']) {
              data[existingIndex]['otherKeysIncluded'] = [];
            }
            data[existingIndex]['otherKeysIncluded'].push(key);
            data[existingIndex]['value'] += d.value;
            data[existingIndex]['percentage'] += percentage;

            sum += percentage;

            if (word.length * data[existingIndex].percentage > widestWord.width * widestWord.size) {
              widestWord = { width: word.length, size: data[existingIndex].percentage };
            }
          } else {
            word = word.length > 30 ? word.slice(0, 30) + '...' : word;
            let hash = '';

            for (let h = 0, lenh = word.length; h < lenh; h++) {
              hash += 'JJ';
            }

            data.push({
              key,
              word,
              hash,
              value: d.value,
              percentage,
            });

            sum += percentage;
            wordsToShow += percentage > 0 && word.length > 0 ? 1 : 0;
            wordLengthSum += word.length;

            if (word.length * percentage > widestWord.width * widestWord.size) {
              widestWord = { width: word.length, size: percentage };
            }
          }
        }
      }

      let maxSize: number = Math.min(this.height, this.width) * 0.14;

      const average: number =
        this.wordCloudSizes?.averagePercentage != null ? this.wordCloudSizes.averagePercentage : sum / wordsToShow;
      const averageLength: number =
        this.wordCloudSizes?.averageLength != null ? this.wordCloudSizes.averageLength : wordLengthSum / wordsToShow;
      wordsToShow = this.wordCloudSizes?.count != null ? this.wordCloudSizes.count : wordsToShow;
      widestWord =
        this.wordCloudSizes?.widestWordWidth != null && this.wordCloudSizes?.widestWordSize != null
          ? { width: this.wordCloudSizes.widestWordWidth, size: this.wordCloudSizes.widestWordSize }
          : widestWord;
      const ellipseReduce: number = (Math.PI * (this.height / 2) * (this.width / 2)) / (this.height * this.width);
      const wordsPerWidth: (number) => number = (max) => this.width / (averageLength * 0.9 * (max + 2) * average);
      const wordsPerHeight: (number) => number = (max) => this.height / ((max + 2) * average);
      const maxWords: (number) => number = (max) =>
        Math.floor(wordsPerHeight(max)) * Math.floor(wordsPerWidth(max)) * ellipseReduce;

      let updatedSize: number = maxSize;

      if (maxWords(maxSize) < wordsToShow * average) {
        while (updatedSize > 1 && maxWords(updatedSize) < wordsToShow * average) {
          updatedSize--;
        }
      } else {
        while (
          maxWords(updatedSize) >= wordsToShow * average * 2.5 &&
          widestWord.width * updatedSize * widestWord.size < this.width &&
          widestWord.size * updatedSize < this.height / 5 &&
          wordsToShow > 0
        ) {
          updatedSize++;
        }
      }

      if (widestWord.width * updatedSize * widestWord.size * 0.9 > this.width) {
        while (updatedSize > 1 && widestWord.width * updatedSize * widestWord.size * 0.9 > this.width) {
          updatedSize--;
        }
      }

      maxSize = updatedSize;

      const getCloud = (size) => {
        if (wordsToShow > 0) {
          cloud()
            .size([this.width, this.height])
            .words(data.sort((a, b) => b.value - a.value).slice(0, 100))
            .rotate(function () {
              return 0;
            })
            .font('Open Sans')
            .padding(1)
            .text((d) => d.hash)
            .timeInterval(750)
            .random((d) => 0.5)
            .fontSize((d) => d.percentage * size)
            .on('end', (d) => {
              this.cloud = d;
            })
            .start();
        } else {
          this.cloud = [];
        }
      };

      this.cloud = [];

      getCloud(maxSize);
    } else {
      this.cloud = [];
    }

    this.previousResponses = this.responses;
    this.responses = this.totalAnswers;
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        const area = d3.pointer(event);
        __this.selectForHover(event, area);
      }
    };

    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              x: d3.interpolateNumber(a[i]['x'], b[i]['x']),
              y: d3.interpolateNumber(a[i]['y'], b[i]['y']),
              size: d3.interpolateNumber(a[i]['size'], b[i]['size']),
            };
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['x'] = x[i]['x'](t);
              c[i]['y'] = x[i]['y'](t);
              c[i]['size'] = x[i]['size'](t);
            }
            return c;
          };
        };

        const interpolator = interpolateArray(dataObj, d);
        const interpolateResponses = d3.interpolateNumber(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let responses;

          if (step >= 1) {
            data = d;
            responses = __this.responses;
            timer.stop();
          } else {
            data = interpolator(ease(step));
            responses = Math.round(interpolateResponses(ease(step)));
          }

          __this.setHeader(context, responses);
          __this.setWords(context, data);
        });
      } else {
        __this.setHeader(context, __this.responses);
        __this.setWords(context, d);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.word-cloud-canvas').data([this.cloud]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'word-cloud-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event, d) {
        __this.setTooltip(d3.pointer(event));
      })
      .on('click', function (event, d) {
        if (__this.filtering && !__this.anonymityLock) {
          const area = d3.pointer(event);
          __this.selectFromWords(area);

          __this.callFilter();
        }
      })
      .each(drawContent);
  }

  setHeader(context, responses: number) {
    if (!this.hideRespondentCounts) {
      context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);
      context.fillStyle = this.color || Colors.TEXT;
      context.textAlign = 'left';
      context.textBaseline = 'middle';

      const h = this.margin.top / 2;

      const wIcon = this.fontSize + 4;

      context.font = 10 / 14 + 'em Open Sans';
      const wNumber = context.measureText(responses).width + 8;

      context.font = 12 / 14 + 'em Open Sans';
      const title = this.title ? shortenText(context, this.title, this.width, 8 + (wIcon + wNumber) / 2) : '';

      const wTitle = title ? context.measureText(title).width + 8 : 0;

      const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

      if (title) {
        context.fillText(title, startPoint, h);
      }

      drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

      context.font = 10 / 14 + 'em Open Sans';
      context.fillText(responses, startPoint + wTitle + wIcon, h);
    }
  }

  setWords(context, data: any[] = [], highlight: any[] = []) {
    context.clearRect(
      0,
      this.margin.top - 2,
      this.margin.left + this.margin.right + this.width,
      this.margin.bottom + this.height,
    );
    context.translate(this.margin.left + this.width / 2, this.margin.top + this.height / 2);
    this.selections = new Set();

    for (let i = 0, len = data.length; i < len; i++) {
      context.font = data[i].size + 'px Open Sans';
      context.textAlign = 'center';
      context.textBaseline = 'middle';

      data[i].actualWidth = context.measureText(data[i].word).width;

      if (highlight.indexOf(data[i].key) >= 0) {
        context.strokeStyle = '#000';
        context.lineWidth = 2;
        context.strokeText(data[i].word, data[i].x, data[i].y);
      }
      context.fillStyle = this.color || Colors.TEXT;

      if (this.filterInput && this.filterInput.length > 0) {
        const xFi = this.filterInput.indexOf(data[i].key) > -1;

        if (!xFi) {
          context.fillStyle = Colors.UNSELECTED;
        } else {
          context.fillStyle = this.color || Colors.TEXT;
          this.selections.add(data[i].key);
          if (data[i].otherKeysIncluded && data[i].otherKeysIncluded.length) {
            this.selections.add(...data[i].otherKeysIncluded);
          }
        }
      }

      context.fillText(data[i].word, data[i].x, data[i].y);
    }

    context.translate(-(this.margin.left + this.width / 2), -(this.margin.top + this.height / 2));
  }

  setTooltip(position, data: any[] = []) {
    const __this = this;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip.html(
      (d) => `
            <div class="question">${d.word}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
    );

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(
        (d) => `
            <div class="question">${d.word}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      );

    // adding hovering effect
    this.base.select('.word-cloud-canvas').each(function (d) {
      const highlight = data.map((item) => item.key);

      __this.setWords(__this.context.context, d, highlight);
    });

    // positioning tooltip
    d3.select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .style('transform', function (d, i, g: any) {
        const el: any = this;
        let sumHeight = 15;
        for (let e = 0, len = g.length; e < len; e++) {
          if (e <= i) {
            sumHeight += g[e].getBoundingClientRect().height + 10;
          }
        }
        return `translate(${position[0] - el.getBoundingClientRect().width / 2}px,${position[1] - sumHeight}px)`;
      });

    if (data.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.select('.word-cloud-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.word-cloud-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock && !(this.dataService.getTextFreezingStatus() && this.isSharedReport)) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.dataService.filter(this.filter);
      }
    }
  }

  // Helpers
  selectForHover(event, area) {
    const items = this.itemsBelow(area);

    if (items.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement), items);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    }
  }

  selectFromWords(area) {
    const items = this.itemsBelow(area);

    for (let s = 0, len = items.length; s < len; s++) {
      if (this.selections.has(items[s].key)) {
        this.selections.delete(items[s].key);
        if (items[s].otherKeysIncluded && items[s].otherKeysIncluded.length) {
          this.selections.delete(...items[s].otherKeysIncluded);
        }
      } else {
        this.selections.add(items[s].key);
        if (items[s].otherKeysIncluded && items[s].otherKeysIncluded.length) {
          this.selections.add(...items[s].otherKeysIncluded);
        }
      }
    }
  }

  itemsBelow(area) {
    const items = this.cloud.filter(
      (item) =>
        area[0] < item.x + item.actualWidth / 2 + this.margin.left + this.width / 2 &&
        area[0] > item.x - item.actualWidth / 2 + this.margin.left + this.width / 2 &&
        area[1] < item.y + item.size / 2 + this.margin.top + this.height / 2 &&
        area[1] > item.y - item.size / 2 + this.margin.top + this.height / 2,
    );

    return items;
  }
}

/**
 * State container for account related data.
 *
 * @unstable
 */
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { Commands } from '@shared/enums/commands.enum';
import { Rights } from '@shared/enums/rights.enum';
import {
  EmailSenderData,
  InviteData,
  TeamAdminSettings,
  TeamData,
  UserData,
  UserDiscoveredQuestion,
} from '@shared/models/account.model';
import { IdentityData } from '@shared/models/prefs.model';
import { shareRef } from '@shared/operators/share-ref.operator';
import { AccountManager } from '@shared/services/account-manager.service';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { RightsManager } from '@shared/services/rights-manager.service';
import { SourceTypeService } from '@shared/services/source-type.service';
import {
  AcceptTeamInvite,
  AuthDoneFetchAccountData,
  CreateTeam,
  GetTeam,
  GetUser,
  GetUserInvites,
  JoinTeam,
  LeaveTeam,
  PostLoginSetupAndRedirect,
  RejectTeamInvite,
  SetTeamColor,
  SetTeamLogo,
  SetTeamName,
  SetVisitorId,
  SwitchTeam,
  VerifyUser,
} from '@shared/states/account.actions';
import { ACCOUNT_STATE_TOKEN, AccountStateModel, TeamMembersCount } from '@shared/states/account.models';
import { SignOutWithRedirect } from '@shared/states/auth.actions';
import { AuthState, AuthStateModel } from '@shared/states/auth.state';
import { RouterState } from '@shared/states/router.state';
import { teamLogoPlaceholder } from '@shared/utilities/assets.utilities';
import { pickBy } from '@shared/utilities/object.utilities';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { assertArray } from '@shared/utilities/array.utilities';
import { parseDefaultAdminSettings } from '@shared/utilities/team.utilities';

@Injectable()
@State<AccountStateModel>({
  name: ACCOUNT_STATE_TOKEN,
  defaults: {
    team: null,
    user: null,
    visitorId: null,
  },
})
export class AccountState implements NgxsOnInit {
  @LocalStorage('report-team') static privateReportTeamKey: string | undefined;

  constructor(
    private store: Store,
    private am: AccountManager,
    private st: SourceTypeService,
    private cf: CloudFunctions,
    private rm: RightsManager,
  ) {}

  ngxsOnInit(): void {
    this.st.registerSourceType('team', {
      action: GetTeam,
      storeSelector: () => AccountState.team,
      defaultKey: () => AccountState.teamKey,
      obs: {},
    });
  }

  /** SELECTORS */

  @Selector([AuthState])
  static isAccountOk({ team, user }: AccountStateModel, { userInfo }: AuthStateModel) {
    const isAnonymous = Boolean(userInfo?.isAnonymous);

    const isInfoLoaded = !!userInfo;
    const isDataLoaded = !!team && !!user;

    return isAnonymous ? isInfoLoaded : isDataLoaded;
  }

  @Selector()
  static team(state: AccountStateModel): TeamData {
    return state.team;
  }

  @Selector([AccountState.team])
  static adminSettings(_, team: TeamData): TeamAdminSettings {
    return team?.adminSettings || parseDefaultAdminSettings({});
  }

  @Selector([AccountState.adminSettings])
  static extensions(_, { extensions }: TeamAdminSettings): string[] {
    return assertArray(extensions);
  }

  @Selector()
  static activeTeamKey(state: AccountStateModel): string {
    return state.user?.team;
  }

  @Selector()
  static defaultTeamKey(state: AccountStateModel): string {
    const userTeams = state.user?.teams || {};

    return '';
  }

  @Selector()
  static user(state: AccountStateModel): UserData {
    return state.user;
  }

  @Selector()
  static userDiscoveredQuestions(state: AccountStateModel): Record<string, UserDiscoveredQuestion> {
    return state.user?.discoveredQuestions || {};
  }

  @Selector()
  static visitorId({ visitorId }: AccountStateModel): string {
    return (visitorId || '').split('_')[0] || '';
  }

  @Selector()
  static teamKeyUserKey(state: AccountStateModel): [string, string] {
    return [this.teamKey(state), this.userKey(state)];
  }

  @Selector()
  static surveyRights(state: AccountStateModel): { [surveyKey: string]: Rights } {
    const teamKey = state.user?.team;
    const rights = state.user?.surveys?.[teamKey] || {};
    return pickBy(rights, (val) => val > 0);
  }

  @Selector()
  static userSurveys(state: AccountStateModel): string[] {
    const surveyRights = AccountState.surveyRights(state);
    return Object.keys(surveyRights || {});
  }

  @Selector()
  static userAvatar(state: AccountStateModel): string {
    const teamKey = state.user?.team;
    const identity: IdentityData = state.user?.identities?.[teamKey];

    return identity?.avatar;

    // TODO: Check provider data ??
    // return state.info && (state.info.photoURL || state.info.providerData.find(i => !!i.photoURL).photoURL);
  }

  @Selector()
  static identity(state: AccountStateModel): IdentityData {
    const teamKey = state.user?.team;
    return state.user?.identities?.[teamKey];
  }

  @Selector()
  static invites(state: AccountStateModel): InviteData[] {
    return state.user?.invites || [];
  }

  @Selector()
  static isValidIdentity(state: AccountStateModel): boolean {
    const teamKey = state.user?.team;
    const identityData = state.user?.identities?.[teamKey] || {};
    return 'email' in identityData && ('firstName' in identityData || 'lastName' in identityData);
  }

  @Selector()
  static canNameTeamOnSignup(state: AccountStateModel): boolean {
    return !state.team?.name;
  }

  @Selector()
  static userKey(state: AccountStateModel): string {
    return state.user?.$key || null;
  }

  @Selector()
  static userTeams(state: AccountStateModel): string[] {
    return (
      (state.user?.teams &&
        Object.entries(state.user.teams)
          .filter(([_, teamRights]) => teamRights > 0)
          .map(([teamKey]) => teamKey)) ||
      []
    );
  }

  @Selector()
  static userEmail(state: AccountStateModel): string {
    const teamKey = state.user?.team;
    const identityData = state.user?.identities?.[teamKey] || ({} as IdentityData);
    return identityData.email;
  }

  @Selector()
  static userPhone(state: AccountStateModel): string {
    const teamKey = state.user?.team;
    const identityData = state.user?.identities?.[teamKey] || ({} as IdentityData);
    return identityData.phone;
  }

  @Selector()
  static teamKey(state: AccountStateModel): string {
    // Anonymous users don't have team data,
    // we use default team from user data
    return state.team?.$key || state.user?.team || AccountState.privateReportTeamKey || null;
  }

  @Selector()
  static teamLogo({ team }: AccountStateModel): string {
    return team?.logo || teamLogoPlaceholder(team?.created);
  }

  @Selector()
  static teamUsers({ team }: AccountStateModel): Record<string, Rights> {
    return team?.users || {};
  }

  @Selector()
  static teamColor({ team }: AccountStateModel): string {
    const defaultColor = '#EAEFF1';
    return team?.color || defaultColor;
  }

  @Selector()
  static teamMembersCount({ team }: AccountStateModel): TeamMembersCount {
    const rights = Object.values(team?.users || {});

    const count: TeamMembersCount = {
      owner: rights.filter((right) => right === Rights.OWNER).length,
      admin: rights.filter((right) => right === Rights.ADMIN).length,
      member: rights.filter((right) => right < Rights.ADMIN && right !== Rights.NONE).length,
      total: rights.filter((right) => right !== Rights.NONE).length,
    };

    return count;
  }

  @Selector()
  static userRole(state: AccountStateModel): Rights {
    const teamKey = state.user?.team;
    return state?.user?.teams?.[teamKey] || 0;
  }

  @Selector([RouterState.routeParams])
  static surveyRight(state: AccountStateModel, params: Params): Rights {
    const teamKey = state.user?.team;
    const surveyKey = params.survey;

    return state.user?.surveys?.[teamKey][surveyKey];
  }

  @Selector([AccountState.userRole])
  static isTeamOwner(state: AccountStateModel, teamRight: Rights) {
    return Rights.hasRights(Rights.OWNER, teamRight);
  }

  @Selector([AccountState.userRole])
  static isTeamAdmin(state: AccountStateModel, teamRight: Rights) {
    return Rights.hasRights(Rights.ADMIN, teamRight);
  }

  @Selector([AccountState.userRole])
  static isTeamMember(state: AccountStateModel, teamRight: Rights) {
    return Rights.hasRights(Rights.EDIT, teamRight);
  }

  static inviteData(inviteKey: string) {
    return createSelector([AccountState], (state: AccountStateModel) =>
      (state.user?.invites || []).find((data) => data.$key === inviteKey),
    );
  }

  @Selector([AccountState.adminSettings])
  static emailSender(_, { emailSender }: TeamAdminSettings): EmailSenderData {
    return emailSender || { address: 'noreply', domain: 'zeffimail.com', forwardTo: '', readonly: false };
  }

  @Selector([AccountState.adminSettings])
  static previousEmailSender(_, { previousEmailSender }: TeamAdminSettings): Partial<EmailSenderData> | undefined {
    return previousEmailSender;
  }

  @Selector([AccountState.emailSender])
  static emailForwardTo(_, emailSender: EmailSenderData): string {
    return emailSender?.forwardTo || '';
  }

  @Selector([AccountState.emailSender])
  static emailSenderAddress(_, emailSender: EmailSenderData): string {
    const defaultEmail = 'noreply@zeffimail.com';

    if (emailSender) {
      const { address, domain } = emailSender;
      return `${address}@${domain}`;
    }

    return defaultEmail;
  }

  @Selector([AccountState.identity])
  static emailSenderName({ team }: AccountStateModel, identity: IdentityData) {
    const { firstName, lastName } = identity || ({} as IdentityData);
    return firstName && lastName ? `${firstName} ${lastName}` : team?.name || 'noreply';
  }

  @Selector([AccountState.adminSettings])
  static smsSenderName({ smsSender }: TeamAdminSettings) {
    return smsSender?.name || 'zeffi';
  }

  /** ACTIONS */

  @StreamAction(GetUser)
  getUser(
    { dispatch, patchState }: StateContext<AccountStateModel>,
    { userKey }: GetUser, // dispatched on auth token change
  ): Observable<AccountStateModel> {
    return !userKey
      ? of(null)
      : this.am.loadUser(userKey).pipe(
          map((user: UserData) => patchState({ user })),
          shareRef(),
        );
  }

  @StreamAction(GetTeam)
  getTeam(
    { getState, patchState }: StateContext<AccountStateModel>,
    { teamKey }: GetTeam,
  ): Observable<AccountStateModel> {
    return !teamKey
      ? of(null)
      : this.am.loadTeam(teamKey).pipe(
          map((newTeamData: TeamData) => {
            const oldTeamData = getState().team;
            // Keep old team data when "$key" is the only property
            // It is used during custom auth for private signin
            const isTeamFromToken = Object.keys(oldTeamData || {}).length === 1 && !!oldTeamData.$key;
            const team = newTeamData ? newTeamData : isTeamFromToken ? oldTeamData : null;

            if (oldTeamData?.$key !== team?.$key) {
              console.log('Team data', team);
            }

            // filter removed users
            const users = pickBy(team?.users, (value) => value !== 0);

            return patchState({
              team: { ...team, users },
            });
          }),
        );
  }

  @Action(CreateTeam)
  createTeam(ctx: StateContext<AccountStateModel>) {
    return this.cf.postOnce(Commands.CreateTeam).pipe(catchError(() => of(null)));
  }

  @Action(JoinTeam)
  joinTeam(ctx: StateContext<AccountStateModel>, { inviteKey }: JoinTeam) {
    return this.cf.postOnce(Commands.JoinTeam, inviteKey).pipe(catchError(() => of(null)));
  }

  @Action(SetTeamName)
  setTeamName(ctx: StateContext<AccountStateModel>, { teamName }: SetTeamName) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.am.updateTeam(teamKey, { name: teamName });
  }

  @Action(SetTeamLogo)
  setTeamLogo(ctx: StateContext<AccountStateModel>, { teamLogo }: SetTeamLogo) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.am.updateTeam(teamKey, { logo: teamLogo });
  }

  @Action(SetTeamColor)
  setTeamColor(ctx: StateContext<AccountStateModel>, { color }: SetTeamColor) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.am.updateTeam(teamKey, { color });
  }

  @Action(SwitchTeam)
  switchTeam(ctx: StateContext<AccountStateModel>, { teamKey }: SwitchTeam) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    return this.am.switchTeam(userKey, teamKey).pipe(map(() => window.location.replace('/')));
  }

  @Action(LeaveTeam)
  leaveTeam(ctx: StateContext<AccountStateModel>, { teamKey, userKey }: LeaveTeam) {
    return this.am.defaultTeamKey(userKey).pipe(
      filter((defaultTeamKey) => defaultTeamKey !== teamKey),
      tap(() => this.rm.setTeamRights(userKey, Rights.NONE, teamKey)),
      switchMap((defaultTeamKey) => this.am.switchTeam(userKey, defaultTeamKey)),
      map(() => window.location.replace('/')),
    );
  }

  @Action(AcceptTeamInvite)
  acceptTeamInvite({ dispatch }: StateContext<AccountStateModel>, { teamKey, inviteKey }: AcceptTeamInvite) {
    return this.am.acceptTeamInvite(teamKey, inviteKey).pipe(switchMap(() => dispatch(new GetUserInvites())));
  }

  @Action(RejectTeamInvite)
  rejectTeamInvite(ctx: StateContext<AccountStateModel>, { inviteKey }: RejectTeamInvite) {
    return this.am.rejectTeamInvite(inviteKey);
  }

  @Action(VerifyUser)
  verifyUser(ctx: StateContext<AccountStateModel>, { inviteKey }: VerifyUser) {
    return this.cf.postOnce(Commands.VerifyUser, inviteKey).pipe(catchError(() => of(null)));
  }

  @StreamAction(GetUserInvites)
  getUserInvites({ setState, getState }: StateContext<AccountStateModel>) {
    return this.am.listUserInvites().pipe(
      map((invites) => {
        if (getState().user) {
          setState(patch({ user: patch({ invites }) }));
        }
      }),
    );
  }

  @Action(SetVisitorId)
  setVisitorId({ patchState }: StateContext<AccountStateModel>, { visitorId }: SetVisitorId) {
    patchState({ visitorId });
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ patchState }: StateContext<AccountStateModel>) {
    patchState({ team: null });
  }

  @Action(AuthDoneFetchAccountData)
  authDoneFetch({ dispatch }: StateContext<AccountStateModel>, { userKey }: AuthDoneFetchAccountData) {
    console.log('Auth done fetch', userKey);

    return dispatch(new GetUser(userKey)).pipe(
      switchMap(() => {
        const user = this.store.selectSnapshot(AccountState.user);
        const authClaims = this.store.selectSnapshot(AuthState.authClaims);

        const hasIdentity = Boolean(user);
        const hasActiveTeam = Boolean(user?.team);

        const authConfig = authClaims?.ory || authClaims?.firebase;
        const isSSOProvider = authConfig.sign_in_provider?.startsWith('saml.');
        const isGoogleProvider = authClaims?.firebase?.sign_in_provider === 'google.com';

        const hasRightsToTeams = Object.values(user?.teams || {}).some((teamRight) => teamRight > 0);

        console.log('User data loaded', hasIdentity, hasActiveTeam, hasRightsToTeams, isSSOProvider, isGoogleProvider);

        if (hasActiveTeam) {
          return dispatch([new GetTeam(user?.team), new GetUserInvites()]);
        } else if (isGoogleProvider && !hasIdentity) {
          return this.store.dispatch(new Navigate(['/signup']));
        } else if (isSSOProvider && !hasRightsToTeams) {
          return dispatch(new SignOutWithRedirect(true));
        } else {
          return of(null);
        }
      }),
    );
  }

  @Action(PostLoginSetupAndRedirect)
  postLoginSetup({ dispatch }: StateContext<AccountStateModel>, { redirectUrl }: PostLoginSetupAndRedirect) {
    const userUid = this.store.selectSnapshot(AuthState.userUid);
    const authClaims = this.store.selectSnapshot(AuthState.authClaims);
    const queryParams = this.store.selectSnapshot(RouterState.queryParams);

    console.log('Post login setup', userUid, authClaims);

    const authConfig = authClaims?.firebase || authClaims?.ory;

    if (authConfig?.sign_in_provider?.startsWith('saml.')) {
      return this.cf.putOnce(Commands.CreateTeam).pipe(
        catchError(() => of(void 0)),
        switchMap(() => dispatch([new AuthDoneFetchAccountData(userUid), new Navigate([redirectUrl], queryParams)])),
      );
    } else {
      return dispatch([new AuthDoneFetchAccountData(userUid), new Navigate([redirectUrl], queryParams)]);
    }
  }
}

import { Input, OnDestroy, Directive } from '@angular/core';

import { Question } from '@player/+survey/question/question-base';
import { SurveyStore } from '@player/shared/services/survey-store.service';

import { Questions } from '@shared/enums/questions.enum';

@Directive()
export abstract class QuestionInput<T = string> extends Question<T> implements OnDestroy {
  @Input()
  protected defaultAnswer: T = '' as unknown as T;

  protected constructor(ss: SurveyStore) {
    super(ss);
  }

  ngOnDestroy(): void {
    if (this.questionData && this.questionData.type !== Questions.INPUT_CHECKBOX && this.isActive && this.isFocused) {
      const value = (this.inputField && this.inputField.nativeElement.value) as unknown as T;

      if (value !== this.answer) {
        this.updateValidity(value);
      }
    }
  }
}

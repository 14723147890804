import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Sheet } from '@player/shared/components/sheet/sheet.component';
import { PlayerAnswer } from '@player/shared/models/player.model';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Questions } from '@shared/enums/questions.enum';
import { QuestionData } from '@shared/models/survey.model';

@Component({
  selector: 'zef-funnel',
  templateUrl: './funnel.component.html',
  styleUrls: ['./funnel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Funnel implements AfterViewInit {
  @Output() readonly answerChanged = new EventEmitter<PlayerAnswer>();

  @ViewChild('sheet', { static: true }) sheet: Sheet;
  @ViewChild('sheet', { read: ElementRef }) sheetEl: ElementRef<HTMLElement>;

  constructor(readonly ss: SurveyStore) {}

  ngAfterViewInit(): void {
    this.fixOutcomeMargin();
  }

  private fixOutcomeMargin() {
    const funnelContainer = this.sheetEl?.nativeElement?.firstChild as HTMLElement;

    if (!!funnelContainer?.clientHeight) {
      setTimeout(() => {
        const containerHeight = funnelContainer.clientHeight;
        this.ss.updateFunnel({ containerHeight });
      }, 300);
    }
  }

  hideSheet() {
    this.ss.updateFunnel({ funnelHidden: true, containerHeight: 0 });
  }

  submitFunnelOne(event: null | boolean) {
    if (event === false) {
      // thumbs down
      this.sheet?.hiding$.next(true);
      this.answerChanged.emit({
        value: '0',
        item: {
          $key: 'zefSurveyUserRating',
          type: Questions.SURVEY_USER_RATING,
        } as QuestionData,
      });
    } else if (event === true) {
      // thumbs up
      this.answerChanged.emit({
        value: '1',
        item: {
          $key: 'zefSurveyUserRating',
          type: Questions.SURVEY_USER_RATING,
        } as QuestionData,
      });
    } else {
      // close
      this.sheet?.hiding$.next(true);
    }

    this.fixOutcomeMargin();
  }

  submitFunnelTwo(event: null | boolean) {
    if (event === false) {
      // thumbs down
      this.sheet?.hiding$.next(true);
      this.answerChanged.emit({
        value: '0',
        item: {
          $key: 'zefSurveyUserRating',
          type: Questions.SURVEY_USER_RATING,
        } as QuestionData,
      });
    } else if (event === true) {
      // thumbs up
      this.answerChanged.emit({
        value: '1',
        item: {
          $key: 'zefSurveyUserRating',
          type: Questions.SURVEY_USER_RATING,
        } as QuestionData,
      });
    } else {
      // close
      this.sheet?.hiding$.next(true);
    }

    this.fixOutcomeMargin();
  }
}

/**
 * Offline view component with static content.
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'offline-view',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineView {
  readonly Environment = environment;

  constructor() {}
}

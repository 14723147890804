import { filter, first } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { TemplatesState } from '@shared/states/templates.state';
import { DiscoverTemplate, LoadTemplates } from '@shared/states/templates.actions';

@Component({
  selector: 'zef-survey-link',
  templateUrl: './survey-link.component.html',
  styleUrls: ['./survey-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyLink implements OnInit {
  @Input('survey') surveyKey: string;
  @Input('open') open?: 'intro' | 'report';

  constructor(readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadTemplates());
  }

  @HostListener('click') onClick() {
    // TODO: Switch to TemplatesState.localeTemplates when "language" is in store...
    this.store
      .select(TemplatesState.allTemplates)
      .pipe(
        filter((templates) => !!templates.length),
        first(),
      )
      .subscribe((templates) => {
        this.store.dispatch(new DiscoverTemplate(this.surveyKey, templates, this.open || 'intro'));
      });
  }
}

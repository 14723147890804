/**
 * Main module for the website.
 *
 * @stable
 */

import { environment } from '@env/environment';

import { createCustomElement } from '@angular/elements';

import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, DoBootstrap, Injector, NgModule } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';

import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { PrefsState } from '@shared/states/prefs.state';
import { AccountState } from '@shared/states/account.state';
import { TemplatesState } from '@shared/states/templates.state';

import { DiscoverModule } from '@home/+discover/discover.module';

import { FunnelModule } from '@home/shared/modules/funnel.module';
import { InviteModule } from '@home/shared/modules/invite.module';
import { AccountModule } from '@home/shared/modules/account.module';
import { BillingModule } from '@home/shared/modules/billing.module';
import { NavBarModule } from '@home/shared/modules/nav-bar/nav-bar.module';

import { PlanSlider as AppPlanSlider } from '@plans/shared/components/plan-slider/plan-slider.component';
import { InitAuthentication } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { AuthSidenav } from '@auth/auth-sidenav.component';
import { AuthSidenavModule } from '@auth/auth-sidenav.module';
import { ActionsState } from '@shared/states/actions.state';
import { SurveyView } from './survey-view/survey-view.component';
import { SurveyLink } from './survey-link/survey-link.component';
import { PlanSlider } from './plan-slider/plan-slider.component';
import { MainMenu } from './main-menu/main-menu.component';

@NgModule({
  bootstrap: [],
  declarations: [MainMenu, PlanSlider, SurveyLink, SurveyView, AppPlanSlider],
  exports: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    FunnelModule,
    InviteModule,
    AccountModule,
    BillingModule,
    NavBarModule,
    AuthSidenavModule,
    DiscoverModule,
    RouterModule.forRoot([]),
    NgxsModule.forRoot([AuthState, ActionsState, AccountState, PrefsState, TemplatesState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase, 'zefApp'),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializer,
      deps: [Store],
      multi: true,
    },
  ],
})
export class ZefModule implements DoBootstrap {
  constructor(readonly injector: Injector, readonly registry: MatIconRegistry) {
    registry.registerFontClassAlias('zeficons', 'zef-icons-full');
    registry.setDefaultFontSetClass('zef-icons-full');
  }

  ngDoBootstrap() {
    try {
      const mainMenu = createCustomElement(MainMenu, { injector: this.injector });

      const planSlider = createCustomElement(PlanSlider, { injector: this.injector });
      const surveyLink = createCustomElement(SurveyLink, { injector: this.injector });
      const surveyView = createCustomElement(SurveyView, { injector: this.injector });
      const authSidenav = createCustomElement(AuthSidenav, { injector: this.injector });

      customElements.define('zef-menu', mainMenu);

      customElements.define('zef-plan-slider', planSlider);
      customElements.define('zef-survey-link', surveyLink);
      customElements.define('zef-survey-view', surveyView);
      customElements.define('zef-auth-sidenav', authSidenav);
    } catch (error) {
      console.error(error);
    }
  }
}

export function authInitializer(store: Store): () => Promise<void> {
  return async () => {
    store.dispatch(new InitAuthentication());
  };
}

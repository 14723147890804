import { Component, ChangeDetectionStrategy } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { QuestionInput } from '@player/+survey/question/question-input-base';

@Component({
  selector: 'input-checkbox',
  templateUrl: './input-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCheckbox extends QuestionInput<boolean> {
  isChecked(): boolean {
    return this.answer == null ? this.defaultAnswer : (this.answer || 'false').toString() !== 'false';
  }

  get preparedAnswer() {
    return this.isChecked();
  }

  constructor(ss: SurveyStore) {
    super(ss);
  }
}

import tinycolor from 'tinycolor2';

import { Component, Input } from '@angular/core';

import { QuestionData, SliderLabelsData } from '@shared/models/survey.model';

/**
 * Handles fetching of sharing urls & social shares
 */
@Component({
  selector: 'slider-label',
  templateUrl: './slider-label.component.html',
  styleUrls: ['./slider-label.component.scss'],
})
export class SliderLabel {
  @Input() sliderLabels: SliderLabelsData;
  @Input() axis?: 'X' | 'Y' | '' = '';
  @Input() data?: QuestionData;
  @Input() color?: string;

  @Input() set shadow(shadow: string) {
    if (this._shadow !== shadow) {
      this.textShadow = shadow && `0 1px 2px ${tinycolor(shadow).setAlpha(0.3).toRgbString()}`;
      this._shadow = shadow;
    }
  }

  @Input() valueSize: number;
  @Input() view?: string;
  @Input() titleSize: number;

  textShadow: string = '';

  private _shadow?: string;
}

/**
 * Manages question data stored in the Firebase.
 *
 * @unstable
 */

import { firstValueFrom, forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { Injectable } from '@angular/core';
import { OrderData } from '@shared/models/order.model';
import { firebaseSafe, pickBy } from '@shared/utilities/object.utilities';

import { Questions } from '@shared/enums/questions.enum';

import { ChoiceItemData, LogicItemStatement, QuestionData } from '@shared/models/survey.model';

import { ObjectsManager } from '@shared/services/objects-manager.service';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';

import { MoveQuestion, QuestionScoredDialog, UpdateGroup, UpdateQuestion } from '@shared/states/survey.actions';
import { logicArrayToData, logicDataToArray } from '@shared/utilities/survey-data.utilities';
import { CopyLanguageTypeLabels } from '@editor/shared/states/language/language.actions';
import * as fn from './questions-functions';
import { sliderSettings } from './questions-functions';

/**
 * Manages questions & groups for a survey
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionsManager extends ObjectsManager<QuestionData> {
  readonly pathRoot = 'questions';

  constructor(db: DatabaseWrapper, readonly store: Store) {
    super(db, store);
  }

  public questionsData(teamKey: string, surveyKey: string) {
    return this.orderedList(`/${this.pathRoot}/${teamKey}/${surveyKey}`).pipe(
      map((questions: QuestionData[]) => this.processChoices(questions)),
      map(logicDataToArray),
    );
  }

  public questionHistoryData(teamKey: string, surveyKey: string) {
    return this.orderedList(`/history/${teamKey}/${surveyKey}/questions`).pipe(
      map((questions: QuestionData[]) => this.processChoices(questions)),
      map(logicDataToArray),
    );
  }

  public processChoices(questions: QuestionData[]): QuestionData[] {
    return questions.map((question) => {
      if (question.choiceList) {
        if (Array.isArray(question.choiceList)) {
          question.choiceList = question.choiceList.map((choice, i) => ({
            ...choice,
            $key: this.db.createPushId(),
            order: OrderData.orderScale * (i + 1),
          }));
        } else {
          question.choiceList = Object.entries<ChoiceItemData>(question.choiceList).map(([$key, choice], i) => ({
            ...choice,
            $key: choice?.customKey || $key,
            order: choice['.priority'] != null ? choice['.priority'] : OrderData.orderScale * (i + 1),
          }));
        }

        question.choiceList = question.choiceList.filter(OrderData.filterDeleted).sort(OrderData.sortByOrder);
      }

      if (question.type === Questions.CHOICE_RADIO || question.type === Questions.CHOICE_TEXT) {
        question.type = (question.choiceLimit || 1) === 1 ? Questions.CHOICE_SINGLE : Questions.CHOICE_MULTI;
      }

      return question;
    });
  }

  public choicesData(questionKey: string): Observable<ChoiceItemData[]> {
    const ref = this.ref.child(`${questionKey}/choiceList`);

    return this.orderedList<ChoiceItemData>(ref).pipe(
      map((choices) => choices.filter((choice) => choice.$key !== 'other')),
    );
  }

  public otherChoice(questionKey: string): Observable<ChoiceItemData> {
    const ref = this.ref.child(`${questionKey}/choiceList`);
    return this.orderedList<ChoiceItemData>(ref).pipe(
      map((choices) => choices.filter((choice) => choice.$key === 'other')),
      map((otherChoices) => otherChoices[0]),
    );
  }

  private processTemplate(questions: QuestionData[]): QuestionData[] {
    const result: any = questions.reduce(
      (acc: any[], question: QuestionData) => {
        const dict = acc[0];
        const group = acc[1];
        const $key = this.db.createPushId();

        if (question.type.startsWith('group')) {
          return [[...dict, { ...question, choiceList: null, $key, group: '' }], $key];
        } else if (typeof question.group === 'string') {
          return [[...dict, { ...question, choiceList: null, $key, group }], group];
        } else {
          return [[...dict, { ...question, choiceList: null, $key, group: null }], null];
        }
      },
      [[], null],
    );

    return result[0].map((question) => {
      if (question.showIf) {
        for (const key in question.showIf) {
          if (question.showIf[key]) {
            const newKey = result[0][Number(key)] && result[0][Number(key)]['$key'];

            if (newKey) {
              question.showIf[newKey] = question.showIf[key];
              delete question.showIf[key];
            }
          }
        }
      }
      return question;
    });
  }

  private processQuestions(questions: QuestionData[]): QuestionData[] {
    const result: any = questions.reduce(
      (acc: any[], question: QuestionData) => {
        const dict = acc[0];

        return [[...dict, { ...question, choiceList: null }]];
      },
      [[], null],
    );

    return result[0];
  }

  private processTemplateShowIfs(
    originalQuestions: QuestionData[],
    newQuestions: { $key: string; choiceList: { $key }[] }[],
  ) {
    for (let qi = 0, lenq = originalQuestions.length; qi < lenq; qi++) {
      const question: QuestionData = originalQuestions[qi];
      const questionKey: string = newQuestions[qi]?.['$key'];

      if (question?.showIf && questionKey) {
        const showIf: { [questionKey: string]: LogicItemStatement } = Object.assign({}, question.showIf);

        for (const key in showIf || {}) {
          const index: number = newQuestions.findIndex((quest) => quest.$key === key);

          if (
            showIf[key]?.['logic']?.['answers']?.length &&
            Questions.isChoice(originalQuestions[index]) &&
            newQuestions[index]?.choiceList
          ) {
            for (let ci = 0, lenc = showIf[key]['logic']['answers'].length; ci < lenc; ci++) {
              const choices: { $key: any }[] = newQuestions[index]?.choiceList;

              showIf[key]['logic']['answers'][ci] = showIf[key]['logic']['answers'][ci]
                .split(';')
                .map((choice: string) => choices[Number(choice)]?.['$key'] || choice)
                .join(';');
            }
          }
        }
        this.updateData(this.ref.child(`${questionKey}`), { showIf });
      }
    }
  }

  public replaceQuestion(questionKey: string, newQuestion: QuestionData): Observable<unknown> {
    const choices = newQuestion.choiceList;
    delete newQuestion.choiceList;

    const ref = this.ref.child(questionKey);

    return from(ref.get()).pipe(
      map((oldQuestion) => oldQuestion.getPriority()),
      switchMap((order) => ref.setWithPriority({ ...firebaseSafe(newQuestion), order }, order)),
      switchMap(() => (choices?.length ? this.insertChoices(questionKey, choices) : of(void 0))),
    );
  }

  public insertQuestions(
    questions: QuestionData[],
    isTemplate?: boolean,
  ): Observable<{ $key: string; choiceList: { $key }[] }[]> {
    if (!questions?.length) {
      return of([]);
    }

    if (isTemplate) {
      return this.insertItems(this.ref, this.processTemplate(questions)).pipe(
        switchMap((keys) => {
          return forkJoin(
            questions.map((question, questionIdx) => {
              const newQuestion = { $key: keys[questionIdx], choiceList: [] };

              if (question.choiceList) {
                // Add choices for copied questions
                return this.addChoices(keys[questionIdx], Object.values(question.choiceList)).pipe(
                  map((choiceKeys) => {
                    newQuestion.choiceList.push(
                      ...choiceKeys.map(($key, i) => ({ $key: question.choiceList[i]?.['customKey'] || $key })),
                    );

                    return newQuestion;
                  }),
                );
              } else {
                return of(newQuestion);
              }
            }),
          );
        }),
        tap((newQuestions) => {
          this.processTemplateShowIfs(questions, newQuestions);
        }),
      );
    } else {
      return this.insertItems(this.ref, this.processQuestions(questions)).pipe(
        switchMap((keys) =>
          forkJoin(
            questions.map((question, i) => {
              const newQuestion = { $key: keys[i], choiceList: [] };
              const choiceValues = Object.values(question.choiceList || {});

              if (choiceValues.length) {
                // Add choices for copied questions
                return this.insertChoices(keys[i], choiceValues).pipe(
                  map((choiceKeys) => {
                    newQuestion.choiceList.push(...(choiceKeys || []).map(($key) => ({ $key })));

                    return newQuestion;
                  }),
                );
              } else {
                return of(newQuestion);
              }
            }),
          ),
        ),
      );
    }
  }

  public addQuestions(questions: QuestionData[] | QuestionData, index?: number) {
    return this.addItems(this.ref, questions, index);
  }

  public addGroup(index?: number, type?: Questions) {
    const question = {} as QuestionData;
    question.type = type || Questions.GROUP_CARDS;

    return this.addItems(this.ref, question, index);
  }

  public insertChoices(questionKey: string, choices: ChoiceItemData[]) {
    const ref = this.ref.child(`${questionKey}/choiceList`);

    const otherChoice = choices.find((choice) => choice.$key === 'other');

    choices = choices.filter((choice) => choice.$key !== 'other');

    if (otherChoice) {
      ref.child('other').setWithPriority(firebaseSafe({ ...otherChoice, order: 1 }), 1);
    }

    return choices.length ? this.insertItems(ref, choices) : of(null);
  }

  public addChoices(questionKey: string, choices: ChoiceItemData[]) {
    const ref = this.ref.child(`${questionKey}/choiceList`);
    const realChoices = choices.filter((choice) => choice.$key !== 'other' && choice.customKey !== 'other');

    return this.addItems(ref, realChoices).pipe(
      concatMap((keys) => {
        let other = choices.find((choice) => choice.$key === 'other' || choice.customKey === 'other');

        if (other) {
          other = {
            ...other,
            customKey: null,
          };

          return ref
            .child('other')
            .setWithPriority(firebaseSafe({ ...other, order: 1 }), 1)
            .then(() => keys);
        }

        return of(keys);
      }),
    );
  }

  public restoreChoice(questionKey: string, choiceKey: string) {
    const ref = this.ref.child(`${questionKey}/choiceList`);
    return this.restoreItems(ref, [choiceKey]);
  }

  public toggleOtherChoice(questionKey: string, enableOther: boolean): Promise<any> {
    const ref = this.ref.child(`${questionKey}/choiceList/other`);

    return ref.once('value', (snapshot) => {
      const value = snapshot.val();

      if (value == null) {
        return ref.setWithPriority({ content: 'Other', comment: true, order: 1 } as Partial<ChoiceItemData>, 1);
      } else {
        return ref.setPriority(enableOther ? 1 : -1, () => {});
      }
    });
  }

  private processTitle(question: QuestionData, copyingGroup: boolean) {
    const title = question.title || $localize`Untitled`;

    return question.type.startsWith('group') || (!copyingGroup && question.title !== '') ? title : question.title;
  }

  public copyQuestions(
    questions: QuestionData[],
  ): Observable<{ keys: string[]; choiceKeys: Record<string, [string, string][]> }> {
    const oldKeys = questions.map(({ $key }) => $key);
    const copies = questions.map((question, i) => ({
      ...question,
      title: this.processTitle(question, questions[0].type.startsWith('group')),
      choiceList: null,
    }));

    return this.copyItems(this.ref, copies).pipe(
      mergeMap((keys: string[]) => {
        const promises: Promise<any>[] = [];
        const choicePromises: Promise<Record<string, [string, string][]>>[] = [];

        if (questions[0].type.startsWith('group')) {
          // set group property for copied questions
          const transaction = keys.slice(1).reduce((dict, key, i) => ({ ...dict, [`${key}/group`]: keys[0] }), {});

          promises.push(this.updateData(this.ref, transaction));
        }

        questions.forEach((question, i) => {
          if (question.choiceList) {
            // add choices for copied questions
            const choices = Array.isArray(question.choiceList)
              ? question.choiceList
              : (Object.values(question.choiceList) as ChoiceItemData[]);
            choicePromises.push(
              firstValueFrom(this.addChoices(keys[i] as string, choices)).then((choiceKeys) => ({
                [keys[i]]: choiceKeys.map((newKey, ci) => [choices[ci]?.$key, newKey]),
              })),
            );
          }

          if (question.showIf) {
            const showIf = { ...question.showIf };
            const attached = Object.keys(showIf);
            const updateKeys = attached.filter((key) => oldKeys.includes(key));

            if (updateKeys.length) {
              updateKeys.forEach((key) => {
                const newKey = keys[oldKeys.indexOf(key)];

                if (newKey) {
                  showIf[newKey] = showIf[key];
                  delete showIf[key];
                }
              });

              promises.push(this.updateQuestion(keys[i], { showIf }));
            }
          }
        });

        return Promise.all([Promise.all(choicePromises), Promise.all(promises)]).then(([choices]) => ({
          keys,
          choiceKeys: choices.reduce((a, b) => ({ ...a, ...b }), {}),
        }));
      }),
    );
  }

  public moveQuestion(question: QuestionData, index?: number, group: string | null = null) {
    const isMovingGroup = Questions.group(question);

    return this.orderedList<QuestionData>(this.ref)
      .pipe(first())
      .pipe(
        map((data: QuestionData[]) => [
          question,
          ...((isMovingGroup && Questions.groupQuestions(question, data)) || []),
        ]),
        switchMap((questions) => {
          const update = questions.reduce((acc, child, idx) => {
            if (idx && isMovingGroup) {
              acc[child.$key] = { group: questions[0].$key };
            } else if (!isMovingGroup) {
              acc[child.$key] = { group };
            }

            return acc;
          }, {} as { [key: string]: Partial<QuestionData> });

          return this.moveItems(this.ref, questions, index, update);
        }),
      );
  }

  public shouldUpdateScored(group: QuestionData, question: QuestionData) {
    const isGroupScored = group.type === Questions.GROUP_SCORED;

    return isGroupScored && fn.settingsChanged(group, question);
  }

  public showScoredSettings(group: QuestionData, question: QuestionData) {
    const isQuestionInGroup = question.group === group.$key;
    const hasScoredSettings = !!fn.sliderSettings(group);

    return hasScoredSettings && !isQuestionInGroup && this.shouldUpdateScored(group, question);
  }

  public moveChoice(questionKey: string, choice: ChoiceItemData, index?: number) {
    const ref = this.ref.child(`${questionKey}/choiceList`);
    return this.moveItems(ref, [choice], index);
  }

  public switchChoice(questionKey: string, sourceChoice: string, targetChoice: string): Observable<void> {
    const ref = this.ref.child(`${questionKey}/choiceList`);
    return this.switchItems(ref, sourceChoice, targetChoice);
  }

  public removeQuestion(question: QuestionData) {
    return this.removeItems(this.ref, [question]);
  }

  public restoreQuestion(question: QuestionData) {
    return this.updatePriority(this.ref.child(question.$key), Math.abs(question.order));
  }

  public removeGroup(question: QuestionData, deleteAll: boolean = true): Observable<string[]> {
    return this.orderedList<QuestionData>(this.ref)
      .pipe(first())
      .pipe(
        map((entries: QuestionData[]) =>
          entries
            .filter((entry: any) => entry.$key === question.$key || entry.group === question.$key)
            .map((entry: any) => {
              entry.group = null;
              return entry;
            }),
        ),

        switchMap((entries: QuestionData[]) => {
          const items = deleteAll ? entries : [question];
          return this.removeItems(this.ref, items).pipe(
            switchMap((keys) => {
              const transaction = entries.reduce(
                (dict, entry) =>
                  // set group property to null for all questions
                  ({ ...dict, [`${entry.$key}/group`]: null }),
                {},
              );
              // commit to database
              return this.updateData(this.ref, transaction).then(() =>
                // return all keys for undo
                entries.map((entry) => entry.$key),
              );
            }),
          );
        }),
      );
  }

  public restoreGroup(keys: string[]) {
    return this.restoreItems(this.ref, keys).pipe(
      tap(() => {
        const group = keys[0];
        const transaction = keys.slice(1).reduce(
          (dict, $key) =>
            // set group property for all questions
            ({ ...dict, [`${$key}/group`]: group }),
          {},
        );
        // commit to database
        this.updateData(this.ref, transaction);
      }),
    );
  }

  public removeChoice(questionKey: string, choice: { $key: string }) {
    const ref = this.ref.child(`${questionKey}/choiceList`);
    return this.removeItems(ref, [choice]);
  }

  public updateQuestion(questionKey: string, data: Partial<QuestionData>) {
    const update = Questions.settings
      .filter((setting) => setting.key in data)
      .reduce(
        (dict, setting) => {
          const value = data[setting.key] === setting.empty ? null : data[setting.key];

          return { ...dict, [setting.key]: value };
        },
        { ...data },
      );

    logicArrayToData(update);

    delete update.choiceList; // This is handled separately

    return this.updateData(this.ref.child(questionKey), update).then(() => {
      if (Array.isArray(data.choiceList)) {
        return this.insertChoices(questionKey, data.choiceList)
          .toPromise()
          .then((keys) => keys || []);
      }
    });
  }

  /**
   * Copies type settings to selected questions.
   *
   * @param  targets        Target question keys.
   * @param  source         Source questions.
   */
  public copyTypeSettings(targets: { $key: string; type: Questions }[], source: QuestionData) {
    targets = targets.filter((target) => target.$key !== source.$key);

    if (targets.length > 0) {
      const update = fn.typeSettings(source);

      return forkJoin(
        targets.map((target) => {
          return this.store.dispatch([
            new UpdateQuestion(target.$key, update, true),
            new CopyLanguageTypeLabels(source, target.$key, source.type),
          ]);
        }),
      );
    } else {
      return of(void 0);
    }
  }

  public copyQuestionOptions(targetKeys: string[], sourceKey: string, questions: QuestionData[]) {
    const actions = targetKeys.map((targetKey) => {
      const source = questions.find(({ $key }) => $key === sourceKey);
      const sourceGroup = questions.find(({ $key }) => $key === source?.group);
      const target = questions.find(({ $key }) => $key === targetKey);
      const targetGroup = questions.find(({ $key }) => $key === target?.group);

      let sourceData: Partial<QuestionData> = fn.typeSettings(source);
      let targetData: Partial<QuestionData> = fn.typeSettings(target);

      if (Questions.zScorable(source)) {
        if (sourceGroup?.type === Questions.GROUP_SCORED && targetGroup?.type === Questions.GROUP_SCORED) {
          // from group to group
          sourceData = fn.typeSettings({ ...sourceGroup, type: source.type });
          targetData = fn.typeSettings({ ...targetGroup, type: target.type });
          targetKey = targetGroup.$key;
          sourceKey = sourceGroup.$key;
        } else if (sourceGroup?.type !== Questions.GROUP_SCORED && targetGroup?.type === Questions.GROUP_SCORED) {
          // from question to group
          sourceData = fn.typeSettings(source);
          targetData = fn.typeSettings({ ...targetGroup, type: target.type });
          targetKey = targetGroup.$key;
        } else if (sourceGroup?.type === Questions.GROUP_SCORED && targetGroup?.type !== Questions.GROUP_SCORED) {
          // from group to question
          sourceData = fn.typeSettings({ ...sourceGroup, type: source.type });
          targetData = fn.typeSettings(target);
          sourceKey = sourceGroup.$key;
        }
      }

      const resetData = Object.keys(targetData || {}).reduce((dict, key) => ({ ...dict, [key]: null }), {});
      const realSourceData = questions.find(({ $key }) => $key === sourceKey);

      return [
        new UpdateQuestion(targetKey, { ...resetData, ...sourceData }, true),
        new CopyLanguageTypeLabels(realSourceData, targetKey, source.type),
      ];
    });

    return forkJoin(actions.map((action) => this.store.dispatch(action)));
  }

  public archiveQuestion(questionKey: string) {
    return this.updateData(this.ref.child(`${questionKey}`), { archived: true });
  }

  public unarchiveQuestion(questionKey: string) {
    return this.updateData(this.ref.child(`${questionKey}`), { archived: null });
  }

  /**
   * Filters questions with unique settings
   *
   * @param questions QuestionData[]
   * @returns questions  QuestionData[]
   */
  public filterItemsWithUniqSettings(questions: QuestionData[]) {
    return questions.filter(fn.uniqueSettingsFilterFn);
  }

  public addScoredData(group: QuestionData, questions: QuestionData[]): Partial<QuestionData> {
    const settings = this.getUniqueSliderSettings(questions).filter((setting) => setting.uniqueSettings.length === 1);

    return settings.reduce(
      (a, b) => {
        const source = b.questions[0];
        if (source.type === Questions.SLIDER_2D) {
          a = {
            ...a,
            sliderLabelsX: source.sliderLabelsX,
            sliderLabelsY: source.sliderLabelsY,
            sliderValuesX: source.sliderValuesX,
            sliderValuesY: source.sliderValuesY,
            sliderSmileys: source.sliderSmileys,
          };
        } else if (source.type === Questions.SLIDER_1D) {
          a = {
            ...a,
            sliderLabels: source.sliderLabels,
            sliderValues: source.sliderValues,
            sliderSmileys: source.sliderSmileys,
          };
        }

        return pickBy(a, (v) => v != null);
      },
      { type: Questions.GROUP_SCORED } as Partial<QuestionData>,
    );
  }

  public checkGroupUpdateAndNotify(
    group: QuestionData,
    questions: QuestionData[],
    update: Partial<QuestionData>,
    skipType?: Questions,
  ): boolean {
    if (update.type !== Questions.GROUP_SCORED) {
      return true;
    }

    const conflictTypes = this.getUniqueSliderSettings(questions, skipType).filter(
      (typeQuestions) => typeQuestions.uniqueSettings.length > 1,
    );

    if (!conflictTypes.length) {
      return true;
    }

    const conflict = conflictTypes[0];
    const conflictQuestions = conflict.uniqueSettings.map(
      (setting) => conflict.questions[conflict.settings.indexOf(setting)],
    );

    console.warn('here');

    this.store.dispatch(
      new QuestionScoredDialog(
        conflictQuestions,
        group,
        void 0,
        new UpdateGroup(group.$key, update, conflictTypes.length === 1),
      ),
    );

    return false;
  }

  public checkScoredAndNotify(
    question: QuestionData,
    questions: QuestionData[],
    groupKey: string,
    index: number,
    action?: any,
  ): boolean {
    if (!groupKey) {
      return true;
    }

    const group = questions.find(({ $key }) => $key === groupKey);

    if (!group) {
      return true;
    }

    if (groupKey === question.group) {
      return true;
    }

    const shouldUpdateScored = this.shouldUpdateScored(group, question);
    const showScoredSettings = this.showScoredSettings(group, question);

    if (!shouldUpdateScored && !showScoredSettings) {
      return true;
    }

    action ||= new MoveQuestion(question, index, groupKey, true);

    this.store.dispatch(new QuestionScoredDialog([question], group, index, action));

    return false;
  }

  private getUniqueSliderSettings(
    questions: QuestionData[],
    skipType?: Questions,
  ): { type: Questions; questions: QuestionData[]; settings: string[]; uniqueSettings: string[] }[] {
    return questions
      .filter((question) => Questions.zScorable(question))
      .reduce((types: Questions[], question: QuestionData) => {
        if (!types.includes(question.type) && question.type !== skipType) {
          types.push(question.type);
        }

        return types;
      }, [])
      .map((type) => ({
        type,
        questions: questions.filter((question) => question.type === type),
      }))
      .map((typeQuestions) => ({
        ...typeQuestions,
        settings: typeQuestions.questions.map((question) => JSON.stringify(sliderSettings(question))),
      }))
      .map((typeQuestions) => ({
        ...typeQuestions,
        uniqueSettings: typeQuestions.settings.filter((setting, i, arr) => arr.indexOf(setting) === i),
      }));
  }
}

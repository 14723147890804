import { Component, Input, OnInit } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Select, Store } from '@ngxs/store';
import { ActionsState } from '@shared/states/actions.state';
import { AuthenticationError, SendPasswordResetEmail } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { isEmail } from 'class-validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'auth-forgot-password',
  templateUrl: './auth-forgot-password.component.html',
  styleUrls: ['./auth-forgot-password.component.scss'],
})
export class AuthForgotPassword implements OnInit {
  readonly AuthError = AuthError;
  public isResetEmailSent: boolean = false;
  @Input() public email: string = '';

  @Select(ActionsState.whileAction(SendPasswordResetEmail))
  readonly sending$!: Observable<boolean>;

  constructor(private store: Store) {}
  ngOnInit() {}

  clearError() {
    if (this.store.selectSnapshot(AuthState.authError)) {
      this.store.dispatch(new AuthenticationError(null));
    }
  }

  resetPassword(email: string) {
    if (!isEmail(email)) {
      this.store.dispatch(new AuthenticationError(AuthError.PASSWORD_RESET_ERROR));
    } else {
      this.isResetEmailSent = true;
      this.store.dispatch(new SendPasswordResetEmail(email));
    }
  }
}

import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit, OnDestroy, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Navigate } from '@ngxs/router-plugin';
import { Store, Select } from '@ngxs/store';
import {
  templateAuthorIntroLink,
  templateAuthorVideoLink,
  TemplateModel,
  templatePlayerLink,
  templateReportLink,
} from '@shared/models/survey.model';
import { CreateTemplate } from '@shared/states/templates.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { PrefsState } from '@shared/states/prefs.state';
import { GoToSignUp } from '@shared/states/auth.actions';

@Component({
  selector: 'discover-survey',
  templateUrl: './discover-survey.dialog.html',
  styleUrls: ['./discover-survey.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverSurvey implements OnInit, OnDestroy {
  private history: any = {};

  @Input() templateKey: string;

  @Input() templates: TemplateModel[] = [];
  @Input() showNavigation: boolean = true;
  @Input() startView: 'intro' | 'report' = 'intro';

  @Select(PrefsState.isMobile)
  readonly isMobile$: Observable<boolean>;

  activeTemplateKey = new BehaviorSubject(null);
  activeContentView = new BehaviorSubject(null);

  constructor(readonly store: Store, @Optional() readonly dialogRef: MatDialogRef<DiscoverSurvey>) {}

  ngOnInit() {
    this.history = {
      url: window.location.pathname,
      title: window.document.title,
      state: window.history.state,
    };

    this.changeTemplate(this.templateKey);
    this.activeContentView.next('intro');
  }

  ngOnDestroy() {
    if (this.dialogRef && window.history && window.history.replaceState) {
      window.history.replaceState(this.history.state, this.history.title, this.history.url);
    }
  }

  videoUrl(template: TemplateModel) {
    return templateAuthorVideoLink(template);
  }

  reportUrl(template: TemplateModel) {
    return templateReportLink(template);
  }

  surveyUrl(template: TemplateModel) {
    return templatePlayerLink(template);
  }

  introContent(template: TemplateModel) {
    return templateAuthorIntroLink(template);
  }

  changeTemplate(templateKey: string) {
    if (this.templates.length === 0) {
      return;
    }

    if (this.dialogRef) {
      if (window.history && window.history.pushState) {
        const template = this.templates.find((t) => t.key === templateKey);

        if (!template) {
          return;
        }

        const title = template.survey.title || '';

        if (template.survey.language !== 'fi') {
          const url =
            '/discover/' +
            template.webkey +
            '/' +
            template.survey.name
              .toLowerCase()
              .replace(/-|\(|\)/g, '')
              .replace(/ /g, '-') +
            '/';

          window.history.pushState({}, title, url);
        } else {
          const url =
            '/fi/inspiroidu/' +
            template.webkey +
            '/' +
            template.survey.name
              .toLowerCase()
              .replace(/-|\(|\)/g, '')
              .replace(/ /g, '-') +
            '/';

          window.history.pushState({}, title, url);
        }
      } else {
        this.store.dispatch(new Navigate(['/discover', this.templateKey], void 0, { skipLocationChange: true }));
      }
    }

    this.activeTemplateKey.next(templateKey);
    this.activeContentView.next('intro');
  }

  changeContentView(value: 'intro' | 'report') {
    this.activeContentView.next(value);
  }

  selectTemplate(templateKey: string) {
    if (templateKey) {
      const isMobile = this.store.selectSnapshot(PrefsState.isMobile);

      if (isMobile) {
        this.store.dispatch(new GoToSignUp());
      } else {
        this.store.dispatch(new CreateTemplate(templateKey));
      }
    }

    if (this.dialogRef) {
      this.dialogRef.close(templateKey);
    }
  }

  templateData(templateKey: string) {
    return this.templates.find((t) => t.key === templateKey);
  }

  @HostListener('document:keyup.ArrowRight')
  gotoNext() {
    const templateKey = this.nextTemplate();
    if (templateKey) {
      this.changeTemplate(templateKey);
    }
  }

  @HostListener('document:keyup.ArrowLeft')
  gotoPrev() {
    const templateKey = this.prevTemplate();
    if (templateKey) {
      this.changeTemplate(templateKey);
    }
  }

  nextTemplate() {
    const templateKey = this.activeTemplateKey.value;
    const templates = this.templates;
    const index = templates.findIndex((t) => t.key === templateKey);
    const template = templates[index + 1];

    return template ? template.key : null;
  }

  prevTemplate() {
    const templateKey = this.activeTemplateKey.value;
    const templates = this.templates;
    const index = templates.findIndex((t) => t.key === templateKey);
    const template = templates[index - 1];

    return template ? template.key : null;
  }
}

<ng-container *ngIf="servicesList | async as services">
  <ng-container *ngIf="(ss.sharing | async).enabled && services.length > 0" class="z-share-box">
    <div class="z-share-area z-fx z-fx-gap-8">
      <mat-icon
        *ngFor="let service of services"
        ngClass="z-{{ service.id }}-icon z-share-icon"
        fontSet="zefplayer"
        [style.background-color]="(ss.colors | async).text80"
        [style.color]="(ss.colors | async).mood"
        (click)="onShareSurvey(service)"
      >
        {{ service.icon }}
      </mat-icon>
    </div>
  </ng-container>
</ng-container>

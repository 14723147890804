<!--
  Survey thumb with menu and actions.

  @unstable
-->

<div *ngIf="template" class="z-fx-col discover-thumb" [class.small]="small">
  <div
    class="survey-image z-fx-flex"
    [style.background-image]="
      template.design?.background?.url || 'assets/images/survey-placeholder.png' | encodeURI : 'url' | safeStyle
    "
  ></div>

  <div class="z-fx-col z-fx-gap-4 z-fx-none survey-info" [title]="template.survey?.name">
    <span class="zef-text-h3 zef-text-overflow-double">{{ template.survey?.name }}</span>

    <span *ngIf="!small" class="zef-text-p2 zef-text-ellipsis">
      {{ template.owner?.name || template.owner?.email || 'ZEF' }}
    </span>
  </div>
</div>

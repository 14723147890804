<ng-container *ngIf="{ owner: (owner$ | async) } as asyncData">
  <div class="folder-tile z-fx-col z-fx-gap-8" [class.hovered]="hovered" (click)="activation.emit('select')">
    <figure class="folder-tile-color" [style.backgroundColor]="folder.color || '#EAEFF1'"></figure>

    <strong class="folder-tile-title zef-text-p1" style="word-break: break-word" [title]="folder?.name || ''">
      {{ folder.name }}
    </strong>
    <span *ngIf="!hovered" class="zef-text-p2 zef-text-light" i18n>
      {{ folder.$surveyCount }} {folder.$surveyCount, plural, one {survey} other {surveys}}
    </span>
    <span
      *ngIf="hovered"
      class="zef-text-p2 zef-text-light zef-text-overflow-line"
      style="max-width: calc(100% - 24px)"
    >
      {{ asyncData.owner?.name }}
    </span>

    <button
      *ngIf="isFolderOwner(asyncData.owner?.uid)"
      mat-icon-button
      [matMenuTriggerFor]="moreMenu"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>more</mat-icon>
    </button>
  </div>

  <mat-menu #moreMenu class="zef-menu-combo" xPosition="before" yPosition="below">
    <ng-template matMenuContent>
      <div mat-menu-item (click)="activation.emit('edit')">
        <div class="z-fx z-fx-start-center">
          <mat-icon>action_edit</mat-icon>
          <span i18n>Edit</span>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div mat-menu-item class="alert" (click)="activation.emit('delete')">
        <div class="z-fx z-fx-start-center">
          <mat-icon color="alert">action_delete</mat-icon>
          <span i18n>Delete...</span>
        </div>
      </div>
    </ng-template>
  </mat-menu>
</ng-container>

import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Select, Store } from '@ngxs/store';

import { AuthState } from '@shared/states/auth.state';
import { assertStoreData } from '@shared/utilities/store.utilities';
import { HelpCenterState } from '@shared/modules/help-center/state/help-center.state';
import { getLastValue, shareRef } from '@shared/operators/share-ref.operator';
import { PrefsState } from '@shared/states/prefs.state';
import {
  GetHelpItemStatus,
  GetHelpItemTip,
  UpdateHelpCenterMode,
} from '@shared/modules/help-center/state/help-center.actions';
import {
  HelpCenterItemStatus,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpItemSubject,
} from '@shared/modules/help-center/help-center.models';
import { OpenSidenav } from '@shared/states/dialog.actions';
import { HelpCenterSidenav } from '@shared/modules/help-center/components/help-sidenav/help-sidenav.component';
import { ZefThemePalette } from '@shared/models/color.model';

@Component({
  selector: 'zef-help-icon',
  templateUrl: './help-icon.component.html',
  styleUrls: ['./help-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpIcon {
  readonly statusColorMap: Record<HelpCenterItemStatus, string> = {
    [HelpCenterItemStatus.Missing]: 'alert',
    [HelpCenterItemStatus.Partial]: 'warning',
    [HelpCenterItemStatus.Complete]: 'ink-lightest',
  };

  readonly _subject$ = new ReplaySubject<HelpItemSubject>(1);

  @Select(PrefsState.language)
  readonly lang$!: Observable<HelpCenterLanguage>;

  @Input()
  set subject(subject: HelpItemSubject) {
    this._subject$.next(subject);
  }

  readonly subject$: Observable<HelpItemSubject> = this._subject$.pipe(
    filter((subject) => !!subject),
    distinctUntilChanged(),
    shareRef(),
  );

  readonly status$: Observable<HelpCenterItemStatus> = this.subject$.pipe(
    switchMap((subject) =>
      assertStoreData(this.store, HelpCenterState.status(subject), new GetHelpItemStatus(subject)),
    ),
    shareRef(),
  );

  readonly color$: Observable<ZefThemePalette> = this.status$.pipe(
    map((status) => this.statusColorMap[status] as ZefThemePalette),
  );

  readonly tip$: Observable<string> = combineLatest([this.subject$, this.lang$]).pipe(
    switchMap(([subject, lang]) =>
      assertStoreData(this.store, HelpCenterState.tip(subject, lang), new GetHelpItemTip(subject, lang)),
    ),
    shareRef(),
  );

  readonly show$: Observable<boolean> = combineLatest([this.status$, this.store.select(AuthState.isZefAdmin)]).pipe(
    map(([status, isAdmin]) => status === HelpCenterItemStatus.Complete || isAdmin),
  );

  constructor(private store: Store) {}

  openArticle(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const subject = getLastValue(this.subject$);
    const status = getLastValue(this.status$);
    const isAdmin = this.store.selectSnapshot(AuthState.isZefAdmin);

    this.store.dispatch([
      new UpdateHelpCenterMode({
        mode: isAdmin && status !== HelpCenterItemStatus.Complete ? HelpCenterMode.Edit : HelpCenterMode.Read,
        language: getLastValue(this.lang$),
      }),
      new OpenSidenav(HelpCenterSidenav, { data: subject }),
    ]);
  }
}

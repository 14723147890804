/**
 * Report app public views module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { LindaModule } from '@shared/modules/linda.module';

import { ChartsModule } from '@report/+charts/charts.module';
import { OfflineModule } from '@report/+offline/offline.module';

import { BillingModule } from '@home/shared/modules/billing.module';

import { ReportApp } from '@report/report.component';

import { NavBar } from '@report/nav-bar/nav-bar.component';
import { NavPane } from '@report/nav-pane/nav-pane.component';
import { SidePane } from '@report/side-pane/side-pane.component';
import { FilterBar } from '@report/filter-bar/filter-bar.component';

import { LanguageView } from '@report/side-pane/language-view/language-view.component';
import { TrendView } from '@report/side-pane/trend-view/trend-view.component';
import { StatsView } from '@report/side-pane/stats-view/stats-view.component';
import { ToolsView } from '@report/side-pane/tools-view/tools-view.component';
import { FilterView } from '@report/side-pane/filter-view/filter-view.component';

import { PromoShare } from '@report/shared/components/promo-share/promo-share.dialog';
import { PromoMerge } from '@report/shared/components/promo-merge/promo-merge.dialog';
import { ReportShare } from '@report/shared/components/report-share/report-share.dialog';
import { RawDataView } from '@report/shared/components/raw-data-view/raw-data-view.dialog';
import { ReportExport } from '@report/shared/components/report-export/report-export.dialog';
import { ReportMergeSurveys } from '@report/shared/components/report-merge-surveys/report-merge-surveys.component';
import { ReportMergeRespondents } from '@report/shared/components/report-merge-respondents/report-merge-respondents.component';

import { InfoBanner } from '@report/shared/services/info-banner.service';

@NgModule({
  bootstrap: [],
  declarations: [
    ReportApp,
    NavBar,
    NavPane,
    SidePane,
    FilterBar,
    LanguageView,
    TrendView,
    StatsView,
    ToolsView,
    FilterView,
    PromoMerge,
    PromoShare,
    RawDataView,
    ReportShare,
    ReportExport,
    ReportMergeSurveys,
    ReportMergeRespondents,
  ],
  exports: [ReportApp, NavBar, NavPane, SidePane, FilterBar, ReportMergeSurveys, ReportMergeRespondents],
  imports: [SharedModule, LindaModule, ChartsModule, OfflineModule, BillingModule],
  providers: [InfoBanner],
})
export class PublicModule {}

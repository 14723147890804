import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { QuestionInput } from '@player/+survey/question/question-input-base';

import { Questions } from '@shared/enums/questions.enum';
import { isEmptyOrEmail, isNumber, isUrl } from '@shared/utilities/validation.utilities';

const minHeight = 80;
const borderOffset = 4;

interface NormalInput {
  validator: (value: string) => boolean;
  icon: string;
  type: string;
  error: string;
  errorTranslate: string;
  autocomplete: string;
}

@Component({
  selector: 'input-simple',
  templateUrl: './input-simple.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSimple extends QuestionInput {
  readonly inputs: { [Question in Questions]?: NormalInput } = {
    [Questions.INPUT_EMAIL]: {
      validator: isEmptyOrEmail,
      icon: 'at',
      type: 'email',
      error: `Hmm… The Email doesn't look valid.`,
      errorTranslate: 'inputEmailErrorText',
      autocomplete: 'email',
    },
    [Questions.INPUT_NUMBER]: {
      validator: isNumber,
      icon: 'number',
      type: 'text',
      error: `Hmm… The Number doesn't look valid.`,
      errorTranslate: 'inputNumberErrorText',
      autocomplete: 'off',
    },
    [Questions.INPUT_URL]: {
      validator: isUrl,
      icon: 'link',
      type: 'url',
      error: `Hmm… The URL doesn't look valid.`,
      errorTranslate: 'inputURLErrorText',
      autocomplete: 'url',
    },
  };

  constructor(ss: SurveyStore) {
    super(ss);
  }

  isTextInput(): boolean {
    return [Questions.INPUT_STRING, Questions.FREE_TEXT].includes(this.getType());
  }

  getType(): Questions {
    return (this.questionData && this.questionData.type) || Questions.UNKNOWN;
  }

  getInput(): NormalInput | undefined {
    return this.inputs[this.getType()];
  }

  getCharsLimit(): number {
    return (this.questionData && this.questionData.charsLimit) || 1500000;
  }

  updateValidity(value): void {
    if (this.getType() !== Questions.INPUT_NUMBER) {
      super.updateValidity(value);
    } else {
      super.updateValidity(value?.replace(',', '.'));
    }

    if (this.isTextInput()) {
      this.updateHeight();
    }
  }

  validate(value: string): boolean {
    switch (this.getType()) {
      case Questions.INPUT_EMAIL:
        return isEmptyOrEmail(value);
      case Questions.INPUT_NUMBER:
        return isNumber(value);
      case Questions.INPUT_URL:
        return isUrl(value);
      default:
        return true;
    }
  }

  updateHeight(): void {
    if (!this.inputField) {
      return;
    }

    const textarea = this.inputField.nativeElement as HTMLTextAreaElement;
    textarea.style.height = `auto`;

    const height =
      textarea.value && 0 < textarea.value.length
        ? Math.max(minHeight, textarea.scrollHeight + borderOffset)
        : minHeight;

    textarea.style.height = `${height}px`;
  }
}

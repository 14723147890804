/**
 * General zef api class for zef services.
 *
 * @unstable
 */

import { environment } from '@env/environment';

import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType, HttpEvent } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { LocalStorage } from 'ngx-webstorage';

import { Requests } from '@shared/enums/requests.enum';
import { map, catchError } from 'rxjs/operators';

export interface RequestParams {
  path: string;
  method: Requests;

  body?: any;
  params?: any;

  useAuthorization?: boolean;
  observe?: 'body' | 'events';
}

export interface ApiUploadProgress<T> {
  progress: number;
  complete: boolean;
  error?: string;
  response?: T;
}

@Injectable({
  providedIn: 'root',
})
export class ZefApi {
  @LocalStorage('consent') trackingAllowed: boolean | undefined;
  @LocalStorage('report-team') privateReportTeamKey: string | undefined;

  private readonly baseUrl: string = `https:${environment.apiServer}/`;

  constructor(readonly http: HttpClient, readonly store: Store) {}

  get<T>(path: string, params: object = {}, useAuthorization: boolean = true): Observable<T> {
    return this.request<T>({ method: Requests.Get, path, params, useAuthorization });
  }

  put<T>(path: string, data: any, params: object = {}, useAuthorization: boolean = true): Observable<T> {
    return this.request<T>({ method: Requests.Put, path, body: data, params, useAuthorization });
  }

  post<T>(path: string, data: any = {}, params: object = {}, useAuthorization: boolean = true): Observable<T> {
    return this.request<T>({ method: Requests.Post, path, body: data, params, useAuthorization });
  }

  delete<T>(path: string, data?: any, params: object = {}, useAuthorization: boolean = true): Observable<T> {
    return this.request<T>({ method: Requests.Delete, path, body: data, params, useAuthorization });
  }

  request<T>({ method, path, body, params, useAuthorization = true, observe = 'body' }: RequestParams): Observable<T> {
    const headers = this.getHeaders(useAuthorization, body instanceof FormData);

    Object.keys((key) => (params[key] = encodeURIComponent(params[key])));

    return this.http.request(method, this.baseUrl + path, {
      body,
      headers,
      responseType: 'json',
      withCredentials: this.trackingAllowed || false,
      reportProgress: observe === 'events',
      params: new HttpParams({ fromObject: params }),
      observe,
    });
  }

  upload<T>(path: string, body: any = {}): Observable<ApiUploadProgress<T>> {
    return this.request<HttpEvent<T>>({ path, body, observe: 'events', method: Requests.Post }).pipe(
      map((event: HttpEvent<T>) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.loaded / (event.total || event.loaded || 0.001);

          return {
            progress,
            complete: progress === 1,
          };
        } else if (event.type === HttpEventType.Response) {
          if (event.status !== 200) {
            throw new Error();
          }

          return {
            progress: 1,
            complete: true,
            response: event.body,
          };
        } else {
          return {
            progress: 0,
            complete: false,
          };
        }
      }),
      catchError(() =>
        of({
          complete: true,
          progress: 1,
          error: $localize`:dialog title@@zef-i18n-00250:Error while uploading, if the error persists please contact support.`,
        }),
      ),
    );
  }

  private getHeaders(useAuthorization: boolean, isFormData: boolean): HttpHeaders {
    const activeTeamKey = this.store.selectSnapshot(
      ({ account }) => account.team?.$key || account.user?.team || this.privateReportTeamKey || null,
    );

    let headers: HttpHeaders = new HttpHeaders({
      'Environment': environment.config,
      'Active-Team': activeTeamKey || '',
    });

    if (!isFormData) {
      headers = headers.set('Content-Type', 'application/json');
    }

    if (useAuthorization) {
      const authToken = this.store.selectSnapshot(({ auth }) => auth?.authToken);

      headers = headers.set('Firebase-Authorization', authToken || '');
    }

    return headers;
  }
}

/**
 * Auto focus on element creation.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Directive, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Directive({
  selector: '[autoFocus]',
  providers: [LifecycleHooks],
})
export class AutoFocus implements OnChanges {
  @Input()
  autoFocus: boolean | Observable<any> | '';

  @Input()
  autoFocusBoundary?: string;

  constructor(readonly elRef: ElementRef<HTMLInputElement>, readonly ls: LifecycleHooks) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.autoFocus) {
      let enabled = this.autoFocus || this.autoFocus === '';

      if (enabled && this.autoFocusBoundary && document.activeElement) {
        if (
          document.activeElement.closest(this.autoFocusBoundary) ===
          this.elRef.nativeElement.closest(this.autoFocusBoundary)
        ) {
          enabled = false;
        }
      }

      if (enabled instanceof Observable) {
        enabled.pipe(takeUntil(this.ls.destroy)).subscribe(() => {
          setTimeout(() => this.elRef.nativeElement.focus({ preventScroll: true }));
        });
      } else if (enabled) {
        setTimeout(() => this.elRef.nativeElement.focus({ preventScroll: true }));
      }
    }
  }
}

/**
 * Models for the scoring data.
 *
 * @unstable
 */

import { Questions } from '@shared/enums/questions.enum';

import { OutcomeData, QuestionData, ScoringData, SliderValuesData, SurveyScoring } from '@shared/models/survey.model';
import { PlayerAnswers } from '@player/shared/models/player.model';

export interface CalculatorInterface {
  calculate(
    answers: any,
    questions: QuestionData[],
    groups: QuestionData[],
    outcomes: OutcomeData[],
    scoring: any,
  ): Map<string, number>;
  countCorrect(
    answers: any,
    questions: QuestionData[],
    groups: QuestionData[],
    outcomes: OutcomeData[],
    scoring: any,
  ): Map<string, { correct: number; max: number }>;
}

export class PercentScoring implements CalculatorInterface {
  public calculate(
    answers: PlayerAnswers,
    questions: QuestionData[],
    groups: QuestionData[],
    outcomes: OutcomeData[],
    scoring: SurveyScoring,
  ): Map<string, number> {
    const scoringMap = new Map<string, number>();

    if (answers && scoring && questions && outcomes) {
      outcomes
        .filter((outcome) => scoring[outcome.$key] != null)
        .forEach((outcome) => {
          const scores: { [questionKey: string]: ScoringData } = scoring[outcome.$key];
          const scoredQuestions = questions.filter(
            (question: QuestionData) =>
              scores[question.$key] != null && Questions.scorable(question) && answers[question.$key] != null,
          );

          const total = scoredQuestions.reduce((cum: number, question: QuestionData) => {
            const answerValue: string = answers[question.$key];
            const mappingValue: ScoringData = scores[question.$key];

            return cum + Math.max(0, Math.min(1, this.getPercent(answerValue, mappingValue, question, groups)));
          }, 0);

          scoringMap.set(outcome.$key, scoredQuestions.length ? total / scoredQuestions.length : null);
        });
    }

    return scoringMap;
  }

  public countCorrect(
    answers: PlayerAnswers,
    questions: QuestionData[],
    groups: QuestionData[],
    outcomes: OutcomeData[],
    scoring: SurveyScoring,
  ): Map<string, { correct: number; max: number }> {
    const scoringMap = new Map<string, { correct: number; max: number }>();

    if (answers && scoring && questions && outcomes) {
      outcomes
        .filter((outcome) => scoring[outcome.$key] != null)
        .forEach((outcome) => {
          const scores: { [questionKey: string]: ScoringData } = scoring[outcome.$key];
          const scoredQuestions = questions.filter(
            (question: QuestionData) => scores[question.$key] != null && Questions.scorable(question),
          );

          let max: number = 0;
          let correct: number = 0;

          scoredQuestions.forEach((question: QuestionData) => {
            const answerValue: string = answers[question.$key];
            const mappingValue: ScoringData = scores[question.$key];
            const percentage =
              answerValue != null
                ? Math.max(0, Math.min(1, this.getPercent(answerValue, mappingValue, question, groups)))
                : 0;

            if (percentage === 1) {
              correct++;
            }
            max++;
          });

          scoringMap.set(outcome.$key, { correct, max });
        });
    }

    return scoringMap;
  }

  private getPercent(answerValue: string, mappingValue: ScoringData, question: QuestionData, groups: QuestionData[]) {
    if (mappingValue && mappingValue.value != null) {
      const group = Questions.getGroup(question, groups);
      const questionData = Questions.zScorable(question) && group?.type === Questions.GROUP_SCORED ? group : question;

      if (question.type === Questions.SLIDER_NPS) {
        answerValue = Math.round(+answerValue).toString();
        questionData.sliderValues = { max: 10, min: 0, step: 1 } as SliderValuesData;
      }

      switch (question.type) {
        case Questions.SLIDER_1D:
        case Questions.SLIDER_1V:
        case Questions.SLIDER_NPS:
          return this.handleDiagramSliderScoring(answerValue, mappingValue, questionData.sliderValues);

        case Questions.SLIDER_1R:
          return this.handleRangeSliderScoring(answerValue, mappingValue, questionData.sliderValues);

        case Questions.SLIDER_2D:
          return this.handle2DScoring(answerValue, mappingValue, questionData);

        case Questions.CHOICE_TEXT:
        case Questions.CHOICE_MULTI:
        case Questions.CHOICE_SINGLE:
        case Questions.CHOICE_PICTURE:
        case Questions.INPUT_DROPDOWN:
          return this.handleChoiceScoring(answerValue, mappingValue);
      }
    }

    return 0;
  }

  // Scoring handlers for different questions

  /**
   * Handles scoring for Choice and Dropdown questions
   */
  private handleChoiceScoring(answerValue: string, mappingValue: ScoringData): number {
    const values: string[] = answerValue.split(';');
    const choices: string[] = mappingValue.value.split(';');

    return values.length && values.filter((value) => choices.includes(value)).length / values.length;
  }

  /**
   * Handles scoring of 2D question
   */
  private handle2DScoring(answerValue: string, mappingValue: ScoringData, question: QuestionData) {
    const [answerX, answerY] = answerValue.split(';').map((val) => parseFloat(val));
    const [mappingX, mappingY] = mappingValue.value.split(';').map((val) => parseFloat(val));

    const { min: minX, max: maxX } = question.sliderValuesX;
    const { min: minY, max: maxY } = question.sliderValuesY;

    const percentX = this.calculateSliderPercent(answerX, mappingX, minX, maxX);
    const percentY = this.calculateSliderPercent(answerY, mappingY, minY, maxY);

    return (percentX + percentY) / 2;
  }

  /**
   * Handles scoring of 1D Range slider
   */
  private handleRangeSliderScoring(answerValue: string, mappingValue: ScoringData, bounds: SliderValuesData): number {
    const { min: sliderMin, max: sliderMax } = bounds;
    const answers = this.parseScoringNumbers(answerValue, { min: sliderMin, max: sliderMax });
    const mappings = this.parseScoringNumbers(mappingValue.value, { min: sliderMin, max: sliderMax });

    if (mappings.length === 1 && answers.length === 1) {
      return mappings[0] === answers[0] ? 1 : 0;
    } else if (mappings.length === 1 && answers.length === 2) {
      // Range answer, point mapping
      const [mapped] = mappings;
      const [answerMin, answerMax] = answers;

      return this.isInRange(mapped, answerMin, answerMax) ? 1 : 0;
    } else if (mappings.length === 2 && answers.length === 1) {
      // Point answer, Range mapping
      const [answer] = answers;
      const [mapMin, mapMax] = mappings;
      const midpoint = (mapMin + mapMax) / 2;

      // NOTE: This formula has been copied from ZEF Global
      return (Math.abs(mapMax - midpoint - Math.abs(midpoint - answer)) / (mapMax - midpoint)) * 0.4 + 0.1;
    } else {
      const [answerMin, answerMax] = answers;
      const [mapMin, mapMax] = mappings;

      return this.calculateRangeOverlap(answerMin, answerMax, mapMin, mapMax);
    }
  }

  private handleDiagramSliderScoring(answerValue: string, mappingValue: ScoringData, bounds: SliderValuesData): number {
    const { min: sliderMin, max: sliderMax } = bounds;
    const answers = this.parseScoringNumbers(answerValue, { min: sliderMin, max: sliderMax });
    const mappings = this.parseScoringNumbers(mappingValue.value, { min: sliderMin, max: sliderMax });

    if (mappings.length === 1) {
      // Point answer, Point mapping
      const mapped = mappings[0];
      const answer = answers[0];

      return this.calculateSliderPercent(answer, mapped, sliderMin, sliderMax);
    } else if (mappings.length === 2) {
      // Point answer, Range mapping
      const [mapMin, mapMax] = mappings;
      const answer = answers[0];

      return this.isInRange(answer, mapMin, mapMax) ? 1 : 0;
    } else {
      console.warn('Slider1D scoring – unidentified condition (this should not happen)', answers, mappings);
      return null;
    }
  }

  // Helper functions

  /**
   * Formats a string into a sorted number array, with removed duplicates
   * Values can be bounded with additional bound param
   */
  private parseScoringNumbers(value: string, bounds?: { min: number; max: number }): number[] {
    let parsedValues = value
      .split(';')
      .map((val) => parseFloat(val))
      .filter((number: number) => !isNaN(number));

    if (bounds) {
      parsedValues = parsedValues.map((num) => this.clampNumber(num, bounds.min, bounds.max));
    }

    return parsedValues
      .reduce((acc: number[], num: number) => {
        if (!acc.includes(num)) {
          acc.push(num);
        }
        return acc;
      }, [])
      .sort((a, b) => a - b);
  }

  /**
   * Check if number is within range
   */
  private isInRange(value: number, min: number, max: number): boolean {
    const minVal = Math.min(max, min);
    const maxVal = Math.max(min, max);

    return value >= minVal && value <= maxVal;
  }

  /**
   * Calculates percentage of how much two ranges overlap
   */
  private calculateRangeOverlap(answerMin: number, answerMax: number, mapMin: number, mapMax: number): number {
    const range = mapMax - mapMin;
    const diff = Math.min(mapMax, answerMax) - Math.max(mapMin, answerMin);

    return Math.max(0, diff) / range;
  }

  /**
   * Calculates proximity percent, given slider bounds, answer value & mapped value
   */
  private calculateSliderPercent(answer: number, mapped: number, min: number, max: number): number {
    const minVal = Math.min(max, min);
    const maxVal = Math.max(min, max);

    answer = this.clampNumber(answer, minVal, maxVal);
    mapped = this.clampNumber(mapped, minVal, maxVal);

    const diff = Math.abs(mapped - answer);

    const range = maxVal - minVal;

    return diff ? 1 - diff / range : 1;
  }

  /**
   * Clamps a number between bounds
   */
  private clampNumber(value: number, min: number, max: number): number {
    const minVal = Math.min(max, min);
    const maxVal = Math.max(min, max);

    return Math.max(minVal, Math.min(maxVal, value));
  }
}

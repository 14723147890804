import { Directive, Input, AfterContentInit, ElementRef, Renderer2 } from '@angular/core';

import { AnalyticsTracks } from '@shared/models/analytics.model';

import { AnalyticsMediator } from '@shared/services/analytics-mediator.service';

@Directive({
  selector: '[analyticsTrack]',
})
export class AnalyticsTracker implements AfterContentInit {
  constructor(private eRef: ElementRef, private analytics: AnalyticsMediator, private renderer: Renderer2) {}

  @Input('analyticsTrackOn') event: string = 'click';
  @Input('analyticsTrack') track: AnalyticsTracks;

  ngAfterContentInit() {
    this.renderer.listen(this.eRef.nativeElement, this.event, (event: Event) => {
      this.analytics.track(this.track);
    });
  }
}

<ng-container
  *ngIf="{
    view: (view$ | async),
    error: (error$ | async),
    state: (state$ | async),
    invite: (invite$ | async),
    user: (userInfo$ | async)
  } as asyncData"
  class="sidenav-box z-fx-col z-fx-gap"
>
  <!-- <pre class="auth-debugger">{{ asyncData.view | json }}</pre> -->

  <div *ngIf="!!asyncData.view && asyncData.view !== View.SIGNUP_COMPLETE" class="auth-sidenav-box z-fx-col z-fx-gap">
    <ng-scrollbar [disabled]="isMobile$ | async">
      <div class="auth-sidebar-container z-fx-col z-fx-gap-48">
        <a [href]="wwwAddress" target="_blank">
          <img src="assets/images/zeffi-bird.svg" width="32" alt="ZEF Logo" i18n-alt="logo image alt" />
        </a>

        <div class="auth-sidebar-content z-fx-col z-fx-auto">
          <ng-container [ngSwitch]="asyncData.view">
            <auth-signup-method
              *ngSwitchCase="View.SIGNUP_SELECT_METHOD"
              [inviteData]="asyncData.invite"
              (select)="selectSignUpMethod($event, asyncData.invite?.email)"
            ></auth-signup-method>

            <auth-google-signup
              *ngSwitchCase="View.SIGNUP_CONFIRM_GOOGLE"
              [invites]="invites$ | async"
              [email]="asyncData.user?.email"
              (submit)="finishGoogleSignup($event)"
            ></auth-google-signup>

            <auth-email-verified
              *ngSwitchCase="View.SIGNUP_VERIFIED"
              [invites]="invites$ | async"
              [email]="asyncData.user?.email"
              (submit)="finishEmailSignup($event)"
            ></auth-email-verified>

            <auth-email-signup
              *ngSwitchCase="View.SIGNUP_CONFIRM_EMAIL"
              [email]="asyncData.invite?.email"
              [isInvite]="!!asyncData.invite"
              [loading]="asyncData.state.loading"
              (submit)="signUpWithEmail($event)"
            ></auth-email-signup>

            <auth-cancel-signup
              *ngSwitchCase="View.SIGNUP_CANCEL"
              (submit)="continueGoogleSignup()"
              (cancel)="cancelSignup()"
            ></auth-cancel-signup>

            <auth-check-email
              *ngSwitchCase="View.SIGNUP_CHECK_EMAIL"
              [email]="asyncData.user?.email"
              [isEmail]="isEmailProvider(asyncData.user)"
              [isInvite]="asyncData.state.isInvite"
              [isDesktop]="isDesktop$ | async"
            ></auth-check-email>

            <auth-account-exist
              *ngSwitchCase="View.SIGNUP_ACCOUNT_EXIST"
              [email]="asyncData.state?.email"
              (submit)="signUpWithNewEmail()"
            ></auth-account-exist>

            <auth-invite-accept *ngSwitchCase="View.INVITE_ACCEPT" [invite]="asyncData.invite"></auth-invite-accept>

            <auth-invite-error
              *ngSwitchCase="View.INVITE_ERROR"
              [inviteData]="asyncData.invite"
              [authError]="asyncData.error"
            ></auth-invite-error>

            <auth-signin-method
              *ngSwitchCase="View.SIGNIN_SELECT_METHOD"
              [authError]="asyncData.error"
              (select)="selectSignInMethod($event)"
            ></auth-signin-method>

            <auth-signin-invite
              *ngSwitchCase="View.SIGNIN_SELECT_INVITE"
              [inviteData]="asyncData.invite"
              [authError]="asyncData.error"
              (select)="selectSignInMethod($event)"
            ></auth-signin-invite>

            <auth-report-signin
              *ngSwitchCase="View.REPORT_AUTHENTICATION"
              [authError]="asyncData.error"
              (select)="signInForReport($event)"
            ></auth-report-signin>
          </ng-container>
        </div>

        <a
          *ngIf="asyncData.view === View.SIGNUP_SELECT_METHOD"
          class="auth-logo-link zef-color-muted"
          [href]="wwwAddress"
          target="_blank"
        >
          <mat-icon class="zef-icon-small zef-color-grey">&#xe910;</mat-icon>
        </a>
      </div>
    </ng-scrollbar>
  </div>
</ng-container>

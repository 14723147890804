<ng-container *ngIf="isTextInput(); else normalInput">
  <textarea
    class="z-input"
    autocomplete="off"
    name="free-text"
    [maxLength]="getCharsLimit()"
    #input
    rows="3"
    [placeholder]="placeholder || ''"
    [value]="answer || ''"
    [style.border-color]="this.isValid ? (ss.colors | async).primary : invalidColor"
    [style.color]="(ss.colors | async).text80"
    [style.background-color]="(ss.colors | async).mood20"
    (focus)="updateFocus($event)"
    (blur)="updateValidity(input.value)"
    (input)="resetValidity()"
  ></textarea>
  <div *ngIf="getCharsLimit() && input.value.length / getCharsLimit() >= 0.8" class="z-texts-paragraph z-input-limit">
    {{ input.value.length }} / {{ getCharsLimit() }}
  </div>
</ng-container>

<ng-template #normalInput>
  <ng-container *ngIf="getInput() as inputData">
    <mat-icon class="z-type-icon" fontSet="zefplayer" [style.color]="(ss.colors | async).primary">
      {{ inputData.icon }}
    </mat-icon>

    <input
      class="z-input"
      [type]="inputData.type"
      [autocomplete]="inputData.autocomplete"
      maxLength="1500000"
      #input
      [placeholder]="placeholder"
      [value]="answer"
      [style.border-color]="this.isValid ? (ss.colors | async).primary : invalidColor"
      [style.color]="(ss.colors | async).text80"
      [style.background-color]="(ss.colors | async).mood20"
      (focus)="updateFocus($event)"
      (blur)="updateValidity(input.value)"
      (input)="resetValidity()"
    />

    <span *ngIf="!isValid" class="z-error-text">
      {{ inputData.error | translateText : inputData.errorTranslate }}
    </span>
  </ng-container>
</ng-template>

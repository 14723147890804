<zef-file-upload
  [size]="iu.size"
  [mode]="iu.shape"
  [error]="hasError"
  [file]="getImageSrc()"
  [progress]="progress$ | async"
  [uploading]="uploading$ | async"
  [disableClick]="iu.disableClick"
  (fileChange)="onFileChange($event)"
></zef-file-upload>

import { Directive, TemplateRef, Inject, forwardRef } from '@angular/core';
import { NavBarContentDirective } from '@home/shared/modules/nav-bar/nav-bar-content.directive';

@Directive({
  selector: '[navBarAvatar]',
})
export class NavBarAvatarDirective {
  constructor(tr: TemplateRef<any>, @Inject(forwardRef(() => NavBarContentDirective)) content: NavBarContentDirective) {
    content.avatar = tr;
  }
}

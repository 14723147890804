<div
  class="item"
  [class.selected]="iu.isSelected(image)"
  [ngClass]="[iu.shape, iu.color || '']"
  (click)="iu.changeImage(image)"
>
  <img *ngIf="!iu.isDefault(image); else defaultOutlet" [src]="image?.urls.thumb" />

  <ng-template #defaultOutlet>
    <ng-container *ngTemplateOutlet="iu.defaultDirective?.vc"></ng-container>
  </ng-template>
</div>

<mat-icon *ngIf="iu.isSelected(image); else menu" class="item-selected-icon zef-icon-large" color="primary">
  bullet_check
</mat-icon>

<ng-template #menu>
  <div *ngIf="!iu.isDefault(image)" class="item-menu" [matMenuTriggerFor]="menu" (menuClosed)="isMenuOpen = false">
    <button mat-icon-button>
      <mat-icon>more</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item class="alert" (click)="iu.removeImage(image)" i18n>Delete</button>
    </mat-menu>
  </div>
</ng-template>

/**
 * Resolves template data from the Firebase.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { mergeMap, mapTo } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Store } from '@ngxs/store';

import { GetTemplatesSurveys, GetTemplateTypes } from '@shared/states/templates.actions';

@Injectable({
  providedIn: 'root',
})
export class SurveyTemplates implements Resolve<void> {
  constructor(readonly store: Store) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<void> {
    return this.store.dispatch(new GetTemplateTypes()).pipe(
      mergeMap(() => this.store.dispatch(new GetTemplatesSurveys())),
      mapTo(void 0),
    );
  }
}

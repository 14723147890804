<div class="z-fx-col z-fx-auto summary1d-container" (elementResize)="updateChart()">
  <div
    *ngFor="let chart of chartData; trackBy: trackById; let i = index"
    class="z-fx-auto summary1d-chart-container"
    [class.z-fx]="flexMode === 'row'"
    [class.z-fx-col]="flexMode === 'column'"
    [style.flex]="chartData.length === 1 && !chart.heightPercentage ? null : '0 0 ' + chart.heightPercentage + '%'"
    [style.font-size.px]="exportChart ? 14 : null"
  >
    <div #tooltipcontainer class="z-fx-col z-fx-auto">
      <div
        *ngIf="flexMode === 'row' || totalQuestionCount > 1 || isJoinedComparison"
        class="z-fx z-fx-end-center toolbar"
      >
        <div
          *ngIf="isGroupedComparison && flexMode === 'row' && (!legends || !legends.length)"
          class="comparison-group-title"
        >
          <span class="circle" [style.background-color]="colorScale(chart?.group?.index)"></span>
          <span class="text-label">{{ chart?.group?.title || '' }}</span>
        </div>
        <div
          *ngIf="legends && legends.length"
          class="z-fx z-fx-wrap z-fx-center-center legends"
          [style.max-width]="
            flexMode === 'row' ? 'calc(70% - 32px)' : (search.open$ | async) ? '50%' : 'calc(100% - 40px)'
          "
          @expand
        >
          <div
            *ngFor="let legend of legends; trackBy: trackBy; let i = index"
            class="legend"
            (mouseover)="onLegendHover(legend.key)"
            (mouseleave)="onLegendHover(null)"
          >
            <div class="label">
              <span class="circle" [style.background-color]="legend.color"></span>
              <span class="text-label">{{ legend.label }}</span>
            </div>
          </div>
        </div>
        <div
          class="z-fx z-fx-end-center questionlist-tools"
          [style.min-width]="flexMode === 'row' ? 'calc(30% + 32px)' : (search.open$ | async) ? '50%' : null"
        >
          <zef-search-expand
            placeholder="Search..."
            i18n-placeholder
            matTooltip="Search..."
            i18n-matTooltip
            matTooltipPosition="above"
            [matTooltipDisabled]="search.open$ | async"
            [value]="searchTerms[chart.id]"
            [style.visibility]="questionCount?.[chart.id] < 7 ? 'hidden' : null"
            (valueChange)="onSearchUpdate($event, chart.id)"
            #search="searchExpand"
          ></zef-search-expand>
          <div
            *ngIf="!isJoinedComparison"
            class="z-fx z-fx-center-center sorter"
            [class.search-open]="search.open$ | async"
            [style.visibility]="questionCount?.[chart.id] < 2 ? 'hidden' : null"
            @expand
          >
            <button mat-icon-button [@rotate]="sortDirections[chart.id] !== 'asc'" (click)="onSortDir(chart.id)">
              <mat-icon class="zef-color-primary">sort_up</mat-icon>
            </button>

            <zef-select [value]="sortKeys[chart.id]" type="combo" (change)="onSortColumn($event, chart.id)">
              <zef-select-option value="survey">
                <mat-icon *selectPrefix color="ink">chart_summary_2d</mat-icon>
                <ng-container *selectOptionLabel i18n>Original</ng-container>
              </zef-select-option>
              <zef-select-option value="average">
                <mat-icon *selectPrefix color="ink">axis_x</mat-icon>
                <ng-container *selectOptionLabel>
                  <ng-container *ngIf="chart?.sliderDetails?.labels as labels">
                    {{ labels.axis ? labels.axis + ': ' : '' }}
                    {{ labels.min || '' }}
                    {{ labels.min && labels.max ? ' - ' : '' }}
                    {{ labels.max || '' }}
                  </ng-container>
                </ng-container>
              </zef-select-option>
            </zef-select>
          </div>
        </div>
      </div>
      <div
        *ngIf="flexMode === 'column'"
        class="summary1d-chart-col"
        [class.no-question-list]="flexMode === 'column' && totalQuestionCount === 1 && !isJoinedComparison"
        [class.only-chart]="flexMode === 'column' && totalQuestionCount === 1 && isJoinedComparison"
        summary1d
        [chartData]="chart"
        [showTooltip]="true"
        [size]="size"
        [highlight]="hoveredFromChart?.[chart.id]"
        [groupHighlight]="comparisonGroupHover"
        [selectedItems]="selectedQuestions?.[chart.id]"
        [domain]="domain"
        [zvalues]="zvalues"
        [showAverages]="showAverages"
        [transitionDuration]="transitionDuration"
        [update]="update"
        [comparison]="comparison"
        [comparisonMode]="comparisonMode"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="hoverFromChart($event, chart.id)"
        (click)="selectQuestions(hoveredFromChart?.[chart.id], chart.id)"
      ></div>
      <ng-scrollbar
        *ngIf="flexMode === 'row' || (flexMode === 'column' && size?.height >= 136 && totalQuestionCount > 1)"
        class="z-fx-col z-fx-between-start z-fx-flex scrollable-area"
        [style.max-height.px]="
          flexMode === 'row' && chart?.visibleQuestionList?.length ? chart.visibleQuestionList.length * rowHeight + (selectedQuestions?.[chart.id]?.length ? countFoundSelectedItems(chart.visibleQuestionList, selectedQuestions?.[chart.id]) : 0) * 8 : null
        "
      >
        <div class="z-fx-col z-fx-auto summary1d-questions" [style.margin-top]="flexMode === 'column' ? 0 : null">
          <div
            *ngFor="let questions of chart.visibleQuestionList; trackBy: trackByKey; let qi = index"
            class="z-fx row"
            [style.height.px]="flexMode === 'row' ? rowHeight + (!isFound(selectedQuestions?.[chart.id], questions[0]?.key) ? 0 : (!exportChart ? 8 : 0)) : null"
          >
            <div
              *ngIf="flexMode === 'row'"
              class="summary1d-chart"
              summary1d
              [chartData]="chart"
              [singleItem]="questions[0]?.key"
              [showTooltip]="isJoinedComparison"
              [size]="size"
              [highlight]="hoveredFromChart?.[chart.id]"
              [groupHighlight]="comparisonGroupHover"
              [selectedItems]="selectedQuestions?.[chart.id]"
              [domain]="domain"
              [zvalues]="zvalues"
              [showAverages]="showAverages"
              [transitionDuration]="transitionDuration"
              [update]="update"
              [comparison]="comparison"
              [comparisonMode]="comparisonMode"
              [selectionExists]="selectionExists"
              [filtersDemo]="filtersDemo"
              [tooltipContainer]="tooltipcontainer"
              (hover)="hoverFromChart($event, chart.id)"
              (click)="selectQuestions(hoveredFromChart?.[chart.id], chart.id)"
            ></div>
            <div
              #questionElement
              class="summary1d-question"
              [class.active]="isFound(hoveredFromChart?.[chart.id], questions[0]?.key)"
              [class.selected]="isFound(selectedQuestions?.[chart.id], questions[0]?.key)"
              [class.question-group]="questions[0]?.isGroupAverage"
              [class.question-in-group]="questions[0]?.questionGroup"
              [class.centered]="fitsToRow(questionElement, isFound(hoveredFromChart?.[chart.id], questions[0]?.key) || isFound(selectedQuestions?.[chart.id], questions[0]?.key))"
              (mouseenter)="hoverFromChart(mapValues(questions, 'key'), chart.id)"
              (mouseleave)="hoverFromChart([], chart.id)"
              (click)="selectQuestions(mapValues(questions, 'key'), chart.id, questions[0]?.isGroupAverage)"
            >
              <div *ngIf="questions[0]" class="summary1d-question-export-text">
                <ng-container *ngIf="isFound(selectedQuestions?.[chart.id], questions[0]?.key)">***</ng-container>
                {{ questions[0].number || questions[0].numberAlphabet }}. {{ questions[0].title }}
                <ng-container *ngIf="!isJoinedComparison">
                  <ng-container *ngFor="let statItem of questions">
                    <ng-container>
                      (x:
                      {{
                        zvalues
                          ? statItem.zAverage != null
                            ? statItem.zAverage.toFixed(1)
                            : '-'
                          : statItem.average != null
                          ? statItem.average.toFixed(1)
                          : '-'
                      }})
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngIf="questions[0] as question">
                <div class="z-fx question" [class.zef-color-ink-lightest]="hasOnlyAnonymousQuestions(questions)">
                  <div class="z-fx z-fx-auto z-fx-start-center question-text">
                    {{ question.number || question.numberAlphabet }}. {{ question.title }}
                  </div>
                  <div
                    *ngIf="question.isGroupAverage"
                    class="group-mode-toggle"
                    (click)="toggleGroupAverages(chart.id); $event.stopPropagation()"
                  >
                    <mat-icon *ngIf="!showGroupAverages?.[chart.id]" class="icon" color="ink">
                      pointer_collapse
                    </mat-icon>
                    <mat-icon *ngIf="showGroupAverages?.[chart.id]" class="icon" color="ink">pointer_expand</mat-icon>
                  </div>
                </div>

                <div
                  *ngFor="let statItem of questions"
                  class="z-fx z-fx-start-center z-fx-wrap stats"
                  [class.show-all]="sortKeys[chart.id] !== 'survey' && !isJoinedComparison"
                  [class.hide]="isJoinedComparison"
                  [class.zef-color-ink-lightest]="statItem.isUnderAnonymityTreshold"
                >
                  <div class="z-fx z-fx-center-center stat-item" [class.selected]="sortKeys[chart.id] === 'n'">
                    <span class="icon">contact</span>
                    <span *ngIf="!statItem.isUnderAnonymityTreshold; else underTreshold">{{ statItem.n }}</span>
                    <ng-template #underTreshold>
                      <span i18n class="number">under {{ anonymityTreshold }}</span>
                    </ng-template>
                  </div>
                  <div
                    *ngIf="!statItem.isUnderAnonymityTreshold; else anonymityIcon"
                    class="z-fx z-fx-center-center stat-item"
                    [class.selected]="sortKeys[chart.id] === 'average'"
                  >
                    <span class="icon">axis_x</span>
                    {{
                      zvalues
                        ? statItem.zAverage != null
                          ? statItem.zAverage.toFixed(1)
                          : '-'
                        : statItem.average != null
                        ? statItem.average.toFixed(1)
                        : '-'
                    }}
                  </div>
                  <ng-template #anonymityIcon>
                    <div class="z-fx z-fx-center-center stat-item" [class.selected]="sortKeys[chart.id] === 'average'">
                      <span class="icon anonymity zef-color-ink">anonymous_on</span>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="chart.visibleQuestionList?.length === 0 && searchTerms?.[chart.id]"
            class="z-fx z-fx-center-center no-results"
            i18n
          >
            No results...
          </div>
        </div>
      </ng-scrollbar>
      <div
        *ngIf="flexMode === 'row' && chart.visibleQuestionList?.length > 0"
        class="summary-1d-scale"
        summary1d
        [style.max-height.px]="fontSize === 16 ? 53 : 48"
        [style.max-width]="flexMode === 'row' ? '70%' : '100%'"
        [chartData]="chart"
        [showOnlyScale]="true"
        [size]="size"
        [highlight]="hoveredFromChart?.[chart.id]"
        [selectedItems]="selectedQuestions?.[chart.id]"
        [domain]="domain"
        [zvalues]="zvalues"
        [showAverages]="showAverages"
        [transitionDuration]="transitionDuration"
        [update]="update"
        [comparison]="comparison"
        [comparisonMode]="comparisonMode"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        (hover)="hoverFromChart($event, chart.id)"
        (click)="selectQuestions(hoveredFromChart?.[chart.id], chart.id)"
      ></div>
    </div>
  </div>
</div>

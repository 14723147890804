import { environment } from '@env/environment';

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Store } from '@ngxs/store';

import { InviteData } from '@shared/models/account.model';
import { VerifyUser } from '@shared/states/account.actions';
import { GoToSignIn } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';

@Component({
  selector: 'auth-signup-method',
  templateUrl: './signup-method.component.html',
})
export class SignupMethod implements OnInit, OnChanges {
  readonly environment = environment;

  @Input() googleError: string | null = null;
  @Input() emailError: string | null = null;
  @Input() inviteData: InviteData | null = null;
  @Output() select = new EventEmitter();

  constructor(private store: Store) {}

  ngOnInit() {
    if (this.inviteData) {
      // console.warn(this.store.selectSnapshot(AuthState.info));

      const inviteKey = this.inviteData.$key;
      this.store.dispatch(new VerifyUser(inviteKey));
    }
  }

  ngOnChanges() {
    this.select.next(null);
  }

  signIn() {
    this.store.dispatch(new GoToSignIn());
  }

  signUpWithGoogle() {
    this.select.next('google');
  }

  signUpWithEmail() {
    this.select.next('email');
  }

  changeMethod() {
    if (environment.website && !!window) {
      const lang = this.store.selectSnapshot(PrefsState.language);
      window.location.href = `http:${environment.webAddress}/login?lang=${lang}`;
    } else {
      this.select.next('change');
    }
  }
}

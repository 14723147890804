import { TeamAdminSettings, TeamType } from '@shared/models/account.model';

export function parseDefaultAdminSettings(settings: Partial<TeamAdminSettings>): TeamAdminSettings {
  return {
    plan: 'free_plan',
    type: TeamType.Client,

    auditLogging: false,
    disableAnalytics: false,
    disableEmail: false,
    disableSms: false,
    extensions: [],

    ...settings,
  };
}

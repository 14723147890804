<!--
  Scrollable list of selectable languages.

  @unstable
-->
<div
  *ngIf="locales | pairs as languages"
  class="z-container-box z-dropdown"
  [style.background-color]="(ss.colors | async).moodParity"
>
  <ng-scrollbar class="z-scroll-box">
    <div class="z-scroll-area z-fx-col z-fx-auto">
      <div
        *ngFor="let language of languages"
        class="z-locale-item z-fx z-fx-start-center"
        [class.active]="selected === language[0]"
        [style.background-color]="
          hover === language[0]
            ? click === language[0]
              ? (ss.colors | async).moodContrast20
              : (ss.colors | async).moodContrast10
            : (ss.colors | async).moodParity
        "
        [style.color]="
          selected === language[0] || click === language[0] ? (ss.colors | async).primary : (ss.colors | async).text
        "
        (mouseenter)="hover = language[0]"
        (mouseleave)="hover = null"
        (mousedown)="click = language[0]"
        (mouseup)="click = null"
        (click)="select.emit(language[0])"
      >
        <div class="z-locale-content z-fx z-fx-gap-16 z-fx-start-center">
          <figure [ngClass]="flagClass(language[0])" class="z-fx-none"></figure>

          <span class="z-lang-name z-texts-ellipsis">{{ language[1]?.native || language[1]?.name }}</span>
        </div>

        <div class="z-locale-indicator" [style.background-color]="(ss.colors | async).primary"></div>
      </div>
    </div>
  </ng-scrollbar>
</div>

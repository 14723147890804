<div class="z-fx-col z-fx-auto z-fx-center-center z-fx-gap-16 content">
  <div class="z-fx z-fx-between-center mat-subheading-2 header">
    <div>
      <span i18n>Trend analysis</span>
      <zef-help-icon [subject]="subjects.AnalyzeCompareRespondents"></zef-help-icon>
    </div>

    <mat-slide-toggle
      [disabled]="false"
      [checked]="trendSwitchOn"
      (change)="trendSwitch($event)"
      color="primary"
      i18n
    ></mat-slide-toggle>
  </div>
  <ng-container *ngIf="trendSwitchOn">
    <zef-select class="time-period-selector" [value]="selectedTrend" (change)="selectOption($event)">
      <zef-select-option value="day" i18n>Daily</zef-select-option>
      <zef-select-option value="week" i18n>Weekly</zef-select-option>
      <zef-select-option value="month" i18n>Monthly</zef-select-option>
      <zef-select-option value="year" i18n>Yearly</zef-select-option>
    </zef-select>
  </ng-container>
</div>

/**
 * State container for cart related data.
 *
 * @unstable
 */

import { Observable } from 'rxjs';
import { map, tap, exhaustMap } from 'rxjs/operators';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { CartManager } from '@plans/shared/services/cart-manager.service';
import { PlanBilling, CartSMS } from '@plans/shared/models/plans.model';
import { CartExtra, CartPlan, ParkedPlan, PlanInfo } from '@plans/shared/models/plans.model';

import { BillingFeedback, BillingPlanData, SCATokenResponse } from '@shared/models/billing.model';

import { GoToFeedback, ConfirmSCA } from '@shared/states/billing.actions';
import { ClearChosen } from '@shared/states/plan.actions';
import { pickBy } from '@shared/utilities/object.utilities';
import { BillingApi } from '@home/shared/services/billing-api.service';
import { Injectable } from '@angular/core';
import {
  GetCart,
  UpdateCart,
  ClearCart,
  Checkout,
  CheckoutComplete,
  AddPlan,
  SetParked,
  ExtraAnswers,
  ExtraSMS,
  CheckoutSMSRefill,
} from './cart.actions';

export enum CartSubChange {
  FirstTime,
  ChangePlan,
  PeriodToMonthly,
  PeriodToYearly,
}

export interface CartStateModel {
  subscription?: CartPlan;
  parked?: ParkedPlan;
  extra?: CartExtra;
  sms?: CartSMS;
  smsRefill?: { enabled: boolean; amount: number };
  total?: number;
  cartDate?: Date;
  taxName?: string;
  taxPercentage?: number;
}

@Injectable()
@State<CartStateModel>({
  name: 'cart',
  defaults: {},
})
export class CartState {
  @Selector()
  static totalPrice(cart: CartStateModel): number {
    return (
      cart.total || (cart.subscription && cart.subscription.billing.total) || (cart.parked && cart.parked.total) || 0
    );
  }

  @Selector()
  static cartPlan(cart: CartStateModel): PlanInfo {
    return cart.subscription && cart.subscription.plan ? cart.subscription.plan : null;
  }

  @Selector()
  static billing({ subscription }: CartStateModel): PlanBilling {
    return subscription && subscription.billing;
  }

  @Selector()
  static parked({ parked }: CartStateModel): ParkedPlan {
    return parked;
  }

  @Selector()
  static total({ total }: CartStateModel): number {
    return total;
  }

  @Selector()
  static isEmpty(cart: CartStateModel): boolean {
    return !cart.subscription && !cart.parked && (!cart.extra || !cart.extra.answers) && !cart.sms;
  }

  @Selector()
  static isBuyingSms(cart: CartStateModel): boolean {
    return !!cart.sms;
  }

  @Selector()
  static isImmediate(cart: CartStateModel): boolean {
    return !!(
      cart.subscription &&
      (cart.subscription.change === CartSubChange.FirstTime ||
        (cart.subscription.change !== CartSubChange.ChangePlan &&
          cart.subscription.billing &&
          cart.subscription.billing.credit))
    );
  }

  @Selector()
  static hasTotal(cart: CartStateModel): boolean {
    return cart.total > 0;
  }

  constructor(private store: Store, private cm: CartManager, private ba: BillingApi) {}

  @Action(GetCart)
  getCart({ dispatch, setState }: StateContext<CartStateModel>): Observable<void> {
    setState(this.cm.getCart());

    return dispatch(new UpdateCart());
  }

  @Action(UpdateCart, { cancelUncompleted: true })
  updateCart({ setState, getState }: StateContext<CartStateModel>): Observable<CartStateModel> {
    return this.cm.setCart(getState()).pipe(map((newCart) => setState({ ...newCart })));
  }

  @Action(ClearCart, { cancelUncompleted: true })
  clearCart({ setState, dispatch }: StateContext<CartStateModel>): Observable<void> {
    setState({});
    return dispatch(new UpdateCart());
  }

  @Action(Checkout, { cancelUncompleted: true })
  checkout({ getState, dispatch }: StateContext<CartStateModel>): Observable<any> {
    const cart = getState();

    return this.cm.checkout(cart).pipe(
      exhaustMap((response: SCATokenResponse) => {
        const scaToken = response && typeof response === 'object' && response.scaToken;

        if (scaToken) {
          return dispatch(new ConfirmSCA(scaToken));
        } else {
          return dispatch(new CheckoutComplete());
        }
      }),
    );
  }

  @Action(CheckoutComplete)
  checkoutComplete({ getState, dispatch }: StateContext<CartStateModel>): Observable<void> {
    const cart = getState();
    let feedback = BillingFeedback.PlanUpgraded;

    if (cart.parked) {
      feedback = BillingFeedback.PlanParked;
    } else if (cart.subscription) {
      feedback = BillingFeedback.PlanChanged;
    }

    if (cart.smsRefill) {
      this.ba.smsRefill(cart.smsRefill.enabled, cart.smsRefill.amount).toPromise();
    }

    return dispatch(new GoToFeedback(feedback)).pipe(tap(() => dispatch([new ClearCart(), new ClearChosen()])));
  }

  @Action(AddPlan)
  addPlan(
    { patchState, dispatch }: StateContext<CartStateModel>,
    { chosenPlan, removeData }: AddPlan,
  ): Observable<void> {
    const plan: BillingPlanData = {
      plan: chosenPlan.chosenPlan,
      options: chosenPlan.chosenOptions,
      paymentPlan: {
        period: chosenPlan.period,
        price: chosenPlan.price,
      },
      removeData,
    };

    const activePlan = this.store.snapshot().billing.activePlan;

    let change = CartSubChange.FirstTime;
    let downgrade = false;

    if (activePlan.plan.id !== 'free_plan' && activePlan.plan.id !== 'trial_plan') {
      change = CartSubChange.ChangePlan;

      const oldPrice = activePlan.paymentPlan.price;
      let newPrice = plan.paymentPlan.price;

      if (activePlan.paymentPlan.period !== plan.paymentPlan.period) {
        change = CartSubChange.PeriodToMonthly;

        if (plan.paymentPlan.period === 'yearly') {
          change = CartSubChange.PeriodToYearly;
          newPrice *= 12;
        }
      }

      downgrade = oldPrice > newPrice;
    }

    const subscription = {
      plan: plan.plan,
      options: plan.options,
      period: plan.paymentPlan.period,
      deleteFrom: plan.removeData,
      change,
      downgrade,
    };

    patchState({ parked: undefined, subscription });

    return dispatch(new UpdateCart());
  }

  @Action(SetParked, { cancelUncompleted: true })
  setParked({ setState, dispatch }: StateContext<CartStateModel>): Observable<void> {
    setState({ parked: {} });

    return dispatch(new UpdateCart());
  }

  @Action(ExtraAnswers, { cancelUncompleted: true })
  extraAnswers(
    { dispatch, getState, patchState, setState }: StateContext<CartStateModel>,
    { amount }: ExtraAnswers,
  ): void {
    const state = getState();

    if (!amount) {
      const newState = pickBy(state, (v, k) => k !== 'extra');
      setState(newState);
    } else {
      patchState({ extra: { answers: { amount } }, sms: undefined });
    }

    if (state.extra && !state.extra.answers) {
      const total = state.total - state.extra.total;
      const newState = pickBy(state, (v, k) => k !== 'extra');
      setState({ ...newState, total, sms: undefined });
    }

    dispatch(new UpdateCart());
  }

  @Action(ExtraSMS, { cancelUncompleted: true })
  extraSMS({ dispatch, patchState }: StateContext<CartStateModel>, { smsPackage }: ExtraSMS): void {
    patchState({ sms: { package: smsPackage }, extra: undefined });

    dispatch(new UpdateCart());
  }

  @Action(CheckoutSMSRefill)
  checkoutSMSRefill({ patchState }: StateContext<CartStateModel>, { enabled, amount }: CheckoutSMSRefill): void {
    patchState({ smsRefill: { enabled, amount } });
  }
}

import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  State,
  Actions,
  StateToken,
  NgxsOnInit,
  StateContext,
  createSelector,
  getActionTypeFromInstance,
} from '@ngxs/store';

import { assertArray } from '@shared/utilities/array.utilities';
import { isDeepEqual } from '@shared/utilities/object.utilities';

export type ActionsStateModel = Record<string, any[]>;

const ACTIONS_STATE = new StateToken<ActionsStateModel>('actions');

enum ActionStatus {
  Dispatched = 'DISPATCHED',
  Successful = 'SUCCESSFUL',
  Canceled = 'CANCELED',
  Errored = 'ERRORED',
}

interface ActionContext<T = any> {
  status: ActionStatus;
  action: T;
  error?: Error;
}

@Injectable()
@State<ActionsStateModel>({
  name: ACTIONS_STATE,
  defaults: {},
})
export class ActionsState implements NgxsOnInit {
  static whileAction(actions: any | any[], withArguments?: object) {
    actions = assertArray(actions);

    return createSelector([ActionsState], (state: ActionsStateModel) =>
      actions.some(
        (action) =>
          assertArray(state[getActionTypeFromInstance(action)]).filter(
            (dispatched) =>
              !withArguments || Object.entries(withArguments).every(([key, value]) => dispatched[key] === value),
          ).length > 0,
      ),
    );
  }

  static completedAction(actions: any | any[]) {
    actions = Array.isArray(actions) ? actions : [actions];

    return createSelector([ActionsState], (state) =>
      actions.every((action) => state[getActionTypeFromInstance(action)]?.length === 0),
    );
  }

  static dispatchedAction(actions: any | any[]) {
    actions = Array.isArray(actions) ? actions : [actions];

    return createSelector([ActionsState], (state) =>
      actions.every((action) => getActionTypeFromInstance(action) in state),
    );
  }

  constructor(private actions$: Actions) {}

  ngxsOnInit({ patchState, getState }: StateContext<ActionsStateModel>): void {
    this.actions$
      .pipe(
        tap((ctx: ActionContext) => {
          const type = getActionTypeFromInstance(ctx.action);

          if (!type) {
            return;
          }

          let actions = [...assertArray(getState()[type])];

          const action = Object.entries(ctx.action).reduce(
            (filtered, [key, value]) => ({
              ...filtered,
              ...(value == null || (typeof value !== 'object' && typeof value !== 'function') ? { [key]: value } : {}),
            }),
            {},
          );

          if (ctx.status === ActionStatus.Dispatched) {
            actions.push(action);
          } else {
            actions = actions.filter((target) => !isDeepEqual(target, action));
          }

          patchState({ [type]: actions });
        }),
      )
      .subscribe();
  }
}

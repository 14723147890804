import { takeUntil } from 'rxjs/operators';

import { Directive, OnInit, Input, Output, TemplateRef, NgZone } from '@angular/core';
import { CdkCopyToClipboard, Clipboard } from '@angular/cdk/clipboard';

import { Store } from '@ngxs/store';

import { DisplaySnackbar } from '@shared/states/dialog.actions';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Directive({
  selector: '[zefCopyToClipboard]',
  providers: [LifecycleHooks],
})
export class CopyToClipboard extends CdkCopyToClipboard implements OnInit {
  @Input('zefCopyToClipboard')
  text: string;

  @Input()
  copyToClipboardNotice?: string | TemplateRef<any>;

  @Input()
  copyToClipboardActionName?: string;

  @Input()
  copyToClipboardAction?: Function;

  @Output()
  readonly copiedToClipboard = this.copied;

  constructor(cb: Clipboard, private lh: LifecycleHooks, private store: Store, nz: NgZone) {
    super(cb, nz);
  }

  ngOnInit(): void {
    this.copied.pipe(takeUntil(this.lh.destroy)).subscribe(() => {
      const noticeTemplate = typeof this.copyToClipboardNotice !== 'string' && this.copyToClipboardNotice;
      const noticeTitle = typeof this.copyToClipboardNotice === 'string' && this.copyToClipboardNotice;

      this.store.dispatch(
        new DisplaySnackbar(noticeTitle, {
          actionName: this.copyToClipboardActionName || void 0,
          actionCallback: this.copyToClipboardAction,
          noticeTemplate,
          color: 'success',
        }),
      );
    });
  }

  copy(attempts?: number): void {
    super.copy(attempts);
  }
}

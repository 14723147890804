import { Component, OnChanges, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

import { Locales } from '@shared/enums/locales.enum';
import { LanguagesData } from '@shared/models/locale.model';

/**
 * This is language view component which handles language in report.
 */
@Component({
  selector: 'language-view',
  templateUrl: './language-view.component.html',
  styleUrls: ['./language-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageView implements OnChanges {
  @Input() languages: LanguagesData = {} as LanguagesData;
  @Input() activeLanguage: string = '';
  @Output() changeLanguage: EventEmitter<string> = new EventEmitter<string>();

  public options: string[] = [];
  public selectedLanguage = '';
  public selectLanguageText = $localize`:@@zef-i18n-00266:Select language`;

  public readonly locales = Locales;

  public menuWidth: string = '286px';

  ngOnChanges() {
    this.options = Object.keys(this.languages);
    this.selectedLanguage = this.activeLanguage;
  }

  flagClass(locale: string) {
    return locale && locale.length !== 2 ? 'zef-lang-other' : `zef-lang-${locale}`;
  }

  public selectOption(option: string) {
    this.selectedLanguage = option;
    this.changeLanguage.emit(option);
  }

  public onMenuResize($event) {
    this.menuWidth = $event.dimensions.width + 'px';
  }
}

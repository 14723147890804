/**
 * Shareable invite module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ParseEmail } from '@home/shared/pipes/parse-email.pipe';

import { InvitePeople } from '@home/shared/dialogs/invite-people/invite-people.dialog';

@NgModule({
  bootstrap: [],
  declarations: [ParseEmail, InvitePeople],
  exports: [ParseEmail],
  imports: [SharedModule],
  providers: [ParseEmail],
})
export class InviteModule {}

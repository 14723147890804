<zef-dialog
  [type]="'ask'"
  [logo]="teamLogo$ | async"
  [logoType]="'square'"
  [title]="dialogTitle"
  [label]="(team$ | async)?.name || dialogLabel"
  [header]="dialogHeader"
>
  <div dialog-content class="z-fx-col z-fx-start-start z-fx-gap-16 z-fx-flex">
    <div *ngIf="owner$ | async" class="avatar-box z-fx z-fx-gap-16 z-fx-start-center">
      <div
        class="zef-avatar-image zef-small"
        [style.background-image]="
          (owner$ | async)?.avatar || 'assets/images/user-placeholder.png' | encodeURI : 'url' | safeStyle
        "
      ></div>

      <div class="z-fx-col z-fx-gap-4">
        <strong>{{ (owner$ | async)?.name }}</strong>
        <div>{{ (owner$ | async)?.email }}</div>
      </div>
    </div>
  </div>

  <div dialog-actions class="z-fx z-fx-start-end z-fx-gap-4">
    <ng-container *ngIf="(owner$ | async) === null">
      <button mat-raised-button color="secondary" [matDialogClose]="responses.Revert" i18n>Revert to old Plan</button>
      <button mat-raised-button color="primary" [matDialogClose]="responses.Select" i18n>Select a Plan</button>
    </ng-container>
    <button *ngIf="owner$ | async" mat-raised-button class="trial-okay-button" i18n [matDialogClose]="responses.Cancel">
      Okay
    </button>
  </div>

  <div *ngIf="owner$ | async" dialog-caption class="mat-caption" i18n>
    An
    <user-role mat-dialog-close role="owner"></user-role>
    can change the plan
  </div>
</zef-dialog>

/**
 * Welcome card component
 *
 * @unstable
 */

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { LanguageManager } from '@player/shared/services/language-manager.service';

@Component({
  selector: 'welcome-card',
  templateUrl: './welcome-card.component.html',
  styleUrls: ['./welcome-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeCard {
  @Input() isAnonymous?: boolean;
  @Input() showLanguageSelector?: boolean;

  constructor(readonly ss: SurveyStore, readonly lm: LanguageManager) {}

  flagClass(locale: string) {
    return locale && locale.length !== 2 ? 'zef-lang-other' : `zef-lang-${locale}`;
  }
}

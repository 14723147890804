import { Component, Input } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';

import { OutcomeData } from '@shared/models/survey.model';

@Component({
  selector: 'promo-text',
  templateUrl: './promo-text.component.html',
  styleUrls: ['./promo-text.component.scss'],
})
export class PromoText {
  @Input() outcomeData?: OutcomeData;

  constructor(readonly ss: SurveyStore) {}
}

/**
 * Manages report data stored on the server.
 *
 * @unstable
 */

// TODO: Rename this to reports-manager and move prefs stuff to prefs manager

import { environment } from '@env/environment';

import firebase from 'firebase/compat/app';

import { BehaviorSubject, combineLatest, forkJoin, Observable, of, throwError, timer } from 'rxjs';
import { catchError, debounce, first, map, mergeMap, shareReplay, switchMap, tap, timeout } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LocalStorage } from 'ngx-webstorage';

import { Cards } from '@shared/enums/cards.enum';
import { Rights } from '@shared/enums/rights.enum';

import { AccountState } from '@shared/states/account.state';

import { CardData, IdentityData } from '@shared/models/prefs.model';
import { ContactsSummary, StoredReport, RespondentFields } from '@shared/models/report.model';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';

import { DialogControl } from '@shared/services/dialog-control.service';
import { PrefsManager } from '@shared/services/prefs-manager.service';

import { firebaseSafe } from '@shared/services/objects-manager.service';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { ReportData } from '@shared/models/survey.model';
import { AuthState } from '@shared/states/auth.state';

@Injectable({
  providedIn: 'root',
})
export class ReportManager {
  public saved = new BehaviorSubject<boolean>(false);

  @LocalStorage('consent') trackingAllowed: boolean | undefined;

  constructor(
    private http: HttpClient,
    readonly store: Store,
    private dc: DialogControl,
    private db: DatabaseWrapper,
    readonly pm: PrefsManager,
  ) {}

  public getAnswers(
    surveyKey: string,
    reportKey: string,
    since: number | null = null,
    filters: any = [],
    mergedSurveys: string[] = [],
    latestAnswerTime: number = 0,
  ): Observable<any> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken: string = this.store.selectSnapshot(AuthState.authToken);

    const url: string = `https:${environment.apiServer}/report/data`;
    const linkUrl: string = `https:${environment.apiServer}/report/dataurl/${surveyKey}`;
    const data: { [prop: string]: any } = {
      survey: {
        key: surveyKey,
      },
      filters,
      since,
      outcomes: true,
      mergedSurveys,
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    const nonCached = this.http
      .post(url, data, {
        headers,
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        timeout(300000),
        catchError((error: any) => {
          if (error && error.status !== 403) {
            this.dc.openErrorDialog(
              $localize`:@@zef-i18n-00040:Server Error`,
              $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
            );
          }

          return throwError(error);
        }),
      );

    const cached = this.http
      .get<{ url: string } | undefined>(linkUrl, { headers, withCredentials: this.trackingAllowed || false })
      .pipe(
        timeout(300000),
        catchError(() => of(null)),
        mergeMap((responseLink) => {
          if (responseLink?.url) {
            return this.http.get(responseLink.url, { withCredentials: false }).pipe(
              timeout(300000),
              catchError(() => nonCached),
              switchMap((res: any) => {
                if (res && res.latestAnswerTime < latestAnswerTime) {
                  const reqData: { [prop: string]: any } = {
                    survey: {
                      key: surveyKey,
                    },
                    filters,
                    since: res.newestRecord || 0,
                    outcomes: true,
                    mergedSurveys,
                  };

                  const newData$ = this.http
                    .post(url, reqData, {
                      headers,
                      withCredentials: this.trackingAllowed || false,
                    })
                    .pipe(
                      timeout(300000),
                      catchError((error: any) => {
                        if (error && error.status !== 403) {
                          this.dc.openErrorDialog(
                            $localize`:@@zef-i18n-00040:Server Error`,
                            $localize`:@@zef-i18n-00043:Uups... New data was not loaded.`,
                          );
                        }

                        return throwError(error);
                      }),
                    );

                  return combineLatest([of(res), newData$]).pipe(
                    map(([cachedData, newData]) => {
                      const combined = {};

                      if (newData) {
                        combined['result'] = cachedData['result'].concat(newData['result'] || []);
                        combined['newestRecord'] = newData['newestRecord'] || cachedData['newestRecord'];
                        combined['cursor'] = newData['cursor'] || cachedData['cursor'];
                        combined['newestAnsweringTime'] =
                          newData['newestAnsweringTime'] || cachedData['newestAnsweringTime'];
                        combined['oldestAnsweringTime'] =
                          cachedData['oldestAnsweringTime'] || newData['oldestAnsweringTime'];
                        combined['latestAnswerTime'] = newData['latestAnswerTime'] || cachedData['latestAnswerTime'];
                      }

                      return combined;
                    }),
                  );
                } else {
                  return of(res);
                }
              }),
            );
          } else {
            return nonCached;
          }
        }),
      );

    if ((!filters || !filters.length) && !since && (!mergedSurveys || !mergedSurveys.length)) {
      return cached;
    } else {
      return nonCached;
    }
  }

  public getTextAnswers(
    surveyKey: string,
    questionKeys: string[] = [],
    filters: any = [],
    cursor: string = '',
  ): Observable<any> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);
    const url = `https:${environment.apiServer}/report/data`;

    const data = {
      survey: {
        key: surveyKey,
      },
      questions: questionKeys,
      filters,
      rows: 5,
      cursor,
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http.post(url, data, { headers, withCredentials: this.trackingAllowed || false }).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00044:Uups... Text Responses were not loaded.`,
          );
        }

        return throwError(error);
      }),
    );
  }

  public getTextSummary(
    questions: { survey: string; question: string; filters: any[] }[],
    translations: string[],
    reportKey: string,
  ): Observable<any> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);
    const url = `https:${environment.apiServer}/report/textSummary`;

    const data = {
      questions,
      translations,
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http.post(url, data, { headers, withCredentials: this.trackingAllowed || false }).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00534:Uups... Summary text was not loaded.`,
          );
        }

        return throwError(error);
      }),
    );
  }

  public getRespondentFields(surveyKey: string): Observable<RespondentFields[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .list<RespondentFields>(`/respondents/${teamKey}/${surveyKey}/fields/`)
      .snapshotChanges()
      .pipe(
        mapListKeys(),
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public getContacts(
    surveyKey: string,
    reportKey: string,
    properties: any = [],
    rows: number | null = null,
    // filters: any = [],
    // cursor: string = '',
    // answerers: any = [],
    mergedSurveys: string[] = [],
  ): Observable<any> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);
    const url = `https:${environment.apiServer}/report/contacts`;

    const data = {
      survey: {
        key: surveyKey,
      },
      properties,
      // answerers: answerers,
      // filters: filters,
      rows,
      // cursor: cursor,
      mergedSurveys,
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http.post(url, data, { headers, withCredentials: this.trackingAllowed || false }).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00045:Uups... Contacts data was not loaded.`,
          );
        }

        return throwError(error);
      }),
    );
  }

  public getContactSummary(
    surveyKey: string,
    reportKey: string = '',
    mergedSurveys: string[] = [],
  ): Observable<ContactsSummary> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);

    const url = `https:${environment.apiServer}/report/contactssummary`;

    const data = {
      survey: {
        key: surveyKey,
      },
      mergedSurveys,
    };

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http.post<ContactsSummary>(url, data, { headers, withCredentials: this.trackingAllowed || false }).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00046:Uups... Contact properties were not loaded.`,
          );
        }

        return throwError(error);
      }),
    );
  }

  /**
   * Pins or unpins a chart from/to dashboard
   *
   * @param surveyKey [description]
   * @param reportKey [description]
   * @param chartKey  [description]
   * @param pinned    Should the chart be pinned
   */
  public pin(surveyKey: string, reportKey: string, chartKey: string, pinned: boolean, data: any) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const pinnedList = this.db.list<CardData>(`prefs/${teamKey}/${userKey}/pinned`);
    const cardName = data.title || '';

    if (pinned) {
      const cardData: CardData = {
        type: Cards.ANALYZE_CARD,
        title: cardName,
        gridSettings: {
          sizex: 6,
          sizey: 2,
          minCols: 2,
          maxRows: 2,
        },
        params: {
          companyKey: teamKey,
          surveyKey,
          reportKey,
          chartKey,
        },
        link: `/surveys/${surveyKey}/analyze/${reportKey}`,
      };

      pinnedList.push(cardData);
    } else {
      pinnedList
        .snapshotChanges()
        .pipe(first(), mapListKeys())
        .subscribe((pinnedItems: CardData[]) => {
          const removedCards = pinnedItems
            .filter(
              (item) =>
                item.type === Cards.ANALYZE_CARD &&
                item.params &&
                item.params.reportKey &&
                item.params.reportKey === reportKey &&
                item.params.chartKey === chartKey,
            )
            .map((item) => item['$key']);
          removedCards.forEach((key) => pinnedList.remove(key));
        });
    }
  }

  public saveReport(
    surveyKey: string,
    data: any,
    key: string = '',
    storeData: any = {},
    publicStoreData: any = {},
    makePublic: boolean = false,
    makePrivate: boolean = false,
    remove: boolean = false,
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    this.saved.next(true);

    return new Observable<any>((observer) => {
      let newReport;

      const priority = data.order;

      const ref = this.db.database.ref(`/reports/${teamKey}/${surveyKey}`);

      if (key) {
        ref.child(key).setPriority(priority, (error) => {
          if (error) {
            console.error('setWithPriority error', error);
          }
        });

        this.db.list(`/reports/${teamKey}/${surveyKey}`).update(key, {
          title: data.title,
          respondents: data.respondents,
          latestAnswerTime: data.latestAnswerTime,
          savingOnProgress: data.savingOnProgress || true,
          savingError: false,
        });
      } else {
        newReport = this.db.createPushId();
        data.owner = userKey;

        if (data.title.indexOf('Autosaved Report ') === 0) {
          data.isAuthorUnsavedChanges = true;
        }

        ref.child(newReport).setWithPriority(firebaseSafe(data), priority);
      }

      const lastModified = {};
      lastModified[userKey] = firebase.database.ServerValue.TIMESTAMP;

      this.db
        .object(`/reports/${teamKey}/${surveyKey}/${newReport ? newReport : key}/lastModified`)
        .update(lastModified);

      this.storeReportData(
        surveyKey,
        newReport ? newReport : key,
        storeData,
        publicStoreData,
        makePublic,
        makePrivate,
        remove,
      ).subscribe(
        (response) => {},
        (error) => {
          this.db.list(`/reports/${teamKey}/${surveyKey}`).update(newReport ? newReport : key, {
            savingOnProgress: false,
            savingError: true,
          });
        },
        () => {
          this.db.list(`/reports/${teamKey}/${surveyKey}`).update(newReport ? newReport : key, {
            savingOnProgress: false,
            savingError: false,
          });
          if (makePublic) {
            this.listReports(surveyKey)
              .pipe(first())
              .subscribe((reportList) => {
                observer.next(reportList.find((item) => item.$key === (newReport ? newReport : key)));
                observer.complete();
              });
          } else {
            observer.next(newReport);
            observer.complete();
          }
        },
      );
    });
  }

  public editReportName(surveyKey: string, reportKey: string, title: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    const lastModified = {};
    lastModified[userKey] = firebase.database.ServerValue.TIMESTAMP;

    this.db.object(`/reports/${teamKey}/${surveyKey}/${reportKey}/lastModified`).update(lastModified);

    return this.db.list(`/reports/${teamKey}/${surveyKey}`).update(reportKey, {
      title,
    });
  }

  public listReports(surveyKey: string): Observable<ReportData[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .list<ReportData>(`/reports/${teamKey}/${surveyKey}/`, (ref) => ref.orderByPriority().startAt(0))
      .snapshotChanges()
      .pipe(
        mapListKeys(),
        switchMap((lists: ReportData[]) =>
          lists.length
            ? combineLatest<ReportData[]>(
                lists.map((list) =>
                  list.owner
                    ? this.db
                        .object<IdentityData>(`/prefs/${teamKey}/${list.owner}/identity`)
                        .valueChanges()
                        .pipe(
                          catchError(() => of({} as IdentityData)),
                          map((ownerData) => ({ ...list, ownerData } as ReportData)),
                        )
                    : of({} as ReportData),
                ),
              )
            : of(lists),
        ),
        catchError(() => of([])),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public storeReportData(
    surveyKey: string,
    reportKey: string,
    data: any,
    publicData: any,
    makePublic: boolean = false,
    makePrivate: boolean = false,
    remove: boolean = false,
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const url = `https:${environment.apiServer}/report/storage/${surveyKey}/${reportKey}`;
    const publicUrl = `https:${environment.apiServer}/report/public/${surveyKey}/${reportKey}`;
    const authToken = this.store.selectSnapshot(AuthState.authToken);

    return new Observable<any>((observer) => {
      const headers = new HttpHeaders()
        .set('Environment', environment.config)
        .set('Active-Team', teamKey || '')
        .set('Content-Type', 'application/json')
        .set('Firebase-Authorization', authToken || '');

      if (remove) {
        this.http
          .delete(url, { headers, withCredentials: this.trackingAllowed || false })
          .pipe(
            timeout(300000),
            catchError((error: any) => {
              observer.error(error);

              if (error && error.status !== 403) {
                this.dc.openErrorDialog(
                  $localize`:@@zef-i18n-00040:Server Error`,
                  $localize`:@@zef-i18n-00047:Uups... Data was not deleted.`,
                );
              }

              return of(error);
            }),
          )
          .subscribe((response) => {
            observer.next(response);

            observer.complete();
          });
        if (makePublic) {
          this.http
            .delete(publicUrl, { headers, withCredentials: this.trackingAllowed || false })
            .pipe(
              timeout(300000),
              catchError((error: any) => {
                observer.error(error);

                if (error && error.status !== 403) {
                  this.dc.openErrorDialog(
                    $localize`:@@zef-i18n-00040:Server Error`,
                    $localize`:@@zef-i18n-00047:Uups... Data was not deleted.`,
                  );
                }

                return of(error);
              }),
            )
            .subscribe((response) => {
              observer.next(response);

              observer.complete();
            });
        }
      } else {
        this.http
          .post(url, data, { headers, withCredentials: this.trackingAllowed || false })
          .pipe(
            timeout(300000),
            catchError((error: any) => {
              observer.error(error);

              if (error && error.status !== 403) {
                this.dc.openErrorDialog(
                  $localize`:@@zef-i18n-00040:Server Error`,
                  $localize`:@@zef-i18n-00048:Uups... Data was not saved.`,
                );
              }

              return of(error);
            }),
          )
          .subscribe((response) => {
            observer.next(response);

            observer.complete();
          });
        if (makePublic) {
          this.http
            .post(publicUrl, publicData, {
              headers,
              withCredentials: this.trackingAllowed || false,
            })
            .pipe(
              timeout(300000),
              catchError((error: any) => {
                observer.error(error);

                if (error && error.status !== 403) {
                  this.dc.openErrorDialog(
                    $localize`:@@zef-i18n-00040:Server Error`,
                    $localize`:@@zef-i18n-00048:Uups... Data was not saved.`,
                  );
                }

                return of(error);
              }),
            )
            .subscribe((response) => {
              observer.next(response);

              observer.complete();
            });
        } else if (makePrivate) {
          this.http
            .delete(publicUrl, { headers, withCredentials: this.trackingAllowed || false })
            .pipe(
              timeout(300000),
              catchError((error: any) => {
                observer.error(error);

                if (error && error.status !== 403) {
                  this.dc.openErrorDialog(
                    $localize`:@@zef-i18n-00040:Server Error`,
                    $localize`:@@zef-i18n-00047:Uups... Data was not deleted.`,
                  );
                }

                return of(error);
              }),
            )
            .subscribe((response) => {
              observer.next(response);

              observer.complete();
            });
        }
      }
    });
  }

  public getPublicReport(publicKey: string, useCredentials = true) {
    let url = 'assets/testing/data.json';

    if (publicKey !== 'demo') {
      url = `https:${environment.reportAddress}/${publicKey.replace(/\/$/, '')}/data.json`;
    }

    const options = !useCredentials ? {} : { withCredentials: this.trackingAllowed || false };

    return new Observable<any>((observer) => {
      this.http
        .get(url, options)
        .pipe(
          timeout(300000),
          catchError((error: any) => {
            observer.error(error);

            return of(error);
          }),
        )
        .subscribe((response) => {
          observer.next(response);
          observer.complete();
        });
    });
  }

  public getStoredReportData(surveyKey: string, reportKey: string) {
    const url = `https:${environment.apiServer}/report/storage/${surveyKey}/${reportKey}`;
    const authToken = this.store.selectSnapshot(AuthState.authToken);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http.get<StoredReport>(url, { headers, withCredentials: this.trackingAllowed || false }).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00049:Uups... Data was not available.`,
          );
        }

        return throwError(error);
      }),
    );
  }

  public loadChartData(surveyKey: string, reportKey: string, chartKey: string) {
    return this.getStoredReportData(surveyKey, reportKey).pipe(
      map((report: any) => (report && report.charts ? report.charts.find((item) => item.key === chartKey) : null)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    // return this.db.object(`/reports/${teamKey}/${surveyKey}/${reportKey}/grid/`)
    //   .valueChanges()
    //   .pipe(
    //     map((grid: any) => {
    //       return grid ? grid.find((item) => item.key === chartKey) : null;
    //     }),
    //     shareReplay({ refCount: true, bufferSize: 1})
    //   );
  }

  public getLatestAnswerTime(surveyKey: string, mergedSurveys: string[] = []) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const object = (key) =>
      this.db
        .object(`/statuses/surveys/answers/${teamKey}/${key}/`)
        .valueChanges()
        .pipe(
          catchError(() => of(null)),
          map((status: any) => (status ? status.time : null)),
          shareReplay({ refCount: true, bufferSize: 1 }),
        );

    return combineLatest([object(surveyKey)].concat(mergedSurveys.map((item) => object(item))));
  }

  public getLatestContactEditTime(): Observable<number> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    let liveBouncer = 0;

    return this.db
      .object(`/statuses/licenses/contacts/${teamKey}/`)
      .valueChanges()
      .pipe(
        catchError(() => of(null)),
        debounce(
          (val) =>
            new Observable<any>((observer) => {
              if (liveBouncer === 0) {
                liveBouncer += 1;
                observer.next('load');
                observer.complete();
              } else {
                timer(15000)
                  .pipe(first())
                  .subscribe((time) => {
                    observer.next('load');
                    observer.complete();
                  });
              }
            }),
        ),
        map((status: any) => (status ? status.time : null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public shareReport(surveyKey: string, reportKey: string, emails: string[], message?: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const url = `https:${environment.apiServer}/report/invite`;

    const data = {
      survey: {
        key: surveyKey,
      },
      report: {
        key: reportKey,
      },
      emails,
      message,
    };

    const authToken = this.store.selectSnapshot(AuthState.authToken);

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return this.http.post(url, data, { headers, withCredentials: this.trackingAllowed || false }).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00050:Uups... Unable to update report share settings.`,
          );
        }

        return throwError(error);
      }),
      tap((response: any) => {
        if (response.users) {
          const shares = {};

          response.users.forEach((user) => {
            shares[user] = Rights.VIEW;
          });

          this.db.object(`/reports/${teamKey}/${surveyKey}/${reportKey}/shares`).update(shares);
        }
      }),
    );
  }

  public unshareReports(surveyKey: string, reportKey: string, users: any[]) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);
    const urls = [];
    const shares = {};

    for (const user of users) {
      shares[user.key] = Rights.NONE;
      urls.push(`https:${environment.apiServer}/report/invite/${surveyKey}/${reportKey}/${user.email}`);
    }

    this.db.object(`/reports/${teamKey}/${surveyKey}/${reportKey}/shares/`).update(shares);

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');

    return forkJoin(
      urls.map((url) =>
        this.http.delete(url, { headers, withCredentials: this.trackingAllowed || false }).pipe(
          timeout(300000),
          catchError((error: any) => {
            if (error && error.status !== 403) {
              this.dc.openErrorDialog(
                $localize`:@@zef-i18n-00040:Server Error`,
                $localize`:@@zef-i18n-00050:Uups... Unable to update report share settings.`,
              );
            }

            return throwError(error);
          }),
        ),
      ),
    );
  }

  public listReportShares(surveyKey: string, reportKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/reports/${teamKey}/${surveyKey}/${reportKey}/shares`)
      .valueChanges()
      .pipe(
        catchError(() => of(null)),
        switchMap((shares: { [key: string]: Rights }) => {
          const users = Object.keys(shares || {}).map((userKey) =>
            this.pm
              .loadIdentity(userKey)
              .pipe(map((identity) => Object.assign(identity, { key: userKey, rights: shares[userKey] }))),
          );

          return combineLatest(users);
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public getReportDetails(surveyKey: string, reportKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/reports/${teamKey}/${surveyKey}/${reportKey}`)
      .valueChanges()
      .pipe(
        catchError(() => of(null)),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public saveOpenedAtTime(surveyKey: string, reportKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    const lastOpened = {};
    lastOpened[userKey] = firebase.database.ServerValue.TIMESTAMP;

    return this.db.object(`/reports/${teamKey}/${surveyKey}/${reportKey}/lastOpened`).update(lastOpened);
  }

  public unsavedChangesToReport(surveyKey: string, reportKey: string, title: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    const lastModified = {};
    lastModified[userKey] = firebase.database.ServerValue.TIMESTAMP;

    return this.db.list(`/reports/${teamKey}/${surveyKey}`).update(reportKey, {
      title,
      lastModified,
      isAuthorUnsavedChanges: false,
    });
  }

  public clearUnsavedChangesReports(surveyKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    return this.listReports(surveyKey)
      .pipe(first())
      .subscribe((reports) => {
        if (reports) {
          for (let r = 0, len = reports.length; r < len; r++) {
            if (reports[r]['owner'] === userKey && reports[r]['isAuthorUnsavedChanges']) {
              const reportKey = reports[r]['$key'];
              const priority = (1 / new Date().valueOf()) * -1;
              const ref = this.db.database.ref(`/reports/${teamKey}/${surveyKey}`);

              ref.child(reportKey).setPriority(priority, (error) => {
                if (error) {
                  console.error('setWithPriority error', error);
                }
              });

              this.storeReportData(surveyKey, reportKey, {}, {}, false, false, true).subscribe(() => {});
            }
          }
        }
      });
  }

  public getFileLink(survey: string, question: string, respondent: number, reportKey: string): Observable<any> {
    const url = `https:${environment.apiServer}/report/download/${survey}/${question}/${respondent}`;
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', reportKey || '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');
    const options = { headers, withCredentials: this.trackingAllowed || false };

    return this.http.get(url, options).pipe(timeout(300000));
  }

  public removeRespondents(respondents: number[]): Observable<any> {
    const url = `https:${environment.apiServer}/delete/respondents/${respondents.join()}`;
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const authToken = this.store.selectSnapshot(AuthState.authToken);

    const headers = new HttpHeaders()
      .set('Environment', environment.config)
      .set('Active-Team', teamKey || '')
      .set('Target-Report', '')
      .set('Content-Type', 'application/json')
      .set('Firebase-Authorization', authToken || '');
    const options = { headers, withCredentials: this.trackingAllowed || false };

    return this.http.delete(url, options).pipe(
      timeout(300000),
      catchError((error: any) => {
        if (error && error.status !== 403) {
          this.dc.openErrorDialog(
            $localize`:@@zef-i18n-00040:Server Error`,
            $localize`:@@zef-i18n-00047:Uups... Data was not deleted.`,
          );
        }

        return of(error);
      }),
    );
  }

  public trackReportOpen(
    respondentCount: number,
    surveyKey: string,
    reportKey: string,
    teamKey: string,
    type: 'open' | 'open_shared_private' | 'open_shared_public' | 'update' | 'update_shared_private',
    initial: boolean,
  ): void {
    const url = `https:${environment.apiServer}/track/report`;
    const headers = new HttpHeaders()
      .set('Active-Team', teamKey || this.store.selectSnapshot(AccountState.teamKey) || '')
      .set('Firebase-Authorization', this.store.selectSnapshot(AuthState.authToken) || '');
    this.http
      .post(
        url,
        { respondentCount, surveyKey, reportKey, type, initial },
        { headers, withCredentials: this.trackingAllowed || false },
      )
      .subscribe(() => {});
  }
}

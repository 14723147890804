/**
 * Basic helpers module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { SafeHtml } from '@shared/pipes/safe-html.pipe';
import { SafeStyle } from '@shared/pipes/safe-style.pipe';
import { SafeSource } from '@shared/pipes/safe-source.pipe';
import { EncodeURI } from '@shared/pipes/encode-uri.pipe';
import { FormatText } from '@shared/pipes/format-text.pipe';
import { ClampNumber } from '@shared/pipes/clamp-number.pipe';
import { OrderNumber } from '@shared/pipes/order-number.pipe';
import { HexifyColor } from '@shared/pipes/hexify-color.pipe';
import { TenThousands } from '@shared/pipes/ten-thousands.pipe';
import { PropertyText } from '@shared/pipes/property-text.pipe';
import { SanitizeHtml } from '@shared/pipes/sanitize-html.pipe';
import { ContrastColor } from '@shared/pipes/contrast-color.pipe';

import { AutoGrow } from '@shared/directives/auto-grow.directive';
import { EnterBlur } from '@shared/directives/enter-blur.directive';
import { AutoFocus } from '@shared/directives/auto-focus.directive';
import { AutoSelect } from '@shared/directives/auto-select.directive';
import { PseudoStyle } from '@shared/directives/pseudo-style.directive';
import { FocusControl, FocusRetain } from '@shared/directives/focus-control.directive';
import { ClickOutside } from '@shared/directives/click-outside.directive';
import { TextOverflow } from '@shared/directives/text-overflow.directive';
import { InjectStyles } from '@shared/directives/inject-styles.directive';
import { StickyElement } from '@shared/directives/sticky-element.directive';
import { ElementResize } from '@shared/directives/element-resize.directive';
import { InputDebouncer } from '@shared/directives/input-debouncer.directive';
import { InputSelection } from '@shared/directives/input-selection.directive';
import { ContentOverflow } from '@shared/directives/content-overflow.directive';
import { NavigationState } from '@shared/directives/navigation-state.directive';
import { NumberTransition } from '@shared/directives/number-transition.directive';

import { LogoSpinner } from '@shared/components/logo-spinner/logo-spinner.component';

import { TextHighlight } from '@shared/components/text-highlight/text-highlight.component';

import { FitFontSize } from '@shared/directives/fit-font-size.directive';
import { MediaMonitorModule } from '@shared/modules/media-monitor.module';
import { ZEF_BREAKPOINTS } from '@shared/breakpoints';
import { ButtonLoading } from '@shared/directives/button-loading.directive';
import { AutoSize } from '@shared/directives/auto-size.directive';
import { TextareaAutoSize } from '@shared/directives/textarea-auto-size.directive';
import { EqualWidthContainer, EqualWidthDirective } from '@shared/directives/equal-width.directive';
import { Portal } from '@shared/directives/portal.directive';
import { UnsplashAttribute } from '@shared/directives/unsplash-attribute.directive';
import { NgScrollScrollable } from '@shared/directives/ng-scroll-scrollable.directive';
import { ObserversModule } from '@angular/cdk/observers';
import { LoadingPlaceholderDirective } from '@shared/directives/loading-placeholder.directive';

@NgModule({
  bootstrap: [],
  declarations: [
    SafeHtml,
    SafeStyle,
    SafeSource,
    EncodeURI,
    FormatText,
    ClampNumber,
    OrderNumber,
    HexifyColor,
    TenThousands,
    PropertyText,
    SanitizeHtml,
    ContrastColor,
    AutoGrow,
    AutoFocus,
    EnterBlur,
    AutoSize,
    AutoSelect,
    PseudoStyle,
    FocusRetain,
    FocusControl,
    FitFontSize,
    ClickOutside,
    InjectStyles,
    TextOverflow,
    TextHighlight,
    StickyElement,
    ElementResize,
    InputDebouncer,
    InputSelection,
    ContentOverflow,
    NavigationState,
    NumberTransition,
    Portal,
    TextareaAutoSize,
    UnsplashAttribute,
    LoadingPlaceholderDirective,

    EqualWidthContainer,
    EqualWidthDirective,
    LogoSpinner,
    ButtonLoading,
    NgScrollScrollable,
  ],
  exports: [
    SafeHtml,
    SafeStyle,
    SafeSource,
    EncodeURI,
    FormatText,
    ClampNumber,
    OrderNumber,
    HexifyColor,
    TenThousands,
    PropertyText,
    SanitizeHtml,
    ContrastColor,
    AutoGrow,
    AutoSize,
    AutoFocus,
    EnterBlur,
    AutoSelect,
    PseudoStyle,
    FocusRetain,
    FocusControl,
    FitFontSize,
    ClickOutside,
    InjectStyles,
    TextOverflow,
    TextHighlight,
    StickyElement,
    ElementResize,
    InputDebouncer,
    InputSelection,
    ContentOverflow,
    NavigationState,
    NumberTransition,
    TextareaAutoSize,
    UnsplashAttribute,
    MediaMonitorModule,
    Portal,
    EqualWidthContainer,
    EqualWidthDirective,
    LoadingPlaceholderDirective,

    LogoSpinner,
    ButtonLoading,
    NgScrollScrollable,
  ],
  imports: [FormsModule, MediaMonitorModule.forRoot(ZEF_BREAKPOINTS), CommonModule, ObserversModule],
  providers: [
    SafeHtml,
    SafeStyle,
    EncodeURI,
    FormatText,
    ClampNumber,
    OrderNumber,
    HexifyColor,
    TenThousands,
    PropertyText,
    SanitizeHtml,
    ContrastColor,
  ],
})
export class BasicModule {}

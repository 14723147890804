import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { ApiPromotion } from '@home/+settings/+integrations/api-promotion/api-promotion.dialog';
import { IntegrationsUpgradeDialog } from '@home/shared/dialogs/integration-upgrade/integrations-upgrade.dialog';

import { LindaModule } from '@shared/modules/linda.module';
import { SurveyIntegrationButton } from '@shared/modules/survey-integration/components/survey-integration-button.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule, MatTooltipModule, LindaModule, RouterModule],
  declarations: [SurveyIntegrationButton, IntegrationsUpgradeDialog, ApiPromotion],
  exports: [SurveyIntegrationButton, IntegrationsUpgradeDialog, ApiPromotion],
})
export class IntegrationSharedModule {}

/**
 * Manages survey data stored in the Firebase.
 *
 * @unstable
 */

import { Injectable } from '@angular/core';
import { AngularFireAction, DatabaseSnapshot } from '@angular/fire/compat/database';
import { Store } from '@ngxs/store';
import { Commands } from '@shared/enums/commands.enum';
import { OutcomeOptions } from '@shared/enums/outcomes.enum';
import { Rights } from '@shared/enums/rights.enum';
import { TeamData } from '@shared/models/account.model';
import { LanguageData2, LocalesData, TranslationData } from '@shared/models/locale.model';
import { LicenseUsage } from '@shared/models/plan.model';
import { IdentityData } from '@shared/models/prefs.model';
import {
  DesignData,
  OutcomeData,
  QuestionData,
  ReleaseData,
  SharingData,
  Survey,
  SurveyData,
  SurveyHistory,
  SurveyScoring,
  TriggerData,
} from '@shared/models/survey.model';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { LocalesManager } from '@shared/services/locales-manager.service';
import { unwrapOrderDataFromSnapshot } from '@shared/services/objects-manager.service';
import { OutcomesManager } from '@shared/services/outcomes-manager.service';
import { QuestionsManager } from '@shared/services/questions-manager.service';
import { ScoringManager } from '@shared/services/scoring-manager.service';
import { TriggersManager } from '@shared/services/triggers-manager.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { AccountState } from '@shared/states/account.state';
import { firebaseSafe, isEmpty, objectArrayToArray, pickBy } from '@shared/utilities/object.utilities';
import 'firebase/database';
import { BehaviorSubject, combineLatest, concatMap, forkJoin, from, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  mapTo,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { shareRef } from '@shared/operators/share-ref.operator';
import {
  setOutcomeTranslations,
  setQuestionTranslations,
  setSharingTranslations,
  setSurveyTranslations,
} from '@shared/utilities/language.utilities';
import { PrefsState } from '@shared/states/prefs.state';
import { LocalesState } from '@shared/states/locales/locales.state';
import { mergeLanguageConfig } from '@editor/shared/states/language/language.utilities';
import { UpdateSurveyData } from '@shared/states/survey.actions';
import { MoveToFolder } from '@shared/states/folders.actions';
import { MessageData } from '@shared/models/email.model';

@Injectable({
  providedIn: 'root',
})
export class SurveysManager {
  public creatingSurvey = new BehaviorSubject(false);

  constructor(
    private db: DatabaseWrapper,
    private store: Store,
    private za: ZefApi,
    private cf: CloudFunctions,
    private om: OutcomesManager,
    private sm: ScoringManager,
    private qm: QuestionsManager,
    private lm: LocalesManager,
    readonly tm: TriggersManager,
  ) {}

  public ownedSurveys(teamKey?: string, userKey?: string): Observable<Survey[]> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return !userKey
      ? of([])
      : this.db
          .list(`/users/${userKey}/surveys/${teamKey}`, (ref) => ref.orderByValue().startAt(1))
          .snapshotChanges()
          .pipe(
            catchError((error) => of([])),
            map((snapshot: any[]) =>
              snapshot
                .filter((snap) => snap.payload.val() === 5)
                .map((data) => data.key)
                .map((key) => this.connectSurvey(key, teamKey)),
            ),
          );
  }

  public surveyRespondents(surveyKey: string, teamKey?: string): Observable<number> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object(`/statuses/surveys/answers/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((usage: LicenseUsage) => usage?.success || 0),
        shareRef(),
      );
  }

  public teamSurveyKeys(teamKey?: string): Observable<string[]> {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    if (!teamKey) {
      return of([]);
    }

    return this.db
      .list<SurveyData>(`/surveys/${teamKey}`)
      .snapshotChanges()
      .pipe(
        catchError(() => of([])),
        map((surveys) => (surveys || []).map((snap) => snap.payload.key)),
        shareRef(),
      );
  }

  public listUserSurveys(userKey: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .list(`/users/${userKey}/surveys/${teamKey}`)
      .snapshotChanges()
      .pipe(
        catchError((error) => of([])),
        map((rights: any[]) => rights.map((surveyRights) => this.connectSurvey(surveyRights.key))),
      );
  }

  listTemplates() {
    return this.db
      .list('/templates/categories', (ref) => ref.orderByPriority())
      .snapshotChanges()
      .pipe(map(unwrapOrderDataFromSnapshot));
  }

  listTemplateSurveys(key: string): Observable<Survey[]> {
    return this.db
      .list(`/templates/surveys/${key}`, (ref: any) => ref.orderByChild('title'))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((action) => ({
            ...this.processTemplatePayload(action.payload),
            key: action.key,
            order: action.payload.getPriority(),
          })),
        ),
      );
  }

  private processTemplatePayload(snapshot: DatabaseSnapshot<any>) {
    const payload: any = snapshot.exportVal();
    const withIndexKey = (o: any, i: number) => ({ $key: `${i}`, ...o });

    return {
      ...payload,
      outcomes: Object.values(payload.outcomes || {}).map(withIndexKey),
      questions: this.qm.processChoices(Object.values(payload.questions || {})).map(withIndexKey),
    };
  }

  private processPayloadKey(action: AngularFireAction<DatabaseSnapshot<any>>) {
    const snapshot = action.payload;
    return { $key: snapshot.key, ...snapshot.val() };
  }

  getReleases(surveyKey: string) {
    const survey = this.connectSurvey(surveyKey);

    return (survey.release as Observable<ReleaseData>).pipe(
      filter(Boolean),
      map((data: ReleaseData) =>
        Object.entries(data.revisions || {}).map(([$key, published]) => ({ $key, published })),
      ),
    );
  }

  setSurveyKeyForManagers(surveyKey: string) {
    this.qm.surveyKey.next(surveyKey);
    this.om.surveyKey.next(surveyKey);
    this.tm.surveyKey.next(surveyKey);
    this.lm.surveyKey.next(surveyKey);
  }

  checkSurvey(surveyKey: string) {
    const survey = this.connectSurvey(surveyKey);

    return survey.survey.pipe(
      first(),
      map((data: any) => (!data || data.order < 0 ? false : true)),
    );
  }

  updateSurvey(surveyKey: string, newData: Object) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const survey = this.db.object(`/surveys/${teamKey}/${surveyKey}`);

    return survey.update(newData);
  }

  updateDesign(surveyKey: string, newData: Object) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const design = this.db.object(`/designs/${teamKey}/${surveyKey}`);

    if (!newData['extract']) {
      newData['modified'] = true;
    }

    return design.update(newData);
  }

  updateSharing(surveyKey: string, newData: Object) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/sharing/${teamKey}/${surveyKey}`).update(firebaseSafe(newData));
  }

  updateRelease(surveyKey: string, newData: Partial<ReleaseData>): Promise<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/releases/${teamKey}/${surveyKey}`).update(firebaseSafe(newData));
  }

  public createSurvey(
    name: string,
    data: Survey,
    isDuplicate: boolean = false,
    hideLanguages?: boolean,
  ): Observable<Survey> {
    this.creatingSurvey.next(true);

    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const newSurveyKey = this.db.list(`/surveys/${teamKey}`).push({ users: { [userKey]: Rights.OWNER } }).key as string;

    return this.db
      .object<Rights>(`/users/${userKey}/surveys/${teamKey}/${newSurveyKey}`)
      .valueChanges()
      .pipe(
        // wait for firebase trigger
        filter((rights) => !!rights),
        take(1),
        switchMap(() => {
          console.log('Created new survey', newSurveyKey, data);

          const newSurvey = this.connectSurvey(newSurveyKey);

          this.qm.surveyKey.next(newSurveyKey);
          this.om.surveyKey.next(newSurveyKey);
          this.tm.surveyKey.next(newSurveyKey);
          this.lm.surveyKey.next(newSurveyKey);

          return this.setSurveyData(name, newSurvey, data, isDuplicate);
        }),
        switchMap((survey) => {
          if (!data.locales || isDuplicate) {
            return of(survey);
          }

          return data.locales.pipe(
            take(1),
            switchMap((locales) => {
              if (!locales || !locales.config) {
                return of(void 0);
              }

              const loc$ = Object.keys(locales.config).map((locale) =>
                this.lm.addSurveyLanguage(newSurveyKey, locale, hideLanguages),
              );

              return loc$.length ? forkJoin(loc$) : of(void 0);
            }),
            mapTo(survey),
          );
        }),
        finalize(() => this.creatingSurvey.next(false)),
      );
  }

  public setSurveyData(name: string, survey: Survey, data: Survey, isDuplicate: boolean): Observable<Survey> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const defaults = this.connectTemplate('default', 'empty');

    return combineLatest([
      this.db.object<TeamData>(`teams/${teamKey}`).valueChanges(),
      combineLatest([data.survey, data.design, data.locales, data.sharing]),
      combineLatest([data.scoring, data.outcomes, data.questions, data.triggers]),
      combineLatest([defaults.survey, defaults.questions, defaults.outcomes, defaults.design, defaults.sharing]),
    ]).pipe(
      take(1),
      concatMap(
        ([
          teamData,
          [surveyData, designData, locales, sharing],
          [scoring, outcomes, questions, triggers],
          [defaultSurvey, defaultQuestions, defaultOutcomes, defaultDesign, defaultSharing],
        ]) => {
          // Update survey data
          const surveyName = name || surveyData.name || defaultSurvey.name;
          questions = Array.isArray(questions) ? questions : defaultQuestions || [];
          outcomes = Array.isArray(outcomes) ? outcomes : defaultOutcomes || [];
          sharing = sharing || defaultSharing;

          const newSurveyData: Partial<SurveyData> = {
            ...pickBy(surveyData, (_, k) => !['$key', 'users', 'groups', 'created', 'published'].includes(k)),
            anonymous: 0,
            created: Date.now(),
            modified: Date.now(),
            name: isDuplicate ? `Copy of ${surveyData.name}` : surveyName,
            sharing: Rights.EDIT,
            template: data.key,
            autojump: surveyData.autojump || defaultSurvey.autojump,
            welcome: surveyData.welcome || defaultSurvey.welcome,
            results: surveyData.results || defaultSurvey.results,
          };

          const isFunnelEnabled = teamData.created > new Date(2020, 9, 1).getTime();
          if (isFunnelEnabled) {
            newSurveyData.funnel = { type: 'zef-funnel-two' };
          }

          designData = {
            ...defaultDesign,
            ...(designData || {}),
          };

          // Update design data
          if (designData && designData.logo && !designData.logo.url && teamData) {
            designData.logo.url = teamData.logo || '';
            designData.logo.mode = 'contain';
          }

          let designStore = of((designData.theme && designData.theme.url) || '');

          if (designData.theme && designData.theme.url) {
            designStore = this.cf
              .put(Commands.FilesStorage, `${teamKey}/styles/${survey.key}.scss`, designData.theme.url)
              .pipe(
                map((response) => (response && response.url) || ''),
                tap((response) => console.log('Theme file copied', response)),
              );
          }

          // Update sharing data
          delete sharing.$key;

          return forkJoin([
            this.qm.insertQuestions(questions, !isDuplicate),
            this.om.insertOutcomes(outcomes, !isDuplicate),
            this.db.object(`/surveys/${teamKey}/${survey.key}`).update(firebaseSafe(newSurveyData)),
            designStore.pipe(
              concatMap((url) => {
                if (designData.theme) {
                  designData.theme.url = url;
                }

                return this.db.object(`/designs/${teamKey}/${survey.key}`).set(designData);
              }),
            ),
            this.db.object(`/sharing/${teamKey}/${survey.key}`).set(firebaseSafe(sharing)),
            this.tm.insertTriggers(triggers, !isDuplicate),
          ]).pipe(
            switchMap(([newQuestions, newOutcomeKeys]) => {
              const newOutcomes = newOutcomeKeys.map((key) => ({ $key: key })) as OutcomeData[];
              const scoringData = this.sm.createScoringData(scoring, newOutcomes, newQuestions);

              return forkJoin([
                this.sm.setScoring(teamKey, survey.key, scoringData),
                this.lm.duplicateTranslations(locales, newOutcomes, newQuestions, !isDuplicate),
              ]);
            }),
          );
        },
      ),
      mapTo(survey),
    );
  }

  public deleteSurvey(surveyKey: string): Observable<unknown> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.store.dispatch(new MoveToFolder(null, [surveyKey]));

    return from(this.db.object(`/surveys/${teamKey}/${surveyKey}`).remove()).pipe(
      switchMap(() => this.za.delete(`delete/answers/${surveyKey}`, null)),
      catchError(() => of(null)),
    );
  }

  public getSurveyData(surveyKey: string, teamKey?: string, skipTranslations?: boolean): Observable<SurveyData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<SurveyData>(`/surveys/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => (data ? { ...data, $key: surveyKey } : {}) as SurveyData),
        switchMap((data) =>
          skipTranslations
            ? of(data)
            : this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
                map((strings) => setSurveyTranslations(data, strings)),
              ),
        ),
        catchError(() => of({} as SurveyData)),
        shareRef(),
      );
  }

  public getOwnerData(surveyKey: string, teamKey?: string): Observable<IdentityData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<IdentityData>(`/surveys/${teamKey}/${surveyKey}/users`)
      .valueChanges()
      .pipe(
        switchMap((rightsData) => {
          const ownerKey = Object.keys(rightsData || {}).find((key) => rightsData[key] === Rights.OWNER);

          if (ownerKey) {
            return this.db
              .object<IdentityData>(`/prefs/${teamKey}/${ownerKey}/identity`)
              .valueChanges()
              .pipe(map((data) => ({ ...data, uid: ownerKey })));
          } else {
            return of({} as IdentityData);
          }
        }),
        catchError(() => of({} as IdentityData)),
        shareRef(),
      );
  }

  public getRightsData(surveyKey: string, teamKey?: string): Observable<Rights> {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<Rights>(`/users/${userKey}/surveys/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => data || Rights.NONE),
        catchError(() => of(Rights.NONE)),
        shareRef(),
      );
  }

  public getDesignData(surveyKey: string, teamKey?: string): Observable<DesignData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<DesignData>(`/designs/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => (data ? data : ({} as any))),
        catchError(() => of({} as DesignData)),
        shareRef(),
      );
  }

  public getScoringData(surveyKey: string, teamKey?: string): Observable<SurveyScoring> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<SurveyScoring>(`/scoring/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as SurveyScoring)),
        catchError(() => of({} as SurveyScoring)),
        shareRef(),
      );
  }

  public getLocalesData(surveyKey: string, teamKey?: string): Observable<LocalesData> {
    return combineLatest([
      this.lm.loadSurveyLocales(surveyKey, teamKey),
      this.store.select(LocalesState.locales),
      this.store.select(LocalesState.languages),
    ]).pipe(
      map(([surveyLocales, defaultLocales, languages]: [LocalesData, LocalesData, LanguageData2[]]) => {
        const config = surveyLocales?.config || {};

        const newSurveyLocales = {
          config: {
            ...config,
          },
          strings: {},
        } as LocalesData;

        Object.entries(config).forEach(([language, configData]) => {
          newSurveyLocales.config[language] = mergeLanguageConfig(language, languages, configData);
        });

        Object.keys(newSurveyLocales.config).forEach((language) => {
          newSurveyLocales.strings[language] = {
            ...(defaultLocales?.strings?.[language] || {}),
            ...pickBy(surveyLocales?.strings?.[language] || {}, (val) => !isEmpty(val)),
          };
        });

        return newSurveyLocales;
      }),
      shareRef(),
    );
  }

  public getDefaultLocalesStrings(surveyKey: string, teamKey?: string): Observable<TranslationData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return combineLatest([
      this.store.select(PrefsState.language),
      this.getSurveyData(surveyKey, teamKey, true).pipe(map(({ language }) => language)),
    ])
      .pipe(
        map(([uiLanguage, surveyLanguage]) => surveyLanguage || uiLanguage || 'en'),
        distinctUntilChanged(),
        shareRef(),
      )
      .pipe(
        switchMap((language) =>
          this.getLocalesData(surveyKey).pipe(map((localesData) => localesData.strings?.[language])),
        ),
        shareRef(),
      );
  }

  public getSharingData(surveyKey: string, teamKey?: string): Observable<SharingData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<SharingData>(`/sharing/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => data || ({} as SharingData)),
        switchMap((sharing) =>
          this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
            map((strings) => setSharingTranslations(sharing, strings)),
          ),
        ),
        catchError(() => of({} as SharingData)),
        shareRef(),
      );
  }

  public getReleaseData(surveyKey: string, teamKey?: string): Observable<ReleaseData> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.db
      .object<ReleaseData>(`/releases/${teamKey}/${surveyKey}`)
      .valueChanges()
      .pipe(
        map((data) => (data ? data : ({} as any))),
        map((data) => ({
          ...data,
          shareLinks: objectArrayToArray(data.shareLinks).filter((shareLink) => !!shareLink),
        })),
        catchError(() => of({} as ReleaseData)),
        shareRef(),
      );
  }

  public getQuestions(surveyKey: string, teamKey?: string): Observable<QuestionData[]> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.qm.questionsData(teamKey, surveyKey).pipe(
      map((data) => data || []),
      switchMap((questions) =>
        this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
          map((strings) => setQuestionTranslations(questions, strings)),
        ),
      ),
      catchError(() => of([])),
      shareRef(),
    );
  }

  public getOutcomes(surveyKey: string, teamKey?: string): Observable<OutcomeData[]> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.om.outcomesData(teamKey, surveyKey).pipe(
      map((data) => data || []),
      switchMap((outcomes) =>
        this.getDefaultLocalesStrings(surveyKey, teamKey).pipe(
          map((strings) => setOutcomeTranslations(outcomes, strings)),
        ),
      ),
      catchError(() => of([])),
      shareRef(),
    );
  }

  public getTriggers(surveyKey: string, teamKey?: string): Observable<TriggerData[]> {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return this.tm.triggerData(teamKey, surveyKey).pipe(
      map((data) => data || []),
      catchError(() => of([])),
      shareRef(),
    );
  }

  public connectSurvey(surveyKey: string, teamKey?: string): Survey {
    teamKey ||= this.store.selectSnapshot(AccountState.teamKey);

    return new Survey({
      key: surveyKey,

      owner: this.getOwnerData(surveyKey, teamKey),

      rights: this.getRightsData(surveyKey, teamKey),

      survey: this.getSurveyData(surveyKey, teamKey),

      design: this.getDesignData(surveyKey, teamKey),

      sharing: this.getSharingData(surveyKey, teamKey),

      scoring: this.getScoringData(surveyKey, teamKey),

      outcomes: this.getOutcomes(surveyKey, teamKey),

      triggers: this.getTriggers(surveyKey, teamKey),

      questions: this.getQuestions(surveyKey, teamKey),

      locales: this.getLocalesData(surveyKey, teamKey),

      release: this.getReleaseData(surveyKey, teamKey),
    });
  }

  public connectTemplate(categoryKey: string, templateKey: string): Survey {
    const templatePath = `/templates/surveys/${categoryKey}/${templateKey}`;

    const template: Survey = new Survey({
      key: templateKey,

      category: categoryKey,

      survey: this.db
        .object<SurveyData>(templatePath + '/survey')
        .valueChanges()
        .pipe(shareRef()),

      design: this.db
        .object<DesignData>(templatePath + '/design')
        .valueChanges()
        .pipe(shareRef()),

      sharing: this.db
        .object<SharingData>(templatePath + '/sharing')
        .valueChanges()
        .pipe(shareRef()),

      scoring: this.db
        .object<any>(templatePath + '/scoring')
        .valueChanges()
        .pipe(shareRef()),

      outcomes: this.db
        .list<OutcomeData[]>(`${templatePath}/outcomes`)
        .snapshotChanges()
        .pipe(
          map((outcomes) => outcomes.map(this.processPayloadKey)),
          shareRef(),
        ),

      triggers: this.db
        .list<TriggerData[]>(`${templatePath}/triggers`)
        .snapshotChanges()
        .pipe(
          map((list: any[]) => list.map(this.processPayloadKey)),
          shareRef(),
        ),

      questions: this.db
        .list<QuestionData[]>(`${templatePath}/questions`)
        .snapshotChanges()
        .pipe(
          map((list: any[]) => list.map(this.processPayloadKey)),
          map((questions) => this.qm.processChoices(questions)),
          shareRef(),
        ),

      locales: this.db
        .object<LocalesData>(templatePath + '/locales')
        .valueChanges()
        .pipe(shareRef()),
    });

    return template;
  }

  public connectHistory(surveyKey: string, teamKey?: string): SurveyHistory {
    teamKey = teamKey || this.store.selectSnapshot(AccountState.teamKey);

    const survey = this.db
      .object<SurveyData>(`/history/${teamKey}/${surveyKey}/surveys`)
      .valueChanges()
      .pipe(
        map((data) => (data ? { ...data, $key: surveyKey } : data)),
        catchError(() => of({} as SurveyData)),
        shareRef(),
      );

    return {
      $key: surveyKey,
      survey,
      owner: survey.pipe(
        switchMap((surveyData) => {
          const rightsData = (surveyData && surveyData.users) || {};
          const ownerKey = Object.keys(rightsData).find((key) => rightsData[key] === Rights.OWNER);

          if (ownerKey) {
            return this.db.object<IdentityData>(`/prefs/${teamKey}/${ownerKey}/identity`).valueChanges();
          } else {
            return of({} as IdentityData);
          }
        }),
        catchError(() => of({} as IdentityData)),
        shareRef(),
      ),
      questions: this.qm.questionHistoryData(teamKey, surveyKey).pipe(
        map((data) => data || []),
        catchError(() => of([])),
        shareRef(),
      ),
    };
  }

  updateResultsOptions(surveyKey: string, resultsOptions: OutcomeOptions): Promise<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.db.object(`/surveys/${teamKey}/${surveyKey}/resultsOptions`).update(resultsOptions);
  }

  migrateSurveyLanguage(
    surveyKey: string,
    language: LanguageData2,
    existingTranslations: TranslationData,
    surveyData: SurveyData,
    questions: QuestionData[],
    outcomes: OutcomeData[],
    sharing: SharingData,
  ): Observable<unknown> {
    return this.lm
      .createLanguageLocal(surveyKey, language, existingTranslations, surveyData, questions, outcomes, sharing)
      .pipe(switchMap(() => this.store.dispatch(new UpdateSurveyData(surveyKey, { localesMigrated: true }))));
  }
}

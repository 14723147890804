import { Injectable } from '@angular/core';

import { QuestionData } from '@shared/models/survey.model';

@Injectable()
export class EditorTriggerManagerService {
  questions: QuestionData[] = [];

  update({ questions }: Partial<EditorTriggerManagerService>): void {
    this.questions = questions || this.questions;
  }
}

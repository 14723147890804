<!--
  Report export component dialog.

  @unstable
-->

<zef-dialog
  #dialog
  *ngIf="{ survey: (survey$ | async)?.survey, design: (survey$ | async)?.design } as asyncData"
  [type]="'ask'"
  [logo]="asyncData?.design?.background?.url || 'assets/images/survey-placeholder.png'"
  [logoType]="'rectangle'"
  [title]="dialogTitle(reportName)"
  [label]="asyncData.survey?.name || dialogLabel"
  [header]=""
  class="report-export-dialog"
  [class.promo]="
    (canExport$$ | async) === false &&
    exportTypes.ExportPro.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) >= 0
  "
>
  <div dialog-content class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16 content">
    <mat-tab-group
      class="z-fx-none export-tabs"
      [(selectedIndex)]="selectedExportTypeIndex"
      (selectedTabChange)="onExportTabChange($event)"
    >
      <ng-container *ngFor="let type of exportTypes.ExportAll">
        <mat-tab>
          <ng-template mat-tab-label class="tab-label">
            <div class="z-fx-col export-item-container">
              <mat-icon class="icon" [style.color]="exportTypes.ExportIconColors[type]">
                {{ exportTypes.ExportIcons[type] }}
              </mat-icon>
              <div class="text">{{ type }}</div>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

    <div
      *ngIf="
        exportTypes.ExportAdvancedSettings.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) >= 0 &&
        ((canExport$$ | async) || exportTypes.ExportPro.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) < 0)
      "
      class="z-fx-col z-fx-auto export-settings"
    >
      <div *ngIf="!showAdvancedOptions" class="description" i18n>
        <ng-container *ngIf="!includeComments">Excluding individual comments</ng-container>
        <ng-container *ngIf="includeComments">Including individual comments</ng-container>
        &ngsp;
        <ng-container *ngIf="includeDatatables !== includeComments; else same">
          <ng-container>and</ng-container>
          &ngsp;
          <ng-container *ngIf="!includeDatatables">excluding supporting datatables.</ng-container>
          <ng-container *ngIf="includeDatatables">including supporting datatables.</ng-container>
          &ngsp;
        </ng-container>
        <ng-template #same>
          <ng-container *ngIf="!includeDatatables">and supporting datatables.</ng-container>
          <ng-container *ngIf="includeDatatables">and supporting datatables.</ng-container>
          &ngsp;
        </ng-template>
        <span class="action-change-type" (click)="onShowAdvancedOptions()">Change</span>
      </div>
      <div *ngIf="showAdvancedOptions" class="list-container z-fx-col">
        <mat-divider></mat-divider>
        <mat-checkbox [(ngModel)]="includeComments" color="primary" i18n>
          Include individual text comments into export file.
        </mat-checkbox>
        <mat-checkbox [(ngModel)]="includeDatatables" color="primary" i18n>
          Include supporting datatables into export file.
        </mat-checkbox>
      </div>
    </div>
    <div
      *ngIf="
        exportTypes.ExportRaw.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) >= 0 &&
        ((canExport$$ | async) || exportTypes.ExportPro.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) < 0)
      "
      class="z-fx-col export-settings"
    >
      <div *ngIf="!showExportTypeList" class="description">
        <ng-container i18n>
          Download a {{ exportTypes.ExportAll[selectedExportTypeIndex].toUpperCase() }} of
        </ng-container>

        <ng-container *ngIf="exportType === 'raw'; else dataFromAllChartsContainer" i18n>
          the original responses.
        </ng-container>
        <ng-template #dataFromAllChartsContainer>
          <ng-container i18n>data from all charts.</ng-container>
        </ng-template>

        <span class="action-change-type" (click)="onShowExportTypes()" i18n>Change</span>
      </div>
      <div *ngIf="showExportTypeList" class="list-container">
        <mat-divider></mat-divider>
        <mat-radio-group class="z-fx-col radio-group" [(ngModel)]="exportType" (change)="onExportTypeChange($event)">
          <mat-radio-button class="radio-button" [disabled]="anonymityTreshold" [value]="'raw'" [color]="'primary'">
            <span i18n>
              Download a {{ exportTypes.ExportAll[selectedExportTypeIndex].toUpperCase() }} of the original responses.
            </span>
            <mat-icon
              *ngIf="anonymityTreshold"
              class="zef-color-ink"
              matTooltip="Anonymous survey"
              i18n-matTooltip
              matTooltipClass="zef-tooltip-ink"
              matTooltipPosition="above"
              [matTooltipDisabled]="!anonymityTreshold"
            >
              anonymous_on
            </mat-icon>
          </mat-radio-button>
          <mat-radio-button class="radio-button" [value]="'summary'" [color]="'primary'" i18n>
            Download a {{ exportTypes.ExportAll[selectedExportTypeIndex].toUpperCase() }} of data from all charts.
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div
      *ngIf="
        (canExport$$ | async) === false &&
        exportTypes.ExportPro.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) >= 0
      "
      class="z-fx all-exports-promo"
    >
      <div class="z-fx-col z-fx-auto content-text">
        <div class="header" i18n>All popular export formats</div>
        <div class="z-fx features">
          <div
            *ngFor="let type of exportTypes.ExportAll"
            class="feature"
            [class.disabled]="exportTypes.ExportPro.indexOf(type) < 0"
          >
            <mat-icon class="icon">check</mat-icon>
            <span>{{ type }}</span>
          </div>
        </div>

        <div class="cta-container">
          <span class="cta" (click)="onClickLink()" i18n>GET ALL EXPORT FORMATS</span>
        </div>
        <div class="starting" i18n>Available starting SMART Plan.</div>
      </div>
      <div class="content-image"></div>
    </div>

    <div *ngIf="exports.length" class="z-fx-col exports-list">
      <mat-divider></mat-divider>
      <ng-container *ngFor="let export of exports">
        <div class="z-fx z-fx-auto z-fx-start-center item-details">
          <div class="z-fx z-fx-none icon-container">
            <mat-icon class="icon" [style.color]="exportTypes.ExportIconColors[export.format]">
              {{ exportTypes.ExportIcons[export.format] }}
            </mat-icon>
          </div>
          <div class="z-fx-col z-fx-flex text-container">
            <div class="title" matLine>{{ export.name + '.' + export.format }}</div>
            <div class="date" matLine i18n>Created on {{ export.created | date : 'LLLL d y' }}</div>
          </div>
          <div class="z-fx-none status-container">
            <ng-container [ngSwitch]="export.status">
              <div *ngSwitchCase="'ready'">
                <div class="status link" i18n (click)="onDownloadExport(export)">Download</div>
              </div>
              <div *ngSwitchCase="'error'">
                <div class="status link" i18n (click)="onDeleteExport(export)">
                  <span class="error">Error...</span>
                  Delete
                </div>
              </div>
              <div *ngSwitchDefault>
                <div class="status" i18n>Generating...</div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    dialog-actions
    class="z-fx-col z-fx-none z-fx-gap-8 footer-box"
    [class.z-fx-start-end]="!!action || !!cancel"
    [class.z-fx-start-stretch]="!(!!action || !!cancel)"
  >
    <div class="z-fx z-fx-none z-fx-end-center z-fx-gap-16">
      <ng-content select="[dialog-tooltips]"></ng-content>

      <div class="z-fx z-fx-auto z-fx-end-center z-fx-gap-4">
        <button
          *ngIf="true"
          mat-raised-button
          color="secondary"
          matDialogClose
          class="z-fx z-fx-center-center close-button"
          i18n
        >
          <span>Close</span>
        </button>
        <button
          *ngIf="
            ((canExport$$ | async) ||
              exportTypes.ExportPro.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) < 0) &&
            exports.length === 0 &&
            !formatChange
          "
          mat-raised-button
          cdkFocusInitial
          class="z-fx z-fx-center-center send-button"
          [color]="'primary'"
          [loading]="exportOnProgress"
          i18n
          (click)="onCreateExport()"
        >
          <span>Create Export</span>
        </button>
        <button
          *ngIf="
            ((canExport$$ | async) ||
              exportTypes.ExportPro.indexOf(exportTypes.ExportAll[selectedExportTypeIndex]) < 0) &&
            ((exports.length > 0 && exportTypes.ExportAll[selectedExportTypeIndex] !== exports[0]['format']) ||
              formatChange ||
              typeChange)
          "
          mat-raised-button
          cdkFocusInitial
          class="z-fx z-fx-center-center send-button"
          [color]="'primary'"
          [loading]="exportOnProgress || formatChange"
          i18n
          (click)="onCreateExport()"
        >
          <span>Replace current export</span>
        </button>
        <button
          *ngIf="
            exports.length > 0 &&
            exportTypes.ExportAll[selectedExportTypeIndex] === exports[0]['format'] &&
            exports[0]['status'] === 'ready' &&
            !typeChange
          "
          mat-raised-button
          cdkFocusInitial
          class="z-fx z-fx-center-center send-button"
          [color]="'success'"
          i18n
          (click)="onDownloadExport(exports[0])"
        >
          <span>Download</span>
        </button>
      </div>
    </div>
  </div>
</zef-dialog>

<zef-dialog
  *ngIf="teamData as team"
  type="ask"
  [logo]="teamLogo"
  logoType="square"
  [title]="'Invite People'"
  i18n-[title]
  label="{{ team.name || 'Unnamed organization' }}"
  i18n-label
  header="Invite people by adding email addresses"
  i18n-header
>
  <div #dc dialog-content class="inputs-wrapper">
    <div class="z-fx z-fx-gap-8">
      <div class="z-fx-col z-fx-gap-16 z-fx-auto">
        <mat-form-field class="zef-align-top">
          <mat-label>
            <ng-container i18n="placeholder|invite people input">
              Enter emails separated by space, comma, or semicolon…
            </ng-container>
          </mat-label>
          <textarea
            matInput
            class="emails-input"
            cdkFocusInitial
            [(ngModel)]="emails"
            (input)="parseEmails.next(emails)"
          ></textarea>
        </mat-form-field>

        <mat-form-field class="zef-align-top">
          <mat-label>
            <ng-container i18n="placeholder|invite message input">Add a message…</ng-container>
          </mat-label>
          <textarea matInput class="message-input" [(ngModel)]="message"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="dc.example" class="z-fx-col z-fx-gap-16 zef-highlight zef-info">
        <strong class="zef-text-p2" i18n>Example</strong>
        <img height="108" src="assets/images/figure-invitation-org.png" />
      </div>
    </div>

    <button
      mat-icon-button
      class="zef-ghost-button"
      [color]="dc.example ? 'ink' : 'warning'"
      (click)="dc.example = !dc.example"
    >
      <mat-icon *ngIf="dc.example">close</mat-icon>
      <mat-icon *ngIf="!dc.example">bulb</mat-icon>
    </button>

    <div class="langauge-selector z-fx z-fx-gap-8 z-fx-start-center">
      <span class="zef-text-p2" i18n>Send invitation in</span>

      <zef-select [value]="locale" (change)="changeLocale($event)">
        <zef-select-option value="en">
          <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
            <figure class="zef-flag-small zef-lang-en"></figure>
            <strong class="zef-text-p2" i18n>English</strong>
          </div>
        </zef-select-option>

        <zef-select-option value="fi">
          <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
            <figure class="zef-flag-small zef-lang-fi"></figure>
            <strong class="zef-text-p2" i18n>Finnish</strong>
          </div>
        </zef-select-option>
      </zef-select>
    </div>
  </div>

  <div class="z-fx z-fx-between-center z-fx-auto" dialog-actions>
    <a class="zef-hyper-link zef-color-muted" routerLink="/settings/users" matDialogClose i18n>Manage People</a>

    <div class="actions z-fx z-fx-gap-4">
      <button mat-raised-button color="secondary" matDialogClose i18n>Cancel</button>

      <button
        mat-raised-button
        color="primary"
        [loading]="loading$ | async"
        [disabled]="!validatedEmails.length || (loading$ | async)"
        (click)="onSendInvites()"
      >
        <ng-container *ngIf="validatedEmails.length <= 1" i18n>Send invitation</ng-container>
        <ng-container *ngIf="validatedEmails.length > 1" i18n>
          Send {{ validatedEmails.length }} invitations
        </ng-container>
      </button>
    </div>
  </div>
</zef-dialog>

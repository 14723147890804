/**
 * Interface for communicating with ZEF billing server.
 *
 * @unstable
 */

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap, shareReplay, mapTo } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { AccountState } from '@shared/states/account.state';

import { LicenseUsage, PlanUsage } from '@shared/models/plan.model';

import {
  InvoiceInfo,
  BillingPlanData,
  PaymentCard,
  ClientInfo,
  InvoiceDetails,
  LicenceUnlockSettings,
  LockedSurveyStatus,
  SCATokenResponse,
  SMSPackage,
  SmsOptions,
  LockedResource,
} from '@shared/models/billing.model';

import { Country } from '@shared/enums/countries.enum';
import { ZefApi } from '@shared/services/zef-api.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';

import { DeleteDataAnswer } from '@plans/shared/models/plans.model';
import { PlanPayment } from '@plans/shared/models/plans.model';

@Injectable({
  providedIn: 'root',
})
export class BillingApi {
  constructor(readonly db: DatabaseWrapper, readonly store: Store, readonly za: ZefApi) {}

  public getActivePlan(teamKey?: string): Observable<BillingPlanData> {
    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);

    teamKey = teamKey || activeTeamKey;

    return this.db
      .object(`/billing/${teamKey}`)
      .valueChanges()
      .pipe(
        catchError(() => of({} as any)),
        map((data) => ({
          options: [],
          ...data,
        })),
        map((data) => {
          if (!data) {
            data = {};
          }
          if (!data.paymentPlan) {
            data.paymentPlan = {} as PlanPayment;
          }
          const { payment, upcoming } = data;

          data.paymentPlan.nextBillingDate = payment && payment.nextBillingDate && new Date(payment.nextBillingDate);
          data.paymentPlan.period = (payment && payment.period) || 'monthly';
          data.paymentPlan.price = (payment && payment.price) || 0;
          data.paymentPlan.taxPercentage = (payment && payment.taxPercentage) || 0;

          delete data.payment;

          if (upcoming && upcoming.payment) {
            data.upcoming.paymentPlan = {} as PlanPayment;
            data.upcoming.paymentPlan.nextBillingDate =
              upcoming.payment.nextBillingDate && new Date(upcoming.payment.nextBillingDate);
            data.upcoming.paymentPlan.period = upcoming.payment.period || 'monthly';
            data.upcoming.paymentPlan.price = upcoming.payment.price || 0;

            delete data.upcoming.payment;
          }

          return data as BillingPlanData;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
  }

  public dowgradeStatusRead(): Observable<void> {
    return this.za.post<void>('billing/forced_free_msg_read');
  }

  public trialStatusRead(): Observable<void> {
    return this.za.post<void>('billing/trial_msg_read');
  }

  public paymentStatusRead(): Observable<void> {
    return this.za.post<void>('billing/payment_msg_read');
  }

  public cancelPlan(order?: DeleteDataAnswer): Observable<void> {
    return this.za.post<void>('billing/cancel_active', { order });
  }

  public tryFree(): Observable<boolean> {
    return this.za
      .post<{ status: string }>('billing/try_free')
      .pipe(map((response) => response && response.status === '1'));
  }

  public getStripeToken(): Observable<{ scaToken?: string }> {
    return this.za.get('billing/sca_token');
  }

  public getLocked(): Observable<LockedResource[]> {
    return this.za.get<{ statuses: LockedResource[] }>('license/locked').pipe(
      map(({ statuses }) => statuses || []),
      map((resources) => resources.filter((resource) => resource.sourceType !== 'list' || resource.sourceName)),
    );
  }

  public getInvoiceDetails(id: string): Observable<InvoiceDetails> {
    return this.za.get<{ invoice: InvoiceDetails }>(`billing/history/${id}`).pipe(map((data) => data.invoice));
  }

  public getAccountDetails(): Observable<ClientInfo> {
    return this.za.get<ClientInfo>(`billing/account`).pipe(
      tap((account) => {
        account.complete = !!(account.type && (account.type === 'individual' || account.vatId) && account.country);
      }),
    );
  }

  public getPaymentHistory(): Observable<InvoiceInfo[]> {
    return this.za.get<{ invoices: InvoiceInfo[] }>(`billing/history`).pipe(map(({ invoices }) => invoices));
  }

  public getPaymentCard(): Observable<PaymentCard> {
    return this.za.get<PaymentCard>(`billing/default_card`).pipe(
      tap((card) => {
        if (card) {
          card.complete = !!card.cardToken && !!card.last4;
        }
      }),
    );
  }

  public getUsage(teamKey?: string): Observable<PlanUsage> {
    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);

    teamKey = teamKey || activeTeamKey;

    return combineLatest(
      ['answers', 'emails', 'contacts', 'sms']
        .map((type) =>
          ['licenses', 'reserves'].map((sort) =>
            this.db
              .object<LicenseUsage>(`/statuses/${sort}/${type}/${teamKey}`)
              .valueChanges()
              .pipe(
                catchError(() => of({} as any)),
                map((usage) => this.processUsage(usage, type !== 'sms')),
              ),
          ),
        )
        .reduce((acc, curr) => acc.concat(curr), []),
    ).pipe(
      map(([answers, extra_answers, emails, extra_emails, contacts, extra_contacts, sms, extra_sms]) => ({
        contacts,
        answers,
        emails,
        sms,
        extra_answers,
        extra_emails,
      })),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  public validateVAT(country: keyof typeof Country, vatId: string): Observable<boolean> {
    return this.za
      .post<{ valid: boolean }>('billing/check_vat_number', {
        vatId,
        country,
      })
      .pipe(map((data) => data.valid));
  }

  public getSurveyUsage(surveyKey: string): Observable<PlanUsage> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return combineLatest(
      ...['answers', 'emails', 'contacts']
        .map((type) =>
          ['surveys'].map((sort) =>
            this.db
              .object<LicenseUsage>(`/statuses/${sort}/${type}/${teamKey}/${surveyKey}`)
              .valueChanges()
              .pipe(
                catchError(() => of({} as any)),
                map((usage) => this.processUsage(usage)),
              ),
          ),
        )
        .reduce((acc, curr) => acc.concat(curr), []),
    ).pipe(map(([answers, emails, contacts]) => ({ contacts, answers, emails })));
  }

  public getSmsPackages(): Observable<SMSPackage[]> {
    return this.za.get<SmsOptions>('plans/sms').pipe(
      map((response) => (response && response.options) || []),
      catchError((error) => of([])),
    );
  }

  public updateCardToken(cardToken: string): Observable<SCATokenResponse> {
    return this.za.post<SCATokenResponse>('billing/default_card', { cardToken });
  }

  public updateClientInfo(data: ClientInfo): Observable<ClientInfo> {
    return this.za.post<ClientInfo>(`billing/account`, data);
  }

  public unlockSource(settings: LicenceUnlockSettings): Observable<{ unlockedCount: number }> {
    return this.za.post<{ unlockedCount: number }>('license/unlock', settings);
  }

  public smsRefill(enabled: boolean, amount: number = null) {
    return this.za.post('billing/sms_reload', { enabled, amount });
  }

  private processUsage(usage: LicenseUsage, active = true): LicenseUsage {
    return {
      active,
      count: 0,
      failed: 0,
      retried: 0,
      success: 0,
      processed: 0,
      ...usage,
    };
  }

  private processLocked(data: LockedSurveyStatus): LockedSurveyStatus {
    return {
      answers: {
        licenseFull: 0,
      },
      contacts: {
        licenseFull: 0,
      },
      ...data,
    };
  }
}

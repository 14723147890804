import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, switchMap, map } from 'rxjs/operators';

import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

import { Store } from '@ngxs/store';

import { shareRef } from '@shared/operators/share-ref.operator';
import { SurveyState } from '@shared/states/survey.state';
import { IntegrationsState } from '@shared/states/integrations.state';
import { IntegrationSurveyStatus } from '@shared/models/integrations.model';
import { SurveyIntegrationService } from '@shared/modules/survey-integration/services/survey-integration.service';

const integrationLocales = {
  [IntegrationSurveyStatus.NotIntegrated]: $localize`Integrate survey`,
  [IntegrationSurveyStatus.Disconnected]: $localize`Integration disconnected`,
  [IntegrationSurveyStatus.Integrated]: $localize`Integration active`,
  [IntegrationSurveyStatus.Inactive]: $localize`Integration paused`,
};

@Component({
  selector: 'survey-integration-button',
  templateUrl: './survey-integration-button.component.html',
  styleUrls: ['./survey-integration-button.component.scss'],
})
export class SurveyIntegrationButton implements OnChanges {
  readonly locales = integrationLocales;

  readonly Status = IntegrationSurveyStatus;

  private surveyKey$ = new ReplaySubject<string | undefined>(1);

  @Input()
  surveyKey?: string;

  @Input()
  mode: 'button' | 'icon';

  @Input()
  showWhenIntegrated?: boolean;

  readonly surveyIntegrationStatus$: Observable<IntegrationSurveyStatus> = this.surveyKey$.pipe(
    distinctUntilChanged(),
    switchMap((surveyKey) => this.store.select(IntegrationsState.surveyIntegrationStatus(surveyKey))),
    shareRef(),
  );

  readonly writeAccessDenied$: Observable<boolean> = this.surveyKey$.pipe(
    switchMap((surveyKey) => this.store.select(SurveyState.canEdit(surveyKey))),
    map((canEdit) => !canEdit),
    shareRef(),
  );

  constructor(private store: Store, private si: SurveyIntegrationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.surveyKey$.next(this.surveyKey);
  }

  openIntegration(event: MouseEvent): void {
    if (this.surveyKey) {
      event.stopPropagation();
      this.si.openIntegration(this.surveyKey);
    }
  }

  shouldShow(status: IntegrationSurveyStatus): boolean {
    return (
      (status !== IntegrationSurveyStatus.NotConnected || this.mode !== 'icon') &&
      (!this.showWhenIntegrated ||
        status === IntegrationSurveyStatus.Integrated ||
        status === IntegrationSurveyStatus.Inactive)
    );
  }

  shouldShowIcon(status: IntegrationSurveyStatus): boolean {
    return (
      status !== IntegrationSurveyStatus.NotConnected &&
      (this.mode === 'icon' ||
        [
          IntegrationSurveyStatus.Integrated,
          IntegrationSurveyStatus.Inactive,
          IntegrationSurveyStatus.Disconnected,
        ].includes(status))
    );
  }
}

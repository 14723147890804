<div class="z-fx-col z-fx-auto z-fx-center-center content">
  <button mat-raised-button class="zef-ghost-button dropdown-button" color="ink" [mat-menu-trigger-for]="dropMenu">
    <figure
      *ngIf="selectedLanguage"
      [class.flag]="selectedLanguage?.length === 2"
      [ngClass]="flagClass(selectedLanguage?.toLowerCase())"
    ></figure>
    <span>
      {{
        (languages && (languages[selectedLanguage]?.locale || languages[selectedLanguage]?.name)) || selectLanguageText
      }}
    </span>
    <mat-icon class="z-arrow-icon">arrow_down</mat-icon>
  </button>
  <mat-menu #dropMenu class="drop-menu" x-position="before" y-position="below">
    <button
      *ngFor="let option of options"
      mat-menu-item
      class="z-fx z-fx-start-center z-fx-gap-8 z-option"
      [class.z-selected]="option === selectedLanguage"
      [style.width]="menuWidth"
      (click)="selectOption(option)"
    >
      <figure [class.flag]="option?.length === 2" [ngClass]="flagClass(option?.toLowerCase())"></figure>
      <span class="z-fx-auto text">
        {{ (languages && (languages[option]?.locale || languages[option]?.name)) || '' }}
      </span>
    </button>
  </mat-menu>
</div>

<div *ngIf="!bigTable && !isUnderAnonymityTreshold()" class="z-fx z-fx-around-center stats">
  <div class="z-fx z-fx-around-center z-fx-gap-8">
    <div class="z-fx z-fx-around-center">
      <mat-icon class="icon">contact</mat-icon>
      <span numberTransition class="number" [data]="responses" [duration]="transitionDuration"></span>
    </div>
  </div>
</div>

<div *ngIf="!!chartSettings?.textSummary" class="z-fx z-fx-gap-8 summary" (elementResize)="summaryResize($event)">
  <mat-icon>highlight</mat-icon>
  <div class="text-scroll-area">
    <div class="zef-text-p1 summary-text">{{ summary$ | async }}</div>
  </div>
</div>

<div class="table-area">
  <div *ngIf="!isUnderAnonymityTreshold()" class="search-box">
    <zef-searchbox (input)="searchUpdate$.next($event)">
      <span i18n>Find...</span>
    </zef-searchbox>
  </div>
  <div *ngIf="selectedRespondents?.length > 0" class="z-fx z-fx-auto selected-bar" [style.height]="headerHeight + 'px'">
    <div class="z-fx z-fx-auto text" i18n>
      {selectedRespondents?.length, plural, =1 {1 respondent} other {{{selectedRespondents?.length}} respondents}}
      selected
    </div>
    <div class="z-fx z-fx-gap-8 buttons">
      <button
        mat-raised-button
        class="zef-combo-button"
        color="secondary"
        [inlineDialogTriggerFor]="deleteDialog"
        [disabled]="!(Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights))"
      >
        <mat-icon class="icon close">action_delete</mat-icon>
        <strong class="zef-text-p2" i18n>Delete...</strong>
      </button>
      <button mat-icon-button color="secondary" (click)="removeRespondentSelections()">
        <mat-icon class="icon close">close</mat-icon>
      </button>
    </div>

    <zef-inline-dialog #deleteDialog="inlineDialog" [loading]="deletionInProgress" (confirm)="onDeleteRespondents()">
      <ng-container *dialogTitle i18n>Confirm Delete?</ng-container>

      <div *dialogContent class="z-fx-col z-fx-gap-16">
        <span class="zef-text-p1" i18n>
          This will irreversibly delete the
          <strong *ngIf="selectedCount > 1">{{ selectedCount }}</strong>
          selected {selectedCount, plural, =1 {respondent} other {respondents}}
        </span>

        <div class="z-fx-col z-fx-gap-8">
          <span class="zef-text-p2 zef-color-alert" i18n>Reconfirm number of Respondents</span>
          <mat-form-field>
            <input #count matInput placeholder="Number..." i18n-placeholder autocomplete="off" />
          </mat-form-field>
        </div>

        <button
          mat-raised-button
          class="zef-stretch-button"
          color="alert"
          [disabled]="selectedCount !== +count.value"
          (click)="deleteDialog.onConfirmClick()"
        >
          <span i18n>Permanently delete {selectedCount, plural, =1 {respondent} other {respondents}}</span>
        </button>
      </div>
    </zef-inline-dialog>
  </div>

  <ng-container *ngIf="availableHeight > 0">
    <div class="table zef-scroll" [style.height.px]="availableHeight" cdkVirtualScrollingElement>
      <ng-container *ngIf="!vsHide && columns.length > 1">
        <ng-container *ngIf="sortKey$ | async as sortkey">
          <ng-container *ngIf="sortDirection$ | async as sortdir">
            <div class="z-fx row header-row">
              <div
                *ngFor="let column of columns; let i = index"
                class="z-fx-col z-fx-end-start z-fx-gap-8"
                [class.header-cell]="details[column]?.key !== 'select'"
                [class.select]="details[column]?.key === 'select'"
                [style.min-width]="columnWidths[i]"
                [style.max-width]="columnWidths[i]"
                (click)="onSortColumn(details[column]?.key)"
              >
                <div class="z-fx z-fx-start-start header-cell-container">
                  <div class="z-fx z-fx-start-center zef-text-break sort-title">
                    {{ getHeaderText(column) }}
                  </div>
                  <mat-icon
                    *ngIf="sortkey === details[column]?.key"
                    [@rotate]="sortdir !== 'asc'"
                    class="zef-color-primary"
                  >
                    sort_up
                  </mat-icon>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <cdk-virtual-scroll-viewport *ngIf="!vsHide" [style.height.px]="availableHeight" autosize>
        <div
          *cdkVirtualFor="let row of chartTable$ | async; trackBy: trackByRow"
          class="z-fx z-fx-sc row"
          [style.cursor]="isNotFreezed() ? 'pointer' : 'default'"
        >
          <ng-container *ngFor="let column of columns; let i = index">
            <div
              *ngIf="details[column]?.key === 'select'"
              class="select z-fx-col"
              [style.min-width]="columnWidths[i]"
              [style.max-width]="columnWidths[i]"
            >
              <mat-checkbox
                color="primary"
                [checked]="isRowSelected(row)"
                (change)="toggleSelected(row, $event.checked)"
              ></mat-checkbox>
            </div>
            <div
              *ngIf="columns.length > 1 && toggledRow !== row[0].value && details[column]?.key !== 'select'"
              class="z-fx-col cell"
              [style.min-width]="columnWidths[i]"
              [style.max-width]="columnWidths[i]"
            >
              <div class="text z-fx-auto">
                <span (click)="filterData(row[column + 1]?.value, column)">
                  {{ row[column + 1]?.text | shorten : shortenNum : '...' }}
                </span>
                <span *ngIf="details[column]?.originalType === 'file-upload' && row[column + 1]?.text">
                  <mat-icon class="download" (click)="downloadFile(row, details[column])">download</mat-icon>
                </span>
              </div>
              <div
                *ngIf="row[column + 1]?.text?.length > shortenNum"
                class="show-more-toggle z-fx-none"
                i18n
                (click)="toggleRow(row[0].value)"
              >
                Show more...
              </div>
            </div>
            <div
              *ngIf="(columns.length === 1 || toggledRow === row[0].value) && details[column]?.key !== 'select'"
              class="z-fx-col cell"
              [style.min-width]="columnWidths[i]"
              [style.max-width]="columnWidths[i]"
            >
              <div class="text z-fx-auto">
                <span class="unshortened" (click)="filterData(row[column + 1]?.value, column)">
                  {{ row[column + 1]?.text }}
                </span>
                <span *ngIf="details[column]?.originalType === 'file-upload'">
                  <mat-icon class="download" (click)="downloadFile(row, details[column])">download</mat-icon>
                </span>
              </div>
              <div
                *ngIf="columns.length > 1 && row[column + 1]?.text?.length > shortenNum"
                class="show-more-toggle"
                i18n
                (click)="toggleRow(row[0].value)"
              >
                Show less...
              </div>
            </div>
          </ng-container>
        </div>
      </cdk-virtual-scroll-viewport>
      <div *ngIf="!anonymityTreshold && (chartTable$ | async).length === 0" class="z-fx-col nothing">
        <div class="z-fx-col z-fx-auto z-fx-start-center">
          <div class="z-fx-col z-fx-end-center image">
            <img src="assets/decos/art-nothing.png" height="48px" width="48px" class="art-nothing" />
          </div>
          <div class="description" i18n>Looks like there is no data to show.</div>
        </div>
      </div>
      <div *ngIf="isUnderAnonymityTreshold()" class="z-fx-col z-fx-auto anonymous">
        <div class="z-fx z-fx-center-center answerers">
          <mat-icon class="respondent-icon">contact</mat-icon>
          <span class="number" i18n>under {{ anonymityTreshold }}</span>
        </div>
        <div class="z-fx-col z-fx-auto z-fx-start-center z-fx-gap-16">
          <div class="z-fx-col z-fx-end-center image">
            <mat-icon class="zef-icon-larger">anonymous_on</mat-icon>
          </div>
          <div class="description zef-text-center">
            <ng-container i18n>
              Charts are hidden if there are less than {{ anonymityTreshold }} respondents in Anonymous survey.
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

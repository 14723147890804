/**
 * Slider usage intro animations.
 *
 * @stable
 */

import { animate, group, style, transition, trigger, query } from '@angular/animations';

const trans = 300;
const bigTrans = 500;

const timing = `ease-in-out`;

const pop = [
  animate(`${trans}ms ${timing}`, style({ opacity: 1 })),
  animate(`${trans}ms ${timing}`, style({ opacity: 0 })),
];

const bounce = [
  animate(`1s ${timing}`, style({ transform: `translate(-50%, -50%) scale(1.25)` })),
  animate(`1s ${timing}`, style({ transform: `translate(-50%, -50%) scale(1)` })),
];

export const sliderIntro = [
  trigger('introAxes', [
    transition(':enter', [
      query(`.z-thumb-arrow-side`, animate(`${trans}ms ${timing}`, style({ opacity: 0.3 })), { delay: bigTrans }),
      group([
        query(
          `.z-thumb-arrow-second`,
          animate(`${bigTrans}ms ${timing}`, style({ right: '55%', transform: 'translateX(100%) scale(1.25, 1.5)' })),
        ),
        query(
          `.z-thumb-arrow-third`,
          animate(`${bigTrans}ms ${timing}`, style({ right: '90%', transform: 'translateX(100%) scale(1.5, 2)' })),
        ),
      ]),
      group([
        query(`.z-thumb-arrow-first`, pop),
        query(`.z-thumb-arrow-second`, pop, { delay: `${trans}ms` }),
        query(`.z-thumb-arrow-third`, pop, { delay: `${trans * 2}ms` }),
      ]),
    ]),
  ]),
  trigger(`introThumb`, [transition(`:enter`, [...bounce, ...bounce, ...bounce])]),
];

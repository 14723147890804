<div *ngIf="questionData?.$key && status$ | async as status" class="z-file-upload">
  <p
    *ngIf="questionData.fileUpload?.description"
    class="z-texts-paragraph"
    [innerHTML]="
      questionData.fileUpload?.description || '' | translateText : 'fileUploadDescription' : questionData | formatText
    "
    [style.color]="(ss.colors | async).text80"
  ></p>

  <div
    class="z-file-upload-area"
    [class.z-solid]="status !== uploadStatus.Empty"
    [style.border-color]="(ss.colors | async)?.primary"
    [ngStyle]="areaStyle$ | async"
    (dragover)="onDragOver()"
    (dragleave)="onDragLeave()"
    (dragexit)="onDragExit()"
  >
    <ng-container *ngIf="status === uploadStatus.Empty">
      <mat-icon class="z-file-upload-icon" fontSet="zefplayer">attachment</mat-icon>

      <div class="z-texts-default-bold z-file-upload-label">
        {{ 'Drag here, or Choose file' | translateText : 'fileUploadDrag' }}
      </div>

      <div class="z-texts-default">{{ 'Max' | translateText : 'fileUploadMax' }}: {{ getMaxSize() }}MB</div>
    </ng-container>

    <input
      class="z-file-upload-input"
      type="file"
      autocomplete="off"
      (change)="onFileChange($event)"
      [accept]="questionData.fileUpload?.allowedFormats || ''"
      #input
    />

    <ng-container *ngIf="status !== uploadStatus.Empty">
      <mat-icon *ngIf="status === uploadStatus.Error" class="z-file-upload-error" fontSet="zefplayer">
        bullet_alert
      </mat-icon>

      <mat-icon
        *ngIf="status === uploadStatus.Complete"
        class="z-file-upload-complete"
        fontSet="zefplayer"
        [style.color]="(ss.colors | async)?.primaryParity"
      >
        bullet_check
      </mat-icon>

      <div *ngIf="status === uploadStatus.Loading" class="z-file-upload-progress">
        <svg
          class="progress-ring"
          [attr.height]="progressRadius * 2 + progressWidth * 2"
          [attr.width]="progressRadius * 2 + progressWidth * 2"
        >
          <circle
            class="z-file-upload-progress-mask"
            fill="transparent"
            shape-rendering="geometricPrecision"
            [attr.stroke-width]="progressWidth / 2"
            [attr.stroke]="(ss.colors | async)?.text25"
            [attr.r]="progressRadius"
            [attr.cx]="progressRadius + progressWidth"
            [attr.cy]="progressRadius + progressWidth"
          />
          <circle
            class="z-file-upload-progress-bar"
            [class.complete]="(uploadProgress$ | async) === 1"
            fill="transparent"
            shape-rendering="geometricPrecision"
            stroke-linecap="round"
            [attr.stroke-width]="progressWidth"
            [attr.stroke-dasharray]="progressCircumference + ' ' + progressCircumference"
            [attr.stroke-dashoffset]="dashOffset$ | async"
            [attr.stroke]="(ss.colors | async)?.primary"
            [attr.r]="progressRadius"
            [attr.cx]="progressRadius + progressWidth"
            [attr.cy]="progressRadius + progressWidth"
          />
        </svg>
      </div>

      <div
        class="z-texts-answer z-file-upload-name"
        [style.color]="
          status === uploadStatus.Complete ? (ss.colors | async)?.primaryParity : (ss.colors | async)?.primary
        "
      >
        {{ fileName$ | async }}
      </div>

      <mat-icon
        class="z-file-upload-close"
        fontSet="zefplayer"
        [style.color]="
          status === uploadStatus.Complete ? (ss.colors | async)?.primaryMood : (ss.colors | async)?.text80
        "
        (click)="onClickClose()"
      >
        close
      </mat-icon>
    </ng-container>
  </div>

  <div *ngIf="status === uploadStatus.Error && errorStatus$ | async as error" class="z-error-text">
    <ng-container *ngIf="error === uploadError.Extension && parseAllowedFormats() as formats">
      {{ 'Please upload ' + formats | translateText : 'fileUploadExtension' : [formats] }}
    </ng-container>

    <ng-container *ngIf="error === uploadError.MaxSize">
      {{ 'Max ' + getMaxSize() + 'MB allowed' | translateText : 'fileUploadMaxAllowed' : [getMaxSize()] }}
    </ng-container>

    <ng-container *ngIf="error === uploadError.UploadError">
      {{ 'Something went wrong, please try again' | translateText : 'fileUploadError' }}
    </ng-container>
  </div>
</div>

<ng-container
  *ngIf="{
    value: value$ | async,
    labels: (ss.optionLabels$ | async)
  } as data"
>
  <div
    class="select z-fx-inline"
    [ngClass]="'select-' + type + ' select-' + size"
    [class.zef-text-p2]="!isInline || type === Types.InlineP2"
    [class.zef-text-h3]="type === Types.InlineH3"
    [class.space]="space"
    [class.disabled]="disabled"
    [class.read-only]="readOnly"
    [class.select-small]="valuePrefixOnly"
    [tabindex]="disabled ? -1 : 0"
    (click)="openSelect()"
  >
    <ng-container *ngIf="!ss?.selectLabel; else selectLabelTemplate">
      <ng-container *ngIf="!ss?.selectLabel">
        <ng-container *ngIf="data.value?.length > 1">
          <span>{{ data.value?.length + ' ' + selectedText }}</span>
        </ng-container>
        <ng-container *ngIf="data.value?.length === 1">
          <ng-container *ngIf="data.labels?.get(data.value?.[0]); else valueAsLabel">
            <ng-container [ngTemplateOutlet]="data.labels?.get(data.value?.[0])"></ng-container>
          </ng-container>
          <ng-template #valueAsLabel>
            <span>{{ data.value?.[0] }}</span>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="!data.value?.length">
          <span>{{ placeholder }}</span>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #selectLabelTemplate>
      <ng-container [ngTemplateOutlet]="ss.selectLabel"></ng-container>
    </ng-template>

    <span class="select-suffix">
      <mat-icon class="select-pointer z-fx-none" [@rotate]="isOpen">pointer_down</mat-icon>
    </span>
  </div>
</ng-container>

<div
  *cdkPortal
  class="select-list zef-card-z4-soft z-fx-col"
  [@dropdown]="dropdownState"
  cdkTrapFocus
  (@dropdown.done)="onDropdownAnimationDone($event)"
>
  <ng-container>
    <div *ngIf="canSearch || loading" class="select-search select-header z-fx-none">
      <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>

      <mat-form-field *ngIf="canSearch" class="zef-search-input">
        <mat-icon matPrefix>action_find</mat-icon>

        <input
          matInput
          [placeholder]="placeholderSearch"
          (input)="onSearchInput(searchInput.value)"
          #searchInput="matInput"
        />
      </mat-form-field>
    </div>

    <div class="z-fx-flex">
      <ng-scrollbar [autoHeightDisabled]="false">
        <div class="select-options">
          <ng-container
            *ngIf="multiSelectOptions?.template"
            [ngTemplateOutlet]="multiSelectOptions?.template"
            [ngTemplateOutletContext]="{ $implicit: (value$ | async) }"
          ></ng-container>

          <ng-content *ngIf="!multiSelectOptions?.template"></ng-content>
        </div>
      </ng-scrollbar>
    </div>
  </ng-container>
</div>

<ng-template #loadingIcon>
  <zef-spinner></zef-spinner>
</ng-template>

/**
 * State container for holding error status.
 *
 * @unstable
 */

import { Action, State, Store } from '@ngxs/store';

import { PrefsState } from '@shared/states/prefs.state';

import { ServerError, TeamRightsError, CloudFunctionsError } from '@shared/states/error.actions';

import { DialogControl } from '@shared/services/dialog-control.service';
import { Injectable } from '@angular/core';

export interface ErrorStateModel {
  cloudFunctions: boolean;
}

@Injectable()
@State<ErrorStateModel>({
  name: 'error',
  defaults: {
    cloudFunctions: false,
  },
})
export class ErrorState {
  constructor(readonly store: Store, readonly dc: DialogControl) {}

  @Action(ServerError)
  serverError({ error }: ServerError) {
    const message =
      error.error instanceof Error ? error.error.message : error.status || $localize`:@@zef-i18n-00015:Unknown Error`;

    this.dc.openErrorDialog($localize`:@@zef-i18n-00040:Server Error`, message.toString());
  }

  @Action(TeamRightsError)
  teamRightsError() {
    this.dc
      .openErrorDialog(
        $localize`:dialog title@@zef-i18n-00055:Access Denied`,
        $localize`:dialog content@@zef-i18n-00056:You don\'t have access to this organization.`,
        ['OK'],
      )
      .afterClosed()
      .subscribe((action: string | null) => {
        window.location.reload();
      });
  }

  @Action(CloudFunctionsError)
  cloudFunctionsError() {
    return this.dc
      .openErrorDialog(
        $localize`:dialog title@@zef-i18n-00057:Connection Error`,
        $localize`:dialog content@@zef-i18n-00058:Network error occured or our service is taking a nap.`,
        [$localize`Refresh`],
      )
      .afterClosed()
      .subscribe(() => {
        window.location.reload();
      });
  }
}

<div class="z-fx-col z-fx-auto summary2d-container" (elementResize)="updateChart()">
  <div
    *ngFor="let chart of chartData; let i = index"
    class="z-fx-auto summary2d-chart-container"
    [class.z-fx]="flexMode === 'row'"
    [class.z-fx-col]="flexMode === 'column'"
  >
    <ng-container *ngIf="chart?.children?.length; else nothing">
      <div
        class="z-fx z-fx-center-start summary2d-chart"
        [style.max-width]="flexMode === 'row' ? '70%' : '100%'"
        [style.max-height]="flexMode === 'column' ? '70%' : '100%'"
        summary2d
        [chartData]="chart"
        [size]="size"
        [highlight]="highlighted"
        [selectedItems]="selectedQuestions?.[chart.id]"
        [domain]="domain"
        [zvalues]="zvalues"
        [showAverages]="showAverages"
        [showZoomText]="showZoomText"
        [showTooltip]="flexMode === 'column'"
        [transitionDuration]="transitionDuration"
        [update]="update"
        [comparison]="comparison"
        [comparisonMode]="comparisonMode"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [showOnlyGroupAverages]="showGroupAverages?.[chart.id]"
        (hover)="hoverFromChart($event, chart.id)"
        (click)="selectQuestions(hoveredFromChart?.[chart.id], chart.id)"
      ></div>
      <div class="z-fx-col z-fx-auto z-fx-gap-8 scrollbar-container">
        <div
          [style.visibility]="questionCount?.[chart.id] < 2 ? 'hidden' : null"
          class="z-fx z-fx-end-center search-box"
        >
          <zef-search-expand
            placeholder="Search..."
            i18n-placeholder
            matTooltip="Search..."
            i18n-matTooltip
            matTooltipPosition="above"
            [matTooltipDisabled]="search.open$ | async"
            [value]="searchTerms[chart.id]"
            [style.visibility]="questionCount?.[chart.id] < 7 ? 'hidden' : null"
            (valueChange)="onSearchUpdate($event, chart.id)"
            #search="searchExpand"
          ></zef-search-expand>
          <div
            *ngIf="comparisonMode !== 'joined'"
            class="z-fx z-fx-center-center sorter"
            [class.search-open]="search.open$ | async"
            @expand
          >
            <button mat-icon-button [@rotate]="sortDirections[chart.id] !== 'asc'" (click)="onSortDir(chart.id)">
              <mat-icon class="zef-color-primary">sort_up</mat-icon>
            </button>

            <zef-select [value]="sortKeys[chart.id]" type="combo" (change)="onSortColumn($event, chart.id)">
              <zef-select-option value="survey">
                <mat-icon *selectPrefix color="ink">chart_summary_2d</mat-icon>
                <ng-container *selectOptionLabel i18n>Original</ng-container>
              </zef-select-option>
              <zef-select-option value="average">
                <mat-icon *selectPrefix color="ink">axis_x</mat-icon>
                <ng-container *selectOptionLabel>
                  <ng-container *ngIf="chart?.sliderDetails?.labels as labels">
                    {{ labels.axis ? labels.axis + ': ' : '' }}
                    {{ labels.min || '' }}
                    {{ labels.min && labels.max ? ' - ' : '' }}
                    {{ labels.max || '' }}
                  </ng-container>
                </ng-container>
              </zef-select-option>
              <zef-select-option value="averageY">
                <mat-icon *selectPrefix color="ink">axis_y</mat-icon>
                <ng-container *selectOptionLabel>
                  <ng-container *ngIf="chart?.sliderDetails?.labelsY as labelsY">
                    {{ labelsY.axis ? labelsY.axis + ': ' : '' }}
                    {{ labelsY.min || '' }}
                    {{ labelsY.min && labelsY.max ? ' - ' : '' }}
                    {{ labelsY.max || '' }}
                  </ng-container>
                </ng-container>
              </zef-select-option>
            </zef-select>
          </div>
        </div>
        <ng-scrollbar class="z-fx-col z-fx-flex">
          <div class="z-fx-col z-fx-auto summary2d-questions" [style.margin-top]="flexMode === 'column' ? 0 : null">
            <div
              *ngFor="let questions of chart.visibleQuestionList; trackBy: trackByKey; let qi = index"
              class="summary2d-question"
              [class.active]="isFound(hoveredFromChart?.[chart.id], questions[0]?.key)"
              [class.selected]="isFound(selectedQuestions?.[chart.id], questions[0]?.key)"
              [class.last-selected-item]="selectedQuestions?.length && selectedQuestions.length === qi - 1"
              [class.question-group]="questions[0]?.isGroupAverage"
              [class.question-in-group]="questions[0]?.questionGroup"
              (mouseenter)="highlightAdd(questions[0], chart.id)"
              (mouseleave)="highlightRemove()"
              (click)="selectQuestions(mapValues(questions, 'key'), chart.id, questions[0]?.isGroupAverage)"
            >
              <div *ngIf="questions[0]" class="summary2d-question-export-text">
                <ng-container *ngIf="isFound(selectedQuestions?.[chart.id], questions[0]?.key)">***</ng-container>
                {{ questions[0].number || questions[0].numberAlphabet }}. {{ questions[0].title }}
                <ng-container *ngIf="comparisonMode !== 'joined'">
                  <ng-container *ngFor="let statItem of questions">
                    <ng-container>
                      (x:
                      {{
                        zvalues
                          ? statItem.zAverage != null
                            ? statItem.zAverage.toFixed(1)
                            : '-'
                          : statItem.average != null
                          ? statItem.average.toFixed(1)
                          : '-'
                      }})
                    </ng-container>
                    <ng-container>
                      (y:
                      {{
                        zvalues
                          ? statItem.zAverageY != null
                            ? statItem.zAverageY.toFixed(1)
                            : '-'
                          : statItem.averageY != null
                          ? statItem.averageY.toFixed(1)
                          : '-'
                      }})
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngIf="questions[0] as question">
                <div class="z-fx question" [class.zef-color-ink-lightest]="hasOnlyAnonymousQuestions(questions)">
                  <div class="z-fx z-fx-auto z-fx-start-center question-text">
                    {{ question.number || question.numberAlphabet }}. {{ question.title }}
                  </div>
                  <div
                    *ngIf="question.isGroupAverage"
                    class="group-mode-toggle"
                    (click)="toggleGroupAverages(chart.id); $event.stopPropagation()"
                  >
                    <mat-icon *ngIf="!showGroupAverages?.[chart.id]" class="icon" color="ink">
                      pointer_collapse
                    </mat-icon>
                    <mat-icon *ngIf="showGroupAverages?.[chart.id]" class="icon" color="ink">pointer_expand</mat-icon>
                  </div>
                </div>

                <div
                  *ngFor="let statItem of questions"
                  class="z-fx z-fx-start-center z-fx-wrap stats"
                  [class.show-all]="sortKeys[chart.id] !== 'survey' && comparisonMode !== 'joined'"
                  [class.anonymity]="statItem.isUnderAnonymityTreshold"
                  [style.color]="colorScale(statItem.index)"
                >
                  <div class="z-fx z-fx-center-center stat-item" [class.selected]="sortKeys[chart.id] === 'n'">
                    <span class="icon">contact</span>
                    <span *ngIf="!statItem.isUnderAnonymityTreshold; else underTreshold">{{ statItem.n }}</span>
                    <ng-template #underTreshold>
                      <span i18n class="number">under {{ anonymityTreshold }}</span>
                    </ng-template>
                  </div>
                  <ng-container *ngIf="!statItem.isUnderAnonymityTreshold; else anonymityIcon">
                    <div class="z-fx z-fx-center-center stat-item" [class.selected]="sortKeys[chart.id] === 'average'">
                      <span class="icon">axis_x</span>
                      {{
                        zvalues
                          ? statItem.zAverage != null
                            ? statItem.zAverage.toFixed(1)
                            : '-'
                          : statItem.average != null
                          ? statItem.average.toFixed(1)
                          : '-'
                      }}
                    </div>
                    <div class="z-fx z-fx-center-center stat-item" [class.selected]="sortKeys[chart.id] === 'averageY'">
                      <span class="icon">axis_y</span>
                      {{
                        zvalues
                          ? statItem.zAverageY != null
                            ? statItem.zAverageY.toFixed(1)
                            : '-'
                          : statItem.averageY != null
                          ? statItem.averageY.toFixed(1)
                          : '-'
                      }}
                    </div>
                  </ng-container>
                  <ng-template #anonymityIcon>
                    <div class="z-fx z-fx-center-center stat-item" [class.selected]="sortKeys[chart.id] === 'average'">
                      <span class="icon anonymity zef-color-ink">anonymous_on</span>
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-scrollbar>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #nothing>
  <div class="z-fx-col z-fx-auto z-fx-center-center nothing">
    <div class="z-fx-col z-fx-end-center image">
      <img src="assets/decos/art-nothing.png" height="64px" width="64px" class="art-nothing" />
    </div>
    <div class="description zef-text-center" i18n>Looks like there is no data to show.</div>
  </div>
</ng-template>

/**
 * Report app offline view module.
 *
 * @stable
 */

import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { BasicModule } from '@shared/modules/basic.module';

import { OfflineView } from '@report/+offline/offline.component';

@NgModule({
  bootstrap: [],
  declarations: [OfflineView],
  exports: [],
  imports: [BasicModule, MatIconModule],
  providers: [],
})
export class OfflineModule {}

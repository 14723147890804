/**
 * Helpers for the social sharing.
 *
 * @stable
 */

import { getOS } from '@player/shared/models/player.model';

export function getShareLink(service: string, title: string, desc: string, url: string): string {
  const os = getOS();

  url = encodeURI(url);
  desc = encodeURI(desc);
  title = encodeURI(title);

  const lineBreak = encodeURI(`\r\n`);

  const descAndUrl = desc ? `${desc}${lineBreak}${url}` : url;

  let sharingUrl = '';
  switch (service) {
    case 'facebook':
      sharingUrl = `https://www.facebook.com/sharer.php?u=${url}`;
      break;

    case 'google':
      sharingUrl = `https://plus.google.com/share?url=${url}`;
      break;

    case 'twitter':
      sharingUrl = `https://twitter.com/intent/tweet?url=${url}&text=${desc}`;
      break;

    case 'whatsapp':
      sharingUrl =
        os === 'desktop' ? `https://web.whatsapp.com/send?text=${descAndUrl}` : `whatsapp://send?text=${descAndUrl}`;
      break;

    case 'linkedin':
      sharingUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${desc}`;
      break;

    case 'email':
      sharingUrl = `mailto:?subject=${title}&body=${descAndUrl}`;
      break;
  }

  return sharingUrl;
}

/**
 * Menu component for the website.
 *
 * @stable
 */

import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { Select } from '@ngxs/store';

import { AccountState } from '@shared/states/account.state';

import { IdentityData } from '@shared/models/prefs.model';
import { TeamData, InviteData } from '@shared/models/account.model';

import { AccountManager } from '@shared/services/account-manager.service';
import { InvitesManager } from '@shared/services/invites-manager.service';
import { AuthState } from '@shared/states/auth.state';

@Component({
  selector: 'zef-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenu {
  @Select(AccountState.invites) invites$: Observable<InviteData[]>;

  @Select(AccountState.team) team$: Observable<TeamData>;

  @Select(AccountState.identity) identity$: Observable<IdentityData>;

  @Select(AuthState.isAnonymous) isAnonymous$: Observable<boolean>;

  constructor(readonly cdRef: ChangeDetectorRef, readonly am: AccountManager, readonly im: InvitesManager) {}
}

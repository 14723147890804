import { Selector } from '@ngxs/store';
import { Rights } from '@shared/enums/rights.enum';
import { SMSPackage } from '@shared/models/billing.model';
import { SurveyData } from '@shared/models/survey.model';
import { AccountStateModel, ACCOUNT_STATE_TOKEN } from '@shared/states/account.models';
import { AccountState } from '@shared/states/account.state';
import { AuthState, AuthStateModel } from '@shared/states/auth.state';
import { BillingState, BillingStateModel } from '@shared/states/billing.state';
import { CartState, CartStateModel } from '@shared/states/cart.state';
import { ContactsStateModel, CONTACTS_STATE_TOKEN } from '@shared/states/contacts.models';
import { PrefsStateModel, PREFS_STATE_TOKEN } from '@shared/states/prefs.state';
import { SurveyState, SurveyStateModel } from '@shared/states/survey.state';

// TODO: Maybe use better name ?
/**
 * Use this to avoid circular dependency issues;
 * when data from one state is needed in another state
 * or to collect data from multiple states
 */
export class Selectors {
  @Selector([AccountState])
  static teamName({ team }: AccountStateModel): string {
    const placeholder = $localize`:@@zef-i18n-00051:Unnamed organization`;

    return team && team.name ? team.name || placeholder : placeholder;
  }

  @Selector([BillingState])
  static smsPackages({ smsPackages }: BillingStateModel): SMSPackage[] {
    return smsPackages;
  }

  @Selector([BillingState, CartState])
  static getVat({ clientInfo }: BillingStateModel, { taxPercentage }: CartStateModel): number {
    return taxPercentage != null ? taxPercentage : clientInfo.taxPercentage || 0;
  }

  // @ts-ignore
  @Selector([ACCOUNT_STATE_TOKEN, PREFS_STATE_TOKEN, CONTACTS_STATE_TOKEN])
  static propertyData({ team }: AccountStateModel, { properties }: PrefsStateModel, { columns }: ContactsStateModel) {
    return { team, properties, columns };
  }

  @Selector([AccountState])
  static teamKey({ team, user }: AccountStateModel) {
    return team?.$key || user?.team || null;
  }

  @Selector([AuthState])
  static authToken({ authToken }: AuthStateModel) {
    return authToken;
  }

  @Selector([AccountState, SurveyState])
  static userSurveysData(as: AccountStateModel, ss: SurveyStateModel): SurveyData[] {
    const teamKey = as.team.$key;
    const userRole = (as.user && as.user.teams && as.user.teams[teamKey]) || 0;
    const surveyRights = AccountState.surveyRights(as);

    return Object.values(ss || {})
      .filter((model) => !!model.survey)
      .filter((model) => {
        if (Rights.hasRights(Rights.ADMIN, userRole)) {
          return true;
        } else {
          return !!surveyRights[model?.survey?.$key];
        }
      })
      .sort(SurveyState.sortByModified)
      .map((model) => model.survey);
  }
}

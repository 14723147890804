/**
 * Invite dialog component with emails validation.
 *
 * @unstable
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Rights } from '@shared/enums/rights.enum';
import { TeamData } from '@shared/models/account.model';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { IdentityData } from '@shared/models/prefs.model';
import { debounceUntil } from '@shared/operators/debounce-until.operator';
import { InvitesManager } from '@shared/services/invites-manager.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { PrefsManager } from '@shared/services/prefs-manager.service';
import { RightsManager } from '@shared/services/rights-manager.service';
import { isEmail } from 'class-validator';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { PrefsState } from '@shared/states/prefs.state';
import { AccountState } from '@shared/states/account.state';
import { teamLogoPlaceholder } from '@shared/utilities/assets.utilities';

type InvitePeopleDialogData = {
  isAdmin?: boolean;
  userData: IdentityData;
  teamData: TeamData;
};

@Component({
  selector: 'invite-people',
  templateUrl: './invite-people.dialog.html',
  styleUrls: ['./invite-people.dialog.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitePeople implements OnInit {
  readonly subjects = HelpSubject;

  private isAdmin: boolean = false;

  public validatedEmails: string[] = [];

  public teamData: TeamData | null = null;
  public teamLogo: string;

  public parseEmails: Subject<string> = new Subject();

  readonly loading$ = new BehaviorSubject<boolean>(false);

  emails: string;
  message: string;
  locale: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: InvitePeopleDialogData,
    readonly dialogRef: MatDialogRef<InvitePeople>,
    readonly cdRef: ChangeDetectorRef,
    readonly hooks: LifecycleHooks,
    readonly pm: PrefsManager,
    readonly rm: RightsManager,
    readonly im: InvitesManager,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.data.isAdmin;
    this.teamData = this.data.teamData;
    this.teamLogo = this.teamData?.logo || teamLogoPlaceholder(0);

    const lang = this.store.selectSnapshot(PrefsState.language);
    this.changeLocale(lang !== 'fi' ? 'en' : 'fi');

    this.parseEmails.pipe(debounceUntil(this.hooks.destroy, 250)).subscribe((emails: string) => {
      this.validatedEmails = emails
        .split(/[ ,:;|\r\n]+/)
        .map((email: string) => email.toLowerCase())
        .filter((email: string) => isEmail(email));

      this.cdRef.markForCheck();
    });
  }

  changeLocale(locale: string) {
    this.locale = locale;
    this.cdRef.markForCheck();
  }

  public onSendInvites(): void {
    const userIds = Object.keys(this.teamData.users || {})
      .filter((userKey: string) => this.teamData.users && !!this.teamData.users[userKey])
      .map((userKey: string) => this.pm.loadIdentity(userKey, this.teamData['$key']).pipe(first()));

    const idsList = userIds.length ? forkJoin(userIds) : of([]);

    idsList.pipe(first()).subscribe((ids: IdentityData[]) => {
      const teamEmails: string[] = ids.filter((id: IdentityData) => id).map((id: IdentityData) => id.email);

      this.validatedEmails = this.validatedEmails.filter((email: string) => email && !teamEmails.includes(email));

      if (this.validatedEmails.length) {
        this.loading$.next(true);

        const teamKey = this.isAdmin ? this.teamData['$key'] : null;

        this.im.sendInvites(this.validatedEmails, this.message, this.locale, teamKey).subscribe((response: any) => {
          response.body.forEach((status: any) => {
            if (status.inviteSent) {
              this.rm.setTeamRights(status.email.replace(/\./g, ','), Rights.EDIT, this.teamData['$key']);
            }
          });

          this.loading$.next(false);
          this.dialogRef.close(this.validatedEmails);
        });
      } else {
        this.loading$.next(false);
      }
    });
  }
}

/**
 * Team card with invitation support.
 *
 * @unstable
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { FadeInOut } from '@shared/animations/fade-in-out.anim';
import { InviteData } from '@shared/models/account.model';
import { AnalyticsTracks } from '@shared/models/analytics.model';
import { AcceptTeamInvite, RejectTeamInvite, SwitchTeam } from '@shared/states/account.actions';

@Component({
  selector: 'invite-item',
  templateUrl: './invite-item.component.html',
  styleUrls: ['./invite-item.component.scss'],
  animations: [FadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteItem {
  readonly AnalyticsTracks = AnalyticsTracks;

  public ignored: boolean | null = false;
  @Input() processed: boolean = false;
  @Input() invite: InviteData | null = null;
  @Output() accepted: EventEmitter<InviteData> = new EventEmitter();
  @Output() rejected: EventEmitter<InviteData> = new EventEmitter();

  constructor(private store: Store) {}

  public switchTeam(): void {
    const { teamKey } = this.invite;
    this.store.dispatch(new SwitchTeam(teamKey));
  }

  public acceptInvite(): void {
    const { teamKey, $key } = this.invite;
    this.processed = true;
    this.accepted.next(this.invite);
    this.store.dispatch(new AcceptTeamInvite(teamKey, $key));
  }

  public ignoreInvite(): void {
    const { $key } = this.invite;
    this.processed = true;
    this.rejected.next(this.invite);
    this.store.dispatch(new RejectTeamInvite($key));
  }
}

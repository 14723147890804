<div class="z-fx-col placeholder">
  <mat-card
    class="z-fx-auto detail-card"
    [class.transparent]="!selectedReportData || !selectedReportData['$key'] || sampleData || isAuthorUnsavedChanges"
  >
    <mat-card-content class="z-fx z-fx-between-center" [style.display]="'flex'">
      <div class="z-fx z-fx-start-center report-title">
        <mat-icon class="report-icon">
          {{
            selectedReportData && selectedReportData['$key'] && !isAuthorUnsavedChanges
              ? 'report'
              : !sampleData
              ? 'results'
              : 'intelligence'
          }}
        </mat-icon>
        <span *ngIf="!selectedReportData || !selectedReportData['$key'] || isAuthorUnsavedChanges">
          <ng-container *ngIf="sampleData" i18n>Sample Results</ng-container>
          <ng-container *ngIf="!sampleData" i18n>All Results</ng-container>
        </span>
        <span
          *ngIf="selectedReportData && selectedReportData['$key'] && !reportNameEditModeOn && !isAuthorUnsavedChanges"
          (click)="
            reportNameEditModeOn =
              Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)
          "
        >
          <ng-container *ngIf="selectedReportData.title">{{ reportName }}</ng-container>
          <ng-container *ngIf="!selectedReportData.title" i18n>Untitled report</ng-container>
        </span>
        <input
          *ngIf="reportNameEditModeOn"
          matInput
          [autoFocus]="true"
          placeholder="Report name..."
          i18n-placeholder="placeholder|Report name"
          [value]="reportName"
          (input)="reportName = $event.target.value"
          (blur)="updateReportName()"
          (keyup.enter)="updateReportName()"
        />

        <button *ngIf="!sampleData" mat-icon-button (click)="reportPanel.openPanel()">
          <mat-icon>pointer_down</mat-icon>
        </button>

        <div
          *ngIf="
            (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
            !sampleData &&
            (!selectedReportData || !selectedReportData['$key'] || isAuthorUnsavedChanges) &&
            gridAvailable
          "
          class="z-fx-start-center save-status"
        >
          <span *ngIf="!newReportSavingOnProgress" class="action" (click)="saveAsReport()" i18n>Save as report</span>
          <zef-help-icon [subject]="subjects.AnalyzeSaveReport"></zef-help-icon>
          <span *ngIf="newReportSavingOnProgress" i18n>Saving...</span>
        </div>
        <div
          *ngIf="
            (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
            !sampleData &&
            selectedReportData &&
            selectedReportData['$key'] &&
            !isAuthorUnsavedChanges
          "
          class="z-fx-start-center save-status"
        >
          <ng-container *ngIf="rs.saveStatus | async as status" [ngSwitch]="status">
            <span *ngSwitchCase="'save'" class="action" (click)="saveReport()" i18n>Save</span>
            <span *ngSwitchCase="'saving'" i18n>Saving...</span>
            <span *ngSwitchCase="'saved'" i18n>Saved</span>
          </ng-container>
        </div>
      </div>

      <div class="z-fx z-fx-gap-4 actions">
        <div *ngIf="(cf.liveStatus | async) && !sampleData" class="z-fx z-fx-auto z-fx-start-center live">
          <mat-icon class="zef-icon-small">live</mat-icon>
          <span class="zef-text-p2" i18n>Live</span>
        </div>
        <button
          *ngIf="
            (canShare$ | async) &&
            (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
            !sampleData
          "
          mat-raised-button
          color="primary"
          (click)="openShareDialog()"
          class="action-share"
        >
          <mat-icon>share</mat-icon>
          <span class="zef-text-p2" i18n="button|share report">Share</span>
        </button>

        <button
          *ngIf="
            (canShare$ | async) === false &&
            (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) &&
            !sampleData
          "
          mat-raised-button
          (click)="openPromoDialog()"
          class="action-share locked"
        >
          <mat-icon>lovelock</mat-icon>
          <span class="zef-text-p2" i18n="button|share report">Share</span>
        </button>

        <button
          *ngIf="
            !(Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) || sampleData
          "
          mat-raised-button
          color="secondary"
          i18n
          (click)="openExportDialog()"
          class="action-export"
        >
          Export
        </button>

        <button mat-icon-button color="secondary" [matMenuTriggerFor]="actionMenu">
          <mat-icon>more</mat-icon>
        </button>

        <mat-menu #actionMenu class="zef-menu-combo" xPosition="before">
          <div
            mat-menu-item
            [disabled]="anonymityTreshold"
            (click)="!anonymityTreshold ? openRawDataViewDialog() : ''"
            matTooltip="Anonymous survey"
            i18n-matTooltip
            matTooltipClass="zef-tooltip-ink"
            matTooltipPosition="above"
            [matTooltipDisabled]="!anonymityTreshold"
          >
            <mat-icon>{{ !anonymityTreshold ? 'chart_table' : 'anonymous_on' }}</mat-icon>
            <span i18n>View all data in table</span>
          </div>
          <div mat-menu-item (click)="openExportDialog()">
            <mat-icon>download</mat-icon>
            <span i18n>Export</span>
          </div>
          <div *ngIf="canMerge$ | async" mat-menu-item (click)="reportMergePanel.openPanel()">
            <mat-icon>merge_branch</mat-icon>
            <span i18n>Compare Surveys</span>
          </div>
          <div *ngIf="(canMerge$ | async) === false" mat-menu-item (click)="openPromoMergeDialog()">
            <mat-icon>lovelock</mat-icon>
            <span i18n>Compare Surveys</span>
          </div>
        </mat-menu>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<zef-panel #reportPanel [width]="'350px'" [scrim]="true">
  <div *ngIf="reportPanel?.isOpen()" class="z-fx-col z-fx-auto">
    <mat-card class="top-bar">
      <div class="z-fx z-fx-start-center">
        <mat-icon class="back-icon" (click)="reportPanel.closePanel()">back</mat-icon>
        <p class="reports-text" i18n>Reports</p>
      </div>
    </mat-card>
    <div class="z-fx-col z-fx-center-start search-box">
      <zef-searchbox [value]="searchTerm" (input)="searchUpdate.next($event)">
        <span i18n>Find...</span>
      </zef-searchbox>
    </div>

    <ng-scrollbar class="z-fx-col z-fx-flex">
      <div class="z-fx-col z-fx-auto content">
        <mat-card class="all-reports-card" [class.selected]="!selectedReportData || !selectedReportData['$key']">
          <mat-card-content
            [routerLink]="'/surveys/' + selectedSurvey + '/analyze/'"
            (click)="reportPanel.closePanel()"
          >
            <div *ngIf="surveyUsage | async as usage" class="z-fx z-fx-start-center z-fx-gap-4 numbers">
              <mat-icon class="report-icon">results</mat-icon>
              <mat-icon class="zef-icon-small">contact</mat-icon>
              <span i18n>
                {{ usage.collectedAnswers }} responses
                <ng-container *ngIf="usage.lockedAnswers">( + {{ usage.lockedAnswers }} locked )</ng-container>
              </span>
            </div>
            <p class="report-name" i18n>All Results</p>
          </mat-card-content>
        </mat-card>

        <div *ngIf="reportsList.length > 0">
          <div *ngFor="let item of reportsList; let i = index" class="z-fx">
            <mat-icon fontSet="md" class="subdirectory-icon">subdirectory_arrow_right</mat-icon>
            <mat-card
              class="single-report-card z-fx-auto"
              [class.selected]="selectedReportData && selectedReportData['$key'] === item.$key"
            >
              <div *ngIf="item.savingOnProgress" class="z-fx z-fx-center-center loading-overlay" i18n>
                Saving on progress...
              </div>
              <mat-icon
                *ngIf="item.publicKey && item.online"
                class="public-icon"
                [matTooltip]="iconTooltip"
                [zefCopyToClipboard]="shareLinkBase + item.publicKey"
                copyToClipboardNotice="Share link copied"
                i18n-copyToClipboardNotice
              >
                player_link
              </mat-icon>
              <div
                *ngIf="Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)"
                class="action-menu"
                [class.public]="item.publicKey && item.online"
                [mat-menu-trigger-for]="actionMenu"
              >
                <mat-icon fontSet="md" class="more-button">more_vert</mat-icon>

                <mat-menu #actionMenu class="menu-dropdown" x-position="before" y-position="below" (close)="close()">
                  <button mat-menu-item (click)="duplicateReport(item)">
                    <mat-icon>action_duplicate</mat-icon>
                    <span i18n>Duplicate report</span>
                  </button>

                  <button mat-menu-item (click)="deleteReport(item.$key, item.title, reportPanel)">
                    <mat-icon>action_delete</mat-icon>
                    <span i18n>Delete report</span>
                  </button>
                </mat-menu>
              </div>

              <mat-card-content (click)="openReport(item['$key'], reportPanel)">
                <div *ngIf="surveyUsage | async as usage" class="z-fx z-fx-start-center numbers">
                  <mat-icon class="report-icon">report</mat-icon>
                  <mat-icon class="person-icon">contact</mat-icon>
                  <span i18n>{{ item.respondents }} responses</span>
                </div>
                <p class="report-name">
                  {{ item.title }}
                  <span *ngIf="item.savingError" class="error-text" i18n>(Error)</span>
                </p>
                <p class="creator-name">{{ item.ownerData?.name }}</p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</zef-panel>

<zef-panel #reportMergePanel="zefPanel" [width]="'800px'" [scrim]="true" [align]="'right'">
  <report-merge-surveys
    *ngIf="reportMergePanel?.isOpen()"
    class="z-fx-col z-fx-auto"
    (closePanel)="reportMergePanel.closePanel()"
  ></report-merge-surveys>
</zef-panel>

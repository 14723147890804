import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Select, Store } from '@ngxs/store';

import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { TemplateModel } from '@shared/models/survey.model';
import { TemplatesState } from '@shared/states/templates.state';
import { LoadTemplates } from '@shared/states/templates.actions';

@Component({
  selector: 'zef-survey-view',
  templateUrl: './survey-view.component.html',
  styleUrls: ['./survey-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyView implements OnInit {
  readonly subjects = HelpSubject;

  @Input('survey')
  surveyKey?: string;

  @Select(TemplatesState.allTemplates)
  readonly templates_!: Observable<TemplateModel[]>;

  readonly templates$ = this.templates_.pipe(filter((ts) => !!ts.length));

  constructor(readonly store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadTemplates());
  }
}

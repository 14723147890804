/*
 * Parse email string to First name and Last name
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseEmail',
})
export class ParseEmail implements PipeTransform {
  public readonly separators: string[] = ['-', '.', '_', '0'];

  public readonly separatorRegexp: RegExp = new RegExp(`[\\${this.separators.join('')}]`);

  constructor() {}

  public transform(email: string | null): string[] {
    if (!email) {
      return [];
    }

    const names: string[] = email
      .split('@')[0]
      .split(this.separatorRegexp)
      .filter((s: string) => !!s)
      .reduce((prev: string[], curr: string, index: number): string[] => {
        if (index <= 1) {
          prev.push(curr);
        } else {
          prev[1] = prev[1] + curr;
        }

        return prev;
      }, [])
      .map((s: string) => s.replace(/[^a-z]/gi, ''))
      .map((s: string) => (s ? s[0].toUpperCase() + s.slice(1) : ''));

    if (!names[0]) {
      names[0] = '';
    }

    if (!names[1]) {
      names[1] = '';
    }

    return names;
  }
}

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'report-merge-respondents',
  templateUrl: './report-merge-respondents.component.html',
  styleUrls: ['./report-merge-respondents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportMergeRespondents implements OnInit, OnDestroy {
  constructor() {}
  ngOnInit() {}
  ngOnDestroy() {}
}

<!--
  @unstable
-->

<div class="container z-fx-col z-fx-center-center">
  <div class="icon z-fx z-fx-center-center z-fx-none" [ngClass]="[colorClass, type, size]">
    <mat-progress-spinner
      *ngIf="type === 'spinner'"
      [mode]="'determinate'"
      [value]="percent ? percent : 0"
      [diameter]="size === 'small' ? 34 : 50"
      [strokeWidth]="2"
    ></mat-progress-spinner>
    <mat-icon [class.zef-icon-large]="size !== 'small'">{{ icon }}</mat-icon>
  </div>
  <mat-progress-bar
    *ngIf="type === 'bar'"
    [ngClass]="[colorClass, size, percent > 100 ? 'over-limit' : '']"
    color="primary"
    [mode]="'determinate'"
    [value]="percent ? percent : 0"
    [bufferValue]="100"
  ></mat-progress-bar>
  <div *ngIf="name" class="mat-subheading-1">{{ name }}</div>
</div>

<ng-container *ngIf="template">
  <ng-container *ngTemplateOutlet="isMobile ? mobileView : desktopView"></ng-container>
</ng-container>

<ng-template #desktopView>
  <div *ngIf="viewMode === 'intro'" class="z-fx z-fx-flex z-fx-h100">
    <div class="z-dialog-sidebar z-fx-none">
      <ng-scrollbar>
        <div class="z-fx-col z-fx-gap-16">
          <h2 class="zef-text-h2">{{ template?.survey?.name }}</h2>
          <iframe
            *ngIf="videoUrl(template) as video"
            class="z-dialog-video"
            [src]="video | safeSource"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div *ngIf="introContent(template) as intro" class="z-intro" [innerHtml]="intro | safeHtml"></div>
        </div>
      </ng-scrollbar>
    </div>

    <survey-view
      *ngIf="template as data"
      class="z-template-preview z-fx-flex"
      [checkSize]="false"
      [playerState]="playerState"
      [teamKey]="teamKey$ | async"
      [teamData]="null"
      [answerData]="playerAnswers"
      [surveyData]="data.survey"
      [designData]="data.design"
      [sharingData]="data.sharing"
      [scoringData]="data.scoring"
      [outcomesData]="data.outcomes"
      [questionsData]="data.questions"
      [languagesData]="data.locales?.config"
      (resetAnswers)="playerAnswers = {}"
    ></survey-view>
  </div>

  <div *ngIf="viewMode === 'report'" class="z-fx-flex z-fx-h100">
    <zef-report [templateKey]="template.key"></zef-report>
  </div>
</ng-template>

<ng-template #mobileView>
  <div class="z-mobile z-fx-col z-fx-gap-32 z-fx-flex z-fx-h100">
    <h2 class="zef-text-h2">{{ template?.survey?.name }}</h2>
    <button mat-icon-button matDialogClose><mat-icon>close</mat-icon></button>

    <button mat-raised-button color="primary" i18n (click)="select.emit()">Select this survey</button>

    <mat-button-toggle-group class="z-fx-none" [value]="viewMode" (change)="viewMode = $event.value">
      <mat-button-toggle class="z-fx-flex" cdkFocusInitial value="intro" [disabled]="viewMode === 'intro'" i18n>
        Intro
      </mat-button-toggle>
      <mat-button-toggle class="z-fx-flex" value="preview" [disabled]="viewMode === 'preview'" i18n>
        Preview
      </mat-button-toggle>
      <mat-button-toggle class="z-fx-flex" value="report" [disabled]="viewMode === 'report'" i18n>
        Report
      </mat-button-toggle>
    </mat-button-toggle-group>

    <ng-container [ngSwitch]="viewMode">
      <ng-container *ngSwitchCase="'intro'">
        <ng-scrollbar class="z-scroll-area z-fx-col">
          <div class="z-content-area z-fx-col z-fx-gap-16 z-fx-col">
            <button mat-raised-button color="secondary" i18n (click)="viewMode = 'preview'">Preview survey</button>
            <iframe
              *ngIf="videoUrl(template) as video"
              class="z-dialog-video"
              [src]="video | safeSource"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <div *ngIf="introContent(template) as intro" class="z-intro" [innerHtml]="intro | safeHtml"></div>
          </div>
        </ng-scrollbar>
      </ng-container>

      <ng-container *ngSwitchCase="'preview'">
        <!-- <button class="zef-web-button-secondary" i18n (click)="viewMode = 'report'">View report</button> -->
        <survey-view
          *ngIf="template as data"
          class="z-template-preview z-fx-flex"
          [checkSize]="false"
          [playerState]="playerState"
          [teamKey]="teamKey$ | async"
          [teamData]="null"
          [answerData]="playerAnswers"
          [surveyData]="data.survey"
          [designData]="data.design"
          [sharingData]="data.sharing"
          [scoringData]="data.scoring"
          [outcomesData]="data.outcomes"
          [questionsData]="data.questions"
          [languagesData]="data.locales?.config"
          (resetAnswers)="playerAnswers = {}"
        ></survey-view>
      </ng-container>

      <ng-container *ngSwitchCase="'report'">
        <zef-report [templateKey]="template.key"></zef-report>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

/**
 * Slider 1R component with labels and values showing.
 *
 * @unstable
 */

import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';

import { FadeInOut } from '@shared/animations/fade-in-out.anim';

import { SliderAbstractComponent } from '@player/+survey/question/slider-abstract/slider-abstract.component';
import { SliderLabel } from '@player/shared/services/slider-label.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

@Component({
  selector: 'slider-1r',
  templateUrl: '../slider-abstract/slider-abstract.component.html',
  styleUrls: ['../slider-abstract/slider-abstract.component.scss', './slider-1r.component.scss'],
  providers: [SliderLabel],
  animations: [FadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Slider1R extends SliderAbstractComponent<string[]> {
  readonly knobs: number[] = [0, 1];

  protected readonly questionType: Questions = Questions.SLIDER_1R;

  constructor(readonly cdRef: ChangeDetectorRef, ss: SurveyStore) {
    super(ss);
  }

  getCursorLineStyle(): { [key: string]: string } {
    return {
      width: `${Math.abs(this.percentLeft[1] - this.percentLeft[0])}%`,
      left: `${Math.min(this.percentLeft[0], this.percentLeft[1])}%`,
    };
  }

  protected setCurrentValue(currentValue: string[]): void {
    const { min, max, initial } = this.getMinMaxInitial();

    currentValue = Array.isArray(currentValue) ? currentValue : [];

    if (currentValue.length < 2) {
      const range = max - min;
      const initValue: number = range * initial + min;

      this.currentValue = [initValue - range * 0.19, initValue + range * 0.19];
    } else {
      this.currentValue = currentValue.map((answer: string) => {
        const value: number = parseFloat(answer);

        return isNaN(value) ? min : Math.max(min, Math.min(max, value));
      });
    }
  }

  protected emitAnswerChange(currentAnswer: number[]): void {
    currentAnswer.sort();
    this.answerChange.emit(currentAnswer.map((answer) => answer.toString()));
  }
}

/**
 * Consent Banner for GDPR reasons
 *
 * Shown for anonymous users
 *
 * @unstable
 */

import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'zef-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class Consent implements OnInit {
  @Output() consentResult = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  onAccept() {
    this.consentResult.emit(true);
  }

  onDecline() {
    this.consentResult.emit(false);
  }
}

<div
  *ngIf="
    data && {
      action: data.cta?.action || (data.cta?.url | translateText : 'ctaLink' : data) || null,
      hasVideo: data.videoEmbed?.provider === 'youtube'
    } as cta
  "
  class="z-media z-fx-col z-fx-gap-24"
>
  <div
    class="z-media-image z-fx-col z-fx-center-center z-fx-auto"
    [style.height.px]="data.image?.url ? (imageHeight | async) : null"
  >
    <ng-content></ng-content>

    <zef-youtube-player
      *ngIf="cta.hasVideo; else image"
      [width]="imageWidth | async"
      [video]="data.videoEmbed | translateObject : ['videoId', 'startTime', 'endTime'] : data"
      [language]="lm.currentLanguage"
    ></zef-youtube-player>

    <ng-template #image>
      <div
        *ngIf="(null | translateText : 'image' : data) || data.image?.url as image"
        class="z-image-item"
        [ngStyle]="backgroundImageStyles()"
        [unsplashAttribute]="image"
        [style.cursor]="data.cta?.url || data.cta?.action ? 'pointer' : 'default'"
        [style.background-image]="image | encodeURI : 'url' | safeStyle"
        (click)="openCtaLink(cta.action)"
      ></div>
    </ng-template>
  </div>

  <ng-container *ngIf="data.cta && (data.cta.text | translateText : 'ctaText' : data) as ctaText">
    <basic-button
      *ngIf="showCtaButton(ctaText)"
      class="z-cta-button"
      [class.with-video]="cta.hasVideo"
      (click)="openCtaLink(cta.action)"
    >
      {{ ctaText }}
    </basic-button>
  </ng-container>
</div>

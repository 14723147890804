import { from, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { loadStripe } from '@stripe/stripe-js/pure';
import type { PaymentIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  readonly stripe: Promise<Stripe> = loadStripe(environment.stripePublishableKey);

  readonly elements: Promise<StripeElements> = this.stripe.then((stripe) => stripe.elements());

  confirmCardPayment(scaToken: string): Observable<PaymentIntentResult> {
    return from(this.stripe.then((stripe) => stripe.confirmCardPayment(scaToken)));
  }
}

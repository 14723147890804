/**
 * Nav menu with signout handling.
 *
 * @unstable
 */

import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { InvitePeople } from '@home/shared/dialogs/invite-people/invite-people.dialog';
import {
  SwitchTeamDialogData,
  SwitchTeam as SwitchTeamDialog,
} from '@home/shared/dialogs/switch-team/switch-team.dialog';

import { InviteData } from '@shared/models/account.model';
import { AccountState } from '@shared/states/account.state';
import { IdentityData } from '@shared/models/prefs.model';
import { DialogControl } from '@shared/services/dialog-control.service';
import { InvitesManager } from '@shared/services/invites-manager.service';
import { TeamData, UserData } from '@shared/models/account.model';
import { UserInfo } from '@shared/models/auth.model';
import { AuthState } from '@shared/states/auth.state';
import { ShowChatBubble } from '@shared/modules/chat/chat.actions';
import { OpenUserMenuOptions } from '@shared/states/interaction.models';
import { HighlightAnimation } from '@shared/animations/highlight.anim';
import { teamLogoPlaceholder } from '@shared/utilities/assets.utilities';

@Component({
  selector: 'nav-bar-menu',
  templateUrl: './nav-bar-menu.component.html',
  styleUrls: ['./nav-bar-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [HighlightAnimation],
})
export class NavBarMenuComponent {
  @Select(AuthState.isZefAdmin)
  readonly isZefAdmin$!: Observable<boolean>;

  @Select(AccountState.userTeams)
  readonly userTeams$!: Observable<string[]>;

  @Select(AccountState.user)
  readonly user$!: Observable<UserData>;

  @Select(AccountState.team)
  readonly team$!: Observable<TeamData>;

  @Select(AccountState.invites)
  readonly invites$!: Observable<InviteData[]>;

  @Select(AccountState.identity)
  readonly identity$!: Observable<IdentityData>;

  @Select(AuthState.info)
  readonly userInfo$!: Observable<UserInfo>;

  @Select(AccountState.isTeamAdmin)
  readonly isAdmin$!: Observable<boolean>;

  readonly canSwitch$: Observable<boolean> = combineLatest([this.invites$, this.userTeams$]).pipe(
    map(([invites, teams]) => invites.length + teams?.length > 1),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly hasOrgActions$: Observable<boolean> = combineLatest([this.isAdmin$, this.canSwitch$]).pipe(
    map(([isAdmin, canSwitch]) => isAdmin || canSwitch),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  @Input()
  menuOptions?: OpenUserMenuOptions;

  constructor(private store: Store, private dc: DialogControl, private im: InvitesManager) {}

  teamLogo(data: TeamData) {
    return data?.logo || teamLogoPlaceholder(+data?.created);
  }

  onInvitePeopleClick(): void {
    const teamData = this.store.selectSnapshot(AccountState.team);
    const userData = this.store.selectSnapshot(AccountState.identity);

    this.dc
      .open(InvitePeople, void 0, { data: { userData, teamData } })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.store.dispatch(new Navigate(['/settings/users']));
        }
      });
  }

  onSwitchTeamClick(): void {
    combineLatest([this.team$, this.user$, this.identity$])
      .pipe(take(1))
      .subscribe(([team, userData, identityData]) => {
        const data: SwitchTeamDialogData = {
          identityData,
          activeTeam: team?.$key,
          userData,
        };

        this.dc.open(SwitchTeamDialog, { width: '80%', height: '80%', data });
      });
  }

  onHelpClick(): void {
    this.store.dispatch(new ShowChatBubble());
  }
}

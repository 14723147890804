import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacySlider as MatSlider } from '@angular/material/legacy-slider';

import { SliderSettings } from '@plans/shared/models/slider.model';

import { TenThousands } from '@shared/pipes/ten-thousands.pipe';

@Component({
  selector: 'plan-slider',
  templateUrl: './plan-slider.component.html',
  styleUrls: ['./plan-slider.component.scss'],
})
export class PlanSlider implements OnChanges, AfterViewInit {
  @Input() settings: SliderSettings;
  @Output() change: EventEmitter<number> = new EventEmitter();

  @ViewChild('thumb') thumbRef;
  @ViewChild('slider') sliderRef: MatSlider;

  private isDowngrade: boolean;

  public thumbStyle;
  public thumbLabel;

  constructor(readonly eRef: ElementRef, readonly sanitizer: DomSanitizer, readonly tenk: TenThousands) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      const current = changes.settings.currentValue ? changes.settings.currentValue.minValue : 0;
      const previous = changes.settings.previousValue ? changes.settings.previousValue.minValue : 1;
      this.isDowngrade = current < previous;
      this.updateSlider(this.sliderValue);
    }
  }

  ngAfterViewInit() {
    this.updateSlider(this.sliderValue);
  }

  get sliderValue() {
    if (!this.settings || !this.sliderRef) {
      return;
    }
    const index = this.sliderRef ? this.sliderRef.value : 0;
    return this.toIndex(this.settings.minValue || 0, index);
  }

  toIndex(value: number, index: number) {
    const currentValue = this.settings.values[index];
    const shouldKeepOldValue = value < currentValue && !this.isDowngrade;

    return shouldKeepOldValue ? index : this.settings.values.indexOf(value);
  }

  updateSlider(index) {
    if (!this.settings || !this.sliderRef) {
      return;
    }
    const min = this.settings.values.indexOf(this.settings.minValue);
    const value = Math.max(min, index);

    this.sliderRef.writeValue(value);
    this.change.emit(this.settings.values[value]);
    this.updateThumb(value);
  }

  updateThumb(index) {
    if (!this.settings || !this.sliderRef || !this.thumbRef) {
      return;
    }

    const values = this.settings.values;
    const thumbLabelText = this.tenk.transform(values[index]) + (this.settings.text || '');
    this.thumbLabel = this.sanitizer.bypassSecurityTrustHtml(thumbLabelText);

    const sliderWidth = this.eRef.nativeElement.clientWidth;
    const thumbWidth = this.thumbRef.nativeElement.clientWidth;
    const segmentWidth = sliderWidth / (values.length - 1);
    const offset = Math.abs(index * segmentWidth) - thumbWidth / 2;
    this.thumbStyle = { transform: `translateX(${offset}px)` };
  }
}

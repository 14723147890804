export function isField(key?: string): boolean {
  return key?.substring(0, 7) === 'fields-';
}

export function sortByValue(a, b): number {
  return a < b ? -1 : 1;
}

export function isContactEntry(key?: string): boolean {
  return [
    'id',
    'firstName',
    'lastName',
    'name',
    'email',
    'phone',
    'photo',
    'thumb',
    'created',
    'modified',
    'source',
    'creator',
    'collectionMethod',
  ].includes(!isField(key) ? key : key?.substring(7));
}

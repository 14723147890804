import { environment } from '@env/environment';

import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { Store, Select } from '@ngxs/store';

import { LocalStorageService } from 'ngx-webstorage';

import { AccountState } from '@shared/states/account.state';

import { PrefsManager } from '@shared/services/prefs-manager.service';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { AuthState } from '@shared/states/auth.state';
import { UserInfo } from '@shared/models/auth.model';
import { SignOutWithoutRedirect } from '@shared/states/auth.actions';

/**
 * This is a navigation bar for the report application.
 */
@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBar implements OnInit {
  readonly window = window;
  readonly environment = environment;

  public selectedTab: number = 4;
  public email: string = '';

  @Input() info: any = null;

  @Select(AuthState.info)
  readonly user$: Observable<UserInfo>;

  @Select(AuthState.isAnonymous)
  readonly isAnonymous$: Observable<boolean>;

  constructor(
    readonly store: Store,
    private cdRef: ChangeDetectorRef,
    private pm: PrefsManager,
    readonly route: ActivatedRoute,
    readonly hooks: LifecycleHooks,
    private ls: LocalStorageService,
  ) {}

  ngOnInit() {
    const userKey = this.store.selectSnapshot(AccountState.userKey);
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    if (userKey) {
      this.pm
        .loadIdentity(userKey, teamKey)
        .pipe(takeUntil(this.hooks.destroy))
        .subscribe((user) => {
          this.email = user.email;
          this.cdRef.detectChanges();
        });
    }
  }

  public logout(): void {
    this.ls.clear('remember');
    this.ls.clear('report-team');

    this.store.dispatch(new SignOutWithoutRedirect()).subscribe(() => {
      window?.location.reload();
    });
  }
}

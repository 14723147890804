import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { ChartDistribution, ChartDomain, CanvasContext, ChartStats } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a horizontal bar chart.
 */
@Directive({
  selector: '[barChartH]',
})
export class BarChartH implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() totalAnswers: number = 0;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;

  private base: any;

  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private max: number = 0;
  private previousMax: number = 0;

  private responses: number = 0;
  private previousResponses: number = 0;

  private scaleX: any;
  private scaleY: any;

  private tooltip: any;

  private width: any;
  private height: any;
  private margin: any;
  private fontSize: number = 0;

  private filter: any;

  private selections: any = new Set();

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.stats ||
      changes.filtersDemo
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement).append('div').attr('class', 'bar-chart-h');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    const unit: number = (10 / 14) * this.fontSize;

    this.margin = {
      top: 2.2 * this.fontSize,
      right: 20,
      bottom: this.fontSize * 3,
      left:
        0.33 * this._element.nativeElement.clientWidth > 12 * unit
          ? 12 * unit
          : 0.33 * this._element.nativeElement.clientWidth,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    const overScale = 1.2;

    this.previousMax = this.max;
    this.max = (d3.max(this.data, (d) => (this.scale === 'percentage' ? d.percentage : d.value)) || 1) * overScale;

    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain([0, this.max]);

    this.scaleY = d3
      .scaleBand()
      .rangeRound([0, this.height])
      .domain(this.domain.keys)
      .paddingInner(0.2)
      .paddingOuter(0)
      .align(0.5);

    this.previousResponses = this.responses;
    this.responses = this.stats && this.stats['responses'] != null ? this.stats['responses'] : this.totalAnswers;
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      // __this.setTexts(context);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context.data ? __this.context.data : [];
        const interpolateArray: any = (a, b) => {
          const nb: number = b ? b.length : 0;
          const na: number = a ? Math.min(nb, a.length) : 0;
          const x: any[] = new Array(na);
          const c: any[] = new Array(...b);

          for (let i = 0; i < na; ++i) {
            x[i] = {
              value: d3.interpolateNumber(a[i]['value'], b[i]['value']),
              percentage: d3.interpolateNumber(a[i]['percentage'], b[i]['percentage']),
              percentage_all: d3.interpolateNumber(a[i]['percentage_all'], b[i]['percentage_all']),
            };
          }

          return function (t) {
            for (let i = 0; i < na; ++i) {
              c[i]['value'] = x[i]['value'](t);
              c[i]['percentage'] = x[i]['percentage'](t);
              c[i]['percentage_all'] = x[i]['percentage_all'](t);
            }
            return c;
          };
        };

        const interpolator = interpolateArray(dataObj, d);
        const interpolateMax = d3.interpolateNumber(__this.previousMax, __this.max);
        const interpolateResponses = d3.interpolateNumber(__this.previousResponses, __this.responses);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scaleX;
          let responses;

          if (step >= 1) {
            data = interpolator(ease(1));
            responses = interpolateResponses(ease(1));
            scaleX = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([0, interpolateMax(ease(1))]);
            timer.stop();
          } else {
            data = interpolator(ease(step));
            responses = Math.round(interpolateResponses(ease(step)));
            scaleX = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([0, interpolateMax(ease(step))]);
          }

          __this.setRects(context, data, scaleX);
          __this.setTexts(context, responses);
        });
      } else {
        __this.setRects(context, d, __this.scaleX);
        __this.setTexts(context, __this.responses);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.bar-chart-h-canvas').data([this.data]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'bar-chart-h-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setRects(context, data: any[] = [], scaleX, filter: any[] | null = [], highlight: any[] | null = []) {
    context.clearRect(this.margin.left - 2, this.margin.top - 2, this.width + this.margin.right + 2, this.height + 4);
    this.setXAxis(context, scaleX);
    this.selections = new Set();

    for (let x = 0, lenx = data.length; x < lenx; x++) {
      const key = data[x]['key'];
      const value = data[x]['value'];
      const percentage = data[x]['percentage'];

      const xPos = this.margin.left;
      const yPos = this.scaleY(key) + this.margin.top;
      const width = scaleX(this.scale === 'percentage' ? percentage : value);
      const height = this.scaleY.bandwidth();

      context.fillStyle = this.selectionExists
        ? Colors.SELECTED
        : this.filtersDemo
        ? Colors.UNSELECTED
        : Colors.DEFAULT;

      if (this.filterInput && this.filterInput.length > 0) {
        const xFi = this.filterInput.indexOf(key) > -1;

        if (!xFi) {
          context.fillStyle = Colors.UNSELECTED;
        } else {
          context.fillStyle = Colors.FILTER;
          this.selections.add(key);
        }
      }

      context.fillRect(xPos, yPos, width, height);

      context.strokeStyle = 'transparent';
      context.lineWidth = 1;
      if (highlight && highlight.length > 0) {
        const xHi = highlight && highlight.indexOf(key) > -1;

        if (xHi) {
          context.lineWidth = 2;
          context.strokeStyle = Colors.HIGHLIGHT;
        }
      }

      context.strokeRect(xPos, yPos, width, height);

      if (this.showNumbers) {
        context.font = 10 / 14 + 'em Open Sans';
        context.textBaseline = 'middle';
        context.textAlign = 'left';
        context.fillStyle = Colors.TEXT;
        context.fillText(
          this.scale === 'percentage' ? (percentage * 100).toFixed(1) + '%' : Math.round(value),
          xPos + width + 5,
          yPos + height / 2,
        );
      }
    }
  }

  setTexts(context, responses) {
    context.clearRect(0, 0, this.margin.left, this.margin.top + this.height);
    context.clearRect(0, 0, this.width + this.margin.right + this.margin.left, this.margin.top);

    context.fillStyle = this.filterInput ? Colors.FILTER : Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = this.margin.top / 2;

    const wIcon = this.fontSize + 4;

    context.font = 10 / 14 + 'em Open Sans';
    const wNumber = context.measureText(responses).width + 12;

    context.font = 12 / 14 + 'em Open Sans';
    const title = this.title ? shortenText(context, this.title, this.width, 8 + (wIcon + wNumber) / 2) : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

    context.font = 10 / 14 + 'em Open Sans';
    context.fillText(responses, startPoint + wTitle + wIcon, h);

    context.font = 12 / 14 + 'em Open Sans';
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'right';
    context.textBaseline = 'middle';
    const areaW = this.margin.left - 8;
    const areaH = this.scaleY.bandwidth();
    const lineHeight = 1.25 * this.fontSize >= areaH ? areaH : 1.25 * this.fontSize;

    this.domain.keys.forEach((d) => {
      context.save();
      const text = this.domain.labels[d] || '';
      context.font = (text === '☑' || text === '☐' ? 24 : 12) / 14 + 'em Open Sans';
      const textLines = this.multiLineWrap(context, text, areaW, areaH, lineHeight);
      const posY = this.margin.top + this.scaleY(d);

      for (let l = 0, len = textLines.length; l < len; l++) {
        const dy =
          len > 1
            ? (l + 1) * (len / (len + 1)) * lineHeight +
              (areaH % lineHeight) / (len + 1) +
              (len < Math.floor(areaH / lineHeight) ? ((Math.floor(areaH / lineHeight) - len) * lineHeight) / 2 : 0)
            : areaH / 2;
        context.fillText(textLines[l], areaW, posY + dy);
        context.restore();
      }
    });
  }

  setXAxis(context, scaleX) {
    context.clearRect(
      0,
      this.height + this.margin.top,
      this.width + this.margin.left + this.margin.right,
      this.margin.bottom,
    );

    const __this = this;
    const tickCount = this.scale === 'percentage' ? 4 : this.max > 3 ? 4 : this.max > 2 ? 2 : 1;
    const tickPadding = 3;
    const ticks = scaleX.ticks(tickCount);
    const tickFormat = scaleX.tickFormat(tickCount, this.scale === 'percentage' ? ',.1%' : 'd');

    context.font = 10 / 14 + 'em Open Sans';
    context.fillStyle = Colors.TEXT;
    context.strokeStyle = Colors.HELPERLINE;
    context.lineWidth = 1;
    context.textAlign = 'center';
    context.textBaseline = 'top';
    ticks.forEach((d) => {
      const x = this.margin.left + scaleX(d);
      const y = this.margin.top + this.height + tickPadding;
      context.fillText(tickFormat(d), x, y);
      context.beginPath();
      context.moveTo(x, y - tickPadding);
      context.lineTo(x, this.margin.top);
      context.stroke();
    });
  }

  setBrush() {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice || !__this.filtering || !__this.anonymityLock) {
        __this.selectForHover(event);
      }
    };

    this.base
      .selectAll('.bar-chart-h-canvas')
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event, d) {
        __this.setTooltip(d3.pointer(event));
      })
      .on('click', function (event, d) {
        if (__this.filtering || !__this.anonymityLock) {
          const area = d3.pointer(event);
          __this.selectFromBars(area);

          __this.callFilter();
        }
      });
  }

  setTooltip(position, data: any[] = []) {
    const __this = this;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    // adding hovering effect
    this.base.select('.bar-chart-h-canvas').each(function (d) {
      const highlight = data.map((item) => item.key);

      __this.setRects(__this.context.context, d, __this.scaleX, [], highlight);
    });

    if (data.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.select('.bar-chart-h-canvas').style('cursor', 'pointer');
    } else {
      this.base.select('.bar-chart-h-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.cf.filter(this.filter);
      }
    }
  }

  // Helpers
  multiLineWrap(context, text, width, height, lineheight) {
    const textLength = context.measureText(text).width;
    const room = Math.floor(height / lineheight);

    if (textLength > width - 10 && text.length > 0) {
      const words = text.split(/\s+/).reverse();
      const lines: string[] = [];
      let limitReached = false;
      let line = '';
      let word;

      while ((word = words.pop())) {
        const testLine = line + word + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > width - 10 && line.length > 0) {
          if (lines.length + 1 === room) {
            lines.push(line + '...');
            limitReached = true;
            break;
          } else {
            lines.push(line);
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      if (!limitReached) {
        lines.push(line);
      }

      return lines;
    } else {
      return [text];
    }
  }

  selectForHover(event) {
    const area = d3.pointer(event);
    const items = this.itemsBelow(area);

    if (items.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement), items);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    }
  }

  selectFromBars(area) {
    const items = this.itemsBelow(area);

    for (let s = 0, len = items.length; s < len; s++) {
      if (this.selections.has(items[s].key)) {
        this.selections.delete(items[s].key);
      } else {
        this.selections.add(items[s].key);
      }
    }
  }

  itemsBelow(area) {
    const items = this.data.filter(
      (item) =>
        area[1] < this.scaleY(item.key) + this.scaleY.bandwidth() + this.margin.top &&
        area[1] > this.scaleY(item.key) + this.margin.top,
    );

    return items;
  }
}

import { Injectable } from '@angular/core';

import { SliderValuesData } from '@shared/models/survey.model';

/**
 * This service will convert filters from chart to filters used in data backend API queries.
 */
@Injectable({
  providedIn: 'root',
})
export class FilterConverter {
  constructor() {}

  public convert(filters: any, mergeSettings: { [s: string]: any } = {}, textLabels: { [s: string]: any } = {}) {
    const filterList = [];
    /* Parsing used filters for the data query */
    for (const filter in filters) {
      if (filter === 'userSegments') {
        for (const customKey in filters[filter].dimensionData.customOrigins) {
          if (filters[filter].filter.indexOf(customKey) >= 0) {
            for (const f in filters[filter].dimensionData.customOrigins[customKey]['filters']) {
              this.setFilters(
                f,
                filters[filter].dimensionData.customOrigins[customKey]['filters'][f],
                filterList,
                mergeSettings,
                textLabels,
              );
            }
          }
        }
      } else {
        this.setFilters(filter, filters[filter], filterList, mergeSettings, textLabels);
      }
    }

    return filterList;
  }

  setFilters(
    longKey: string,
    filter: any,
    filterList: any[],
    mergeSettings: { [s: string]: any } = {},
    textLabels: { [s: string]: any } = {},
  ) {
    const key: string = longKey.indexOf('/') >= 0 ? longKey.split('/')[1] : longKey;
    const surveyKey: string = filter.dimensionData && filter.dimensionData['survey'];
    const mergedSurveys: any[] = [];

    if (mergeSettings && Object.keys(mergeSettings).length) {
      for (const survey in mergeSettings) {
        for (const question in mergeSettings[survey]) {
          if (
            mergeSettings[survey][question]['question'] &&
            mergeSettings[survey][question]['question']['$key'] === key.split(':')[0]
          ) {
            const mergedFilter: { [prop: string]: any } = {};

            mergedFilter['id'] = question;
            mergedFilter['survey'] = survey;

            if (key.split(':')[1] != null) {
              mergedFilter['part'] = key.split(':')[1];
            }

            if (filter.dimensionData.originalTypeSpecifier === 'text-sentiment') {
              mergedFilter['filterType'] = 'sentiment';
              let numbers = [];
              for (const val of filter.filter) {
                numbers = numbers.concat(
                  this.classToValue(Number(val), filter.dimensionData.valueScaleLinear, filter.values.length),
                );
              }

              mergedFilter['values'] = numbers.length
                ? [
                    `${Math.min(...numbers.map((val) => Number(val))) / 10}/${
                      Math.max(...numbers.map((val) => Number(val))) / 10
                    }`,
                  ]
                : [];
            } else if (filter.dimensionData.originalTypeSpecifier === 'text-words') {
              mergedFilter['filterType'] = 'word';
              const groupSeparator: string = '\u001D';

              mergedFilter['values'] = filter.filter.map((item) => {
                const word: string[] = textLabels[filter.dimensionData.key]?.[item]?.split(groupSeparator);
                const PoS: string = filter.dimensionData.valueGroupKeys[word[1]];

                return word[0] + groupSeparator + PoS;
              });
            } else if (mergeSettings[survey][question]['choices']) {
              const values = [];
              for (const origChoice of filter.filter) {
                const strippedKey: string = origChoice.indexOf('/') >= 0 ? origChoice.split('/')[1] : origChoice;
                const choiceSurvey: string = origChoice.indexOf('/') >= 0 ? origChoice.split('/')[0] : '';

                const mapped =
                  choiceSurvey !== survey &&
                  mergeSettings[survey][question] &&
                  mergeSettings[survey][question]['choices'].find(
                    (item) => item && item.mappedTo && item.mappedTo.$key === strippedKey,
                  );

                if (mapped) {
                  values.push(mapped.orig.$key);
                } else {
                  values.push(strippedKey);
                }
              }
              mergedFilter['values'] = values;
            } else {
              let values: string[] = [];

              if (filter.dimensionData.scale === 'linear') {
                let numbers = [];
                for (const val of filter.filter) {
                  numbers = numbers.concat(
                    this.classToValue(Number(val), filter.dimensionData.valueScaleLinear, filter.values.length),
                  );
                }
                values = numbers.length
                  ? [
                      `${Math.min(...numbers.map((val) => Number(val)))}/${Math.max(
                        ...numbers.map((val) => Number(val)),
                      )}`,
                    ]
                  : [];

                mergedFilter['filterType'] = 'range';
              } else {
                values = filter.filter;
              }
              mergedFilter['values'] = values;
            }
            if (mergedFilter.values && mergedFilter.values.length) {
              mergedSurveys.push(mergedFilter);
            }
          } else if (
            key === 'outcome' &&
            question === 'outcomes' &&
            mergeSettings[survey][question]['outcomes'] &&
            mergeSettings[survey][question]['outcomes'].length
          ) {
            const mergedFilter: { [prop: string]: any } = {};
            const values = [];

            mergedFilter['id'] = key.split(':')[0];
            mergedFilter['survey'] = survey;

            for (const origOutcome of filter.filter) {
              const strippedKey: string = origOutcome.indexOf('/') >= 0 ? origOutcome.split('/')[1] : origOutcome;
              const outcomeSurvey: string = origOutcome.indexOf('/') >= 0 ? origOutcome.split('/')[0] : '';

              const mapped =
                outcomeSurvey !== survey &&
                mergeSettings[survey][question] &&
                mergeSettings[survey][question]['outcomes'].find(
                  (item) => item && item.mappedTo && item.mappedTo.$key === strippedKey,
                );

              if (mapped) {
                values.push(mapped.orig.$key);
              } else {
                values.push(strippedKey);
              }
            }
            mergedFilter['values'] = values;

            if (mergedFilter.values && mergedFilter.values.length) {
              mergedSurveys.push(mergedFilter);
            }
          }
        }
      }
    }

    if (filter.dimensionData.originalType === 'contact-property') {
      // notice that we remove 'contacts-' text from key
      let values: string[] = [];
      if (filter.dimensionData.scale === 'categorical') {
        values = filter.filter;
      } else {
        // TODO: selection of multiple text values when comma is used on contact property values
        values = filter.labels.split(', ');
      }

      filterList.push({ property: key.slice(9), values });
    } else if (filter.dimensionData.originalType === 'respondent-field') {
      filterList.push({
        id: key,
        survey: surveyKey,
        values: filter.filter,
        mergedSurveys,
      });
    } else if (filter.dimensionData.originalType === 'zefSurveyUserRating') {
      filterList.push({
        id: key,
        survey: surveyKey,
        values: filter.filter,
        mergedSurveys: Object.keys(mergeSettings || {}).map((survey) => ({
          id: 'zefSurveyUserRating',
          survey,
          values: filter.filter,
        })),
      });
    } else if (filter.dimensionData.originalTypeSpecifier === 'text-sentiment') {
      let numbers = [];
      for (const val of filter.filter) {
        numbers = numbers.concat(
          this.classToValue(Number(val), filter.dimensionData.valueScaleLinear, filter.values.length),
        );
      }

      filterList.push({
        id: key.split(':')[0],
        survey: surveyKey,
        filterType: 'sentiment',
        values: numbers.length
          ? [
              `${Math.min(...numbers.map((val) => Number(val))) / 10}/${
                Math.max(...numbers.map((val) => Number(val))) / 10
              }`,
            ]
          : [],
        mergedSurveys,
      });
    } else if (filter.dimensionData.originalTypeSpecifier === 'text-words') {
      const groupSeparator: string = '\u001D';

      filterList.push({
        id: key.split(':')[0],
        survey: surveyKey,
        filterType: 'word',
        values: filter.filter.map((item) => {
          const word: string[] = textLabels[filter.dimensionData.key]?.[item]?.split(groupSeparator);
          const PoS: string = filter.dimensionData.valueGroupKeys[word[1]];

          return word[0] + groupSeparator + PoS;
        }),
        mergedSurveys,
      });
    } else if (filter.dimensionData.scale === 'linear') {
      let numbers = [];
      for (const val of filter.filter) {
        numbers = numbers.concat(
          this.classToValue(Number(val), filter.dimensionData.valueScaleLinear, filter.values.length),
        );
      }
      const values: string[] = numbers.length
        ? [`${Math.min(...numbers.map((val) => Number(val)))}/${Math.max(...numbers.map((val) => Number(val)))}`]
        : [];

      if (filter.dimensionData.originalType === 'slider-2d' || filter.dimensionData.originalType === 'slider-1r') {
        filterList.push({
          id: key.split(':')[0],
          survey: surveyKey,
          part: key.split(':')[1],
          filterType: 'range',
          values,
          mergedSurveys,
        });
      } else {
        filterList.push({
          id: key.split(':')[0],
          survey: surveyKey,
          filterType: 'range',
          values,
          mergedSurveys,
        });
      }
    } else if (key === 'time') {
      const values: string[] = [];

      for (const time of filter.filter) {
        const start = new Date(Number(time));

        if (filter.timePeriod === 'day') {
          values.push(`${start.toISOString()}/${new Date(Number(time) + 86400000).toISOString()}`);
        } else if (filter.timePeriod === 'week') {
          values.push(`${start.toISOString()}/${new Date(Number(time) + 86400000 * 7).toISOString()}`);
        } else if (filter.timePeriod === 'month') {
          let end;
          if (start.getMonth() === 11) {
            end = new Date(start.getFullYear() + 1, 0, 1).setHours(0, 0, 0, 0);
          } else {
            end = new Date(start.getFullYear(), start.getMonth() + 1, 1).setHours(0, 0, 0, 0);
          }

          values.push(`${start.toISOString()}/${new Date(Number(end)).toISOString()}`);
        } else if (filter.timePeriod === 'year') {
          const end = new Date(start.getFullYear() + 1, 0, 1).setHours(0, 0, 0, 0);

          values.push(`${start.toISOString()}/${new Date(Number(end)).toISOString()}`);
        }
      }
      filterList.push({ id: key, values });
    } else if (key === 'shareLink') {
      filterList.push({
        id: key,
        values: filter.filter,
      });
    } else if (filter.textFilter) {
    } else {
      filterList.push({
        id: key,
        survey: surveyKey,
        values: filter.filter
          .filter((f) => (f.indexOf('/') >= 0 && f.split('/')[0] === surveyKey) || f.indexOf('/') < 0)
          .map((f) => (f.indexOf('/') >= 0 ? f.split('/')[1] : f)),
        mergedSurveys,
      });
    }
  }

  /**
   * Return value scale from classes.
   *
   * @param classVal  Original class value.
   * @param origin    Scale origins for the answer.
   * @param classes   Number of classes in dimension.
   * @returns         Array of values in class.
   */
  classToValue(classVal: number, origin: SliderValuesData, classes: number) {
    const values: string[] = [];

    const min = !origin || origin.min == null ? 0 : origin.min;
    const max = !origin || origin.max == null ? 100 : origin.max;
    const step = !origin || origin.step == null ? 1 : origin.step;
    const defaulRounder = Math.pow(10, 5);

    const smallerStep =
      step > 1 && step % 1 === 0
        ? 1
        : Math.round((step % 1) * defaulRounder) / defaulRounder > 0
        ? Number('1e-' + step.toString().split('.')[1].length)
        : step;
    const originalClasses = (max + step - min) / step;
    const rounder = step % 1 > 0 ? Math.pow(10, step.toString().split('.')[1].length) : Math.pow(10, 0);

    const classCalc = (answer) => Math.floor((Number(answer) - min) / ((max + smallerStep - min) / classes));

    if (classes === originalClasses) {
      const val = min + classVal * step;
      values.push((Math.round(val * defaulRounder) / defaulRounder).toString());
    } else {
      let valMin = Math.floor((classVal * ((max - min) / classes) + min) * rounder) / rounder;
      let valMax = Math.floor(((classVal + 1) * ((max - min) / classes) + min) * rounder) / rounder;

      valMin = Math.round(valMin * defaulRounder) / defaulRounder;
      valMax = Math.round(valMax * defaulRounder) / defaulRounder;

      valMin =
        classCalc(valMin) < classVal
          ? classCalc(valMin + smallerStep) < classVal
            ? classCalc(valMin + 2 * smallerStep) === classVal
              ? valMin + 2 * smallerStep
              : valMin
            : valMin + smallerStep
          : valMin;
      valMax =
        classCalc(valMax + smallerStep) === classVal
          ? valMax + smallerStep
          : classCalc(valMax) > classVal
          ? classCalc(valMin - smallerStep) === classVal
            ? valMax - smallerStep
            : valMax
          : valMax;

      valMin = Math.round(valMin * defaulRounder) / defaulRounder;
      valMax = Math.round(valMax * defaulRounder) / defaulRounder;

      for (let val = valMin; val <= valMax; val += step) {
        values.push((Math.round(val * defaulRounder) / defaulRounder).toString());
      }
    }

    return values;
  }
}

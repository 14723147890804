import { environment } from '@env/environment';

import { Observable, tap, EMPTY } from 'rxjs';

import { Injectable } from '@angular/core';

import { patch } from '@ngxs/store/operators';
import { State, createSelector, Selector, StateContext, Action, Store } from '@ngxs/store';

import { shareRef } from '@shared/operators/share-ref.operator';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { HelpCenterService } from '@shared/modules/help-center/help-center.service';
import {
  GetHelpItemStatus,
  GetHelpItemTip,
  UpdateHelpCenterMode,
  GetHelpItemData,
  GetHelpItemStatistics,
  UpdateHelpItemData,
  IncrementHelpItemStats,
} from '@shared/modules/help-center/state/help-center.actions';
import {
  HELP_CENTER_STATE,
  HelpItemSubject,
  HelpCenterStateModel,
  HelpCenterItem,
  HelpCenterItems,
  HelpCenterItemStatus,
  HelpCenterItemData,
  HelpCenterItemStatistics,
  HelpCenterLanguage,
  HelpCenterMode,
} from '@shared/modules/help-center/help-center.models';

type StateCtx = StateContext<HelpCenterStateModel>;

@State({
  name: HELP_CENTER_STATE,
  defaults: {
    items: {},
  },
})
@Injectable()
export class HelpCenterState {
  @Selector([PrefsState.language])
  static language({ language }: HelpCenterStateModel, uiLanguage): string {
    return language || uiLanguage;
  }

  @Selector()
  static edit({ mode }: HelpCenterStateModel): boolean {
    return mode === HelpCenterMode.Edit;
  }

  @Selector()
  static preview({ mode }: HelpCenterStateModel): boolean {
    return mode === HelpCenterMode.Preview;
  }

  @Selector()
  static read({ mode }: HelpCenterStateModel): boolean {
    return mode === HelpCenterMode.Read;
  }

  @Selector()
  static items({ items }: HelpCenterStateModel): HelpCenterItems {
    return items;
  }

  static item(subject: HelpItemSubject) {
    return createSelector(
      [HelpCenterState.items],
      (items: HelpCenterItems): HelpCenterItem | undefined => items[subject],
    );
  }

  static status(subject: HelpItemSubject) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item?: HelpCenterItem): HelpCenterItemStatus | undefined => item?.status,
    );
  }

  static tip(subject: HelpItemSubject, lang: HelpCenterLanguage) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item?: HelpCenterItem): string | undefined => item?.data?.[lang]?.quickTip,
    );
  }

  static data(subject: HelpItemSubject, lang: HelpCenterLanguage) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item: HelpCenterItem | undefined): HelpCenterItemData | undefined => item?.data?.[lang],
    );
  }

  static stats(subject: HelpItemSubject) {
    return createSelector(
      [HelpCenterState.item(subject)],
      (item: HelpCenterItem | undefined): Record<string, HelpCenterItemStatistics> | undefined => item?.stats,
    );
  }

  constructor(private hcs: HelpCenterService, private store: Store) {}

  @StreamAction(GetHelpItemStatus)
  getStatus(ctx: StateCtx, { subject }: GetHelpItemStatus): Observable<unknown> {
    return this.hcs.getItemStatus(subject).pipe(
      tap((status) => this.updateItem(ctx, subject, { status })),
      shareRef(),
    );
  }

  @StreamAction(GetHelpItemTip)
  getTip(ctx: StateCtx, { subject, lang }: GetHelpItemTip): Observable<unknown> {
    return this.hcs.getItemTip(subject, lang).pipe(
      tap((quickTip) => this.updateItemData(ctx, subject, lang, { quickTip })),
      shareRef(),
    );
  }

  @StreamAction(GetHelpItemData)
  getData(ctx: StateCtx, { subject, lang }: GetHelpItemData): Observable<unknown> {
    return this.hcs.getItemData(subject, lang).pipe(
      tap((data) => this.updateItemData(ctx, subject, lang, data)),
      shareRef(),
    );
  }

  @StreamAction(GetHelpItemStatistics)
  getStatistics(ctx: StateCtx, { subject }: GetHelpItemStatistics): Observable<unknown> {
    return this.hcs.getItemStatistics(subject).pipe(
      tap((stats) => this.updateItem(ctx, subject, { stats })),
      shareRef(),
    );
  }

  @Action(UpdateHelpCenterMode)
  updateMode({ patchState }: StateCtx, { mode }: UpdateHelpCenterMode): void {
    patchState(mode);
  }

  @Action(UpdateHelpItemData)
  updateData(ctx: StateCtx, { subject, data }: UpdateHelpItemData): Observable<unknown> {
    const dataEntries = Object.entries(data || {});

    if (!dataEntries.length) {
      return EMPTY;
    }

    dataEntries.forEach(([lang, update]) => this.updateItemData(ctx, subject, lang as HelpCenterLanguage, update));

    return this.hcs.updateItemData(subject, data);
  }

  @Action(IncrementHelpItemStats)
  updateStats(_: StateCtx, { subject, property, lang }: IncrementHelpItemStats): Observable<unknown> {
    if (environment.production && this.store.selectSnapshot(AuthState.isZefAdmin)) {
      return EMPTY;
    }

    return this.hcs.updateItemStats(subject, property, lang);
  }

  private updateItemData(
    { setState, getState }: StateCtx,
    subject: HelpItemSubject,
    lang: HelpCenterLanguage,
    update: Partial<HelpCenterItemData>,
  ): void {
    const subjectState = getState().items[subject];
    const langUpdate = subjectState?.data?.[lang] ? patch(update) : update;
    const dataUpdate = subjectState?.data ? patch({ [lang]: langUpdate }) : { [lang]: langUpdate };
    const itemUpdate = subjectState ? patch({ data: dataUpdate }) : ({ data: dataUpdate } as HelpCenterItem);

    setState(patch({ items: patch({ [subject]: itemUpdate }) }));
  }

  private updateItem(
    { setState, getState }: StateCtx,
    subject: HelpItemSubject,
    update: Partial<HelpCenterItem>,
  ): void {
    const itemUpdate = getState().items[subject] ? patch(update) : (update as HelpCenterItem);

    setState(patch({ items: patch({ [subject]: itemUpdate }) }));
  }
}

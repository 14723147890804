import { StateToken } from '@ngxs/store';

import { TeamData, UserData } from '@shared/models/account.model';

export interface AccountStateModel {
  team: TeamData | null;
  user: UserData | null;
  visitorId: string | null;
}

export const ACCOUNT_STATE_TOKEN = new StateToken<AccountStateModel>('account');

export type TeamMembersCount = {
  owner: number;
  admin: number;
  member: number;
  total: number;
};

/**
 * Create thumb with new & sample creation.
 *
 * @unstable
 */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { AccountManager } from '@shared/services/account-manager.service';
import { SetTemplate } from '@shared/states/templates.actions';
import { AnalyticsTracks } from '@shared/models/analytics.model';
import { AccountState } from '@shared/states/account.state';
import { Observable } from 'rxjs';
import { Rights } from '@shared/enums/rights.enum';

@Component({
  selector: 'create-thumb',
  templateUrl: './create-thumb.component.html',
  styleUrls: ['./create-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateThumb {
  readonly AnalyticsTracks = AnalyticsTracks;
  public hovering: boolean = false;

  @Select(AccountState.userRole)
  userRole$: Observable<Rights>;

  @Input() sample: boolean = false;

  @Output() loading: EventEmitter<boolean> = new EventEmitter();

  constructor(readonly store: Store, readonly am: AccountManager) {}

  /**
   * Create new survey and send analytics events.
   */
  public onSurveyCreate(): void {
    this.store.dispatch(new SetTemplate(''));
  }
}

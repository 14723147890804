import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, first } from 'rxjs/operators';

import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Select, Store } from '@ngxs/store';

import { SurveysManager } from '@shared/services/surveys-manager.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';
import { setQuestionTranslations, setOutcomeTranslations } from '@shared/utilities/language.utilities';

import { Survey, QuestionData, ChoiceItemData, OutcomeData } from '@shared/models/survey.model';

import { Questions } from '@shared/enums/questions.enum';

import { SurveyState } from '@shared/states/survey.state';
import { IndexState } from '@shared/states/index/index.state';
import { SurveyIndex } from '@shared/states/index/index-state.models';
import { GetSurveysIndex } from '@shared/states/index/index-state.actions';

import { DataPreparation } from '@report/shared/services/data-preparation.service';
import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { LocalesData } from '@shared/models/locale.model';

@Component({
  selector: 'report-merge-surveys',
  templateUrl: './report-merge-surveys.component.html',
  styleUrls: ['./report-merge-surveys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportMergeSurveys implements OnInit, OnDestroy {
  @Output() closePanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Select(IndexState.surveys)
  readonly surveys$: Observable<SurveyIndex[]>;

  readonly icons = Questions.QuestionIcons;
  readonly iconColors = Questions.QuestionIconColors;

  public searchTerm: string = '';
  public showMatchedQuestions: boolean = false;
  public showNewQuestions: boolean = false;
  public showOutcomes: boolean = false;

  public survey: Survey | null = null;
  public selectedSurvey: Survey | null = null;
  public surveySelectionDone: boolean = false;

  public activeSurveyQuestions: QuestionData[] = [];
  public matchedQuestions: QuestionData[] = [];
  public newQuestions: QuestionData[] = [];
  public selectedQuestions: { [key: string]: boolean } = {};

  public activeSurveyOutcomes: OutcomeData[] = [];
  public selectedSurveyOutcomes: OutcomeData[] = [];

  public activeSurveyLocales: LocalesData;

  public activeSurveyUsage: number;
  public selectedSurveyUsage: number;

  public selectedAllNewQuestions: boolean = true;
  public selectedAllMatchedQuestions: boolean = true;

  public openManualMergeItem: string = '';
  public selectedQuestionManualMerge: QuestionData | null = null;
  public selectedQuestionManualMergeChoice: { [key: string]: any } = {};
  public selectedQuestionManualMergeNewChoice: { [prop: string]: any } = {
    $key: 'new',
    content: 'Add as new item',
    manualMerge: true,
  };

  public outcomesManualMergeOutcome: { [key: string]: any } = {};
  public outcomesManualMergeNewOutcome: { [prop: string]: any } = {
    $key: 'new',
    title: 'Add as new item',
    manualMerge: true,
  };
  public mergeDone: boolean = false;

  public mappings: { [key: string]: any } = {};
  public alreadyMergedSurveys: string[];

  private activeSurvey: string = '';
  private activeSurveyQuestionsSub: any;

  readonly searchUpdate = new BehaviorSubject<string>('').pipe(
    map((search) => search.toLowerCase()),
    distinctUntilChanged(),
  ) as BehaviorSubject<string>;

  readonly surveys: Observable<SurveyIndex[]> = this.searchUpdate.pipe(
    switchMap((query) =>
      this.surveys$.pipe(
        map((surveys) =>
          surveys.filter(
            (survey) =>
              survey?.$key &&
              survey.$key !== this.activeSurvey &&
              (query == null || survey?.surveyName?.toLowerCase().indexOf(query) >= 0),
          ),
        ),
      ),
    ),
  );

  constructor(
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    private dp: DataPreparation,
    private hooks: LifecycleHooks,
    private route: ActivatedRoute,
    private sm: SurveysManager,
    private store: Store,
  ) {}

  ngOnInit() {
    this.store.dispatch(new GetSurveysIndex());

    this.route.params.pipe(takeUntil(this.hooks.destroy)).subscribe((params) => {
      this.activeSurvey = params.survey;
      this.survey = this.sm.connectSurvey(this.activeSurvey);

      this.activeSurveyQuestionsSub = combineLatest(
        this.survey.questions,
        this.survey.outcomes,
        this.store.select(SurveyState.surveyUsage(this.activeSurvey)),
        this.survey.locales,
      )
        .pipe(takeUntil(this.hooks.destroy))
        .subscribe(([questions, outcomes, usage, locales]) => {
          this.activeSurveyQuestions = questions;
          this.activeSurveyOutcomes = outcomes.filter((item) => item.type !== 'goodbye');
          this.activeSurveyUsage = usage?.answers?.success;
          this.activeSurveyLocales = locales;
        });

      this.cdRef.detectChanges();
    });

    this.alreadyMergedSurveys = this.cf.getIncludedSurveys().map((survey) => survey['$key']);
  }

  ngOnDestroy() {
    this.mergeDone = false;
  }

  trackByFn(index: number, survey: SurveyIndex): string | number {
    return survey?.$key || index;
  }

  public filterQuestions(questions, question) {
    const matchQuestionTypes: (a: string, b: string) => boolean = (target, item) => {
      if (
        target === Questions.SLIDER_2D ||
        target === Questions.SLIDER_1R ||
        item === Questions.SLIDER_2D ||
        item === Questions.SLIDER_1R ||
        (target.split('-')[0].replace('free', 'input') === 'input' && target !== Questions.INPUT_DROPDOWN) ||
        (item.split('-')[0].replace('free', 'input') === 'input' && item !== Questions.INPUT_DROPDOWN)
      ) {
        return target === item;
      } else if (
        target.split('-')[0].replace('free', 'input') === 'choice' ||
        item.split('-')[0].replace('free', 'input') === 'choice'
      ) {
        return (
          target.split('-')[0].replace('free', 'input') === item.split('-')[0].replace('free', 'input') ||
          item === Questions.INPUT_DROPDOWN ||
          target === Questions.INPUT_DROPDOWN
        );
      } else {
        return target.split('-')[0].replace('free', 'input') === item.split('-')[0].replace('free', 'input');
      }
    };

    return questions.pipe(map((arr: QuestionData[]) => arr.filter((q) => matchQuestionTypes(question.type, q.type))));
  }

  public selectSurvey(survey: SurveyIndex): void {
    if (survey?.$key) {
      this.surveySelectionDone = false;
      this.selectedSurvey = this.sm.connectSurvey(survey.$key);

      combineLatest(this.selectedSurvey.questions, this.selectedSurvey.outcomes, this.selectedSurvey.locales)
        .pipe(first())
        .subscribe(([questions, outcomes, locales]) => {
          const activeSurveyLanguages = Object.keys(this.activeSurveyLocales?.strings || {});
          const selectedSurveyLanguages = Object.keys(locales?.strings || {});
          const sharedLanguages = activeSurveyLanguages.filter((item) => selectedSurveyLanguages.includes(item));
          this.selectedSurveyUsage = survey.respondents;

          if (sharedLanguages.length > 0) {
            for (const lang of sharedLanguages) {
              this.matchQuestions(
                setQuestionTranslations(questions, locales.strings[lang]),
                setQuestionTranslations(this.activeSurveyQuestions, this.activeSurveyLocales.strings[lang]),
              );
              this.matchOutcomes(
                setOutcomeTranslations(
                  outcomes.filter((item) => item.type !== 'goodbye'),
                  locales.strings[lang],
                ),
                setOutcomeTranslations(this.activeSurveyOutcomes, this.activeSurveyLocales.strings[lang]),
              );
            }
          } else {
            this.matchQuestions(questions, this.activeSurveyQuestions);
            this.matchOutcomes(
              outcomes.filter((item) => item.type !== 'goodbye'),
              this.activeSurveyOutcomes,
            );
          }

          for (let q = 0, len = questions.length; q < len; q++) {
            this.selectedQuestions[questions[q]['$key']] = true;
          }

          this.surveySelectionDone = true;
          this.cdRef.detectChanges();
        });
    }
  }

  public unselectSurvey(): void {
    this.selectedSurvey = null;
    this.matchedQuestions = [];
    this.newQuestions = [];
    this.selectedQuestions = {};
    this.mappings = {};
    delete this.selectedSurveyUsage;

    this.cdRef.detectChanges();
  }

  public selectQuestion(i: string, $event: boolean): void {
    this.selectedQuestions[i] = $event;
  }

  public selectAllQuestions(list: string, $event: boolean): void {
    const questions: QuestionData[] = list === 'new' ? this.newQuestions : this.matchedQuestions;

    for (let q = 0, len = questions.length; q < len; q++) {
      this.selectedQuestions[questions[q]['$key']] = $event;
    }
  }

  public mapQuestion(question: QuestionData, cancel: boolean = false): void {
    if (!cancel) {
      this.mappings[question.$key] = {
        question: this.selectedQuestionManualMerge,
        choices:
          question['choiceList'] && question['choiceList'].length > 0
            ? question['choiceList'].map((choice) => ({
                orig: choice,
                mappedTo: this.selectedQuestionManualMergeChoice[choice['$key']],
              }))
            : [],
        survey: this.activeSurvey,
      };

      const index = this.newQuestions.findIndex((item) => item['$key'] === question['$key']);
      const matchedIndex = this.matchedQuestions.findIndex((item) => item['$key'] === question['$key']);

      if (index >= 0) {
        this.newQuestions.splice(index, 1);
      }

      if (matchedIndex < 0) {
        this.matchedQuestions.push(question);
      }
    }

    this.selectedQuestionManualMerge = null;
    this.selectedQuestionManualMergeChoice = {};
    this.openManualMergeItem = '';
  }

  public editMatchedItem(question: QuestionData): void {
    if (this.mappings[question['$key']]) {
      this.selectedQuestionManualMerge = this.mappings[question['$key']]['question'];

      for (let i = 0, len = this.mappings[question['$key']]['choices'].length; i < len; i++) {
        const orig = this.mappings[question['$key']]['choices'][i]['orig'];
        const mappedTo = this.mappings[question['$key']]['choices'][i]['mappedTo'];

        this.selectedQuestionManualMergeChoice[orig['$key']] = mappedTo;
      }
    }

    this.openManualMergeItem = question['$key'];
    this.cdRef.detectChanges();
  }

  public clearMapping(question: QuestionData): void {
    delete this.mappings[question['$key']];

    const index = this.newQuestions.findIndex((item) => item['$key'] === question['$key']);
    const matchedIndex = this.matchedQuestions.findIndex((item) => item['$key'] === question['$key']);

    if (index < 0) {
      this.newQuestions.push(question);
    }

    if (matchedIndex >= 0) {
      this.matchedQuestions.splice(matchedIndex, 1);
    }

    this.selectedQuestionManualMerge = null;
    this.selectedQuestionManualMergeChoice = {};
    this.openManualMergeItem = '';
  }

  public checkIfMapped(question: QuestionData): boolean {
    for (const i in this.mappings) {
      if (this.mappings[i]['question'] && this.mappings[i]['question']['$key'] === question['$key']) {
        return true;
      }
    }
    return false;
  }

  public doMerge(): void {
    this.cf.mergeSurvey(
      this.selectedSurvey.key,
      Object.keys(this.selectedQuestions).filter((q) => this.selectedQuestions[q]),
      this.mappings,
    );
    this.mergeDone = true;
  }

  matchQuestions(a: QuestionData[], b: QuestionData[]): void {
    for (let q = 0, lenq = a.length; q < lenq; q++) {
      const matchedKeys = this.matchedQuestions.map((item) => item.$key);
      const newKeys = this.newQuestions.map((item) => item.$key);

      const similarItems = b
        .filter((item) => item.type === a[q].type)
        .filter((item) => this.checkQuestionMatch(a[q], item, a, b));

      if (similarItems && similarItems.length === 1) {
        if (!matchedKeys.includes(a[q]['$key'])) {
          this.matchedQuestions.push(a[q]);
        }
        if (newKeys.includes(a[q]['$key'])) {
          const newListIndex = this.newQuestions.findIndex((item) => item.$key === a[q]['$key']);

          if (newListIndex >= 0) {
            this.newQuestions.splice(newListIndex, 1);
          }
        }

        this.mappings[a[q]['$key']] = {
          question: similarItems[0],
          choices: this.matchChoices(a[q]['choiceList'], similarItems[0]['choiceList']),
          survey: this.activeSurvey,
        };
      } else {
        if (!matchedKeys.includes(a[q]['$key']) && !newKeys.includes(a[q]['$key'])) {
          this.newQuestions.push(a[q]);
        }
      }
    }
  }

  checkQuestionMatch(a: QuestionData, b: QuestionData, aList: QuestionData[], bList: QuestionData[]): boolean {
    const cleanedText = (str) => (str || '').toLowerCase().replace(/\s+/g, '');

    if (cleanedText(a.title) === cleanedText(b.title)) {
      if (a.type.indexOf(Questions.CHOICE) >= 0 || a.type === Questions.INPUT_DROPDOWN) {
        for (let c = 0, lenc = (a.choiceList || []).length; c < lenc; c++) {
          if (
            (b.choiceList || [])
              .map((item) => cleanedText(item.content))
              .indexOf(cleanedText(a.choiceList[c].content)) < 0
          ) {
            return false;
          }
        }
        return true;
      } else if (a.type === Questions.SLIDER_2D || a.type === Questions.SLIDER_1D || a.type === Questions.SLIDER_1V) {
        return this.dp.generateGroupId(a, aList) === this.dp.generateGroupId(b, bList);
      } else if (a.type === Questions.SLIDER_NPS || a.type === Questions.SLIDER_1R) {
        const comparison = (ac, bc) =>
          ac.labels.axis === bc.labels.axis &&
          ac.labels.max === bc.labels.max &&
          ac.labels.min === bc.labels.min &&
          ac.values.max === bc.values.max &&
          ac.values.min === bc.values.min;
        return comparison(this.dp.parseSliderData(aList, a), this.dp.parseSliderData(bList, b));
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  matchChoices(a: ChoiceItemData[] = [], b: ChoiceItemData[] = []): { [key: string]: ChoiceItemData }[] {
    const cleanedText = (str) => (str || '').toLowerCase().replace(/\s+/g, '');
    return a.map((aitem) => ({
      orig: aitem,
      mappedTo: b.find((bitem) => cleanedText(bitem.content) === cleanedText(aitem.content)),
    }));
  }

  matchOutcomes(a: OutcomeData[] = [], b: OutcomeData[] = []) {
    const cleanedText = (str) => (str || '').toLowerCase().replace(/\s+/g, '');

    if (!this.mappings['outcomes']) {
      this.mappings['outcomes'] = {
        outcomes: [],
        survey: this.activeSurvey,
      };
    }

    for (let o = 0, leno = a.length; o < leno; o++) {
      const currIndex = this.mappings['outcomes']['outcomes'].findIndex((item) => item.orig?.$key === a[o]['$key']);
      if (currIndex >= 0) {
        if (this.mappings['outcomes']['outcomes'][currIndex]['mappedTo']['$key'] === 'new') {
          this.mappings['outcomes']['outcomes'][currIndex]['mappedTo'] = b.find(
            (bitem) => cleanedText(bitem.title) === cleanedText(a[o].title),
          ) || {
            $key: 'new',
            title: 'Add as new item',
            manualMerge: true,
          };
        }
      } else {
        this.mappings['outcomes']['outcomes'].push({
          orig: a[o],
          mappedTo: b.find((bitem) => cleanedText(bitem.title) === cleanedText(a[o].title)) || {
            $key: 'new',
            title: 'Add as new item',
            manualMerge: true,
          },
        });
      }
    }
  }
}

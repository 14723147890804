import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { Store } from '@ngxs/store';

import { LocalStorage } from 'ngx-webstorage';

import { PlanId } from '@shared/models/plan.model';

import { PlanCustomization, PlanInfo, PlanOption, PlanPayment } from '@plans/shared/models/plans.model';

import { ZefApi } from '@shared/services/zef-api.service';

@Injectable({
  providedIn: 'root',
})
export class PlansApi {
  @LocalStorage('prefs') accountPrefs: any | undefined;

  constructor(@Inject(LOCALE_ID) readonly locale: string, readonly store: Store, readonly za: ZefApi) {}

  public getPlans(): Observable<PlanInfo[]> {
    const locale = this.locale.split('-')[0] || 'en';

    return this.za.get<PlanInfo[]>('plans/list', { locale }, false).pipe(
      map((plans) => plans.sort((a, b) => a.priceMonthly - b.priceMonthly)),
      catchError(() => of([])),
    );
  }

  public getOptions(): Observable<PlanOption[]> {
    return this.za.get<PlanOption[]>('plans/options', void 0, false).pipe(catchError(() => of([])));
  }

  public getPaymentPlan(
    plan: PlanId,
    period: 'monthly' | 'yearly',
    options: PlanCustomization[],
  ): Observable<PlanPayment> {
    if (plan === 'trial_plan') {
      plan = 'plan_master';
    }

    return this.za.post<PlanPayment>('plans/payment_plan', { plan, period, options });
  }
}

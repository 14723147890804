import { BehaviorSubject, takeUntil } from 'rxjs';

import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  OnChanges,
  OnInit,
  Input,
  Output,
  HostListener,
  SimpleChanges,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { Questions } from '@shared/enums/questions.enum';
import { rotateAnimation } from '@shared/animations/rotate.anim';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { Colors } from '@report/shared/enums/colors.enum';
import {
  ChartDistribution,
  ChartDomain,
  ChartStats,
  DimensionDataItem,
  SummaryChartData,
  SummaryChartDataChild,
  ChartSizeInput,
  SummaryChartDataSliderDetails,
  SummaryChartDataGroup,
  ChartSettings,
} from '@shared/models/report.model';

import * as d3 from 'd3';

/**
 * This is a Summary 1d chart component.
 */
/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'summary-1d',
  templateUrl: './summary-1d.component.html',
  styleUrls: ['./summary-1d.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    rotateAnimation,
    trigger('expand', [
      state('void', style({ width: '0' })),
      state('*', style({ width: '*' })),
      transition(':enter, :leave', animate('200ms ease-in-out')),
    ]),
    trigger('parent', [transition(':enter', [])]),
  ],
})
export class Summary1D implements OnChanges, OnInit {
  @Input() distribution: ChartDistribution[] = [];
  @Input() stats: ChartStats[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() showAverages: boolean = false;
  @Input() zvalues: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() comparisonMode: any;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() anonymityTreshold: number = null;

  @Input() chartSettings: ChartSettings = {} as ChartSettings;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  public updateQuestionList$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public searchTerms: { [s: string]: string } = {};
  public sortKeys: { [s: string]: string } = {};
  public sortDirections: { [s: string]: string } = {};
  public selectedQuestions: { [s: string]: string[] } = {};
  public hoveredFromChart: { [s: string]: string[] } = {};
  public showGroupAverages: { [s: string]: boolean } = {};
  public questionGroups: { [s: string]: { [s: string]: SummaryChartDataChild[] } } = {};
  public questionCount: { [s: string]: number } = {};

  public legends: any[] = [];
  public totalQuestionCount: number = null;

  public flexMode: string = 'row';
  public isJoinedComparison: boolean = false;
  public isGroupedComparison: boolean = false;
  public comparisonGroupHover: string = '';
  public exportChart: boolean = false;

  public chartData: SummaryChartData[] = [];
  public highlighted: string[] = [];
  public size: ChartSizeInput = {} as ChartSizeInput;
  public rowHeight: number;

  private axisGroups: any;
  private margin: any = { top: 50, right: 50, bottom: 50, left: 50 };
  private width: number = 0;
  private fontSize: number = 0;

  @HostListener('window:resize') resize() {
    this.updateChart();
  }

  constructor(private _element: ElementRef, private cdRef: ChangeDetectorRef, readonly hooks: LifecycleHooks) {}

  ngOnChanges(changes: SimpleChanges) {
    this.exportChart = this.hasParentClass(this._element.nativeElement, 'export-chart');
    if (changes.chartSettings && changes.chartSettings.firstChange && this.chartSettings?.summaryChartQuestionList) {
      this.sortKeys = this.chartSettings.summaryChartQuestionList.sortKeys;
      this.sortDirections = this.chartSettings.summaryChartQuestionList.sortDirections;
      this.selectedQuestions = this.chartSettings.summaryChartQuestionList.selectedQuestions;
      this.showGroupAverages = this.chartSettings.summaryChartQuestionList.showGroupAverages;
    }

    if (
      changes.distribution ||
      changes.stats ||
      changes.domain ||
      changes.details ||
      changes.filterInput ||
      changes.zvalues ||
      changes.update ||
      changes.comparison ||
      changes.comparisonMode ||
      changes.filtersDemo
    ) {
      this.updateChart();
    }
  }

  ngOnInit() {
    this.updateQuestionList$.pipe(takeUntil(this.hooks.destroy)).subscribe((update) => {
      const groupSeparator: string = '\u001D';
      const rawArray = this.chartData.slice();

      this.totalQuestionCount = Array.from(
        new Set(
          this.chartData
            .map((chart) => chart.children)
            .reduce((a, b) => a?.concat(b), [])
            .map((dim) => dim.key.split(':')[0]),
        ),
      ).length;

      for (const chart of rawArray) {
        if (!this.sortKeys[chart.id]) {
          this.sortKeys[chart.id] = 'survey';
        }
        if (!this.sortDirections[chart.id]) {
          this.sortDirections[chart.id] = this.sortKeys[chart.id] === 'survey' ? 'asc' : 'desc';
        }
        if (!this.hoveredFromChart[chart.id]) {
          this.hoveredFromChart[chart.id] = [];
        }

        const sortKey = this.sortKeys[chart.id];
        const sortVal =
          sortKey === 'average'
            ? this.zvalues
              ? 'zAverage'
              : 'average'
            : sortKey === 'averageY'
            ? this.zvalues
              ? 'zAverageY'
              : 'averageY'
            : '';
        const sortDirection = this.sortDirections[chart.id];
        const searchTerm = this.searchTerms[chart.id];
        const hoveredFromChart = this.hoveredFromChart[chart.id];
        const selectedQuestions =
          this.selectedQuestions[chart.id] && this.selectedQuestions[chart.id].slice().reverse();
        const rawList = chart.children.slice();

        let filteredList: SummaryChartDataChild[] = rawList;

        if (searchTerm) {
          const filteredResults = filteredList.filter((col) =>
            col.title.toLowerCase().includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        if (this.showGroupAverages[chart.id]) {
          filteredList = filteredList.filter((item) => !item.questionGroup || item.isGroupAverage);
        }

        if (
          !this.showGroupAverages[chart.id] &&
          !this.isJoinedComparison &&
          (this.sortKeys[chart.id] !== 'survey' || this.sortDirections[chart.id] !== 'asc')
        ) {
          filteredList = filteredList.filter((item) => !item.isGroupAverage);
        }

        let sortedList = !this.isJoinedComparison
          ? sortKey === 'survey'
            ? sortDirection === 'desc'
              ? filteredList.reverse()
              : filteredList
            : filteredList.sort((a, b) => {
                if (a[sortVal] > b[sortVal]) {
                  return sortDirection === 'asc' ? 1 : -1;
                }
                if (a[sortVal] < b[sortVal]) {
                  return sortDirection === 'asc' ? -1 : 1;
                }

                return 0;
              })
          : filteredList;

        if (selectedQuestions && selectedQuestions.length) {
          sortedList = sortedList.sort((a, b) =>
            selectedQuestions.indexOf(a.key) >= 0 && selectedQuestions.indexOf(b.key) < 0 ? -1 : 1,
          );
        }

        const finalList = [];
        const idList = [];

        for (let i = 0, len = sortedList.length; i < len; i++) {
          const key = !sortedList[i]['isGroupAverage']
            ? sortedList[i]['key']
            : sortedList[i]['key'].split(groupSeparator)[1];
          const index = idList.indexOf(key);
          if (index < 0) {
            finalList.push([sortedList[i]]);
            idList.push(key);
          } else {
            finalList[index].push(sortedList[i]);
          }
        }

        chart.visibleQuestionList = finalList;
      }

      const minHeight =
        !!Object.values(this.sortKeys).find((sortkey) => sortkey !== 'survey') && !this.isJoinedComparison ? 72 : 48;
      const aH = this._element.nativeElement.clientHeight;
      const chartCount = this.chartData.length;
      const selectedQuestionsCounts: number[] = rawArray.map(
        (chart) =>
          Array.from(
            new Set(
              this.selectedQuestions?.[chart.id]
                ?.filter((item) => !this.showGroupAverages?.[chart.id] || item.indexOf(groupSeparator) >= 0)
                .map((item) => (item.indexOf(groupSeparator) >= 0 ? item.split(groupSeparator)[1] : item)),
            ),
          ).length,
      );
      const totalSelectedQuestionsCount: number = selectedQuestionsCounts.reduce((a, b) => a + b, 0);
      const legendAddition: number =
        this.isJoinedComparison &&
        this.comparison.values.length > 3 &&
        this._element.nativeElement.getElementsByClassName('legends') &&
        this._element.nativeElement.getElementsByClassName('legends')[0]
          ? this._element.nativeElement.getElementsByClassName('legends')[0].offsetHeight - 32
          : 0;
      const unfilteredQuestionCounts: number[] = rawArray.map(
        (chart) =>
          Array.from(
            new Set(
              chart.children
                .filter((item) => !this.showGroupAverages[chart.id] || !item.questionGroup || item.isGroupAverage)
                .filter(
                  (item) =>
                    !(
                      !this.showGroupAverages[chart.id] &&
                      !this.isJoinedComparison &&
                      (this.sortKeys[chart.id] !== 'survey' || this.sortDirections[chart.id] !== 'asc')
                    ) || !item.isGroupAverage,
                )
                .map((item) => (!item['isGroupAverage'] ? item['key'] : item['key'].split(groupSeparator)[1])),
            ),
          ).length,
      );
      const totalUnfilteredQuestionCount: number = unfilteredQuestionCounts.reduce((a, b) => a + b, 0);
      const availableExportHeight: number =
        360 -
        (this.isJoinedComparison ? Math.ceil(this.comparison.values.length / 2) * 20 : 0) -
        (this.isGroupedComparison ? 20 : 0);

      this.rowHeight = !this.exportChart
        ? Math.floor(
            Math.min(
              Math.max(
                ((this.flexMode === 'row' ? 1 : 0.7) *
                  (aH -
                    chartCount * (40 + (this.fontSize === 16 ? 53 : 48) + legendAddition) -
                    totalSelectedQuestionsCount * 8)) /
                  totalUnfilteredQuestionCount,
                minHeight,
              ),
              160,
            ),
          )
        : Math.min(Math.max(34, availableExportHeight / Math.max(...unfilteredQuestionCounts, 1)), 56);

      // Calculating fixed height for chart container in charts with multiple scales
      if (rawArray.length > 1) {
        const helperStuffHeight: number = (chartCount * (40 + (this.fontSize === 16 ? 53 : 48))) / aH;

        for (let i = 0, len = rawArray.length; i < len; i++) {
          rawArray[i].heightPercentage = unfilteredQuestionCounts[i]
            ? (unfilteredQuestionCounts[i] / totalUnfilteredQuestionCount) * (1 - helperStuffHeight) * 100 +
              (helperStuffHeight * 100) / len -
              (unfilteredQuestionCounts[i] / totalUnfilteredQuestionCount) *
                ((totalSelectedQuestionsCount * 8) / aH) *
                100 +
              ((selectedQuestionsCounts[i] * 8) / aH) * 100
            : (1 / len) * 100;
        }
      }

      this.update = new Date();
    });
  }

  updateChart() {
    this.isJoinedComparison =
      this.comparison && this.comparison.values && this.comparison.values.length && this.comparisonMode === 'joined';
    this.isGroupedComparison =
      this.comparison && this.comparison.values && this.comparison.values.length && this.comparisonMode !== 'joined';
    this.setQuestions();
    this.setSizes();
    this.setData();
    this.setQuestionGroups();
    this.updateQuestionList$.next('update');
  }

  /**
   * Parsing questions from data by connecting dimension pairs together.
   */
  setQuestions() {
    this.axisGroups = {};

    // const naturalPairs = {};
    for (let i = 0; i < this.details.length; i++) {
      const item = i.toString();
      const question = [item];
      if (
        this.details[item].originalType === Questions.SLIDER_1D ||
        this.details[item].originalType === Questions.SLIDER_1V ||
        this.details[item].originalType === Questions.SLIDER_NPS ||
        this.details[item].originalType === Questions.SLIDER_2D ||
        this.details[item].originalType === Questions.SLIDER_1R ||
        this.details[item].originalType === Questions.INPUT_NUMERIC ||
        this.details[item].originalTypeSpecifier === 'text-sentiment' ||
        this.details[item].scale === 'linear'
      ) {
        const group = this.details[item].group;
        const groupId = this.parseGroupId(this.details[item]);

        if (!this.axisGroups[groupId]) {
          this.axisGroups[groupId] = { groups: new Set(), questions: [] };
        }

        question.push(group, groupId);

        this.axisGroups[groupId]['groups'].add(group);
        this.axisGroups[groupId]['questions'].push(question);
      }
    }
  }

  /**
   * Setting sizes for 2Ds.
   */
  setSizes() {
    const aW = this._element.nativeElement.clientWidth;
    const aH = this._element.nativeElement.clientHeight;

    if (aW < 400) {
      this.flexMode = 'column';
    } else {
      this.flexMode = 'row';
    }

    const size = {
      width: Math.min(
        aW * (this.flexMode === 'row' ? 0.7 : 1) - (this.flexMode === 'row' ? 32 : 0),
        this.flexMode === 'row' && aW * 0.3 > 240 ? aW - 240 : Infinity,
      ),
      height: aH,
    };

    size.height = size.height > 0 ? size.height : 0;

    this.size = size;

    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.margin = {
      top: this.fontSize * 3,
      right: (!this.exportChart ? this.fontSize : 14) * 3,
      bottom: this.fontSize * 3,
      left: (!this.exportChart ? this.fontSize : 14) * 3,
    };

    this.width =
      size.width - this.margin.left - this.margin.right > 0 ? size.width - this.margin.left - this.margin.right : 0;
  }

  /**
   * Setting data for 1Ds.
   */
  setData() {
    const setData = (axisGroup, comparisonGroup = '', comparisonItem = '', cidx = 0) => {
      const children: SummaryChartDataChild[] = [];
      const questions = new Set();
      let id;
      let sliderDetails;
      let group;
      const comparisonDomain = this.domain.find((dom) => dom.key === comparisonGroup);
      const cdi = this.domain.findIndex((dom) => dom.key === comparisonGroup);

      let number = 0;

      for (const q of axisGroup['questions']) {
        const x = q[0];
        const groupId = (comparisonItem && this.comparisonMode !== 'joined' ? `${comparisonItem}: ` : ``) + `${q[2]}`;

        if (this.domain[x] && this.details[x]) {
          const sliderLabels = this.domain[x].labelsLinear;
          const sliderValues = this.details[x].valueScaleLinear;

          const zMethod = axisGroup.groups.size > 1 ? 'globalZ' : 'z';
          const key = this.details[x].key;
          const domIndex = this.domain[x].index;
          const title = this.domain[x].title ? this.domain[x].title : this.details[x].title || '';
          const questionGroup = this.details[x].group;
          const questionGroupLabel = this.domain[x].groupLabel;

          const n = comparisonDomain
            ? this.stats[cdi] &&
              this.stats[cdi]['children'] &&
              this.stats[cdi]['children'][cidx] &&
              this.stats[cdi]['children'][cidx]['children'] &&
              this.stats[cdi]['children'][cidx]['children'][domIndex] &&
              this.stats[cdi]['children'][cidx]['children'][domIndex]['count'] != null
              ? this.stats[cdi]['children'][cidx]['children'][domIndex]['count']
              : null
            : this.stats[x] && this.stats[x]['count'];
          const isUnderAnonymityTreshold: boolean = this.anonymityTreshold && this.anonymityTreshold > n;

          const average = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex]['average'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex]['average']
                : null
              : this.stats[x] && this.stats[x]['average']
            : null;
          const std = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex]['std'] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex]['std']
                : null
              : this.stats[x] && this.stats[x]['std']
            : null;
          const zAverage = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Average`]
                : null
              : this.stats[x] && this.stats[x][`${zMethod}Average`]
            : null;
          const zStd = !isUnderAnonymityTreshold
            ? comparisonDomain
              ? this.stats[cdi] &&
                this.stats[cdi]['children'] &&
                this.stats[cdi]['children'][cidx] &&
                this.stats[cdi]['children'][cidx]['children'] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex] &&
                this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`] != null
                ? this.stats[cdi]['children'][cidx]['children'][domIndex][`${zMethod}Std`]
                : null
              : this.stats[x] && this.stats[x][`${zMethod}Std`]
            : null;

          const dom = [sliderValues.min, sliderValues.max];
          const cx = !isUnderAnonymityTreshold
            ? d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
                this.zvalues ? (zAverage != null ? zAverage : dom[0] + (dom[1] - dom[0]) / 2) : average,
              )
            : null;
          const rx = !isUnderAnonymityTreshold
            ? d3
                .scaleLinear()
                .rangeRound([0, this.width])
                .domain([0, dom[1] - dom[0]])(this.zvalues ? zStd : std) / 2
            : null;

          if (!id) {
            id = (this.comparisonMode === 'joined' ? 'joined' : '') + groupId;
          }
          if (!sliderDetails) {
            sliderDetails = {
              labels: sliderLabels,
              values: sliderValues,
            };
          }
          if (!group) {
            group = {
              key: comparisonItem ? comparisonItem : 'aggregate',
              title:
                comparisonItem && !this.isJoinedComparison
                  ? `${comparisonDomain ? comparisonDomain['labels'][comparisonItem] : ''}`
                  : ``,
              index: comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
            };
          }

          number += 1;

          if (n >= 0 && (!this.zvalues || zAverage != null)) {
            const child: SummaryChartDataChild = {
              key,
              title,
              n: !isUnderAnonymityTreshold ? n : null,
              cx,
              rx,
              domain: dom,
              average,
              std,
              zAverage,
              zStd,
              number,
              group: comparisonItem ? comparisonItem : 'aggregate',
              groupLabel: comparisonDomain ? comparisonDomain['labels'][comparisonItem] : '',
              index: comparisonDomain && comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonItem] : null,
              questionGroup,
              questionGroupLabel,
              isUnderAnonymityTreshold,
            };
            children.push(child);
            questions.add(number + '. ' + title);
          }
        }
      }
      return {
        children,
        id,
        sliderDetails,
        group,
        questions: Array.from(questions) as string[],
      };
    };

    const chartData: SummaryChartData[] = [];

    for (const group in this.axisGroups) {
      const axisGroup = this.axisGroups[group];

      if (
        this.comparison &&
        this.comparison.values.length > 0 &&
        this.details.find((details) => details.key === this.comparison.key) // ensuring details' existence
      ) {
        const comparisonDetails = this.details.find((details) => details.key === this.comparison.key);
        const joinedData: SummaryChartData = {
          children: [] as SummaryChartDataChild[],
          id: '',
          sliderDetails: {} as SummaryChartDataSliderDetails,
          group: {} as SummaryChartDataGroup,
          questions: [] as string[],
        };
        const joinedQuestions = new Set();

        if (comparisonDetails) {
          for (let c = 0, len = this.comparison.values.length; c < len; c++) {
            if (comparisonDetails['values'][this.comparison.values[c]]) {
              const comparisonItem = comparisonDetails['values'][this.comparison.values[c]].toString();
              const comparisonValue = this.comparison.values[c];
              const comparisonData: SummaryChartData = setData(
                axisGroup,
                this.comparison.key,
                comparisonItem,
                comparisonValue,
              );

              if (this.comparisonMode && this.isJoinedComparison) {
                joinedData.children = joinedData.children.concat(comparisonData.children);
                joinedData.id = comparisonData.id;
                joinedData.sliderDetails = comparisonData.sliderDetails;
                joinedData.group = comparisonData.group;
                (comparisonData.questions || []).forEach(joinedQuestions.add, joinedQuestions);
              } else {
                chartData.push(comparisonData);
              }
            }
          }

          if (this.comparisonMode && this.isJoinedComparison) {
            joinedData.questions = Array.from(joinedQuestions).sort(
              (a: string, b: string) => Number(a.split('.')[0]) - Number(b.split('.')[0]),
            ) as string[];
            chartData.push(joinedData);
          }
        }
      } else {
        chartData.push(setData(axisGroup));
      }
    }

    if (this.chartData.length === 0) {
      this.chartData = chartData;
    } else {
      const orderChanges = () => {
        const oldArr = this.chartData.slice().map((item) => item.id);
        const newArr = chartData.slice().map((item) => item.id);

        if (oldArr.length === newArr.length) {
          for (let o = 0; o < oldArr.length; o++) {
            if (oldArr[o] !== newArr[o]) {
              return true;
            }
          }
          return false;
        } else {
          return true;
        }
      };

      if (orderChanges()) {
        this.chartData = chartData;
      } else {
        for (const item in chartData) {
          const index = this.chartData.findIndex((test) => test.id === chartData[item].id);

          if (index > -1) {
            this.chartData[index]['group'] = chartData[item]['group'];
            this.chartData[index]['id'] = chartData[item]['id'];
            this.chartData[index]['children'] = chartData[item]['children'];
            this.chartData[index]['questions'] = chartData[item]['questions'];
            this.chartData[index]['sliderDetails'] = chartData[item]['sliderDetails'];
          } else {
            this.chartData.push(chartData[item]);
          }
        }
        // removing old items
        let i = this.chartData.length;
        while (i--) {
          const index = chartData.findIndex((test) => test.id === this.chartData[i].id);

          if (index === -1) {
            this.chartData.splice(i, 1);
          }
        }
      }
    }

    const legendDom: ChartDomain =
      this.isJoinedComparison &&
      this.comparison &&
      this.comparison.key &&
      this.domain &&
      this.domain.find((dom) => dom.key === this.comparison.key);
    this.legends = legendDom?.keys?.map((key, keyIndex) => {
      const statItem = ((this.stats && this.stats[1] && this.stats[1]['children']) || []).find(
        (item) => item && item.key === key,
      );
      return {
        key,
        label: legendDom.labels[key],
        color: Colors.getComparisonColor(legendDom.colors[key] != null ? legendDom.colors[key] : keyIndex),
      };
    });
  }

  private setQuestionGroups(): void {
    const groupSeparator: string = '\u001D';

    for (let c = 0, lenc = this.chartData.length; c < lenc; c++) {
      const chartId = this.chartData[c]?.['id'];
      this.questionCount[chartId] = Array.from(
        new Set(this.chartData[c]?.children?.map((cItem) => cItem?.key)?.reduce((a, b) => a?.concat(b), []) || []),
      )?.length;
    }

    this.questionGroups = {};

    if (Math.max(...Object.values(this.questionCount)) > 1) {
      for (let ic = 0, lenc = this.chartData.length; ic < lenc; ic++) {
        const chartId = this.chartData[ic]['id'];
        const rawList = this.chartData[ic].children;

        this.questionGroups[chartId] = {};
        const uniqueQuestionGroups: string[] = [];

        for (let i = 0, len = rawList.length; i < len; i++) {
          const questionGroup = rawList[i]['questionGroup'];
          const group = rawList[i]['group'] + groupSeparator + questionGroup;
          const question = rawList[i];

          if (questionGroup && question) {
            if (!this.questionGroups[chartId][group]) {
              this.questionGroups[chartId][group] = [];
            }
            this.questionGroups[chartId][group].push(question);
          }
          if (!uniqueQuestionGroups.includes(questionGroup)) {
            uniqueQuestionGroups.push(questionGroup);
          }
        }
        const showGroupAverages: boolean =
          Object.values(this.questionGroups[chartId])
            .map((arr) => arr.length)
            .filter((len) => len > 1).length > 0;

        if (showGroupAverages) {
          const groupCount: number = uniqueQuestionGroups.length;

          for (const qg in this.questionGroups[chartId]) {
            const questions: SummaryChartDataChild[] = this.questionGroups[chartId][qg];
            const n: number = questions.reduce((a, b) => a + b.n, 0);
            const isUnderAnonymityTreshold: boolean = this.anonymityTreshold && this.anonymityTreshold > n;
            const newIndex: number = rawList.findIndex((item) => item.questionGroup === qg.split(groupSeparator)[1]);
            const indexInAllGroups = Object.keys(this.questionGroups[chartId])
              .map((key) => key.split(groupSeparator)[1])
              .map((groupItem) => rawList.findIndex((item) => item.questionGroup === groupItem))
              .indexOf(newIndex);
            const reducedStats: { [s: string]: number } = questions.reduce(
              (a, b) => ({
                average: a.average + b.average * b.n,
                std: a.std + Math.pow(b.std, 2),
                zAverage: a.zAverage + b.zAverage * b.n,
                zStd: a.zStd + Math.pow(b.zStd, 2),
              }),
              {
                average: 0,
                std: 0,
                zAverage: 0,
                zStd: 0,
              },
            );
            const dom: number[] = questions[0]?.['domain'];
            const domY: number[] = questions[0]?.['domainY'];
            const average: number = reducedStats.average / n;
            const std: number = Math.sqrt(reducedStats.std / questions.length);
            const zAverage: number =
              groupCount > 1 && !isNaN(reducedStats.zAverage / n)
                ? reducedStats.zAverage / n
                : dom[0] + (dom[1] - dom[0]) / 2;
            const zStd: number = Math.sqrt(reducedStats.zStd / questions.length);
            const cx: number = d3.scaleLinear().rangeRound([0, this.width]).domain(dom)(
              this.zvalues ? zAverage : average,
            );
            const rx: number =
              d3
                .scaleLinear()
                .rangeRound([0, this.width])
                .domain([0, dom[1] - dom[0]])(this.zvalues ? zStd : std) / 2;

            const questionGroupItem: SummaryChartDataChild = {
              key: qg,
              title: questions[0]?.['questionGroupLabel'] || '',
              n,
              cx,
              rx,
              domain: dom,
              domainY: domY,
              average,
              std,
              zAverage,
              zStd,
              number: null,
              numberAlphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[indexInAllGroups] || '.',
              group: questions[0]?.['group'],
              groupLabel: questions[0]?.['groupLabel'],
              index: questions[0]?.['index'],
              questionGroup: '',
              questionGroupLabel: '',
              isGroupAverage: true,
              isUnderAnonymityTreshold,
            };
            const existingGroupIndex: number = rawList.findIndex((item) => item.key === questionGroupItem['key']);
            if (existingGroupIndex < 0) {
              rawList.splice(newIndex, 0, questionGroupItem);
            } else {
              rawList[existingGroupIndex] = questionGroupItem;
            }
          }
        }
      }
    }
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      summaryChartQuestionList: {
        sortKeys: this.sortKeys,
        sortDirections: this.sortDirections,
        selectedQuestions: this.selectedQuestions,
        showGroupAverages: this.showGroupAverages,
      },
    });
  }

  public onLegendHover($event: string): void {
    this.comparisonGroupHover = $event || '';
    this.cdRef.detectChanges();
  }

  public onSearchUpdate(searchTerm, id: string): void {
    this.searchTerms[id] = searchTerm;
    this.updateQuestionList$.next('search');
  }

  public onSortColumn(column: string, id: string): void {
    if (column !== 'survey') {
      this.sortDirections[id] = 'desc';
    } else {
      this.sortDirections[id] = 'asc';
    }

    this.sortKeys[id] = column;
    this.updateQuestionList$.next('sort column changed');
    this.settingsChanged();
  }

  public onSortDir(id: string): void {
    this.sortDirections[id] = this.sortDirections[id] === 'asc' ? 'desc' : 'asc';
    this.updateQuestionList$.next('sort dir changed');
    this.settingsChanged();
  }

  public hoverFromChart($event: string[], id: string): void {
    if ($event && $event.length > 0) {
      const groupSeparator: string = '\u001D';
      const comparisonFriends =
        $event.findIndex((item) => item.indexOf(groupSeparator) >= 0) >= 0
          ? this.chartData[this.chartData.findIndex((item) => item.id === id)]?.children
              ?.map((item) => item.key)
              ?.filter(
                (item) =>
                  $event
                    .map((selectedItem) => selectedItem.split(groupSeparator)[1])
                    .indexOf(item.split(groupSeparator)[1] || item) >= 0,
              )
          : [];
      const questionList = Array.from(new Set([...$event, ...comparisonFriends]));

      if (!this.hoveredFromChart[id]) {
        this.hoveredFromChart[id] = [];
      }

      if (questionList && this.hoveredFromChart[id] && questionList.join() !== this.hoveredFromChart[id].join()) {
        if (questionList && questionList.length > 0) {
          this.hoveredFromChart[id] = questionList;
        } else if (this.hoveredFromChart[id].length > 0) {
          this.hoveredFromChart[id] = [];
        }
        this.cdRef.detectChanges();
      }
    } else {
      this.hoveredFromChart[id] = [];
      this.cdRef.detectChanges();
    }
  }

  public selectQuestions(questions: string[] = [], id, isGroup?: boolean): void {
    const questionList = Array.from(
      new Set(!isGroup ? questions : [...questions, ...this.questionGroups[id][questions[0]]?.map((item) => item.key)]),
    );

    if (!this.selectedQuestions[id]) {
      this.selectedQuestions[id] = [];
    }

    for (const question of questionList || []) {
      if (this.selectedQuestions[id].indexOf(question) < 0) {
        this.selectedQuestions[id].push(question);
      } else {
        this.selectedQuestions[id].splice(this.selectedQuestions[id].indexOf(question), 1);
      }
    }

    this.updateQuestionList$.next('selected question');
    this.settingsChanged();
  }

  public toggleGroupAverages(chartId: string): void {
    const previousState: boolean = !!this.showGroupAverages[chartId];
    this.showGroupAverages[chartId] = !previousState;
    this.selectedQuestions[chartId] = [];
    this.updateQuestionList$.next('toggled group averages');
    this.settingsChanged();
  }

  public fitsToRow(container, hover): boolean {
    const textContainer =
      container.getElementsByClassName('question-text') && container.getElementsByClassName('question-text')[0];
    const textContainerHeight = (textContainer && textContainer.scrollHeight) || 0;

    return textContainerHeight <= this.rowHeight - 16 - (hover && !this.isJoinedComparison ? 16 : 0);
  }

  public colorScale(index): string {
    if (this.comparison && this.comparison.values.length > 0) {
      return Colors.getComparisonColor(index);
    } else {
      return Colors.TEXT;
    }
  }

  public isFound(arr, item): boolean {
    return !!arr && !!arr.length && !!item && arr.indexOf(item) >= 0;
  }

  public countFoundSelectedItems(arr, selectedArr): number {
    const mappedArr: string[] = (arr || []).map((item) => item[0].key);
    return (selectedArr || []).filter((item) => mappedArr.indexOf(item) >= 0).length;
  }

  public mapValues(arr: SummaryChartDataChild[] = [], property: string = ''): string[] {
    return arr.map((item) => item[property]);
  }

  public hasOnlyAnonymousQuestions(questions): boolean {
    return !questions.some((q) => !q?.isUnderAnonymityTreshold);
  }

  trackByKey(i: number, item: any[]): number {
    return (item && item[0] && item[0]['key']) || null;
  }

  trackById(i: number, item: any[]): number {
    return (item && item['id']) || null;
  }

  hasParentClass(element, classname): boolean {
    if (element.className && element.className.split(' ').indexOf(classname) >= 0) {
      return true;
    }

    return !!element.parentNode && this.hasParentClass(element.parentNode, classname);
  }

  /**
   * Parsing group ids from axis details.
   *
   * @param detailsX   Dimension details for axis x.
   * @param detailsY  Dimension details for axis x.
   * @returns        Parsed group id.
   */
  parseGroupId(details: DimensionDataItem): string {
    return `${details.labelsLinear ? details.labelsLinear.axis : ''}: ${
      details.labelsLinear ? details.labelsLinear.min : ''
    } - ${details.labelsLinear ? details.labelsLinear.max : ''} (${
      details.valueScaleLinear ? details.valueScaleLinear.min : ''
    }-${details.valueScaleLinear ? details.valueScaleLinear.max : ''})`;
  }
}

/**
 * Common module for the player application.
 *
 * @stable
 */

import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule, Injectable } from '@angular/core';

import { CardScroll } from '@player/shared/components/card-scroll/card-scroll.component';
import { SurveyScroller } from '@player/shared/directives/survey-scroller.directive';

import { SurveyView } from '@player/+survey/survey.component';
import { DrawerMenu } from '@player/+survey/drawer/drawer-menu.component';
import { WelcomeCard } from '@player/+survey/welcome/welcome-card.component';
import { OutcomeCard } from '@player/+survey/outcome/outcome-card.component';
import { QuestionCard } from '@player/+survey/question/question-card.component';
import { SliderAnswer } from '@player/shared/pipes/slider-answer.pipe';
import { LabelOverflow } from '@player/shared/directives/label-overflow.directive';
import { FunnelOne } from '@player/shared/components/funnel-one/funnel-one.component';
import { FunnelTwo } from '@player/shared/components/funnel-two/funnel-two.component';
import { Funnel } from '@player/+survey/funnel/funnel.component';
import { Sheet } from '@player/shared/components/sheet/sheet.component';

import { Slider1D } from '@player/+survey/question/slider-1d/slider-1d.component';
import { Slider1R } from '@player/+survey/question/slider-1r/slider-1r.component';
import { Slider2D } from '@player/+survey/question/slider-2d/slider-2d.component';
import { SliderNps } from '@player/+survey/question/slider-nps/slider-nps.component';

import { FileUpload } from '@player/+survey/question/file-upload/file-upload.component';

import { OutcomesScrollbar } from '@player/+survey/outcomes/outcomes-scrollbar.component';
import { QuestionsScrollbar } from '@player/+survey/questions/questions-scrollbar.component';

import { PromoText } from '@player/shared/components/promo-text/promo-text.component';
import { SliderLabel } from '@player/shared/components/slider-label/slider-label.component';
import { SliderThumb } from '@player/shared/components/slider-thumb/slider-thumb.component';
import { SliderImages } from '@player/shared/components/slider-images/slider-images.component';
import { ShareButtons } from '@player/shared/components/share-buttons/share-buttons.component';

import { InputInfo } from '@player/+survey/question/input-info/input-info.component';
import { InputSimple } from '@player/+survey/question/input-simple/input-simple.component';
import { InputPhone } from '@player/+survey/question/input-phone/input-phone.component';
import { InputOther } from '@player/+survey/question/input-other/input-other.component';
import { InputChoice } from '@player/+survey/question/input-choice/input-choice.component';
import { InputCheckbox } from '@player/+survey/question/input-checkbox/input-checkbox.component';
import { InputDropdown } from '@player/+survey/question/input-dropdown/input-dropdown.component';
import { LanguageSelect } from '@player/+survey/drawer/language-select/language-select.component';
import { OptionsDropdown } from '@player/shared/components/options-dropdown/options-dropdown.component';

import { BasicModule } from '@shared/modules/basic.module';
import { SharedModuleForPlayer } from '@player/shared/modules/shared.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CommonModule } from '@angular/common';
import { InputChoicePicture } from '@player/+survey/question/input-choice-picture/input-choice-picture.component';
import { PictureImage } from '@player/shared/components/picture-image/picture-image.component';
import { HammerModule, HammerGestureConfig } from '@angular/platform-browser';
import { PictureZoom } from '@player/shared/directives/picture-zoom.directive';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {};
}

@NgModule({
  declarations: [
    SurveyView,

    DrawerMenu,

    WelcomeCard,

    OutcomeCard,
    QuestionCard,
    Funnel,
    Sheet,

    Slider1D,
    Slider1R,
    Slider2D,
    SliderNps,

    InputSimple,
    InputInfo,
    InputPhone,
    InputOther,
    InputChoice,
    InputCheckbox,
    InputDropdown,
    InputChoicePicture,

    FileUpload,

    PromoText,
    FunnelOne,
    FunnelTwo,

    SliderLabel,
    SliderThumb,
    ShareButtons,
    SliderImages,
    LanguageSelect,
    OptionsDropdown,
    PictureImage,

    PictureZoom,
    SliderAnswer,
    LabelOverflow,

    OutcomesScrollbar,
    QuestionsScrollbar,

    SurveyScroller,
    CardScroll,
  ],
  exports: [SurveyView],
  imports: [
    CommonModule,
    BasicModule,
    SharedModuleForPlayer,
    NgScrollbarModule.withConfig({
      autoHeightDisabled: true,
      track: 'vertical',
      appearance: 'standard',
      visibility: 'hover',
      trackClickScrollDuration: 0,
    }),

    PortalModule,
    OverlayModule,

    HammerModule,
  ],
  providers: [{ provide: HammerGestureConfig, useClass: HammerConfig }],
})
export class SurveyModule {}

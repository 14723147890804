/**
 * Manages invites listing and sending.
 *
 * @unstable
 */

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Store } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { DialogControl } from '@shared/services/dialog-control.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { AccountState } from '@shared/states/account.state';
import { AuthState } from '@shared/states/auth.state';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvitesManager {
  public onSentInvites = new Subject();

  @LocalStorage('consent') trackingAllowed: boolean | undefined;

  constructor(
    private http: HttpClient,
    private db: DatabaseWrapper,
    private dc: DialogControl,
    private ns: SnackbarService,
    private store: Store,
  ) {}

  public getInvites(hash: string): Observable<InviteData[]> {
    return this.db
      .list<InviteData>(`/invites`, (ref) => ref.orderByChild('hash').equalTo(hash))
      .snapshotChanges()
      .pipe(
        mapListKeys(),
        catchError((error) => of([])),
      );
  }

  findInvites(email: string) {
    return this.db
      .list<InviteData[]>(`/invites`, (ref) => ref.orderByChild('email').equalTo(email))
      .snapshotChanges()
      .pipe(
        mapListKeys(),
        catchError((error) => of([])),
      );
  }

  public sendInvites(
    emails: string[],
    message: string = '',
    locale: string = '',
    teamKey?: string | null,
  ): Observable<boolean> {
    let url = `https:${environment.apiServer}/invite/send?locale=${locale}`;

    if (teamKey) {
      url = `https:${environment.apiServer}/admin/invite/send/${teamKey}?locale=${locale}`;
    }

    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);
    const token = this.store.selectSnapshot(AuthState.authToken);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Environment', environment.config)
      .set('Active-Team', activeTeamKey || '')
      .set('Firebase-Authorization', token || '');

    return this.http
      .post(
        url,
        { emails, message },
        {
          headers,
          observe: 'response',
          withCredentials: this.trackingAllowed || false,
        },
      )
      .pipe(
        catchError((error: any) => this.handleRequestError(error)),
        tap((response: any) => {
          if (!(response instanceof HttpResponse) || response.status !== 200) {
            const title = $localize`:snackbar notification@@zef-i18n-00037:No invitations sent`;
            this.ns.open(title, { color: 'warning' });
          } else {
            let invitesSent = 0;
            let ssoNotAllowed = 0;
            let ssoUsersAdded = 0;
            let alreadyInTeam = 0;

            for (const status of response.body) {
              if (status.inviteSent) {
                invitesSent++;
              } else if (status.ssoNotAllowed) {
                ssoNotAllowed++;
              } else if (status.ssoUserAdded) {
                ssoUsersAdded++;
              } else if (status.alreadyInTeam) {
                alreadyInTeam++;
              }
            }

            if (invitesSent === 1) {
              const title = $localize`:snackbar notification|one invitation@@zef-i18n-00038:Invitation sent`;
              this.ns.open(title, { color: 'success' });
            } else if (invitesSent) {
              const title = $localize`:snackbar notification|many invitations@@zef-i18n-00039:${emails.length}:INTERPOLATION: invitations sent`;
              this.ns.open(title, { color: 'success' });
            } else if (ssoUsersAdded) {
              const title = $localize`:snackbar notification|SSO users added@@zef-i18n-00039a-sso:SSO users added no invitations sent`;
              this.ns.open(title, { color: 'success' });
            } else if (ssoNotAllowed) {
              const title = $localize`:snackbar notification|SSO team not allowed@@zef-i18n-00039b-sso:SSO users not allowed to be added`;
              this.ns.open(title, { color: 'success' });
            } else if (alreadyInTeam) {
              const title = $localize`:snackbar notification|invitations skipped@@zef-i18n-00039c-sso:All users already in the team`;
              this.ns.open(title, { color: 'success' });
            }
          }

          this.onSentInvites.next({
            teamKey: this.store.selectSnapshot(AccountState.teamKey),
            userKey: this.store.selectSnapshot(AccountState.userKey),
            inviteesCount: emails.length,
          });
        }),
      );
  }

  public cancelInvite(email: string, teamKey?: string): Observable<boolean> {
    let url = `https:${environment.apiServer}/invite/send/${email}`;

    if (teamKey) {
      url = `https:${environment.apiServer}/admin/invite/send/${teamKey}/${email}`;
    }

    const token = this.store.selectSnapshot(AuthState.authToken);

    const activeTeamKey = this.store.selectSnapshot(AccountState.teamKey);

    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Environment', environment.config)
      .set('Active-Team', activeTeamKey || '')
      .set('Firebase-Authorization', token || '');

    return this.http
      .delete(url, {
        headers,
        observe: 'response',
        withCredentials: this.trackingAllowed || false,
      })
      .pipe(
        catchError((error: any) => this.handleRequestError(error)),
        map((response) => response instanceof HttpErrorResponse && response.status === 200),
      );
  }

  private handleRequestError(error: HttpErrorResponse): Observable<string> {
    const message =
      error.error instanceof Error ? error.error.message : error.status || $localize`:@@zef-i18n-00015:Unknown Error`;

    this.dc.openErrorDialog($localize`:@@zef-i18n-00040:Server Error`, message.toString());

    return of(message as string);
  }
}

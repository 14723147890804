<div class="z-fx z-fx-start-center z-fx-gap-8">
  <ng-container *ngIf="!isMultiSelect; else multiselectStuff">
    <div *ngIf="prefix" class="select-item-prefix z-fx-none">
      <ng-container [ngTemplateOutlet]="prefix" [ngTemplateOutletContext]="{ $implicit: value }"></ng-container>
    </div>
  </ng-container>
  <ng-template #multiselectStuff>
    <mat-icon *ngIf="!radioType" class="select-item-checkbox">check</mat-icon>
    <div *ngIf="radioType" class="select-item-radio">
      <div class="select-item-radio-circle"></div>
    </div>
  </ng-template>

  <div
    *ngIf="label; else valueAsLabel"
    class="select-item-label zef-text-ellipsis z-fx-flex"
    [class.placeholder]="placeholder"
    textOverflow
  >
    <ng-container [ngTemplateOutlet]="label" [ngTemplateOutletContext]="{ $implicit: value }"></ng-container>

    <ng-container *ngIf="placeholder">...</ng-container>
  </div>
  <ng-template #valueAsLabel>
    <div
      *ngIf="!valuePrefixOnly"
      class="select-item-label zef-text-ellipsis z-fx-flex"
      [class.placeholder]="placeholder"
      textOverflow
    >
      {{ value }}
    </div>
  </ng-template>
</div>

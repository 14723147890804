import {
  HelpItemSubject,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpCenterItemData,
  HelpCenterItemStatistics,
} from '@shared/modules/help-center/help-center.models';

export class GetHelpItemStatus {
  static type = '[Help Center] Get item status';

  constructor(readonly subject: HelpItemSubject) {}
}

export class GetHelpItemTip {
  static type = '[Help Center] Get item tip';

  constructor(readonly subject: HelpItemSubject, readonly lang: HelpCenterLanguage) {}
}

export class GetHelpItemData {
  static type = '[Help Center] Get item data';

  constructor(readonly subject: HelpItemSubject, readonly lang: HelpCenterLanguage) {}
}

export class GetHelpItemStatistics {
  static type = '[Help Center] Get item stats';

  constructor(readonly subject: HelpItemSubject) {}
}

export class UpdateHelpCenterMode {
  static type = '[Help Center] Update mode';

  constructor(readonly mode: { language?: HelpCenterLanguage; mode?: HelpCenterMode }) {}
}

export class UpdateHelpItemData {
  static type = '[Help Center] Update data item';

  constructor(
    readonly subject: HelpItemSubject,
    readonly data: Partial<Record<HelpCenterLanguage, Partial<HelpCenterItemData>>>,
  ) {}
}

export class IncrementHelpItemStats {
  static type = '[Help Center] Increment stats';

  constructor(
    readonly subject: HelpItemSubject,
    readonly property: keyof HelpCenterItemStatistics,
    readonly lang: HelpCenterLanguage,
  ) {}
}

import { TemplateModel } from '@shared/models/survey.model';
import { ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

export class SetTemplate {
  static readonly type = '[Templates] - Set Template';

  constructor(readonly templateKey?: string) {}
}

export class CreateTemplate {
  static readonly type = '[Templates] - Create Template';

  constructor(readonly templateKey?: string) {}
}

// @todo test if dialog still opens after removing the componentFactoryResolver/viewContainerRef when
// https://github.com/angular/components/issues/19616 or https://github.com/angular/components/issues/19335 are fixed
export class DiscoverTemplate {
  static readonly type = '[Templates] - Discover Template';

  constructor(
    readonly templateKey: string,
    readonly templates: TemplateModel[],
    readonly startView: 'intro' | 'report' = 'intro',
    readonly componentFactoryResolver?: ComponentFactoryResolver,
    readonly viewContainerRef?: ViewContainerRef,
  ) {}

  toJSON = () => ({
    ...this,
    type: DiscoverTemplate.type,
    templates: [],
    componentFactoryResolver: null,
    viewContainerRef: null,
  });
}

export class GetTemplateTypes {
  static readonly type = '[Templates] - Get template types';
}

export class GetTemplatesSurveys {
  static readonly type = '[Templates] - Get all surveys';
}

export class LoadTemplates {
  static readonly type = '[Templates] - Load templates';
}

export class FilterTemplates {
  static readonly type = '[Templates] - Filter Template';

  constructor(
    readonly query: string,
    readonly category?: string,
    readonly language?: string,
    readonly complexity?: number,
  ) {}
}

export class ResetTemplatesFilter {
  static readonly type = '[Templates] - Reset Filter';
}

export class ToggleTemplatesFilter {
  static readonly type = '[Templates] - Toggle Filter';
  constructor(readonly visible: boolean) {}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TemplateModel } from '@shared/models/survey.model';

@Component({
  selector: 'discover-thumb',
  templateUrl: './discover-thumb.component.html',
  styleUrls: ['./discover-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverThumb {
  @Input()
  template?: TemplateModel;

  @Input()
  small?: boolean;
}

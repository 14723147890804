/**
 * Player app shared views module.
 *
 * @stable
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicModule } from '@shared/modules/basic.module';

import { TranslateText } from '@player/shared/pipes/translate-text.pipe';
import { NgPipesModule, ShortenPipe } from 'ngx-pipes';

import { MatIconModule } from '@angular/material/icon';
import { TranslateObject } from '@player/shared/pipes/translate-object.pipe';

import { MediaCtaComponent } from '@player/shared/components/media-cta/media-cta.component';
import { YoutubePlayerModule } from '@shared/modules/youtube-player/youtube-player.module';
import { BasicButton } from '@player/shared/components/basic-button/basic-button.component';

@NgModule({
  declarations: [TranslateText, TranslateObject, MediaCtaComponent, BasicButton],
  exports: [
    NgPipesModule,
    MatIconModule,
    YoutubePlayerModule,

    ShortenPipe,

    TranslateText,
    TranslateObject,

    MediaCtaComponent,
    BasicButton,
  ],
  imports: [CommonModule, BasicModule, NgPipesModule, MatIconModule, YoutubePlayerModule],
  providers: [ShortenPipe, TranslateText],
})
export class SharedModuleForPlayer {}

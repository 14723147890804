import { fromEvent, merge, BehaviorSubject, EMPTY } from 'rxjs';
import { tap, mapTo, distinctUntilChanged, takeUntil, switchMap, delay } from 'rxjs/operators';

import {
  Component,
  ChangeDetectionStrategy,
  Output,
  ElementRef,
  HostListener,
  HostBinding,
  ChangeDetectorRef,
  Input,
} from '@angular/core';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

@Component({
  selector: 'zef-drag-zone',
  templateUrl: './drag-zone.component.html',
  styleUrls: ['./drag-zone.component.scss'],
  providers: [LifecycleHooks],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragZoneComponent {
  private onlyOnDrag$ = new BehaviorSubject(false);

  @Output()
  readonly zoneDrop = fromEvent(this.el.nativeElement, 'drop').pipe(tap((event) => event.preventDefault()));

  @Output()
  readonly zoneDragEnd = merge(...['dragend', 'dragleave'].map((event) => fromEvent(this.el.nativeElement, event)));

  @Output()
  readonly zoneDragStart = fromEvent(this.el.nativeElement, 'dragenter');

  @Output()
  readonly zoneActive = merge(
    merge(
      ...['pointerleave', 'pointercancel'].map((event) => fromEvent(this.el.nativeElement, event)),
      this.zoneDragEnd,
      this.zoneDrop,
    ).pipe(mapTo(false)),
    merge(
      this.onlyOnDrag$.pipe(
        switchMap((onlyOnDrag) =>
          onlyOnDrag ? EMPTY : fromEvent(this.el.nativeElement, 'pointerenter').pipe(mapTo(true)),
        ),
      ),
      this.zoneDragStart,
    ).pipe(mapTo(true)),
  ).pipe(distinctUntilChanged());

  private readonly active$ = new BehaviorSubject<boolean>(false);

  @Input()
  set onlyOnDrag(onlyOnDrag: boolean) {
    this.onlyOnDrag$.next(onlyOnDrag);
  }

  @Input()
  set active(active: boolean) {
    this.active$.next(active);
  }

  @HostBinding('class.active')
  _active?: boolean;

  @HostBinding('class.no-pointer')
  get noPointer(): boolean {
    return this.onlyOnDrag$.getValue();
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  constructor(private el: ElementRef<HTMLElement>, private lh: LifecycleHooks, private cd: ChangeDetectorRef) {
    merge(this.zoneActive, this.active$.pipe(delay(1)))
      .pipe(takeUntil(this.lh.destroy))
      .subscribe((active) => {
        this._active = active;
        this.cd.markForCheck();
      });
  }
}

export interface CountryData {
  code?: string;
  name: string;
  dial: number;
  eu: boolean;
}

export const Country: Record<string, CountryData> = {
  AF: {
    name: 'Afghanistan (‫افغانستان‬‎)',
    dial: 93,
    eu: false,
  },
  AL: {
    name: 'Albania (Shqipëri)',
    dial: 355,
    eu: false,
  },
  DZ: {
    name: 'Algeria (‫الجزائر‬‎)',
    dial: 213,
    eu: false,
  },
  AD: {
    name: 'Andorra',
    dial: 376,
    eu: true,
  },
  AO: {
    name: 'Angola',
    dial: 244,
    eu: false,
  },
  AG: {
    name: 'Antigua and Barbuda',
    dial: 1,
    eu: false,
  },
  AR: {
    name: 'Argentina',
    dial: 54,
    eu: false,
  },
  AM: {
    name: 'Armenia (Հայաստան)',
    dial: 374,
    eu: false,
  },
  AU: {
    name: 'Australia',
    dial: 61,
    eu: false,
  },
  AT: {
    name: 'Austria (Österreich)',
    dial: 43,
    eu: true,
  },
  AZ: {
    name: 'Azerbaijan (Azərbaycan)',
    dial: 994,
    eu: false,
  },
  BS: {
    name: 'Bahamas',
    dial: 1,
    eu: false,
  },
  BH: {
    name: 'Bahrain (‫البحرين‬‎)',
    dial: 973,
    eu: false,
  },
  BD: {
    name: 'Bangladesh (বাংলাদেশ)',
    dial: 880,
    eu: false,
  },
  BB: {
    name: 'Barbados',
    dial: 1,
    eu: false,
  },
  BY: {
    name: 'Belarus (Беларусь)',
    dial: 375,
    eu: false,
  },
  BE: {
    name: 'Belgium (België)',
    dial: 32,
    eu: true,
  },
  BZ: {
    name: 'Belize',
    dial: 501,
    eu: false,
  },
  BJ: {
    name: 'Benin (Bénin)',
    dial: 229,
    eu: false,
  },
  BT: {
    name: 'Bhutan (འབྲུག)',
    dial: 975,
    eu: false,
  },
  BO: {
    name: 'Bolivia',
    dial: 591,
    eu: false,
  },
  BA: {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    dial: 387,
    eu: false,
  },
  BW: {
    name: 'Botswana',
    dial: 267,
    eu: false,
  },
  BR: {
    name: 'Brazil (Brasil)',
    dial: 55,
    eu: false,
  },
  BN: {
    name: 'Brunei',
    dial: 673,
    eu: false,
  },
  BG: {
    name: 'Bulgaria (България)',
    dial: 359,
    eu: true,
  },
  BF: {
    name: 'Burkina Faso',
    dial: 226,
    eu: false,
  },
  BI: {
    name: 'Burundi (Uburundi)',
    dial: 257,
    eu: false,
  },
  KH: {
    name: 'Cambodia (កម្ពុជា)',
    dial: 855,
    eu: false,
  },
  CM: {
    name: 'Cameroon (Cameroun)',
    dial: 237,
    eu: false,
  },
  CA: {
    name: 'Canada',
    dial: 1,
    eu: false,
  },
  CV: {
    name: 'Cape Verde (Kabu Verdi)',
    dial: 238,
    eu: false,
  },
  CF: {
    name: 'Central African Republic (République centrafricaine)',
    dial: 236,
    eu: false,
  },
  TD: {
    name: 'Chad (Tchad)',
    dial: 235,
    eu: false,
  },
  CL: {
    name: 'Chile',
    dial: 56,
    eu: false,
  },
  CN: {
    name: 'China (中国)',
    dial: 86,
    eu: false,
  },
  CO: {
    name: 'Colombia',
    dial: 57,
    eu: false,
  },
  KM: {
    name: 'Comoros (‫جزر القمر‬‎)',
    dial: 269,
    eu: false,
  },
  CD: {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    dial: 243,
    eu: false,
  },
  CG: {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    dial: 242,
    eu: false,
  },
  CR: {
    name: 'Costa Rica',
    dial: 506,
    eu: false,
  },
  CI: {
    name: 'Côte d’Ivoire',
    dial: 225,
    eu: false,
  },
  HR: {
    name: 'Croatia (Hrvatska)',
    dial: 385,
    eu: true,
  },
  CU: {
    name: 'Cuba',
    dial: 53,
    eu: false,
  },
  CY: {
    name: 'Cyprus (Κύπρος)',
    dial: 357,
    eu: true,
  },
  CZ: {
    name: 'Czech Republic (Česká republika)',
    dial: 420,
    eu: true,
  },
  DK: {
    name: 'Denmark (Danmark)',
    dial: 45,
    eu: true,
  },
  DJ: {
    name: 'Djibouti',
    dial: 253,
    eu: false,
  },
  DM: {
    name: 'Dominica',
    dial: 1,
    eu: false,
  },
  DO: {
    name: 'Dominican Republic (República Dominicana)',
    dial: 1,
    eu: false,
  },
  EC: {
    name: 'Ecuador',
    dial: 593,
    eu: false,
  },
  EG: {
    name: 'Egypt (‫مصر‬‎)',
    dial: 20,
    eu: false,
  },
  SV: {
    name: 'El Salvador',
    dial: 503,
    eu: false,
  },
  GQ: {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    dial: 240,
    eu: false,
  },
  ER: {
    name: 'Eritrea',
    dial: 291,
    eu: false,
  },
  EE: {
    name: 'Estonia (Eesti)',
    dial: 372,
    eu: true,
  },
  ET: {
    name: 'Ethiopia',
    dial: 251,
    eu: false,
  },
  FJ: {
    name: 'Fiji',
    dial: 679,
    eu: false,
  },
  FI: {
    name: 'Finland (Suomi)',
    dial: 358,
    eu: true,
  },
  FR: {
    name: 'France',
    dial: 33,
    eu: true,
  },
  GA: {
    name: 'Gabon',
    dial: 241,
    eu: false,
  },
  GM: {
    name: 'Gambia',
    dial: 220,
    eu: false,
  },
  GE: {
    name: 'Georgia (საქართველო)',
    dial: 995,
    eu: false,
  },
  DE: {
    name: 'Germany (Deutschland)',
    dial: 49,
    eu: true,
  },
  GH: {
    name: 'Ghana (Gaana)',
    dial: 233,
    eu: false,
  },
  GR: {
    name: 'Greece (Ελλάδα)',
    dial: 30,
    eu: true,
  },
  GD: {
    name: 'Grenada',
    dial: 1,
    eu: false,
  },
  GT: {
    name: 'Guatemala',
    dial: 502,
    eu: false,
  },
  GN: {
    name: 'Guinea (Guinée)',
    dial: 224,
    eu: false,
  },
  GW: {
    name: 'Guinea-Bissau (Guiné Bissau)',
    dial: 245,
    eu: false,
  },
  GY: {
    name: 'Guyana',
    dial: 592,
    eu: false,
  },
  HT: {
    name: 'Haiti',
    dial: 509,
    eu: false,
  },
  HN: {
    name: 'Honduras',
    dial: 504,
    eu: false,
  },
  HU: {
    name: 'Hungary (Magyarország)',
    dial: 36,
    eu: true,
  },
  IS: {
    name: 'Iceland (Ísland)',
    dial: 354,
    eu: false,
  },
  IN: {
    name: 'India (भारत)',
    dial: 91,
    eu: false,
  },
  ID: {
    name: 'Indonesia',
    dial: 62,
    eu: false,
  },
  IR: {
    name: 'Iran (‫ایران‬‎)',
    dial: 98,
    eu: false,
  },
  IQ: {
    name: 'Iraq (‫العراق‬‎)',
    dial: 964,
    eu: false,
  },
  IE: {
    name: 'Ireland',
    dial: 353,
    eu: true,
  },
  IL: {
    name: 'Israel (‫ישראל‬‎)',
    dial: 972,
    eu: false,
  },
  IT: {
    name: 'Italy (Italia)',
    dial: 39,
    eu: true,
  },
  JM: {
    name: 'Jamaica',
    dial: 1,
    eu: false,
  },
  JP: {
    name: 'Japan (日本)',
    dial: 81,
    eu: false,
  },
  JO: {
    name: 'Jordan (‫الأردن‬‎)',
    dial: 962,
    eu: false,
  },
  KZ: {
    name: 'Kazakhstan (Казахстан)',
    dial: 7,
    eu: false,
  },
  KE: {
    name: 'Kenya',
    dial: 254,
    eu: false,
  },
  KI: {
    name: 'Kiribati',
    dial: 686,
    eu: false,
  },
  KW: {
    name: 'Kuwait (‫الكويت‬‎)',
    dial: 965,
    eu: false,
  },
  KG: {
    name: 'Kyrgyzstan (Кыргызстан)',
    dial: 996,
    eu: false,
  },
  LA: {
    name: 'Laos (ລາວ)',
    dial: 856,
    eu: false,
  },
  LV: {
    name: 'Latvia (Latvija)',
    dial: 371,
    eu: true,
  },
  LB: {
    name: 'Lebanon (‫لبنان‬‎)',
    dial: 961,
    eu: false,
  },
  LS: {
    name: 'Lesotho',
    dial: 266,
    eu: false,
  },
  LR: {
    name: 'Liberia',
    dial: 231,
    eu: false,
  },
  LY: {
    name: 'Libya (‫ليبيا‬‎)',
    dial: 218,
    eu: false,
  },
  LI: {
    name: 'Liechtenstein',
    dial: 423,
    eu: true,
  },
  LT: {
    name: 'Lithuania (Lietuva)',
    dial: 370,
    eu: true,
  },
  LU: {
    name: 'Luxembourg',
    dial: 352,
    eu: true,
  },
  MK: {
    name: 'Macedonia (FYROM) (Македонија)',
    dial: 389,
    eu: false,
  },
  MG: {
    name: 'Madagascar (Madagasikara)',
    dial: 261,
    eu: false,
  },
  MW: {
    name: 'Malawi',
    dial: 265,
    eu: false,
  },
  MY: {
    name: 'Malaysia',
    dial: 60,
    eu: false,
  },
  MV: {
    name: 'Maldives',
    dial: 960,
    eu: false,
  },
  ML: {
    name: 'Mali',
    dial: 223,
    eu: false,
  },
  MT: {
    name: 'Malta',
    dial: 356,
    eu: true,
  },
  MH: {
    name: 'Marshall Islands',
    dial: 692,
    eu: false,
  },
  MR: {
    name: 'Mauritania (‫موريتانيا‬‎)',
    dial: 222,
    eu: false,
  },
  MU: {
    name: 'Mauritius (Moris)',
    dial: 230,
    eu: false,
  },
  MX: {
    name: 'Mexico (México)',
    dial: 52,
    eu: false,
  },
  FM: {
    name: 'Micronesia',
    dial: 691,
    eu: false,
  },
  MD: {
    name: 'Moldova (Republica Moldova)',
    dial: 373,
    eu: false,
  },
  MC: {
    name: 'Monaco',
    dial: 377,
    eu: true,
  },
  MN: {
    name: 'Mongolia (Монгол)',
    dial: 976,
    eu: false,
  },
  ME: {
    name: 'Montenegro (Crna Gora)',
    dial: 382,
    eu: true,
  },
  MA: {
    name: 'Morocco (‫المغرب‬‎)',
    dial: 212,
    eu: false,
  },
  MZ: {
    name: 'Mozambique (Moçambique)',
    dial: 258,
    eu: false,
  },
  MM: {
    name: 'Myanmar (Burma) (မြန်မာ)',
    dial: 95,
    eu: false,
  },
  NA: {
    name: 'Namibia (Namibië)',
    dial: 264,
    eu: false,
  },
  NR: {
    name: 'Nauru',
    dial: 674,
    eu: false,
  },
  NP: {
    name: 'Nepal (नेपाल)',
    dial: 977,
    eu: false,
  },
  NL: {
    name: 'Netherlands (Nederland)',
    dial: 31,
    eu: true,
  },
  NZ: {
    name: 'New Zealand',
    dial: 64,
    eu: false,
  },
  NI: {
    name: 'Nicaragua',
    dial: 505,
    eu: false,
  },
  NE: {
    name: 'Niger (Nijar)',
    dial: 227,
    eu: false,
  },
  NG: {
    name: 'Nigeria',
    dial: 234,
    eu: false,
  },
  KP: {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    dial: 850,
    eu: false,
  },
  NO: {
    name: 'Norway (Norge)',
    dial: 47,
    eu: false,
  },
  OM: {
    name: 'Oman (‫عُمان‬‎)',
    dial: 968,
    eu: false,
  },
  PK: {
    name: 'Pakistan (‫پاکستان‬‎)',
    dial: 92,
    eu: false,
  },
  PW: {
    name: 'Palau',
    dial: 680,
    eu: false,
  },
  PA: {
    name: 'Panama (Panamá)',
    dial: 507,
    eu: false,
  },
  PG: {
    name: 'Papua New Guinea',
    dial: 675,
    eu: false,
  },
  PY: {
    name: 'Paraguay',
    dial: 595,
    eu: false,
  },
  PE: {
    name: 'Peru (Perú)',
    dial: 51,
    eu: false,
  },
  PH: {
    name: 'Philippines',
    dial: 63,
    eu: false,
  },
  PL: {
    name: 'Poland (Polska)',
    dial: 48,
    eu: true,
  },
  PT: {
    name: 'Portugal',
    dial: 351,
    eu: true,
  },
  QA: {
    name: 'Qatar (‫قطر‬‎)',
    dial: 974,
    eu: false,
  },
  RO: {
    name: 'Romania (România)',
    dial: 40,
    eu: true,
  },
  RU: {
    name: 'Russia (Россия)',
    dial: 7,
    eu: false,
  },
  RW: {
    name: 'Rwanda',
    dial: 250,
    eu: false,
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    dial: 1,
    eu: false,
  },
  LC: {
    name: 'Saint Lucia',
    dial: 1,
    eu: false,
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    dial: 1,
    eu: false,
  },
  WS: {
    name: 'Samoa',
    dial: 685,
    eu: false,
  },
  SM: {
    name: 'San Marino',
    dial: 378,
    eu: true,
  },
  ST: {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    dial: 239,
    eu: false,
  },
  SA: {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    dial: 966,
    eu: false,
  },
  SN: {
    name: 'Senegal (Sénégal)',
    dial: 221,
    eu: false,
  },
  RS: {
    name: 'Serbia (Србија)',
    dial: 381,
    eu: false,
  },
  SC: {
    name: 'Seychelles',
    dial: 248,
    eu: false,
  },
  SL: {
    name: 'Sierra Leone',
    dial: 232,
    eu: false,
  },
  SG: {
    name: 'Singapore',
    dial: 65,
    eu: false,
  },
  SK: {
    name: 'Slovakia (Slovensko)',
    dial: 421,
    eu: true,
  },
  SI: {
    name: 'Slovenia (Slovenija)',
    dial: 386,
    eu: true,
  },
  SB: {
    name: 'Solomon Islands',
    dial: 677,
    eu: false,
  },
  SO: {
    name: 'Somalia (Soomaaliya)',
    dial: 252,
    eu: false,
  },
  ZA: {
    name: 'South Africa',
    dial: 27,
    eu: false,
  },
  KR: {
    name: 'South Korea (대한민국)',
    dial: 82,
    eu: false,
  },
  ES: {
    name: 'Spain (España)',
    dial: 34,
    eu: true,
  },
  LK: {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    dial: 94,
    eu: false,
  },
  SD: {
    name: 'Sudan (‫السودان‬‎)',
    dial: 249,
    eu: false,
  },
  SR: {
    name: 'Suriname',
    dial: 597,
    eu: false,
  },
  SZ: {
    name: 'Swaziland',
    dial: 268,
    eu: false,
  },
  SE: {
    name: 'Sweden (Sverige)',
    dial: 46,
    eu: true,
  },
  CH: {
    name: 'Switzerland (Schweiz)',
    dial: 41,
    eu: false,
  },
  SY: {
    name: 'Syria (‫سوريا‬‎)',
    dial: 963,
    eu: false,
  },
  TW: {
    name: 'Taiwan (台灣)',
    dial: 886,
    eu: false,
  },
  TJ: {
    name: 'Tajikistan',
    dial: 992,
    eu: false,
  },
  TZ: {
    name: 'Tanzania',
    dial: 255,
    eu: false,
  },
  TH: {
    name: 'Thailand (ไทย)',
    dial: 66,
    eu: false,
  },
  TL: {
    name: 'Timor-Leste',
    dial: 670,
    eu: false,
  },
  TG: {
    name: 'Togo',
    dial: 228,
    eu: false,
  },
  TO: {
    name: 'Tonga',
    dial: 676,
    eu: false,
  },
  TT: {
    name: 'Trinidad and Tobago',
    dial: 1,
    eu: false,
  },
  TN: {
    name: 'Tunisia (‫تونس‬‎)',
    dial: 216,
    eu: false,
  },
  TR: {
    name: 'Turkey (Türkiye)',
    dial: 90,
    eu: false,
  },
  TM: {
    name: 'Turkmenistan',
    dial: 993,
    eu: false,
  },
  TV: {
    name: 'Tuvalu',
    dial: 688,
    eu: false,
  },
  UG: {
    name: 'Uganda',
    dial: 256,
    eu: false,
  },
  UA: {
    name: 'Ukraine (Україна)',
    dial: 380,
    eu: false,
  },
  AE: {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    dial: 971,
    eu: false,
  },
  GB: {
    name: 'United Kingdom',
    dial: 44,
    eu: true,
  },
  US: {
    name: 'United States',
    dial: 1,
    eu: false,
  },
  UY: {
    name: 'Uruguay',
    dial: 598,
    eu: false,
  },
  UZ: {
    name: 'Uzbekistan (Oʻzbekiston)',
    dial: 998,
    eu: false,
  },
  VU: {
    name: 'Vanuatu',
    dial: 678,
    eu: false,
  },
  VA: {
    name: 'Vatican City (Città del Vaticano)',
    dial: 39,
    eu: true,
  },
  VE: {
    name: 'Venezuela',
    dial: 58,
    eu: false,
  },
  VN: {
    name: 'Vietnam (Việt Nam)',
    dial: 84,
    eu: false,
  },
  EH: {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    dial: 212,
    eu: false,
  },
  YE: {
    name: 'Yemen (‫اليمن‬‎)',
    dial: 967,
    eu: false,
  },
  ZM: {
    name: 'Zambia',
    dial: 260,
    eu: false,
  },
  ZW: {
    name: 'Zimbabwe',
    dial: 263,
    eu: false,
  },
};

/* eslint-disable @typescript-eslint/no-namespace */
export namespace Countries {
  export const codes = Object.keys(Country);
  export const labels = codes.map((code) => Country[code].name);

  export const getLabel = (code: keyof typeof Country): string | undefined => Country[code] && Country[code].name;

  export function isInEU(code: keyof typeof Country): boolean {
    return !!Country[code] && Country[code].eu;
  }
}

import { Type } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { SidenavConfig, SidenavUpdateType } from '@shared/modules/sidenav/models/sidenav.models';
import { SnackbarOptions } from '@shared/models/notice.models';

export class OpenDialog<T = any, D = any> {
  static readonly type = '[Dialog] - Open';

  constructor(readonly component: Type<T>, readonly config?: MatDialogConfig<D>) {}

  toJSON = () => ({ ...this, type: OpenDialog.type, component: this.component.name, config: '[MatDialogConfig]' });
}

export class DialogClosed<T = any, D = any> {
  static readonly type = '[Dialog] - Closed';

  constructor(readonly component: Type<T>, readonly response: D) {}

  toJSON = () => ({ ...this, type: DialogClosed.type, component: this.component.name, response: this.response });
}

export class DisplaySnackbar {
  static readonly type = '[Dialog] - Display snackbar';

  constructor(readonly title: string, readonly options: SnackbarOptions = {}) {}

  toJSON = () => ({ ...this, type: DisplaySnackbar.type, title: this.title, options: '[SnackbarOptions]' });
}

export class OpenSidenav<D = any, T = any> {
  static readonly type = '[Dialog] - Open sidenav';

  constructor(readonly component: Type<T>, readonly config?: SidenavConfig<T, D>) {}

  readonly toJSON = () => ({
    ...this,
    type: OpenSidenav.type,
    component: this.component.name,
    config: '[SidenavConfig]',
  });
}

export class CloseLastSidenav {
  static readonly type = '[Dialog] - Close last sidenav';
}

export class SidenavClosed<D = any, T = any> {
  static readonly type = '[Dialog] - Closed sidenav';

  constructor(readonly component: Type<T>, readonly response: D) {}

  toJSON = () => ({ ...this, type: SidenavClosed.type, component: this.component.name, response: this.response });
}

export class SidenavUpdate<T = any> {
  static readonly type = '[Dialog] - Sidenav update';

  constructor(readonly type: SidenavUpdateType, readonly response: T) {}
}

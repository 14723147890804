import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { FunnelModule } from '@home/shared/modules/funnel.module';

import { DiscoverPage } from '@home/+discover/discover.component';
import { DiscoverRouting } from '@home/+discover/discover.routing';

import { ResetFilter } from '@home/shared/guards/reset-filter.guard';

import { ThumbsModule } from '@home/shared/modules/thumbs.module';

@NgModule({
  bootstrap: [],
  declarations: [DiscoverPage],
  exports: [],
  imports: [SharedModule, FunnelModule, ThumbsModule, DiscoverRouting],
  providers: [ResetFilter],
})
export class DiscoverModule {}

import { BehaviorSubject } from 'rxjs';

import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { SharedModule } from '@shared/shared.module';

import { DiscoverSurvey } from '@home/shared/dialogs/discover-survey/discover-survey.dialog';
import { TemplatePreview } from '@home/+discover/preview/preview.component';
import { SurveyModule } from '@player/+survey/survey.module';
import { PublicModule } from '@report/shared/modules/public.module';
import { FONT_PICKER_CONFIG, FontPickerConfigInterface, FontPickerModule } from 'ngx-font-picker';
import { environment } from '@env/environment';
import { DatabaseLoader } from '@editor/shared/services/database-loader.service';
import { ClientCountry } from '@shared/tokens/client-country.token';
import { Store } from '@ngxs/store';
import { PrefsState } from '@shared/states/prefs.state';
import { DATE_TIME_PROVIDER } from '@shared/tokens/date-time-provider.token';
import { TriggerManager } from '@player/shared/services/trigger-manager.service';
import { EditorTriggerManagerService } from '@editor/shared/services/editor-trigger-manager.service';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  apiKey: environment.googleBrowserKey,
};

@NgModule({
  bootstrap: [],
  declarations: [DiscoverSurvey, TemplatePreview],
  exports: [DiscoverSurvey, TemplatePreview],
  imports: [FontPickerModule, SurveyModule, PublicModule, SharedModule, MatDialogModule],
  providers: [
    { provide: FONT_PICKER_CONFIG, useValue: DEFAULT_FONT_PICKER_CONFIG },
    { provide: 'isPublished', useValue: false },
    { provide: 'LocaleLoader', useClass: DatabaseLoader },
    { provide: TriggerManager, useClass: EditorTriggerManagerService },
    { provide: ClientCountry, useFactory: funnelClientCountry, deps: [Store] },
    { provide: DATE_TIME_PROVIDER, useValue: new BehaviorSubject(new Date()) },
  ],
})
export class FunnelModule {}

export function funnelClientCountry(store: Store): string {
  return store.selectSnapshot(PrefsState.country) || store.selectSnapshot(PrefsState.language);
}

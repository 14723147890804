import { Observable, of } from 'rxjs';
import { map, catchError, tap, filter, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpEvent, HttpResponse, HttpEventType } from '@angular/common/http';

import { Store } from '@ngxs/store';

import { Commands } from '@shared/enums/commands.enum';
import { AccountState } from '@shared/states/account.state';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import {
  ImageUploadDirectory,
  ImageGalleryItem,
  ImageGalleryRequestParams,
} from '@shared/modules/image-upload/models/image-upload.models';
import { UploadImageProgress, UploadImageComplete } from '@shared/modules/image-upload/image-upload.actions';
import { addProtocolToUrl } from '@shared/utilities/string.utilities';

@Injectable({ providedIn: 'root' })
export class ImageUploadManager {
  constructor(private cf: CloudFunctions, private store: Store) {}

  getImages<T extends ImageGalleryItem = ImageGalleryItem>(
    directory: ImageUploadDirectory,
    params?: ImageGalleryRequestParams,
  ): Observable<{ images: T[]; token: string | null }> {
    return this.cf
      .get<{ images: T[]; token: string | null }>(Commands.ImageGallery, this.getPath(directory, params))
      .pipe(catchError(() => of({ images: [], token: null })));
  }

  uploadImage(directory: ImageUploadDirectory, image: File): Observable<ImageGalleryItem> {
    const form = new FormData();
    form.append('file', image);

    return this.cf
      .post<HttpEvent<ImageGalleryItem>>(Commands.ImageGallery, this.getPath(directory), form, 'form', 'events')
      .pipe(
        filter((event) => !!event),
        tap((event) => {
          if (event.type === HttpEventType.Sent) {
            this.store.dispatch(new UploadImageProgress(image, 0));
          } else if (event.type === HttpEventType.UploadProgress) {
            this.store.dispatch(new UploadImageProgress(image, Math.min(1, event.loaded / image.size)));
          }
        }),
        filter((event) => event.type === HttpEventType.Response),
        take(1),
        tap(() => this.store.dispatch(new UploadImageProgress(image, 1))),
        map((response: HttpResponse<ImageGalleryItem>) => ({
          ...response.body,
          created: Date.now(),
        })),
        tap((imageItem) => this.store.dispatch(new UploadImageComplete(directory, imageItem, image))),
      );
  }

  pushImage(image: ImageGalleryItem, directory: ImageUploadDirectory): Observable<ImageGalleryItem> {
    return this.cf.put(Commands.ImageGallery, this.getPath(directory), {
      url: addProtocolToUrl(image.urls.regular),
      download: addProtocolToUrl(image.urls.download),
      id: image.id,
    });
  }

  removeImage(image: string): Observable<void> {
    return this.cf.delete(Commands.ImageGallery, `${this.store.selectSnapshot(AccountState.teamKey)}/${image}`);
  }

  private getPath(directory: ImageUploadDirectory, params?: ImageGalleryRequestParams): string {
    const baseUrl = `${this.store.selectSnapshot(AccountState.teamKey)}/${directory}`;
    const urlParams = new URLSearchParams();

    if (params?.query) {
      urlParams.set('query', params.query);
    }

    if (params?.page) {
      urlParams.set('page', params.page.toString());
    }

    if (params?.maxResults) {
      urlParams.set('maxResults', params.maxResults.toString());
    }

    const queryString = urlParams.toString();

    return `${baseUrl}${queryString ? `?${queryString}` : ''}`;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  SimpleChanges,
  Input,
  OnChanges,
  HostBinding,
  AfterViewInit,
} from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

import { YoutubePlayerService } from '@shared/modules/youtube-player/youtube-player.service';
import { VideoEmbedInfo, defaultVideoRatio } from '@shared/models/video-embed.model';

@Component({
  selector: 'zef-youtube-player',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<div #youtubeContainer></div>',
  styleUrls: ['./youtube-player.component.scss'],
})
export class YoutubePlayerComponent extends YouTubePlayer implements OnChanges, OnDestroy, AfterViewInit {
  @Input()
  video?: VideoEmbedInfo;

  @Input()
  language?: string;

  @HostBinding('style.height.px')
  get hostHeight(): number {
    return this.height;
  }

  readonly defaultPlayerVars = {
    enablejsapi: 1,
    modestbranding: 1,
    playsinline: 1,
    rel: 0,
    showinfo: 0,
    iv_load_policy: 0,
  };

  constructor(private yp: YoutubePlayerService, ng: NgZone, @Inject(PLATFORM_ID) platformId: object) {
    super(ng, platformId);

    this.yp.register(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const videoChanged = (changes.video && this.video) || (changes.language && this.language && this.video);

    if (videoChanged) {
      if (this.videoId !== this.video.videoId) {
        this.videoId = this.video.videoId;
      }

      if (
        changes.video.firstChange ||
        this.video.startTime !== this.playerVars.start ||
        this.video.endTime !== this.playerVars.end ||
        (this.language && this.language !== this.playerVars.hl)
      ) {
        this.playerVars = {
          ...this.defaultPlayerVars,
          start: this.video.startTime,
          end: this.video.endTime,
          hl: this.language,
        };
      }
    }

    if (videoChanged || (changes.width && this.width)) {
      const height = this.width / (this.video.aspectRatio || defaultVideoRatio);

      if (height !== this.height) {
        this.height = height;
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.yp.unregister(this);
  }

  ngAfterViewInit(): void {
    try {
      super.ngAfterViewInit();
    } catch {}
  }
}

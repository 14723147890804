import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { SnackbarService } from '@shared/services/snackbar.service';

import { ChartDomain, ChartSettings, DimensionDataItem, FilterData } from '@shared/models/report.model';
import { Rights } from '@shared/enums/rights.enum';

import { Crossfilter } from '@report/shared/services/crossfilter.service';
import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

import { rotateAnimation } from '@shared/animations/rotate.anim';

/**
 * This is a text answer table.
 */
@Component({
  selector: 'text-answer-table',
  templateUrl: './text-answer-table.component.html',
  styleUrls: ['./text-answer-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [rotateAnimation],
})
export class TextAnswerTable implements OnInit, OnChanges, OnDestroy {
  @Input() details: DimensionDataItem[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() filterInput: any[] = [];
  @Input() crossfilter: Crossfilter | null = null;
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() size: string = '0px';
  @Input() bigTable: boolean = false;
  @Input() isSharedReport: boolean = false;
  @Input() update: Date = new Date();
  @Input() comparison: any;
  @Input() anonymityTreshold: number = null;
  @Input() userRights: number = 0;
  @Input() surveyRights: number = 0;
  @Input() chartSettings: ChartSettings = {} as ChartSettings;

  @Output() settingsChange: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize') resize() {
    this.setEnvironment();
  }

  readonly Rights = Rights;

  // Summary Observable
  public summary$: Observable<string>;

  // BehaviorSubjects
  public searchUpdate$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public languageFilter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public sortKey$: BehaviorSubject<string> = new BehaviorSubject<string>('default');
  public sortDirection$: BehaviorSubject<string> = new BehaviorSubject<string>('asc');
  public chartData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public chartTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public comparisonTable$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  // Variables used in HTML template
  public columns: number[] = [];
  public columnWidths: string[] = [];
  public sizeUpdate: Date = new Date();
  public expandedLabels: boolean = false;
  public vsHide: boolean = false;
  public responses: number = 0;
  public hasTextDimension: boolean = false;

  public availableHeight: number = 0;
  public otherAreaHeight: number = 84;

  public shortenNum: number = 200;
  public headerHeight: number = 56;
  public headerTop: string = '0px';

  public toggledRow: number | null = null;
  public expandedHeader: boolean = false;

  public selectedRespondents: number[] = [];
  public deletionInProgress: boolean = false;

  private dataService: Crossfilter | null = this.cf;

  private filter: FilterData[];
  private selections: any = {};

  @ViewChild(CdkVirtualScrollViewport) vsViewport: CdkVirtualScrollViewport;

  constructor(
    private _element: ElementRef,
    private cdRef: ChangeDetectorRef,
    private cf: Crossfilter,
    readonly ns: SnackbarService,
    readonly hooks: LifecycleHooks,
  ) {}

  ngOnInit(): void {
    combineLatest([this.chartData$, this.searchUpdate$, this.languageFilter$, this.sortKey$, this.sortDirection$])
      .pipe(takeUntil(this.hooks.destroy))
      .subscribe(([chartData, searchTerm, languageFilter, sortKey, sortDirection]) => {
        const rawArray = chartData.slice();
        const textArray = rawArray;
        const sortIndex = this.details.findIndex((item) => item.key === sortKey) + 1;

        let filteredList: any[];

        if (!searchTerm) {
          filteredList = textArray;
        } else {
          const filteredResults = textArray.filter((col) =>
            col
              .map((c) => c.text)
              .join()
              .toLowerCase()
              .includes(searchTerm.toLowerCase()),
          );
          filteredList = filteredResults;
        }

        const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

        const sortedList =
          sortKey === 'default'
            ? sortDirection === 'desc'
              ? filteredList.reverse()
              : filteredList
            : sortKey === 'time'
            ? sortDirection === 'asc'
              ? filteredList.reverse()
              : filteredList
            : filteredList.sort((a, b) => {
                if (a[sortIndex]['text'] && b[sortIndex]['text']) {
                  if (sortDirection === 'asc') {
                    return collator.compare(a[sortIndex]['text'], b[sortIndex]['text']);
                  } else {
                    return collator.compare(b[sortIndex]['text'], a[sortIndex]['text']);
                  }
                } else {
                  return a[sortIndex]['text'] ? -1 : 1;
                }
              });

        if (sortedList.length === 0) {
          this.vsHide = true;
        } else {
          this.vsHide = false;
        }

        this.responses = sortedList?.length || 0;
        this.chartTable$.next(sortedList);
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();

        if (this.vsViewport) {
          this.vsViewport.scrollTo({ top: 0 });
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();

          this.vsViewport.setRenderedRange({ start: 0, end: this.vsViewport.getRenderedRange().end + 4 });
          this.cdRef.markForCheck();
          this.cdRef.detectChanges();
        }
      });
  }

  private setData(): void {
    if (this.crossfilter) {
      this.dataService = this.crossfilter;
    } else {
      this.dataService = this.cf;
    }

    this.shortenNum = this.bigTable ? 30 : 200;

    this.chartData$.next(
      this.details
        ? this.dataService.getTextAnswersFor(
            this.details,
            this.isSharedReport,
            false,
            true,
            !!(this.anonymityTreshold && (this.comparison || this.dataService.getTrendAnalysisStatus())),
          )
        : [],
    );

    this.summary$ = this.dataService.getTextSummaryFor(this.details, this.isSharedReport);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.columns = this.details
      ? this.details
          .map((item, index) => Number(index))
          .filter(
            (i) =>
              (!this.comparison ||
                !(
                  ((this.dataService.getTextFreezingStatus() && this.isSharedReport) || this.anonymityTreshold) &&
                  this.details[i].key === this.comparison.key
                )) &&
              !(
                ((this.dataService.getTextFreezingStatus() && this.isSharedReport) || this.anonymityTreshold) &&
                this.dataService.getTrendAnalysisStatus() &&
                this.details[i].key === 'time'
              ),
          )
          .filter((index, i, arr) => !this.anonymityTreshold || i === 0)
      : [];
    this.setEnvironment();

    this.updateFilters();

    if (changes.size) {
      this.sizeChange(!changes.size.isFirstChange() && !!this.chartTable$.value?.length);
    }

    if (changes.chartSettings && changes.chartSettings.firstChange) {
      this.sortKey$.next(this.chartSettings.sortKey || 'default');
      this.sortDirection$.next(this.chartSettings.sortDirection || 'asc');
    }

    this.setData();
  }

  ngOnDestroy() {
    delete this.columns;
  }

  get selectedCount() {
    return this.selectedRespondents?.length || 0;
  }

  private setEnvironment() {
    const width = this._element.nativeElement.clientWidth - 16; /* 16 = Scrollbar */
    const dimTypes = (this.columns || []).map((col) =>
      this.details?.[col]?.key === 'select' ? 'select' : this.details?.[col]?.scale,
    );

    const linear = dimTypes.filter((t) => t === 'linear').length;
    const text = dimTypes.filter((t) => t === 'text').length;
    const select = dimTypes.filter((t) => t === 'select').length;
    const others = dimTypes.filter((t) => t !== 'linear' && t !== 'text' && t !== 'select').length;

    if (dimTypes.length === 1) {
      this.columnWidths = ['100%'];
    } else if (select * 32 + linear * 120 + (text + others) * 160 > width) {
      this.columnWidths = dimTypes.map((type) => (type === 'linear' ? '120px' : type === 'select' ? '32px' : '160px'));
    } else {
      this.columnWidths = dimTypes.map((type, i, arr) =>
        text
          ? type === 'text'
            ? `${(width - (linear * 120 + others * 160 + select * 32)) / text}px`
            : type === 'linear'
            ? '120px'
            : type === 'select'
            ? '32px'
            : '160px'
          : others
          ? type === 'linear'
            ? '120px'
            : type === 'select'
            ? '32px'
            : `${(width - (linear * 120 + select * 32)) / others}px`
          : linear
          ? type === 'linear'
            ? `${(width - select * 32) / linear}px`
            : '32px'
          : '32px',
      );
    }
  }

  private sizeChange(roughUpdateNeeded: boolean) {
    if (this.size) {
      this.availableHeight = parseFloat(this.size) - this.otherAreaHeight;

      this.sizeUpdate = new Date();
      if (roughUpdateNeeded) {
        this.forceVSUpdate();
      }
    }
  }

  public toggleRow(row) {
    if (this.toggledRow === row) {
      this.toggledRow = null;
    } else {
      this.toggledRow = row;
    }
    this.cdRef.detectChanges();
  }

  public filterData(value, i) {
    const dimKey = this.details[i].key;
    if (
      !(this.dataService.getTextFreezingStatus() && this.isSharedReport) &&
      !this.anonymityLock &&
      this.dataService.getDimensions()?.[dimKey]
    ) {
      const scale = this.details[i].scale;
      let values: any[] = [];
      let filterValue: number[] = [];

      if (scale === 'text') {
        values = Array.from(Array(this.dataService.getTextAnswers()[dimKey].length).keys());
        filterValue = value[1];
      } else if (scale === 'contact-text') {
        values = Array.from(Array(this.dataService.getTextContacts()[dimKey].length).keys());
        filterValue = value[1];
      } else if (this.details[i].values && this.details[i].values.length > 0) {
        values = this.details[i].values;
        filterValue = value[0].map((item) => this.details[i]['values'][item]);
      }

      if (!this.selections[dimKey]) {
        this.selections[dimKey] = new Set();
      }

      for (const val of filterValue) {
        if (this.selections[dimKey].has(val)) {
          this.selections[dimKey].delete(val);
        } else {
          this.selections[dimKey].add(val);
        }
      }

      const newFilter: FilterData = {
        key: dimKey,
        values,
        filter: Array.from(this.selections[dimKey]),
        textFilter: this.details[i].scale === 'text' || this.details[i].scale === 'contact-text' ? true : false,
      };

      this.callFilter(newFilter);
    }
  }

  public downloadFile(row, column) {
    if (column && column.key && row && row[0] && row[0]['value'] != null) {
      this.cf.downloadFile(column.survey, column.key, row[0]['value']);
    }
  }

  public getDataAsArray(): (string | number)[][] {
    const table = [];
    const details = this.details.filter((det) => det.key !== 'select');
    table.push(
      details.map((det) => (det.key !== 'time' ? det.title : det.title + ' (' + this.getTimePeriodName() + ')')),
    );
    table.push(
      ...this.chartTable$.value.map((row) =>
        row.slice(2).map((val, valIndex) => {
          return !!val.text && details?.[valIndex]?.scale === 'linear' ? Number(val.text) : val.text;
        }),
      ),
    );
    return table;
  }

  updateFilters() {
    for (let i = 0, len = this.filterInput.length; i < len; i++) {
      const filters = this.filterInput[i];
      const key = this.details[i].key;
      this.selections[key] = new Set();
      if (filters && filters.length > 0) {
        for (const filter of filters) {
          this.selections[key].add(filter);
        }
      }
    }
  }

  callFilter(filter: FilterData) {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.dataService.filter(this.filter);
      }
    }
  }

  private forceVSUpdate(): void {
    this.vsHide = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.vsHide = false;
      this.cdRef.detectChanges();
    }, 0);
  }

  trackByTextItem(i: number, item: any): string {
    return item.text;
  }

  trackByKey(i: number, item: any): string {
    return item.key;
  }

  trackByIndex(i: number, detail: DimensionDataItem): number {
    return i;
  }

  trackByRow(i: number, row: any[]): number {
    return (row && row[0] && row[0]['value']) || null;
  }

  public onSortColumn(column: string): void {
    const sortDimensionType: string = this.details.find((item) => item.key === column)?.scale;

    if (this.sortKey$.value !== column) {
      this.sortKey$.next(column);
      this.sortDirection$.next(sortDimensionType !== 'linear' ? 'asc' : 'desc');
    } else if (
      (sortDimensionType !== 'linear' && this.sortDirection$.value === 'desc') ||
      (sortDimensionType === 'linear' && this.sortDirection$.value === 'asc')
    ) {
      this.sortKey$.next('default');
      this.sortDirection$.next('asc');
    } else {
      this.sortDirection$.next(this.sortDirection$.value === 'asc' ? 'desc' : 'asc');
    }

    this.settingsChanged();
  }

  public settingsChanged(): void {
    this.settingsChange.emit({
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
    });
  }

  public isRowSelected(data) {
    return data && data[0] && data[0]['value'] != null && this.selectedRespondents.includes(data[0]['value']);
  }

  public toggleSelected(row, $event) {
    const id = row && row[0] && row[0]['value'];

    if ($event && this.selectedRespondents.indexOf(id) < 0) {
      this.selectedRespondents.push(id);
    } else if (!$event && this.selectedRespondents.indexOf(id) >= 0) {
      this.selectedRespondents.splice(this.selectedRespondents.indexOf(id), 1);
    }
  }

  public onDeleteRespondents(): void {
    const deleteCount: number = this.selectedRespondents?.length;
    this.deletionInProgress = true;
    this.dataService
      .deleteRespondents(this.selectedRespondents)
      .pipe(first())
      .subscribe(
        (response) => {
          this.selectedRespondents = [];
          this.deletionInProgress = false;
          this.cdRef.detectChanges();
          this.ns.open(
            deleteCount +
              ' ' +
              (deleteCount === 1
                ? $localize`:singular respondent@@zef-i18n-000509-ma:respondent`
                : $localize`:plural contact@@zef-i18n-000510-ma:respondents`) +
              $localize`:snackbar notification|success message@@zef-i18n-000511-ma: succesfully deleted`,
            { color: 'alert' },
          );
        },
        (error) => {},
        () => {},
      );
  }

  public removeRespondentSelections(): void {
    this.selectedRespondents = [];
  }

  public headerRowResize($event): void {
    this.headerHeight = $event?.dimensions?.height || 56;
  }

  public isNotFreezed(): boolean {
    return !(this.dataService.getTextFreezingStatus() && this.isSharedReport);
  }

  public isUnderAnonymityTreshold(): boolean {
    return this.anonymityTreshold && this.chartData$?.value?.length < this.anonymityTreshold;
  }

  public getTimePeriodName(): string {
    const names = {
      year: $localize`:@@zef-i18n-00339:Yearly`,
      month: $localize`:@@zef-i18n-00340:Monthly`,
      week: $localize`:@@zef-i18n-00341:Weekly`,
      day: $localize`:@@zef-i18n-00342:Daily`,
    };
    return (this.dataService.getTimePeriod() && names[this.dataService.getTimePeriod()]) || '';
  }

  public summaryResize($event) {
    this.otherAreaHeight = ($event?.dimensions?.height ? $event.dimensions.height + 40 : 0) + 84;
    this.sizeChange(true);
  }

  public getHeaderText(i) {
    return (
      this.domain[i]?.title ||
      this.details[i]?.title +
        (this.details[i]?.key === 'time' || this.domain[i]?.key === 'time' ? ' (' + this.getTimePeriodName() + ')' : '')
    );
  }
}

<div class="z-fx-col z-fx-flex report-container">
  <mat-progress-bar
    *ngIf="cf.loading | async"
    mode="indeterminate"
    color="primary"
    class="loading-bar"
  ></mat-progress-bar>
  <nav-bar *ngIf="isSharedReport && !isTemplateReport" class="z-fx-col" [info]="reportInfo"></nav-bar>

  <filter-bar
    *ngIf="isTemplateReport; else showInPortal"
    class="z-fx-col z-fx-none filter-bar"
    [userRights]="userRights"
    [surveyRights]="surveyRights"
    [isTemplateReport]="isTemplateReport"
    [isSharedReport]="isSharedReport"
    (scrollTo)="scrollTo($event)"
  ></filter-bar>
  <ng-template #showInPortal>
    <filter-bar
      *zefPortal
      class="z-fx-col z-fx-none filter-bar"
      [userRights]="userRights"
      [surveyRights]="surveyRights"
      [isTemplateReport]="isTemplateReport"
      [isSharedReport]="isSharedReport"
      (scrollTo)="scrollTo($event)"
    ></filter-bar>
  </ng-template>

  <ng-scrollbar class="z-fx-col z-fx-flex" (elementResize)="onViewResize($event)" id="zefReport">
    <div class="z-fx-col z-fx-auto content">
      <div
        *ngIf="loaded && sampleData && sampleDataBanner && !sampleDataRemovalBanner"
        class="z-fx z-fx-center-center space-40 sample-data-info"
        i18n
      >
        <div class="space-40"></div>
        <div class="z-fx z-fx-auto z-fx-center-center">
          <span>
            You are viewing sample data. Did you already
            <span class="link" (click)="goToShareView()">Share</span>
            your survey?
          </span>
        </div>
        <div class="z-fx z-fx-start-center space-40">
          <mat-icon class="banner-close-icon" (click)="closeSampleDataBanner()">close</mat-icon>
        </div>
      </div>

      <div
        *ngIf="loaded && sampleData && sampleDataRemovalBanner"
        class="z-fx z-fx-center-center space-40 sample-data-removal"
        i18n
      >
        <div class="space-40"></div>
        <div class="z-fx z-fx-auto z-fx-center-center">
          <span>
            You are viewing sample data. The survey has now got new responses.
            <span class="link" (click)="showRealResponses()">See real data</span>
          </span>
        </div>
        <div class="z-fx z-fx-start-center space-40">
          <mat-icon class="banner-close-icon" (click)="closeSampleDataRemovalBanner()">close</mat-icon>
        </div>
      </div>

      <div
        *ngIf="loaded && userRights > 0 && !isSharedReport && showLastReportBanner && lastOpenReport && !rs.autoSaveOn"
        class="z-fx space-40 previous-data-info"
      >
        <div class="space-40"></div>
        <div class="z-fx z-fx-auto z-fx-center-center">
          <span>
            <ng-container *ngIf="!isAuthorUnsavedChanges" i18n>Open your</ng-container>
            <ng-container *ngIf="isAuthorUnsavedChanges" i18n>Continue from your last</ng-container>
            &ngsp;
            <span class="link" (click)="openLastReport()">
              <ng-container *ngIf="!isAuthorUnsavedChanges" i18n>last Report</ng-container>
              <ng-container *ngIf="isAuthorUnsavedChanges" i18n>unsaved changes</ng-container>
            </span>
          </span>
        </div>
        <div class="z-fx z-fx-start-center space-40">
          <mat-icon class="banner-close-icon" (click)="closeLastReportBanner()">close</mat-icon>
        </div>
      </div>

      <nav-pane
        *ngIf="userRights > 0 && !isSharedReport"
        class="z-fx-col"
        [userRights]="userRights"
        [surveyRights]="surveyRights"
      ></nav-pane>

      <div *ngIf="loaded" [ngSwitch]="isDesktop$ | async" class="z-fx z-fx-auto container">
        <charts-view
          *ngIf="loaded"
          class="z-fx-col z-fx-auto"
          [scrollState]="scrollState"
          [userRights]="userRights"
          [surveyRights]="surveyRights"
          [touchDevice]="touchDevice"
          [isSharedReport]="isSharedReport"
          [filtersDemo]="filtersDemo"
          (scrollTo)="scrollTo($event)"
        ></charts-view>
        <side-pane
          *ngSwitchCase="true"
          class="z-fx-col side-pane-normal"
          [userRights]="userRights"
          [surveyRights]="surveyRights"
          [scrollState]="scrollState"
          [isSharedReport]="isSharedReport"
          [isTemplateReport]="isTemplateReport"
          [isFiltersApplied]="filtersApplied || filtersDemo"
          [isDrawer]="false"
        ></side-pane>
        <div *zefPortal class="z-fx-col z-fx-none z-fx-center-center side-pane-area">
          <mat-icon *ngSwitchCase="false" class="mobile-side-pane-switch" (click)="sidePanel.openPanel()">
            keyboard_arrow_left
          </mat-icon>
        </div>
      </div>
      <zef-logo-spinner *ngIf="!loaded"></zef-logo-spinner>
    </div>
  </ng-scrollbar>

  <zef-panel #sidePanel [align]="'right'" [width]="width * 0.8 > 400 ? '360px' : width * 0.8 + 'px'" [scrim]="true">
    <div *ngIf="sidePanel?.isOpen()" class="z-fx side-pane-panel-container">
      <side-pane
        class="z-fx-col z-fx-auto side-pane-panel"
        [userRights]="userRights"
        [surveyRights]="surveyRights"
        [scrollState]="scrollState"
        [isSharedReport]="isSharedReport"
        [isTemplateReport]="isTemplateReport"
        [isFiltersApplied]="filtersApplied || filtersDemo"
        [isDrawer]="true"
      ></side-pane>
    </div>
  </zef-panel>
</div>

/**
 * Modify numbers over 9999 to a string with K suffix.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tenK',
})
export class TenThousands implements PipeTransform {
  public transform(value: number): string | null {
    return +value < 10000 ? `${value}` : `${(+value / 1000).toFixed(1)}k`.replace('.0', '');
  }
}

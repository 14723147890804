<div class="z-input-wrapper">
  <div
    class="z-input z-select"
    #input
    [style.border-color]="this.isValid ? (ss.colors | async).primary : invalidColor"
    [style.background-color]="(ss.colors | async).mood"
    [style.color]="isAnswered ? (ss.colors | async).primary : (ss.colors | async).text80"
    (click)="dropdown.toggle()"
  >
    <ng-container *ngIf="selectedOption !== null; else showPlaceholder">
      {{ selectedOption?.content | translateText : 'choice' : questionData : selectedOption }}
    </ng-container>
    <ng-template #showPlaceholder>
      {{ placeholder }}
    </ng-template>
    <mat-icon *ngIf="!isAnswered" class="z-arrow-icon" fontSet="zefplayer" [style.color]="(ss.colors | async).button">
      arrow_down
    </mat-icon>
  </div>

  <span *ngIf="!isValid" class="z-error-text">{{ errorText }}</span>

  <mat-icon
    *ngIf="isAnswered"
    class="z-answered-icon"
    fontSet="zefplayer"
    [style.background-color]="(ss.colors | async).primary"
    [style.color]="(ss.colors | async).primaryParity"
    (click)="resetAnswer()"
  >
    check
  </mat-icon>
</div>

<options-dropdown
  [trackByOption]="trackByOption"
  [filterOptions]="filterOptions"
  [attachedTo]="input"
  [options]="options"
  [selectedOption]="selectedOption"
  [canSearch]="questionData?.type !== Questions.INPUT_NUMERIC"
  (select)="onSelectOption($event)"
  #dropdown="optionsDropdown"
>
  <ng-template let-option>
    {{ option.content | translateText : 'choice' : questionData : option }}
  </ng-template>
</options-dropdown>

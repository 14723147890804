/**
 * Sends an event if content overflows the element.
 *
 * @unstable
 */

import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

/**
 * This directive monitors if the scrollheight is larger than the height of the element.
 */
@Directive({
  selector: '[contentOverflow]',
})
export class ContentOverflow implements OnInit, OnDestroy {
  private ro: any = null;

  private needsScroll: boolean | null = null;

  @Output() contentOverflow = new EventEmitter<boolean>();

  constructor(readonly zone: NgZone, readonly cdRef: ChangeDetectorRef, readonly elRef: ElementRef) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.ro = new ResizeObserver((entries, observer) => {
        // Offset is rounded down and scroll up so we need to reduce 2 pixels
        const offsetHeight = this.elRef.nativeElement.offsetHeight;
        const scrollHeight = this.elRef.nativeElement.scrollHeight - 2;

        if (offsetHeight !== 0) {
          if (scrollHeight > offsetHeight) {
            if (this.needsScroll !== true) {
              this.zone.run(() => {
                this.contentOverflow.emit((this.needsScroll = true));
              });

              this.cdRef.markForCheck();
            }
          } else {
            if (this.needsScroll !== false) {
              this.zone.run(() => {
                this.contentOverflow.emit((this.needsScroll = false));
              });

              this.cdRef.markForCheck();
            }
          }
        }
      });

      this.ro.observe(this.elRef.nativeElement);
    });
  }

  ngOnDestroy(): void {
    if (this.ro) {
      this.ro.disconnect();
    }
  }
}

import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  OnDestroy,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';

import { SurveyView } from '@player/+survey/survey.component';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { PictureZoom } from '@player/shared/directives/picture-zoom.directive';

import { ImageStyles } from '@shared/models/styles.model';

@Component({
  selector: 'picture-image',
  templateUrl: './picture-image.component.html',
  styleUrls: ['./picture-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('zoom', [
      transition('* => true', [
        style({
          transform: 'translate({{translateX}}px, {{translateY}}px) scale({{scaleX}}, {{scaleY}})',
          opacity: 0.33,
        }),
        animate('200ms ease-in-out', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
      transition(
        'true => false',
        animate(
          '200ms ease-in-out',
          style({
            transform: 'translate({{translateX}}px, {{translateY}}px) scale({{scaleX}}, {{scaleY}})',
            opacity: 0.33,
          }),
        ),
      ),
    ]),
  ],
})
export class PictureImage implements OnInit, OnDestroy {
  @Input()
  image?: ImageStyles;

  @Input()
  zoom?: boolean;

  @ViewChild(CdkPortal)
  portal?: CdkPortal;

  @ViewChild(PictureZoom)
  pictureZoom?: PictureZoom;

  imageProportion = 1;

  showZoom: boolean = false;

  imageRect = this.el.nativeElement.getBoundingClientRect();

  readonly zoomParams$ = combineLatest([this.ss.viewRect$, this.ss.viewSize]).pipe(
    map(([rect, size]) => {
      this.imageRect = this.el.nativeElement.getBoundingClientRect();
      const offset = size === 'xl' || size === 'lg' ? 64 : 32;

      try {
        this.overlayRef?.updatePosition();
      } catch {}

      return {
        scaleX: this.imageRect.width / (rect.width + offset),
        scaleY: this.imageRect.height / (rect.height + offset),
        translateX: this.imageRect.left + this.imageRect.width / 2 - (rect.left + rect.width / 2),
        translateY: this.imageRect.top + this.imageRect.height / 2 - (rect.top + rect.height / 2),
      };
    }),
  );

  private overlayRef?: OverlayRef;

  constructor(
    readonly ss: SurveyStore,
    private overlay: Overlay,
    private sv: SurveyView,
    private el: ElementRef<HTMLElement>,
  ) {}

  ngOnInit(): void {
    this.imageRect = this.el.nativeElement.getBoundingClientRect();
  }

  zoomImage(): void {
    this.overlayRef?.dispose();
    this.ss.hideContent$.next(true);
    this.showZoom = true;

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.sv.surveyView)
        .withPositions([{ originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' }])
        .withGrowAfterOpen(true),
      hasBackdrop: false,
      panelClass: 'zef-survey',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    this.overlayRef.attach(this.portal);
  }

  @HostListener('document:keydown.escape')
  closeZoom(): void {
    if (!this.pictureZoom?.active) {
      this.ss.hideContent$.next(false);
      this.showZoom = false;
    }
  }

  onLoad(image: HTMLImageElement): void {
    this.imageProportion = image.naturalWidth / image.naturalHeight;
  }

  onAnimationDone(event: AnimationEvent): void {
    if (event.toState.toString() === 'false') {
      this.overlayRef?.dispose();
    }
  }

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }
}

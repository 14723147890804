import type { RecipientCounter, CounterKey } from '@shared/models/recipient.model';
import { OrderData } from '@shared/models/order.model';
import { BlockData } from '@shared/modules/email-composer/models/block-data.model';

export type MessageType = 'notification' | 'empty' | 'reminder' | 'invite' | '';
export type MessageKind = 'email' | 'sms' | 'link' | 'embed' | 'popup';
export type MessageStatus = 'new' | 'draft' | 'scheduled' | 'sent' | 'sending' | 'automated';

export interface MessageExternalList {
  integrationId: string;
  lists: string[];
  listLinks?: string[];
}

export type MessageProcessType = 'send' | 'schedule' | 'automate';

export interface MessageData extends OrderData {
  name: string;
  type: MessageType;
  kind: MessageKind;
  parent?: string;
  language?: string;
  anonymous: boolean;
  from?: string;
  anonymity?: any;
  automation?: AutomationSettings;
  respondentFields?: string[]; // list of contact column keys
  created?: number;
  sending?: number;
  scheduledAt?: number;
  scheduledFromStatus?: CounterKey;
  scheduledToStatus?: CounterKey;
  survey: string | null;
  sender: string | null;
  creator: string | null;
  processType?: MessageProcessType;

  recipients?: number[];
  recipientsLists?: number[];
  externalLists: MessageExternalList[];
  typeContacts?: number;
  totalContacts?: number;
  lockedContacts?: number;

  counter?: RecipientCounter;

  shareLink: { url: string };
  status: MessageStatus;
  sendingStatus?: EmailStatus;
  trackingStatus?: EmailStatus;

  master?: string;
  slave?: string;
}

export interface SmsData extends MessageData {
  message: string;
  repairCountryCode?: string;
}

export interface EmailData extends MessageData {
  subject: string;
  preview: string;

  bodyText: string;
  bodyHtml: string;

  design: EmailDesign;

  // used in triggers
  recipientAddresses: string[];

  // hidden template
  hidden: boolean;

  template: BlockData<object>[] | null;
}

export class EmailDesign {
  backgroundColor: string = '#f9fafb';
  contentColor: string = '#fff';
}

export interface EmailStatus {
  time: any;

  active: boolean;

  count: number; // Total | Total

  retried: number; // Bounced | Unused

  failed: number; // Skipped | Spam

  success: number; // Delivered | Clicked

  processed: number; // Processed | Opened
}
export interface EmailDraftErrors {
  subject: boolean;

  body: boolean;

  surveyLink?: boolean;

  preview: boolean;

  contacts: boolean;
}

export interface EmailErrorPayload {
  emailKey: string;

  errors: EmailDraftErrors;
}

export type AutomateContactList = 'currentFuture' | 'onlyFuture';

export interface AutomationSettings {
  enabled: boolean;
  automateContacts: AutomateContactList;
  sendAfterDays?: number;
  sendAfterDaysFromPrev?: number;
}

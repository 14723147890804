import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Select } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';
import { ActionsState } from '@shared/states/actions.state';
import { SendPasswordResetEmail } from '@shared/states/auth.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'auth-signin-invite',
  templateUrl: './signin-invite.component.html',
})
export class SigninInvite {
  public email: string = '';
  public password: string = '';
  public showInfo: boolean = true;

  readonly AuthError = AuthError;
  @Input() authError: { code: AuthError; message: string } | null = null;
  @Input() inviteData: InviteData | null = null;
  @Output() select = new EventEmitter();

  @Select(ActionsState.whileAction(SendPasswordResetEmail))
  readonly sending$!: Observable<boolean>;

  @Select(ActionsState.completedAction(SendPasswordResetEmail))
  readonly sent$!: Observable<boolean>;

  public get hasGoogleProvider() {
    return (this.inviteData?.providers || []).includes('google.com');
  }

  public get hasPasswordProvider() {
    return (this.inviteData?.providers || []).includes('password');
  }

  changeUser() {
    this.select.next({ method: 'change' });
  }

  onDialogClose() {
    this.select.next({ method: 'init' });
  }

  signInWithGoogle() {
    this.select.next({ method: 'google' });
  }

  signInWithEmail(email: string, password: string) {
    this.select.next({ method: 'password', email, password });
  }

  signInResetPassword(email: string) {
    this.select.next({ method: 'reset', email });
  }
}

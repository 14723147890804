import { Component, OnChanges, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';

/**
 * This is language view component which handles language in report.
 */
@Component({
  selector: 'trend-view',
  templateUrl: './trend-view.component.html',
  styleUrls: ['./trend-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendView implements OnChanges {
  @Input() activeTrend: string = '';
  @Input() trendAnalysisOn: boolean = false;
  @Output() changeTrend: EventEmitter<string> = new EventEmitter<string>();
  @Output() trendSwitched: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly subjects = HelpSubject;

  public selectedTrend = '';
  public trendSwitchOn: boolean = false;

  constructor() {}

  ngOnChanges() {
    this.selectedTrend = this.activeTrend;
    this.trendSwitchOn = this.trendAnalysisOn;
  }

  public trendSwitch(action) {
    this.trendSwitchOn = action.checked;
    this.trendSwitched.emit(action.checked);
  }

  public selectOption(option: string) {
    this.selectedTrend = option;
    this.changeTrend.emit(option);
  }
}

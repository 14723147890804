import {
  Directive,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  AfterViewInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { NavBarService } from '@home/shared/modules/nav-bar/nav-bar.service';
import { NavBarInstance } from '@home/shared/modules/nav-bar/nav-bar.models';

import { ZefThemePalette } from '@shared/models/color.model';

@Directive({
  selector: '[navBarContent]',
})
export class NavBarContentDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input('navBarContentColor')
  color: ZefThemePalette | 'logic-dark' = 'secondary';

  @Input('navBarContentInvert')
  invert?: boolean;

  avatar?: TemplateRef<any>;

  icon?: TemplateRef<any>;

  get layer(): NavBarInstance {
    return {
      content: this.tr,
      color: this.color,
      avatar: this.avatar,
      icon: this.icon,
      invert: !!this.invert,
    };
  }

  constructor(private nbs: NavBarService, private tr: TemplateRef<any>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.shouldUpdate(changes)) {
      this.nbs.updateLayer(this.layer);
    }
  }

  ngOnInit(): void {
    this.nbs.addLayer(this.layer);
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.nbs.updateLayer(this.layer));
  }

  ngOnDestroy(): void {
    this.nbs.removeLayer(this.layer);
  }

  private shouldUpdate(changes: SimpleChanges): boolean {
    return ['color', 'invert'].some((key) => changes[key] && !changes[key].firstChange);
  }
}

<!--
  Team card element with team switching.

  @unstable
-->

<div *ngIf="invite" class="wrapper z-fx-col z-fx-gap-8 z-fx-center-center">
  <team-tile [teamName]="invite.teamName" [teamLogo]="invite.teamLogo" [teamCreated]="0"></team-tile>

  <div class="z-fx-col z-fx-w100 z-fx-gap-8 z-fx-center-center">
    <ng-container *ngIf="!processed; else processedInvite">
      <div *ngIf="!ignored; then accept; else ignore"></div>

      <ng-template #accept>
        <button
          mat-raised-button
          color="success"
          class="zef-stretch-button"
          (click)="acceptInvite()"
          [analyticsTrack]="AnalyticsTracks.TEAM_ACCEPT_INVITE"
          i18n
        >
          Accept Invite
        </button>

        <a class="zef-text-link-underline zef-text-p2 zef-color-muted" i18n (click)="ignored = true">Ignore</a>
      </ng-template>

      <ng-template #ignore>
        <button
          mat-raised-button
          color="alert"
          class="zef-stretch-button"
          (click)="ignoreInvite()"
          [analyticsTrack]="AnalyticsTracks.TEAM_IGNORE_INVITE"
          i18n
        >
          Confirm ignore
        </button>

        <a class="zef-text-link-underline zef-text-p2 zef-color-muted" i18n (click)="ignored = false; (false)">
          Cancel ignore
        </a>
      </ng-template>
    </ng-container>

    <ng-template #processedInvite>
      <button mat-raised-button color="primary" class="zef-stretch-button" (click)="switchTeam()" i18n>
        Switch now
      </button>
    </ng-template>
  </div>
</div>

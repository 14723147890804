/**
 * Controls browsers history state.
 *
 * @unstable
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HistoryState {
  constructor() {}

  public getParam(param: string, remove?: boolean): string | null {
    let value = null;

    const url = window.location.href;

    const ree = new RegExp('(\\?|&)$');
    const rer = new RegExp(param + '=[^&]*&?');
    const rem = new RegExp('.*\\?.*' + param + '=([^&]*)');

    const result = url.match(rem);

    if (result && result.length === 2) {
      if (remove) {
        value = result[1];

        const newUrl = url.replace(rer, '').replace(ree, '');

        try {
          window.history.replaceState({}, '', newUrl);
        } catch (error) {
          console.error('Changing window history replace state failed!');
        }
      }
    }

    if (param === 'zefId') {
      this.getParam('zefExtra', true);
    }

    return value;
  }
}

<zef-sidenav [loading]="loading$ | async" [ngSwitch]="edit$ | async" [confirmClose]="!!(dataUpdates$ | async)">
  <ng-container *zefSidenavTitle>
    <ng-container *ngSwitchCase="true">
      <zef-inline-edit
        *ngIf="title$ | async as title; else directInputTmpl"
        class="z-fx-min-width"
        placeholder="Enter title..."
        i18n-placeholder
        [value]="title"
        (valueChange)="updateData({ title: $event })"
      ></zef-inline-edit>

      <ng-template #directInputTmpl>
        <mat-form-field class="zef-input-big zef-input-inline zef-input-no-padding">
          <input
            matInput
            ngModel
            placeholder="Enter title..."
            i18n-placeholder
            [ngModelOptions]="{ updateOn: 'blur' }"
            (ngModelChange)="updateData({ title: $event })"
          />
        </mat-form-field>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="false">{{ title$ | async }}</ng-container>
  </ng-container>

  <ng-template zefSidenavButtonSecondary>
    <div class="z-fx z-fx-end-center">
      <ng-container *ngIf="isAdmin$ | async">
        <div *ngIf="stats$ | async as stats" class="zef-text-p2-muted z-fx-sc z-fx-gap-4">
          <div matTooltip="Hovers" i18n-matTooltip>{{ stats.current.hovers }} ({{ stats.total.hovers }})</div>

          <span>•</span>

          <div matTooltip="Clicks" i18n-matTooltip>{{ stats.current.clicks }} ({{ stats.total.clicks }})</div>

          <span>•</span>

          <div matTooltip="External clicks" i18n-matTooltip>
            {{ stats.current.externalClicks }} ({{ stats.total.externalClicks }})
          </div>
        </div>

        <mat-divider [vertical]="true" class="zef-divider-16"></mat-divider>
      </ng-container>

      <div class="z-fx-sc z-fx-gap-4">
        <mat-button-toggle
          *ngFor="let lang of languages"
          class="zef-icon-toggle"
          [value]="lang"
          [checked]="(lang$ | async) === lang"
          (change)="updateMode({ language: lang })"
        >
          <figure class="zef-flag-small" [ngClass]="'zef-lang-' + lang"></figure>
        </mat-button-toggle>
      </div>

      <mat-divider [vertical]="true" class="zef-divider-16"></mat-divider>

      <button
        *ngIf="edit$ | async"
        mat-raised-button
        color="primary"
        class="zef-ghost-button"
        (click)="updateMode({ mode: modes.Preview })"
      >
        <mat-icon>visible_on</mat-icon>

        <span i18n>Preview</span>
      </button>

      <button
        *ngIf="(isAdmin$ | async) && (edit$ | async) === false"
        mat-raised-button
        color="primary"
        class="zef-ghost-button"
        (click)="updateMode({ mode: modes.Edit })"
      >
        <mat-icon>edit</mat-icon>

        <span i18n>Edit</span>
      </button>
    </div>
  </ng-template>

  <ng-container *zefSidenavButtonPrimary>
    <a
      *ngIf="read$ | async; else updateBtnTmpl"
      [href]="externalLink$ | async"
      rel="nofollow"
      target="_blank"
      mat-raised-button
      color="ink"
      (click)="increment('externalClicks')"
    >
      <mat-icon>action_academy</mat-icon>

      <span i18n>Read more in Help Center</span>
    </a>

    <ng-template #updateBtnTmpl>
      <button
        mat-raised-button
        color="success"
        [disabled]="quickTipError$ | async"
        [loading]="saving$ | async"
        (click)="onUpdate()"
        i18n
      >
        Update
      </button>
    </ng-template>
  </ng-container>

  <div *zefSidenavContent class="zef-card-32 ql-snow">
    <ng-container *ngIf="data$ | async as data">
      <div *ngSwitchCase="true" class="z-fx-col z-fx-gap-32">
        <div class="z-fx z-fx-gap-16">
          <div class="help-quick-tip z-fx-col z-fx-gap-16 z-fx-flex">
            <div class="z-fx-sc z-fx-gap-8 zef-text-p2">
              <div i18n>Quick tip</div>

              <div [ngClass]="(quickTipError$ | async) ? 'zef-color-alert' : 'zef-color-success'">
                {{ quickTipTextArea.value.length }}/{{ maxChars }}
              </div>

              <div *ngIf="data.quickTip" class="help-test zef-text-lightest z-fx-sc">
                <div i18n>Test</div>

                <button
                  mat-icon-button
                  [helpTooltip]="data.quickTip"
                  matTooltipPosition="above"
                  [matTooltipShowDelay]="300"
                >
                  <mat-icon class="zef-icon-small" color="ink-lightest">help_center</mat-icon>
                </button>
              </div>
            </div>

            <mat-form-field
              class="zef-textarea-small zef-textarea-space"
              [class.zef-textarea-error]="quickTipError$ | async"
            >
              <textarea
                placeholder="This is a quick tip about the subject. There is a character limit set to 160 to maintain better readability."
                i18n-placeholder
                matInput
                autoSize
                [autoSizeMinRows]="3"
                [ngModel]="data.quickTip"
                (ngModelChange)="updateData({ quickTip: $event })"
                #quickTipTextArea
              ></textarea>
            </mat-form-field>
          </div>

          <div class="z-fx-col z-fx-gap-16 z-fx-flex">
            <div class="zef-text-p2" i18n>External Help Center link</div>

            <mat-form-field class="zef-input-small zef-input-space">
              <input
                placeholder="https://zeffi.hs-sites.com/en/knowledge/knowledge-base-article"
                i18n-placeholder
                matInput
                [ngModel]="data.externalLink"
                (ngModelChange)="updateData({ externalLink: $event })"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="z-fx-col z-fx-gap-16">
          <div class="zef-text-p2" i18n>Article</div>

          <quill
            [value]="data.article || ''"
            (editorCreate)="onEditorCreated($event)"
            (contentChange)="updateData({ article: $event.html })"
          ></quill>
        </div>
      </div>

      <help-article
        *ngSwitchCase="false"
        class="zef-text-p1 ql-editor"
        [article]="data.parsedArticle | safeHtml"
      ></help-article>
    </ng-container>
  </div>
</zef-sidenav>

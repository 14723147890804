import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Navigate, RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';

import { environment } from '@env/environment';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { LicenseFeature } from '@shared/enums/license-feature.enum';
import { Questions } from '@shared/enums/questions.enum';
import {
  ApiKey,
  CustomIntegration,
  IntegrationConfig,
  IntegrationQuestionPropertyLink,
  IntegrationSurveyLink,
  IntegrationSurveyStatus,
  IntegrationSyncStatus,
  ServiceIntegration,
  ServiceIntegrationList,
  ServiceIntegrationProperty,
} from '@shared/models/integrations.model';
import { Status } from '@shared/models/status.model';
import { RouterStateParams } from '@shared/serializers/custom-router-state.serializer';
import { IntegrationsManager } from '@shared/services/integrations-manager.service';
import { SourceTypeService } from '@shared/services/source-type.service';
import { GetContactColumns, GetContactLists } from '@shared/states/contacts.actions';
import {
  AuthenticateIntegration,
  AuthenticateIntegrationTask,
  AuthorizeCustomIntegration,
  CancelSyncIntegration,
  ConfigureCustomService,
  ConnectPartnerService,
  CreateCustomIntegration,
  DeleteApiKey,
  DeleteIntegration,
  DeleteSurveyIntegration,
  DeleteSurveyIntegrationPropertyLink,
  DisconnectPartnerService,
  ExportListFromIntegration,
  FinalizeAuthentication,
  GenerateApiKey,
  GetApiKeys,
  GetIntegrationLists,
  GetIntegrationProperties,
  GetIntegrationsTemplates,
  GetServiceIntegrations,
  ImportListFromIntegration,
  RecreateApiKey,
  RemoveLinkIntegration,
  RemoveListIntegration,
  RemovePropertyIntegration,
  RenameIntegration,
  RequestIntegration,
  SaveSurveyIntegration,
  SaveSyncIntegration,
  SetToken,
  SyncSurveyIntegration,
  ToggleIntegrationConnection,
  ToggleSurveyIntegration,
  UpdateApiKey,
  UpdateCustomIntegration,
  UpdateListIntegration,
  UpdatePropertyIntegration,
} from '@shared/states/integrations.actions';
import { RouterState as SharedRouterState } from '@shared/states/router.state';
import { SurveyState, SurveyStateModel } from '@shared/states/survey.state';
import { assertArray } from '@shared/utilities/array.utilities';
import {
  getPropertyLinksFromSurvey,
  hasNewIntegrationItems,
  hasNewItems,
} from '@shared/utilities/integration.utilities';
import { isEmpty, pickBy } from '@shared/utilities/object.utilities';
import { BillingState, BillingStateModel } from './billing.state';

export interface IntegrationsStateModel {
  apiKeys: ApiKey[];
  services: ServiceIntegration[];
  serviceLists: Record<string, ServiceIntegrationList[]>;
  serviceProperties: Record<string, ServiceIntegrationProperty[]>;
  templates: CustomIntegration[];
  token?: string;
}

@Injectable()
@State<IntegrationsStateModel>({
  name: 'integrations',
  defaults: {
    apiKeys: [],
    services: [],
    serviceLists: {},
    serviceProperties: {},
    templates: [],
  },
})
export class IntegrationsState {
  @Selector()
  static apiKeys(state: IntegrationsStateModel): ApiKey[] {
    return state.apiKeys.filter((key) => !key?.integrationKey);
  }

  @Selector()
  static services(state: IntegrationsStateModel): ServiceIntegration[] {
    return state.services;
  }

  @Selector()
  static authenticatedServices(state: IntegrationsStateModel): ServiceIntegration[] {
    return state.services.filter((service) => !!service.authCode);
  }

  @Selector()
  static serviceLists(state: IntegrationsStateModel): Record<string, ServiceIntegrationList[]> {
    return state.serviceLists || {};
  }

  @Selector()
  static serviceProperties(state: IntegrationsStateModel): Record<string, ServiceIntegrationProperty[]> {
    return state.serviceProperties;
  }

  @Selector()
  static connectedServices(state: IntegrationsStateModel): ServiceIntegration[] {
    return state.services.filter((service) => !!(service.connected || service.interrupted));
  }

  @Selector([BillingState])
  static hasConnectedServices(state: IntegrationsStateModel, { activePlan }: BillingStateModel): boolean {
    const hasFeature = LicenseFeature.planHasFeature(activePlan?.plan?.id, LicenseFeature.Integrations);
    return hasFeature && state.services.some((service) => !!(service.connected || service.interrupted));
  }

  static integration(key: string) {
    return createSelector([IntegrationsState], (state: IntegrationsStateModel): ServiceIntegration | undefined =>
      state.services?.find((service) => service.$key === key),
    );
  }

  static apiKey(key: string) {
    return createSelector([IntegrationsState], (state: IntegrationsStateModel): ApiKey | undefined =>
      state.apiKeys?.find((api) => api.$key === key),
    );
  }

  static getIntegrationLists(key: string) {
    return createSelector(
      [IntegrationsState],
      (state: IntegrationsStateModel): ServiceIntegrationList[] => state.serviceLists?.[key] || [],
    );
  }

  static getIntegrationProperties(key: string) {
    return createSelector(
      [IntegrationsState],
      (state: IntegrationsStateModel): ServiceIntegrationProperty[] => state.serviceProperties?.[key] || [],
    );
  }

  @Selector([RouterState])
  static currentIntegration(
    { services, templates }: IntegrationsStateModel,
    router: RouterStateModel,
  ): ServiceIntegration | CustomIntegration | undefined {
    const { integrationId, integration } = SharedRouterState.routeParams({ router } as any);
    const service: any = integrationId && services.find(({ $key }) => $key === integrationId);

    if (integration === 'hubspot') {
      return service || ({ type: integration.toLowerCase() } as ServiceIntegration);
    } else if (!!integration) {
      const template = integration && templates.find(({ type }) => type === integration);
      const logo = IntegrationsState.integrationLogo(template);
      const name = service?.name || template?.name;
      const config = { ...template?.config, ...service?.config };
      const auth = service?.auth || null;

      // console.warn(service);

      return {
        ...template,
        logo,
        name,
        ...IntegrationsState.integrationActiveStatus(service || template),
        config,
        auth,
      };
    }
  }

  @Selector([RouterState])
  static someConfigurationOptions({ services, templates }: IntegrationsStateModel, router: RouterStateModel) {
    const { integrationId } = SharedRouterState.routeParams({ router } as any);
    const service: any = integrationId && services.find(({ $key }) => $key === integrationId);
    const template = templates.find(({ type }) => type === service?.type);

    const custom = service?.config?.custom || {};
    const options = template?.config?.options || [];

    const values = pickBy(custom, (value, key) =>
      options.some((option) => option.name === key && option.shouldRefreshConfig),
    );
    return JSON.stringify(values);
  }

  @Selector([RouterState])
  static currentIntegrationLists(state: IntegrationsStateModel, router: RouterStateModel): ServiceIntegrationList[] {
    const { integrationId } = SharedRouterState.routeParams({ router } as any);

    return state.serviceLists[integrationId] || [];
  }

  @Selector([RouterState])
  static currentIntegrationProperties(
    state: IntegrationsStateModel,
    router: RouterStateModel,
  ): ServiceIntegrationProperty[] {
    const { integrationId } = SharedRouterState.routeParams({ router } as any);

    return state.serviceProperties[integrationId] || [];
  }

  static integrationBySurvey(surveyKey?: string) {
    return createSelector(
      [IntegrationsState, RouterState],
      (state: IntegrationsStateModel, router: { state: RouterStateParams }): ServiceIntegration => {
        const survey = surveyKey || SharedRouterState.routeParam('survey')({ router });

        return state.services.find((service) => !!service.config?.surveys?.[survey]);
      },
    );
  }

  static surveyIntegration(surveyKey?: string) {
    return createSelector(
      [IntegrationsState, RouterState],
      (state: IntegrationsStateModel, router: { state: RouterStateParams }): IntegrationSurveyLink | undefined => {
        const survey = surveyKey || SharedRouterState.routeParam('survey')({ router });

        return state.services.reduce(
          (surveyIntegration, service) => surveyIntegration || service.config?.surveys?.[survey],
          void 0 as IntegrationSurveyLink | undefined,
        );
      },
    );
  }

  static surveyIntegrationStatus(survey?: string) {
    return createSelector(
      [IntegrationsState, RouterState],
      (state: IntegrationsStateModel, router?: { state: RouterStateParams }): IntegrationSurveyStatus => {
        const authenticated = IntegrationsState.authenticatedServices(state);

        survey ??= SharedRouterState.routeParam('survey')({ router });

        const service = authenticated.find(({ config }) => !!config?.surveys?.[survey]);

        const integration = service?.config?.surveys?.[survey];

        if (!integration) {
          return authenticated.some(({ connected, interrupted }) => connected || interrupted)
            ? IntegrationSurveyStatus.NotIntegrated
            : IntegrationSurveyStatus.NotConnected;
        }

        if (!service.connected && !service.interrupted) {
          return IntegrationSurveyStatus.Disconnected;
        }

        return integration.active ? IntegrationSurveyStatus.Integrated : IntegrationSurveyStatus.Inactive;
      },
    );
  }

  static getQuestionIntegrations(questionKey: string, surveyKey?: string) {
    return createSelector(
      [IntegrationsState, RouterState, SurveyState, BillingState],
      (
        state: IntegrationsStateModel,
        router: { state: RouterStateParams },
        surveyState: SurveyStateModel,
        billing: BillingStateModel,
      ): IntegrationQuestionPropertyLink[] => {
        if (!IntegrationsState.hasConnectedServices(state, billing)) {
          return [];
        }

        const survey = surveyKey || SharedRouterState.routeParam('survey')({ router });

        const service = state.services.find(({ config }) => !!config?.surveys?.[survey]);

        if (!service) {
          return [];
        }

        const integration = service.config?.surveys?.[survey];

        if (!integration) {
          return [];
        }

        const questions = surveyState?.[survey]?.questions || [];
        const question = questions.find(({ $key }) => $key === questionKey);
        const targets = Questions.group(question)
          ? Questions.groupQuestions(question, questions)
          : assertArray(question);
        const targetKeys = targets.map(({ $key }) => $key);

        if (!targetKeys.length) {
          targetKeys.push(questionKey);
        }

        const links = getPropertyLinksFromSurvey(integration).filter((link) => targetKeys.includes(link.itemId));

        if (!links.length) {
          return [];
        }

        const properties = IntegrationsState.getIntegrationProperties(service.$key)(state);

        return links.map((link) => ({
          link,
          service,
          property: properties.find((prop) => prop.id === link.integrationId),
          question,
        }));
      },
    );
  }

  static integrationActiveStatus(service: ServiceIntegration | CustomIntegration) {
    let status: Status = Status.Offline;
    if (service?.interrupted) {
      status = Status.Warning;
    } else if ('auth' in service && !service?.auth?.access_token) {
      status = Status.Warning;
    } else if (service?.connected) {
      status = Status.Online;
    }

    const active: boolean = '$key' in (service as ServiceIntegration);
    const $key = service.$key || null;
    const creator = service?.creator || null;

    return { active, status, $key, creator };
  }

  static integrationLogo(service: ServiceIntegration | CustomIntegration) {
    return 'logo' in service ? `${environment.storageAddress}/${service.logo}` : null;
  }

  @Selector()
  static integrationTemplates({ templates }: IntegrationsStateModel): CustomIntegration[] {
    return templates.map((template) => {
      const logo = IntegrationsState.integrationLogo(template);

      return { ...template, logo };
    });
  }

  @Selector()
  static configuredServices({ services }: IntegrationsStateModel): (CustomIntegration | ServiceIntegration)[] {
    return services.filter((service) => !isEmpty(service?.config));
  }

  @Selector()
  static customIntegrations({ templates, services }: IntegrationsStateModel): CustomIntegration[] {
    return services
      .map((service) => {
        const template = templates.find((it) => String(it.type) === String(service.type));
        if (template) {
          const logo = IntegrationsState.integrationLogo(template);
          const name = service?.name || template.name;

          return {
            ...template,
            ...service,
            logo,
            name,
            ...IntegrationsState.integrationActiveStatus(service),
          } as CustomIntegration;
        }
      })
      .filter(Boolean);
  }

  @Selector()
  static token({ token }: IntegrationsStateModel) {
    return token;
  }

  constructor(private im: IntegrationsManager, private st: SourceTypeService) {
    this.st.registerSourceType('integration', {
      action: GetServiceIntegrations,
      storeSelector: (key: string) => IntegrationsState.integration(key),
      obs: {},
    });
    this.st.registerSourceType('api', {
      action: GetApiKeys,
      storeSelector: (key: string) => IntegrationsState.apiKey(key),
      obs: {},
    });
  }

  @Action(GetApiKeys)
  getApiKeys({ patchState }: StateContext<IntegrationsStateModel>): Observable<any> {
    return this.im.getApiKeys().pipe(map((apiKeys) => patchState({ apiKeys })));
  }

  @Action(GenerateApiKey)
  generateApiKey({ patchState, getState }: StateContext<IntegrationsStateModel>): Observable<ApiKey> {
    return this.im.generateApiKey().pipe(
      tap((apiKey) =>
        apiKey
          ? patchState({
              apiKeys: [...getState().apiKeys, apiKey],
            })
          : void 0,
      ),
    );
  }

  @Action(DeleteApiKey)
  deleteApiKey({ getState, patchState }: StateContext<IntegrationsStateModel>, { key }: DeleteApiKey): Observable<any> {
    const { apiKeys } = getState();

    return this.im.deleteApiKey(key).pipe(
      map((deleted) =>
        patchState({
          apiKeys: deleted ? apiKeys.filter(({ $key }) => $key !== key) : apiKeys,
        }),
      ),
    );
  }

  @Action(UpdateApiKey)
  updateApiKey(
    { getState, patchState, dispatch }: StateContext<IntegrationsStateModel>,
    { key, update }: UpdateApiKey,
  ): Observable<any> {
    const apiKeys = [...getState().apiKeys];
    const idx = apiKeys.findIndex(({ $key }) => $key === key);
    const apiKey = {
      ...apiKeys[idx],
      ...update,
    };

    return this.im.updateApiKey(key, update).pipe(
      switchMap((updated) => {
        if (updated && apiKey.token.includes('•')) {
          apiKeys[idx] = apiKey;

          patchState({ apiKeys });

          return of(void 0);
        } else if (updated) {
          return dispatch(new GetApiKeys());
        } else {
          return of(void 0);
        }
      }),
    );
  }

  @StreamAction(GetIntegrationsTemplates)
  getIntegrationsTemplates({ patchState }: StateContext<IntegrationsStateModel>) {
    return this.im.getIntegrationsTemplates().pipe(map((templates) => patchState({ templates })));
  }

  @StreamAction(GetServiceIntegrations)
  getServiceIntegrations({ patchState }: StateContext<IntegrationsStateModel>): Observable<any> {
    return this.im.getServiceIntegrations().pipe(map((services) => patchState({ services })));
  }

  @Action(RequestIntegration)
  requestIntegration(ctx: StateContext<IntegrationsStateModel>, { data }: RequestIntegration): Observable<any> {
    return this.im.requestIntegration(data);
  }

  @Action(AuthenticateIntegration)
  authenticateIntegration(
    { getState }: StateContext<IntegrationsStateModel>,
    { service }: AuthenticateIntegration,
  ): Observable<any> {
    return this.im.authenticateIntegration(service);
  }

  @Action(AuthenticateIntegrationTask)
  AuthenticateIntegrationTask(
    { getState }: StateContext<IntegrationsStateModel>,
    { service, integrationId }: AuthenticateIntegrationTask,
  ): Observable<any> {
    return this.im.authenticateCustomIntegration(service, integrationId);
  }

  @Action(AuthorizeCustomIntegration)
  AuthorizeCustomIntegration(
    { getState }: StateContext<IntegrationsStateModel>,
    { integrationId, update }: AuthorizeCustomIntegration,
  ): Observable<any> {
    return this.im.authorizeCustomIntegration(integrationId, update);
  }

  @Action(CreateCustomIntegration)
  createCustomIntegration(
    { getState }: StateContext<IntegrationsStateModel>,
    { service }: CreateCustomIntegration,
  ): Observable<any> {
    return this.im.connectCustomIntegration(service);
  }

  @Action(FinalizeAuthentication)
  finalizeAuthentication(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, code }: FinalizeAuthentication,
  ): Observable<any> {
    return this.im
      .finalizeAuthentication(integrationId, code)
      .pipe(tap((update) => this.updateIntegration(ctx, integrationId, update)));
  }

  @Action(ToggleIntegrationConnection)
  toggleIntegrationConnection(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, connected }: ToggleIntegrationConnection,
  ): Observable<any> {
    const update: Partial<ServiceIntegration> = {
      connected,
    };

    if (connected) {
      update.interrupted = false;
    }

    this.updateIntegration(ctx, integrationId, update);

    return this.im.updateIntegration(integrationId, update);
  }

  @Action(DeleteIntegration)
  deleteIntegration(ctx: StateContext<IntegrationsStateModel>, { integrationId }: DeleteIntegration): Observable<any> {
    return this.im
      .deleteIntegration(integrationId)
      .pipe(tap(() => ctx.dispatch(new Navigate(['settings/integrations']))));
  }

  @Action(GetIntegrationLists)
  getIntegrationLists(
    { getState, patchState }: StateContext<IntegrationsStateModel>,
    { integrationId }: GetIntegrationLists,
  ): Observable<any> {
    return this.im.getIntegrationLists(integrationId).pipe(
      tap((lists) => {
        const serviceLists = { ...getState().serviceLists };
        serviceLists[integrationId] = lists;

        patchState({ serviceLists });
      }),
    );
  }

  @Action(GetIntegrationProperties)
  getIntegrationProperties(
    { getState, patchState }: StateContext<IntegrationsStateModel>,
    { integrationId }: GetIntegrationLists,
  ): Observable<any> {
    return this.im.getIntegrationProperties(integrationId).pipe(
      tap((properties) => {
        const serviceProperties = { ...getState().serviceProperties };
        serviceProperties[integrationId] = properties;

        patchState({ serviceProperties });
      }),
    );
  }

  @Action(UpdateListIntegration)
  updateListIntegration(
    { getState, dispatch }: StateContext<IntegrationsStateModel>,
    { integrationId, link }: UpdateListIntegration,
  ): Observable<any> {
    const integration = getState().services.find((service) => service.$key === integrationId);
    const config = { ...integration?.config };

    const lists = [...(config.lists || [])];
    const linkIdx = lists.findIndex(({ $key }) => link.$key === $key);

    if (linkIdx === -1) {
      lists.push(link);
    } else {
      lists[linkIdx] = link;
    }

    config.lists = lists;

    if (integration?.type === 'hubspot') {
      return dispatch(new SaveSyncIntegration(integrationId, config));
    } else {
      return dispatch(new UpdateCustomIntegration(integrationId, { lists }));
    }
  }

  @Action(UpdatePropertyIntegration)
  updatePropertyIntegration(
    { getState, dispatch }: StateContext<IntegrationsStateModel>,
    { integrationId, link }: UpdatePropertyIntegration,
  ): Observable<any> {
    const integration = getState().services.find((service) => service.$key === integrationId);
    const config = { ...integration?.config };

    const properties = [...(config.properties || [])];
    const linkIdx = properties.findIndex(({ $key }) => link.$key === $key);

    if (linkIdx === -1) {
      properties.push(link);
    } else {
      properties[linkIdx] = link;
    }

    config.properties = properties;
    if (integration?.type === 'hubspot') {
      return dispatch(new SaveSyncIntegration(integrationId, config));
    } else {
      return dispatch(new UpdateCustomIntegration(integrationId, { properties }));
    }
  }

  @Action(RemoveListIntegration)
  removeListIntegration(ctx: StateContext<IntegrationsStateModel>, action: RemoveListIntegration): Observable<any> {
    return this.removeLinkIntegration(ctx, action, 'lists');
  }

  @Action(RemovePropertyIntegration)
  removePropertyIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    action: RemovePropertyIntegration,
  ): Observable<any> {
    return this.removeLinkIntegration(ctx, action, 'properties');
  }

  @Action(SaveSyncIntegration)
  saveSyncIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, config }: SaveSyncIntegration,
  ): Observable<any> {
    return this.im.saveSyncIntegration(integrationId, config).pipe(
      tap((update) => this.updateIntegration(ctx, integrationId, update)),
      tap(() => this.updateIntegrationStatus(ctx, integrationId, { active: true })),
      concatMap(() => {
        const maps: Observable<any>[] = [];

        if (hasNewItems(config.lists)) {
          maps.push(ctx.dispatch(new GetContactLists({ start: 0 })));
        }

        if (hasNewIntegrationItems(config?.lists)) {
          maps.push(ctx.dispatch(new GetIntegrationLists(integrationId)));
        }

        if (hasNewItems(config.properties)) {
          maps.push(ctx.dispatch(new GetContactColumns()));
        }

        if (hasNewIntegrationItems(config.properties)) {
          maps.push(ctx.dispatch(new GetIntegrationProperties(integrationId)));
        }

        return maps.length ? forkJoin(maps) : of(void 0);
      }),
    );
  }

  @Action(CancelSyncIntegration)
  cancelSyncIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId }: CancelSyncIntegration,
  ): Observable<any> {
    return this.im.cancelSyncIntegration(integrationId).pipe(
      tap((update) => this.updateIntegration(ctx, integrationId, update)),
      tap(() => this.updateIntegrationStatus(ctx, integrationId, { active: false })),
      tap(() => this.updateIntegrationStatus(ctx, integrationId, { active: false }, 'automatic')),
    );
  }

  @Action(ImportListFromIntegration)
  importListFromIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, linkListId }: ImportListFromIntegration,
  ): Observable<any> {
    this.updateIntegrationStatus(ctx, integrationId, { active: true }, 'import-' + linkListId);

    return this.im.importListFromIntegration(integrationId, linkListId);
  }

  @Action(ExportListFromIntegration)
  exportListFromIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, linkListId }: ExportListFromIntegration,
  ): Observable<any> {
    this.updateIntegrationStatus(ctx, integrationId, { active: true }, 'export-' + linkListId);

    return this.im.exportListToIntegration(integrationId, linkListId);
  }

  @Action(SaveSurveyIntegration)
  saveSurveyIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, surveyKey, link }: SaveSurveyIntegration,
  ): Observable<any> {
    return this.im.saveSurveyIntegration(integrationId, surveyKey, link);
  }

  @Action(SyncSurveyIntegration)
  syncSurveyIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, surveyKey, params }: SyncSurveyIntegration,
  ): Observable<any> {
    return this.im.syncSurveyIntegration(integrationId, surveyKey, params);
  }

  @Action(DeleteSurveyIntegration)
  deleteSurveyIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, surveyKey }: DeleteSurveyIntegration,
  ): Observable<any> {
    return this.im.deleteSurveyIntegration(integrationId, surveyKey);
  }

  @Action(ToggleSurveyIntegration)
  toggleSurveyIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, surveyKey, active }: ToggleSurveyIntegration,
  ): Observable<any> {
    return this.im.toggleSurveyIntegration(integrationId, surveyKey, active);
  }

  @Action(DeleteSurveyIntegrationPropertyLink)
  deleteSurveyIntegrationPropertyLink(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, surveyKey, linkKey }: DeleteSurveyIntegrationPropertyLink,
  ): Observable<any> {
    return this.im.deleteSurveyIntegrationPropertyLink(integrationId, surveyKey, linkKey);
  }

  @Action(ConfigureCustomService)
  configureCustomService(
    { dispatch }: StateContext<IntegrationsStateModel>,
    { service }: ConfigureCustomService,
  ): Observable<any> {
    return this.im.assertAccess().pipe(
      switchMap(() => {
        const serviceType = service.type.toLowerCase();

        const url = service.$key
          ? `settings/integrations/${serviceType}/${service.$key}`
          : `settings/integrations/${serviceType}`;

        return dispatch(new Navigate([url]));
      }),
      catchError(() => of(null)),
    );
  }

  @Action(ConnectPartnerService)
  connectPartnerService(ctx: StateContext<IntegrationsStateModel>, { service }: ConnectPartnerService) {
    return this.im.connectPartnerService(service);
  }

  @Action(DisconnectPartnerService)
  disconnectPartnerService(ctx: StateContext<IntegrationsStateModel>, { service }: DisconnectPartnerService) {
    return this.im.disconnectPartnerService(service);
  }

  @Action(RecreateApiKey)
  RecreateApiKey(ctx: StateContext<IntegrationsStateModel>, { service }: RecreateApiKey) {
    return this.im.recreateApiKey(service);
  }

  @Action(SetToken)
  SetToken({ patchState }: StateContext<IntegrationsStateModel>, { token }: SetToken): void {
    patchState({ token });
  }

  @Action(UpdateCustomIntegration)
  UpdateCustomIntegration(
    ctx: StateContext<IntegrationsStateModel>,
    { integrationId, update }: UpdateCustomIntegration,
  ) {
    return this.im.updateCustomIntegration(integrationId, update);
  }

  @Action(RenameIntegration)
  RenameIntegration(ctx: StateContext<IntegrationsStateModel>, { integrationId, name }: RenameIntegration) {
    return this.im.updateIntegration(integrationId, { name });
  }

  private removeLinkIntegration(
    { getState, dispatch }: StateContext<IntegrationsStateModel>,
    { integrationId, linkId }: RemoveLinkIntegration,
    link: 'properties' | 'lists',
  ): Observable<void> {
    const config = {
      ...getState().services.find((service) => service.$key === integrationId)?.config,
    };

    const links = [...config[link]];
    const linkIdx = links.findIndex(({ $key }) => linkId === $key);

    if (linkIdx > -1) {
      links.splice(linkIdx, 1);
    }

    config[link] = links as any[];

    return dispatch(new SaveSyncIntegration(integrationId, config));
  }

  private updateIntegration(
    { getState, patchState }: StateContext<IntegrationsStateModel>,
    integrationId: string,
    update: Partial<ServiceIntegration> | null,
  ): void {
    if (!update) {
      return;
    }

    const services = [...getState().services];
    const idx = services.findIndex(({ $key }) => integrationId === $key);

    if (idx > -1) {
      services[idx] = {
        ...services[idx],
        ...update,
      };

      patchState({ services });
    }
  }

  private updateIntegrationStatus(
    { getState, patchState }: StateContext<IntegrationsStateModel>,
    integrationId: string,
    update: Partial<IntegrationSyncStatus>,
    key: string = 'global',
  ): void {
    const services = [...getState().services];
    const idx = services.findIndex(({ $key }) => integrationId === $key);

    if (idx > -1) {
      services[idx] = {
        ...services[idx],
        status: {
          ...(services[idx]?.status || {}),
          [key]: {
            ...(services[idx]?.status?.[key] || {}),
            ...update,
          },
        } as {
          global: IntegrationSyncStatus;
          automatic: IntegrationSyncStatus;
          [listLinkId: string]: IntegrationSyncStatus;
        },
      };

      patchState({ services });
    }
  }
}

import * as d3 from 'd3';

import {
  Directive,
  ElementRef,
  OnChanges,
  Input,
  Output,
  HostListener,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';

import { CanvasContext } from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawRoundRect } from '@shared/utilities/canvas.utilities';

/**
 * This is a horizontal multi bar chart.
 */
@Directive({
  selector: '[multiBarChartH]',
})
export class MultiBarChartH implements OnChanges {
  @Input() values: number[] = [];
  @Input() previousValues: number[] = [];
  @Input() scale: [number, number] = [0, 100];
  @Input() previousScale: [number, number] = [0, 100];
  @Input() colors: number[] = [];
  @Input() drawHelperLines: boolean = false;
  @Input() drawScale: boolean = true;
  @Input() showNumbers: boolean = true;
  @Input() percentageValues: boolean = false;
  @Input() previousPercentageValues: boolean = false;
  @Input() transitionDuration: number = 0;
  @Input() hover: boolean = false;
  @Input() update: Date = new Date();
  @Input() touchDevice: boolean = false;
  @Input() currentHoveredBar: number = null;
  @Input() selections: boolean | boolean[] = false;

  @Output() hoveredBar: EventEmitter<number> = new EventEmitter<number>();

  private base: any;
  private context: CanvasContext = {} as CanvasContext;
  private canvas: any;

  private scaleX: any;

  private width: number;
  private height: number;
  private margin: { [s: string]: number };
  private fontSize: number = 0;

  private previousHover: boolean = false;
  private currentItemBelow: number = null;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.values ||
      changes.scale ||
      changes.colors ||
      changes.drawHelperLine ||
      changes.drawScale ||
      changes.transitionDuration ||
      (changes.hover && this.previousHover !== this.hover) ||
      changes.update ||
      changes.showNumbers ||
      changes.previousValues ||
      changes.percentageValues ||
      changes.previousScale ||
      changes.currentHoveredBar
    ) {
      this.updateChart(changes.values || changes.scale);
      this.previousHover = this.hover;
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'multi-bar-chart-h')
      .style('position', 'relative');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = (this.hover ? 'bold ' : 'normal ') + (12 / 14) * this.fontSize + 'px Open Sans';
    const max = context.measureText('99999').width + 4;

    const sideMargin: number = Math.max(this.fontSize, max);

    this.margin = {
      top: 8,
      right: sideMargin,
      bottom: 8,
      left: this.scale[0] === 0 ? this.fontSize : sideMargin,
    };

    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;

    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.scaleX = d3.scaleLinear().rangeRound([0, this.width]).domain(this.scale);
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice) {
        const area = d3.pointer(event);
        const itemBelow = __this.itemsBelow(area);

        if (__this.currentItemBelow !== itemBelow) {
          __this.hoveredBar.emit(itemBelow);
        }
        __this.currentItemBelow = itemBelow;
      }
    };

    const drawContent = function (d) {
      const context = d3.select(this).node().getContext('2d');
      const checkArraySimilarity: (arr1: number[], arr2: number[]) => boolean = (arr1: number[], arr2: number[]) =>
        JSON.stringify(arr1) === JSON.stringify(arr2);

      if (
        dataChanges &&
        __this.previousValues != null &&
        (!checkArraySimilarity(__this.previousValues, __this.values) ||
          !checkArraySimilarity(__this.previousScale, __this.scale)) &&
        __this.previousPercentageValues === __this.percentageValues &&
        __this.transitionDuration > 0
      ) {
        const interpolateValue = d3.interpolateArray(__this.previousValues, __this.values);
        const interpolateMin = d3.interpolateNumber(__this.previousScale[0], __this.scale[0]);
        const interpolateMax = d3.interpolateNumber(__this.previousScale[1], __this.scale[1]);
        const ease = d3.easeCubic;

        const timer = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;
          let data;
          let scale;

          if (step >= 1) {
            data = interpolateValue(ease(1));
            scale = __this.scaleX;

            timer.stop();
          } else {
            data = interpolateValue(ease(step));
            scale = d3
              .scaleLinear()
              .rangeRound([0, __this.width])
              .domain([interpolateMin(ease(step)), interpolateMax(ease(step))]);
          }

          __this.setRects(context, data, scale);
        });
      } else {
        __this.setRects(context, d, __this.scaleX);
      }

      __this.context = { context, data: d };
    };

    this.canvas = this.base.selectAll('.multi-bar-chart-h-canvas').data([this.values]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'multi-bar-chart-h-canvas')
      .style('position', 'absolute')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', function (event, d) {
        __this.hoveredBar.emit(null);
      })
      .each(drawContent);
  }

  setRects(context, values, scale) {
    context.clearRect(
      0,
      0,
      this.width + this.margin.right + this.margin.left,
      this.height + this.margin.top + this.margin.bottom,
    );

    context.strokeStyle = Colors.HELPERLINE;
    context.beginPath();
    context.moveTo(this.margin.left + scale(0), 0);
    context.lineTo(this.margin.left + scale(0), this.height + this.margin.top + this.margin.bottom);
    context.closePath();
    context.stroke();

    if (this.drawHelperLines) {
      const tickCount = this.percentageValues ? 4 : this.scale[1] > 3 ? 4 : this.scale[1] > 2 ? 2 : 1;
      const ticks = scale.ticks(tickCount);

      context.font = 10 / 14 + 'em Open Sans';
      context.fillStyle = Colors.TEXT;
      context.strokeStyle = Colors.HELPERLINE;
      context.lineWidth = 1;
      context.textAlign = 'center';
      context.textBaseline = 'top';
      ticks.forEach((d) => {
        if (!(this.percentageValues && d > 1)) {
          const x = this.margin.left + scale(d);
          context.beginPath();
          context.moveTo(x, 0);
          context.lineTo(x, this.height + this.margin.top + this.margin.bottom);
          context.stroke();
        }
      });
    }

    for (let i = 0, len = values.length; i < len; i++) {
      const val: number = !isNaN(values[i]) ? values[i] : 0;

      context.fillStyle = this.colors[i];
      context.globalAlpha = 1;
      context.lineWidth = null;

      if (this.hover && (this.currentHoveredBar == null || this.currentHoveredBar === i)) {
        context.lineWidth = 2;
        context.strokeStyle = Colors.HIGHLIGHT;
      }

      if (typeof this.selections === 'object' && this.selections.length) {
        context.globalAlpha = !this.selections[i] ? 0.2 : 1;
      } else if (this.currentHoveredBar != null) {
        context.globalAlpha = this.currentHoveredBar !== i ? 0.2 : 1;
      }

      const x = scale(val > 0 ? 0 : val) + this.margin.left;
      const y = this.margin.top + i * (this.height / len) + 2;
      const w = val > 0 ? scale(val) - scale(0) : scale(0) - scale(val);
      const h = this.height / len - 4;
      const r = { tl: 0, tr: 5, bl: 0, br: 5 };
      const hov = this.hover && (this.currentHoveredBar == null || this.currentHoveredBar === i);

      if (val) {
        drawRoundRect(context, x, y, w, h, r, true, hov);
      }

      if (
        this.showNumbers ||
        (this.hover && (this.currentHoveredBar == null || this.currentHoveredBar === i)) ||
        (!this.hover && this.currentHoveredBar === i)
      ) {
        context.font =
          (this.hover && (this.currentHoveredBar === i || this.currentHoveredBar == null) ? 'bold ' : 'normal ') +
          12 / 14 +
          'em Open Sans';
        context.textBaseline = 'middle';
        context.textAlign = val >= 0 ? 'start' : 'end';
        context.fillStyle = Colors.TEXT;

        const textPos: number = scale(val) + this.margin.left + (val >= 0 ? 4 : -4);
        context.fillText(
          !isNaN(val) ? (this.percentageValues ? (val * 100).toFixed(1) + '%' : Math.round(val)) : '-',
          textPos,
          this.margin.top + (i * (this.height / len) + 2) + (this.height / len - 4) / 2,
        );
      }
    }
  }

  itemsBelow(area): number {
    for (let i = 0, len = this.values.length; i < len; i++) {
      const val: number = !isNaN(this.values[i]) ? this.values[i] : 0;
      let xLeft;
      let xRight;
      let yTop;
      let yBottom;

      if (val > 0) {
        xLeft = this.scaleX(0) + this.margin.left;
        xRight = xLeft + this.scaleX(val) - this.scaleX(0);
        yTop = this.margin.top + i * (this.height / len);
        yBottom = yTop + this.height / len;
      } else if (val < 0) {
        xLeft = this.scaleX(val) + this.margin.left;
        xRight = xLeft + this.scaleX(0) - this.scaleX(val);
        yTop = this.margin.top + i * (this.height / len);
        yBottom = yTop + this.height / len;
      }

      if (area[0] > xLeft && area[0] < xRight && area[1] < yBottom && area[1] > yTop) {
        return i;
      }
    }

    return null;
  }
}

import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Select, Store } from '@ngxs/store';
import { Rights } from '@shared/enums/rights.enum';
import { TeamData } from '@shared/models/account.model';
import { IdentityData } from '@shared/models/prefs.model';
import { PrefsManager } from '@shared/services/prefs-manager.service';
import { AccountState } from '@shared/states/account.state';
import { TrialStatusRead } from '@shared/states/billing.actions';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

/* eslint-disable @typescript-eslint/no-shadow */
export enum TrialExpiredResponse {
  Cancel = 1,
  Revert,
  Select,
}
/* eslint-enable @typescript-eslint/no-shadow */

@Component({
  templateUrl: './trial-expired.dialog.html',
  styleUrls: ['./trial-expired.dialog.scss'],
})
export class TrialExpired {
  responses = TrialExpiredResponse;

  @Select(AccountState.team)
  team$!: Observable<TeamData>;

  @Select(AccountState.teamLogo)
  teamLogo$: Observable<string>;

  readonly owner$: Observable<IdentityData | null> = this.store.select(AccountState.isTeamOwner).pipe(
    concatMap((isOwner) => (isOwner ? of(null) : this.store.select(AccountState.team))),
    concatMap((team: TeamData | null) => {
      if (team) {
        const [owner] = Object.entries(team.users).find(([_, rt]) => Rights.hasRights(Rights.OWNER, rt)) || [''];

        if (owner) {
          return this.pm.loadIdentity(owner);
        }
      }

      return of(null);
    }),
  );
  dialogTitle = $localize`:dialog title@@zef-i18n-00162:Trial Period is Over`;
  dialogLabel = $localize`:dialog label@@zef-i18n-00098:Unnamed organization`;
  dialogHeader = $localize`:dialog header@@zef-i18n-00163:Select new plan or stay on the current one.`;

  constructor(
    readonly store: Store,
    readonly pm: PrefsManager,
    readonly md: MatDialogRef<TrialExpired, TrialExpiredResponse>,
  ) {
    this.md.afterClosed().subscribe((response) => {
      this.store.dispatch(new TrialStatusRead(response || TrialExpiredResponse.Cancel));
    });
  }
}

/**
 * Service for storing and retrieving property values.
 *
 * @unstable
 */

import { combineLatest, ReplaySubject } from 'rxjs';
import { takeUntil, startWith, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Outcomes } from '@shared/enums/outcomes.enum';
import { PropertyStore } from '@shared/services/property-store.service';
import { PropertyFallbacks, TeamProperty, OtherProperty, SurveyProperty } from '@shared/enums/properties.enum';

import { LanguageManager } from '@player/shared/services/language-manager.service';

@Injectable()
export class PlayerStore extends PropertyStore {
  constructor(readonly language: LanguageManager) {
    super();
  }

  public init() {
    combineLatest(this.survey, this.team, this.outcomes)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([survey, team, outcomes]) => {
        if (survey) {
          this.set(SurveyProperty.NAME, survey.title || PropertyFallbacks.EMPTY);
          this.set(SurveyProperty.DESCRIPTION, survey.description || PropertyFallbacks.POWERED_BY);
          this.set(OtherProperty.START_BUTTON, survey.start || PropertyFallbacks.START);
        }

        if (team) {
          this.set(TeamProperty.NAME, team.name || PropertyFallbacks.EMPTY);
        }

        outcomes.forEach((item) => {
          this.set(OtherProperty.OUTCOME_TITLE, item.title || PropertyFallbacks.EMPTY, item.$key);
          this.set(
            OtherProperty.OUTCOME_DESCRIPTION,
            item.content || item.title || PropertyFallbacks.POWERED_BY,
            item.$key,
          );

          if (item.type === Outcomes.OUTCOME) {
            this.set(OtherProperty.MATCH_PERCENTAGE, `${0}%`, item.$key);
            this.set(OtherProperty.OUTCOME_CORRECT_ANSWERS, `${0}%`, item.$key);
            this.set(OtherProperty.OUTCOME_MAX_CORRECT_ANSWERS, `${0}%`, item.$key);
          }
        });
      });

    combineLatest(
      this.scoredOutcomes,
      this.survey.pipe(mergeMap((survey) => this.language.changeLocale.pipe(startWith(survey.language)))),
    )
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([scoredOutcomes, language]) => {
        scoredOutcomes.forEach((outcome) => {
          const { score, item, correct } = outcome;
          const scoreValue = score || 0;
          const correctValue = correct?.correct || 0;
          const maxCorrectValue = correct?.max || 0;
          if (item.type === Outcomes.OUTCOME) {
            this.set(OtherProperty.MATCH_PERCENTAGE, `${Math.round(scoreValue * 100)}%`, item.$key);
            this.set(OtherProperty.OUTCOME_CORRECT_ANSWERS, `${correctValue}`, item.$key);
            this.set(OtherProperty.OUTCOME_MAX_CORRECT_ANSWERS, `${maxCorrectValue}`, item.$key);
          } else if (item.type === Outcomes.GOODBYE) {
            this.set(OtherProperty.MATCH_PERCENTAGE, ``, item.$key);
            this.set(OtherProperty.OUTCOME_CORRECT_ANSWERS, ``, item.$key);
            this.set(OtherProperty.OUTCOME_MAX_CORRECT_ANSWERS, ``, item.$key);
          }
        });

        this.language.getLocaleData(language).subscribe((localeMap: Map<string, string>) => {
          // Translated properties
          this.set(SurveyProperty.NAME, localeMap.get('surveyTitle'), language);
          this.set(SurveyProperty.DESCRIPTION, localeMap.get('surveyDescription'), language);
          this.set(OtherProperty.START_BUTTON, localeMap.get('startSurvey'), language);

          scoredOutcomes
            .map((outcome) => outcome.item)
            .forEach((outcome) => {
              this.set(OtherProperty.OUTCOME_TITLE, localeMap.get(`title-${outcome.$key}`), language, outcome.$key);
              this.set(
                OtherProperty.OUTCOME_DESCRIPTION,
                localeMap.get(`content-${outcome.$key}`),
                language,
                outcome.$key,
              );
            });
        });
      });
  }

  public get(value: any, locale?: string, context?: string): string {
    // TODO: Used for fallback purposes, remove when dashes props have been removed from old surveys
    let propName: string = value.replace('-', '_');
    let returnValue: string = this.propMap.get(propName) ? this.propMap.get(propName) : null;

    [context, locale].forEach((token: string) => {
      if (token) {
        propName += `_${token}`;

        returnValue = this.propMap.get(propName) ? this.propMap.get(propName) : returnValue;
      }
    });

    return returnValue;
  }

  public set(key: any, value: string, locale?: string, context?: string) {
    if (context) {
      key = key + `_${context}`;
    }

    if (locale) {
      key = key + `_${locale}`;
    }

    const previousValue = this.get(key, locale, context);

    if (value != null && previousValue !== value) {
      this.propMap.set(key, value);
    }
  }
}

import { fromEvent, merge } from 'rxjs';
import { takeUntil, tap, filter, startWith } from 'rxjs/operators';

import { Directive, ElementRef, OnInit, Input, HostBinding, HostListener, NgZone } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

import { LifecycleHooks } from '@shared/services/lifecycle-hooks.service';

type Point = { x: number; y: number; z?: number };

@Directive({
  selector: 'img[pictureZoom]',
  exportAs: 'pictureZoom',
  providers: [LifecycleHooks],
})
export class PictureZoom implements OnInit {
  active: boolean = false;

  @Input('pictureZoomContainer')
  container?: HTMLElement;

  @HostBinding('draggable')
  readonly draggable = false;

  @HostBinding('style.pointer-events')
  readonly pointerEvents = 'none';

  private readonly transitionSpeed = 200;

  private readonly img = this.el.nativeElement;

  private handlers = {
    doubletap: this.onDoubleTap,
    pan: this.onPan,
    pinch: this.onPinch,
    panstart: this.onPanStart,
    pinchstart: this.onPinchStart,
    panend: this.onPanEnd,
    pinchend: this.onPinchEnd,
  };

  private fixHammerjsDeltaIssue: Point = { x: 0, y: 0 };

  private pinchZoomOrigin: Point = { x: 0, y: 0 };

  private pinchStart: Point = { x: 0, y: 0 };

  private lastEvent?: string;

  private originalSize = { width: this.img.clientWidth, height: this.img.clientHeight };

  private current = {
    x: 0,
    y: 0,
    z: 1,
    zooming: false,
    width: this.originalSize.width,
    height: this.originalSize.height,
  };

  private last: Point = {
    x: this.current.x,
    y: this.current.y,
    z: this.current.z,
  };

  private get element(): HTMLElement {
    return this.container || this.img;
  }

  private transitionTimeout?: number;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private hgc: HammerGestureConfig,
    private lh: LifecycleHooks,
    private nz: NgZone,
  ) {}

  ngOnInit(): void {
    this.nz.runOutsideAngular(() => {
      this.img.style.webkitTransformStyle = 'preserve-3d';
      this.img.style.webkitBackfaceVisibility = 'hidden';

      merge(
        fromEvent(this.img, 'load').pipe(
          startWith(this.img.complete),
          filter((e) => !!e),
          tap(() => this.setOriginalSize()),
        ),
        fromEvent(this.hgc.buildHammer(this.element), Object.keys(this.handlers).join(' ')).pipe(
          tap((event: any) => this.handlers[event.type]?.bind(this)(event)),
          takeUntil(this.lh.destroy),
        ),
        fromEvent(this.element, 'wheel').pipe(
          filter((event: WheelEvent) => event.ctrlKey && !!event.deltaY),
          tap((event: WheelEvent) => this.onWheel(event)),
        ),
      )
        .pipe(takeUntil(this.lh.destroy))
        .subscribe();
    });
  }

  zoomIn(): void {
    this.zoomInOut(1);
  }

  zoomOut(): void {
    this.zoomInOut(-1);
  }

  @HostListener('document:keydown.+', ['$event'])
  onPlusKey(event: KeyboardEvent): void {
    if (!event.ctrlKey) {
      this.zoomIn();
    }
  }

  @HostListener('document:keydown.-', ['$event'])
  onMinusKey(event: KeyboardEvent): void {
    if (!event.ctrlKey) {
      this.zoomOut();
    }
  }

  @HostListener('pointerup.n', ['$event'])
  @HostListener('click.n', ['$event'])
  @HostListener('mouseup.n', ['$event'])
  onPointerUp(event: PointerEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  private setOriginalSize(): void {
    this.current.width = this.originalSize.width = this.img.clientWidth;
    this.current.height = this.originalSize.height = this.img.clientHeight;
  }

  private update(constrain?: boolean): void {
    if (constrain) {
      this.current.z = this.last.z = Math.min(Math.max(1, this.current.z), 5);

      if (this.current.z === 1) {
        this.current.x = this.last.x = 0;
        this.current.y = this.last.y = 0;
      }

      this.img.style.transition = `${this.transitionSpeed}ms transform ease-in-out`;

      clearTimeout(this.transitionTimeout);
      this.transitionTimeout = window.setTimeout(() => (this.img.style.transition = null), this.transitionSpeed);
    }

    this.current.height = this.originalSize.height * this.current.z;
    this.current.width = this.originalSize.width * this.current.z;
    this.img.style.transform = `translate3d(${this.current.x}px, ${this.current.y}px, 0) scale(${this.current.z})`;
  }

  private onWheel(event: WheelEvent): void {
    event.preventDefault();
    this.zoomInOut(event.deltaY < 0 ? 1 : -1, { x: event.clientX, y: event.clientY });
  }

  private onDoubleTap(event): void {
    if (this.current.z > 1) {
      this.current.z = 1;
      this.update(true);
    } else {
      this.zoomInOut(1, event.center);
    }
  }

  private onPan(event): void {
    if (this.lastEvent !== 'pan') {
      this.fixHammerjsDeltaIssue = {
        x: event.deltaX,
        y: event.deltaY,
      };
    }

    const maxPosX = Math.ceil(((this.current.z - 1) * this.img.clientWidth) / 2);
    const maxPosY = Math.ceil(((this.current.z - 1) * this.img.clientHeight) / 2);

    this.current.x = this.last.x + event.deltaX - this.fixHammerjsDeltaIssue.x;
    this.current.y = this.last.y + event.deltaY - this.fixHammerjsDeltaIssue.y;

    this.current.x = Math.max(Math.min(this.current.x, maxPosX), -maxPosX);
    this.current.y = Math.max(Math.min(this.current.y, maxPosY), -maxPosY);

    this.lastEvent = 'pan';
    this.update();
  }

  private onPinch(event): void {
    const d = this.scaleFrom(this.pinchZoomOrigin, this.last.z, this.last.z * event.scale);
    this.current.x = d.x + this.last.x + event.deltaX;
    this.current.y = d.y + this.last.y + event.deltaY;
    this.current.z = d.z + this.last.z;
    this.lastEvent = 'pinch';

    this.update();
  }

  private onPanStart(): void {
    this.active = true;
  }

  private onPinchStart(event): void {
    this.active = true;

    this.pinchStart.x = event.center.x;
    this.pinchStart.y = event.center.y;
    this.pinchZoomOrigin = this.getRelativePosition(this.pinchStart);
    this.lastEvent = 'pinchstart';
  }

  private onPanEnd(): void {
    this.last.x = this.current.x;
    this.last.y = this.current.y;
    this.lastEvent = 'panend';

    setTimeout(() => (this.active = false));
    this.update(true);
  }

  private onPinchEnd(): void {
    this.last.x = this.current.x;
    this.last.y = this.current.y;
    this.last.z = this.current.z;
    this.lastEvent = 'pinchend';

    setTimeout(() => (this.active = false));
    this.update(true);
  }

  private zoomInOut(scaleFactor: number, point?: Point): void {
    if (!point) {
      const box = this.container.getBoundingClientRect();

      point = {
        x: box.left + box.width / 2,
        y: box.top + box.height / 2,
      };
    }

    const zoomOrigin = this.getRelativePosition(point);
    const d = this.scaleFrom(zoomOrigin, this.current.z, this.current.z + scaleFactor);
    this.current.x += d.x;
    this.current.y += d.y;
    this.current.z += d.z;

    this.last.x = this.current.x;
    this.last.y = this.current.y;
    this.last.z = this.current.z;

    this.update(true);
  }

  private getRelativePosition(point: Point): Point {
    const domCoords = this.getCoords();

    return {
      x: (point.x - domCoords.x) / ((this.originalSize.width * this.current.z) / 2) - 1,
      y: (point.y - domCoords.y) / ((this.originalSize.height * this.current.z) / 2) - 1,
    };
  }

  private getCoords(): Point {
    const box = this.img.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    return { x: box.left + scrollLeft - clientLeft, y: box.top + scrollTop - clientTop };
  }

  private scaleFrom(zoomOrigin: Point, currentScale: number, newScale: number): Point {
    const currentShift = this.getCoordinateShiftDueToScale(currentScale);
    const newShift = this.getCoordinateShiftDueToScale(newScale);

    return {
      x: zoomOrigin.x * (currentShift.x - newShift.x),
      y: zoomOrigin.y * (currentShift.y - newShift.y),
      z: newScale - currentScale,
    };
  }

  private getCoordinateShiftDueToScale(scale: number): Point {
    return {
      x: (scale * this.originalSize.width - this.originalSize.width) / 2,
      y: (scale * this.originalSize.height - this.originalSize.height) / 2,
    };
  }
}

import { trigger, style, transition, animate } from '@angular/animations';

export const HighlightAnimation = trigger('highlight', [
  transition(
    '* => true',
    [
      animate('750ms ease-in-out', style({ backgroundColor: '{{backgroundColor}}', color: '{{color}}' })),
      animate('250ms 1500ms ease-in-out', style({ backgroundColor: '*', color: '*' })),
    ],
    { params: { backgroundColor: '#0083ff', color: '#fff' } },
  ),
]);

/**
 * Icon component with emblem support.
 *
 * @unstable
 */
import { Component, Input, Output, EventEmitter, HostListener, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'zef-emblem',
  templateUrl: './emblem.component.html',
  styleUrls: ['./emblem.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Emblem {
  @Input() name = '';
  @Input() icon = '';
  @Input() emblem = '';

  @Input() toggle = false;
  @Input() rounded = false;
  @Input() checked = false;

  @Input() disabled = false;

  @Output() checkedChange = new EventEmitter();

  @HostListener('click', ['$event']) onClick(event: any) {
    this.toggleChecked();
  }

  constructor() {}

  public toggleChecked() {
    if (this.toggle && !this.disabled) {
      this.checked = !this.checked;

      this.checkedChange.emit({
        name: this.name,
        checked: this.checked,
      });
    }
  }
}

<mat-form-field
  [class.zef-suffix-corner]="!big"
  [class.zef-input-search-big]="big"
  [class.zef-input-search]="!big"
  [class.zef-input-space]="space"
  [class.zef-input-ghost]="ghost"
  [class.zef-input-elevated]="elevated"
>
  <div matPrefix [ngSwitch]="loading" class="z-fx-sc">
    <zef-spinner *ngSwitchCase="true" [color]="iconColor" @scale></zef-spinner>

    <mat-icon *ngSwitchDefault [class.zef-icon-large]="big" [color]="iconColor" @scale>
      {{ icon }}
    </mat-icon>
  </div>

  <input
    matInput
    type="search"
    autocomplete="off"
    [placeholder]="placeholder"
    [autoFocus]="!!autoFocus"
    [value]="value || ''"
    (input)="valueChange.emit(input.value)"
    (focus)="inputFocus.emit()"
    (blur)="inputBlur.emit()"
    #input="matInput"
  />

  <ng-container matSuffix>
    <button *ngIf="input.value" mat-icon-button (mousedown)="$event.preventDefault()" (click)="onCloseClick()">
      <mat-icon [class.zef-icon-large]="big">bullet_close</mat-icon>
    </button>
  </ng-container>
</mat-form-field>

import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import {
  ChartDistribution,
  ChartDomain,
  StatsData,
  DimensionDataItem,
  ChartLegend,
  CanvasContext,
} from '@shared/models/report.model';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon, shortenText } from '@shared/utilities/canvas.utilities';

/**
 * This is a Net Promoter Score number calculator & displayer.
 */
@Directive({
  selector: '[radarChart]',
})
export class RadarChart implements OnChanges {
  @Input() distributions: ChartDistribution[][] = [];
  @Input() stats: any[] = [];
  @Input() domain: ChartDomain[] = [];
  @Input() details: DimensionDataItem[] = [];
  @Input() zValues: boolean = false;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() comparison: any;
  @Input() showNumbers: boolean = false;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() totalAnswers: any;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;

  private base: any;

  private width: any;
  private height: any;
  private margin: any;
  private surplusMargin: any;
  private combinedMargin: any;
  private radiusFactor: any;
  private textFactor: any;
  private polygonDotRadius: number = 0;
  private fontSize: number = 0;
  private unit: number = 0;
  private marginBottomUnits: number = 0;
  private marginRightUnits: number = 0;
  private marginLeftUnits: number = 0;

  private data: any[] = [];

  private scaleX: any;
  private scaleY: any;

  private canvas: any;
  private context: CanvasContext[] = [];

  private chartTitleData: any[] = [];
  private answerCountData: any[] = [];

  private categoricalNumber: number = 0;
  private linearNumber: number = 0;

  private legends: ChartLegend[] = [];

  private tooltip: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.domain) {
      this.context = [];
    }
    if (
      changes.distributions ||
      changes.stats ||
      changes.domain ||
      changes.details ||
      changes.zValues ||
      changes.scale ||
      changes.filterInput ||
      changes.comparison ||
      changes.showNumbers ||
      changes.update ||
      changes.filtering ||
      changes.filtersDemo
    ) {
      this.updateChart(changes.distributions);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setData();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement);
    this.marginBottomUnits = 4;
    this.marginLeftUnits = 4;
    this.marginRightUnits = 4;
    this.radiusFactor = 0.8;
    this.textFactor = 0.9;
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;
    this.polygonDotRadius = 0.5 * this.unit;

    this.margin = {
      top: 3 * this.unit,
      right: this.marginRightUnits * this.unit,
      bottom: this.marginBottomUnits * this.unit,
      left: this.marginLeftUnits * this.unit,
    };
    this.linearNumber = 0;
    this.categoricalNumber = 0;
    let chartAmount = 0;

    for (const dom in this.domain) {
      if (this.domain[dom].scale === 'linear') {
        this.linearNumber += 1;
      } else if (this.domain[dom].scale === 'categorical' || this.domain[dom].scale === 'time') {
        this.categoricalNumber += 1;
      }
    }

    if (this.linearNumber > 2) {
      chartAmount = this.parseGroupNumber(this.details);
    } else {
      chartAmount =
        this.comparison && this.categoricalNumber === 2 ? this.distributions.length - 1 : this.distributions.length;
    }

    const dividedHeight = this._element.nativeElement.clientHeight / chartAmount;
    const size = Math.min(this._element.nativeElement.clientWidth, dividedHeight);
    const width = size - this.margin.left - this.margin.right;
    const height = size - this.margin.top - this.margin.bottom;
    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
    this.surplusMargin = {
      horizontal: (this._element.nativeElement.clientWidth - size) / 2,
      vertical: (dividedHeight - size) / 2,
    };
    this.combinedMargin = {
      top: this.margin.top + this.surplusMargin.vertical,
      right: this.margin.right + this.surplusMargin.horizontal,
      bottom: this.margin.bottom + this.surplusMargin.vertical,
      left: this.margin.left + this.surplusMargin.horizontal,
    };
  }

  setScales() {
    this.scaleX = (f, i, g) =>
      (this.width / 2) * (1 - f * Math.cos((i * 2 * Math.PI) / g + Math.PI / 2)) + this.combinedMargin.left;
    this.scaleY = (f, i, g) =>
      (this.height / 2) * (1 - f * Math.sin((i * 2 * Math.PI) / g + Math.PI / 2)) + this.combinedMargin.top;
  }

  setData() {
    this.data = [];
    this.chartTitleData = [];

    if (this.linearNumber > 2) {
      this.setLinearData();
    } else {
      this.setCategoricalData();
    }
  }

  setCategoricalData() {
    if (!this.comparison || this.categoricalNumber > 2) {
      for (const dim in this.distributions) {
        this.chartTitleData[dim] = this.domain[dim].title;
        this.answerCountData[dim] = this.comparison == null ? [this.totalAnswers[dim]] : [];
        this.data[dim] = [[]];
        const max = d3.max(this.distributions[dim], (d) => d.percentage) || 1;
        const factorScale = d3
          .scaleLinear()
          .range([0, this.radiusFactor])
          .domain([0, max * 1.1]);
        for (const item in this.distributions[dim]) {
          const key = this.distributions[dim][item]['key'];
          const label = this.domain[dim].labels[this.distributions[dim][item]['key']];
          const value = this.distributions[dim][item]['percentage'];
          const valueText = `<span class="icon">contact</span> ${this.distributions[dim][item]['value']} (${(
            this.distributions[dim][item]['percentage'] * 100
          ).toFixed(1)}%)`;
          const factor = factorScale(value);
          const x = this.scaleX(factor, item, this.distributions[dim].length);
          const y = this.scaleY(factor, item, this.distributions[dim].length);
          const comparisonKey = '';
          const comparisonLabel = '';
          const dataItem = {
            key,
            label,
            value,
            valueText,
            x,
            y,
            comparisonKey,
            comparisonLabel,
            comparisonIndex: null,
            comparisonColor: null,
          };
          this.data[dim][0].push(dataItem);
        }
      }
    } else if (this.categoricalNumber > 1) {
      const comparisonIndex = this.domain.findIndex((item) => item.key === this.comparison.key);
      const comparisonDomain = this.domain.find((item) => item.key === this.comparison.key) || ({} as ChartDomain);
      const responses: number[] = [];

      for (let d = 0, lend = (this.distributions[comparisonIndex] || []).length; d < lend; d++) {
        for (let dom = 0, lendom = this.domain.length; dom < lendom; dom++) {
          if (dom !== comparisonIndex) {
            const statItem = (
              (this.stats && this.stats[comparisonIndex] && this.stats[comparisonIndex]['children']) ||
              []
            ).find((item) => item && item.key === this.distributions[comparisonIndex][d]['key']);

            responses[d] =
              statItem &&
              statItem['children'] &&
              statItem['children'][this.domain[dom].index] &&
              statItem['children'][this.domain[dom].index]['responses'] != null
                ? statItem['children'][this.domain[dom].index]['responses']
                : null;
          }
        }
      }

      let index = 0;
      for (let dim = 0, len = this.distributions.length; dim < len; dim++) {
        if (dim !== comparisonIndex) {
          this.chartTitleData[index] = this.domain[index].title;
          this.answerCountData[index] = responses;
          this.data[index] = [];
          let max = 0;
          for (const item in this.distributions[dim]) {
            for (const childItem of this.distributions[dim][item]['children']) {
              if (childItem.percentage_all > max) {
                max = childItem.percentage_all;
              }
            }
          }
          const factorScale = d3
            .scaleLinear()
            .range([0, this.radiusFactor])
            .domain([0, (max || 1) * 1.1]);
          for (let c = 0, cLen = this.comparison.values.length; c < cLen; c++) {
            this.data[index].push([]);
            for (const item in this.distributions[dim]) {
              const key = this.distributions[dim][item]['key'];
              const label = this.domain[dim].labels[this.distributions[dim][item]['key']];
              const value = this.distributions[dim][item]['children'][c]['percentage_all'];
              const valueText = `<span class="icon">contact</span> ${
                this.distributions[dim][item]['children'][c]['value']
              } (${(this.distributions[dim][item]['children'][c]['percentage_all'] * 100).toFixed(1)}%)`;
              const factor = factorScale(value);
              const x = this.scaleX(factor, item, this.distributions[dim].length);
              const y = this.scaleY(factor, item, this.distributions[dim].length);
              const comparisonKey = this.distributions[dim][item]['children'][c]['key'];
              const comparisonLabel = comparisonDomain['labels'][comparisonKey];
              const comparisonColor = comparisonDomain['colors'] ? comparisonDomain['colors'][comparisonKey] : null;

              const dataItem = {
                key,
                label,
                value,
                valueText,
                x,
                y,
                comparisonKey,
                comparisonLabel,
                comparisonIndex: c,
                comparisonColor,
              };
              this.data[index][c].push(dataItem);
            }
          }
          index += 1;
        }
      }
    }
  }

  setLinearData() {
    const axisGroups = {};
    for (let i = 0; i < this.details.length; i++) {
      const item = i.toString();
      const question = [item];
      if (this.details[item].scale === 'linear') {
        const group = this.details[item].group;
        const groupId = this.parseGroupId(this.details[item]);

        if (!axisGroups[groupId]) {
          axisGroups[groupId] = { groups: new Set(), questions: [] };
        }

        question.push(group, groupId);

        axisGroups[groupId]['groups'].add(group);
        axisGroups[groupId]['questions'].push(question);
      }
    }

    const groupObj = {};
    for (const item in this.details) {
      if (this.details[item].scale === 'linear') {
        const groupId = this.parseGroupId(this.details[item]);
        const sliderLabels = this.domain[item].labelsLinear;
        const sliderValues = this.details[item].valueScaleLinear;
        const key = this.details[item].key;
        const domIndex = this.domain[item].index;
        const label = this.domain[item].title;
        const zMethod = axisGroups[groupId] && axisGroups[groupId].groups.size > 1 ? 'globalZ' : 'z';

        if (!groupObj[groupId]) {
          groupObj[groupId] = {};
          groupObj[groupId]['children'] = [];
          groupObj[groupId]['sliderValues'] = sliderValues;
          groupObj[groupId]['chartTitle'] = sliderLabels.axis;
        }

        if (this.comparison) {
          for (let c = 0, len = this.comparison.values.length; c < len; c++) {
            const comparisonDetails =
              this.details.find((det) => det.key === this.comparison.key) || ({} as DimensionDataItem);
            const cdi = this.domain.findIndex((dom) => dom.key === this.comparison.key);
            const comparisonKey = comparisonDetails.values[this.comparison.values[c]];
            const comparisonLabel = comparisonDetails.labelsCategorical[comparisonKey];
            const comparisonColor = comparisonDetails.customColors[comparisonKey];

            const average =
              this.stats[cdi] &&
              this.stats[cdi]['children'] &&
              this.stats[cdi]['children'][c] &&
              this.stats[cdi]['children'][c]['children'] &&
              this.stats[cdi]['children'][c]['children'][domIndex] &&
              this.stats[cdi]['children'][c]['children'][domIndex]['average']
                ? this.stats[cdi]['children'][c]['children'][domIndex]['average']
                : null;
            const zAverage =
              this.stats[cdi] &&
              this.stats[cdi]['children'] &&
              this.stats[cdi]['children'][c] &&
              this.stats[cdi]['children'][c]['children'] &&
              this.stats[cdi]['children'][c]['children'][domIndex] &&
              this.stats[cdi]['children'][c]['children'][domIndex][`${zMethod}Average`]
                ? this.stats[cdi]['children'][c]['children'][domIndex][`${zMethod}Average`]
                : null;
            const n =
              this.stats[cdi] &&
              this.stats[cdi]['children'] &&
              this.stats[cdi]['children'][c] &&
              this.stats[cdi]['children'][c]['children'] &&
              this.stats[cdi]['children'][c]['children'][domIndex] &&
              this.stats[cdi]['children'][c]['children'][domIndex]['count']
                ? this.stats[cdi]['children'][c]['children'][domIndex]['count']
                : null;

            const value =
              ((this.zValues ? zAverage : average) - sliderValues.min) / (sliderValues.max - sliderValues.min);
            const valueText = `<span class="icon">contact</span> ${n} <span class="icon">axis_x</span> ${
              this.zValues ? (zAverage ? zAverage.toFixed(1) : '') : average ? average.toFixed(1) : ''
            }${this.zValues ? ' (z-scored)' : ''}`;

            const dataItem = {
              key,
              label,
              value,
              valueText,
              x: 0,
              y: 0,
              comparisonKey,
              comparisonLabel,
              comparisonIndex: c,
              comparisonColor,
            };

            if (!groupObj[groupId]['children'][c]) {
              groupObj[groupId]['children'][c] = [];
            }

            groupObj[groupId]['children'][c].push(dataItem);
          }
        } else {
          const average = this.stats[item].average;
          const zAverage = this.stats[item][`${zMethod}Average`];
          const n = this.stats[item].count;

          const value =
            ((this.zValues ? zAverage : average) - sliderValues.min) / (sliderValues.max - sliderValues.min);
          const valueText = `<span class="icon">contact</span> ${n} <span class="icon">axis_x</span> ${
            this.zValues ? (zAverage ? zAverage.toFixed(1) : '') : average ? average.toFixed(1) : ''
          }${this.zValues ? ' (z-scored)' : ''}`;

          const comparisonKey = '';
          const comparisonLabel = '';

          const dataItem = {
            key,
            label,
            value,
            valueText,
            x: 0,
            y: 0,
            comparisonKey,
            comparisonLabel,
            comparisonIndex: null,
            comparisonColor: null,
          };

          if (!groupObj[groupId]['children'][0]) {
            groupObj[groupId]['children'][0] = [];
          }

          groupObj[groupId]['children'][0].push(dataItem);
        }
      }
    }

    for (const group in groupObj) {
      const factorScale = d3.scaleLinear().range([0, this.radiusFactor]).domain([0, 1]);
      for (let i = 0, len = groupObj[group]['children'].length; i < len; i++) {
        for (let c = 0, cLen = groupObj[group]['children'][i].length; c < cLen; c++) {
          const factor = factorScale(groupObj[group]['children'][i][c]['value']);
          groupObj[group]['children'][i][c]['x'] = this.scaleX(factor, c, cLen);
          groupObj[group]['children'][i][c]['y'] = this.scaleY(factor, c, cLen);
        }
      }

      this.data.push(groupObj[group]['children']);
      this.chartTitleData.push(groupObj[group]['chartTitle']);
    }
  }

  /**
   * Selecting colors.
   */
  colorScale(index) {
    if (index) {
      return Colors.getComparisonColor(index - 1);
    } else {
      return this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT;
    }
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const hoverFunction = function (event, d, i) {
      const area = d3.pointer(event);
      __this.selectForHover(event, area, d);
    };
    const mouseOutFunction = (event, d) => {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    };
    const drawContent = function (d, i) {
      const context = d3.select(this).node().getContext('2d');

      context.clearRect(
        0,
        0,
        __this.width + __this.combinedMargin.right + __this.combinedMargin.left,
        __this.height + __this.combinedMargin.top + __this.combinedMargin.bottom,
      );

      __this.setLegends(context);
      __this.setTitle(context, __this.chartTitleData[i], __this.answerCountData[i]);

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const dataObj = __this.context && __this.context[i] && __this.context[i].data ? __this.context[i].data : [];
        const interpolator = d3.interpolateArray(dataObj, d);
        const ease = d3.easeCubic;

        const t = d3.timer((elapsed) => {
          context.clearRect(
            0,
            0,
            __this.width + __this.combinedMargin.right + __this.combinedMargin.left,
            __this.height + __this.combinedMargin.top,
          );

          const step = elapsed / __this.transitionDuration;
          let data;

          if (step >= 1) {
            data = interpolator(ease(1));
            t.stop();
          } else {
            data = interpolator(ease(step));
          }

          __this.setArea(context, d);
          __this.setPolygons(context, data);
          __this.setTexts(context, d);
          __this.setTitle(context, __this.chartTitleData[i], __this.answerCountData[i]);
        });
      } else {
        __this.setArea(context, d);
        __this.setPolygons(context, d);
        __this.setTexts(context, d);
      }

      __this.context[i] = { context, data: d };
    };

    this.canvas = this.base.selectAll('.radar-chart-canvas').data(this.data);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.combinedMargin.left + this.combinedMargin.right)
      .attr('height', this.height + this.combinedMargin.top + this.combinedMargin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'radar-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.combinedMargin.left + this.combinedMargin.right)
      .attr('height', this.height + this.combinedMargin.top + this.combinedMargin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', mouseOutFunction)
      .each(drawContent);
  }

  setLegends(context) {
    if (this.categoricalNumber < 3 || (this.comparison && this.linearNumber > 2)) {
      const legends = this.comparison
        ? (() => {
            const legendArr: any[] = [];
            const domain: ChartDomain =
              this.domain.find((dom) => dom.key === this.comparison.key) || ({} as ChartDomain);

            for (const key of domain.keys) {
              const legend = {};
              legend['key'] = key;
              legend['label'] = domain.labels[key];
              if (domain.colors && domain.colors[key] != null) {
                legend['comparisonColor'] = domain.colors[key];
              }
              legendArr.push(legend);
            }

            return legendArr;
          })()
        : ([] as any[]);

      this.legends = [];
      const y = this.combinedMargin.top + this.height + this.unit;
      const width = this.width + this.combinedMargin.left + this.combinedMargin.right;
      const height = 2 * this.unit;
      let usedSpace = 0;
      let arr: any[] = [];
      let currentX = 0;
      let currentY = 0;
      let margin;

      context.clearRect(0, y - 1, width, this.combinedMargin.bottom);

      context.textBaseline = 'top';
      context.textAlign = 'left';
      context.font = 10 / 14 + 'em Open Sans';

      for (let i = 0, length = legends.length; i < length; i++) {
        const key = legends[i].key;
        const colorIndex: number = legends[i].comparisonColor != null ? legends[i].comparisonColor : i;
        const text = shortenText(context, legends[i].label, width, this.combinedMargin.left);
        const elW = context.measureText(text).width;

        if (usedSpace + elW + 2 * this.unit <= width) {
          usedSpace += elW + 2 * this.unit;
          arr.push([key, colorIndex, text]);
        } else {
          margin = (width - usedSpace) / 2;
          currentX = margin;

          for (let a = 0, len = arr.length; a < len; a++) {
            const itemText = arr[a][2];
            const itemColor = arr[a][1];
            const itemKey = arr[a][0];
            currentX += this.unit;

            context.fillStyle = Colors.TEXT;
            context.fillText(itemText, currentX, currentY + y);

            context.beginPath();
            context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
            context.closePath();
            context.fillStyle = this.colorScale(itemColor + 1);
            context.fill();
            this.legends.push({
              key: itemKey,
              x: currentX,
              y: currentY + y,
              width: context.measureText(itemText).width,
              height,
            });
            currentX += context.measureText(itemText).width + this.unit;
          }

          currentY += height;
          usedSpace = elW + 2 * this.unit;

          arr = [[key, colorIndex, text]];
        }
      }

      margin = (width - usedSpace) / 2;
      currentX = margin;

      for (let a = 0, len = arr.length; a < len; a++) {
        const itemText = arr[a][2];
        const itemColor = arr[a][1];
        const itemKey = arr[a][0];
        currentX += this.unit;

        context.font = 10 / 14 + 'em Open Sans';
        context.fillStyle = Colors.TEXT;
        context.fillText(itemText, currentX, currentY + y);

        context.beginPath();
        context.arc(currentX - 0.8 * this.unit, currentY + y + 0.6 * this.unit, 0.5 * this.unit, 0, 2 * Math.PI);
        context.closePath();
        context.fillStyle = this.colorScale(itemColor + 1);
        context.fill();
        this.legends.push({
          key: itemKey,
          x: currentX,
          y: currentY + y,
          width: context.measureText(itemText).width,
          height,
        });
        currentX += context.measureText(itemText).width + this.unit;
      }

      const spaceNeeded = currentY / this.unit + 4;

      if (!isNaN(spaceNeeded) && this.marginBottomUnits !== spaceNeeded) {
        this.marginBottomUnits += spaceNeeded - this.marginBottomUnits;
        this.marginLeftUnits += (spaceNeeded - this.marginLeftUnits) / 2;
        this.marginRightUnits += (spaceNeeded - this.marginRightUnits) / 2;
        this.setEnvironment();
        this.setData();
        this.setLegends(context);
      }

      // if (this.combinedMargin.bottom !== (currentY + 30)) {
      //   this.combinedMargin.bottom += (currentY + 30) - this.combinedMargin.bottom;
      //   this.combinedMargin.left += ((currentY + 30) - this.combinedMargin.left) / 2;
      //   this.combinedMargin.right += ((currentY + 30) - this.combinedMargin.right) / 2;
      //   this.setEnvironment();
      //   this.setData();
      //   this.setLegends(context);
      // }
    }
  }

  setTitle(context, titleText: string = '', answerCount: number[] = []) {
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';
    context.font = 10 / 14 + 'em Open Sans';

    const h = this.surplusMargin.vertical + this.margin.top / 2;
    const wIcon: number[] = [];
    const wNumber: number[] = [];

    for (let i = 0, len = answerCount.length; i < len; i++) {
      wIcon.push(this.fontSize + 0.4 * this.unit);
      wNumber.push(context.measureText(Math.round(answerCount[i])).width + 0.8 * this.unit);
    }

    context.font = 'normal bold ' + 12 / 14 + 'em Open Sans';
    const title = titleText
      ? shortenText(
          context,
          titleText,
          this.combinedMargin.left + this.width + this.combinedMargin.right,
          8 + (wIcon.reduce((a, b) => a + b, 0) + wNumber.reduce((a, b) => a + b, 0)) / 2 + this.unit,
        )
      : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint =
      this.combinedMargin.left +
      this.width / 2 -
      (wIcon.reduce((a, b) => a + b, 0) + wNumber.reduce((a, b) => a + b, 0) + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    let currentPoint: number = startPoint + wTitle;

    for (let i = 0, len = answerCount.length; i < len; i++) {
      context.fillStyle =
        this.comparison != null &&
        this.comparison.index >= 0 &&
        this.domain[this.comparison.index] &&
        this.domain[this.comparison.index].keys &&
        this.domain[this.comparison.index].keys[i] != null
          ? this.domain[this.comparison.index].colors[this.domain[this.comparison.index].keys[i]] != null
            ? this.colorScale(this.domain[this.comparison.index].colors[this.domain[this.comparison.index].keys[i]] + 1)
            : this.colorScale(i)
          : Colors.TEXT;

      drawContactIcon(context, this.fontSize, currentPoint, h, context.fillStyle);

      context.font = 10 / 14 + 'em Open Sans';
      context.fillText(answerCount[i], currentPoint + wIcon[i], h);
      currentPoint += wIcon[i] + wNumber[i];
    }
  }

  setArea(context, data) {
    context.strokeStyle = '#cfd8dd';
    context.lineWidth = 1;

    for (let s = 0, sLen = 5; s < sLen; s++) {
      context.beginPath();

      for (let i = 0, len = data[0].length; i < len; i++) {
        const f = s / sLen;
        const x = this.scaleX(f, i, len);
        const y = this.scaleY(f, i, len);

        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
      }

      context.closePath();
      context.stroke();
    }

    context.beginPath();

    for (let i = 0, len = data[0].length; i < len; i++) {
      const f = this.radiusFactor;
      const x = this.scaleX(f, i, len);
      const y = this.scaleY(f, i, len);

      context.moveTo(this.combinedMargin.left + this.width / 2, this.combinedMargin.top + this.height / 2);
      context.lineTo(x, y);
    }

    context.stroke();
  }

  setTexts(context, data) {
    context.font = 10 / 14 + 'em Open Sans';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = Colors.TEXT;

    for (let i = 0, len = data[0].length; i < len; i++) {
      context.textAlign = i === 0 ? 'center' : i < len / 2 ? 'start' : i === len / 2 ? 'center' : 'end';
      const f = this.textFactor;
      const x = this.scaleX(f, i, len);
      const y = this.scaleY(f, i, len);
      const w =
        i === 0 || i === len / 2
          ? this.width
          : i < len / 2
          ? this.width + this.combinedMargin.left + this.combinedMargin.right - x
          : x;
      const text = shortenText(context, data[0][i].label, w, 1 * this.unit);

      context.fillText(text, x, y);
    }
  }

  setPolygons(context, data, highlight: any[] = []) {
    for (let d = 0, lend = data.length; d < lend; d++) {
      context.beginPath();
      for (let i = 0, len = data[d].length; i < len; i++) {
        const x = data[d][i]['x'];
        const y = data[d][i]['y'];

        if (i === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
      }
      const color = this.colorScale(
        data[d] && data[d][0] && data[d][0]['comparisonColor'] != null ? data[d][0]['comparisonColor'] + 1 : d,
      );
      context.strokeStyle = color;
      context.fillStyle = color;
      context.closePath();

      if (highlight.length > 0) {
        if (highlight.find((item) => item.comparisonKey === data[d][0].comparisonKey)) {
          context.globalAlpha = 0.3;
        } else {
          context.globalAlpha = 0;
        }
      } else {
        context.globalAlpha = 0.1;
      }

      context.fill();

      if (highlight.length > 0) {
        if (highlight.find((item) => item.comparisonKey === data[d][0].comparisonKey)) {
          context.globalAlpha = 1;
        } else {
          context.globalAlpha = 0.2;
        }
      } else {
        context.globalAlpha = 1;
      }

      context.lineWidth = 3;
      context.stroke();

      for (let i = 0, len = data[d].length; i < len; i++) {
        const x = data[d][i]['x'];
        const y = data[d][i]['y'];
        context.beginPath();
        context.arc(x, y, this.polygonDotRadius, 0, 2 * Math.PI);
        context.closePath();
        context.fill();
      }
    }
    context.globalAlpha = 1;
  }

  setTooltip(position, data: any[] = []) {
    const __this = this;
    const options = {
      backgroundColor: this.comparison ? (d) => this.colorScale(d.comparisonColor + 1) : null,
      texts: (d) => `
          <div class="question">
          ${d.comparisonLabel ? d.comparisonLabel + ': ' : ''}
          ${d.label ? d.label : ''}</div>
          <div class="stats">
          ${d.valueText ? d.valueText : ''}
          </div>
        `,
    };

    // Tooltip
    this.tooltip = this.base.selectAll('.item-tooltip').data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip
      .html(options.texts)
      .style('transform', function (d, i) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (i + 1) * (this.getBoundingClientRect().height + 15)}px)`;
      })
      .style('background-color', options.backgroundColor)
      .style('border-top-color', options.backgroundColor);

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(options.texts)
      .style('transform', function (d, i) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (i + 1) * (this.getBoundingClientRect().height + 15)}px)`;
      })
      .style('background-color', options.backgroundColor)
      .style('border-top-color', options.backgroundColor);

    this.base.selectAll('.radar-chart-canvas').each(function (d, i) {
      if (__this.context && __this.context[i] && __this.context[i].context) {
        const context = __this.context[i].context;
        const contextData = __this.context[i].data;
        context.clearRect(
          0,
          0,
          __this.width + __this.combinedMargin.right + __this.combinedMargin.left,
          __this.height + __this.combinedMargin.top + __this.combinedMargin.bottom,
        );

        __this.setLegends(context);
        __this.setTitle(context, __this.chartTitleData[i], __this.answerCountData[i]);
        __this.setArea(context, d);
        __this.setPolygons(context, contextData, data.find((item) => item.comparisonKey) ? data : []);
        __this.setTexts(context, d);
      }
    });
  }

  // Helpers
  parseGroupId(details) {
    return (
      `${details.labelsLinear.axis}: ${details.labelsLinear.min} - ${details.labelsLinear.max} ` +
      `(${details.valueScaleLinear.min}-${details.valueScaleLinear.max})`
    );
  }

  parseGroupNumber(details): number {
    const groups = {};
    for (const item in this.details) {
      if (this.details[item].scale === 'linear') {
        const groupId = this.parseGroupId(this.details[item]);
        groups[groupId] = 'exists';
      }
    }

    return Object.keys(groups).length;
  }

  multiLineWrap(context, text, width, height, lineheight) {
    const textLength = context.measureText(text).width;
    const room = Math.floor(height / lineheight);

    if (textLength > width - 10 && text.length > 0) {
      const words = text.split(/\s+/).reverse();
      const lines: string[] = [];
      let limitReached = false;
      let line: string = '';
      let word;

      while ((word = words.pop())) {
        const testLine = line + word + ' ';
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > width - 10 && line.length > 0) {
          if (lines.length + 1 === room) {
            lines.push(line + '...');
            limitReached = true;
            break;
          } else {
            lines.push(line);
          }

          line = word + ' ';
        } else {
          line = testLine;
        }
      }

      if (!limitReached) {
        lines.push(line);
      }

      return lines;
    } else {
      return [text];
    }
  }

  selectForHover(event, area, d) {
    const itemsBelow = this.itemsBelow(area, d);
    const parents = itemsBelow.parents;
    const legends = itemsBelow.legends;

    if (parents.length > 0) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement), parents);
    } else if (legends.length > 0) {
      const domain = this.domain.find((dom) => dom.key === this.comparison.key) || ({} as ChartDomain);

      this.setTooltip(
        d3.pointer(event, this._element.nativeElement),
        legends.map((item) => ({
          comparisonKey: item.key,
          comparisonLabel: domain.labels[item.key],
          comparisonIndex: domain.keys.indexOf(item.key),
          comparisonColor: domain.colors ? domain.colors[item.key] : null,
        })),
      );
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    }
  }

  itemsBelow(area, d) {
    const parents: any[] = [];

    for (let i = 0, len = d.length; i < len; i++) {
      for (let it = 0, lent = d[i].length; it < lent; it++) {
        const item = d[i][it];
        const f = this.textFactor;
        const textWidth =
          it === 0 || it === lent / 2
            ? this.width
            : it < lent / 2
            ? this.width + this.combinedMargin.left + this.combinedMargin.right - item.x
            : item.x;
        const textWidthL = it === 0 || it === lent / 2 ? textWidth / 2 : it < lent / 2 ? 1 * this.unit : textWidth;
        const textWidthR = it === 0 || it === lent / 2 ? textWidth / 2 : it < lent / 2 ? textWidth : 1 * this.unit;

        if (
          area[0] < item.x + this.polygonDotRadius &&
          area[0] > item.x - this.polygonDotRadius &&
          area[1] < item.y + this.polygonDotRadius &&
          area[1] > item.y - this.polygonDotRadius
        ) {
          parents.push(item);
        } else if (
          area[0] < this.scaleX(f, it, lent) + textWidthR &&
          area[0] > this.scaleX(f, it, lent) - textWidthL &&
          area[1] < this.scaleY(f, it, lent) + 0.8 * this.unit &&
          area[1] > this.scaleY(f, it, lent) - 0.8 * this.unit
        ) {
          parents.push(item);
        }
      }
    }

    const legends = this.legends.filter(
      (item) =>
        area[0] > item.x - 15 && area[0] < item.x + item.width && area[1] > item.y && area[1] < item.y + item.height,
    );

    return { parents, legends };
  }
}

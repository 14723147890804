/**
 * Manages data data stored in the Firebase.
 *
 * @unstable
 */

import { from, Observable, of } from 'rxjs';
import { map, mapTo, tap } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { Injectable } from '@angular/core';

import { AccountState } from '@shared/states/account.state';

import { OutcomeData } from '@shared/models/survey.model';

import { ObjectsManager } from '@shared/services/objects-manager.service';
import { ScoringManager } from '@shared/services/scoring-manager.service';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { logicArrayToData, logicDataToArray } from '@shared/utilities/survey-data.utilities';

/**
 * Manages outcomes for an survey (handles copying etc.).
 */
@Injectable({
  providedIn: 'root',
})
export class OutcomesManager extends ObjectsManager<OutcomeData> {
  readonly pathRoot = 'outcomes';

  constructor(db: DatabaseWrapper, readonly store: Store, readonly sm: ScoringManager) {
    super(db, store);
  }

  public outcomesData(teamKey: string, surveyKey: string): Observable<OutcomeData[]> {
    return this.orderedList(`/${this.pathRoot}/${teamKey}/${surveyKey}`).pipe(map(logicDataToArray));
  }

  public addOutcomes(outcomes: OutcomeData[] | OutcomeData, index?: number) {
    return this.addItems(this.ref, outcomes, index);
  }

  public insertOutcomes(outcomes: OutcomeData[], isTemplate: boolean): Observable<string[]> {
    if (!outcomes.length) {
      return of([]);
    }

    if (isTemplate) {
      return this.addItems(this.ref, outcomes).pipe(
        tap((keys) => outcomes.map((outcome, outcomeIdx) => (outcome['$key'] = keys[outcomeIdx]))),
      );
    } else {
      return this.insertItems(this.ref, outcomes);
    }
  }

  public copyOutcomeScore(oldKey: string, newKey: string) {
    return this.sm.copyOutcomeScoring(oldKey, newKey, this.surveyKey.getValue());
  }

  public copyOutcome(outcome: OutcomeData): Observable<(string | null)[]> {
    outcome = { ...outcome };
    outcome.title = $localize`:@@zef-i18n-00041:${outcome.title || 'Untitled'}:INTERPOLATION:`;

    return this.copyItems(this.ref, [outcome]);
  }

  public removeOutcome(outcome: OutcomeData) {
    return this.removeItems(this.ref, [outcome]);
  }

  public restoreOutcome(outcome: OutcomeData) {
    return this.updatePriority(this.ref.child(outcome.$key), Math.abs(outcome.order));
  }

  public updateOutcome(outcomeKey: string, data: Partial<OutcomeData>) {
    logicArrayToData(data);

    return this.updateData(this.ref.child(outcomeKey), data);
  }

  public scoreOutcome(
    surveyKey: string,
    outcomeKey: string,
    questionKey: string,
    data: { type: string; value: string },
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const ref = this.db.object(`/scoring/${teamKey}/${surveyKey}/${outcomeKey}`);
    return ref.update({ [questionKey]: data || null });
  }

  public moveOutcome(outcome: OutcomeData, index: number): Observable<void> {
    return from(this.moveItems(this.ref, [outcome], index)).pipe(mapTo(void 0));
  }

  public archiveOutcome(outcomeKey: string) {
    return this.updateData(this.ref.child(`${outcomeKey}`), { archived: true });
  }

  public unarchiveOutcome(outcomeKey: string) {
    return this.updateData(this.ref.child(`${outcomeKey}`), { archived: null });
  }
}

<div
  *ngIf="sn?.isOpen$ | async"
  class="sidenav z-fx-col"
  [@toggle]="sn?.position"
  (@toggle.done)="sn?.onAnimationDone($event)"
>
  <div class="sidenav-header z-fx z-fx-between-center z-fx-gap-16 zef-text-overflow-line z-fx-none">
    <div class="zef-text-h2 z-fx z-fx-start-center zef-text-ellipsis sidenav-title">
      <ng-container *ngTemplateOutlet="title?.tr"></ng-container>
    </div>

    <div class="sidenav-buttons z-fx z-fx-gap-4">
      <ng-container *ngTemplateOutlet="buttonSecondary?.tr"></ng-container>

      <ng-container *ngTemplateOutlet="buttonPrimary?.tr"></ng-container>

      <button *ngIf="menu?.menu" mat-icon-button color="secondary" [matMenuTriggerFor]="menu?.menu">
        <mat-icon>more</mat-icon>
      </button>

      <button
        mat-icon-button
        color="secondary"
        [inlineDialogTriggerFor]="discardDialog?.dialog || confirmMenu"
        [inlineDialogDisabled]="!confirmClose"
        (click)="trigger.inlineDialogDisabled && closeSidenav()"
        #trigger="inlineDialogTrigger"
      >
        <mat-icon>close</mat-icon>
      </button>

      <zef-inline-dialog (confirm)="closeSidenav()" #confirmMenu>
        <ng-container *dialogTitle i18n>Confirm close?</ng-container>

        <ng-container *dialogContent i18n>This will discard all your unsaved changes.</ng-container>

        <ng-container *dialogAction i18n>Discard changes</ng-container>
      </zef-inline-dialog>
    </div>
  </div>

  <mat-progress-bar *ngIf="loading" mode="query"></mat-progress-bar>

  <mat-divider></mat-divider>

  <ng-scrollbar *ngIf="withScroll" cdkScrollable>
    <ng-container *ngTemplateOutlet="content?.tr"></ng-container>
  </ng-scrollbar>

  <ng-template *ngIf="!withScroll" [ngTemplateOutlet]="content?.tr"></ng-template>
</div>

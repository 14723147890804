import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { ChartDistribution, ChartDomain, CanvasContext } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';

import { drawContactIcon } from '@shared/utilities/canvas.utilities';

/**
 * This is a heat map chart.
 */
@Directive({
  selector: '[heatmap]',
})
export class Heatmap implements OnChanges {
  @Input() data: ChartDistribution[][] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;

  private base: any;

  private boxDimensions: number[] = [];

  private canvas: any;
  private chart: any;
  private colors: any;

  private context: CanvasContext[] = [];

  private datas: any;

  private domIndexes: any = {};

  private totalAnswers: number[] = [];
  private maxValues: number[] = [];
  private previousMaxValues: number[] = [];

  private responses: number[] = [];
  private previousResponses: number[] = [];

  private selectedBoxes: any[] = [];

  private scaleX: any = [];
  private scaleY: any = [];
  private scaleColor: any = [];

  private tooltip: any;

  private brush: any = [];
  private brushArea: any;

  private width: number = 0;
  private height: number = 0;
  private margin: any;
  private fontSize: number = 0;

  private filter: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.stats ||
      changes.filtersDemo
    ) {
      this.updateChart(changes.data);
    }
  }

  constructBody() {
    this.base = d3.select(this._element.nativeElement);
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setChart();
    this.setCanvas(dataChanges);
    this.setBrush();
  }

  setEnvironment() {
    this.domIndexes.linear = [];
    this.domIndexes.categorical = null;

    for (const dom in this.domain) {
      if (this.domain[dom].scale === 'categorical') {
        this.domIndexes.categorical = dom;
      } else if (this.domain[dom].scale === 'linear') {
        this.domIndexes.linear.push(dom);
      }
    }

    this.datas =
      this.data.length > 2 && this.domIndexes.categorical != null
        ? this.data[this.domIndexes.categorical]
        : [{ children: this.data[this.domIndexes.linear[0]] }];

    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.margin = {
      top: this.fontSize * 3,
      right: this.fontSize * 3,
      bottom: this.fontSize * 3,
      left: this.fontSize * 3,
    };
    const comparisonHeight = this._element.nativeElement.clientHeight / (this.datas ? this.datas.length : 1);
    const size = Math.min(this._element.nativeElement.clientWidth, comparisonHeight);
    const width = size - this.margin.left - this.margin.right;
    const height = size - this.margin.top - this.margin.bottom;
    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
  }

  setScales() {
    this.scaleX = d3
      .scaleBand()
      .rangeRound([0, this.width])
      .domain(this.domain[this.domIndexes.linear[0]].keys)
      .paddingInner(0.2)
      .paddingOuter(0);

    this.scaleY = d3
      .scaleBand()
      .rangeRound([this.height, 0])
      .domain(this.domain[this.domIndexes.linear[1]].keys)
      .paddingInner(0.2)
      .paddingOuter(0);

    this.boxDimensions = [this.scaleX.bandwidth(), this.scaleY.bandwidth()];

    this.colors = (i) =>
      this.data.length > 2
        ? Colors.getComparisonColor(i)
        : this.selectionExists
        ? Colors.SELECTED
        : this.filtersDemo
        ? Colors.UNSELECTED
        : Colors.DEFAULT;

    this.scaleColor = [];

    for (const comparisonItem in this.datas) {
      const comparisonDimKey: string = this.domIndexes.categorical
        ? this.domain[this.domIndexes.categorical].key
        : 'aggregate';
      const cdi = this.domIndexes.categorical;
      const cidx =
        this.stats &&
        this.stats[cdi] &&
        this.stats[cdi]['children'] &&
        this.stats[cdi]['children'].findIndex((ch) => ch && ch.key && ch.key === this.datas[comparisonItem].key);
      const didx = this.domain[this.domIndexes.linear[0]].index;
      const didy = this.domain[this.domIndexes.linear[1]].index;
      const x = this.domIndexes.linear[0];
      const y = this.domIndexes.linear[1];
      const choiceKey: string = this.datas[comparisonItem].key;
      const dimKeyX: string = this.domain[this.domIndexes.linear[0]].key;
      const dimKeyY: string = this.domain[this.domIndexes.linear[1]].key;

      const colorIndex: number =
        this.datas[comparisonItem].key && this.domain[this.domIndexes.categorical].colors
          ? this.domain[this.domIndexes.categorical].colors[this.datas[comparisonItem].key] != null
            ? this.domain[this.domIndexes.categorical].colors[this.datas[comparisonItem].key]
            : Number(comparisonItem)
          : 0;

      this.totalAnswers[comparisonItem] = 0;

      this.previousResponses[comparisonItem] = this.responses[comparisonItem];
      this.responses[comparisonItem] =
        this.stats &&
        cdi &&
        this.stats[cdi] &&
        this.stats[cdi]['children'] &&
        this.stats[cdi]['children'][cidx] &&
        this.stats[cdi]['children'][cidx]['children'] &&
        this.stats[cdi]['children'][cidx]['children'][didx] &&
        this.stats[cdi]['children'][cidx]['children'][didx]['responses'] != null
          ? this.stats[cdi]['children'][cidx]['children'][didx]['responses']
          : this.stats && this.stats[x] && this.stats[x]['responses'] != null
          ? this.stats[x]['responses']
          : this.totalAnswers[comparisonItem];

      this.previousMaxValues[comparisonItem] = this.maxValues[comparisonItem] ? this.maxValues[comparisonItem] : 0;
      let max = 0;

      for (const item of this.datas[comparisonItem].children) {
        this.totalAnswers[comparisonItem] += item.value;

        for (const child of item.children) {
          if (child.value > max) {
            max = child.value;
          }
        }
      }

      this.maxValues[comparisonItem] = max || 1;
      this.scaleColor[comparisonItem] = d3
        .scaleLinear<string>()
        .domain([0, max || 1])
        .range(['white', this.colors(colorIndex)]);
    }
  }

  setChart() {
    this.chart = this.base.selectAll('.heatmap').data(this.datas);

    this.chart.exit().remove();

    this.chart.attr('data-index', (d, i) => i);

    this.chart
      .enter()
      .append('div')
      .attr('class', 'heatmap')
      .attr('data-index', (d, i) => i);
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const drawContent = function (d) {
      if (this.parentElement) {
        const i = this.parentElement.attributes['data-index'].value;
        const colorIndex: number =
          __this.datas[i].key && __this.domain[__this.domIndexes.categorical].colors
            ? __this.domain[__this.domIndexes.categorical].colors[__this.datas[i].key] != null
              ? __this.domain[__this.domIndexes.categorical].colors[__this.datas[i].key]
              : Number(i)
            : 0;
        const context = d3.select(this).node().getContext('2d');

        if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
          const dataObj = __this.context[i] && __this.context[i].data ? __this.context[i].data : {};
          const interpolator = d3.interpolateObject(dataObj, d);
          const colorInterpolator = d3.interpolateNumber(__this.previousMaxValues[i], __this.maxValues[i]);
          const interpolateResponses = d3.interpolateNumber(__this.previousResponses[i] || 0, __this.responses[i]);

          const t = d3.timer((elapsed) => {
            const step = elapsed / __this.transitionDuration;
            let data;
            let colors;
            let responses;

            if (step >= 1) {
              data = interpolator(d3.easeCubicOut(1));
              responses = interpolateResponses(d3.easeCubicOut(1));
              colors = d3
                .scaleLinear<string>()
                .domain([0, colorInterpolator(d3.easeCubicOut(1))])
                .range(['white', __this.colors(colorIndex)]);
              t.stop();
            } else {
              data = interpolator(d3.easeCubicOut(step));
              responses = Math.round(interpolateResponses(d3.easeCubicOut(step)));
              colors = d3
                .scaleLinear<string>()
                .domain([0, colorInterpolator(d3.easeCubicOut(step))])
                .range(['white', __this.colors(colorIndex)]);
            }

            __this.setTexts(context, d, responses);
            __this.setRects(context, data, colors);
            __this.setColorHelper(context, __this.scaleColor[i], __this.maxValues[i]);
          });
        } else {
          __this.setTexts(context, d, __this.responses[i]);
          __this.setRects(context, d, __this.scaleColor[i]);
          __this.setColorHelper(context, __this.scaleColor[i], __this.maxValues[i]);
        }

        __this.context[i] = { context, data: d };
      }
    };

    this.canvas = this.base
      .selectAll('.heatmap')
      .selectAll('.heatmap-canvas')
      .data((d) => [d]);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    // Enter new elements
    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'heatmap-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);
  }

  setRects(context, data = {}, colorScale, filter: any[] | null = [], highlight: any[] | null = []) {
    context.clearRect(this.margin.left, this.margin.top, this.width + 1, this.height + 1);
    this.selectedBoxes = [new Set(), new Set()];

    for (let x = 0, lenx = data['children'].length; x < lenx; x++) {
      const keyX = data['children'][x]['key'];
      const xPos = this.scaleX(keyX) + this.margin.left;
      for (let y = 0, leny = data['children'][x]['children'].length; y < leny; y++) {
        const keyY = data['children'][x]['children'][y]['key'];
        const yPos = this.scaleY(keyY) + this.margin.top;
        const value = data['children'][x]['children'][y]['value'];
        const width = this.boxDimensions[0];
        const height = this.boxDimensions[1];

        context.fillStyle = colorScale(value);
        context.strokeStyle = '#ecf0f2';
        context.lineWidth = 1;

        if (filter != null && filter.length === 2) {
          const xFi =
            filter &&
            filter[0][0] <= xPos - this.margin.left + width / 2 &&
            filter[1][0] >= xPos - this.margin.left + width / 2;
          const yFi =
            filter &&
            filter[0][1] <= yPos - this.margin.top + height / 2 &&
            filter[1][1] >= yPos - this.margin.top + height / 2;

          if (!xFi || !yFi) {
            context.globalAlpha = 0.2;
          } else if (xFi && yFi) {
            this.selectedBoxes[0].add(keyX);
            this.selectedBoxes[1].add(keyY);
            context.strokeStyle = '#c4cfd5';
          }
        } else if (
          this.filterInput &&
          this.filterInput[0] &&
          this.filterInput[1] &&
          (this.filterInput[0].length > 0 || this.filterInput[1].length > 0)
        ) {
          const xFi = this.filterInput[0].indexOf(keyX) > -1;
          const yFi = this.filterInput[1].indexOf(keyY) > -1;

          if (!xFi || !yFi) {
            context.globalAlpha = 0.2;
          } else {
            context.strokeStyle = '#c4cfd5';
          }
        }

        if (
          highlight &&
          highlight.length > 0 &&
          ((highlight[0] && highlight[0].length > 0) || (highlight[1] && highlight[1].length > 0))
        ) {
          const xHi = highlight[0] && highlight[0].indexOf(keyX) > -1;
          const yHi = highlight[1] && highlight[1].indexOf(keyY) > -1;

          if ((xHi && yHi) || (highlight[0] && highlight[0].length === 0 && yHi)) {
            context.strokeStyle = Colors.HIGHLIGHT;
          }
        }

        context.fillRect(xPos, yPos, width, height);
        context.strokeRect(xPos, yPos, width, height);
        context.globalAlpha = 1;
      }
    }
  }

  setTexts(context, data, responses) {
    const wrap = function wrapText(text = '', width, padding) {
      const textLength = context.measureText(text).width;

      if (textLength > width - 2 * padding && text && text.length > 0) {
        const availableSpace = (width - 2 * padding) / (textLength / text.length);
        const firstPartClip = Math.floor(availableSpace / 2 - text.length - 1);
        const secondPartClip = -Math.floor(availableSpace / 2 - 1);
        const firstPart = text.slice(0, firstPartClip);
        const secondPart = secondPartClip < 0 ? text.slice(secondPartClip) : '';

        return firstPart + ' ... ' + secondPart;
      } else {
        return text;
      }
    };

    context.clearRect(
      0,
      0,
      this.width + this.margin.left + this.margin.right,
      this.height + this.margin.bottom + this.margin.top,
    );

    context.fillStyle = this.filterInput.find((item) => item) ? Colors.FILTER : Colors.TEXT;
    context.textAlign = 'left';
    context.textBaseline = 'middle';

    const h = 1.2 * (22 / 3 / 42) * this.margin.top;

    const wIcon = this.fontSize + 4;

    context.font = 10 / 14 + 'em Open Sans';
    const wNumber = context.measureText(responses).width + 3;

    context.font = 12 / 14 + 'em Open Sans';

    const title =
      data.key && this.domain[this.domIndexes.categorical]
        ? wrap(this.domain[this.domIndexes.categorical].labels[data.key], this.width, 8 + (wIcon + wNumber) / 2)
        : '';

    const wTitle = title ? context.measureText(title).width + 8 : 0;

    const startPoint = this.margin.left + this.width / 2 - (wIcon + wNumber + wTitle) / 2;

    if (title) {
      context.fillText(title, startPoint, h);
    }

    drawContactIcon(context, this.fontSize, startPoint + wTitle, h, context.fillStyle);

    context.font = 10 / 14 + 'em Open Sans';
    context.fillText(responses, startPoint + wTitle + wIcon, h);

    context.fillStyle = Colors.TEXT;
    context.font = 12 / 14 + 'em Open Sans';
    context.textBaseline = 'top';
    context.textAlign = 'start';
    const xMinLabel = wrap(this.domain[this.domIndexes.linear[0]].labelsLinear.min, this.width / 2, 10);
    context.fillText(xMinLabel, this.margin.left, this.height + this.margin.top + this.margin.bottom / 12);

    context.textAlign = 'end';
    const xMaxLabel = wrap(this.domain[this.domIndexes.linear[0]].labelsLinear.max, this.width / 2, 10);
    context.fillText(xMaxLabel, this.margin.left + this.width, this.height + this.margin.top + this.margin.bottom / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Open Sans';
    context.textAlign = 'center';
    const xAxisLabel = wrap(this.domain[this.domIndexes.linear[0]].labelsLinear.axis, this.width, 10);
    context.fillText(
      xAxisLabel,
      this.margin.left + this.width / 2,
      this.height + this.margin.top + (1.5 * this.margin.bottom) / 3,
    );

    context.font = 12 / 14 + 'em Open Sans';
    context.textBaseline = 'bottom';
    context.rotate((270 * Math.PI) / 180);

    context.textAlign = 'start';
    const yMinLabel = wrap(this.domain[this.domIndexes.linear[0]].labelsLinearY.min, this.width / 2, 10);
    context.fillText(yMinLabel, 0 - (this.margin.top + this.height), (11 * this.margin.left) / 12);

    context.textAlign = 'end';
    const yMaxLabel = wrap(this.domain[this.domIndexes.linear[0]].labelsLinearY.max, this.width / 2, 10);
    context.fillText(yMaxLabel, 0 - this.margin.top, (11 * this.margin.left) / 12);

    context.font = 'normal bold ' + 12 / 14 + 'em Open Sans';
    context.textAlign = 'center';
    const yAxisLabel = wrap(this.domain[this.domIndexes.linear[0]].labelsLinearY.axis, this.width, 10);
    context.fillText(yAxisLabel, 0 - (this.margin.top + this.height / 2), (1.5 * this.margin.left) / 3);

    context.rotate((90 * Math.PI) / 180);
  }

  setColorHelper(context, colorScale, max) {
    context.strokeStyle = '#c4cfd5';
    context.lineWidth = 0.2;
    const len = Math.min(max, 9) + 1;
    const box = (10 / 14) * this.fontSize;

    for (let i = 0; i < len; i++) {
      context.fillStyle = colorScale?.((i / (len - 1)) * max);
      context.fillRect(
        this.margin.left + this.width / 2 - (len * box) / 2 + i * box,
        ((10 + 2 * (22 / 3)) / 42) * this.margin.top,
        box,
        box,
      );
      context.strokeRect(
        this.margin.left + this.width / 2 - (len * box) / 2 + i * box,
        ((10 + 2 * (22 / 3)) / 42) * this.margin.top,
        box,
        box,
      );
    }

    context.font = 10 / 14 + 'em Open Sans';
    context.textBaseline = 'middle';
    context.fillStyle = Colors.TEXT;
    context.textAlign = 'center';

    context.fillText(
      0,
      this.margin.left + this.width / 2 - (len * box) / 2 - box,
      ((10 + 2 * (22 / 3)) / 42) * this.margin.top + 0.5 * box,
    );
    context.fillText(
      max,
      this.margin.left + this.width / 2 + (len * box) / 2 + box,
      ((10 + 2 * (22 / 3)) / 42) * this.margin.top + 0.5 * box,
    );
  }

  setBrush() {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (this.parentElement && (!__this.touchDevice || !__this.filtering || __this.anonymityLock)) {
        const i = this.parentElement.attributes['data-index'].value;
        const area = d3.pointer(event);
        const items = __this.datas[i].children.filter(
          (item) =>
            area[0] < __this.scaleX(item.key) + __this.scaleX.bandwidth() + __this.margin.left &&
            area[0] > __this.scaleX(item.key) + __this.margin.left,
        );

        const itemsY = __this.data[1].filter(
          (item) =>
            area[1] < __this.scaleY(item.key) + __this.scaleY.bandwidth() + __this.margin.top &&
            area[1] > __this.scaleY(item.key) + __this.margin.top,
        );

        if (items.length === 1) {
          const childItems = items[0].children.filter(
            (childItem, index) =>
              area[1] > __this.scaleY(childItem.key) + __this.margin.top &&
              area[1] < __this.scaleY(childItem.key) + __this.scaleY.bandwidth() + __this.margin.top,
          );

          if (childItems.length > 0) {
            __this.setTooltip(
              d3.pointer(event, __this._element.nativeElement),
              childItems,
              items,
              __this.totalAnswers[i],
            );
          } else {
            __this.setTooltip(
              d3.pointer(event, __this._element.nativeElement),
              items[0].children,
              items,
              __this.totalAnswers[i],
            );
          }
        } else if (itemsY.length === 1) {
          const yItem: any = {
            children: [],
            key: itemsY[0].key,
            percentage: 0,
            percentage_all: 0,
            value: 0,
          };
          let allValues = 0;

          for (let xi = 0, lenxi = __this.datas[i].children.length; xi < lenxi; xi++) {
            for (let yi = 0, lenyi = __this.datas[i].children[xi]['children'].length; yi < lenyi; yi++) {
              if (__this.datas[i].children[xi]['children'][yi]['key'] === yItem['key']) {
                yItem['children'].push(__this.datas[i].children[xi]['children'][yi]);
                yItem['value'] += __this.datas[i].children[xi]['children'][yi]['value'];
                yItem['percentage_all'] += __this.datas[i].children[xi]['children'][yi]['percentage_all'];
              }
              allValues += __this.datas[i].children[xi]['children'][yi]['value'];
            }
          }
          yItem['percentage'] = yItem['value'] / allValues;

          __this.setTooltip(d3.pointer(event, __this._element.nativeElement), [yItem], items, __this.totalAnswers[i]);
        } else {
          __this.setTooltip(d3.pointer(event, __this._element.nativeElement));
        }
      }
    };

    if (this.filtering && !this.anonymityLock) {
      for (const item in this.datas) {
        this.brush[item] = d3
          .brush()
          .on('brush', function (event, d) {
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;
            const parentsParent =
              this.parentElement && this.parentElement.parentElement ? this.parentElement.parentElement : null;
            const index = parentsParent ? parentsParent.attributes['data-index'].value : -1;
            d3.select(parentsParent)
              .select('.heatmap-canvas')
              .each(function (da) {
                __this.setRects(__this.context[index].context, da, __this.scaleColor[index], sel);
              });
          })
          .on('end', function (event, d) {
            const sel =
              this && d3.select(this) && d3.select(this).node() ? d3.brushSelection(d3.select(this).node()!) : null;
            const parentsParent =
              this.parentElement && this.parentElement.parentElement ? this.parentElement.parentElement : null;
            const index = parentsParent ? parentsParent.attributes['data-index'].value : -1;
            d3.select(parentsParent)
              .select('.heatmap-canvas')
              .each(function (da) {
                __this.setRects(__this.context[index].context, da, __this.scaleColor[index], sel);
              });

            __this.callFilter();
          });
      }

      const callBrush = function (d) {
        if (this.parentElement) {
          const index = this.parentElement.parentElement.attributes['data-index'].value;

          if (
            __this.filterInput &&
            __this.filterInput[0] &&
            __this.filterInput[1] &&
            (__this.filterInput[0].length > 0 || __this.filterInput[1].length > 0)
          ) {
            const boxX = __this.boxDimensions[0];
            const boxY = __this.boxDimensions[1];
            const minX = __this.scaleX(__this.filterInput[0][0]) - boxX / 3;
            const maxX = __this.scaleX(__this.filterInput[0][__this.filterInput[0].length - 1]) + 1.33 * boxX;
            const minY = __this.scaleY(__this.filterInput[1][__this.filterInput[1].length - 1]) - boxY / 3;
            const maxY = __this.scaleY(__this.filterInput[1][0]) + 1.33 * boxY;
            const brushArea = [
              [minX, minY],
              [maxX, maxY],
            ];

            d3.select(this).call(__this.brush[index]).call(__this.brush[index].move, brushArea);
          } else {
            const brushOn = d3.brushSelection(d3.select(this).node()) != null;

            if (__this.filterInput && !__this.filterInput[0] && !__this.filterInput[1] && brushOn) {
              d3.select(this).call(__this.brush[index].move, null);
            } else {
              d3.select(this).call(__this.brush[index]);
            }
          }
        }
      };

      this.brushArea = this.base
        .selectAll('.heatmap')
        .selectAll('.svg-brush')
        .data((d) => [d]);

      this.brushArea.exit().remove();

      this.brushArea
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top)
        .select('.brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);

      this.brushArea
        .enter()
        .append('svg')
        .attr('class', 'svg-brush')
        .attr('width', this.width + this.margin.left)
        .attr('height', this.height + this.margin.top)
        .style('position', 'absolute')
        .style('top', 0)
        .style('left', 0)
        .on('mousemove', hoverFunction)
        .on('mouseout', function (event, d) {
          __this.setTooltip(d3.pointer(event));
        })
        .append('g')
        .attr('class', 'brush')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .each(callBrush);
    } else {
      this.canvas.on('mousemove', hoverFunction).on('mouseout', function (event, d) {
        __this.setTooltip(d3.pointer(event));
      });
    }
  }

  setTooltip(position, data: any[] = [], parentList: any[] = [], totalAnswers = 0) {
    const __this = this;

    const texts = (parent, child) => `
        <div class="question">
        ${
          parent.length > 0
            ? this.domain[this.domIndexes.linear[0]].labels[parent[0].key] + (data.length === 1 ? ', ' : '')
            : ''
        }
        ${data.length === 1 ? this.domain[this.domIndexes.linear[1]].labels[child.key] : ''}</div>
        <div class="stats">
          <span class="icon">contact</span>
        ${data.length > 1 ? parent[0].value : child.value} (${
      data.length > 1
        ? ((parent[0].value / totalAnswers) * 100).toFixed(1)
        : ((child.value / totalAnswers) * 100).toFixed(1)
    }%)
        </div>
      `;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip
      .html((d) => texts(parentList, d))
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 20)}px)`;
      });

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html((d) => texts(parentList, d))
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 20)}px)`;
      });

    // adding hovering effect
    this.base.selectAll('.heatmap-canvas').each(function (d) {
      if (this.parentElement) {
        const i = this.parentElement.attributes['data-index'].value;
        const highlight = [parentList.map((item) => item.key), data.map((item) => item.key)];

        __this.setRects(__this.context[i].context, d, __this.scaleColor[i], [], highlight);
      }
    });
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];

      const filterX = {
        key: this.domain[this.domIndexes.linear[0]].key,
        values: this.domain[this.domIndexes.linear[0]].keys,
        filter: Array.from(this.selectedBoxes[0]),
      };

      this.filter.push(filterX);

      const filterY = {
        key: this.domain[this.domIndexes.linear[0]].keyY,
        values: this.domain[this.domIndexes.linear[0]].keysY,
        filter: Array.from(this.selectedBoxes[1]),
      };

      this.filter.push(filterY);
      if (JSON.stringify(this.filter.map((item) => item.filter)) !== JSON.stringify(this.filterInput.slice(0, 2))) {
        this.cf.filter(this.filter);
      }
    }
  }
}

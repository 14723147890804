import { Component, Output } from '@angular/core';
import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Subject } from 'rxjs';
import { delay, skip, startWith } from 'rxjs/operators';

@Component({
  selector: 'player-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.scss'],
})
export class Sheet {
  hiding$ = new Subject();
  @Output() hidden = this.hiding$.pipe(startWith(false), skip(1), delay(300));

  constructor(readonly ss: SurveyStore) {}
}

import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'nav-bar-button, a[nav-bar-button]',
  templateUrl: 'nav-bar-button.component.html',
  styleUrls: ['nav-bar-button.component.scss'],
})
export class NavBarButtonComponent {
  @HostBinding('class.zef-interactive')
  readonly interactive = true;
}

<div
  *cdkPortal
  class="z-options-container"
  [ngStyle]="(ss.font | async)?.styles"
  [style.background-color]="(ss.colors | async).mood"
>
  <div class="z-options-wrapper" [style.background-color]="(ss.colors | async).text05">
    <input
      *ngIf="canSearch && options?.length >= minShowSearch"
      #search
      type="search"
      class="z-search z-texts-hint"
      [placeholder]="('Search' | translateText : 'searchPlaceholder') + '...'"
      [style.border-color]="(ss.colors | async).text80"
      [style.color]="(ss.colors | async).primary"
      (click)="$event.stopPropagation()"
      (input)="onSearchInput(search.value)"
    />

    <ng-scrollbar [autoHeightDisabled]="false">
      <div class="z-options">
        <div
          *ngFor="let option of filteredOptions; trackBy: trackByOption"
          class="z-option z-texts-option"
          [style.background-color]="
            hoverOption === option || selectedOption === option ? (ss.colors | async).primary : (ss.colors | async).mood
          "
          [style.color]="
            hoverOption === option || selectedOption === option
              ? (ss.colors | async).primaryParity
              : (ss.colors | async).text
          "
          (mouseenter)="hoverOption = option"
          (mouseleave)="hoverOption = null"
          (click)="selectOption(option)"
        >
          <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: option }"></ng-template>
        </div>
      </div>
    </ng-scrollbar>
  </div>
</div>

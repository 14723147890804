/**
 * List ordering related data models.
 *
 * @unstable
 */

export abstract class KeyData {
  $key: string;
  $insertKey?: string;
}

export interface OrderInterface {
  order: number;
}

export class OrderData extends KeyData implements OrderInterface {
  static orderScale = 0.001;
  static minDistance = 0.0000001;
  archived?: boolean;

  order: number;

  static sortByOrder(a: Partial<Pick<OrderData, 'order'>>, b: Partial<Pick<OrderData, 'order'>>): number {
    if (a.order == null || b.order == null) {
      return a.order === b.order ? 0 : a.order == null ? -1 : 1;
    }

    return a.order - b.order;
  }

  static filterDeleted(o: OrderData): boolean {
    return o.order >= 0;
  }

  /**
   * Generates order values for items in ordered list.
   *
   * @param entries list of items
   * @param count number of order values to generate
   * @param index position for the first new item
   * @param offsets offset positions to correct too small distances
   */
  static generateOrders(
    entries: OrderData[],
    count: number,
    index?: number,
    offsets = [0, 0],
  ): { index: number; order: number }[] {
    if (!entries || (entries.length > 0 && !count)) {
      throw new Error('Must provide entries and count.');
    }

    // index to add the item
    const entryIndex = index == null ? entries.length : index;

    // first item in the range
    const prevItem = entries[entryIndex - 1 - offsets[0]];

    // last item in the range
    const nextItem = entries[entryIndex + offsets[1]];

    // order value of the first item
    const start = prevItem && prevItem.order !== null ? prevItem.order : 0;

    // order value of the last item
    const end = nextItem && nextItem.order !== null ? nextItem.order : 1;

    // step length not bigger than set orderScale
    const step = Math.min(OrderData.orderScale, (end - start) / (count + 1 + offsets[0] + offsets[1]));

    // if step is too small add more items from entries to increase possible step
    if (step < OrderData.minDistance) {
      if (entryIndex - offsets[0] !== 0 && entryIndex + offsets[1] < entries.length) {
        // first left than right
        offsets = [offsets[0] + (offsets[1] >= offsets[0] ? 1 : 0), offsets[1] + (offsets[0] > offsets[1] ? 1 : 0)];
      } else if (entryIndex - offsets[0] !== 0) {
        // reached beginning, add from the right
        offsets[0] = offsets[0] + 1;
      } else if (entryIndex + offsets[1] < entries.length) {
        // reached end, add from the left
        offsets[1] = offsets[1] + 1;
      } else {
        // not likely
        throw new Error(`Reached maximum of ${1 / OrderData.minDistance} items`);
      }

      // generate with new offsets
      return OrderData.generateOrders(entries, count, index, offsets);
    }

    return Array.from(new Array(count + offsets[0] + offsets[1]), (_, i) => ({
      index: entryIndex - offsets[0] + i,
      order: start + step * (i + 1),
    }));
  }
}

<div
  *ngIf="isMultiple() && questionData?.showStatus !== false && questionData?.choiceLimit > 1"
  class="z-status-text z-texts-paragraph"
  [style.color]="(ss.colors | async).text"
>
  {{ 'Select up to' + questionData?.choiceLimit | translateText : 'choiceSelectUpTo' : [questionData?.choiceLimit] }}
</div>

<div class="z-choices-list">
  <ng-container *ngFor="let choice of choices; trackBy: trackByChoice; index as i">
    <div
      *ngIf="showChoice(choice); else otherInput"
      [style.background-color]="
        isSelected(choice) || hovering === choice
          ? (ss.colors | async).primary
          : (ss.colors | async).primaryParityPrimary10
      "
      [style.color]="(ss.colors | async).primaryParity"
      [class.selected]="isSelected(choice)"
      [class.z-checkbox]="isMultiple()"
      [class.z-radio]="!isMultiple()"
      [class.z-disabled]="
        !isSelected(choice) &&
        isMultiple() &&
        questionData?.choiceLimit > 1 &&
        selectedChoices.length >= questionData?.choiceLimit
      "
      (click)="selectChoice(choice)"
      (mouseover)="onMouseOver(choice)"
      (mouseout)="hovering = null"
    >
      <div class="z-answer-box" [style.background-color]="(ss.colors | async).primaryParity">
        <mat-icon
          *ngIf="isSelected(choice)"
          class="z-answered-icon"
          fontSet="zefplayer"
          [style.color]="(ss.colors | async).primary"
          (click)="resetInput()"
        >
          check
        </mat-icon>
      </div>
      <div class="z-label">{{ choice?.content | translateText : 'choice' : questionData : choice }}</div>
    </div>

    <ng-template #otherInput>
      <input-other
        [isActive]="true"
        [placeholder]="choice?.content | translateText : 'choice' : questionData : choice"
        [answer]="otherAnswer || ''"
        [style.margin-top.px]="4"
        [waitFocus]="waitOtherFocus"
        (answerChange)="changeOther($event)"
        (addOther)="selectChoice(choice)"
        (removeOther)="changeOther(null); showOtherInput = false"
      ></input-other>
    </ng-template>
  </ng-container>
</div>

<span *ngIf="!isValid" class="z-error-text">{{ errorText }}</span>

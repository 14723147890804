/**
 * Manages creation of shortened sharing urls & sharing pop-ups.
 *
 * @unstable
 */

import * as utf8 from 'utf8';

import { environment } from '@env/environment';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface SharingUrlParams {
  // OpenGraph settings
  ogUrl?: string;
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;

  title: string;
  description: string;

  type?: string;
  siteName?: string;
  url?: string;

  width?: number;
  height?: number;

  fbAppId?: string;

  // Helper properties for settings correct image settings
  imageTitleText?: string;
  imageButtonText?: string;
  imageBackgroundSrc?: string;
  imageSource: 'survey' | 'outcome';
}

// TODO: Should this be an interface and not a class
class SharingImageParams {
  constructor(
    public teamKey?: string,
    public surveyKey?: string,
    public revisionNo?: string,
    public imageSource?: 'survey' | 'outcome',
    public fields?: { name: string; value: string }[],
  ) {}
}

@Injectable()
export class SharesManager {
  private teamKey: string;
  private surveyKey: string;
  private releaseUrl: string;

  private endpoint = `${environment.apiServer}/share/url`;

  constructor(readonly http: HttpClient, @Optional() @Inject('isPublished') readonly allowSharing: boolean) {
    if (window.location.href.includes('zefProxy=true')) {
      this.endpoint = `https:${environment.bckServer}/share/url`;
    }

    if (window.ZEF && window.ZEF.version && window.ZEF.version !== 'default') {
      const version = window.ZEF.version.replace('x', '0').replace(/\./g, '-');

      this.endpoint = this.endpoint.replace('//', '//' + version + '.');
    }
  }

  public init(teamKey: string, surveyKey: string, releaseUrl: string) {
    [this.teamKey, this.surveyKey, this.releaseUrl] = [teamKey, surveyKey, releaseUrl];
  }

  public fetchSharingLink(service: any, params: SharingUrlParams): Observable<string> {
    if (!this.allowSharing) {
      // TODO: Handle situation where player is not published
      return of(null);
    }

    const { ogTitle, ogDescription, imageTitleText, imageButtonText, imageBackgroundSrc, imageSource } = params;

    const fields = this.generateFields({
      bgImage: imageBackgroundSrc,
      titleText: imageTitleText,
      buttonText: imageButtonText,
    });

    const revisionNumber = window.ZEF ? window.ZEF.revision : Math.random().toString();

    const imageParams = new SharingImageParams(this.teamKey, this.surveyKey, revisionNumber, imageSource, fields);

    const ogImage = this.encodeImageParams(imageParams);

    const surveyLinkParams = {
      title: ogTitle,
      description: ogDescription,
      image: ogImage,
      width: 540,
      height: 275,
      url: this.releaseUrl,
    };

    const payload = { share: surveyLinkParams, redirectUrl: window.location.origin + window.location.pathname };

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(this.endpoint, payload, { headers, withCredentials: true }).pipe(map((x: any) => x && x.url));
  }

  private generateFields(params: { bgImage: string; titleText: string; buttonText: string }) {
    const { bgImage, titleText, buttonText } = params;

    return [
      { name: 'backgroundImage.src', value: bgImage },
      { name: 'objects.0.text', value: titleText },
      { name: 'objects.1.objects.1.text', value: buttonText },
    ];
  }

  private encodeImageParams(image: SharingImageParams): string {
    const encodedUTF8 = utf8.encode(JSON.stringify(image));

    return btoa(encodedUTF8);
  }
}

import { fromEvent, Observable, race } from 'rxjs';
import { tap } from 'rxjs/operators';

const touchPassive = typeof CSS !== 'undefined' && !!CSS.supports && CSS.supports('overscroll-behavior', 'contain');

export function raceFromEvent(element: Node, events: (keyof HTMLElementEventMap)[]) {
  return race(
    events.map((eventName) =>
      fromEvent<MouseEvent | TouchEvent>(element, eventName, {
        passive: touchPassive || !eventName.includes('touch'),
        capture: true,
      }).pipe(
        tap((event) => {
          if (event) {
            event.stopPropagation();

            if (!touchPassive && eventName.includes('touch')) {
              event.preventDefault();
            }
          }
        }),
      ),
    ),
  );
}

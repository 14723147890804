<!--
  Simple infobox view for showing text in a box.

  @unstable
-->

<div *ngIf="show" class="wrapper">
  <div class="container">
    <mat-icon class="close-button" (click)="onCloseAndSave($event)">close</mat-icon>

    <h1>
      <ng-content select="[title]"></ng-content>
    </h1>

    <div class="content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>

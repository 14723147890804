import { concat, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Injectable, NgZone } from '@angular/core';

import { PaymentFailed, PaymentFailedResponse } from '@home/shared/dialogs/payment-failed/payment-failed.dialog';
import { TrialExpired, TrialExpiredResponse } from '@home/shared/dialogs/trial-expired/trial-expired.dialog';

import { LicenseUsage, PlanUsage } from '@shared/models/plan.model';

import {
  BillingFeedback,
  BillingPlanData,
  BillingStatus,
  ClientInfo,
  InvoiceInfo,
  LockedResource,
  LockedSurveyStatus,
  PaymentCard,
  PaymentStatus,
  SCATokenResponse,
  SMSPackage,
  SurveyLocked,
  TrialStatus,
} from '@shared/models/billing.model';
import { BillingApi } from '@home/shared/services/billing-api.service';

import type { PaymentIntentResult } from '@stripe/stripe-js';

import { Navigate } from '@ngxs/router-plugin';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';

import { PlanCustomization, PlanInfo } from '@plans/shared/models/plans.model';

import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { LicenseFeature } from '@shared/enums/license-feature.enum';
import { AppState } from '@shared/models/state.model';
import { PrefsState } from '@shared/states/prefs.state';
import { AccountState } from '@shared/states/account.state';
import { DialogControl } from '@shared/services/dialog-control.service';
import {
  CancelPlan,
  ConfirmSCA,
  DisableSMSAutoRefill,
  DowngradeStatusRead,
  EnableSMSAutoRefill,
  ExpiredSCA,
  GetActivePlan,
  GetBuyerInfo,
  GetLicenseUsage,
  GetLocked,
  GetPaymentCard,
  GetPaymentHistory,
  GetSmsPackages,
  GetUsage,
  GoToFeedback,
  PaymentStatusRead,
  RequestSCA,
  SetNewPlan,
  TrialStatusRead,
  TryFree,
  UnlockAnswers,
  UnlockContacts,
  UpdateCardInfo,
  UpdateClientInfo,
  VerifySCA,
} from '@shared/states/billing.actions';
import { CheckoutCanceled, CheckoutComplete, UpdateCart } from '@shared/states/cart.actions';
import { GetPlans } from '@shared/states/plan.actions';
import { AuthState } from '@shared/states/auth.state';
import { SignOutWithRedirect } from '@shared/states/auth.actions';
import { SwitchTeam } from '@shared/states/account.actions';
import { OpenDialog } from '@shared/states/dialog.actions';
import { StripeService } from '@shared/services/stripe.service';

/**
 * State model for the billing module
 */
export interface BillingStateModel {
  // TODO: create payment method and history interface once structure is known
  // Current active plan of the current active team
  activePlan: BillingPlanData;
  // Actual plan usage of active team used in usage card
  usage?: PlanUsage;
  lockedSurveys: LockedSurveyStatus[];
  lockedSources: LockedResource[];
  // Locked answers per survey used in locked card
  answersLocked: Partial<SurveyLocked>[];
  // Locked contacts per survey used in usage card
  contactsLocked: Partial<SurveyLocked>[];

  paymentHistory: InvoiceInfo[];
  paymentLoading: boolean;
  paymentMethod?: PaymentCard;
  clientInfo: ClientInfo;
  smsPackages: SMSPackage[];
  pendingPayment?: number;
  paymentFailed?: string;
}

const defaultStateModel: BillingStateModel = {
  activePlan: {
    plan: {
      id: 'free_plan',
      features: [],
    },
    options: [],
    paymentPlan: {
      period: 'monthly',
    },
  } as BillingPlanData,
  paymentHistory: [],
  paymentLoading: true,
  lockedSurveys: [],
  lockedSources: [],
  answersLocked: [],
  contactsLocked: [],
  clientInfo: {},
  smsPackages: [],
};

@Injectable()
@State<BillingStateModel>({
  name: 'billing',
  defaults: defaultStateModel,
})
export class BillingState {
  @Selector()
  static isNotPaid({ activePlan }: BillingStateModel): boolean {
    return !activePlan.plan || ['free_plan', 'trial_plan', 'parked_plan'].includes(activePlan.plan.id);
  }

  @Selector()
  static isFree({ activePlan }: BillingStateModel): boolean {
    return !activePlan.plan || ['free_plan'].includes(activePlan.plan.id);
  }

  @Selector()
  static isCanceled({ activePlan }: BillingStateModel): boolean {
    return !!activePlan.canceled;
  }

  @Selector()
  static isParked({ activePlan }: BillingStateModel): boolean {
    return activePlan.plan.id === 'parked_plan';
  }

  @Selector()
  static planName({ activePlan }: BillingStateModel): string {
    return activePlan.plan.name;
  }

  @Selector()
  static isSmsEnabled({ activePlan }: BillingStateModel): boolean {
    return (
      activePlan.plan.id === 'plan_genius' ||
      activePlan.plan.id === 'plan_master' ||
      activePlan.plan.id === 'plan_einstein' ||
      activePlan.plan.id === 'plan_pro' ||
      activePlan.plan.id === 'plan_enterprise' ||
      activePlan.plan.id === 'trial_plan'
    );
  }

  @Selector()
  static hasPaidPlan({ activePlan }: BillingStateModel): boolean {
    return [
      'plan_genius',
      'plan_master',
      'plan_einstein',
      'plan_pro',
      'plan_enterprise',
      'plan_basic',
      'plan_smart',
      'trial_plan',
    ].includes(activePlan.plan.id);
  }

  @Selector()
  static smsPackages({ smsPackages }: BillingStateModel): SMSPackage[] {
    return smsPackages;
  }

  static smsPackage(amount: number) {
    return createSelector(
      [BillingState],
      ({ smsPackages }: BillingStateModel): SMSPackage => smsPackages.find((p) => p.amount === amount),
    );
  }

  @Selector()
  static smsRemaining({ usage }: BillingStateModel): number {
    return (usage && usage.sms && usage.sms.count) || 0;
  }

  @Selector()
  static smsAutoRefill({ smsPackages, usage }: BillingStateModel): { enabled: boolean; package: SMSPackage | void } {
    const enabled = (usage && usage.sms && usage.sms.active) || false;
    const amount = (usage && usage.sms && usage.sms.retried) || 1000;
    const package_ = smsPackages.find((p) => p.amount === amount);

    return { enabled, package: package_ };
  }

  @Selector()
  static isApiEnabled({ activePlan }: BillingStateModel): boolean {
    return (
      activePlan.plan.id === 'plan_genius' ||
      activePlan.plan.id === 'plan_master' ||
      activePlan.plan.id === 'plan_einstein' ||
      activePlan.plan.id === 'plan_pro' ||
      activePlan.plan.id === 'plan_enterprise'
    );
  }

  // @Selector([CartState])
  // static getVat({ clientInfo }: BillingStateModel, cart: CartStateModel): number {
  //   return cart && cart.taxPercentage != null ? cart.taxPercentage : clientInfo.taxPercentage || 0;
  // }

  @Selector()
  static totalLockedContacts({ usage }: BillingStateModel): number {
    return usage?.contacts?.failed || 0;
  }

  @Selector()
  static totalLockedAnswers({ usage }: BillingStateModel): number {
    return usage?.answers?.failed || 0;
  }

  @Selector()
  static activePlan({ activePlan }: BillingStateModel): BillingPlanData {
    return activePlan;
  }

  @Selector()
  static cardComplete({ paymentMethod }: BillingStateModel): boolean {
    return !!paymentMethod?.complete;
  }

  @Selector()
  static clientComplete({ clientInfo }: BillingStateModel): boolean {
    return !!clientInfo?.complete;
  }

  @Selector()
  static activeOptions({ activePlan }: BillingStateModel): PlanCustomization[] {
    return activePlan?.options || [];
  }

  @Selector()
  static nextBillingDate({ activePlan }: BillingStateModel): Date | undefined {
    return activePlan?.paymentPlan?.nextBillingDate || activePlan?.upcoming?.paymentPlan?.nextBillingDate;
  }

  @Selector()
  static licenceUsage({ usage }: BillingStateModel): PlanUsage {
    return usage;
  }

  @Selector()
  static answersUsage({ usage }: BillingStateModel): LicenseUsage {
    return usage?.answers;
  }

  @Selector()
  static contactsUsage({ usage }: BillingStateModel): LicenseUsage {
    return usage?.contacts;
  }

  @Selector()
  static contactsRemaining({ usage }: BillingStateModel): number {
    return Math.max(0, usage?.contacts?.count - usage?.contacts?.success) || 0;
  }

  @Selector()
  static emailsUsage({ usage }: BillingStateModel): LicenseUsage {
    return usage?.emails;
  }

  @Selector()
  static smsUsage({ usage }: BillingStateModel): LicenseUsage {
    return usage?.sms;
  }

  @Selector()
  static extraAnswersUsage({ usage }: BillingStateModel): LicenseUsage {
    return usage?.extra_answers;
  }

  @Selector()
  static extraEmailsUsage({ usage }: BillingStateModel): LicenseUsage {
    return usage?.extra_emails;
  }

  @Selector()
  static lockedSurveys({ lockedSources }: BillingStateModel): LockedResource[] {
    return lockedSources.filter((source) => source.lockedAnswers > 0);
  }

  @Selector()
  static lockedSources({ lockedSources }: BillingStateModel): LockedResource[] {
    return lockedSources;
  }

  @Selector()
  static paymentMethod({ paymentMethod }: BillingStateModel): PaymentCard {
    return paymentMethod;
  }

  @Selector()
  static paymentLoading({ paymentLoading }: BillingStateModel): boolean {
    return paymentLoading;
  }

  @Selector()
  static clientInfo({ clientInfo }: BillingStateModel): ClientInfo {
    return clientInfo;
  }

  static featureActive(feature: LicenseFeature) {
    return createSelector([BillingState], ({ activePlan }: BillingStateModel): boolean =>
      LicenseFeature.planHasFeature(activePlan.plan.id, feature),
    );
  }

  @Selector()
  static billingStatus({ activePlan }: BillingStateModel): BillingStatus {
    if (activePlan) {
      if (activePlan.forcedFree === true) {
        return BillingStatus.FORCED_FREE;
      } else if (activePlan.scaRequired === true) {
        return BillingStatus.SCA_REQUIRED;
      } else if (activePlan.paymentStatus === PaymentStatus.PAYMENT_FAILED) {
        return BillingStatus.REVIEW_REQUIRED;
      } else if (activePlan.plan && activePlan.plan.id === 'free_plan') {
        return BillingStatus.FREE;
      } else if (activePlan.plan && activePlan.plan.id === 'parked_plan') {
        return BillingStatus.PARKED_PLAN;
      }
    }

    return BillingStatus.OK;
  }

  @Selector()
  static paymentCard({ paymentMethod }: BillingStateModel): PaymentCard {
    return paymentMethod;
  }

  @Selector()
  static paymentPrice({ pendingPayment }: BillingStateModel): number {
    return pendingPayment || 0;
  }

  @Selector()
  static paymentFailed({ paymentFailed }: BillingStateModel): string {
    return paymentFailed;
  }

  @Selector()
  static isPaymentExpired({ activePlan }: BillingStateModel): boolean {
    return !!activePlan?.scaExpired;
  }

  @Selector()
  static isScaRequeired({ activePlan }: BillingStateModel): boolean {
    return !!activePlan?.scaRequired;
  }

  @Selector()
  static failedAnswersCount({ usage }: BillingStateModel): number {
    return usage?.answers?.failed || 0;
  }

  @Selector()
  static failedContactsCount({ usage }: BillingStateModel): number {
    return usage?.contacts?.failed || 0;
  }

  @Selector()
  static paymentHistory({ paymentHistory }: BillingStateModel): InvoiceInfo[] {
    return paymentHistory;
  }

  constructor(
    private store: Store,
    private zone: NgZone,
    private ba: BillingApi,
    private dc: DialogControl,
    private ss: StripeService,
  ) {}

  @Action(GetLocked)
  getLocked({ patchState }: StateContext<BillingStateModel>): Observable<any> {
    return this.ba.getLocked().pipe(
      catchError(() => of([])),
      map((lockedSources) => {
        patchState({ lockedSources });
      }),
    );
  }

  @StreamAction([GetActivePlan])
  getActivePlan(ctx: StateContext<BillingStateModel>): Observable<any> {
    return this.ba.getActivePlan().pipe(
      concatMap((activePlan) => this.parsePlan(activePlan, ctx)),
      tap((activePlan) => this.checkStatus(activePlan, ctx)),
    );
  }

  @Action(GetPaymentHistory, { cancelUncompleted: true })
  getPaymentHistory({ patchState }: StateContext<BillingStateModel>): Observable<BillingStateModel> {
    return this.ba
      .getPaymentHistory()
      .pipe(map((paymentHistory) => patchState({ paymentHistory, paymentLoading: false })));
  }

  @Action(GetBuyerInfo, { cancelUncompleted: true })
  getBuyerInfo({ patchState }: StateContext<BillingStateModel>): Observable<BillingStateModel> {
    return this.ba.getAccountDetails().pipe(map((clientInfo) => patchState({ clientInfo })));
  }

  @Action(GetPaymentCard, { cancelUncompleted: true })
  getPaymentCard({ patchState }: StateContext<BillingStateModel>): Observable<BillingStateModel> {
    return this.ba.getPaymentCard().pipe(map((paymentMethod) => patchState({ paymentMethod })));
  }

  @StreamAction(GetUsage)
  getUsage({ patchState, dispatch, getState }: StateContext<BillingStateModel>): Observable<BillingStateModel> {
    const types = ['emails', 'contacts', 'answers', 'sms'];
    return this.ba.getUsage().pipe(
      concatMap((usage: PlanUsage) => {
        const unknownCount = types.filter((type) => !usage[type].count);
        if (unknownCount.length) {
          return dispatch(new GetActivePlan()).pipe(
            map(() => {
              const { activePlan } = getState();
              return unknownCount.reduce((dict, type) => {
                const count: number = activePlan.plan[type];
                const options = activePlan.options || [];
                const extra = options.find((option) => option.id === `extra_${type}`);

                return { ...dict, [type]: { ...usage[type], count: count + ((extra && extra.value) || 0) } };
              }, usage) as PlanUsage;
            }),
          );
        }

        return of(usage);
      }),
      map((usage: PlanUsage) => patchState({ usage })),
    );
  }

  @StreamAction(GetLicenseUsage)
  getLicenseUsage(
    { patchState, dispatch, getState }: StateContext<BillingStateModel>,
    { teamKey }: GetLicenseUsage,
  ): Observable<BillingStateModel> {
    const types = ['emails', 'contacts', 'answers', 'sms'];
    return this.ba.getUsage(teamKey).pipe(
      concatMap((usage: PlanUsage) => {
        const unknownCount = types.filter((type) => !usage[type].count);
        if (unknownCount.length) {
          return dispatch(new GetActivePlan()).pipe(
            map(() => {
              const { activePlan } = getState();
              return unknownCount.reduce((dict, type) => {
                const count: number = activePlan.plan[type];
                const options = activePlan.options || [];
                const extra = options.find((option) => option.id === `extra_${type}`);

                return { ...dict, [type]: { ...usage[type], count: count + ((extra && extra.value) || 0) } };
              }, usage) as PlanUsage;
            }),
          );
        }

        return of(usage);
      }),
      map((usage: PlanUsage) => patchState({ usage })),
    );
  }

  @Action(UnlockAnswers)
  unlockAnswers({ dispatch }: StateContext<BillingStateModel>, { settings }: UnlockAnswers) {
    return this.ba.unlockSource(settings).pipe(map(({ unlockedCount }) => dispatch(new GetLocked())));
  }

  @Action(UnlockContacts)
  unlockContacts({ dispatch }: StateContext<BillingStateModel>, { settings }: UnlockContacts) {
    return this.ba.unlockSource(settings).pipe(map(({ unlockedCount }) => dispatch(new GetLocked())));
  }

  @Action(TryFree, { cancelUncompleted: true })
  tryFree({ dispatch }: StateContext<BillingStateModel>): Observable<void> {
    return this.ba.tryFree().pipe(
      map((status) => {
        if (status) {
          dispatch(new GoToFeedback(BillingFeedback.PlanUpgraded));
        }
      }),
    );
  }

  @Action(GoToFeedback)
  goToFeedback({ dispatch }: StateContext<BillingStateModel>, { feedback }: GoToFeedback): Observable<void> {
    return dispatch(new Navigate(['settings/billing'], void 0, { fragment: feedback }));
  }

  @Action(UpdateClientInfo, { cancelUncompleted: true })
  updateClientInfo(
    { patchState, getState, dispatch }: StateContext<BillingStateModel>,
    { clientInfo }: UpdateClientInfo,
  ): Observable<any> {
    const oldClientInfo = getState().clientInfo;
    const needsCartUpdate =
      oldClientInfo.country !== clientInfo.country ||
      oldClientInfo.vatId !== clientInfo.vatId ||
      oldClientInfo.type !== clientInfo.type;

    patchState({ clientInfo });

    return this.ba.updateClientInfo(clientInfo).pipe(
      tap(({ taxPercentage }) => {
        const client = { ...getState().clientInfo, taxPercentage };
        patchState({ clientInfo: client });
        if (needsCartUpdate) {
          dispatch(new UpdateCart());
        }
      }),
    );
  }

  @Action(UpdateCardInfo, { cancelUncompleted: true })
  updateCardInfo(
    { patchState, getState, dispatch }: StateContext<BillingStateModel>,
    { paymentMethod }: UpdateCardInfo,
  ): Observable<void> {
    return this.ba.updateCardToken(paymentMethod.cardToken).pipe(
      map((response: SCATokenResponse) => {
        const scaToken = response && typeof response === 'object' && response.scaToken;

        patchState({ paymentMethod });

        if (scaToken) {
          dispatch(new VerifySCA());
        }
      }),
      catchError(() => of(null)),
    );
  }

  @Action(TrialStatusRead, { cancelUncompleted: true })
  trialStatusRead({ dispatch }: StateContext<BillingStateModel>, { response }: TrialStatusRead): Observable<void> {
    return this.ba.trialStatusRead().pipe(
      tap(() => {
        if (response === TrialExpiredResponse.Select) {
          dispatch(new Navigate(['/plans']));
        }
      }),
    );
  }

  @Action(DowngradeStatusRead, { cancelUncompleted: true })
  dowgradeStatusRead(ctx: StateContext<BillingStateModel>): Observable<void> {
    return this.ba.dowgradeStatusRead();
  }

  @Action(PaymentStatusRead, { cancelUncompleted: true })
  paymentStatusRead({ dispatch }: StateContext<BillingStateModel>, { response }: PaymentStatusRead): Observable<void> {
    return this.ba.paymentStatusRead().pipe(
      tap(() => {
        if (response === PaymentFailedResponse.Review) {
          dispatch(new Navigate(['/settings/billing']));
        }
      }),
    );
  }

  @Action(CancelPlan, { cancelUncompleted: true })
  cancelPlan(
    { patchState, getState, dispatch }: StateContext<BillingStateModel>,
    { response }: CancelPlan,
  ): Observable<void> {
    return concat(this.ba.cancelPlan(response), dispatch(new GoToFeedback(BillingFeedback.PlanChanged)));
  }

  @Action(SetNewPlan, { cancelUncompleted: true })
  setNewPlan({ patchState }: StateContext<BillingStateModel>, { newPlan }: SetNewPlan): BillingStateModel {
    return patchState({ activePlan: { ...newPlan } });
  }

  @Action(ExpiredSCA)
  expiredSCA({ getState, patchState }: StateContext<BillingStateModel>) {
    const plan = getState().activePlan;
    patchState({ activePlan: { ...plan, scaExpired: true } });
  }

  @Action(GetSmsPackages, { cancelUncompleted: true })
  getSmsPackages({ patchState }: StateContext<BillingStateModel>) {
    return this.ba.getSmsPackages().pipe(
      map((smsPackages) => smsPackages.sort((a, b) => (a && b ? a.amount - b.amount : 0))),
      map((smsPackages) => patchState({ smsPackages })),
    );
  }

  @Action(RequestSCA)
  requestSCA({ dispatch, getState, patchState }: StateContext<BillingStateModel>) {
    return this.ba.getStripeToken().pipe(
      tap(({ pendingPayment }: SCATokenResponse) => {
        if (pendingPayment) {
          patchState({ pendingPayment });
        }
      }),
      catchError((error) => {
        const scaExpired = error && error.status === 404;

        return dispatch(scaExpired ? new ExpiredSCA() : []);
      }),
    );
  }

  @Action(VerifySCA)
  verifySCA({ dispatch, patchState }: StateContext<BillingStateModel>) {
    return this.ba.getStripeToken().pipe(
      switchMap(({ scaToken }: SCATokenResponse) => {
        if (!scaToken) {
          return of(null);
        } else {
          return this.ss.confirmCardPayment(scaToken).pipe(
            switchMap((result: PaymentIntentResult) => {
              if (result?.paymentIntent) {
                if (!this.store.selectSnapshot(PrefsState.isMobile)) {
                  this.openAuthSuccessDialog();
                }

                return dispatch(new GetActivePlan());
              } else if (result?.error?.code) {
                patchState({ paymentFailed: result.error.code });

                if (!this.store.selectSnapshot(PrefsState.isMobile)) {
                  this.dc.open(PaymentFailed);
                }
              }

              return of(null);
            }),
          );
        }
      }),
      catchError((error) => {
        const scaExpired = error && error.status === 404;

        return dispatch(scaExpired ? new ExpiredSCA() : []);
      }),
    );
  }

  private openAuthSuccessDialog() {
    const team = this.store.selectSnapshot(AccountState.team);

    this.dc
      .openTeamDialog(
        team,
        'success',
        $localize`:dialog title@@zef-i18n-00052:Authentication successful`,
        '',
        $localize`:dialog content@@zef-i18n-00053:Everything is set. You can close this dialog.`,
        [$localize`:dialog button label@@zef-i18n-00054:Done`],
      )
      .afterClosed()
      .subscribe(() => {
        if (this.store.selectSnapshot(AuthState.authScope)) {
          window.location.assign(`https:${environment.wwwAddress}`);

          window.close();
        }
      });
  }

  @Action(ConfirmSCA)
  confirmSCA({ dispatch }: StateContext<BillingStateModel>, { scaToken }: ConfirmSCA): Observable<unknown> {
    return !scaToken
      ? of(null)
      : this.ss.confirmCardPayment(scaToken).pipe(
          switchMap((result: any) => {
            if (result && result.paymentIntent) {
              return dispatch(new CheckoutComplete());
            } else {
              // "CheckoutCanceled" for dismissing progress overlay
              // "CheckoutComplete" for clearing out the cart
              return dispatch([new CheckoutCanceled(), new CheckoutComplete()]);
            }
          }),
        );
  }

  @Action(EnableSMSAutoRefill)
  enableSMSAutoRefill({ dispatch }: StateContext<BillingStateModel>, { smsPackage }: EnableSMSAutoRefill) {
    return this.ba.smsRefill(true, smsPackage.amount);
  }

  @Action(DisableSMSAutoRefill)
  disableSMSAutoRefill({ getState }: StateContext<BillingStateModel>) {
    const { usage } = getState();
    const amount = (usage && usage.sms && usage.sms.retried) || 0;
    return this.ba.smsRefill(false, amount);
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ setState }: StateContext<BillingStateModel>) {
    setState(defaultStateModel);
  }

  private checkStatus(
    activePlan: BillingPlanData,
    { patchState, getState, dispatch }: StateContext<BillingStateModel>,
  ): void {
    const previousStatus = getState().activePlan?.trialStatus;

    patchState({ activePlan });

    if (!activePlan.paymentStatus && activePlan.trialStatus !== TrialStatus.TRIAL_EXPIRED) {
      return;
    }

    if (previousStatus === activePlan.trialStatus) {
      return;
    }

    if (activePlan.trialStatus === TrialStatus.TRIAL_EXPIRED) {
      dispatch(new OpenDialog(TrialExpired));
    }
  }

  private parsePlan(
    activePlan: BillingPlanData,
    { dispatch }: StateContext<BillingStateModel>,
  ): Observable<BillingPlanData> {
    activePlan = {
      options: [],
      paymentPlan: {
        period: 'monthly',
        price: 0,
      },
      ...activePlan,
    };

    if (!activePlan.plan) {
      return dispatch(new GetPlans()).pipe(map(() => this.setToFreePlan(activePlan)));
    } else {
      return of(activePlan);
    }
  }

  private setToFreePlan(activePlan): BillingPlanData {
    const plans = this.store.selectSnapshot(({ plan }: AppState) => plan.plans || []);
    const freePlan = plans.find((plan) => plan.id === 'free_plan');

    activePlan.plan =
      freePlan ||
      ({
        id: 'free_plan',
        name: 'Free',
        features: [],
      } as PlanInfo);

    return activePlan;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliderAnswer',
})
export class SliderAnswer implements PipeTransform {
  public transform(value: string | null): string | string[] | null {
    if (value == null) {
      return null;
    }

    const parts = value.toString().split(';');

    return parts.length > 1 ? parts : parts[0];
  }
}

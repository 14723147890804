<zef-promo-dialog backgroundImage="assets/images/promo-api.png" requiredPlan="plan_genius">
  <span dialog-title i18n>Automate over API</span>

  <div dialog-list>
    <span i18n>Monitor Responses</span>
    <span i18n>Manage Contacts and Surveys</span>
    <span i18n>Share surveys via links or email invitations</span>
  </div>

  <span dialog-button i18n>Get API integration</span>

  <div dialog-footnote class="zef-text-light zef-text-p2">
    <ng-container i18n>Learn more at</ng-container>
    <a class="zef-hyper-link" href="http://developer.zef.fi" target="_blank">developer.zef.fi</a>
  </div>
</zef-promo-dialog>

import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateTimeProvider {
  readonly date$ = new ReplaySubject<Date>(1);
}

import { Subject } from 'rxjs';

import { InjectionToken, Type, Directive, OnDestroy, ViewContainerRef, NgModuleRef, ViewChild } from '@angular/core';
import { SidenavDirective } from '@shared/modules/sidenav/directives/sidenav.directive';

export interface SidenavConfig<T = any, D = any> {
  position?: 'left' | 'right';
  // default true
  scrim?: boolean;
  // default true
  backdrop?: boolean;
  width?: number | string;
  component?: Type<T>;
  data?: D;
  viewContainerRef?: ViewContainerRef;
  ngModuleRef?: NgModuleRef<any>;
}

export type SidenavUpdateType = 'gallery';

export const SidenavDefaults: SidenavConfig = {
  position: 'right',
  width: 800,
};

export const SIDENAV_CONFIG = new InjectionToken<SidenavConfig>('zef-sidenav-config');

export const SIDENAV_DATA = new InjectionToken<SidenavConfig['data']>('zef-sidenav-data');

@Directive()
export abstract class SidenavRef<T = void> implements OnDestroy {
  @ViewChild(SidenavDirective, { static: true })
  sidenav: SidenavDirective;

  protected _isOpen = true;

  protected _close = new Subject<void>();

  readonly close$ = this._close.asObservable();

  close(response?: T): void {
    this.sidenav.closeSidenav(response);
  }

  afterClose(): void {
    this._isOpen = false;
    this._close.next();
  }

  ngOnDestroy(): void {
    this._close.complete();
  }
}

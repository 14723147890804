import { Observable, Subject } from 'rxjs';
import { buffer, debounceTime, map, takeUntil } from 'rxjs/operators';

import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class SliderLabel implements OnDestroy {
  readonly syncSize: { [key: string]: Observable<number> } = {};
  readonly updateSize: { [key: string]: Subject<number> } = {};
  readonly destroy = new Subject<void>();

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  getSync(type: string): Observable<number> {
    if (!this.syncSize[type]) {
      this.syncSize[type] = this.getUpdate(type).pipe(
        buffer(this.getUpdate(type).pipe(debounceTime(1))),
        map((sizes) => Math.min(...sizes)),
        takeUntil(this.destroy),
      );
    }

    return this.syncSize[type];
  }

  getUpdate(type): Subject<number> {
    if (!this.updateSize[type]) {
      this.updateSize[type] = new Subject<number>().pipe(takeUntil(this.destroy)) as Subject<number>;
    }

    return this.updateSize[type];
  }

  update(value: number, type: string): void {
    this.getUpdate(type).next(value);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Chip } from '@shared/components/chip/chip.component';

@NgModule({
  declarations: [Chip],
  imports: [CommonModule],
  exports: [Chip],
})
export class ChipModule {}

import { Component, Input, Optional } from '@angular/core';

import { environment } from '@env/environment';
import { getShareLink } from '@player/shared/models/sharing.model';
import { TranslateText } from '@player/shared/pipes/translate-text.pipe';
import { LanguageManager } from '@player/shared/services/language-manager.service';
import { SharesManager, SharingUrlParams } from '@player/shared/services/shares-manager.service';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { Outcomes } from '@shared/enums/outcomes.enum';

import { Services } from '@shared/enums/services.enum';
import { OutcomeData, ServiceData } from '@shared/models/survey.model';
import { NameIconMap } from '@shared/models/utility.model';
import { PropertyText } from '@shared/pipes/property-text.pipe';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';

/**
 * Handles fetching of sharing urls & social shares
 */
@Component({
  selector: 'share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
})
export class ShareButtons {
  @Input() outcomeData?: OutcomeData;

  readonly servicesList = this.ss.sharing.pipe(
    filter((sharing) => !!sharing),
    map((sharing) => (this.isOutcomeSharing() ? sharing.outcomeSharing : sharing)),
    map((sharing) =>
      Services.social
        .filter((service) => sharing[service.id] && sharing[service.id].enabled)
        .map(
          (service) =>
            ({
              ...sharing[service.id],
              id: service.id,
              name: service.name,
              icon: service.icon,
            } as ServiceData & NameIconMap),
        ),
    ),
  );

  constructor(
    readonly sm: SharesManager,
    readonly ss: SurveyStore,
    readonly tt: TranslateText,
    readonly pt: PropertyText,
    readonly lm: LanguageManager,
  ) {}

  public onShareSurvey(service: ServiceData & NameIconMap): void {
    if (!this.sm?.allowSharing || !service) {
      return;
    }

    combineLatest(this.ss.survey, this.ss.sharing)
      .pipe(
        take(1),
        mergeMap(([survey, sharing]) => {
          const isOutcome = this.isOutcomeSharing();
          const ttParams = [];
          const ptParams = [this.lm.currentLanguage];

          sharing = isOutcome ? sharing.outcomeSharing : sharing;

          if (isOutcome) {
            ttParams.push('outcomeSharing');
            ptParams.push(this.outcomeData.$key);
          }

          const params: SharingUrlParams = {
            siteName: 'ZEF',
            imageSource: isOutcome ? 'outcome' : 'survey',

            title:
              this.pt.transform(
                this.tt.transform(service.shareTitle, ...[...ttParams, service.id, 'shareTitle']),
                ...ptParams,
              ) || '',
            description:
              this.pt.transform(
                this.tt.transform(service.shareBody, ...[...ttParams, service.id, 'shareBody']),
                ...ptParams,
              ) || '',

            ogTitle:
              this.pt.transform(
                this.tt.transform(
                  isOutcome ? this.outcomeData.title : survey.title,
                  ...(isOutcome ? ['title', this.outcomeData] : ['surveyTitle']),
                ),
                ...ptParams,
              ) || 'Zeffi Survey',
            ogDescription:
              this.pt.transform(
                this.tt.transform(
                  isOutcome ? this.outcomeData.content : survey.description,
                  ...(isOutcome ? ['content', this.outcomeData] : ['surveyDescription']),
                ),
                ...ptParams,
              ) || 'Powered by Zeffi',

            imageTitleText: this.pt.transform(
              this.tt.transform(sharing.titleText, ...[...ttParams, 'imageDataTitle']),
              ...ptParams,
            ),
            imageButtonText: this.pt.transform(
              this.tt.transform(sharing.buttonText, ...[...ttParams, 'imageDataCta']),
              ...ptParams,
            ),
            imageBackgroundSrc:
              (isOutcome &&
                this.tt.transform(this.outcomeData.image && this.outcomeData.image.url, 'image', this.outcomeData)) ||
              null,

            url: window.location.href,
          };

          const shareWaitAddress = `https:${environment.surveyAddress}/sharing`;

          const h = 400;
          const w = 600;
          const y = window.screen.height / 2 - h / 2;
          const x = window.screen.width / 2 - w / 2;

          const popup = window.open(
            shareWaitAddress,
            'shareWindow',
            'width=' +
              w +
              ',height=' +
              h +
              ',left=' +
              x +
              ',top=' +
              y +
              ',' +
              'location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1',
          );

          params.url = window.location.href;

          // Switch title & description to open graph for Facebook, Google
          if (['facebook', 'google', 'linkedin'].includes(service.id)) {
            params.ogTitle = params.title;
            params.ogDescription = params.description;
          }

          return this.sm.fetchSharingLink(service.id, params).pipe(
            map((url) => ({ popup, url, params })),
            catchError((err) => {
              console.error(err);

              if (popup) {
                popup.postMessage({ error: true }, '*');
              }

              return of({ url: '', popup, params });
            }),
          );
        }),
      )
      .subscribe(({ url, popup, params }) => {
        if (url) {
          const shareLink = getShareLink(service.id, params.title, params.description, url);

          popup.location.replace(shareLink);

          if (service.id === 'email') {
            // Used so popup doesn't close before email client is opened
            setTimeout(() => popup.close(), 5000);
          }
        }
      });
  }

  private isOutcomeSharing(): boolean {
    return !!this.outcomeData && this.outcomeData.type === Outcomes.OUTCOME;
  }
}

import { NgZone, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { environment } from '@env/environment.beta';
import { SurveyType } from '@editor/shared/models/template.model';
import { DiscoverSurvey } from '@home/shared/dialogs/discover-survey/discover-survey.dialog';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { TemplateModel, SurveyModel, ReleaseData, SharingData, DesignData } from '@shared/models/survey.model';
import { DialogControl } from '@shared/services/dialog-control.service';
import { SurveysManager } from '@shared/services/surveys-manager.service';
import { PrefsState, PrefsStateModel } from '@shared/states/prefs.state';
import { pickBy } from '@shared/utilities/object.utilities';
import { Observable, combineLatest, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import {
  CreateTemplate,
  DiscoverTemplate,
  FilterTemplates,
  GetTemplatesSurveys,
  GetTemplateTypes,
  LoadTemplates,
  ResetTemplatesFilter,
  SetTemplate,
  ToggleTemplatesFilter,
} from './templates.actions';
import { RouterState } from './router.state';
import { MoveToFolder } from './folders.actions';

export interface TemplatesStateModel {
  types: SurveyType[];
  templates: { [key: string]: TemplateModel[] };
  activeTemplate?: TemplateModel;
  activeTemplateKey?: string;
  activeGroup?: string;

  filtering: {
    query?: string;
    category?: string;
    language?: string;
    complexity?: number;
    visible?: boolean;
  };
}

@Injectable()
@State<TemplatesStateModel>({
  name: 'templates',
  defaults: {
    types: [],
    templates: {},
    filtering: {
      visible: false,
    },
  },
})
export class TemplatesState {
  private dialogRef?: MatDialogRef<DiscoverSurvey>;

  @Selector([PrefsState])
  static discoverableTemplates({ templates }: TemplatesStateModel, { language }: PrefsStateModel) {
    const discoverable = ['grfuwy', 'iwncpj', 'bomati'];

    const filtered = Object.values(templates)
      .reduce((a, c) => a.concat(c), [])
      .filter(({ webkey }) => discoverable.includes(webkey))
      .filter(({ survey }) => survey.language === language);

    return discoverable.map((webkey) => filtered.find((template) => template.webkey === webkey));
  }

  @Selector()
  static allTemplates({ templates }: TemplatesStateModel) {
    return TemplatesState.templatesArray(templates);
  }

  private static templatesArray(templates: { [key: string]: TemplateModel[] }) {
    return Object.values(templates).reduce((a, c) => a.concat(c), []);
  }

  private static getLocaleTemplates(
    templates: { [key: string]: TemplateModel[] },
    types: SurveyType[],
    language: string,
  ) {
    const categories = Object.values(types)
      .filter((t) => t.language.startsWith(language || ''))
      .map((t) => t.$key);

    return TemplatesState.templatesArray(templates).filter((t) =>
      categories.some((category) => t.category === category),
    );
  }

  @Selector()
  static localeCategories({ types, filtering }: TemplatesStateModel) {
    return types.filter((t) => t.language.startsWith(filtering.language || ''));
  }

  @Selector([PrefsState.language])
  static templateFilter({ filtering }: TemplatesStateModel, lang: string) {
    const language = filtering.language === undefined ? lang : filtering.language;
    return { ...filtering, language };
  }

  @Selector([RouterState.routeParams])
  static previewActive(state: TemplatesStateModel, params: Params) {
    return state.activeTemplate && !params.survey && !params.surveyKey;
  }

  @Selector([RouterState.routeParams])
  static selectedTemplate(state: TemplatesStateModel, params: Params) {
    // Template name or category name from URL
    const name = params && params.templateName;
    const ts = TemplatesState.allTemplates(state);

    const template = ts.find((t) => t.key === name);
    const category = state.types.find((c) => c.$key === name);

    if (category) {
      return ts.filter((t) => t.category === category.$key).map((t) => t.key)[0];
    }
    if (template) {
      return name;
    }

    // console.warn(template, category);
    return params.template || params.templateKey;
  }

  @Selector([RouterState.routeParams, PrefsState.language])
  static filteredTemplates({ templates, types, filtering }: TemplatesStateModel, params: Params, lang: string) {
    const language = filtering.language === undefined ? lang : filtering.language;
    const ts = TemplatesState.getLocaleTemplates(templates, types, language);
    const tz = TemplatesState.filtered(ts, filtering.complexity);

    return ts
      .filter((template) => template.webkey)
      .filter((template) => {
        // filter by category
        const searchCategory = (filtering.category || '').toLowerCase();
        const categoryKeys = types
          .filter((t) => (t.name || '').toLowerCase().includes(searchCategory) || t.$key === searchCategory)
          .map((t) => t.$key);

        const name = params && params.templateName;
        const category = types.find((c) => c.$key === name);

        return category ? template.category === category.$key : categoryKeys.includes(template.category);
      })
      .filter((template) => tz.includes(template.key))
      .filter((template) => {
        // filter by term
        const templateSearchableText = [template.author, template.survey.name].join(' ').toLowerCase();
        const searchQuery = (filtering.query || '').toLowerCase();

        return templateSearchableText.includes(searchQuery);
      });
  }

  private static filtered(templates: TemplateModel[], complexity = 0) {
    const sorted = [...templates].sort((a, b) => {
      const A = Object.keys(a.questions).length;
      const B = Object.keys(b.questions).length;

      return A < B ? -1 : A > B ? 1 : 0;
    });

    const count = sorted.length;
    const step = 100 / count;
    let inRange: TemplateModel[];

    if (complexity < step) {
      const end = count + 1 + Math.floor(complexity / step);
      inRange = sorted.slice(0, end);
    } else if (complexity > step) {
      const start = Math.floor(complexity / step) - 1;
      inRange = sorted.slice(start);
    } else {
      inRange = sorted;
    }

    return inRange.map((t) => t.key);
  }

  @Selector()
  static templateTypes({ types }: TemplatesStateModel) {
    return types;
  }

  @Selector()
  static activeTemplate({ activeTemplate }: TemplatesStateModel) {
    return activeTemplate;
  }

  @Selector()
  static activeTemplateForPreview({ activeTemplate, activeTemplateKey }: TemplatesStateModel): Partial<SurveyModel> {
    return (
      activeTemplate && {
        survey: { ...activeTemplate.survey, $key: activeTemplateKey },
        questions: activeTemplate.questions,
        outcomes: activeTemplate.outcomes || [],
        design: activeTemplate.design || new DesignData(),
        sharing: activeTemplate.sharing || new SharingData(),
        release: activeTemplate.release || new ReleaseData(),
        scoring: activeTemplate.scoring || null,
        locales: activeTemplate.locales || null,
      }
    );
  }

  @Selector()
  static activeTemplateKey({ activeTemplateKey }: TemplatesStateModel) {
    return activeTemplateKey;
  }

  @Selector([TemplatesState.activeTemplate])
  static templateLanguage({ filtering }: TemplatesStateModel, template: TemplateModel) {
    return filtering?.language || template?.survey?.language;
  }

  constructor(readonly zone: NgZone, readonly store: Store, readonly dc: DialogControl, readonly sm: SurveysManager) {}

  @Action(SetTemplate)
  setTemplate(ctx: StateContext<TemplatesStateModel>, { templateKey }: SetTemplate): void {
    const url = templateKey ? `/surveys/create/${templateKey}` : '/surveys/create';

    const state = ctx.getState();
    const templates = TemplatesState.templatesArray(state.templates);
    const activeTemplate = templates.find((t) => t.key === templateKey);
    const queryParams = this.store.selectSnapshot(RouterState.queryParams);

    ctx.patchState({ activeTemplateKey: templateKey, activeTemplate });
    ctx.dispatch(new Navigate([url], queryParams, { preserveFragment: true }));
  }

  @Action(DiscoverTemplate)
  discoverTemplate(
    ctx: StateContext<TemplatesStateModel>,
    { templateKey, templates, startView, componentFactoryResolver, viewContainerRef }: DiscoverTemplate,
  ) {
    const desktopConfig: MatDialogConfig<any> = {
      panelClass: 'discover-dialog',
      height: '1100px',
      width: '1600px',
      maxHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 80px)',
    };

    const mobileConfig: MatDialogConfig<any> = {
      panelClass: 'discover-dialog-mobile',
      height: '1100px',
      width: '1600px',
      maxHeight: '100vh',
      maxWidth: '100vw',
    };

    const config: MatDialogConfig = {
      ...(this.store.selectSnapshot(PrefsState.isMobile) ? mobileConfig : desktopConfig),
      componentFactoryResolver,
      viewContainerRef,
    };

    const activeTemplate = templates.find((t) => t.key === templateKey);

    ctx.patchState({ activeTemplateKey: templateKey, activeTemplate });

    this.zone.run(() => {
      if (!this.dialogRef) {
        const inputs = { templateKey, startView, templates };

        this.dialogRef = this.dc.open(DiscoverSurvey, inputs, config);

        this.dialogRef.afterClosed().subscribe(() => {
          this.dialogRef = undefined;
        });
      } else {
        this.dialogRef.componentInstance.changeTemplate(templateKey);
      }
    });
  }

  @Action(FilterTemplates)
  filterTemplates(
    { getState, patchState }: StateContext<TemplatesStateModel>,
    { query, category, language, complexity }: FilterTemplates,
  ) {
    const oldFilter = getState().filtering;
    const newFilter = pickBy({ query, category, language, complexity }, (val) => val != null);

    patchState({ filtering: { ...oldFilter, ...newFilter } });
  }

  @Action(ResetTemplatesFilter)
  resetTemplatesFilter({ patchState }: StateContext<TemplatesStateModel>) {
    patchState({ filtering: { visible: false } });
  }

  @Action(ToggleTemplatesFilter)
  toggleTemplatesFilter({ patchState }: StateContext<TemplatesStateModel>, { visible }: ToggleTemplatesFilter) {
    patchState({ filtering: { visible } });
  }

  @Action(LoadTemplates)
  loadTemplates({ dispatch }: StateContext<TemplatesStateModel>) {
    return dispatch(new GetTemplateTypes()).pipe(mergeMap(() => dispatch(new GetTemplatesSurveys())));
  }

  @Action(CreateTemplate)
  createTemplate(ctx: StateContext<TemplatesStateModel>, { templateKey }: CreateTemplate): Observable<void> {
    const state = ctx.getState();

    if (this.sm.creatingSurvey.value) {
      return of(void 0);
    }

    const templates = TemplatesState.templatesArray(state.templates);
    const template = templates.find((t) => t.key === templateKey);

    const locale = this.store.selectSnapshot(PrefsState.locale);
    const date = new DatePipe(locale).transform(Date.now(), 'shortDate');
    const surveyTitle =
      (template && template.survey && template.survey.name) ||
      $localize`:@@zef-i18n-00064:New survey ${date}:INTERPOLATION:`;

    const survey = template
      ? this.sm.connectTemplate(template.category, template.key)
      : this.sm.connectTemplate('default', 'empty');

    return this.sm.createSurvey(surveyTitle, survey, false, !!template).pipe(
      map(({ key }) => {
        let path = '/surveys';

        const url = `/surveys/edit/${key}/build`;
        const lang = this.store.selectSnapshot(PrefsState.language);

        if (lang === 'fi' || window.location.pathname.slice(0, 4) === '/fi/') {
          path = '/fi/kyselyt';
        } else if (lang === 'sv' || window.location.pathname.slice(0, 4) === '/sv/') {
          path = '/sv/undersokningar';
        }

        if (environment.website && window.location.pathname.indexOf(path) !== 0) {
          window.location.assign(path + '#' + url);
        } else {
          const { folderKey } = this.store.selectSnapshot(RouterState.queryParams);
          if (!!folderKey) {
            ctx.dispatch([new MoveToFolder(folderKey, [key]), new Navigate([url])]);
          } else {
            ctx.dispatch(new Navigate([url]));
          }
        }
      }),
    );
  }

  @StreamAction(GetTemplateTypes)
  getTemplates({ patchState }: StateContext<TemplatesStateModel>): Observable<TemplatesStateModel> {
    return this.sm.listTemplates().pipe(map((types) => patchState({ types })));
  }

  @StreamAction(GetTemplatesSurveys)
  getTemplatesSurveys({ patchState, getState }: StateContext<TemplatesStateModel>): Observable<any> {
    return combineLatest(
      getState().types.map(({ $key }) =>
        this.sm
          .listTemplateSurveys($key)
          .pipe(map((surveys) => ({ [$key]: surveys.map((survey) => ({ ...survey, category: $key })) }))),
      ),
    ).pipe(
      tap((templates) => {
        patchState({ templates: templates.reduce((acc, prev) => Object.assign(acc, prev), {}) });
      }),
    );
  }
}

import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener } from '@angular/core';

import { ChartDistribution, ChartDomain } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

/**
 * This is a horizontal area chart.
 */
@Directive({
  selector: '[areaChart]',
})
export class AreaChart implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;

  private svg: any;
  private css: any;
  private base: any;
  private lineGroup: any;

  private lines: any;
  private line: any;

  private dots: any;

  private scaleX: any;
  private scaleY: any;

  private axisX: any;
  private axisY: any;

  private brush: any;
  private brushArea: any;

  private transition: any;

  private width: any;
  private height: any;
  private margin: any;

  private filter: any;

  @HostListener('window:resize') resize() {
    this.ngOnChanges();
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges() {
    this.setEnvironment();
    this.setTransition(this.transitionDuration);
    this.setBody();
    this.setScales();
    this.setLine();
    this.setData();
    this.setBrush();
    this.setAxis();
    this.filterUpdate();
    this.setCSS();
  }

  constructBody() {
    this.svg = d3.select(this._element.nativeElement).append('svg');
    this.base = this.svg.append('g');
    this.axisX = this.base.append('g').attr('class', 'axis axis--x');
    this.axisY = this.base.append('g').attr('class', 'axis axis--y');
    this.lineGroup = this.base.append('g').attr('class', 'lines');
    this.brushArea = this.base.append('g').attr('class', 'brush');
  }

  setEnvironment() {
    this.margin = { top: 20, right: 35, bottom: 80, left: 35 };
    const width = this._element.nativeElement.clientWidth - this.margin.left - this.margin.right;
    const height = this._element.nativeElement.clientHeight - this.margin.top - this.margin.bottom;
    this.width = width > 0 ? width : 0;
    this.height = height > 0 ? height : 0;
    this.base.attr('transform', `translate(${this.margin.left},10)`);
  }

  setTransition(duration) {
    this.transition = d3.transition().duration(duration);
  }

  setScales() {
    this.scaleX = d3.scalePoint().rangeRound([0, this.width]).domain(this.domain.keys).padding(0.7).align(0.5);

    this.scaleY = d3
      .scaleLinear()
      .rangeRound([this.height, 0])
      .domain([0, d3.max(this.data, (d) => (this.scale === 'percentage' ? d.percentage : d.value)) || 0]);
  }

  setBody() {
    this.svg
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);
  }

  setLine() {
    this.line = d3
      .area()
      .x((d: any, i: any) => this.scaleX(d.key))
      .y1((d: any) => this.scaleY(this.scale === 'percentage' ? d.percentage : d.value))
      .y0((d: any) => this.scaleY(0));
  }

  setBrush() {
    this.brush = d3
      .brushX()
      .on('brush', (event, d) => {
        const sel = d3.brushSelection(this.brushArea.node());
        this.lineSelection(sel);
      })
      .on('end', (event, d) => {
        const sel = d3.brushSelection(this.brushArea.node());
        this.lineSelection(sel);
        this.callFilter();
      });
    this.brushArea.call(this.brush);
  }

  setData() {
    // JOIN new data
    this.lines = this.lineGroup.selectAll('.area').data([this.data]);

    this.dots = this.lineGroup.selectAll('.dot').data(this.data);

    // Exit old elemets not present in new data
    this.lines.exit().transition(this.transition).remove();

    this.dots.exit().transition(this.transition).remove();

    // Update existing elements
    this.lines.transition(this.transition).attr('d', this.line);

    this.dots
      .transition(this.transition)
      .attr('cx', (d) => this.scaleX(d.key))
      .attr('cy', (d) => this.scaleY(this.scale === 'percentage' ? d.percentage : d.value));

    // Enter new elements
    this.lines
      .enter()
      .append('path')
      .attr('class', 'area')
      .attr('d', (d) => {
        const arr = [];
        for (const i in d) {
          arr[i] = {};
          arr[i]['key'] = d[i].key;
          arr[i]['value'] = 0;
          arr[i]['percentage'] = 0;
        }
        return this.line(arr);
      })
      .transition(this.transition)
      .attr('d', this.line);

    this.dots
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('r', 3.5)
      .attr('cx', (d) => this.scaleX(d.key))
      .attr('cy', this.height)
      .transition(this.transition)
      .attr('cy', (d) => this.scaleY(this.scale === 'percentage' ? d.percentage : d.value));
  }

  setAxis() {
    /* TODO: Fix this to work properly with all kinds of domainLabels */
    const tickPadding = this.domain.keys.length > 11 ? 0 : 15;
    const __this = this; /* this is needed to call global variable in local function */

    const axisX = d3
      .axisBottom(this.scaleX)
      .tickFormat((d: any) => this.domain.labels[d])
      .tickPadding(tickPadding)
      .tickSize(0);

    const axisY = d3
      .axisLeft(this.scaleY)
      .ticks(4)
      .tickFormat((d: any) => {
        if (this.scale !== 'percentage' && d % 1 === 0) {
          return d;
        } else if (this.scale !== 'percentage') {
          return '';
        } else {
          return d3.format(',.1%')(d);
        }
      })
      .tickPadding(5)
      .tickSize(0);

    this.axisX
      .attr('transform', function (d) {
        if (!this.attributes.getNamedItem('transform')) {
          return `translate(0,${__this.height + 15})`;
        } else {
          return this.attributes.getNamedItem('transform').value;
        }
      })
      .transition(this.transition)
      .call(axisX)
      .attr('transform', `translate(0,${this.height + 15})`);

    this.axisY.transition(this.transition).call(axisY);

    // TODO: Better solution. Now if tons of lines --> Let's just rotate texts
    if (this.domain.keys.length > 11) {
      this.axisX
        .selectAll('text')
        .attr('y', 0)
        .attr('transform', 'rotate(-90)')
        .attr('x', -9)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'end');
    }
  }

  lineSelection(sel) {
    this.lineGroup
      .selectAll('.dot')
      .classed('included', (d) => {
        const dot = this.scaleX(d.key) + this.scaleX.bandwidth(d.key) / 2;
        return sel && sel[0] <= dot && sel[1] >= dot;
      })
      .classed('excluded', (d) => {
        const dot = this.scaleX(d.key) + this.scaleX.bandwidth(d.key) / 2;
        return (sel && sel[0] > dot) || (sel && sel[1] < dot);
      })
      .classed(
        'default',
        (d) =>
          // const dot = this.scaleX(d.key) + ( this.scaleX.bandwidth(d.key) / 2 );
          !sel,
      );
  }

  filterUpdate() {
    // Updating selections
    this.lineGroup
      .selectAll('.dot')
      .classed(
        'included',
        (d) => this.filterInput && this.filterInput.length > 0 && this.filterInput.indexOf(d.key) >= 0,
      )
      .classed(
        'excluded',
        (d) => this.filterInput && this.filterInput.length > 0 && this.filterInput.indexOf(d.key) < 0,
      )
      .classed('default', (d) => !this.filterInput || this.filterInput.length < 1);
    // Removing brush if filter is removed somewhere else
    if (!this.filterInput && d3.brushSelection(this.brushArea.node())) {
      this.brushArea.call(this.brush.move, null);
    }
  }

  callFilter() {
    this.filter = [];
    const filter = { key: this.domain.key, values: this.domain.keys, filter: [] as string[] };
    this.lineGroup.selectAll('.included').each((d) => {
      filter.filter.push(d.key);
    });
    this.filter.push(filter);
    this.cf.filter(this.filter);
  }

  setCSS() {
    const css = `
      .area {
        fill: #04b0e9;
      }

      .dot {
        stroke: #04b0e9;
        fill: #fff;
        stroke-width: 1.5px;
      }

      .dot.included {
        stroke: black;
      }
      `;

    this.css = this.svg.selectAll('style').data((d) => [d]);

    this.css.exit().remove();

    this.css.select('style').text(css);

    this.css.enter().append('defs').append('style').property('type', 'text/css').text(css);
  }
}

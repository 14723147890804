import { Component, ChangeDetectionStrategy, OnDestroy, Inject, OnInit } from '@angular/core';

import { Observable, combineLatest, Subject, scan } from 'rxjs';
import { switchMap, map, startWith, withLatestFrom, tap, delay } from 'rxjs/operators';

import { Store, Select } from '@ngxs/store';

import { QUILL_CONFIG } from 'ngx-quill-wrapper';
import Quill, * as QuillInstance from 'quill';

import { AuthState } from '@shared/states/auth.state';
import { SIDENAV_DATA, SidenavRef } from '@shared/modules/sidenav/models/sidenav.models';
import {
  HelpItemSubject,
  HelpCenterItemData,
  HelpCenterItemStatistics,
  HelpCenterLanguage,
  HelpCenterMode,
  HelpCenterToolTipMaxChar,
} from '@shared/modules/help-center/help-center.models';
import { HelpCenterState } from '@shared/modules/help-center/state/help-center.state';
import { assertStoreData } from '@shared/utilities/store.utilities';
import {
  GetHelpItemData,
  GetHelpItemStatistics,
  UpdateHelpCenterMode,
  UpdateHelpItemData,
  IncrementHelpItemStats,
} from '@shared/modules/help-center/state/help-center.actions';
import { ActionsState } from '@shared/states/actions.state';
import { assertString } from '@shared/utilities/string.utilities';
import { shareRef, getLastValue } from '@shared/operators/share-ref.operator';
import { parseHelpCenterArticle } from '@shared/modules/help-center/help-center.utilities';

const sizes = ['12px', '14px', '18px', '24px'];

@Component({
  templateUrl: './help-sidenav.component.html',
  styleUrls: ['./help-sidenav.component.scss', '../help-article/help-article.component.scss'],
  providers: [
    {
      provide: QUILL_CONFIG,
      useValue: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'code-block'],
            [{ align: [] }, { indent: '-1' }, { indent: '+1' }, { header: 1 }, { header: 2 }],
            [
              { size: sizes },
              {
                color: [
                  '#fff',
                  '#dae2e5',
                  '#93a0ab',
                  '#60717f',
                  '#495c6c',
                  '#112539',
                  '#0083ff',

                  '#cc9256',
                  '#b39005',
                  '#ef5d65',
                  '#e20046',
                  '#f00',
                  '#18b53a',
                  '#9a2cde',
                ],
              },
            ],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['image', 'video', 'link'],
          ],
        },
      },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpCenterSidenav implements OnInit, OnDestroy {
  @Select(AuthState.isZefAdmin)
  readonly isAdmin$!: Observable<boolean>;

  @Select(HelpCenterState.edit)
  readonly edit$!: Observable<boolean>;

  @Select(HelpCenterState.preview)
  readonly preview$!: Observable<boolean>;

  @Select(HelpCenterState.read)
  readonly read$!: Observable<boolean>;

  @Select(HelpCenterState.language)
  readonly lang$!: Observable<HelpCenterLanguage>;

  readonly dataUpdate$ = new Subject<Partial<HelpCenterItemData | undefined>>();

  readonly dataUpdates$ = this.dataUpdate$.pipe(
    withLatestFrom(this.lang$),
    scan((data, [update, lang]) => {
      if (!update) {
        return undefined;
      }

      if (!data[lang]) {
        data[lang] = {};
      }

      data[lang] = {
        ...data[lang],
        ...update,
      };

      return data;
    }, {} as Partial<Record<HelpCenterLanguage, Partial<HelpCenterItemData>>>),
    startWith(undefined),
    shareRef(),
  );

  readonly data$: Observable<HelpCenterItemData> = combineLatest([
    this.lang$.pipe(
      switchMap((lang) =>
        assertStoreData(
          this.store,
          HelpCenterState.data(this.subject, lang),
          new GetHelpItemData(this.subject, lang),
          (data) => data?.title != null,
        ),
      ),
    ),
    combineLatest([this.dataUpdates$, this.lang$]).pipe(map(([data, lang]) => data?.[lang])),
  ]).pipe(
    map(([data, update]) => ({
      ...data,
      ...(update || {}),
    })),
    map((data) => ({
      ...data,
      parsedArticle: parseHelpCenterArticle(data.article),
    })),
    shareRef(),
  );

  readonly title$: Observable<string> = this.data$.pipe(map((data) => data.title || ''));

  readonly externalLink$: Observable<string> = this.data$.pipe(map((data) => data.externalLink || ''));

  readonly stats$: Observable<{ current: HelpCenterItemStatistics; total: HelpCenterItemStatistics }> = combineLatest([
    this.lang$,
    assertStoreData(this.store, HelpCenterState.stats(this.subject), new GetHelpItemStatistics(this.subject)),
  ]).pipe(
    map(([lang, stats]) => ({
      current: {
        hovers: stats[lang]?.hovers || 0,
        clicks: stats[lang]?.clicks || 0,
        externalClicks: stats[lang]?.externalClicks || 0,
      },
      total: Object.values(stats).reduce(
        (total, current) => ({
          hovers: total.hovers + (current.hovers || 0),
          clicks: total.clicks + (current.clicks || 0),
          externalClicks: total.externalClicks + (current.externalClicks || 0),
        }),
        {
          hovers: 0,
          clicks: 0,
          externalClicks: 0,
        },
      ),
    })),
    shareRef(),
  );

  readonly loading$ = this.store.select(
    ActionsState.whileAction([GetHelpItemData, GetHelpItemStatistics], { subject: this.subject }),
  );

  readonly saving$ = this.store.select(ActionsState.whileAction(UpdateHelpItemData, { subject: this.subject }));

  readonly languages: HelpCenterLanguage[] = ['en', 'fi'];

  readonly modes = HelpCenterMode;

  readonly maxChars = HelpCenterToolTipMaxChar;

  readonly incremented: `${keyof HelpCenterItemStatistics}-${HelpCenterLanguage}`[] = [];

  readonly quickTipError$: Observable<boolean> = this.data$.pipe(
    map(({ quickTip }) => assertString(quickTip).length > this.maxChars),
    shareRef(),
  );

  constructor(
    private store: Store,
    private sn: SidenavRef,
    @Inject(SIDENAV_DATA)
    private subject: HelpItemSubject,
  ) {
    const Size = QuillInstance.import('attributors/style/size');
    Size.whitelist = sizes;
    QuillInstance.register(Size, true);
  }

  ngOnInit(): void {
    this.increment('clicks');
  }

  ngOnDestroy(): void {
    this.store.dispatch(new UpdateHelpCenterMode({ language: undefined }));
  }

  updateMode(mode: { language?: HelpCenterLanguage; mode?: HelpCenterMode }): void {
    if (mode.language) {
      this.increment('clicks');
    }

    this.store.dispatch(new UpdateHelpCenterMode(mode));
  }

  increment(property: keyof HelpCenterItemStatistics): void {
    const lang = getLastValue(this.lang$);
    const key: `${keyof HelpCenterItemStatistics}-${HelpCenterLanguage}` = `${property}-${lang}`;

    if (!this.incremented.includes(key)) {
      this.incremented.push(key);
      this.store.dispatch(new IncrementHelpItemStats(this.subject, property, lang));
    }
  }

  onUpdate(): void {
    this.store
      .dispatch(new UpdateHelpItemData(this.subject, getLastValue(this.dataUpdates$, {})))
      .pipe(
        tap(() => this.dataUpdate$.next(undefined)),
        delay(1),
      )
      .subscribe(() => this.sn.close());
  }

  onEditorCreated(quill: Quill): void {
    quill.focus();

    setTimeout(() => quill.format('size', '14px'));
  }

  updateData(update: Partial<HelpCenterItemData>): void {
    this.dataUpdate$.next(update);
  }
}

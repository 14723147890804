import { Component, Input } from '@angular/core';

@Component({
  selector: 'zef-multi-select-category',
  templateUrl: './multi-select-category.component.html',
  styleUrls: ['./multi-select-category.component.scss'],
})
export class MultiSelectCategory {
  @Input()
  category?: string;
}

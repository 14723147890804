/**
 * Utility / helper related data models.
 *
 * @unstable
 */

import { Events } from '@shared/enums/events.enum';

export interface GridData {
  col?: number;
  row?: number;

  sizex: number;
  sizey: number;

  minRows?: number;
  maxRows?: number;
  minCols?: number;
  maxCols?: number;
}

export class SortedList<T extends { $key: string }> {
  private array: T[] = [];

  constructor(object: { [key: string]: T }) {
    for (const key in object) {
      object[key].$key = key;

      this.array.push(object[key]);
    }
  }

  getArray(key: string) {
    return this.array.sort((a, b) => {
      if (a[key] > b[key]) {
        return 1;
      }

      if (a[key] < b[key]) {
        return -1;
      }

      return 0;
    });
  }
}

export interface NameIconMap<T = string> {
  id: T;
  name: string;
  icon: string;
}

export class KeyValuePair {
  key: string = '';
  value: any = null;

  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }
}

export class InputSelection {
  direction: string = '';
  start: number = 0;
  end: number = 0;
  text: string = '';
}

export class MessageEvent {
  constructor(public type: Events, public data: any) {}
}

export interface ActionValue<T = any> {
  action: string;
  value: T;
}

export type SourceType =
  | 'survey'
  | 'user'
  | 'integration'
  | 'api'
  | 'team'
  | 'surveyDesign'
  | 'list'
  | 'import'
  | 'copy-paste';

export interface TopLeft<T = number> {
  top: T;
  left: T;
}

export interface XY {
  x: number;
  y: number;
}

export interface Rect<T = number> extends TopLeft<T> {
  width: T;
  height: T;
}

export interface Area {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

export enum AreaMode {
  XY = 'xy',
  XX = 'xx',
  YY = 'yy',
}

export interface TimeValue<T = number> {
  hour: T;
  minute: T;
}

import * as d3 from 'd3';

import { Directive, ElementRef, OnChanges, Input, HostListener, SimpleChanges, SimpleChange } from '@angular/core';

import { ChartDistribution, ChartDomain } from '@shared/models/report.model';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Colors } from '@report/shared/enums/colors.enum';
import { shortenText } from '@shared/utilities/canvas.utilities';

export interface CanvasContext {
  context: CanvasRenderingContext2D[];
  data: ChartDistribution[];
}

/**
 * This is a donut chart.
 */
@Directive({
  selector: '[multiDonutChart]',
})
export class MultiDonutChart implements OnChanges {
  @Input() data: ChartDistribution[] = [];
  @Input() domain: ChartDomain = {} as ChartDomain;
  @Input() stats: any;
  @Input() scale: any;
  @Input() filterInput: any;
  @Input() transitionDuration: number = 0;
  @Input() update: Date = new Date();
  @Input() filtering: boolean = false;
  @Input() anonymityLock: boolean = false;
  @Input() title: string = '';
  @Input() totalAnswers: number = 0;
  @Input() selectionExists: boolean = false;
  @Input() filtersDemo: boolean = false;
  @Input() touchDevice: boolean = false;

  private width: number = 0;
  private height: number = 0;
  private margin: any = { top: 20, right: 20, bottom: 20, left: 20 };
  private radius: number = 0;
  private fontSize: number = 0;
  private unit: number = 0;

  private responses: number = 0;

  private context: CanvasContext = {} as CanvasContext;

  private filter: any;
  private selections: any = new Set();

  // d3 elements
  private base: any;
  private canvas: any;
  private chartTitle: any;
  private enterChartTitle: any;
  private colorScale: any;
  private pie: any;
  private tooltip: any;

  @HostListener('window:resize') resize() {
    this.updateChart(null);
  }

  constructor(private _element: ElementRef, private cf: Crossfilter) {
    this.constructBody();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.domain) {
      this.context = { context: [], data: [] };
    }

    if (
      changes.data ||
      changes.domain ||
      changes.scale ||
      changes.filterInput ||
      changes.update ||
      changes.filtering ||
      changes.anonymityLock ||
      changes.title ||
      changes.stats ||
      changes.filtersDemo
    ) {
      this.updateChart(changes.data);
    }
  }

  updateChart(dataChanges: SimpleChange | null) {
    this.setEnvironment();
    this.setScales();
    this.setTitle();
    this.setCanvas(dataChanges);
  }

  constructBody() {
    this.base = d3
      .select(this._element.nativeElement)
      .append('div')
      .attr('class', 'donut-chart')
      .style('text-align', 'center');
  }

  setEnvironment() {
    this.fontSize = parseFloat(window.getComputedStyle(this._element.nativeElement).fontSize);
    this.unit = (10 / 14) * this.fontSize;

    this.margin = {
      top: 2 * this.unit,
      right: 2 * this.unit,
      bottom: 2 * this.unit,
      left: 2 * this.unit,
    };

    // Algorithm to calculate optimal pie size
    // More from: http://stackoverflow.com/a/870967
    let sqW = 1;
    let sqH = 1;
    let maxSq: number = 1;
    const aW = this._element.nativeElement.clientWidth;
    const aH = this._element.nativeElement.clientHeight - 3 * this.unit; // 30 is pixels needed for the chart title
    const n: number = this.data.length;

    let cW;
    let cH;

    while (n > maxSq) {
      cW = aW / (sqH + 1);
      cH = aH / (sqW + 1);
      if (cW >= cH) {
        sqH = sqH + 1;
      } else {
        sqW = sqW + 1;
      }
      maxSq = sqH * sqW;
    }

    const size = Math.min(aW / sqH, aH / sqW);

    this.width = size - this.margin.left - this.margin.right > 0 ? size - this.margin.left - this.margin.right : 0;
    this.height = size - this.margin.top - this.margin.bottom > 0 ? size - this.margin.top - this.margin.bottom : 0;

    this.radius = Math.min(this.width, this.height) / 2;
  }

  setScales() {
    this.pie = d3
      .pie()
      .sort(null)
      .value((d: any) => d);

    this.colorScale = d3
      .scaleOrdinal()
      .range([
        this.selectionExists ? Colors.SELECTED : this.filtersDemo ? Colors.UNSELECTED : Colors.DEFAULT,
        Colors.UNSELECTED,
      ]);

    this.responses = this.stats && this.stats['responses'] != null ? this.stats['responses'] : this.totalAnswers;
  }

  setCanvas(dataChanges: SimpleChange | null) {
    const __this = this;
    const hoverFunction = function (event, d) {
      if (!__this.touchDevice || !__this.filtering || __this.anonymityLock) {
        __this.selectForHover(event, d);
      }
    };
    const mouseOutFunction = (event, d) => {
      this.setTooltip(d3.pointer(event, this._element.nativeElement));
    };
    const clickFunction = function (event, d) {
      __this.selectFromArcs(d);
      __this.callFilter();
    };

    const drawContent = function (d, i) {
      const context = d3.select(this).node().getContext('2d');

      if (dataChanges && !dataChanges.firstChange && __this.transitionDuration > 0) {
        const oldPercentage =
          __this.context && __this.context.data && __this.context.data[i] && __this.context.data[i].percentage
            ? __this.context.data[i].percentage
            : 0;
        const interpolator = d3.interpolateNumber(oldPercentage, d.percentage);
        const ease = d3.easeCubic;
        const data = Object.assign({}, d);

        const t = d3.timer((elapsed) => {
          const step = elapsed / __this.transitionDuration;

          if (step >= 1) {
            data.percentage = interpolator(ease(1));
            t.stop();
          } else {
            data.percentage = interpolator(ease(step));
          }

          __this.setChart(context, data);
        });
      } else {
        __this.setChart(context, d);
      }

      __this.context.context[i] = context;
      __this.context.data[i] = d;
    };

    this.canvas = this.base.selectAll('.donut-chart-canvas').data(this.data);

    this.canvas.exit().remove();

    this.canvas
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .each(drawContent);

    this.canvas
      .enter()
      .append('canvas')
      .attr('class', 'donut-chart-canvas')
      .style('position', 'relative')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .on('mousemove', hoverFunction)
      .on('mouseout', mouseOutFunction)
      .on('click', clickFunction)
      .each(drawContent);
  }

  setTitle() {
    const __this = this;
    const wrap = function wrapText(text = '', elem, width, padding) {
      const self = d3.select(elem);
      self.text(text);

      const textLength = self.node().offsetWidth;

      if (textLength > width - 2 * padding && text && text.length > 0) {
        const availableSpace = (width - 2 * padding) / (textLength / text.length);
        const firstPart = text.slice(0, Math.floor(availableSpace / 2 - text.length - 1));
        const secondPart = text.slice(-Math.floor(availableSpace / 2 - 1));

        self.text(firstPart + ' ... ' + secondPart);
      }
    };

    this.chartTitle = this.base.selectAll('.chart-title').data(this.title ? [this.title] : ['']);

    this.chartTitle.exit().remove();

    this.chartTitle.style('line-height', 1.5 * this.unit + 'px');

    this.chartTitle
      .select('.title-text')
      .style('font-size', 1.2 * this.unit + 'px')
      .each(function (d) {
        wrap(__this.title, this, __this._element.nativeElement.clientWidth, 3 * this.unit);
      });

    this.chartTitle
      .select('.responses-icon')
      .style('font-size', 1.4 * this.unit + 'px')
      .style('padding', `0 ${0.4 * this.unit}px 0 ${(__this.title ? 1.2 : 0) * this.unit}px`);

    this.chartTitle
      .select('.number-responses')
      .style('font-size', this.unit + 'px')
      .transition(d3.transition().duration(this.transitionDuration))
      .tween('text', function (d) {
        const that = d3.select(this);

        const start = that.text() ? d3.select(this).text() : 0;

        const i = d3.interpolate(start, __this.responses as any);
        const prec = (__this.responses + '').split('.');
        const round = prec.length > 1 ? Math.pow(10, prec[1].length) : 1;

        return function (t) {
          that.text(Math.round(i(t) * round) / round);
        };
      });

    this.enterChartTitle = this.chartTitle
      .enter()
      .append('div')
      .attr('class', 'chart-title')
      .style('line-height', 1.5 * this.unit + 'px')
      .style('position', 'absolute')
      .style('left', '50%')
      .style('width', 'auto')
      .style('-webkit-transform', 'translateX(-50%)')
      .style('-moz-transform', 'translateX(-50%)')
      .style('-ms-transform', 'translateX(-50%)')
      .style('-o-transform', 'translateX(-50%)')
      .style('transform', 'translateX(-50%)');

    this.enterChartTitle
      .append('span')
      .attr('class', 'title-text')
      .style('white-space', 'nowrap')
      .style('font-size', 1.2 * this.unit + 'px')
      .style('vertical-align', 'middle')
      .each(function (d) {
        wrap(__this.title, this, __this._element.nativeElement.clientWidth, 30);
      });

    this.enterChartTitle
      .append('span')
      .attr('class', 'responses-icon')
      .style('white-space', 'nowrap')
      .style('font-size', 1.4 * this.unit + 'px')
      .style('font-family', 'zef-icons-full')
      .style('vertical-align', 'middle')
      .style('padding', `0 ${0.4 * this.unit}px 0 ${(__this.title ? 1.2 : 0) * this.unit}px`)
      .text('contact');

    this.enterChartTitle
      .append('span')
      .attr('class', 'number-responses')
      .style('white-space', 'nowrap')
      .style('font-size', this.unit + 'px')
      .style('vertical-align', 'middle')
      .text(this.responses);
  }

  setChart(context, data, highlight: any[] = []) {
    context.clearRect(
      0,
      0,
      this.width + this.margin.right + this.margin.left,
      this.height + this.margin.top + this.margin.bottom,
    );
    this.selections = new Set(this.filterInput ? this.filterInput : null);

    const arc = d3
      .arc()
      .outerRadius(this.radius - 10)
      .innerRadius(this.radius / 1.667)
      .padAngle(0.02)
      .context(context);

    const arcExtended = d3
      .arc()
      .outerRadius(this.radius)
      .innerRadius(this.radius / 1.667)
      .padAngle(0.02)
      .context(context);

    const arcs = this.pie([data.percentage, 1 - data.percentage]);

    context.save();
    context.translate(this.margin.left + this.width / 2, this.margin.top + this.height / 2);

    let inFilter;
    let inHighlight;

    if (this.filterInput && this.filterInput.length > 0) {
      inFilter = this.filterInput.indexOf(data.key) > -1;

      if (!inFilter) {
        context.globalAlpha = 0.2;
      } else {
        context.globalAlpha = 1;
      }
    }

    if (highlight.length > 0) {
      inHighlight = highlight && highlight.indexOf(data.key) > -1;
    }

    arcs.forEach((d: any, i) => {
      context.beginPath();
      if (inFilter || inHighlight) {
        arcExtended(d);
      } else {
        arc(d);
      }
      context.fillStyle = this.colorScale(i);

      if (inFilter && i === 0) {
        context.fillStyle = Colors.FILTER;
      }

      context.fill();

      if (inHighlight && i === 0) {
        context.lineWidth = 2;
        context.strokeStyle = Colors.HIGHLIGHT;
        context.stroke();
      }
    });

    context.font = 16 / 14 + 'em Open Sans';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = Colors.TEXT;

    const percentage = data.percentage && this.radius > 20 ? (data.percentage * 100).toFixed(1) + '%' : '';
    context.fillText(percentage, 0, 0);

    context.restore();

    context.font = 12 / 14 + 'em Open Sans';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillStyle = Colors.TEXT;

    context.save();
    context.font =
      (this.domain.labels[data.key] === '☑' || this.domain.labels[data.key] === '☐' ? 20 : 12) / 14 + 'em Open Sans';
    const label = shortenText(
      context,
      this.domain.labels[data.key],
      this.margin.left + this.width + this.margin.right,
      20,
    );
    context.fillText(label, this.margin.left + this.width / 2, this.margin.top + this.height + this.margin.bottom / 2);
    context.restore();
  }

  setTooltip(position, data: any[] = []) {
    const __this = this;

    this.tooltip = d3
      .select(this._element.nativeElement)
      .selectAll('.item-tooltip')
      .data(data.length > 0 ? data : []);

    this.tooltip.exit().remove();

    this.tooltip
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    this.tooltip
      .enter()
      .append('div')
      .attr('class', 'item-tooltip')
      .html(
        (d) => `
            <div class="question">${this.domain.labels[d.key]}</div>
            <div class="stats">
              <span class="icon">contact</span> ${d.value} (${(d.percentage * 100).toFixed(1)}%)
            </div>
          `,
      )
      .style('transform', function (d) {
        return `translate(${
          position[0] - this.getBoundingClientRect().width / 2
        }px,${position[1] - (this.getBoundingClientRect().height + 15)}px)`;
      });

    // adding hovering effect
    this.base.selectAll('.donut-chart-canvas').each(function (d, i) {
      const highlight = data.map((item) => item.key);

      __this.setChart(__this.context.context[i], d, highlight);
    });

    if (data.length > 0 && this.filtering && !this.anonymityLock) {
      this.base.selectAll('.donut-chart-canvas').style('cursor', 'pointer');
    } else {
      this.base.selectAll('.donut-chart-canvas').style('cursor', null);
    }
  }

  callFilter() {
    if (this.filtering && !this.anonymityLock) {
      this.filter = [];
      const filter = { key: this.domain.key, values: this.domain.keys, filter: Array.from(this.selections) };

      this.filter.push(filter);
      if (JSON.stringify(this.filter[0].filter) !== JSON.stringify(this.filterInput)) {
        this.cf.filter(this.filter);
      }
    }
  }

  // Helpers
  selectForHover(event, data) {
    if (data) {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild), [data]);
    } else {
      this.setTooltip(d3.pointer(event, this._element.nativeElement.firstElementChild));
    }
  }

  selectFromArcs(data) {
    if (this.selections.has(data.key)) {
      this.selections.delete(data.key);
    } else {
      this.selections.add(data.key);
    }
  }
}

<div
  *ngIf="nb.currentLayer$ | async as layer"
  class="nav z-fx-sc zef-border-bottom"
  [ngClass]="'zef-bgcolor-' + layer.color"
>
  <ng-container *ngIf="!website">
    <div [class.invert]="layer.invert">
      <a nav-bar-button *ngIf="!layer.icon; else layer.icon" class="zef-border-right" routerLink="/">
        <zeffi-icon [invert]="layer.invert" class="zef-icon-large"></zeffi-icon>
      </a>
    </div>
  </ng-container>

  <div class="z-fx-flex">
    <ng-container *ngTemplateOutlet="layer.content"></ng-container>
  </div>

  <ng-container *ngIf="!website">
    <nav-bar-button
      *ngIf="!layer.avatar; else layer.avatar"
      class="zef-border-left"
      (click)="menuOptions = {}"
      [matMenuTriggerFor]="userMenu"
    >
      <zef-avatar
        size="small"
        [emblem]="true"
        [identity]="identity$ | async"
        [notifications]="(invites$ | async)?.length"
      ></zef-avatar>

      <mat-menu #userMenu xPosition="before" yPosition="below">
        <nav-bar-menu *matMenuContent [menuOptions]="menuOptions"></nav-bar-menu>
      </mat-menu>
    </nav-bar-button>
  </ng-container>
</div>

/*
 * Bypasses angular security checker for the given html string.
 *
 * @unstable
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtml implements PipeTransform {
  constructor(readonly sanitizer: DomSanitizer) {}

  public transform(styles: string | null, args?: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(styles);
  }
}

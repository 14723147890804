import { ReplaySubject, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Component, TemplateRef, Input, ViewChild, Output } from '@angular/core';

import { TextOverflow } from '@shared/directives/text-overflow.directive';

@Component({
  selector: 'select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.scss'],
})
export class SelectItem<T = any> {
  private textOverflowDirective$ = new ReplaySubject<TextOverflow | undefined>();

  @Input()
  prefix?: TemplateRef<T>;

  @Input()
  label?: TemplateRef<T>;

  @Input()
  value?: T;

  @Input()
  placeholder?: boolean;

  @Input()
  valuePrefixOnly?: boolean;

  @Input()
  isMultiSelect?: boolean;

  @Input()
  radioType?: boolean;

  @ViewChild(TextOverflow)
  set textOverflowDirective(textOverflowDirective: TextOverflow) {
    this.textOverflowDirective$.next(textOverflowDirective);
  }

  @Output()
  readonly selectItemOverflow = this.textOverflowDirective$.pipe(
    distinctUntilChanged(),
    switchMap((textOverflow) => textOverflow?.textOverflow || of(false)),
  );
}

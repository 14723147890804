<button
  class="z-basic-button z-texts-answer"
  [class.ghost-cta]="ghost$ | async"
  [class.loading]="loading$ | async"
  [ngStyle]="styles | async"
  [style.backgroundImage]="backgroundImage | async"
  (click)="onClick($event)"
>
  <span>
    <ng-content></ng-content>
  </span>
</button>

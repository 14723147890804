import { Directive, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';

import { UnsplashApi } from '@shared/services/unsplash-api.service';

@Directive({
  selector: '[unsplashAttribute]',
})
export class UnsplashAttribute implements OnChanges {
  private readonly indicator = '-unsplash-';

  private checked: string[] = [];

  @Input()
  src?: string;

  @Input()
  unsplashAttribute?: string;

  constructor(private ua: UnsplashApi, private el: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.src || changes.unsplashAttribute) {
      this.checkIfAttributeNeeded();
    }

    if (changes.src && this.el.nativeElement instanceof HTMLImageElement) {
      this.el.nativeElement.src = this.src;
    }
  }

  private checkIfAttributeNeeded(): void {
    const src = this.unsplashAttribute || this.src || '';

    if (this.checked.includes(src)) {
      return;
    }

    this.checked.push(src);

    if (src.includes('/external/') && src.includes(this.indicator)) {
      const id = src.substr(src.indexOf(this.indicator) + this.indicator.length);

      if (id) {
        this.ua.attribute(id).subscribe();
      }
    }
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { searchQueryData } from '@shared/utilities/string.utilities';

@Component({
  selector: 'zef-text-highlight',
  templateUrl: './text-highlight.component.html',
  styleUrls: ['./text-highlight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextHighlight implements OnChanges {
  parts: { highlight: boolean; text: string }[] = [];

  @Input()
  text: string = '';

  @Input()
  search: string = '';

  @Input()
  color: string = '#0083ff';

  ngOnChanges(changes: SimpleChanges) {
    this.parts = this.parseTextParts();
  }

  private parseTextParts(): { highlight: boolean; text: string }[] {
    const search = this.search?.trim();
    const text = this.text?.trim();

    const parts = [];

    const searchData = searchQueryData(text, search);

    if (searchData.length) {
      let start = 0;

      searchData.forEach((data) => {
        const before = text.substring(start, data.idx);
        const match = text.substr(data.idx, data.part.length);

        if (before) {
          parts.push({ highlight: false, text: before });
        }

        if (match) {
          parts.push({ highlight: true, text: match });
        }

        start = data.idx + data.part.length;
      });

      const rest = text.substr(start);

      if (rest) {
        parts.push({ highlight: false, text: rest });
      }
    } else {
      parts.push({ highlight: false, text });
    }

    return parts;
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FolderModel } from '@shared/models/survey.model';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { AccountState } from '@shared/states/account.state';
import { Observable, of } from 'rxjs';
import { firebaseSafe } from '@shared/utilities/object.utilities';
import { catchError } from 'rxjs/operators';
import { DatabaseWrapper } from './database-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class FoldersApi {
  constructor(private db: DatabaseWrapper, private store: Store) {}

  private get teamKey() {
    return this.store.selectSnapshot(AccountState.teamKey);
  }

  getFolders(): Observable<FolderModel[]> {
    return !this.teamKey
      ? of([])
      : this.db
          .list(`/folders/${this.teamKey}`)
          .snapshotChanges()
          .pipe(
            mapListKeys<FolderModel>(),
            catchError((error) => of([])),
          );
  }

  createFolder(data: Partial<FolderModel>) {
    return this.db.list(`/folders/${this.teamKey}`).push(firebaseSafe(data));
  }

  updateFolder(folderKey: string, data: Partial<FolderModel>) {
    return this.db.object(`/folders/${this.teamKey}/${folderKey}`).update(firebaseSafe(data));
  }

  deleteFolder(folderKey: string) {
    return this.db.object(`/folders/${this.teamKey}/${folderKey}`).remove();
  }

  updateData(data: { [folderKey: string]: Partial<FolderModel> }) {
    return this.db.object(`/folders/${this.teamKey}`).update(data);
  }
}

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { ReportManager } from '@shared/services/report-manager.service';

import { ChartsManager } from '@report/shared/services/charts-manager.service';

import { ReportData } from '@shared/models/survey.model';
import { DimensionDataItem, Exports, PreparedAnswersData, StoredReport } from '@shared/models/report.model';

import { Charts } from '@shared/enums/charts.enum';
import { Questions } from '@shared/enums/questions.enum';
import { AuthState } from '@shared/states/auth.state';
import { AccountState } from '@shared/states/account.state';

import packageJson from '../../../../../package.json';

/**
 * This service will update stored report data with new responses.
 */
@Injectable({
  providedIn: 'root',
})
export class ReportSave {
  private autoSave: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private autoSaveSub: any;
  private autoSaveNeeded: boolean = false;

  private preAutoSaveSub: any;

  private saveDetails: any = {};

  private cfChangeSub: any;
  private cmChangeSub: any;
  private reportDetailsSub: any;

  private version: string = '';

  public autoSaveOn: boolean = false;
  public preAutoSaveOn: boolean = false;
  public saveReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public saveStatus: BehaviorSubject<string> = new BehaviorSubject<string>('saved');

  constructor(private rm: ReportManager, private store: Store) {}

  public disconnectAutoSave() {
    if (this.autoSaveSub) {
      this.autoSaveSub.unsubscribe();
    }

    if (this.cfChangeSub) {
      this.cfChangeSub.unsubscribe();
    }

    if (this.cmChangeSub) {
      this.cmChangeSub.unsubscribe();
    }

    if (this.reportDetailsSub) {
      this.reportDetailsSub.unsubscribe();
    }

    this.autoSave.next(null);

    if (Object.keys(this.saveDetails).length > 0 && this.autoSaveNeeded) {
      this.saveReady.next(false);
      this.saveStatus.next('saving');
      this.save(
        this.saveDetails.cf,
        this.saveDetails.cm,
        this.saveDetails.surveyKey,
        this.saveDetails.reportKey,
        this.saveDetails.reportTitle,
        this.saveDetails.makePublic,
        this.saveDetails.makePrivate,
        this.saveDetails.remove,
        this.saveDetails.publicSettings,
      ).subscribe(() => {
        this.saveReady.next(true);
        this.saveStatus.next('saved');
      });
    }

    this.autoSaveOn = false;
    this.saveDetails = {};
  }

  public disconnectPreAutoSave() {
    this.preAutoSaveOn = false;
    if (this.preAutoSaveSub) {
      this.preAutoSaveSub.unsubscribe();
    }
  }

  public initPreAutoSave(cf: Crossfilter, cm: ChartsManager, surveyKey: string = '') {
    if (!this.autoSaveOn && !this.preAutoSaveOn) {
      this.preAutoSaveOn = true;
      this.preAutoSaveSub = combineLatest(cf.dataChange, cm.gridChange).subscribe(([cfEvent, cmEvent]) => {
        if (
          (cfEvent && cfEvent.indexOf('new answers') === -1 && cfEvent.indexOf('data changes') === -1) ||
          cmEvent != null
        ) {
          this.rm.clearUnsavedChangesReports(surveyKey);
          this.initAutoSave(cf, cm, surveyKey, '');
        }
      });
    }
  }

  public initAutoSave(cf: Crossfilter, cm: ChartsManager, surveyKey: string = '', reportKey: string = '') {
    this.disconnectPreAutoSave();

    this.saveDetails.cf = cf;
    this.saveDetails.cm = cm;
    this.saveDetails.surveyKey = surveyKey;
    this.saveDetails.reportKey = reportKey;
    this.saveDetails.reportTitle = '';
    this.saveDetails.makePublic = false;
    this.saveDetails.makePrivate = false;
    this.saveDetails.remove = 1;
    this.saveDetails.publicSettings = null;

    this.autoSaveOn = true;

    if (!reportKey) {
      const date: Date = new Date();
      this.saveDetails.reportTitle = `Autosaved Report ${date.getUTCDate()}.${date.getUTCMonth() + 1} ${
        date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;

      this.save(
        this.saveDetails.cf,
        this.saveDetails.cm,
        this.saveDetails.surveyKey,
        this.saveDetails.reportKey,
        this.saveDetails.reportTitle,
        this.saveDetails.makePublic,
        this.saveDetails.makePrivate,
        this.saveDetails.remove,
        this.saveDetails.publicSettings,
      ).subscribe((newReport: string) => {
        this.saveDetails.reportKey = newReport;
        this.reportDetailsSub = this.rm.getReportDetails(surveyKey, newReport).subscribe((item: any) => {
          if (item) {
            this.saveDetails.reportTitle = item.title;
            this.saveDetails.makePublic = item.online;
            this.saveDetails.publicSettings = item.publicSettings;
          }
        });
      });
    } else {
      this.reportDetailsSub = this.rm.getReportDetails(surveyKey, reportKey).subscribe((item: any) => {
        if (item) {
          this.saveDetails.makePublic = item.online;
          this.saveDetails.publicSettings = item.publicSettings;
          this.saveDetails.reportTitle = item.title;
        }
      });
    }

    this.cfChangeSub = cf.dataChange.subscribe((change) => {
      this.autoSave.next(change);
      if (change) {
        this.autoSaveNeeded = true;
        this.saveStatus.next('save');
      }
    });

    this.cmChangeSub = cm.gridChange.subscribe((change) => {
      this.autoSave.next(change);
      if (change) {
        this.autoSaveNeeded = true;
        this.saveStatus.next('save');
      }
    });

    this.autoSaveSub = this.autoSave.pipe(debounceTime(15000)).subscribe((action) => {
      if (action != null && this.autoSaveNeeded) {
        this.autoSaveNeeded = false;
        this.saveStatus.next('saving');
        this.save(
          this.saveDetails.cf,
          this.saveDetails.cm,
          this.saveDetails.surveyKey,
          this.saveDetails.reportKey,
          this.saveDetails.reportTitle,
          this.saveDetails.makePublic,
          this.saveDetails.makePrivate,
          this.saveDetails.remove,
          this.saveDetails.publicSettings,
        ).subscribe(() => {
          this.saveStatus.next('saved');
          // Saving done!
        });
      }
    });
  }

  public quickSave() {
    return new Observable<any>((observer) => {
      if (Object.keys(this.saveDetails).length > 0) {
        this.autoSaveNeeded = false;
        this.saveStatus.next('saving');
        this.save(
          this.saveDetails.cf,
          this.saveDetails.cm,
          this.saveDetails.surveyKey,
          this.saveDetails.reportKey,
          this.saveDetails.reportTitle,
          this.saveDetails.makePublic,
          this.saveDetails.makePrivate,
          this.saveDetails.remove,
          this.saveDetails.publicSettings,
        ).subscribe(() => {
          this.saveStatus.next('saved');
          observer.next('saved');
          observer.complete();
        });
      } else {
        observer.next('not saved');
        observer.complete();
      }
    });
  }

  public save(
    cf: Crossfilter,
    cm: ChartsManager,
    surveyKey: string = '',
    reportKey: string = '',
    reportTitle: string = '',
    makePublic: boolean = false,
    makePrivate: boolean = false,
    remove: number = 1,
    publicSettings: { explorable: boolean; live: boolean } | null = null,
  ) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const isAnonymous = this.store.selectSnapshot(AuthState.isAnonymous);
    this.version = packageJson.version;

    if (!isAnonymous && cf && cf.getAnswerData() != null && Object.keys(cf.getAnswerData()).length > 0) {
      this.saveDetails.remove = remove;
      const newReport: ReportData = new ReportData();
      const answerData: PreparedAnswersData = cf.getAnswerData();
      const date: Date = new Date();
      newReport.title = reportTitle
        ? reportTitle
        : `Report ${date.getUTCDate()}.${date.getUTCMonth() + 1} ${
            date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
          }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
      newReport.respondents = answerData && answerData.answerers ? answerData.answerers.length : 0;
      newReport.order = (1 / new Date().valueOf()) * remove;
      newReport.publicSettings = publicSettings;
      newReport.savingOnProgress = true;

      if (cf.getLatestAnswerTime()) {
        newReport.latestAnswerTime = cf.getLatestAnswerTime();
      }

      const storedReport: StoredReport = {
        respondents: Object.assign({}, cf.getAnswerData()),
        dimensions: Object.assign({}, cf.getDimensions()),
        comparison: cf.getComparisonDetails(),
        latestAnswerTime: cf.getLatestAnswerTime(),
        charts: cm.grid
          .filter((item) => !item.isHintCard)
          .map((item) => {
            const newItem: any = Object.assign({}, item);
            if (newItem.data) {
              newItem.data = {
                details: (newItem.data.details || []).map((det) =>
                  det
                    ? {
                        key: det.key,
                        originalType: det.originalType,
                        originalTypeSpecifier: det.originalTypeSpecifier,
                        scale: det.scale,
                      }
                    : {},
                ),
                scales: newItem.data.scales,
              };
            }
            if (newItem.exports) {
              newItem.exports = {} as Exports;
            }
            return newItem;
          }),
        info: { title: newReport.title, appVersion: this.version, teamKey, contactsMigrated: true },
        locales: JSON.parse(JSON.stringify(cf.getLocales())),
        activeLocale: cf.getActiveLocale(),
        trend: cf.getTrendAnalysisStatus(),
        freezeTextTables: cf.getTextFreezingStatus(),
        safeReport: cf.getSafeReportStatus(),
      };
      if (cf.getAnswersParameters()) {
        storedReport['getAnswers'] = cf.getAnswersParameters();
      }
      if (cf.getTimePeriod()) {
        storedReport['timePeriod'] = cf.getTimePeriod();
      }
      if (cf.getMergeSettings()) {
        storedReport['mergedSurveys'] = cf.getMergeSettings();
      }
      if (cf.getAnonymityTreshold()) {
        storedReport['anonymityTreshold'] = cf.getAnonymityTreshold();
      }
      if (publicSettings) {
        storedReport['publicSettings'] = publicSettings;
      }
      // cleaning unnecessary saving of translation strings
      if (storedReport['locales']) {
        for (const survey in storedReport['locales']) {
          if (storedReport['locales'][survey]['strings']) {
            storedReport['locales'][survey]['strings'] = {};
          }
        }
      }

      const storedPublicReport: StoredReport = {} as StoredReport;

      if (makePublic) {
        storedPublicReport['respondents'] = Object.assign({}, cf.getAnswerData());
        storedPublicReport['dimensions'] = Object.assign({}, cf.getDimensions());
        storedPublicReport['comparison'] = cf.getComparisonDetails();
        storedPublicReport['latestAnswerTime'] = cf.getLatestAnswerTime();
        storedPublicReport['charts'] = cm.grid
          .filter((item) => !item.isHintCard)
          .map((item) => {
            const newItem: any = Object.assign({}, item);
            if (newItem.data) {
              newItem.data = {
                details: (newItem.data.details || []).map((det) =>
                  det
                    ? {
                        key: det.key,
                        originalType: det.originalType,
                        originalTypeSpecifier: det.originalTypeSpecifier,
                        scale: det.scale,
                      }
                    : {},
                ),
                scales: newItem.data.scales,
              };
            }
            if (newItem.exports) {
              newItem.exports = {} as Exports;
            }
            return newItem;
          });
        storedPublicReport['info'] = {
          title: newReport.title,
          appVersion: this.version,
          teamKey,
          contactsMigrated: true,
        };
        storedPublicReport['locales'] = JSON.parse(JSON.stringify(cf.getLocales()));
        storedPublicReport['activeLocale'] = cf.getActiveLocale();
        storedPublicReport['trend'] = cf.getTrendAnalysisStatus();
        storedPublicReport['freezeTextTables'] = cf.getTextFreezingStatus();
        storedPublicReport['safeReport'] = cf.getSafeReportStatus();

        if (cf.getAnswersParameters()) {
          storedPublicReport['getAnswers'] = cf.getAnswersParameters();
        }
        if (cf.getTimePeriod()) {
          storedPublicReport['timePeriod'] = cf.getTimePeriod();
        }
        if (cf.getMergeSettings()) {
          storedPublicReport['mergedSurveys'] = cf.getMergeSettings();
        }
        if (cf.getAnonymityTreshold()) {
          storedPublicReport['anonymityTreshold'] = cf.getAnonymityTreshold();
        }
        if (publicSettings) {
          storedPublicReport['publicSettings'] = publicSettings;
        }
        // cleaning unnecessary saving of translation strings
        if (storedPublicReport['locales']) {
          for (const survey in storedPublicReport['locales']) {
            if (storedPublicReport['locales'][survey]['strings']) {
              storedPublicReport['locales'][survey]['strings'] = {};
            }
          }
        }

        const allowPublicComments = this.store.selectSnapshot(AuthState.allowPublicComments);

        // cleaning personal data
        const notAllowedTypes: string[] = [
          ...(!allowPublicComments ? [Questions.FREE_TEXT] : []),
          Questions.INPUT_URL,
          Questions.INPUT_EMAIL,
          Questions.INPUT_PHONE,
          Questions.INPUT_STRING,
          Questions.INPUT_ADDRESS,
          Questions.INPUT_NUMBER,
          Questions.FILE_UPLOAD,
          'contact-property',
          'respondent-field',
        ];

        if (!allowPublicComments) {
          storedPublicReport['respondents']['textLabels'] = {};
          storedPublicReport['respondents']['textAnswers'] = {};
        }

        storedPublicReport['respondents']['textContacts'] = {};

        for (const dim in storedPublicReport['dimensions']) {
          if (
            notAllowedTypes.indexOf(storedPublicReport['dimensions'][dim]['originalType']) >= 0 ||
            storedPublicReport['dimensions'][dim]['originalTypeSpecifier'] === 'choice-comment'
          ) {
            delete storedPublicReport['dimensions'][dim];
          }
        }

        storedPublicReport['charts'] = storedPublicReport['charts']
          .filter((chart) => {
            const notAllowed: DimensionDataItem[] = (chart.data.details || []).filter(
              (item) =>
                notAllowedTypes.indexOf(item.originalType) >= 0 || item['originalTypeSpecifier'] === 'choice-comment',
            );
            return (
              (Charts.SUMMARYCHARTS.indexOf(chart.chartSettings.type) >= 0 ||
                Charts.TRENDCHARTS.indexOf(chart.chartSettings.type) >= 0 ||
                notAllowed.length === 0) &&
              chart.key !== 'Contacts_header'
            );
          })
          .map((chart) => {
            const notAllowedIndexes: number[] = [];
            for (let i = 0, len = (chart.data.details || []).length; i < len; i++) {
              const type: string = chart.data.details[i].originalType;
              const specifier: string = chart.data.details[i].originalTypeSpecifier;
              if (notAllowedTypes.indexOf(type) >= 0 || specifier === 'choice-comment') {
                notAllowedIndexes.push(i);
              }
            }

            for (let item = notAllowedIndexes.length - 1; item >= 0; item--) {
              const dimensionItems = ['domain', 'distributions', 'filters', 'details', 'scales', 'totalAnswers'];
              for (const dim of dimensionItems) {
                if (chart['data'][dim]) {
                  chart['data'][dim].splice(notAllowedIndexes[item], 1);
                }
              }
            }

            return chart;
          });
      }

      if (remove < 0) {
        for (const item in cm.grid) {
          if (cm.grid[item]['isPinned']) {
            this.rm.pin(surveyKey, reportKey, cm.grid[item]['key'], false, cm.grid[item]);
          }
        }
      }

      /* Cleaning answer data for very large reports */
      if (new TextEncoder().encode(JSON.stringify(storedReport)).length > 31000000) {
        storedReport['respondents']['answerDates'] = [];
        storedReport['respondents']['answerers'] = [];
        storedReport['respondents']['answers'] = [];
        storedReport['respondents']['textAnswers'] = {};
        storedReport['respondents']['textContacts'] = {};
        storedReport['respondents']['textLabels'] = {};
      }

      return this.rm.saveReport(
        surveyKey,
        newReport,
        reportKey,
        storedReport,
        storedPublicReport,
        makePublic,
        makePrivate,
        remove < 1,
      );
    } else {
      return new Observable<any>((observer) => {
        observer.next('');
        observer.complete();
      });
    }
  }

  public duplicate(surveyKey: string = '', reportKey: string = '', reportData: ReportData) {
    return new Observable<any>((observer) => {
      const duplication = () => {
        this.rm
          .getStoredReportData(surveyKey, reportKey)
          .pipe(first())
          .subscribe((report: any) => {
            const details: ReportData = new ReportData();
            details.title = 'Copy of ' + reportData.title;
            details.respondents =
              report && report.respondents && report.respondents.answerers ? report.respondents.answerers.length : 0;
            details.order = 1 / new Date().valueOf();
            details.savingOnProgress = true;

            return this.rm
              .saveReport(surveyKey, details, '', report, {} as StoredReport, false, false, false)
              .subscribe(() => {
                observer.next('Ready!');
                observer.complete();
              });
          });
      };

      if (this.saveDetails && this.autoSaveNeeded && reportKey === this.saveDetails.reportKey) {
        this.autoSaveNeeded = false;
        this.save(
          this.saveDetails.cf,
          this.saveDetails.cm,
          this.saveDetails.surveyKey,
          this.saveDetails.reportKey,
          this.saveDetails.reportTitle,
          this.saveDetails.makePublic,
          this.saveDetails.makePrivate,
          this.saveDetails.remove,
          this.saveDetails.publicSettings,
        ).subscribe(() => {
          duplication();
        });
      } else {
        duplication();
      }
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { ChartBuilderListItem, Exports, GridItem, GridItemDataItem } from '@shared/models/report.model';

import { Charts } from '@shared/enums/charts.enum';
import { Questions } from '@shared/enums/questions.enum';
import { AccountState } from '@shared/states/account.state';

import { ChartsManager } from '@report/shared/services/charts-manager.service';
import { DataConverter } from '@report/shared/services/data-converter.service';

import { Crossfilter } from '@report/shared/services/crossfilter.service';

import { Calculations } from '@report/shared/enums/calculations.enum';
import { NgScrollbar } from 'ngx-scrollbar';
import { isField } from '@report/shared/utilities/report.utilities';

/**
 * This is charts view component which converts grid object to actual ng-grid and pushes data to chart card component.
 */
@Component({
  selector: 'chart-builder',
  templateUrl: './chart-builder.component.html',
  styleUrls: ['./chart-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartBuilder implements OnInit, OnDestroy {
  @Input() dimensionInput;
  @Output() builder = new EventEmitter<any>();

  @ViewChild('sb', { read: NgScrollbar })
  sb?: NgScrollbar;

  readonly calculations = Calculations;

  private cfSub: Subscription | null = null;
  private searchSub: Subscription | null = null;

  private cfGridItem: GridItemDataItem | null = null;

  public dimensionList: ChartBuilderListItem[] = [];
  public groupList: ChartBuilderListItem[] = [];
  public questionList: ChartBuilderListItem[] = [];

  public categories: { [key: string]: string } = {
    groups: $localize`Groups`,
    questions: $localize`Questions`,
    dimensions: $localize`Single dimensions`,
  };
  public placeholderText: string = $localize`Select data`;

  public selectedDimensions: ChartBuilderListItem[] = [];

  public searchUpdate: Subject<string> = new Subject<string>();

  public filteredDimensions: ChartBuilderListItem[] = [];
  public filteredGroups: ChartBuilderListItem[] = [];
  public filteredQuestions: ChartBuilderListItem[] = [];

  public gridItem: GridItem | null = null;
  public gridSettings: any = { sizey: 6, sizex: 1, dragHandle: '.header' };

  constructor(
    private cf: Crossfilter,
    private cm: ChartsManager,
    private dc: DataConverter,
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngOnInit() {
    const hasEmotionsRights: boolean =
      this.store?.selectSnapshot(AccountState?.extensions)?.indexOf('languageEmotion') >= 0;
    const cleanedList: (list: ChartBuilderListItem[]) => ChartBuilderListItem[] = (list) =>
      list
        .slice()
        .filter(
          (dim) =>
            !['text-sentiment', 'text-words', 'text-emotions'].includes(
              dim?.['details']?.[0]?.['originalTypeSpecifier'],
            ),
        );
    this.searchSub = this.searchUpdate.pipe(debounceTime(150), distinctUntilChanged()).subscribe((term) => {
      this.filteredGroups = term ? this.filterItems(term, this.groupList) : this.groupList.slice();
      this.filteredQuestions = term ? this.filterItems(term, this.questionList) : this.questionList.slice();
      this.filteredDimensions = term
        ? this.filterItems(term, cleanedList(this.dimensionList))
        : cleanedList(this.dimensionList);
      this.cdRef.detectChanges();
    });

    if (!this.cfSub || (this.cfSub && this.cfSub.closed)) {
      this.cfSub = this.cf.crossfilter.subscribe((crossfilter) => {
        this.dimensionList = [];
        this.groupList = [];
        this.questionList = [];

        // this.dimensionObject = crossfilter.dimensions;
        const groups = {};
        const activeLocale: string = this.cf.getActiveLocale();
        const locales: any = this.cf.getLocales();

        if (crossfilter) {
          for (const dim in crossfilter.dimensions) {
            const survey: string = crossfilter.dimensions[dim]['survey'];
            const title: string =
              activeLocale &&
              crossfilter.dimensions[dim]['localeStrings'] &&
              crossfilter.dimensions[dim]['localeStrings'][activeLocale] &&
              crossfilter.dimensions[dim]['localeStrings'][activeLocale]['title']
                ? crossfilter.dimensions[dim]['localeStrings'][activeLocale]['title']
                : crossfilter.dimensions[dim].title;
            const customTitle: { [s: string]: string } = {
              default: title,
            };
            for (const locale in locales[survey]?.config || {}) {
              customTitle[locale] =
                locale &&
                crossfilter.dimensions[dim]['localeStrings'] &&
                crossfilter.dimensions[dim]['localeStrings'][locale] &&
                crossfilter.dimensions[dim]['localeStrings'][locale]['title']
                  ? crossfilter.dimensions[dim]['localeStrings'][locale]['title']
                  : crossfilter.dimensions[dim].title;
            }
            if (dim === 'outcome' && crossfilter.gridItems[dim]) {
              this.dimensionList.push({
                key: crossfilter.dimensions[dim].key,
                title,
                customTitle,
                details: [crossfilter.dimensions[dim]],
                icon: this.getQuestionIcon([crossfilter.dimensions[dim]], '', dim),
                iconClass: this.iconClass([crossfilter.dimensions[dim]], '', dim),
              });
            } else if (dim !== 'outcome') {
              this.dimensionList.push({
                key: crossfilter.dimensions[dim].key,
                title,
                customTitle,
                details: [crossfilter.dimensions[dim]],
                icon: this.getQuestionIcon([crossfilter.dimensions[dim]], '', dim),
                iconClass: this.iconClass([crossfilter.dimensions[dim]], '', dim),
              });

              const group = crossfilter.dimensions[dim]['group'];

              if (
                group &&
                crossfilter.dimensions[dim]['originalTypeSpecifier'] !== 'text-sentiment' &&
                crossfilter.dimensions[dim]['originalTypeSpecifier'] !== 'text-words' &&
                crossfilter.dimensions[dim]['originalTypeSpecifier'] !== 'text-emotions'
              ) {
                if (!groups[group]) {
                  groups[group] = [];
                }

                groups[group].push(crossfilter.dimensions[dim]);
              }
            }
          }
        }

        const groupKeys = Object.keys(groups);
        // const dimKeys = Object.keys(this.dimensionList);

        for (const survey in this.cf.questions) {
          for (const question of this.cf.questions[survey]) {
            const surveyLocales = locales[survey];
            const group = groupKeys.find((key) => key === survey + '/' + question.$key);
            const title: string =
              surveyLocales &&
              surveyLocales['strings'] &&
              surveyLocales['strings'][activeLocale] &&
              surveyLocales['strings'][activeLocale][`title-` + question.$key]
                ? surveyLocales['strings'][activeLocale][`title-` + question.$key]
                : question.title;
            const customTitle: { [s: string]: string } = {
              default: title,
            };
            for (const locale in surveyLocales.config || {}) {
              customTitle[locale] =
                surveyLocales &&
                surveyLocales['strings'] &&
                surveyLocales['strings'][locale] &&
                surveyLocales['strings'][locale][`title-` + question.$key]
                  ? surveyLocales['strings'][locale][`title-` + question.$key]
                  : question.title;
            }
            if (group) {
              this.groupList.push({
                key: survey + '/' + question.$key,
                title,
                customTitle,
                details: groups[group],
                icon: this.getQuestionIcon(groups[group], 'group'),
                iconClass: this.iconClass(groups[group], 'group'),
              });
            } else {
              const dimensions = this.dimensionList
                .filter((dim) => survey + '/' + question.$key === dim.key.split(':')[0])
                .map((item) => item.details[0]);
              const hasSentiment: boolean = dimensions.some((item) => item.originalTypeSpecifier === 'text-sentiment');
              const hasWords: boolean = dimensions.some((item) => item.originalTypeSpecifier === 'text-words');
              const hasEmotions: boolean =
                hasEmotionsRights && dimensions.some((item) => item.originalTypeSpecifier === 'text-emotions');

              if (dimensions.length > 0) {
                this.questionList.push({
                  key: question.$key,
                  title,
                  customTitle,
                  details: dimensions.filter(
                    (item) =>
                      item.originalTypeSpecifier !== 'text-sentiment' &&
                      item.originalTypeSpecifier !== 'text-words' &&
                      item.originalTypeSpecifier !== 'text-emotions',
                  ),
                  icon: this.getQuestionIcon(dimensions),
                  iconClass: this.iconClass(dimensions),
                });

                if (hasWords) {
                  this.questionList.push({
                    key: question.$key + ':words',
                    title: title + ' - ' + $localize`:@@zef-i18n-00526:words`,
                    customTitle,
                    details: dimensions.filter((item) => item.originalTypeSpecifier === 'text-words'),
                    icon: this.getQuestionIcon(dimensions, 'words'),
                    iconClass: this.iconClass(dimensions),
                  });
                }
                if (hasSentiment) {
                  this.questionList.push({
                    key: question.$key + ':sentiment',
                    title: title + ' - ' + $localize`:@@zef-i18n-00531:sentiment`,
                    customTitle,
                    details: dimensions
                      .filter(
                        (item) =>
                          item.originalTypeSpecifier !== 'text-words' && item.originalTypeSpecifier !== 'text-emotions',
                      )
                      .slice()
                      .reverse(),
                    icon: this.getQuestionIcon(dimensions, 'sentiment'),
                    iconClass: this.iconClass(dimensions),
                  });
                }
                if (hasEmotions) {
                  this.questionList.push({
                    key: question.$key + ':emotions',
                    title: title + ' - ' + $localize`emotions`,
                    customTitle,
                    details: dimensions
                      .filter(
                        (item) =>
                          item.originalTypeSpecifier !== 'text-words' &&
                          item.originalTypeSpecifier !== 'text-sentiment',
                      )
                      .reverse(),
                    icon: this.getQuestionIcon(dimensions, 'emotions'),
                    iconClass: this.iconClass(dimensions),
                  });
                }
              }
            }
          }
        }

        this.filteredDimensions = cleanedList(this.dimensionList);
        this.filteredGroups = this.groupList.slice();
        this.filteredQuestions = this.questionList.slice();
      });
    }
    if (this.dimensionInput) {
      this.updateChart();
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy() {
    if (this.cfSub) {
      this.cfSub.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  // For Searchable menu

  filterItems(name: string, list: any) {
    return list.filter((dim) => dim?.title?.toLowerCase()?.indexOf(name?.toLowerCase()) > -1);
  }

  select($event, dimension, i) {
    this.selectedDimensions[i] = $event;
    this.searchUpdate.next('');
    this.updateChart();
    this.cdRef.detectChanges();
  }

  public trackByKey(i: number, item: ChartBuilderListItem): string {
    return item?.key || i.toString();
  }

  closeSelect() {
    this.searchUpdate.next('');
  }

  displayFn(dimension) {
    return dimension ? dimension.title : dimension;
  }

  dimTitleFn(dimension) {
    if (dimension?.details?.[0]?.scale === 'linear' && dimension?.details?.[0]?.originalType !== 'respondent-field') {
      const activeLocale: string = this.cf.getActiveLocale();
      const axis: string =
        (activeLocale && dimension.details[0]?.['localeStrings']?.[activeLocale]?.['labelsLinear']?.['axis']) ||
        dimension.details[0].labelsLinear?.axis;
      return (axis ? `(${axis}) ` : '') + dimension.title;
    } else {
      return dimension.title;
    }
  }

  newDimension() {
    this.selectedDimensions.push(null);
  }

  deleteDimension(i) {
    this.selectedDimensions.splice(i, 1);
    this.updateChart();
  }

  updateChart() {
    const dimensions: string[] = [];
    const chartableDimensions = this.selectedDimensions.slice().filter((d) => d?.details);
    if (this.dimensionInput) {
      chartableDimensions.unshift(this.dimensionInput);
    }

    for (const dim of chartableDimensions) {
      for (const details of dim.details) {
        if (details.key) {
          dimensions.push(details.key);
        }
      }
    }

    this.cfGridItem = this.cf.gridItemGenerate(dimensions);

    const gridItem = this.cf.calculate({ newGriditem: this.cfGridItem });

    const linear = gridItem['newGriditem'].scales.filter((item) => item === 'linear').length > 0 ? true : false;
    const twods =
      gridItem['newGriditem'].details.filter((item) => item.originalType === Questions.SLIDER_2D).length > 3
        ? true
        : false;
    const chartType =
      linear && twods
        ? Charts.SUMMARY2D
        : this.cm.chartTypeRecommendation(gridItem['newGriditem'].scales, gridItem['newGriditem'].details);

    // const key = new Date().valueOf();

    let title: string = '';
    const customTitle: { [s: string]: string } = {};

    if (chartableDimensions.length > 0) {
      for (let d = 0, len = chartableDimensions.length; d < len; d++) {
        title += d > 0 ? ' - ' : '';
        title += chartableDimensions[d].title;

        for (const locale in chartableDimensions[d]['customTitle'] || {}) {
          if (!customTitle[locale]) {
            customTitle[locale] = '';
          }
          customTitle[locale] += d > 0 ? ' - ' : '';
          customTitle[locale] += chartableDimensions[d]['customTitle'][locale];
        }
      }
    } else {
      title = 'New Custom Chart';
    }

    if (!this.gridItem) {
      this.gridItem = {
        hided: false,
        gridId: this.cm.grid.length,
        key: this.cf.generateId(),
        title,
        customTitle,
        showDataTable: false,
        chartSettings: {
          type: chartType,
          scale: 'absolute',
          textSummary: this.cm.textSummaryAvailable(gridItem['newGriditem'].details),
        },
        isGroup: false,
        data: this.dc.BasicConverter(gridItem['newGriditem']),
        gridSettings: {
          sizex: 1,
          sizey: this.cm.chartHeightRecommendation(gridItem['newGriditem'], chartType),
          col: 1,
          dragHandle: '.header',
        },
        isCustomCard: true,
        exports: {} as Exports,
      };
    } else {
      this.gridItem.chartSettings.type = chartType;
      this.gridItem.chartSettings.textSummary = this.cm.textSummaryAvailable(gridItem['newGriditem'].details);
      this.gridItem.title = title;
      this.gridItem.customTitle = customTitle;
      this.gridItem.data = this.dc.BasicConverter(gridItem['newGriditem']);
      this.gridItem.gridSettings.sizey = this.cm.chartHeightRecommendation(gridItem['newGriditem'], chartType);
    }

    this.cdRef.markForCheck();
    setTimeout(() => this.sb?.update());
  }

  changeHeight($event) {}

  dataTable($event) {}

  close() {
    delete this.gridItem;
    this.builder.emit(false);
  }

  save() {
    if (this.gridItem != null && this.cfGridItem != null) {
      this.cf.gridItems[this.gridItem.key] = this.cfGridItem;

      if (Charts.SUMMARYCHARTS.find((chart) => this.gridItem != null && chart === this.gridItem.chartSettings.type)) {
        this.gridItem.gridSettings.sizex = 2;
        this.gridItem.gridSettings.col = 1;
      } else {
        this.gridItem.gridSettings.col = (this.cm.grid.length - 1) % 2 ? 5 : 1;
      }

      let row: number = 0;
      for (let i = 0, len = this.cm.grid.length; i < len; i++) {
        if (this.cm.grid[i].gridSettings && this.cm.grid[i].gridSettings.row > row) {
          row = this.cm.grid[i].gridSettings.row;
        }
      }

      this.gridItem.gridSettings.row = row + 1;
      this.cm.createGridItem(this.gridItem);
      delete this.gridItem;

      this.cm.gridChange.next('Added new custom chart');
    }
    this.builder.emit(false);
  }

  private getQuestionIcon(details, type?: string, key?: string): string {
    if (type === 'group') {
      return 'editor_question_group';
    } else if (isField(key)) {
      return 'property';
    } else if (key === 'userSegments') {
      return 'group';
    } else if (type === 'sentiment') {
      return Charts.ChartIcons[Charts.TEXTSENTIMENTANALYSIS];
    } else if (type === 'words') {
      return Charts.ChartIcons[Charts.WORDANALYSIS];
    } else if (type === 'emotions') {
      return Charts.ChartIcons[Charts.TEXTEMOTIONS];
    } else if (details?.length > 0) {
      return key === 'time'
        ? 'time'
        : key === 'hashtags'
        ? 'hash'
        : key === 'lang'
        ? 'flag'
        : key === 'shareLink'
        ? 'link'
        : key === 'zefSurveyUserRating'
        ? 'player_thumbs_up'
        : key === 'survey'
        ? 'survey'
        : key === 'outcome'
        ? 'editor_star_empty'
        : Questions.QuestionIcons[details[0].originalType];
    } else {
      return '';
    }
  }

  private iconClass(details, itemType?: string, key?: string) {
    const isMetric = ['time', 'hashtags', 'lang', 'shareLink', 'zefSurveyUserRating', 'survey'].indexOf(key) >= 0;
    const isRespondentField = isField(key);

    const type = isMetric
      ? 'metric'
      : isRespondentField
      ? 'contact'
      : itemType === 'group'
      ? 'group_cards'
      : details?.length > 0
      ? details[0].originalType
      : '';
    return `item-${type}`;
  }
}

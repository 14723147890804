<mat-icon
  class="z-answered-icon"
  fontSet="zefplayer"
  [style.color]="(ss.colors | async).primary"
  [style.top.px]="iconTop"
  (click)="resetInput()"
>
  <ng-container *ngIf="!!input.value?.trim()">check</ng-container>
</mat-icon>

<textarea
  class="z-input"
  autocomplete="off"
  maxLength="1500000"
  rows="1"
  [placeholder]="placeholder"
  [value]="answer?.trim()"
  [style.border-color]="this.isValid ? (ss.colors | async).primary : invalidColor"
  [style.color]="(ss.colors | async).primary"
  (focus)="updateFocus($event)"
  (blur)="selectAnswer(input.value?.trim())"
  (input)="selectAnswer(input.value?.trim(), false); updateHeight()"
  #input
></textarea>

<span *ngIf="!isValid" class="z-error-text">{{ errorText }}</span>

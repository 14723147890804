import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  TemplateRef,
  ContentChild,
  SimpleChanges,
  OnChanges,
  ViewChild,
} from '@angular/core';

import { SlideInOut } from '@shared/components/panel/panel.animations';
import { OverlayConfig, OverlayRef, Overlay } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';

@Component({
  exportAs: 'zefPanel',
  selector: 'zef-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
  animations: [SlideInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Panel implements OnInit, AfterViewInit, OnChanges {
  @ContentChild('panelContent', { read: TemplateRef })
  template: TemplateRef<any>;

  private name: string = '';

  private childs: Panel[] = [];

  public locked: boolean = false;

  public closing: boolean = false;

  @Input() layer: number = 104;

  @Input() scrim: boolean = true;
  @Input() backdrop: boolean = false;
  @Input() interactive: boolean = true;

  @Input() align: string = 'left';
  @Input() width: string = '320px';
  @Input() state: string = 'closed';

  @Input() parent: Panel | null = null;

  @Input() toggle?: boolean;

  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();

  @ViewChild(CdkPortal)
  portal?: CdkPortal;

  private overlayRef: OverlayRef;

  // TODO: Change to using dynamic event listening if this issue is not fixed:
  //       https://github.com/angular/angular/issues/15905
  @HostListener('window:keydown', ['$event']) onKeydown(event: any) {
    if (event.key === 'Escape' && this.state.split('-')[0] === 'opened' && this.interactive) {
      this.closePanel();
    }
  }

  @HostListener('mousewheel', ['$event']) onMouseWheel(event: MouseEvent) {
    if (this.backdrop) {
      const isPanelTarget = event.composedPath()?.some((el: HTMLElement) => el?.classList?.contains('wrapper'));

      if (isPanelTarget) {
        event.stopPropagation();
      }
    }
  }

  constructor(private cdRef: ChangeDetectorRef, private overlay: Overlay) {}

  ngOnInit() {
    this.state = this.state + '-' + this.align;

    this.cdRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toggle) {
      if (this.toggle && !this.isOpen()) {
        this.openPanel();
      } else if (!this.toggle && this.isOpen()) {
        this.closePanel();
      }
    }
  }

  ngAfterViewInit() {
    if (this.parent) {
      this.parent.addChildPanel(this);

      this.align = this.parent.align;

      this.scrim = false;
    }
  }

  public togglePanel(name?: string): void {
    if (this.isOpen(name)) {
      this.closePanel(name);
    } else {
      this.openPanel(name);
    }
  }

  public isOpen(name?: string) {
    if (name && name !== this.name) {
      return false;
    }

    return this.state.split('-')[0] === 'opened';
  }

  public openPanel(name?: string) {
    this.name = name || '';

    this.state = 'opened-' + this.align + '-' + (this.parent ? 'child' : 'root');

    this.overlayRef?.dispose();

    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      backdropClass: !this.scrim && this.interactive ? 'cdk-overlay-transparent-backdrop' : '',
      hasBackdrop: this.scrim || this.interactive,
    });

    this.overlayRef = this.overlay.create(config);

    this.overlayRef.backdropClick().subscribe(() => this.closePanel());

    this.overlayRef.attach(this.portal);

    this.cdRef.markForCheck();

    this.open.emit();
  }

  public closePanel(name?: string) {
    if (!this.locked && this.isOpen()) {
      if (!name || name === this.name) {
        this.closing = true;

        for (const child of this.childs) {
          child.closePanel();
        }

        this.state = 'closed-' + this.align;

        this.overlayRef.dispose();

        this.overlayRef = null;

        this.cdRef.markForCheck();

        this.close.emit(this.name);
      }
    }
  }

  public addChildPanel(child: Panel) {
    this.childs.push(child);
  }

  public hasOpenChilds(name?: string) {
    for (const child of this.childs) {
      if (!name || name === child.name) {
        if (child.state.split('-')[0] === 'opened') {
          return true;
        }
      }
    }

    return false;
  }
}

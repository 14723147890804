import { ChangeDetectionStrategy, Component } from '@angular/core';

import { InputChoice } from '@player/+survey/question/input-choice/input-choice.component';
import { ChoiceLayout } from '@shared/models/survey.model';

@Component({
  selector: 'input-choice-picture',
  templateUrl: './input-choice-picture.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputChoicePicture extends InputChoice {
  readonly layout = ChoiceLayout;
}

import {
  ContactImportSettings,
  ContactImportSource,
  ContactsListData,
  ContactsSearchParams,
  ImportColumnData,
  SelectColumn,
  ContactImportStep,
  ContactImportReview,
} from '@shared/models/contact.model';
import { EmailData } from '@shared/models/email.model';

export class GetContactColumns {
  static readonly type = '[Contacts] Get Contact Columns';
}

export class GetContacts {
  static readonly type = '[Contacts] Get Contacts';

  constructor(readonly params: Partial<ContactsSearchParams>) {}
}

export class AddToLists {
  static readonly type = '[Contacts] Add to Lists';

  constructor(readonly ids: number[], readonly lists: number[], readonly all: boolean = false) {}
}

export class RemoveFromList {
  static readonly type = '[Contacts] Remove from List';

  constructor(readonly ids: number[], readonly listId: number) {}
}

export class ComposeEmail {
  static readonly type = '[Contacts] Compose Email';

  constructor(readonly data: Partial<EmailData>) {}
}

export class CreateContact {
  static readonly type = '[Contacts] Create Contact';

  constructor(readonly data: any) {}
}

export class DeleteContacts {
  static readonly type = '[Contacts] Delete Contacts';

  constructor(readonly ids: number[]) {}
}

export class SelectContacts {
  static readonly type = '[Contacts] Select Contacts';

  constructor(readonly ids: number[]) {}
}

export class LoadingContacts {
  static readonly type = '[Contacts] Loading Contacts';

  constructor(readonly status: boolean) {}
}

export class ResetContacts {
  static readonly type = '[Contacts] Reset Contacts';
}
export class GetContactLists {
  static readonly type = '[Contacts] Get Contact Lists';

  constructor(readonly params: Partial<ContactsSearchParams>) {}
}

export class CreateContactList {
  static readonly type = '[Contacts] Create Contact List';

  constructor(readonly list: Partial<ContactsListData>, readonly members?: number[], readonly all?: boolean) {}
}

export class UpdateContactList {
  static readonly type = '[Contacts] Update Contact List';

  constructor(readonly list: Partial<ContactsListData> & Pick<ContactsListData, 'id'>) {}
}

export class DeleteContactLists {
  static readonly type = '[Contacts] Delete Contact Lists';

  constructor(readonly ids: number[]) {}
}

export class LoadingContactLists {
  static readonly type = '[Contacts] Loading Contact Lists';

  constructor(readonly status: boolean) {}
}

export class ResetContactLists {
  static readonly type = '[Contacts] Reset Contact Lists';
}

export class ResetImport {
  static readonly type = '[Contacts] Reset Import';
}

export class ToggleImportGDPR {
  static readonly type = '[Contacts] Toogle Import GDPR';
  constructor(readonly gdprAccepted: boolean) {}
}

export class UpdateImportPreview {
  static readonly type = '[Contacts] Update Import Preview';
  constructor(readonly response: ImportColumnData) {}
}

export class UpdateImportSettings {
  static readonly type = '[Contacts] Update Import Settings';
  constructor(readonly change?: Partial<ContactImportSettings>) {}
}

export class UpdateImportStep {
  static readonly type = '[Contacts] Update Import Step';
  constructor(readonly step: ContactImportStep) {}
}

export class UpdateImportSource {
  static readonly type = '[Contacts] Update Import Source';
  constructor(readonly change: Partial<ContactImportSource>) {}
}

export class CreateImportColumns {
  static readonly type = '[Contacts] Create Import Columns';
}

export class SkipUnmatchedImportColumns {
  static readonly type = '[Contacts] Skip Unmatched Import Columns';
}

export class UpdateImportColumn {
  static readonly type = '[Contacts] Update Import Column';
  constructor(readonly column: SelectColumn, readonly change: Partial<SelectColumn>) {}
}

export class UpdateImportReview {
  static readonly type = '[Contacts] Update Import Review';
  constructor(readonly change: Partial<ContactImportReview>) {}
}

export class UploadContactsFile {
  static readonly type = '[Contacts] Upload file';

  constructor(readonly file: File) {}

  toJSON = () => ({ ...this, type: UploadContactsFile.type, file: this.file.name });
}

export class ImportContactsFromCopyPaste {
  static readonly type = '[Contacts] Import contacts from copy paste';

  constructor(
    readonly name: string,
    readonly copyPaste: string,
    readonly repairLanguage: string,
    readonly invite?: string,
    readonly inviteKind?: 'email' | 'sms',
  ) {}
}

/**
 * Auto selects text on input focus.
 *
 * @unstable
 */

import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[autoSelect]',
})
export class AutoSelect {
  public activeElement: any = null;

  @HostListener('focus', ['$event']) onFocus(event: Event): void {
    if (this.activeElement !== this.elRef.nativeElement) {
      this.elRef.nativeElement.select();

      this.activeElement = document.activeElement;
    }
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: Event): void {
    this.activeElement = document.activeElement;
  }

  constructor(readonly elRef: ElementRef) {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Rights } from '@shared/enums/rights.enum';
import { IdentityData } from '@shared/models/prefs.model';
import { FolderModel } from '@shared/models/survey.model';
import { PrefsState } from '@shared/states/prefs.state';
import { Observable } from 'rxjs';
import { AccountState } from '@shared/states/account.state';

@Component({
  selector: 'folder-thumb',
  templateUrl: './folder-thumb.component.html',
  styleUrls: ['./folder-thumb.component.scss'],
})
export class FolderThumb implements OnInit {
  readonly Rights = Rights;
  @Input() hovered: boolean = false;
  @Input() folder: FolderModel | null = null;
  @Output() activation: EventEmitter<string> = new EventEmitter();

  public owner$?: Observable<IdentityData>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.owner$ = this.store.select(PrefsState.folderOwner(this.folder.createdBy));
  }

  isFolderOwner(ownerKey: string) {
    return this.store.selectSnapshot(AccountState.userKey) === ownerKey;
  }
}

<div class="z-progress-wrapper">
  <div class="z-progress-steps z-fx-col z-fx-center-center">
    <span
      *ngIf="showTopArrow | async"
      class="z-first"
      [style.color]="(ss.colors | async).text"
      (click)="gotoIndex(index$.getValue() - 1)"
    ></span>

    <figure
      *ngFor="let step of steps | async"
      [class.active]="(index$ | async) === step"
      [style.background-color]="(ss.colors | async).text"
      (click)="gotoIndex(step)"
    ></figure>

    <span
      *ngIf="showBottomArrow | async"
      class="z-last"
      [style.color]="(ss.colors | async).text"
      (click)="gotoIndex(index$.getValue() + 1)"
    ></span>
  </div>
</div>

import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, filter, merge, Observable, of, ReplaySubject, switchMap } from 'rxjs';

import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Select, Store } from '@ngxs/store';

import { Rights } from '@shared/enums/rights.enum';
import { getLastValue, shareRef } from '@shared/operators/share-ref.operator';
import { FoldersState } from '@shared/states/folders.state';
import { AccountState } from '@shared/states/account.state';
import { AnalyticsTracks } from '@shared/models/analytics.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { FolderModel } from '@shared/models/survey.model';
import { PrefsState } from '@shared/states/prefs.state';
import { SurveyIndex } from '@shared/states/index/index-state.models';
import { Status } from '@shared/models/status.model';

type Activation = 'build' | 'edit' | 'share' | 'analyze' | 'select' | 'duplicate' | 'move' | 'remove' | 'delete';

@Component({
  selector: 'survey-thumb',
  templateUrl: './survey-thumb.component.html',
  styleUrls: ['./survey-thumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyThumb {
  readonly Analytics = AnalyticsTracks;

  readonly thumbMenuTrigger$ = new ReplaySubject<MatMenuTrigger>(1);

  readonly survey$ = new ReplaySubject<SurveyIndex>(1);

  readonly surveyKey$: Observable<string> = this.survey$.pipe(
    map((survey) => survey?.$key),
    filter((key) => !!key),
    shareRef(),
  );

  readonly backgroundImage$: Observable<string> = this.survey$.pipe(
    map((survey) => survey?.background || 'assets/images/survey-placeholder.png'),
  );

  readonly respondents$: Observable<number> = this.survey$.pipe(map((survey) => survey?.respondents || 0));

  readonly status$: Observable<Status> = this.survey$.pipe(map((survey) => survey?.status || Status.Warning));

  readonly visible$ = new BehaviorSubject(true);

  readonly selected$: Observable<boolean> = this.surveyKey$.pipe(
    switchMap((surveyKey) => this.store.select(FoldersState.isSelected(surveyKey))),
  );

  readonly folder$: Observable<FolderModel | undefined> = this.surveyKey$.pipe(
    switchMap((surveyKey) => this.store.select(FoldersState.surveyFolder(surveyKey))),
  );

  readonly canEdit$: Observable<boolean> = this.store.select(AccountState.userRole).pipe(
    switchMap((teamRights) =>
      Rights.hasRights(Rights.ADMIN, teamRights)
        ? of(true)
        : combineLatest([this.survey$, this.store.select(AccountState.userKey)]).pipe(
            map(([survey, userKey]) => Rights.hasRights(Rights.EDIT, survey?.users?.[userKey])),
          ),
    ),
    shareRef(),
  );

  readonly thumbActive$: Observable<boolean> = this.thumbMenuTrigger$.pipe(
    switchMap((trigger) =>
      trigger ? merge(trigger.menuOpened, trigger.menuClosed).pipe(map(() => trigger.menuOpen)) : of(false),
    ),
    shareRef(),
  );

  @Select(FoldersState.hasSelected)
  readonly hasSelected$!: Observable<boolean>;

  @Input()
  interactive?: boolean;

  @Input()
  set survey(survey: SurveyIndex) {
    this.survey$.next(survey);
  }

  @Input()
  set visible(visible: boolean) {
    this.visible$.next(visible);
  }

  @Output()
  readonly activation = new EventEmitter<Activation | string>();

  @ViewChild(MatMenuTrigger)
  set thumbMenuTrigger(thumbMenuTrigger: MatMenuTrigger) {
    this.thumbMenuTrigger$.next(thumbMenuTrigger);
  }

  private touchActivated = false;

  constructor(private store: Store, readonly el: ElementRef<HTMLElement>) {}

  onThumbClick(): void {
    if (getLastValue(this.hasSelected$)) {
      this.activation.emit('select');
    } else {
      const isTouchEnabled = this.store.selectSnapshot(PrefsState.touchEnabled);
      const hasSelected = this.store.selectSnapshot(FoldersState.hasSelected);

      if (isTouchEnabled && !hasSelected && !this.touchActivated) {
        this.touchActivated = true;
        return;
      } else if (isTouchEnabled && this.touchActivated) {
        this.touchActivated = false;
      }

      this.activation.emit('build');
    }
  }
}

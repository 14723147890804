import { Injectable } from '@angular/core';

import { Questions } from '@shared/enums/questions.enum';

import { AnswerData, AnswererData } from '@shared/models/answer.model';
import {
  OutcomeData,
  QuestionData,
  SliderLabelsData,
  SliderValuesData,
  ReleaseData,
  ShareLinkData,
} from '@shared/models/survey.model';

import { LocalesData } from '@shared/models/locale.model';

/**
 * This service will generate random data to report until we have actual data from server.
 */
@Injectable({
  providedIn: 'root',
})
export class DataGenerator {
  private responses: any = [];
  private rounder = Math.pow(10, 5);
  private randomComments: string[] = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    'Vivamus porttitor ex in scelerisque efficitur.',
    'Nam lobortis nibh sit amet purus suscipit venenatis.',
    'Quisque faucibus tellus ac quam facilisis dapibus.',
    'Maecenas in sem ut dolor eleifend placerat.',
    'Proin ornare nibh sit amet lorem fringilla, sit amet feugiat elit posuere.',
    'Nunc accumsan sem tempus nulla vestibulum lobortis.',
    'Morbi iaculis dui sit amet sem mattis pharetra.',
    'Vestibulum viverra arcu sed blandit pharetra.',
    'Phasellus consequat mauris nec urna condimentum suscipit.',
    'Etiam malesuada augue sit amet diam ornare molestie.',
    'Praesent non lacus hendrerit, mattis velit sit amet, tincidunt quam.',
    'Phasellus dapibus mauris eget orci aliquam, at sodales arcu rhoncus.',
    'Phasellus sit amet diam sed erat fringilla mollis at sed eros.',
    'Integer interdum nisi id purus congue congue.',
    'Nunc malesuada lectus ut enim placerat euismod.',
    'Mauris quis lorem tincidunt, porta urna sed, euismod dolor.',
    'Phasellus convallis odio sed nunc tempor, nec porta erat pulvinar.',
    'Phasellus aliquam est et metus lobortis euismod.',
    'Praesent quis est hendrerit, blandit nisi quis, viverra velit.',
    'Ut vel turpis consequat, lobortis mauris nec, facilisis justo.',
    'Sed iaculis metus sed magna laoreet, non posuere lectus laoreet.',
    'Morbi a augue a nisl rutrum vulputate.',
    'Sed pellentesque elit sed lectus ultricies congue.',
    'Phasellus tempor tellus sit amet ligula vulputate, eget lacinia nunc placerat.',
    'Quisque sit amet ante consequat, fringilla lacus at, hendrerit risus.',
    'Donec nec justo in est vehicula rutrum.',
    'Nullam et turpis semper enim blandit condimentum.',
    'Duis tempor lorem eu imperdiet pulvinar.',
    'Proin ut ante id diam commodo convallis.',
    'Cras feugiat nisl id nisl faucibus, at faucibus metus fringilla.',
    'Etiam pharetra sem in eros tincidunt, ac faucibus mi sollicitudin.',
    'Duis a nulla egestas lorem laoreet hendrerit.',
    'Nam sed purus quis urna luctus egestas ac nec sapien.',
    'Duis accumsan mi quis tortor eleifend, id imperdiet ex iaculis.',
    'Praesent ultricies libero in tempor imperdiet.',
    'Morbi elementum urna eu dui interdum vulputate eget iaculis lacus.',
    'Sed porttitor eros in accumsan venenatis.',
    'Donec at velit sit amet ante egestas ornare in in neque.',
    'Mauris in est porta, eleifend lectus nec, fringilla justo.',
    'Aenean gravida diam id purus viverra condimentum.',
    'Duis in leo in nisl fermentum consectetur in ac velit.',
    'Sed sagittis enim eu efficitur mollis.',
    'Maecenas id turpis ac tortor tempus tincidunt.',
    'Donec lacinia felis nec tristique ullamcorper.',
    'Fusce sagittis velit pretium imperdiet tristique.',
    'Nulla placerat lectus sed vulputate luctus.',
    'Curabitur varius neque sed magna molestie, nec imperdiet mauris interdum.',
    'Vestibulum et est ornare, pretium erat at, scelerisque lectus.',
    'Donec vehicula mauris et tellus ultricies, vel porta lorem auctor.',
    'Sed placerat diam sit amet sem accumsan, sed mattis est porttitor.',
    'Fusce gravida dui id sagittis vulputate.',
    'Praesent tincidunt mauris sed mi aliquam interdum.',
    'Sed a odio pharetra, sollicitudin orci at, congue magna.',
    'Aliquam at ante eu felis dictum porta.',
    'Pellentesque in urna id sapien fringilla venenatis.',
    'Etiam at ante eu mi feugiat eleifend.',
    'Vivamus elementum ante rhoncus velit lacinia, in imperdiet nunc interdum.',
    'Nulla non sem semper, efficitur velit sed, ultrices urna.',
    'Duis interdum urna in neque tempor, sed imperdiet augue lobortis.',
    'Maecenas non nisl pharetra lectus ultrices sodales quis vehicula ligula.',
    'Morbi et arcu suscipit, egestas quam quis, posuere elit.',
    'Etiam a nulla gravida, sodales nisl sit amet, suscipit turpis.',
    'Maecenas in augue et orci sodales semper.',
    'Pellentesque a nunc a mi venenatis convallis sit amet quis augue.',
    'Integer egestas eros eu posuere varius.',
    'Etiam euismod ante eget felis eleifend, ac ultrices erat ullamcorper.',
    'In sed tortor et ligula gravida congue ac a leo.',
    'Vestibulum a purus sit amet diam elementum suscipit eu non tellus.',
    'Vivamus quis lectus a tortor lobortis vulputate.',
    'Sed efficitur nisi eu sem rutrum, sed mollis massa consequat.',
    'Aenean ornare tellus vehicula, vulputate est eleifend, suscipit leo.',
    'Nam at quam rutrum, porttitor orci in, accumsan quam.',
    'Vestibulum gravida orci fermentum, efficitur ipsum sed, elementum mi.',
    'Maecenas placerat ligula id nibh venenatis pellentesque.',
    'Nunc fringilla odio vehicula magna vestibulum mattis.',
    'Cras interdum sem at enim convallis ultricies.',
    'Maecenas quis justo non neque sodales posuere id sed arcu.',
    'Nunc id dolor eu massa interdum aliquet eu nec massa.',
    'Sed bibendum nunc ut orci mollis, id rutrum enim porttitor.',
    'Proin vel lorem feugiat, finibus orci nec, vehicula quam.',
    'Donec quis eros ac lacus maximus vestibulum vel non felis.',
    'Nunc volutpat nisi id neque gravida pellentesque a ut ante.',
    'Aliquam et turpis eget lectus sagittis tempus.',
    'Vestibulum id ante ut mi lobortis auctor ac nec arcu.',
    'Ut ac purus in sapien sollicitudin tristique nec non augue.',
    'Nunc at elit ut sem pretium iaculis id dapibus mi.',
    'Nunc rutrum arcu eu enim gravida semper.',
    'Cras porttitor dui eget luctus molestie.',
    'In quis neque sit amet magna pretium aliquam.',
    'Nullam aliquam tortor eget lorem ullamcorper eleifend.',
    'Etiam at dolor semper neque convallis aliquet.',
    'Curabitur iaculis quam ac augue fermentum facilisis.',
    'Duis non dui non erat ultricies fringilla sit amet non elit.',
    'Suspendisse convallis arcu id odio bibendum iaculis.',
    'Praesent eleifend mi eget sollicitudin feugiat.',
    'Donec scelerisque sapien a arcu finibus, at scelerisque odio convallis.',
    'Donec tempus dolor in nunc sollicitudin, at elementum est posuere.',
    'Sed posuere nibh in turpis iaculis iaculis.',
    'In pretium leo eget ante facilisis, vitae porttitor nibh tincidunt.',
    'Nam elementum libero eget nisl consequat dignissim et hendrerit turpis.',
    'Donec egestas velit ac enim rhoncus, at ultricies erat auctor.',
    'Nam vulputate tortor mattis, sodales quam eu, imperdiet nisl.',
    'Nulla sodales orci non nisi scelerisque commodo.',
    'Etiam cursus sem a magna convallis semper.',
    'Nunc malesuada purus efficitur mattis sollicitudin.',
    'Nam sed quam facilisis, finibus massa non, euismod lectus.',
    'Praesent sagittis urna vel est consectetur, et ultrices ex ultricies.',
    'Aliquam venenatis ex a tortor vulputate sollicitudin.',
    'Suspendisse vel est eget massa congue pharetra non vitae massa.',
    'Maecenas et turpis at eros aliquam accumsan.',
    'Etiam interdum mauris non sapien sollicitudin, eu tempor sem viverra.',
    'Cras hendrerit diam sit amet turpis posuere viverra.',
    'Praesent venenatis sapien eget porta consequat.',
    'Aenean non sapien vel leo aliquet cursus id et nunc.',
    'Sed feugiat quam sit amet ex mollis fringilla.',
    'Mauris tempor elit eget cursus blandit.',
    'Suspendisse a nunc non nulla tincidunt venenatis nec eget risus.',
    'Sed vulputate dolor eu lacinia bibendum.',
    'Etiam accumsan enim non dolor sagittis, vel malesuada leo gravida.',
    'Nullam laoreet sapien quis turpis malesuada, et vestibulum neque tincidunt.',
    'Quisque non nulla tempor erat tristique viverra.',
    'Cras eu eros eget dui porta varius.',
    'Etiam a lorem feugiat augue venenatis facilisis id eu risus.',
    'Aenean ut elit semper, maximus turpis non, luctus mauris.',
    'Aenean varius tellus in vehicula convallis.',
    'Aenean iaculis nisl sit amet velit mollis commodo.',
    'In sed diam eu lorem volutpat convallis.',
    'Proin consequat justo in lorem rutrum, non faucibus elit imperdiet.',
    'Mauris ut tellus at leo euismod efficitur in quis justo.',
    'Donec non turpis volutpat, lobortis lorem venenatis, pellentesque metus.',
    'Phasellus consectetur ligula elementum molestie pulvinar.',
    'Pellentesque nec eros ac dui sollicitudin cursus venenatis posuere sapien.',
    'Morbi iaculis tortor vel pulvinar dictum.',
    'Aliquam pellentesque est efficitur, gravida ipsum ac, malesuada magna.',
    'Nunc dictum nibh ac magna rutrum consequat non at lorem.',
    'Nullam eget tortor et mi sodales ornare.',
    'Sed viverra erat at tellus sodales ultricies.',
    'Etiam in nulla in dolor laoreet tempor congue in dolor.',
    'Vivamus egestas orci ac enim dapibus, ut ultrices metus tristique.',
    'Curabitur hendrerit massa at dui viverra, quis fringilla nisi rhoncus.',
    'Praesent pretium tellus ac lectus placerat, quis interdum nulla tincidunt.',
    'Sed lobortis felis tempus, pharetra mi in, efficitur dolor.',
    'Nam blandit quam sit amet cursus pellentesque.',
    'Nunc eu diam sodales, ornare sem nec, fermentum lacus.',
    'Nam tristique massa eu nisl finibus, a dignissim nunc fringilla.',
    'Pellentesque id massa suscipit, ornare leo in, suscipit leo.',
    'Sed ac velit eu purus porta tincidunt ut tempus lectus.',
    'Vivamus at nisi vel magna tincidunt ultricies.',
    'Mauris ac massa et sapien consectetur elementum eget nec est.',
    'Proin a sapien non neque condimentum lobortis.',
    'Vivamus sed lacus quis eros auctor vehicula.',
    'Curabitur vitae quam eu risus faucibus viverra.',
    'Pellentesque convallis ante sit amet nisl iaculis tempor.',
    'Nullam non sem ut erat aliquam ultrices.',
    'Duis dapibus nisl non varius blandit.',
    'Suspendisse pretium ipsum eu erat iaculis, sed pharetra ex cursus.',
    'Nam mollis dolor ac dui ultricies cursus.',
    'Sed a arcu a velit rutrum rhoncus.',
    'Sed congue lacus mattis dolor pharetra tincidunt.',
    'Nulla lobortis felis eget sem hendrerit sodales.',
    'Fusce maximus neque sed porta dapibus.',
    'Donec sodales dui ac mi bibendum, vehicula congue lacus tincidunt.',
    'Proin nec arcu vel enim hendrerit mollis et in massa.',
    'Aliquam ut sapien porta, lobortis lectus in, rhoncus mauris.',
    'Sed quis diam ac lacus porta consequat.',
    'Phasellus porta elit sed dignissim efficitur.',
    'Integer iaculis nisi in est luctus condimentum.',
    'Nullam sed lectus eget neque mattis dictum.',
    'Vivamus vitae metus malesuada, elementum ligula a, molestie ligula.',
    'Quisque ut velit congue quam hendrerit mattis at eu arcu.',
    'Curabitur venenatis diam et mattis bibendum.',
    'Nulla at libero dignissim, cursus dolor laoreet, rhoncus neque.',
    'In lobortis quam a nisi condimentum cursus sit amet eget dolor.',
    'Cras iaculis justo ut convallis facilisis.',
    'Nulla ut nisi eu est hendrerit imperdiet sed sit amet arcu.',
    'Etiam at dolor at mi elementum pellentesque.',
    'Pellentesque id ante accumsan, ornare sapien non, malesuada eros.',
    'Aenean et ex quis mi blandit consectetur.',
    'Etiam vel arcu finibus, malesuada elit vel, faucibus diam.',
    'Fusce elementum risus quis interdum mollis.',
    'Suspendisse ac diam porttitor, hendrerit orci a, fringilla nulla.',
    'Nam in eros non orci sodales malesuada.',
    'Proin luctus leo a efficitur ornare.',
    'Aenean cursus ex vel lorem convallis, commodo efficitur est elementum.',
    'Sed cursus justo eleifend, porttitor velit ac, fermentum orci.',
    'Fusce commodo arcu nec fermentum semper.',
    'Morbi id felis tempus, rhoncus purus in, cursus lorem.',
    'Nunc posuere diam eu tellus aliquam sodales.',
    'Fusce dapibus lorem vel lacinia aliquet.',
    'Sed dignissim nisl in ullamcorper dictum.',
    'Donec a nisl vel diam consectetur egestas nec a justo.',
    'Nam nec urna in mauris efficitur ultricies in ac diam.',
    'Proin porttitor eros vitae arcu mollis gravida.',
    'Ut vel sapien dignissim, facilisis augue blandit, maximus lacus.',
    'Quisque nec nisi convallis, cursus justo sed, posuere quam.',
    'Fusce sed nisl a enim placerat semper.',
    'Proin non nibh eu tortor aliquet pulvinar.',
    'Morbi ut dolor vel massa faucibus interdum in quis lorem.',
    'Mauris rhoncus dui vel quam dictum egestas.',
    'Nulla et arcu et tellus lacinia egestas.',
    'Sed in tellus tincidunt, auctor est et, lacinia magna.',
    'Donec et lorem sit amet dui malesuada sollicitudin sit amet in magna.',
    'Nam mattis eros vitae risus pulvinar, sit amet efficitur sem porttitor.',
    'Ut egestas massa sit amet mollis congue.',
    'Nulla in mauris eu tortor lobortis rhoncus.',
    'Praesent id elit vulputate, bibendum sem at, vulputate tortor.',
    'Vestibulum mattis nisl id odio imperdiet finibus.',
    'Cras rutrum urna et nibh tincidunt, vitae venenatis justo scelerisque.',
    'Mauris egestas mi eget mauris accumsan mattis.',
    'Quisque porta nisl vel dui commodo, id rhoncus ipsum interdum.',
    'Curabitur cursus sapien at libero tempus suscipit.',
    'Vestibulum nec odio in tellus blandit laoreet quis hendrerit ligula.',
    'Nullam ac enim a est iaculis finibus ut vel mauris.',
    'Fusce ullamcorper lacus nec massa luctus, at tempor neque aliquam.',
    'Mauris pharetra turpis eget dolor dapibus interdum.',
    'Etiam bibendum sem at fermentum congue.',
    'Aliquam semper turpis quis mi suscipit, ut ultricies ipsum elementum.',
    'Duis cursus nisi id sapien maximus ullamcorper.',
    'Praesent ullamcorper arcu at ipsum congue, nec consequat sem faucibus.',
    'Vestibulum quis odio iaculis, commodo sapien eget, egestas lorem.',
    'Fusce tempor ipsum a commodo volutpat.',
    'Nunc pulvinar lectus vitae enim porttitor, quis tempus nunc ultricies.',
    'Duis et urna condimentum, tempor neque at, sollicitudin neque.',
    'Fusce id libero vel orci iaculis ultrices sed sit amet erat.',
    'Praesent pulvinar erat id ligula lobortis laoreet.',
    'Phasellus accumsan nulla maximus nibh blandit pharetra.',
    'Sed suscipit quam id sem congue lacinia.',
    'Praesent auctor orci at enim pulvinar, et accumsan nunc auctor.',
    'Praesent cursus sem at felis maximus aliquet.',
    'Donec volutpat quam eget vehicula condimentum.',
    'Nunc tempus nulla in ipsum sagittis tincidunt.',
    'Vivamus porta mi a justo molestie efficitur ut a tortor.',
    'Vivamus et arcu vel enim feugiat accumsan sit amet nec purus.',
    'Suspendisse et metus pharetra, iaculis dolor quis, malesuada quam.',
    'Sed at urna sit amet augue tempus dapibus.',
    'Vestibulum euismod diam sed augue dapibus, in bibendum augue iaculis.',
    'Vestibulum at justo sed nulla mollis pharetra et quis nunc.',
    'Sed in purus imperdiet, euismod eros vitae, laoreet augue.',
    'Mauris accumsan diam lobortis urna pulvinar mollis.',
    'Curabitur dapibus tortor eget eleifend fermentum.',
    'Duis dictum arcu et magna blandit tempus.',
    'In nec augue quis nunc scelerisque semper.',
    'Nunc vestibulum mauris a commodo accumsan.',
    'Mauris rhoncus justo vel efficitur ultrices.',
    'In imperdiet urna vitae pharetra pulvinar.',
    'Cras commodo mauris at libero ornare suscipit.',
    'Nullam sit amet lacus ut velit vehicula tristique semper a justo.',
    'Duis eget massa sed lorem dictum lacinia.',
    'Nullam ut sem ac lacus malesuada dignissim pellentesque eu augue.',
    'Curabitur convallis orci vitae efficitur varius.',
    'Nunc sit amet quam pellentesque, ultrices leo at, posuere magna.',
    'Phasellus id lacus non eros ullamcorper mattis.',
    'Donec ac ex at purus egestas blandit vitae ac velit.',
    'Duis euismod dui at lorem placerat eleifend.',
    'Curabitur vitae ipsum varius, vehicula odio quis, facilisis justo.',
    'Etiam ornare enim non nulla lobortis, nec lacinia mi volutpat.',
    'Cras tempus neque sed commodo placerat.',
    'Vivamus tempor nisi in eros ultricies, nec volutpat turpis pulvinar.',
    'Aenean blandit sem eu mollis tincidunt.',
    'Vivamus cursus nisi non erat imperdiet facilisis sit amet ac mauris.',
    'Nulla pretium lacus sed ante dapibus cursus.',
    'Sed scelerisque eros in aliquet tempus.',
    'Sed et orci posuere, laoreet ante malesuada, aliquam orci.',
    'In vestibulum nibh at tellus mattis, vel sollicitudin ligula dapibus.',
    'Etiam eu turpis id velit dapibus pharetra eget quis velit.',
    'Quisque eu turpis condimentum, mattis nulla suscipit, venenatis dolor.',
    'Phasellus ultrices ex non malesuada vulputate.',
    'Quisque et sem ac mauris elementum dapibus.',
    'Nulla vel mauris pharetra, tristique massa in, fringilla est.',
    'Maecenas ornare turpis vitae felis placerat, vitae volutpat nulla feugiat.',
    'Vestibulum quis mi vel odio rhoncus consectetur.',
    'Proin imperdiet augue et ultrices bibendum.',
    'Maecenas laoreet risus vel turpis congue vehicula.',
    'Fusce pulvinar urna ac felis ultrices, dictum consectetur dui tincidunt.',
    'Fusce non metus nec nunc tempus mollis.',
    'Etiam sodales erat et aliquam tempor.',
    'Etiam euismod nibh ac augue cursus, quis mollis ex gravida.',
    'Integer a eros vitae velit tempus semper.',
    'Cras id odio efficitur, laoreet libero ut, maximus quam.',
    'Nullam ut ipsum dignissim velit pellentesque convallis.',
    'In imperdiet ligula non eros sollicitudin, nec aliquam orci feugiat.',
    'Etiam id eros vel orci semper interdum.',
    'Phasellus hendrerit risus sed rhoncus vulputate.',
    'Praesent vel felis sed leo cursus maximus at faucibus diam.',
    'Cras non ex aliquam purus vestibulum pharetra vitae a dui.',
    'Vestibulum mattis mi quis ligula malesuada dictum.',
    'Quisque iaculis nisi finibus, ornare est sed, dignissim enim.',
    'Phasellus dignissim nunc et pellentesque tempor.',
    'Integer eget orci viverra, sagittis enim id, consequat ex.',
    'Donec quis metus nec erat gravida pretium.',
    'Praesent eget libero viverra, accumsan dolor aliquam, condimentum est.',
    'Maecenas ac eros vitae sapien posuere molestie.',
    'Proin scelerisque felis ultrices lorem dictum, ac pulvinar magna consequat.',
    'Donec congue justo eget iaculis interdum.',
    'Donec mattis mauris ut massa vulputate, id commodo purus suscipit.',
    'Proin in arcu gravida, congue tellus eget, elementum felis.',
    'Fusce eget purus vestibulum, rhoncus erat vel, condimentum diam.',
    'Donec et urna suscipit, commodo elit vitae, porta justo.',
    'Nam convallis ante in feugiat accumsan.',
    'Curabitur quis ipsum sed libero tristique lobortis ut non arcu.',
    'Nunc gravida tortor sit amet eleifend facilisis.',
    'Nullam eu sem cursus justo cursus tempus sed vel velit.',
    'Curabitur a enim mattis, dignissim velit sed, porta enim.',
    'Ut eleifend urna venenatis arcu fringilla interdum.',
    'Integer suscipit risus ac lorem malesuada volutpat.',
    'Ut fringilla nisi eu scelerisque dictum.',
    'Suspendisse luctus justo at purus accumsan pharetra.',
    'Curabitur varius enim a lorem blandit feugiat.',
    'Maecenas ut velit a purus tristique facilisis.',
    'Donec consequat nisi vitae libero aliquam aliquet.',
    'Duis vitae lectus et diam vehicula sodales.',
    'Phasellus convallis metus vitae dolor varius gravida.',
    'Ut vitae tortor bibendum, tincidunt nibh et, auctor tellus.',
    'Quisque ac purus imperdiet, hendrerit magna vitae, tempor ligula.',
    'Integer luctus ligula sit amet nisl commodo, in imperdiet massa ultrices.',
    'Quisque non metus sollicitudin, vestibulum massa sed, iaculis enim.',
    'Suspendisse mattis nisi eget ante elementum commodo.',
    'Quisque malesuada quam non maximus sodales.',
    'In posuere nunc eget metus pharetra, nec luctus est pretium.',
    'Praesent bibendum erat in tortor ornare, et ultrices elit lacinia.',
    'Maecenas eu dolor posuere, feugiat magna at, faucibus diam.',
    'Quisque quis lectus ornare, maximus leo ac, elementum ipsum.',
    'Curabitur et mauris eu odio faucibus aliquam eu at orci.',
    'Aenean ut magna vitae justo suscipit volutpat.',
    'Maecenas molestie lacus dapibus, cursus magna eu, varius nulla.',
    'Ut facilisis augue vel augue rhoncus tincidunt.',
    'Nullam eleifend mauris ut erat efficitur, at hendrerit neque elementum.',
    'Sed et diam tempor, dignissim augue vitae, venenatis erat.',
    'Donec rutrum purus ut eleifend varius.',
    'Fusce in lacus id nunc rhoncus condimentum.',
    'Praesent convallis quam vel pharetra pulvinar.',
    'Quisque malesuada augue a lorem euismod posuere.',
    'Vestibulum mollis est vitae eros vulputate, nec sollicitudin erat mollis.',
    'Integer convallis felis sit amet semper aliquam.',
    'Mauris maximus erat id massa maximus mattis.',
    'Integer volutpat ex a felis ultricies tempor.',
    'Sed fringilla dui vitae ipsum mattis, ut luctus dolor ultricies.',
    'Etiam id felis sollicitudin, suscipit ipsum vel, efficitur nunc.',
    'Nullam pellentesque eros id mattis interdum.',
    'Vivamus sodales augue vel mauris posuere, nec viverra justo ornare.',
    'Donec dignissim enim fermentum tempus viverra.',
    'Morbi maximus turpis a semper vulputate.',
    'Aliquam et justo eu arcu auctor lobortis quis vel odio.',
    'Vivamus consequat urna vel leo luctus, non suscipit metus interdum.',
    'Phasellus scelerisque ante sit amet nibh malesuada hendrerit.',
    'Vestibulum fermentum neque at vehicula consequat.',
    'Quisque in quam id nisl dictum vestibulum.',
    'Morbi posuere odio vitae elit consequat scelerisque.',
  ];

  constructor() {}

  generateAnswerers(
    n: number,
    defaultSurvey: string,
    questions: { [survey: string]: QuestionData[] } = {},
    outcomes: { [survey: string]: OutcomeData[] } = {},
    locales: { [survey: string]: LocalesData } = {},
    release: { [survey: string]: ReleaseData } = {},
  ) {
    this.responses = [];
    for (let i = 0; i < n; i++) {
      const answererId: number = 10000000 + Math.floor(Math.random() * 90000000);
      const time: string = new Date(
        ((d) => new Date(d.setDate(d.getDate() - Math.floor(Math.random() * 20))))(new Date()),
      ).toISOString();
      const lang: string =
        locales && locales[defaultSurvey] && locales[defaultSurvey].config
          ? Object.keys(locales[defaultSurvey].config)[
              Math.floor(Math.random() * Object.keys(locales[defaultSurvey].config).length)
            ]
          : '';
      const locationObj: ShareLinkData | null =
        release && release[defaultSurvey] && release[defaultSurvey].shareLinks && Math.random() > 0.3
          ? release[defaultSurvey].shareLinks[
              Math.floor(Math.random() * Object.keys(release[defaultSurvey].shareLinks).length)
            ]
          : null;
      const location: string =
        locationObj != null
          ? locationObj.url
          : release && release[defaultSurvey] && release[defaultSurvey].url
          ? release[defaultSurvey].url
          : '';
      const hashtags: string[] =
        locationObj != null && locationObj.tags != null ? locationObj.tags.split(',').filter((item) => item) : [];

      this.responses[i] = { id: answererId, time };
      this.responses[i]['result'] = this.randomOutcome(outcomes[defaultSurvey]);
      this.responses[i]['answers'] = {};
      this.responses[i]['survey'] = defaultSurvey;
      this.responses[i]['language'] = lang;
      this.responses[i]['location'] = location;
      this.responses[i]['hashtags'] = hashtags;

      for (const survey in questions) {
        for (const question of questions[survey]) {
          const type = question.type;
          const answerId: number = 10000000 + Math.floor(Math.random() * 90000000);
          const randomizedAnswer: any = this.randomAnswer(questions[survey], question);

          const answer: AnswerData = {
            id: answerId,
            survey,
            question: question.$key,
            part: 0,
            answerer: answererId,
            collector: 'collector',
            value: randomizedAnswer,
            scaled: randomizedAnswer,
            zValue: null,
            time,
            scoring: 'scoring',
          };

          if (type === 'group-list' || type === 'group-cards' || type === 'group-scored') {
            // this.responses[i]['answers'][question.$key] = {};
          } else {
            this.responses[i]['answers'][question.$key] = answer;
          }
        }
      }
    }

    return this.responses;
  }

  randomAnswer(questions, question): string {
    const slidervalues = this.parseSliderData(questions, question).values;

    if (
      question.type === Questions.SLIDER_1D ||
      question.type === Questions.SLIDER_1V ||
      question.type === Questions.INPUT_NUMERIC
    ) {
      const step: number = slidervalues.sliderValues.step == null ? 1 : slidervalues.sliderValues.step;
      const min: number = slidervalues.sliderValues.min == null ? 0 : slidervalues.sliderValues.min;
      const max: number = slidervalues.sliderValues.max == null ? 100 : slidervalues.sliderValues.max;
      const range: number = (max - min) / step;
      const value: number = min + Math.round(this.gaussianRandom(0, range)) * step;

      return (Math.round(value * this.rounder) / this.rounder).toString();
    } else if (question.type === Questions.SLIDER_NPS) {
      const step: number = 1;
      const min: number = 0;
      const max: number = 10;
      const range: number = (max - min) / step;
      const value: number = min + Math.round(this.npsRandom(0, range)) * step;

      return (Math.round(value * this.rounder) / this.rounder).toString();
    } else if (question.type === Questions.SLIDER_2D) {
      const stepX: number = slidervalues.sliderValuesX.step == null ? 1 : slidervalues.sliderValuesX.step;
      const minX: number = slidervalues.sliderValuesX.min == null ? 0 : slidervalues.sliderValuesX.min;
      const maxX: number = slidervalues.sliderValuesX.max == null ? 100 : slidervalues.sliderValuesX.max;

      const stepY: number = slidervalues.sliderValuesY.step == null ? 1 : slidervalues.sliderValuesY.step;
      const minY: number = slidervalues.sliderValuesY.min == null ? 0 : slidervalues.sliderValuesY.min;
      const maxY: number = slidervalues.sliderValuesY.max == null ? 100 : slidervalues.sliderValuesY.max;

      const rangeX: number = (maxX - minX) / stepX;
      const valueX: number = minX + Math.round(this.gaussianRandom(0, rangeX)) * stepX;

      const rangeY: number = (maxY - minY) / stepY;
      const valueY: number = minY + Math.round(this.gaussianRandom(0, rangeY)) * stepY;

      return (
        (Math.round(valueX * this.rounder) / this.rounder).toString() +
        ';' +
        (Math.round(valueY * this.rounder) / this.rounder).toString()
      );
    } else if (question.type === Questions.SLIDER_1R) {
      const stepX: number = slidervalues.sliderValues.step == null ? 1 : slidervalues.sliderValues.step;
      const minX: number = slidervalues.sliderValues.min == null ? 0 : slidervalues.sliderValues.min;
      const maxX: number = slidervalues.sliderValues.max == null ? 100 : slidervalues.sliderValues.max;

      const stepY: number = slidervalues.sliderValues.step == null ? 1 : slidervalues.sliderValues.step;
      const minY: number = slidervalues.sliderValues.min == null ? 0 : slidervalues.sliderValues.min;
      const maxY: number = slidervalues.sliderValues.max == null ? 100 : slidervalues.sliderValues.max;

      const rangeX: number = (maxX - minX) / stepX;
      const valueX: number = minX + Math.round(this.gaussianRandom(0, rangeX / 2)) * stepX;

      const rangeY: number = (maxY - minY) / stepY;
      const valueY: number = minY + Math.round(this.gaussianRandom(rangeY / 2, rangeY)) * stepY;

      return (
        (Math.round(valueX * this.rounder) / this.rounder).toString() +
        ';' +
        (Math.round(valueY * this.rounder) / this.rounder).toString()
      );
    } else if (Questions.category(question, Questions.CHOICE) || question.type === Questions.INPUT_DROPDOWN) {
      if (question.choiceList && question.choiceList.length > 0) {
        let choiceArr: string[] = question.choiceList ? question.choiceList.slice().map((choice) => choice.$key) : [];
        const randomDelete: number = Math.floor(Math.random() * Object.keys(question.choiceList).length - 0.001);
        if ((question.choiceLimit || 1) === 1) {
          const random: number = Math.floor(Math.random() * choiceArr.length - 0.001);
          choiceArr = [choiceArr[random]];
        } else {
          for (let i = 0; i < randomDelete; i++) {
            const random: number = Math.floor(Math.random() * choiceArr.length - 0.001);
            choiceArr.splice(random, 1);
          }
        }
        const newChoiceArr: string[] = choiceArr.map((choice) => {
          if (choice != null && question.choiceList.find((c) => c.$key === choice).comment) {
            return `${choice}=${this.randomComments[Math.floor(Math.random() * 101)].replace(/,/g, '')}`;
          } else {
            return choice;
          }
        });
        const choices: string = newChoiceArr.toString();

        return choices.replace(/,/g, ';');
      } else {
        return '';
      }
    } else if (question.type === Questions.INPUT_STRING || question.type === Questions.FREE_TEXT) {
      let answer: string | null = null;

      if (Math.random() > 0.8) {
        answer = '';
        for (let i = 0, len = Math.ceil(Math.random() * 5); i < len; i++) {
          answer += (i > 0 ? ' ' : '') + this.randomComments[Math.floor(Math.random() * 101)];
        }
      }

      return answer;
    } else if (question.type === Questions.INPUT_PHONE) {
      return (
        `+35812345` +
        Math.floor(Math.random() * 9.9) +
        Math.floor(Math.random() * 9.9) +
        Math.floor(Math.random() * 9.9) +
        Math.floor(Math.random() * 9.9) +
        Math.floor(Math.random() * 9.9)
      );
    } else if (question.type === Questions.INPUT_EMAIL) {
      const letters = 'abcdefghijklmnopqrstuvwxyz';
      const len = Math.ceil(Math.random() * 10);
      let result = '';

      for (let i = 0; i < len; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
      }
      result += '@';

      const lend = Math.ceil(Math.random() * 10);

      for (let i = 0; i < lend; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
      }

      result += Math.random() > 0.5 ? '.com' : '.net';

      return result;
    } else if (question.type === Questions.INPUT_URL) {
      const letters = 'abcdefghijklmnopqrstuvwxyz';
      const len = Math.ceil(Math.random() * 10);
      let result = 'http://www.';

      for (let i = 0; i < len; i++) {
        result += letters.charAt(Math.floor(Math.random() * letters.length));
      }

      result += Math.random() > 0.5 ? '.com' : '.net';

      return result;
    } else if (question.type === Questions.INPUT_NUMBER) {
      return `${Math.round(Math.random() * 100)}`;
    } else if (question.type === Questions.INPUT_CHECKBOX) {
      return `${Math.random() > 0.5 ? 'true' : 'false'}`;
    } else {
      return `Smthng`;
    }
  }

  randomOutcome(outcomes) {
    if (outcomes?.length > 0) {
      const randomNumber: number = Math.floor(Math.random() * outcomes.length);
      return { outcome: outcomes[randomNumber]['$key'], score: 1 };
    } else {
      return null;
    }
  }

  gaussianRand(): number {
    let rand: number = 0;

    for (let i = 0; i < 3; i++) {
      rand += Math.random();
    }

    return rand / 2.25;
  }

  positiveRand(): number {
    let rand: number = 0;

    for (let i = 0; i < 4; i++) {
      rand += Math.random();
    }

    return rand / 2.3;
  }

  gaussianRandom(start, end): number {
    const fivePercent: boolean = Math.random() > 0.95;
    const rand: number = Math.floor(start + this.gaussianRand() * (end - start + 1));
    return rand > end ? end : rand < start ? start : fivePercent ? 0 : rand;
  }

  npsRandom(start, end): number {
    const threePercent: boolean = Math.random() > 0.97;
    const fortyPercent: boolean = Math.random() > 0.6;
    const rand: number = Math.floor(start + this.positiveRand() * (end - start + 1));
    return rand > end ? (fortyPercent ? 0.9 : 1) * end : rand < start ? start : threePercent ? 0 : rand;
  }

  /**
   * Parses slider labels and values to selected question.
   *
   * @param  questionsList
   * @param  target       Target question key.
   * @returns  object         Object that contains labels and values.
   */
  public parseSliderData(questions: QuestionData[], question: QuestionData) {
    const groupIndex = questions.findIndex((x) => x.$key === question.group);

    let values: any;
    let labels: any;

    if (
      groupIndex >= 0 &&
      questions[groupIndex].type === Questions.GROUP_SCORED &&
      question.type !== Questions.INPUT_NUMERIC &&
      question.type !== Questions.SLIDER_NPS &&
      question.type !== Questions.SLIDER_1R
    ) {
      labels = {
        sliderLabels: questions[groupIndex].sliderLabels ? questions[groupIndex].sliderLabels : new SliderLabelsData(),
        sliderLabelsX: questions[groupIndex].sliderLabelsX
          ? questions[groupIndex].sliderLabelsX
          : new SliderLabelsData(),
        sliderLabelsY: questions[groupIndex].sliderLabelsY
          ? questions[groupIndex].sliderLabelsY
          : new SliderLabelsData(),
      };

      values = {
        sliderValues: questions[groupIndex].sliderValues ? questions[groupIndex].sliderValues : new SliderValuesData(),
        sliderValuesX: questions[groupIndex].sliderValuesX
          ? questions[groupIndex].sliderValuesX
          : new SliderValuesData(),
        sliderValuesY: questions[groupIndex].sliderValuesY
          ? questions[groupIndex].sliderValuesY
          : new SliderValuesData(),
      };
    } else {
      labels = {
        sliderLabels: question.sliderLabels ? question.sliderLabels : new SliderLabelsData(),
        sliderLabelsX: question.sliderLabelsX ? question.sliderLabelsX : new SliderLabelsData(),
        sliderLabelsY: question.sliderLabelsY ? question.sliderLabelsY : new SliderLabelsData(),
      };

      values = {
        sliderValues: question.sliderValues ? question.sliderValues : new SliderValuesData(),
        sliderValuesX: question.sliderValuesX ? question.sliderValuesX : new SliderValuesData(),
        sliderValuesY: question.sliderValuesY ? question.sliderValuesY : new SliderValuesData(),
      };
    }

    return { labels, values };
  }
}

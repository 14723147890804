import { Injectable } from '@angular/core';

import { Store } from '@ngxs/store';

import { OpenSidenav } from '@shared/states/dialog.actions';
import { SurveyState } from '@shared/states/survey.state';
import { assertArray } from '@shared/utilities/array.utilities';
import { SurveyIntegrationSidenav } from '@shared/modules/survey-integration/components/survey-integration-sidenav.component';

@Injectable({
  providedIn: 'root',
})
export class SurveyIntegrationService {
  constructor(private store: Store) {}

  openIntegration(key?: string, activeQuestions?: string | string[]): void {
    key = key || this.store.selectSnapshot(SurveyState.activeSurveyKey);

    this.store.dispatch(
      new OpenSidenav(SurveyIntegrationSidenav, {
        data: { surveyKey: key, activeQuestions: assertArray(activeQuestions) },
      }),
    );
  }
}

import { environment } from '@env/environment';

import { ChangeDetectionStrategy, Component, NgModuleRef, ViewContainerRef } from '@angular/core';
import { SurveyType } from '@editor/shared/models/template.model';
import { Select, Store } from '@ngxs/store';
import { RouteAnimation } from '@shared/animations/route-animation.anim';
import { TemplateModel } from '@shared/models/survey.model';
import { PrefsState } from '@shared/states/prefs.state';
import {
  FilterTemplates,
  ResetTemplatesFilter,
  ToggleTemplatesFilter,
  DiscoverTemplate,
} from '@shared/states/templates.actions';
import { TemplatesState } from '@shared/states/templates.state';
import { Observable } from 'rxjs';
import { HelpSubject } from '@shared/modules/help-center/help-subject.enum';
import { AuthState } from '@shared/states/auth.state';

@Component({
  selector: 'zef-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss'],
  animations: [RouteAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscoverPage {
  readonly subjects = HelpSubject;

  @Select(TemplatesState.filteredTemplates)
  readonly templates$: Observable<TemplateModel[]>;

  @Select(TemplatesState.localeCategories)
  readonly categories$: Observable<SurveyType[]>;

  @Select(TemplatesState.templateFilter)
  readonly templateFilter$: Observable<any>;

  @Select(TemplatesState.selectedTemplate)
  readonly selectedTemplate$: Observable<string>;

  @Select(AuthState.isAnonymous)
  readonly isAnonymous$: Observable<boolean>;

  @Select(PrefsState.isMobile)
  readonly isMobile$: Observable<boolean>;

  constructor(private store: Store, private ng: NgModuleRef<any>, private vc: ViewContainerRef) {}

  requestDemo() {
    const language = this.store.selectSnapshot(PrefsState.language);
    const url =
      language === 'fi'
        ? `https:${environment.wwwAddress}/fi/yhteystiedot/`
        : `https:${environment.wwwAddress}/contact`;
    window.open(url, '_blank');
  }

  filterByTerm(query: string) {
    this.store.dispatch(new FilterTemplates(query));
  }

  filterByComplexity(complexity: any) {
    this.store.dispatch(new FilterTemplates(null, null, null, complexity));
  }

  filterByCategory(category: string) {
    this.store.dispatch(new FilterTemplates(null, category));
  }

  filterByLanguage(code: string) {
    this.store.dispatch(new FilterTemplates(null, '', code));
  }

  resetFilter(value: boolean) {
    this.store.dispatch(new ResetTemplatesFilter());
  }

  toggleFilter(value: boolean) {
    this.store.dispatch(new ToggleTemplatesFilter(value));
  }

  discoverTemplate(templateKey: string, templates: TemplateModel[]) {
    this.store.dispatch(
      new DiscoverTemplate(templateKey, templates, 'intro', this.ng.componentFactoryResolver, this.vc),
    );
  }

  trackByFn(index: number, template: TemplateModel) {
    return template?.survey?.$key || index;
  }
}

import {
  BuilderData,
  ChoiceLogic,
  DateTimeLogic,
  InputLogic,
  LogicItemStatement,
  LogicOperator,
  LogicStatement,
  OutcomeData,
  QuestionData,
  ScoringData,
  Slider2DLogic,
  SliderLogic,
  TriggerData,
  WhenLogicStatement,
  WithLogic,
} from '@shared/models/survey.model';
import { PlayerAnswers } from '@player/shared/models/player.model';
import { Questions } from '@shared/enums/questions.enum';
import { objectArrayToArray } from '@shared/utilities/object.utilities';
import { isNotEmptyArray } from '@shared/utilities/array.utilities';
import { DateRangePart } from '@shared/modules/date-range/date-range.models';
import { isInFuture, isInPast } from '@shared/utilities/date.utilities';
import { isNotEmptyObject } from 'class-validator';
import { AreaMode } from '@shared/models/utility.model';

/* eslint-disable @typescript-eslint/no-namespace */
export namespace Logic {
  export const logicInput = ({ type }: QuestionData, logic?: LogicStatement): logic is InputLogic =>
    [
      Questions.FREE_TEXT,
      Questions.INPUT_URL,
      Questions.INPUT_EMAIL,
      Questions.INPUT_PHONE,
      Questions.INPUT_NUMBER,
      Questions.INPUT_STRING,
      Questions.INPUT_CHECKBOX,
      Questions.FILE_UPLOAD,
    ].includes(type);

  export const logicSingleChoice = ({ type, choiceLimit }: QuestionData): boolean =>
    [Questions.INPUT_DROPDOWN, Questions.INPUT_NUMERIC].includes(type) ||
    (Questions.choices.includes(type) && choiceLimit === 1);

  export const logicMultipleChoice = ({ type, choiceLimit }: QuestionData): boolean =>
    Questions.choices.includes(type) && choiceLimit !== 1;

  export const logicChoice = (question: QuestionData, logic?: LogicStatement): logic is ChoiceLogic =>
    logicSingleChoice(question) || logicMultipleChoice(question);

  export const logicSlider1D = ({ type }: QuestionData, logic?: LogicStatement): logic is SliderLogic =>
    [Questions.SLIDER_1V, Questions.SLIDER_1D].includes(type);

  export const logicSlider1R = ({ type }: QuestionData, logic?: LogicStatement): logic is SliderLogic =>
    type === Questions.SLIDER_1R;

  export const logicSlider1 = (q: QuestionData, logic?: LogicStatement): logic is SliderLogic =>
    logicSlider1D(q) || logicSlider1R(q);

  export const logicSlider2D = ({ type }: QuestionData, logic?: LogicStatement): logic is Slider2DLogic =>
    type === Questions.SLIDER_2D;

  export const logicSliderNPS = ({ type }: QuestionData, logic?: LogicStatement): logic is SliderLogic =>
    type === Questions.SLIDER_NPS;

  export const logicCheckbox = ({ type }: QuestionData, logic?: LogicStatement): logic is InputLogic =>
    type === Questions.INPUT_CHECKBOX;

  export const logicInfo = ({ type }: QuestionData, logic?: LogicStatement): logic is InputLogic =>
    type === Questions.INFO_TEXT;

  export const logicable = (q: QuestionData): boolean =>
    logicInput(q) ||
    logicChoice(q) ||
    logicSlider1(q) ||
    logicSlider2D(q) ||
    logicSliderNPS(q) ||
    logicInfo(q) ||
    Questions.group(q);

  export const showItemLogic = (
    key: string,
    { logic }: LogicItemStatement,
    questions: QuestionData[],
    answerData: PlayerAnswers,
  ): boolean => {
    if (!logic) {
      return true;
    }

    const item = questions.find((question) => question.$key === key);
    let answer = answerData && answerData[key];

    if (!item || answer == null) {
      return false;
    }

    answer = answer.toString();

    if (logicCheckbox(item, logic)) {
      return answer === logic.answer.toString();
    } else if (logicInput(item, logic)) {
      return !!answer === logic.answer;
    } else if (logicChoice(item, logic)) {
      const answers = answer.split(';');

      const logicTest = (answerChoice: string) =>
        answerChoice === 'other' ? answers.some((a) => a.startsWith('other=')) : answers.includes(answerChoice);
      const isOr = logic.operator === LogicOperator.Or || !Questions.isChoice(item) || (item.choiceLimit || 1) === 1;

      const choiceAnswers = objectArrayToArray<string>(logic.answers).map((a) => a.toString());

      return isOr ? choiceAnswers.some((a) => logicTest(a)) : choiceAnswers.every((a) => logicTest(a));
    } else if (logicSliderNPS(item, logic)) {
      const answerNr = Math.round(parseFloat(answer));

      if (Number.isNaN(answerNr)) {
        return false;
      }

      return logic.min <= answerNr && logic.max >= answerNr;
    } else if (logicSlider1D(item, logic)) {
      const answerNr = item.type === Questions.SLIDER_NPS ? Math.round(parseFloat(answer)) : parseFloat(answer);

      if (Number.isNaN(answerNr)) {
        return false;
      }

      return logic.min <= answerNr && logic.max >= answerNr;
    } else if (logicSlider1R(item, logic)) {
      const [min, max] = answer.split(';').map((a) => parseFloat(a));

      if (Number.isNaN(min) || Number.isNaN(max)) {
        return false;
      }

      return logic.min <= min && logic.max >= max;
    } else if (logicSlider2D(item, logic)) {
      const [x, y] = answer.split(';').map((a) => parseFloat(a));

      if (Number.isNaN(x) || Number.isNaN(y)) {
        return false;
      }

      const inXRange = logic.minX <= x && logic.maxX >= x;
      const inYRange = logic.minY <= y && logic.maxY >= y;

      return (logic.mode === AreaMode.XX || inYRange) && (logic.mode === AreaMode.YY || inXRange);
    }

    return false;
  };

  export const showItem = (
    item: WithLogic,
    questions: QuestionData[],
    answers: PlayerAnswers,
    def: boolean = true,
  ): boolean => {
    const entries = Object.entries(item.showIf || {});

    if (entries.length === 0) {
      return def;
    }

    // can only be all AND or all OR
    if (entries[0][1].operator === LogicOperator.And) {
      return entries.every(([key, logic]) => showItemLogic(key, logic, questions, answers));
    } else {
      return entries.some(([key, logic]) => showItemLogic(key, logic, questions, answers));
    }
  };

  export const showOutcome = (
    outcome: OutcomeData,
    questions: QuestionData[],
    answers: PlayerAnswers,
    date: Date,
  ): boolean => {
    let show = showItem(outcome, questions, answers);
    const hasShowWhen = isNotEmptyArray(outcome.showWhen);

    if (hasShowWhen && (show || outcome.showWhen[0].operator === LogicOperator.Or)) {
      show = isDateInWhenRange(date, outcome.showWhen);
    }

    return show;
  };

  export const showQuestion = (
    question: QuestionData,
    questions: QuestionData[],
    answers: PlayerAnswers,
    shown: string[],
    date: Date,
  ): boolean => {
    const entries = Object.entries(question.showIf || {});
    const showGroup = !question.group || shown.includes(question.group);
    const hasShowWhen = isNotEmptyArray(question.showWhen);

    if (entries.length === 0 && !hasShowWhen) {
      return showGroup;
    }

    let checkLogic;
    let show = showGroup;

    if (entries.length) {
      if (entries[0][1].operator === LogicOperator.And) {
        checkLogic = showGroup && entries.every(([key]) => shown.includes(key));
      } else {
        checkLogic = showGroup || entries.some(([key]) => shown.includes(key));
      }

      show = checkLogic && showItem(question, questions, answers);
    }

    if (hasShowWhen && (show || question.showWhen[0].operator === LogicOperator.Or)) {
      show = isDateInWhenRange(date, question.showWhen);
    }

    return show;
  };

  export const isDateInWhenRange = (date: Date, showWhen: WhenLogicStatement[]): boolean => {
    if (!isNotEmptyArray(showWhen)) {
      return true;
    }

    return showWhen.some((when: WhenLogicStatement) => {
      if (when.logic?.dateFrom && isInPast(date, dateTimeLogicToDate(when.logic, 'from'))) {
        return false;
      }

      return !(when.logic?.dateUntil && isInFuture(date, dateTimeLogicToDate(when.logic, 'until')));
    });
  };

  export const dateTimeLogicToDate = (logic: DateTimeLogic, part: DateRangePart): Date => {
    const isFrom = part === 'from';
    const time = logic[isFrom ? 'timeFrom' : 'timeUntil'];
    let date = logic[isFrom ? 'dateFrom' : 'dateUntil'];
    date = new Date(date);

    const hour = time?.hour ?? (isFrom ? 0 : 24);
    const minute = time?.minute || 0;
    date.setHours(hour, minute);

    return date;
  };

  export const hasShowIfLogic = (item?: WithLogic): boolean =>
    Object.values(item?.showIf || {}).some((showIf) => isNotEmptyObject(showIf.logic));

  export const hasShowWhenLogic = (item?: WithLogic): boolean =>
    isNotEmptyArray(item?.showWhen?.filter((showWhen) => !!showWhen.logic));

  export const hasLogic = (item?: WithLogic): boolean => hasShowIfLogic(item) || hasShowWhenLogic(item);

  export const isLogicUpdate = (question: QuestionData, update: Partial<QuestionData>): boolean => {
    const updatePaths = Object.keys(update || {});
    const updateKeys = updatePaths.map((key) => key.split('/')).reduce((a, b) => a.concat(b), []);

    if (logicable(question) && updateKeys.includes('type')) {
      return true;
    } else if (logicChoice(question)) {
      if (update.choiceLimit != null && question.choiceLimit !== update.choiceLimit) {
        return true;
      }

      if (
        update.choiceList != null &&
        objectArrayToArray(update.choiceList).length < objectArrayToArray(question.choiceList).length
      ) {
        return true;
      }

      if (question.type === Questions.INPUT_NUMERIC && updateKeys.includes('sliderValues')) {
        return true;
      }
    } else if (logicSlider1(question) || logicSliderNPS(question)) {
      const valuesUpdates = updateKeys.filter((key) => key === 'sliderValues');

      return (valuesUpdates.length > 0 && !updateKeys.includes('visible')) || valuesUpdates.length > 1;
    } else if (logicSlider2D(question)) {
      return updateKeys.includes('sliderValuesX') || updateKeys.includes('sliderValuesY');
    }

    return false;
  };

  export const checkAttached = (
    keys: string[],
    questions: QuestionData[],
    outcomes: OutcomeData[],
    triggers: TriggerData[],
  ): BuilderData[] => {
    const check = ({ showIf, $key }) =>
      !!showIf && !keys.includes($key) && Object.keys(showIf).some((key) => keys.includes(key));

    return [...questions.filter(check), ...outcomes.filter(check), ...triggers.filter(check)];
  };

  export const attached = (
    question: QuestionData,
    questions: QuestionData[],
    outcomes: OutcomeData[],
    triggers: TriggerData[],
    checkScored?: boolean,
  ): BuilderData[] => {
    const keys = Questions.attachedKeys(question, questions, checkScored);

    return checkAttached(keys, questions, outcomes, triggers);
  };

  export const attachedMove = (question: QuestionData, questions: QuestionData[], index: number): QuestionData[] => {
    const keys = Questions.attachedKeys(question, questions);
    const oldIndex = questions.findIndex(({ $key }) => question.$key === $key);
    const movingUp = oldIndex > index;

    if (movingUp) {
      const attachedKeys = questions
        .filter(({ $key, showIf }) => !!showIf && keys.includes($key))
        .map(({ showIf }) => Object.keys(showIf))
        .reduce((a, b) => a.concat(b), []);

      return questions.filter(({ $key }, idx) => !keys.includes($key) && attachedKeys.includes($key) && idx >= index);
    } else {
      return questions.filter(
        ({ showIf, $key }, idx) =>
          !keys.includes($key) && !!showIf && Object.keys(showIf).some((key) => keys.includes(key) && idx < index),
      );
    }
  };

  export const logicToScoring = (logic: LogicStatement, question: QuestionData): ScoringData | null => {
    if (!logic) {
      return null;
    }

    const type = Questions.AnswerTypes[question.type];

    let scoring: string;

    if (logicChoice(question, logic) && logic.answers?.length) {
      scoring = logic.answers.join(';');
    } else if ((logicSliderNPS(question, logic) || logicSlider1D(question, logic)) && logic.min != null) {
      scoring = logic.min.toString();
    } else if (logicSlider1R(question, logic) && logic.min != null && logic.max != null) {
      scoring = [logic.min, logic.max].join(';');
    } else if (logicSlider2D(question, logic) && logic.minX != null && logic.minY != null) {
      scoring = [logic.minX, logic.minY].join(';');
    }

    if (scoring && type) {
      return {
        type,
        value: scoring,
      };
    }

    return null;
  };

  export const scoringToLogic = (scoring: string, question: QuestionData): LogicStatement => {
    if (!scoring) {
      return;
    }

    if (logicChoice(question)) {
      return { answers: scoring.split(';') } as ChoiceLogic;
    }

    if (logicSlider1D(question) || logicSliderNPS(question)) {
      const value = parseFloat(scoring);

      return { min: value, max: value } as SliderLogic;
    }

    if (logicSlider1R(question) || logicSlider2D(question)) {
      const [x, y] = scoring.split(';').map((val) => parseFloat(val));

      if (logicSlider1R(question)) {
        return { min: x, max: y } as SliderLogic;
      } else {
        return {
          minX: x,
          maxX: x,
          minY: y,
          maxY: y,
        } as Slider2DLogic;
      }
    }
  };

  // export const logicToScoring = (logic: LogicStatement): string => {};
}

<!--
  Create thumb with new / sample survey modes.

  @unstable
-->

<div
  *ngIf="!sample"
  class="new-thumb z-fx-col z-fx-center-center z-fx-flex"
  [class.disabled]="(userRole$ | async) < 2"
  (click)="onSurveyCreate()"
  [analyticsTrack]="AnalyticsTracks.SURVEY_CREATE"
>
  <mat-icon>plus</mat-icon>

  <span class="title-text" i18n>Create a new Survey</span>
</div>

<div
  *ngIf="{ survey: survey$ | async, respondents: respondents$ | async, status: status$ | async } as data"
  class="thumb zef-border-radius-l zef-border z-fx-col"
  [class.zef-cursor-pointer]="interactive"
  [class.thumb-interactive]="interactive"
  [class.thumb-active]="thumbActive$ | async"
  [class.thumb-selected]="selected$ | async"
  [class.thumb-selection-mode]="hasSelected$ | async"
  [class.zef-interactive]="hasSelected$ | async"
  (click)="onThumbClick()"
>
  <div
    *ngIf="visible$ | async"
    class="thumb-image z-fx-flex z-fx-col"
    [style.background-image]="backgroundImage$ | async | encodeURI : 'url' | safeStyle"
  >
    <div class="thumb-header z-fx-sc">
      <div class="thumb-status">
        <zef-status-icon [status]="data.status" [type]="'toggle'"></zef-status-icon>

        <mat-checkbox
          *ngIf="interactive"
          color="primary"
          [checked]="selected$ | async"
          (change)="activation.emit('select')"
          (click)="$event.stopPropagation()"
        >
          <button mat-icon-button></button>
        </mat-checkbox>
      </div>

      <div *ngIf="interactive" class="thumb-header-interactions z-fx-flex">
        <div class="thumb-header-interactions-inner z-fx z-fx-between-center">
          <div class="z-fx-sc z-fx-gap-8 zef-text-p1 zef-color-secondary" (click)="$event.stopPropagation()">
            <span
              [analyticsTrack]="Analytics.SURVEY_SHARE"
              class="zef-text-link-hover-underline"
              (click)="activation.emit('share')"
              i18n
            >
              Share
            </span>
            <span
              [analyticsTrack]="Analytics.SURVEY_ANALYZE"
              class="zef-text-link-hover-underline"
              (click)="activation.emit('analyze')"
              i18n
            >
              Analyze
            </span>
          </div>

          <button mat-icon-button [matMenuTriggerFor]="thumbMenu" (click)="$event.stopPropagation()">
            <mat-icon color="secondary">more</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <mat-menu #thumbMenu class="zef-menu-combo" xPosition="before" yPosition="below">
      <ng-template matMenuContent>
        <div
          *ngIf="canEdit$ | async"
          mat-menu-item
          [analyticsTrack]="Analytics.SURVEY_EDIT"
          (click)="activation.emit('edit')"
        >
          <div class="z-fx z-fx-start-center">
            <mat-icon>action_edit</mat-icon>
            <span i18n>Edit</span>
          </div>
        </div>

        <div mat-menu-item (click)="activation.emit('duplicate')">
          <div class="z-fx z-fx-start-center">
            <mat-icon>action_duplicate</mat-icon>
            <span i18n>Duplicate</span>
          </div>
        </div>

        <ng-container *ngIf="canEdit$ | async">
          <mat-divider></mat-divider>

          <div mat-menu-item (click)="activation.emit('move')">
            <div class="z-fx z-fx-start-center">
              <mat-icon>folder</mat-icon>
              <span i18n>Move to folder...</span>
            </div>
          </div>

          <div *ngIf="folder$ | async" mat-menu-item (click)="activation.emit('remove')">
            <div class="z-fx z-fx-start-center">
              <mat-icon>folder_off</mat-icon>
              <span i18n>Remove from folder</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="canEdit$ | async">
          <mat-divider></mat-divider>

          <div mat-menu-item class="alert" (click)="activation.emit('delete')">
            <div class="z-fx z-fx-start-center">
              <mat-icon color="alert">action_delete</mat-icon>
              <span i18n>Delete...</span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </mat-menu>
  </div>

  <div
    *ngIf="(visible$ | async) && data.survey as survey"
    class="thumb-content z-fx-none zef-border-top z-fx-col z-fx-gap-4 zef-text-overflow-line"
  >
    <div class="zef-text-h3 zef-text-ellipsis">
      {{ survey.surveyName }}
    </div>

    <div class="thumb-details-wrapper z-fx z-fx-between-start">
      <div class="thumb-details-more z-fx-sc z-fx-gap-4 z-fx-flex zef-text-p2-muted">
        <mat-icon class="zef-icon-small z-fx-none" color="ink-lightest">contacts</mat-icon>

        <div class="thumb-details-respondents z-fx-flex zef-text-ellipsis" i18n>
          {data.respondents, plural, =1 {{{data.respondents }} Respondent} other {{{data.respondents}} Respondents}}
        </div>
      </div>

      <div class="thumb-details z-fx-sc z-fx-gap-4 z-fx-flex zef-text-p2-muted">
        <span class="zef-text-ellipsis">
          {{ survey.owner }}
        </span>

        <span class="thumb-dot">•</span>

        <span class="zef-text-ellipsis z-fx-none">
          {{ data.respondents }}
        </span>
      </div>

      <div class="thumb-icons z-fx-none">
        <survey-integration-button
          mode="icon"
          [surveyKey]="survey.$key"
          [showWhenIntegrated]="true"
        ></survey-integration-button>

        <button
          *ngIf="folder$ | async as folder"
          mat-icon-button
          [matTooltip]="folder?.name"
          matTooltipPosition="above"
          (click)="activation.emit(folder.$key); $event.stopPropagation()"
        >
          <mat-icon [style.color]="folder.color">folder</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

/**
 * Simple infobox displaying text with closed state support.
 *
 * @unstable
 */

import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { StatesData } from '@shared/models/prefs.model';

import { PrefsManager } from '@shared/services/prefs-manager.service';

@Component({
  selector: 'zef-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Infobox implements OnInit, OnDestroy {
  @Input() id = '';

  public show = false;

  private infoTexts: any = {};

  private statesSub: any = null;

  constructor(private pm: PrefsManager, readonly cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.id) {
      this.show = true;
    } else {
      this.statesSub = this.pm.loadStatesData().subscribe((states: StatesData | null) => {
        this.infoTexts = states ? states.infotexts || {} : {};
        if (!this.infoTexts[this.id]) {
          this.show = true;
          this.cdRef.detectChanges();
        } else if (this.statesSub) {
          this.statesSub.unsubscribe();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.statesSub) {
      this.statesSub.unsubscribe();
    }
  }

  public onCloseAndSave(event: any) {
    this.show = false;

    if (this.id) {
      if (this.statesSub) {
        this.statesSub.unsubscribe();
      }

      this.infoTexts[this.id] = true;

      this.pm.updateStatesData({ infotexts: this.infoTexts });
    }

    this.cdRef.detectChanges();
  }
}

/**
 * Emotions translated.
 *
 * @unstable
 */

export class Emotions {
  static readonly us: { [emotion: string]: string } = {
    admiration: 'admiration',
    amusement: 'amusement',
    anger: 'anger',
    annoyance: 'annoyance',
    approval: 'approval',
    caring: 'caring',
    confusion: 'confusion',
    curiosity: 'curiosity',
    desire: 'desire',
    disappointment: 'disappointment',
    disapproval: 'disapproval',
    disgust: 'disgust',
    embarrassment: 'embarrassment',
    excitement: 'excitement',
    fear: 'fear',
    gratitude: 'gratitude',
    grief: 'grief',
    joy: 'joy',
    love: 'love',
    nervousness: 'nervousness',
    optimism: 'optimism',
    pride: 'pride',
    realization: 'realization',
    relief: 'relief',
    remorse: 'remorse',
    sadness: 'sadness',
    surprise: 'surprise',
    neutral: 'neutral',
  };

  static readonly en: any = Emotions.us;

  static readonly fi: { [emotion: string]: string } = {
    admiration: 'ihailu',
    amusement: 'huvittuneisuus',
    anger: 'viha',
    annoyance: 'harmitus',
    approval: 'hyväksyntä',
    caring: 'välittäminen',
    confusion: 'hämmennys',
    curiosity: 'uteliaisuus',
    desire: 'halu',
    disappointment: 'pettymys',
    disapproval: 'paheksunta',
    disgust: 'inho',
    embarrassment: 'häpeä',
    excitement: 'jännitys',
    fear: 'pelko',
    gratitude: 'kiitollisuus',
    grief: 'syvä suru',
    joy: 'ilo',
    love: 'rakkaus',
    nervousness: 'hermostuneisuus',
    optimism: 'optimismi',
    pride: 'ylpeys',
    realization: 'oivallus',
    relief: 'helpotus',
    remorse: 'katumus',
    sadness: 'suru',
    surprise: 'yllättyneisyys',
    neutral: 'neutraali',
  };

  static readonly sv: { [emotion: string]: string } = {
    admiration: 'beundran',
    amusement: 'nöje',
    anger: 'vrede',
    annoyance: 'irritation',
    approval: 'godkännande',
    caring: 'omtänksam',
    confusion: 'förvirring',
    curiosity: 'nyfikenhet',
    desire: 'önskan',
    disappointment: 'besvikelse',
    disapproval: 'ogillande',
    disgust: 'avsky',
    embarrassment: 'förlägenhet',
    excitement: 'spänning',
    fear: 'rädsla',
    gratitude: 'tacksamhet',
    grief: 'djup sorg',
    joy: 'glädje',
    love: 'kärlek',
    nervousness: 'nervositet',
    optimism: 'optimism',
    pride: 'stolthet',
    realization: 'insikt',
    relief: 'lättnad',
    remorse: 'ånger',
    sadness: 'sorg',
    surprise: 'överraskad',
    neutral: 'neutral',
  };
}

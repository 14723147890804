import { Observable, of } from 'rxjs';
import { concatMap, shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { PlayerApi } from '@player/shared/services/player-api.service';
import { AnswersManager } from '@player/shared/services/answers-manager.service';

import { Logic } from '@shared/enums/logic.enum';
import { QuestionData, TriggerData } from '@shared/models/survey.model';

@Injectable({
  providedIn: 'root',
})
export class TriggerManager {
  team: string;

  triggers: TriggerData[] = [];

  questions: QuestionData[] = [];

  private unloading = false;

  private answeringFinished = false;

  private previousRequest?: Observable<void>;

  private previousTriggers?: TriggerData[];

  constructor(readonly am: AnswersManager, readonly pa: PlayerApi) {}

  init({ triggers, questions, team }: Partial<TriggerManager>): void {
    this.team = team;
    this.triggers = triggers;
    this.questions = questions;
  }

  update({ questions }: Partial<TriggerManager>): void {
    this.questions = questions || this.questions;
  }

  shutdown(): void {
    if (!this.unloading) {
      this.unloading = true;

      this.checkTriggers();
    }
  }

  resume(): void {
    this.unloading = false;
  }

  reset(): void {
    this.answeringFinished = false;
  }

  checkTriggers(answeringFinished?: boolean): void {
    if (this.answeringFinished) {
      return;
    }

    const answers = this.am.getQuestionAnswers(this.questions);
    const respondent = this.am.getCurrentAnswererId();
    this.answeringFinished = !!answeringFinished;

    if (respondent) {
      const triggers = this.triggers.filter((trigger) => Logic.showItem(trigger, this.questions, answers, false));

      if (triggers.length || this.previousTriggers?.length) {
        const previous = this.previousRequest || of(void 0);

        this.previousTriggers = triggers;

        this.previousRequest = previous.pipe(
          concatMap(() =>
            this.pa.post(
              'trigger/send_notification',
              {
                respondent,
                team: this.team,
                triggers: triggers.map(({ $key }) => $key),
                questions: this.questions.map(({ $key }) => $key),
                answeringFinished: this.answeringFinished,
              },
              this.unloading,
            ),
          ),
          shareReplay(1),
        );

        this.previousRequest.subscribe();
      }
    }
  }
}

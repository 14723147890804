/**
 * Slider 1D component with labels and values showing.
 *
 * @unstable
 */

import { map, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import {
  Component,
  OnChanges,
  OnInit,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  SimpleChange,
} from '@angular/core';

import { SliderAbstractComponent } from '@player/+survey/question/slider-abstract/slider-abstract.component';

import { Questions } from '@shared/enums/questions.enum';
import { FadeInOut } from '@shared/animations/fade-in-out.anim';
import { QuestionData, SliderValuesData } from '@shared/models/survey.model';
import { SliderLabel } from '@player/shared/services/slider-label.service';
import { SurveyStore } from '@player/shared/services/survey-store.service';

@Component({
  selector: 'slider-1d',
  templateUrl: '../slider-abstract/slider-abstract.component.html',
  styleUrls: ['../slider-abstract/slider-abstract.component.scss', './slider-1d.component.scss'],
  providers: [SliderLabel],
  animations: [FadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Slider1D extends SliderAbstractComponent<string> implements OnInit, OnChanges {
  readonly knobs: number[] = [0];

  protected readonly questionType: Questions = Questions.SLIDER_1D;

  @Input() showInput: boolean = false;

  constructor(readonly cdRef: ChangeDetectorRef, ss: SurveyStore) {
    super(ss);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (
      this.groupData &&
      this.questionData &&
      this.groupData.type === Questions.GROUP_SCORED &&
      this.questionData.type !== Questions.SLIDER_NPS
    ) {
      this.groupAnswers = combineLatest([this.ss.normalQuestions, this.ss.answers]).pipe(
        map(([questions, answers]) =>
          questions
            .filter(
              (q: QuestionData) =>
                answers[q.$key] != null &&
                q.$key !== this.questionData.$key &&
                q.type === this.questionData.type &&
                this.groupData.$key === q.group,
            )
            .map((question) => ({
              no: questions.indexOf(question) + 1,
              val: answers[question.$key],
            })),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    const dataChanges: SimpleChange = changes.questionData || changes.groupData;

    if (dataChanges) {
      const current: QuestionData = dataChanges.currentValue || null;

      if (current) {
        current.sliderValues = current.sliderValues || ({} as SliderValuesData);
      }

      this.startPercent = (!current || current.sliderValues.initial == null ? 0.5 : current.sliderValues.initial) * 100;

      this.overlapOffset = undefined;

      this.cdRef.detectChanges();
    }
  }

  getCursorLineStyle(): { [key: string]: string } {
    const style: { [key: string]: string } = {
      width: Math.abs(this.percentLeft[0] - this.startPercent) + '%',
    };

    if (this.percentLeft[0] > this.startPercent) {
      style.left = `${this.startPercent}%`;
    } else {
      style.right = `${100 - this.startPercent}%`;
    }

    return style;
  }

  protected setCurrentValue(currentValue: string): void {
    const { min, max } = this.getMinMaxInitial();

    let value: number = parseFloat(currentValue);

    value = isNaN(value) ? min : Math.max(min, Math.min(max, value));

    this.currentValue = currentValue ? [value] : [];
  }

  protected emitAnswerChange(currentAnswer: number[]): void {
    const [first] = currentAnswer;
    this.answerChange.emit(first != null ? first.toString() : null);

    if (first != null) {
      this.answerReady.emit();
    }
  }
}

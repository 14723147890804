import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Actions, createSelector, ofAction, Selector, State, StateContext, Store } from '@ngxs/store';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import {
  ContactColumn,
  ContactImportError,
  ContactImportSource,
  ContactItemData,
  ContactsDataResponse,
  ContactsImportModel,
  ContactsListData,
  ContactsListResponse,
  ContactsSearchParams,
  FbContactsImportColumns,
  SelectColumn,
} from '@shared/models/contact.model';
import { ContactsManager } from '@shared/services/contacts-manager.service';
import { EmailsManager } from '@shared/services/emails-manager.service';
import { SwitchTeam } from '@shared/states/account.actions';
import { SignOutWithRedirect } from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { GetLocked } from '@shared/states/billing.actions';
import { isCorrectContactsFileType, sortedContactColumns } from '@shared/states/contacts.functions';
import { CONTACTS_STATE_TOKEN, ContactsStateModel } from '@shared/states/contacts.models';
import { RouterState } from '@shared/states/router.state';
import { diff } from '@shared/utilities/object.utilities';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountState } from './account.state';
import {
  AddToLists,
  ComposeEmail,
  CreateContactList,
  CreateImportColumns,
  DeleteContactLists,
  DeleteContacts,
  GetContactColumns,
  GetContactLists,
  GetContacts,
  ImportContactsFromCopyPaste,
  LoadingContactLists,
  LoadingContacts,
  RemoveFromList,
  ResetContactLists,
  ResetContacts,
  ResetImport,
  SkipUnmatchedImportColumns,
  ToggleImportGDPR,
  UpdateContactList,
  UpdateImportColumn,
  UpdateImportPreview,
  UpdateImportReview,
  UpdateImportSettings,
  UpdateImportSource,
  UpdateImportStep,
  UploadContactsFile,
} from './contacts.actions';

const contactsImportDefaults: ContactsImportModel = {
  step: 'source',
  settings: {
    textDelimiter: null,
    textQualifier: null,
    encoding: 'utf-8',
    firstRowHasHeaders: null,
    repeatingDelimsAsOne: true,
    countryCode: null,
  },
  source: {
    gdprAccepted: false,
    type: 'text',
  },
};

const defaultStateModel = {
  columns: [],
  itemsCount: 0,
  listsCount: 0,
  resultsCount: 0,
  contactItems: [],
  contactLists: [],
  contactResults: [],
  listsLoading: false,
  itemsLoading: false,
  import: contactsImportDefaults,
};

@Injectable()
@State<ContactsStateModel>({
  name: CONTACTS_STATE_TOKEN,
  defaults: defaultStateModel,
})
export class ContactsState {
  private getItemsParams;
  private getListsParams;
  private getResultsParams;

  constructor(private store: Store, private cm: ContactsManager, private em: EmailsManager, private actions: Actions) {
    this.store
      .select(AccountState)
      .pipe(
        filter((account) => !!account && !!account.team && !!account.user?.is && !!account.team['$key']),
        map((account) => account.team['$key']),
        distinctUntilChanged(),
        switchMap((teamKey) => this.cm.dbChanges(teamKey).pipe(distinctUntilChanged())),
        takeUntil(this.actions.pipe(ofAction(SignOutWithRedirect))),
        debounceTime(5000),
      )
      .subscribe(() => this.onLicenceChange());
  }

  /* SELECTORS */

  @Selector()
  static columns(state: ContactsStateModel) {
    return state.columns;
  }

  @Selector()
  static anonymityColumns(state: ContactsStateModel) {
    return [...state.columns.filter((cc) => !cc.required), ...state.columns.filter((cc) => cc.required)];
  }

  @Selector()
  static customColumns(state: ContactsStateModel) {
    return state.columns.filter((cc) => !cc.required);
  }

  @Selector()
  static visibleColumns(state: ContactsStateModel) {
    return state.columns.filter((col) => col.visible);
  }

  @Selector()
  static itemsCount(state: ContactsStateModel) {
    return state.itemsCount;
  }

  @Selector()
  static itemsLoading(state: ContactsStateModel) {
    return state.itemsLoading;
  }

  @Selector()
  static listsCount(state: ContactsStateModel) {
    return state.listsCount;
  }

  @Selector()
  static listsLoading(state: ContactsStateModel) {
    return state.listsLoading;
  }

  @Selector()
  static resultsCount(state: ContactsStateModel) {
    return state.resultsCount;
  }

  @Selector()
  static contactItems(state: ContactsStateModel) {
    return state.contactItems || [];
  }

  @Selector()
  static contactLists(state: ContactsStateModel) {
    return state.contactLists || [];
  }

  @Selector()
  static contactResults(state: ContactsStateModel) {
    return state.contactResults;
  }

  @Selector()
  static importStep(state: ContactsStateModel) {
    return state.import && state.import.step;
  }

  @Selector()
  static importSource(state: ContactsStateModel) {
    return (state.import && state.import.source) || null;
  }

  @Selector()
  static importReview(state: ContactsStateModel) {
    if (!state.import) {
      return null;
    } else {
      const date = new Date();
      const source = state.import.source;
      const review = state.import.review;
      const preview = state.import.preview;
      const columns = (state.import.columns || []).filter((col) => col.state === 'selected');

      const hasEmail = columns.some((col) => col.matchedColumn && col.matchedColumn.$key === 'email');
      const hasPhone = columns.some((col) => col.matchedColumn && col.matchedColumn.$key === 'phone');

      const canConflict = !!(preview?.hasEmails || preview?.hasPhoneNumbers);

      const sourceName = source && source.type === 'file' ? source.fileName : null;

      const listNameSuggestion = [
        source?.type === 'text' ? 'Copy-Paste' : source?.fileName,
        'import',
        `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`,
      ].join(' ');

      return { listNameSuggestion, sourceName, columns, canConflict, hasEmail, hasPhone, review };
    }
  }

  @Selector()
  static importSourceError(state: ContactsStateModel): ContactImportError {
    const source = (state.import && state.import.source) || ({} as ContactImportSource);

    if (source.type === 'file' && !isCorrectContactsFileType(source.fileName)) {
      return ContactImportError.WRONG_FORMAT;
    } else if (source.gdprAccepted === false) {
      return ContactImportError.NOT_ACCEPTED;
    } else if (source.type === 'text' && !source.pasteData) {
      return ContactImportError.NO_PASTE_DATA;
    } else {
      return null;
    }
  }

  @Selector()
  static importSettings(state: ContactsStateModel) {
    return (state.import && state.import.settings) || null;
  }

  @Selector()
  static importPreview(state: ContactsStateModel) {
    return state.import && state.import.preview;
  }

  @Selector()
  static importColumns(state: ContactsStateModel): SelectColumn[] {
    return (state.import && state.import.columns) || [];
  }

  @Selector()
  static importColumnsError(state: ContactsStateModel): ContactImportError {
    const columns = (state.import && state.import.columns) || [];

    const isEmail = (column: SelectColumn) => column.state === 'selected' && column.matchedColumn?.$key === 'email';
    const isPhone = (column: SelectColumn) => column.state === 'selected' && column.matchedColumn?.$key === 'phone';
    const isIdColumn = (column: SelectColumn) => isEmail(column) || isPhone(column);
    const isUnmatched = (column: SelectColumn) => column.state === 'unmatched';

    if (columns.every(isUnmatched)) {
      return ContactImportError.ZERO_MATCHED;
    } else if (columns.filter(isIdColumn).length === 0) {
      return ContactImportError.NO_ID_COLUMNS;
    } else if (columns.some(isUnmatched)) {
      return ContactImportError.HAS_UNMATCHED;
    } else {
      return null;
    }
  }

  @Selector()
  static editableColumns(state: ContactsStateModel) {
    return state.columns.filter((column) => column.editable);
  }

  @Selector()
  static matchedImportColumns(state: ContactsStateModel): FbContactsImportColumns[] {
    return ((state.import && state.import.columns) || []).map((column) =>
      column.state === 'selected' && column.matchedColumn
        ? {
            key: column.matchedColumn.$key || null,
            name: column.matchedColumn.name,
            type: column.matchedColumn.type,
          }
        : {
            key: null,
            name: column.name,
            type: column.type,
            skipped: true,
          },
    );
  }

  @Selector()
  static importColumnNames(state: ContactsStateModel): string[] {
    return ((state.import && state.import.columns) || []).map((column) =>
      column.matchedColumn ? column.matchedColumn.name : column.name,
    );
  }

  @Selector()
  static newImportColumns(state: ContactsStateModel): SelectColumn[] {
    return ((state.import && state.import.columns) || []).filter(
      (column) => column.state === 'selected' && column.matchedColumn && !column.matchedColumn.$key,
    );
  }

  static contactsInList(listId: number) {
    return createSelector([ContactsState], ({ contactLists }: ContactsStateModel): number | null => {
      const list = contactLists.find((cld: ContactsListData) => cld.id === listId);
      return list ? list.contactCount : null;
    });
  }

  @Selector([RouterState.routeParams])
  static activeList(state: ContactsStateModel, params: Params) {
    return params.listId ? state.contactLists.find((list) => list.id === +params.listId) : null;
  }

  private onLicenceChange() {
    if (!this.store.selectSnapshot(AuthState.isUserVerified)) {
      return;
    }

    if (this.getItemsParams) {
      this.store.dispatch(new GetContacts({ ...this.getItemsParams, refresh: true }));
    }

    if (this.getListsParams) {
      this.store.dispatch(new GetContactLists({ ...this.getListsParams, refresh: true }));
    }

    if (this.getResultsParams) {
      if (this.getResultsParams.type === 'item') {
        this.store.dispatch(new GetContacts({ ...this.getResultsParams, refresh: true }));
      } else {
        this.store.dispatch(new GetContactLists({ ...this.getResultsParams, refresh: true }));
      }
    }

    this.store.dispatch(new GetLocked());
  }

  /* ACTIONS */

  @Action(AddToLists)
  addToLists(_, { ids, lists, all }: AddToLists) {
    return this.cm.addContactsToLists(ids, lists, all);
  }

  @Action(RemoveFromList)
  removeFromList(_, { ids, listId }: RemoveFromList) {
    return this.cm.removeContactsFromList(ids, listId);
  }

  @Action(ComposeEmail)
  composeEmail(_, { data }: ComposeEmail) {
    const emailKey = this.em.newEmail(data);

    this.store.dispatch(new Navigate([`/emails/${emailKey}/templates`]));
  }

  @Action(GetContacts)
  getContacts({ getState, patchState }: StateContext<ContactsStateModel>, { params }: GetContacts) {
    params.type = 'item';

    const isSearch = params.search || params.listId;

    if (!isSearch && this.getItemsParams && this.getItemsParams.sort !== params.sort) {
      params.refresh = true;
    }

    if (
      isSearch &&
      this.getResultsParams &&
      (this.getResultsParams.type !== params.type ||
        this.getResultsParams.sort !== params.sort ||
        this.getResultsParams.search !== params.search ||
        this.getResultsParams.listId !== params.listId)
    ) {
      params.refresh = true;
    }

    if (params.refresh) {
      patchState({ resultsCount: 0, contactResults: [] });
    }

    const state = getState();

    const contacts = params.refresh ? [] : !isSearch ? state.contactItems : (state.contactResults as ContactItemData[]);

    if (!contacts[params.start] || this.getItemsParams.count !== params.count) {
      this.store.dispatch(new LoadingContacts(true));
    }

    if (isSearch) {
      this.getResultsParams = params;
    } else {
      this.getItemsParams = params;
    }

    // TODO: We could optimize this so that no server query if data in store,
    //       but then we need to check all items not just first...

    return this.cm.getContacts(params).pipe(
      map((response: ContactsDataResponse) => {
        if (response) {
          const results = (response.result || []).map((item) => ({ ...item.entry, ...item.fields }));
          return isSearch
            ? {
                resultsCount: response.totalCount,
                contactResults: this.contactItems(results, state.contactResults, response.totalCount || 0, params),
              }
            : {
                itemsCount: response.totalCount,
                contactItems: this.contactItems(results, state.contactItems, response.totalCount || 0, params),
              };
        }
      }),
      map((newState) =>
        patchState({
          ...newState,
          itemsLoading: false,
        }),
      ),
    );
  }

  private contactItems(
    newContacts: any[],
    oldContacts: any[],
    contactsTotal: number,
    params: Partial<ContactsSearchParams>,
  ) {
    const { start, count } = params;

    if (!start) {
      return newContacts;
    }

    return new Array(contactsTotal).fill(null).map((v, i) => {
      if (i >= start && i < start + count) {
        return newContacts[i - start];
      } else {
        return oldContacts[i] || newContacts[i];
      }
    });
  }

  @Action(DeleteContacts)
  deleteContacts(
    { getState, patchState }: StateContext<ContactsStateModel>,
    { ids }: DeleteContacts,
  ): Observable<ContactsStateModel> {
    const state = getState();

    const contacts = state.contactItems.filter((contact) => !!contact && !ids.includes(contact.id));

    return this.cm.deleteContacts(ids).pipe(
      map((ok) =>
        !ok
          ? null
          : patchState({
              contactItems: contacts,
            }),
      ),
    );
  }

  @Action(LoadingContacts)
  loadingContacts({ patchState }: StateContext<ContactsStateModel>, { status }: LoadingContacts) {
    patchState({ itemsLoading: status });
  }

  @Action(ResetContacts)
  resetContacts({ patchState }: StateContext<ContactsStateModel>) {
    patchState({ itemsLoading: false, itemsCount: 0, contactItems: [] });
  }

  @Action(GetContactLists)
  getLists(
    { getState, patchState }: StateContext<ContactsStateModel>,
    { params }: GetContactLists,
  ): Observable<ContactsStateModel> {
    params.type = 'list';

    const isSearch = params.search;

    if (!isSearch && this.getListsParams && this.getListsParams.sort !== params.sort) {
      params.refresh = true;
    }

    if (
      isSearch &&
      this.getResultsParams &&
      (this.getResultsParams.type !== params.type ||
        this.getResultsParams.sort !== params.sort ||
        this.getResultsParams.search !== params.search)
    ) {
      params.refresh = true;
    }

    if (params.refresh) {
      patchState({ resultsCount: 0, contactResults: [] });
    }

    const state = getState();

    const lists = params.refresh ? [] : !isSearch ? state.contactLists : (state.contactResults as ContactsListData[]);

    if (!lists[params.start] || this.getListsParams.count !== params.count) {
      this.store.dispatch(new LoadingContactLists(true));
    }

    if (isSearch) {
      this.getResultsParams = params;
    } else {
      this.getListsParams = params;
    }

    // TODO: We could optimize this so that no server query if data in store,
    //       but then we need to check all items not just first...

    return this.cm.getContactLists(params).pipe(
      map((response: ContactsListResponse) => {
        if (response) {
          const results = response.result || [];
          return isSearch
            ? {
                resultsCount: response.totalCount,
                contactResults: this.contactLists(results, state.contactResults, response.totalCount || 0, params),
              }
            : {
                listsCount: response.totalCount,
                contactLists: this.contactLists(results, state.contactLists, response.totalCount || 0, params),
              };
        }
      }),
      map((newState) =>
        patchState({
          ...newState,
          listsLoading: false,
        }),
      ),
    );
  }

  private contactLists(newLists: any[], oldLists: any[], listsTotal: number, params: any) {
    const { start, count } = params;

    if (!start) {
      return newLists;
    }

    return new Array(listsTotal).fill(null).map((v, i) => {
      if (i >= start && i < start + count) {
        return newLists[i - start];
      } else {
        return oldLists[i] || newLists[i];
      }
    });
  }

  @Action(CreateContactList)
  createList({ patchState }: StateContext<ContactsStateModel>, { list, members, all }: CreateContactList) {
    return this.cm.createContactList(
      {
        ...list,
      },
      members,
      all,
    );
  }

  @Action(UpdateContactList)
  updateContactList(ctx: StateContext<ContactsStateModel>, { list }: UpdateContactList) {
    return this.cm.updateContactList(list);
  }

  @Action(DeleteContactLists)
  deleteLists({ getState, patchState }: StateContext<ContactsStateModel>, { ids }: DeleteContactLists) {
    const state = getState();

    const lists = state.contactLists.filter((list) => list && !ids.includes(list.id));
    return forkJoin(ids.map((id) => this.cm.removeContactsFromList([], id, true))).pipe(
      switchMap(() => this.cm.deleteContactLists(ids)),
      map((ok) =>
        !ok
          ? null
          : patchState({
              contactLists: lists,
            }),
      ),
    );
  }

  @Action(LoadingContactLists)
  loadingLists({ patchState }: StateContext<ContactsStateModel>, { status }: LoadingContactLists) {
    patchState({ listsLoading: status });
  }

  @Action(ResetContactLists)
  resetContactLists({ patchState }: StateContext<ContactsStateModel>) {
    patchState({ listsLoading: false, listsCount: 0, contactLists: [] });
  }

  @StreamAction(GetContactColumns)
  getColumns({ patchState }: StateContext<ContactsStateModel>) {
    const contactColumns = this.cm.listContactsColumns();

    const contactPrefs = this.store
      .select((state) => state.prefs.columns)
      .pipe(map((columns) => columns.contacts || {}));

    return combineLatest([contactColumns, contactPrefs]).pipe(
      map(([cols, prefs]) => {
        const entryColumns = this.cm.entryColumns.filter((col) => col.type !== 'auto').map((col) => ({ ...col }));
        let allColumns = [...entryColumns];

        cols.forEach((col) => {
          const entry = allColumns.find((entryCol) => entryCol.$key === col.$key);
          col = {
            ...col,
          };

          if (entry) {
            Object.assign(entry, col);
          } else {
            allColumns.push(col);
          }
        });

        allColumns = allColumns.map((col) => ({
          ...col,
          ...prefs[col.$key],
        }));

        return sortedContactColumns(allColumns.filter((col) => !!col.$key));
      }),
      map((columns) => columns.filter((col, i, self) => self.findIndex(({ $key }) => $key === col.$key) === i)),
      tap((columns) => patchState({ columns })),
    );
  }

  @Action(ToggleImportGDPR)
  toggleImportGDPR({ patchState, getState }: StateContext<ContactsStateModel>, { gdprAccepted }: ToggleImportGDPR) {
    const state = getState().import;
    const source = { ...state.source, gdprAccepted };

    patchState({ import: { ...state, source } });
  }

  @Action(ResetImport)
  resetImport({ patchState }: StateContext<ContactsStateModel>) {
    patchState({ import: contactsImportDefaults });
  }

  @Action(UpdateImportPreview)
  updateImportPreview(
    { patchState, getState, dispatch }: StateContext<ContactsStateModel>,
    { response }: UpdateImportPreview,
  ) {
    const state = getState().import;

    const { hasPhoneNumbers, hasEmails } = response;
    const maxColumns = response.rows.length;
    const maxRows = response.rows.map((r) => r.rows?.length || 0).reduce((a, b) => Math.max(a, b), 0);

    // response.rows.forEach(column => column.rows = column.rows.slice(0, 5));
    // console.warn(response);

    const columns = response.rows; // .slice(0, 4);
    const sample = columns.slice(0, 4).map((column) => ({ ...column, rows: column.rows.slice(0, 5) }));

    const preview = {
      columns,
      sample,
      hasEmails,
      hasPhoneNumbers,
      maxColumns,
      maxRows,
    };

    const containsEmail = columns.map((data) => (data.rows || [])[0]).some((val) => (val || '').includes('@'));
    const hasMultilpleRows = columns[0] && columns[0].rows && columns[0].rows.length > 1;

    const firstRowHasHeaders =
      state.settings.firstRowHasHeaders === null
        ? hasMultilpleRows && !containsEmail
        : state.settings.firstRowHasHeaders;
    const textDelimiter =
      state.settings.textDelimiter === null ? response.delimiter || ',' : state.settings.textDelimiter;
    const textQualifier =
      state.settings.textQualifier === null ? response.qualifier || null : state.settings.textQualifier;

    const settings = {
      ...state.settings,
      firstRowHasHeaders,
      textDelimiter,
      textQualifier,
      countryCode: response.countryCode,
    };

    const selected = [];

    if (state.settings.firstRowHasHeaders === null) {
      // update settings with "recommanded" parameters from backend
      // and fetch preview data again
      dispatch(new UpdateImportSettings(settings));
    } else {
      patchState({ import: { ...state, preview, settings } });
    }
  }

  @Action(UpdateImportStep)
  updateImportStep({ patchState, getState }: StateContext<ContactsStateModel>, { step }: UpdateImportStep) {
    const state = getState().import;

    patchState({ import: { ...state, step } });
  }

  @Action(UpdateImportSettings)
  updateImportSettings({ patchState, getState }: StateContext<ContactsStateModel>, { change }: UpdateImportSettings) {
    const state = getState().import;
    const settings = { ...state.settings, ...change };

    patchState({ import: { ...state, preview: undefined, settings } });
  }

  @Action(UpdateImportSource)
  updateImportSource({ patchState, getState }: StateContext<ContactsStateModel>, { change }: UpdateImportSource) {
    const state = getState().import;
    const source = { ...state.source, ...change };
    // Clear data only if source changes
    const importState =
      Object.keys(diff(change, state.source)).length === 0
        ? { ...state, source }
        : { step: state.step, settings: state.settings, source };

    patchState({ import: importState });
  }

  @Action(CreateImportColumns)
  createImportColumns({ patchState, getState }: StateContext<ContactsStateModel>) {
    const state = getState();
    const newColumns = (state.import && state.import.preview && state.import.preview.columns) || [];
    const oldColumns = state.columns;
    const hasCustomColumns = oldColumns.some((c) => !c.required);

    function compareNames(name1: string, name2: string): boolean {
      if (!name1 || !name2) {
        return false;
      }
      const stripRegex = /[#_-\s]/g;
      return name1.toLowerCase().replace(stripRegex, '') === name2.toLowerCase().replace(stripRegex, '');
    }

    const columns: SelectColumn[] = newColumns.map((column, index) => {
      if (column.type === 'email' && newColumns.findIndex((c) => c.type === 'email') === index) {
        // match first "email" field
        const matchedColumn: ContactColumn = {
          $key: 'email',
          name: 'Email',
          type: 'email',
          editable: true,
          visible: true,
          required: true,
        };

        return { ...column, state: 'selected', matchedColumn };
      } else if (column.type === 'phone' && newColumns.findIndex((c) => c.type === 'phone') === index) {
        // match first "phone" field
        const matchedColumn: ContactColumn = {
          $key: 'phone',
          name: 'Phone',
          type: 'phone',
          editable: true,
          visible: true,
          required: true,
        };

        return { ...column, state: 'selected', matchedColumn };
      } else if (!hasCustomColumns) {
        // match all columns when importing for the first time
        const matchedColumn =
          oldColumns.find((old) => compareNames(old.name, column.name)) ||
          ({
            name: column.name,
            type: column.type,
          } as ContactColumn);

        return { ...column, state: 'selected', matchedColumn };
      } else if (oldColumns && oldColumns.some((old) => compareNames(old.name, column.name))) {
        // match existing contact column
        const matchedColumn: ContactColumn = oldColumns.find((old) => compareNames(old.name, column.name));

        return { ...column, state: 'selected', matchedColumn };
      } else {
        return { ...column, state: 'unmatched' };
      }
    });

    patchState({ import: { ...state.import, columns } });
  }

  @Action(UpdateImportColumn)
  updateImportColumn(
    { patchState, getState }: StateContext<ContactsStateModel>,
    { column, change }: UpdateImportColumn,
  ) {
    const state = getState().import;
    const columns: SelectColumn[] = (state.columns || []).map((col) =>
      col !== column ? col : { ...column, ...change },
    );

    patchState({ import: { ...state, columns } });
  }

  @Action(UpdateImportReview)
  updateImportReview(
    { patchState, getState, dispatch }: StateContext<ContactsStateModel>,
    { change }: UpdateImportReview,
  ) {
    const state = getState().import;
    const review = { ...state.review, ...change };
    const source = { ...state.source };
    source.list = review.list && ({ ...review.list } as ContactsListData);

    patchState({ import: { ...state, review, source } });
  }

  @Action(ImportContactsFromCopyPaste)
  importContactsFromCopyPaste(
    ctx: StateContext<ContactsStateModel>,
    data: ImportContactsFromCopyPaste,
  ): Observable<void> {
    return this.cm.importContactsFromCopyPaste(
      data.name,
      data.copyPaste,
      data.repairLanguage,
      data.invite,
      data.inviteKind,
    );
  }

  @Action(SkipUnmatchedImportColumns)
  skipUnmatchedImportColumns({ patchState, getState }: StateContext<ContactsStateModel>) {
    const state = getState().import;
    const columns: SelectColumn[] = (state.columns || []).map((col) =>
      col.state === 'unmatched' ? { ...col, state: 'skipped' } : col,
    );

    patchState({ import: { ...state, columns } });
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ setState }: StateContext<ContactsStateModel>) {
    setState(defaultStateModel);
  }

  @Action(UploadContactsFile)
  uploadContactsFile(
    { dispatch, getState }: StateContext<ContactsStateModel>,
    { file }: UploadContactsFile,
  ): Observable<void> {
    dispatch(new UpdateImportSource({ type: 'file', fileName: file.name }));

    if (isCorrectContactsFileType(file.name)) {
      return this.cm.uploadContacts(file);
    } else {
      return of(void 0);
    }
  }
}

<!--
  Dialog view with support for basic information showing.

  @unstable
-->

<div
  class="container-box z-fx-col z-fx-center-between z-fx-grow"
  [style.background-image]="background | encodeURI : 'url' | safeStyle"
>
  <div class="container-inner z-fx-col z-fx-auto">
    <div *ngIf="!hideHeader" class="header-box z-fx z-fx-start-center z-fx-gap-16 z-fx-none">
      <div
        *ngIf="!!logo"
        class="zef-logo-image {{ logoType }} zef-medium"
        [style.background-image]="logo | encodeURI : 'url' | safeStyle"
      ></div>

      <zef-source-logo *ngIf="sourceType" [source]="sourceType" [key]="sourceKey" size="medium"></zef-source-logo>

      <div class="z-fx-col z-fx-center-start">
        <div class="zef-text-h1">{{ title }}</div>

        <div class="zef-text-p2 zef-text-light">{{ label }}</div>
      </div>
    </div>

    <div class="content-box z-fx-col z-fx-gap-16 z-fx-grow">
      <ng-scrollbar *ngIf="!hideContent" [autoHeightDisabled]="false" [autoWidthDisabled]="true">
        <mat-dialog-content
          class="z-fx-col z-fx-start-stretch z-fx-gap-16 z-fx-flex"
          [class.mat-inset-z1]="!hideBorder && (!!label || !!header || !!content)"
          [class.mat-inset-z0]="hideBorder"
        >
          <div *ngIf="header" class="zef-text-h3 z-fx-sc z-fx-none">
            {{ header }}
            <zef-help-icon *ngIf="helpSubject" [subject]="helpSubject"></zef-help-icon>
          </div>

          <div *ngIf="content" class="content-area z-fx-col z-fx-center-start z-fx-none" [ngClass]="type">
            <div [innerHTML]="content | safeHtml"></div>
          </div>

          <div *ngIf="showInput" class="z-fx-col z-fx-center-start z-fx-none">
            <mat-form-field>
              <mat-icon *ngIf="inputIcon" [ngClass]="iconColorClass" matPrefix>{{ inputIcon }}</mat-icon>

              <input
                matInput
                [(ngModel)]="inputValue"
                cdkFocusInitial
                autoSelect
                [placeholder]="inputPlaceholder"
                (keydown.enter)="onInputEnter($event)"
              />

              <ng-content select="[dialog-fields]"></ng-content>
            </mat-form-field>
          </div>

          <ng-content select="[dialog-content]" class="z-fx-none"></ng-content>
        </mat-dialog-content>
      </ng-scrollbar>

      <ng-content select="[dialog-contents]" class="z-fx-none"></ng-content>
    </div>
  </div>

  <mat-dialog-actions
    *ngIf="!hideActions"
    [class.no-padding]="noActionPadding"
    class="footer-box z-fx-col z-fx-gap-8 z-fx-none"
    [ngClass]="action || cancel ? 'z-fx-start-end' : 'z-fx-start-stretch'"
  >
    <div class="z-fx z-fx-end-center z-fx-gap-16 z-fx-none">
      <ng-content select="[dialog-tooltips]"></ng-content>

      <div class="z-fx z-fx-gap-4 z-fx-end-center z-fx-flex">
        <ng-content select="[dialog-actions]"></ng-content>

        <button *ngIf="cancel" mat-raised-button color="secondary" matDialogClose class="close-button" [ngClass]="type">
          <span>{{ cancel }}</span>
        </button>

        <button
          *ngIf="action"
          mat-raised-button
          class="action-button"
          [color]="getActionColor()"
          [disabled]="disabled || (showInput && !inputValue?.length)"
          [ngClass]="type"
          (click)="close(action)"
        >
          <span>{{ action }}</span>
        </button>
      </div>
    </div>

    <ng-content select="[dialog-caption]"></ng-content>
  </mat-dialog-actions>

  <div *ngIf="dialogImage" class="dialog-image">
    <ng-container [ngTemplateOutlet]="dialogImage"></ng-container>
  </div>
</div>

<button mat-icon-button matDialogClose [class.shadow]="!!dialogImage"><mat-icon>close</mat-icon></button>

/**
 * Common module for the report charts.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { ChartsView } from '@report/+charts/charts.component';

import { ChartModule } from '@report/shared/modules/chart.module';

// import { HintCard } from '@report/+charts/hint-card/hint-card.component';
import { ChartCard } from '@report/+charts/chart-card/chart-card.component';
import { ChartBuilder } from '@report/+charts/chart-builder/chart-builder.component';

import { ChartMenu } from '@report/+charts/chart-card/chart-menu/chart-menu.component';

import { FullscreenChart } from '@report/+charts/fullscreen-chart/fullscreen-chart.dialog';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';

// import { HintCardActionManager } from '@report/+charts/hint-card/hint-card-action-manager.service';

@NgModule({
  bootstrap: [],
  declarations: [
    ChartsView,
    // HintCard,
    ChartCard,
    ChartBuilder,
    ChartMenu,
    FullscreenChart,
  ],
  exports: [SharedModule, ChartModule, ChartsView],
  imports: [SharedModule, ChartModule, PortalModule, OverlayModule],
  providers: [
    // HintCardActionManager
  ],
})
export class ChartsModule {}

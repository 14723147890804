<span
  *ngIf="!editing"
  class="inline z-fx-sc"
  [matTooltip]="tooltip"
  [matTooltipDisabled]="!tooltip"
  [class.zef-cursor-pointer]="useClick"
  [class.zef-text-muted]="!value"
  (click)="editing = useClick"
>
  <span class="zef-text-ellipsis z-fx-flex">
    {{ value || placeholder }}
  </span>

  <button *ngIf="!useClick" class="inline-edit z-fx-none" mat-icon-button (click)="editing = true">
    <mat-icon>edit</mat-icon>
  </button>
</span>

<mat-form-field *ngIf="editing" class="zef-input-small zef-input-space">
  <input
    matInput
    autoFocus
    autoSize
    [value]="value"
    [placeholder]="placeholder"
    (blur)="onSubmit(input.value)"
    (keydown.enter)="onSubmit(input.value)"
    (keydown.escape)="onEscape($event)"
    #input="matInput"
  />
</mat-form-field>

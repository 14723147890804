import { Component, HostBinding, Input } from '@angular/core';

import { parityColor } from '@shared/models/styles.model';

@Component({
  selector: 'slider-thumb',
  templateUrl: './slider-thumb.component.html',
  styleUrls: ['./slider-thumb.component.scss'],
})
export class SliderThumb {
  @Input() set color(color: string) {
    this.backgroundColor = color;
    this.textColor = parityColor(color);
  }

  @Input() questionNumber = 0;

  @Input() checked = false;

  @HostBinding('style.background-color')
  backgroundColor?: string;

  @HostBinding('style.color')
  textColor?: string;
}

<mat-icon [ngClass]="colorClass" [class.zef-icon-small]="size === 'small'">
  {{ kind }}
</mat-icon>

<label
  [ngClass]="size === 'small' ? colorClass : ''"
  [class.zef-text-p3]="size === 'small'"
  [class.zef-text-p1]="size === 'medium'"
  class="zef-text-overflow-line"
>
  <ng-container *ngIf="defaultLabel" [ngSwitch]="kind">
    <ng-container *ngSwitchCase="'email'" i18n>Email</ng-container>
    <ng-container *ngSwitchCase="'sms'" i18n>SMS</ng-container>
    <ng-container *ngSwitchCase="'link'" i18n>Link</ng-container>
    <ng-container *ngSwitchCase="'embed'" i18n>Embed</ng-container>
    <ng-container *ngSwitchCase="'popup'" i18n>Popup</ng-container>
  </ng-container>

  <ng-container *ngIf="!defaultLabel">
    <ng-content></ng-content>
  </ng-container>
</label>

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DiscoverPage } from '@home/+discover/discover.component';

import { ResetFilter } from '@home/shared/guards/reset-filter.guard';

import { SurveyTemplates } from '@editor/shared/resolves/survey-templates.resolve';

const DiscoverRoutes: Routes = [
  {
    path: '',
    component: DiscoverPage,
    canDeactivate: [ResetFilter],
    children: [{ path: ':templateName', component: DiscoverPage }],
    resolve: {
      templates: SurveyTemplates,
    },
  },
];

export const DiscoverRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(DiscoverRoutes);

<zef-dialog
  *ngIf="{
    isTeamOwner: (isTeamOwner$ | async),
    card: (paymentCard$ | async),
    price: (paymentPrice$ | async),
    isPaymentExpired: (isPaymentExpired$ | async)
  } as asyncData"
  type="ask"
  logoType="square"
  [logo]="teamLogo$ | async"
  [label]="teamName$ | async"
  [title]="dialogTitle"
>
  <div dialog-content>
    <div class="z-fx">
      <div class="z-fx-col z-fx-gap-16">
        <strong *ngIf="!asyncData.isPaymentExpired" class="zef-text-p1 zef-color-red" i18n>
          Please authenticate your payment method ASAP to avoid interruption in service.
        </strong>
        <strong *ngIf="asyncData.isPaymentExpired" class="zef-text-p1 zef-color-red" i18n>
          The payment authentication period has expired. Please update your payment method; we will attempt to charge it
          in a couple of days.
        </strong>

        <span class="zef-text-p1" i18n>
          On 14 September 2019, new requirements for authenticating online payments are introduced in Europe as part of
          the
          <a
            class="zef-hyper-link"
            href="https://en.wikipedia.org/wiki/Payment_Services_Directive#Revised_Directive_on_Payment_Services_(PSD2)"
            target="_blank"
          >
            Second Payment Services Directive
          </a>
          (PSD2). As for these new requirements your payment method is requested to complete
          <a class="zef-hyper-link" href="https://en.wikipedia.org/wiki/Strong_customer_authentication" target="_blank">
            Strong Customer Authentication
          </a>
          .
        </span>
      </div>

      <div *ngIf="asyncData.isTeamOwner" class="payment-info z-fx-col z-fx-gap-24">
        <div *ngIf="asyncData.price" class="z-fx-col z-fx-gap-8">
          <strong class="zef-text-p1" i18n>Pending payment</strong>
          <price-decimal [price]="asyncData.price"></price-decimal>
        </div>
        <div *ngIf="asyncData.card" class="z-fx-col z-fx-gap-8">
          <strong class="zef-text-p1" i18n>Payment method</strong>
          <span class="zef-text-p1">{{ asyncData.card.name }}</span>
          <span class="zef-text-p1">**** **** **** {{ asyncData.card.last4 }}</span>
          <a class="zef-text-p2 zef-hyper-link" (click)="gotoBilling('billing-details')" i18n>Change</a>
        </div>
      </div>
    </div>
  </div>

  <div dialog-actions class="z-fx-col z-fx-gap-16 z-fx-end-end">
    <div class="z-fx z-fx-gap-4 z-fx-end-end">
      <button
        *ngIf="!asyncData.isTeamOwner || asyncData.isPaymentExpired"
        mat-raised-button
        color="secondary"
        matDialogClose
      >
        <strong class="zef-text-p2" i18n>Close</strong>
      </button>

      <button
        *ngIf="asyncData.isPaymentExpired"
        mat-raised-button
        color="primary"
        matDialogClose
        [disabled]="!asyncData.isTeamOwner"
        (click)="gotoBilling()"
      >
        <strong class="zef-text-p2" i18n>Go to Plans & Billing</strong>
      </button>

      <button
        *ngIf="!asyncData.isPaymentExpired"
        mat-raised-button
        color="primary"
        [matDialogClose]="true"
        [disabled]="!asyncData.isTeamOwner"
      >
        <strong class="zef-text-p2" i18n>Authenticate your Payment Method</strong>
      </button>
    </div>

    <span *ngIf="!asyncData.isTeamOwner" class="zef-text-p2-muted" i18n>
      An
      <user-role mat-dialog-close role="owner"></user-role>
      can complete this step
    </span>
  </div>
</zef-dialog>

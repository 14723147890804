import { CalculatorInterface, PercentScoring } from '@player/shared/models/scoring.model';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';

import { PlayerAnswers, PlayerOutcome } from '@player/shared/models/player.model';

import { Logic } from '@shared/enums/logic.enum';
import { defaultOutcomeOptions, Outcomes } from '@shared/enums/outcomes.enum';
import { TeamData } from '@shared/models/account.model';
import { LanguagesData } from '@shared/models/locale.model';
import {
  ChoiceItemData,
  DesignData,
  OutcomeData,
  QuestionData,
  ReleaseData,
  SharingData,
  SurveyData,
  SurveyScoring,
  TriggerData,
  ViewState,
} from '@shared/models/survey.model';
import { isShallowEqual } from '@shared/utilities/object.utilities';
import { Questions } from '@shared/enums/questions.enum';
import { ViewSize } from '@shared/enums/view-size.enum';
import {
  contrastColor,
  desaturatedColor,
  mixColors,
  opaqueColor,
  parityColor,
  transparentColor,
} from '@shared/models/styles.model';
import { DATE_TIME_PROVIDER } from '@shared/tokens/date-time-provider.token';
import { TriggerManager } from '@player/shared/services/trigger-manager.service';

export type ColorState = {
  primary: string;
  primary50: string;
  primary15: string;
  primaryMood: string;
  primaryParity: string;
  primaryParityPrimary10: string;
  primaryParityPrimary2050: string;
  button: string;
  buttonContrast: string;
  buttonMuted: string;
  buttonDesaturated: string;
  mood: string;
  mood20: string;
  mood50: string;
  moodOpacity: string;
  mood80: string;
  moodContrast: string;
  moodContrast10: string;
  moodContrast20: string;
  moodContrastMix5010: string;
  moodParity: string;
  text: string;
  text80: string;
  text50: string;
  text30: string;
  text25: string;
  text05: string;
  textContrast: string;
};

export type FunnelData = {
  emailSubmitted?: boolean;
  emailShown?: boolean;
  signupSubmitted?: boolean;
  funnelHidden: boolean;
  containerHeight: number;
};

@Injectable()
export class SurveyStore {
  readonly viewState = new BehaviorSubject<ViewState>(ViewState.Loading);

  readonly viewSize = new ReplaySubject<ViewSize>(1);

  readonly activeKey = new BehaviorSubject<string>('');

  readonly overrideKey = new BehaviorSubject<string>('');

  readonly cardHeights = new BehaviorSubject<number[]>([]);

  readonly playerDimensions = new BehaviorSubject<any>({});

  readonly initSeen = new Subject<void>();

  readonly buttonClick = new Subject<void>();

  readonly viewRect$ = new ReplaySubject<DOMRect>(1);

  readonly hideContent$ = new BehaviorSubject(false);

  readonly isMobile = this.viewSize.pipe(map(ViewSize.isMobile), shareReplay({ refCount: true, bufferSize: 1 }));

  readonly answers = new BehaviorSubject<PlayerAnswers>({}).pipe(
    distinctUntilChanged(isShallowEqual),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as BehaviorSubject<PlayerAnswers>;

  readonly hiddenAnswers = new BehaviorSubject<PlayerAnswers>({}).pipe(
    distinctUntilChanged(isShallowEqual),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as BehaviorSubject<PlayerAnswers>;

  readonly allAnswers = combineLatest([this.answers, this.hiddenAnswers]).pipe(
    map(([answers, hiddenAnswers]) => ({ ...hiddenAnswers, ...answers } as PlayerAnswers)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<PlayerAnswers>;

  readonly team = new ReplaySubject<TeamData>(1).pipe(
    map((data) => ({ ...new TeamData(), ...data })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<TeamData>;

  readonly isFree = this.team.pipe(
    map((team) => !team || !team.plan || team.plan === 'free_plan' || team.plan === 'parked_plan'),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly isBasic = this.team.pipe(
    map((team) => team && (team.plan === 'plan_basic' || team.plan === 'plan_smart')),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly isEnterprise = this.team.pipe(
    map(
      (team) =>
        team && (team.plan === 'plan_enterprise' || team.plan === 'plan_einstein' || team.plan === 'plan_master'),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly survey = new BehaviorSubject(new SurveyData()).pipe(
    map((data) => ({ ...new SurveyData(), ...data })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<SurveyData>;

  readonly design = new BehaviorSubject(new DesignData()).pipe(
    map((data) => new DesignData(data)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<DesignData>;

  readonly colors: Observable<ColorState> = this.design.pipe(
    map(({ colors }) => ({
      primary: colors.primary,
      primary50: transparentColor(colors.primary, 0.5),
      primary15: transparentColor(colors.primary, 0.15),
      primaryMood: mixColors(colors.primary, colors.mood, 20),
      primaryParity: parityColor(colors.primary),
      primaryParityPrimary10: mixColors(colors.primary, parityColor(colors.primary), 10),
      primaryParityPrimary2050: mixColors(
        transparentColor(colors.primary, 0.5),
        transparentColor(contrastColor(colors.primary), 0.2),
      ),
      button: colors.buttons,
      buttonContrast: parityColor(colors.buttons),
      buttonMuted: desaturatedColor(transparentColor(colors.buttons, 0.4), 0.25),
      buttonDesaturated: desaturatedColor(colors.buttons, 0.8),
      mood: colors.mood,
      mood80: transparentColor(colors.mood, 0.8),
      moodOpacity: opaqueColor(colors.mood, colors.opacity),
      mood20: transparentColor(colors.mood, 0.2),
      mood50: transparentColor(colors.mood, 0.5),
      moodContrast: parityColor(colors.mood),
      moodContrast10: transparentColor(parityColor(colors.mood), 0.1),
      moodContrast20: transparentColor(parityColor(colors.mood), 0.2),
      moodContrastMix5010: mixColors(
        transparentColor(colors.mood, 0.5),
        transparentColor(parityColor(colors.mood), 0.1),
      ),
      moodParity: parityColor(colors.mood),
      text: colors.texts,
      text80: transparentColor(colors.texts, 0.8),
      text50: transparentColor(colors.texts, 0.5),
      text30: transparentColor(colors.texts, 0.3),
      text25: transparentColor(colors.texts, 0.25),
      text05: transparentColor(colors.texts, 0.05),
      textContrast: parityColor(colors.texts),
    })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly font = combineLatest([this.design, this.colors, this.viewSize]).pipe(
    map(([{ font }, { primary }, viewSize]) => ({
      styles: font.getStyles(primary, viewSize),
    })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<{ styles: Record<string, string> }>;

  readonly logo = this.design.pipe(
    map(({ logo }) => {
      if (logo.url.indexOf('assets/images/team-placeholder') !== -1) {
        logo.url = '';
      }

      return logo;
    }),
    map((logo) => ({
      url: logo.url,
      image: logo.getImage(),
      styles: logo.getStyles(),
    })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly background = this.design.pipe(
    map(({ background }) => ({
      image: background.getImage(),
      styles: background.getStyles(),
      filter: background.getFilter(),
      brightness: background.getBrightnessColor(),
    })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly sidebar = this.design.pipe(
    map(({ sidebar }) => ({
      teamName: sidebar.teamName,
      teamLogo: sidebar.teamLogo,
      sharingButtons: sidebar.sharingButtons,
      selectLanguage: sidebar.selectLanguage,
    })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly release = new ReplaySubject<ReleaseData>(1).pipe(
    map((data) => ({ ...new ReleaseData(), ...data })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<ReleaseData>;

  readonly sharing = new ReplaySubject(1).pipe(
    map((data) => new SharingData(data)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<SharingData>;

  readonly scoring = new ReplaySubject(1).pipe(
    map((data) => data || {}),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<SurveyScoring>;

  readonly outcomes = new ReplaySubject<OutcomeData[]>(1).pipe(
    map((data) => (data || []).map((outcome) => new OutcomeData(outcome))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<OutcomeData[]>;

  readonly thanksOutcome = this.outcomes.pipe(
    map((outcomes) => outcomes.find((o) => o.type === Outcomes.GOODBYE)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly questions = new ReplaySubject<QuestionData[]>(1).pipe(
    map((data) => (data || []).map((question) => new QuestionData(question))),
    map((questions: QuestionData[]) =>
      questions.map((question, i) => {
        if (Questions.shufflable(question) && question.choiceShuffle) {
          question.choiceList = Questions.shuffleChoices(question.choiceList);
        }

        if (question.choiceList?.length) {
          question.choiceList = question.choiceList.map((choice) => new ChoiceItemData(choice));
        }

        question.$key ||= i.toString();

        return question;
      }),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<QuestionData[]>;

  readonly filteredQuestions = combineLatest([
    this.questions,
    this.answers,
    this.hiddenAnswers,
    this.allAnswers,
    this.overrideKey,
    this.dt,
  ]).pipe(
    map(([questions, answers, hiddenAnswers, allAnswers, override, date]) => {
      const keys = ['zefSurveyUserRating'];
      const filteredQuestions = questions.reduce((filtered, question) => {
        if (question.$key === override || Logic.showQuestion(question, questions, allAnswers, keys, date)) {
          if (!question?.archived) {
            filtered.push(question);
            keys.push(question.$key);
          }
        }

        return filtered;
      }, [] as QuestionData[]);

      let answersUpdated = false;

      Object.entries(hiddenAnswers).forEach(([key, answer]) => {
        if (keys.includes(key)) {
          answers[key] = answer;
          delete hiddenAnswers[key];
          answersUpdated = true;
        }
      });

      if (filteredQuestions.length !== questions.length) {
        Object.entries(answers).forEach(([key, answer]) => {
          if (answer != null && !keys.includes(key)) {
            hiddenAnswers[key] = answer;
            delete answers[key];
            answersUpdated = true;
          }
        });

        if (answersUpdated) {
          this.answers.next(answers);
          this.hiddenAnswers.next(hiddenAnswers);
        }
      }

      return filteredQuestions;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<QuestionData[]>;

  readonly cardQuestions = this.filteredQuestions.pipe(
    map((questions) => questions.filter((q) => !Questions.group(q) || !!(q.showTitle !== false && q.title))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<QuestionData[]>;

  readonly normalQuestions = this.cardQuestions.pipe(
    map((questions) => questions.filter((q) => !Questions.group(q) && q.type !== Questions.INFO_TEXT)),
    tap((questions) => this.tm.update({ questions })),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly filteredOutcomes = combineLatest([
    this.outcomes,
    this.filteredQuestions,
    this.allAnswers,
    this.overrideKey,
    this.dt,
  ]).pipe(
    map(([outcomes, questions, answers, override, date]) =>
      outcomes.filter((outcome) => {
        const isArchived = Boolean(outcome?.archived);
        const isOverride = outcome.$key === override;

        return !isArchived && (isOverride || Logic.showOutcome(outcome, questions, answers, date));
      }),
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly scorableOutcomes = this.filteredOutcomes.pipe(
    map((outcomes) => outcomes.filter((o) => o.type === Outcomes.OUTCOME)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly outcomesOptions = this.survey.pipe(
    map((surveyData) => surveyData.resultsOptions || defaultOutcomeOptions),
    shareReplay(1),
  );

  private readonly calculator: CalculatorInterface = new PercentScoring();

  readonly scoredOutcomes: Observable<PlayerOutcome[]> = combineLatest([
    combineLatest([this.answers, this.cardQuestions, this.questions]),
    combineLatest([this.scorableOutcomes, this.outcomesOptions, this.scoring.pipe(startWith({})), this.overrideKey]),
  ]).pipe(
    map(([[answers, questions, allQuestions], [outcomes, options, scoring, override]]) => {
      const groups = allQuestions.filter(Questions.group);
      const scores = this.calculator.calculate(answers, questions, groups, outcomes, scoring);
      const correct = this.calculator.countCorrect(answers, questions, groups, outcomes, scoring);

      const sorted = outcomes
        .filter((o) => o.$key === override || scores.has(o.$key))
        .map((item) => ({ item, score: scores.get(item.$key) || 0, correct: correct.get(item.$key) || null }))
        .filter(
          (o) => !(options.hideZeroScoreOutcomes && (options.score === 'correct' ? !o.correct?.correct : !o.score)),
        )
        .sort((a, b) => b.score - a.score);

      return options.count ? sorted.slice(0, options.count) : sorted;
    }),
    distinctUntilChanged((a, b) => {
      if (a.length !== b.length) {
        return false;
      }

      return a.every((outcome, index) => isShallowEqual(outcome, b[index]));
    }),
    startWith([]),
    shareReplay(1),
  );

  readonly triggers = new ReplaySubject<TriggerData[]>(1).pipe(
    map((data) => (data || []).map((trigger) => ({ ...new TriggerData(), ...trigger }))),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<TriggerData[]>;

  readonly languages = new ReplaySubject(1).pipe(
    map((data) => (data || {}) as LanguagesData),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as ReplaySubject<LanguagesData>;

  readonly percentCompleted = combineLatest([this.normalQuestions, this.answers]).pipe(
    map(([questions, answers]) => questions.filter(({ $key }) => answers[$key] != null).length / questions.length),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly activeIdx = combineLatest([this.activeKey, this.cardQuestions, this.scoredOutcomes]).pipe(
    map(([key, questions, outcomes]) => {
      let idx = 0;

      if (key) {
        idx = questions.findIndex((question) => question.$key === key);

        if (idx === -1) {
          idx = outcomes.findIndex((outcome) => outcome.item.$key === key);
        }
      }

      return idx;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly scrollDir = this.activeIdx.pipe(
    distinctUntilChanged(),
    pairwise(),
    map(([prev, next]) => (next - prev > 0 ? 1 : -1)),
    shareReplay(1),
  );

  readonly activeQuestion = combineLatest([this.cardQuestions, this.activeIdx]).pipe(
    map(([questions, index]) => questions[index]),
    delay(1),
    shareReplay(1),
  );

  readonly seenQuestions: Observable<string[]> = merge(
    this.activeKey.pipe(map((key) => ({ add: key }))),
    this.initSeen.pipe(
      switchMap(() =>
        this.cardQuestions.pipe(
          filter((q) => q.length > 0),
          take(1),
        ),
      ),
      map((questions) => ({ init: questions.map((question) => question.$key) })),
    ),
  ).pipe(
    scan((seen, { add, init }: { init?: string[]; add?: string }) => {
      if (init) {
        return init;
      }

      if (add && !seen.includes(add)) {
        seen.push(add);
      }

      return seen;
    }, [] as string[]),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly funnelData = new BehaviorSubject<FunnelData>({
    emailSubmitted: false,
    emailShown: false,
    signupSubmitted: false,
    funnelHidden: false,
    containerHeight: 0,
  });

  readonly showPromoText = combineLatest([this.survey, this.isEnterprise]).pipe(
    map(([surveyData, isEnterprise]) => !surveyData.funnel && !isEnterprise),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly showFunnelOne = combineLatest([this.answers, this.funnelData, this.survey]).pipe(
    map(([answers, data, { funnel }]) => {
      const isFunnelOne = funnel?.type === 'zef-funnel-one';
      const hasRatingAnswer = 'zefSurveyUserRating' in answers;
      const showFunnel =
        (!data.funnelHidden && !hasRatingAnswer) ||
        (!data.funnelHidden && data.emailShown && hasRatingAnswer && !data.emailSubmitted) ||
        (!data.funnelHidden && data.emailShown && data.emailSubmitted);

      return isFunnelOne && showFunnel;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<boolean>;

  readonly showFunnelTwo = combineLatest([this.answers, this.funnelData, this.survey]).pipe(
    map(([answers, data, { funnel }]) => {
      const isFunnelOne = funnel?.type === 'zef-funnel-one';
      const isFunnelTwo = funnel?.type === 'zef-funnel-two';
      const hasRatingAnswer = 'zefSurveyUserRating' in answers;
      const showFunnel =
        (!data.funnelHidden && !hasRatingAnswer) || (!data.funnelHidden && hasRatingAnswer && !data.signupSubmitted);

      return (isFunnelOne || isFunnelTwo) && showFunnel;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<boolean>;

  readonly showFunnels = combineLatest([
    this.viewState,
    this.showFunnelOne,
    this.showFunnelTwo,
    // add other funnels
  ]).pipe(
    map(([viewState, ...shownFunnels]) => viewState === ViewState.Outcomes && shownFunnels.some(Boolean)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  ) as Observable<boolean>;

  constructor(@Inject(DATE_TIME_PROVIDER) private dt: Observable<Date>, private tm: TriggerManager) {}

  updateFunnel(data: Partial<FunnelData>) {
    const value: FunnelData = { ...this.funnelData.value, ...data };
    this.funnelData.next(value);
  }

  resetFunnel() {
    this.funnelData.next({
      emailSubmitted: false,
      emailShown: false,
      signupSubmitted: false,
      funnelHidden: false,
      containerHeight: 0,
    });
  }

  updateDimensions(change: any) {
    this.playerDimensions.next({ ...this.playerDimensions.value, ...change });
  }
}

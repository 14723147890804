<zef-promo-dialog backgroundImage="assets/images/promo-compare-survey.png" requiredPlan="plan_genius">
  <span dialog-title i18n>Compare Surveys</span>

  <div dialog-list>
    <span i18n>Combine multiple surveys to analyze together</span>
    <span i18n>Compare results between different surveys</span>
    <span i18n>Do multi-survey trend analysis</span>
  </div>

  <span dialog-button i18n>Get Survey Comparison</span>
</zef-promo-dialog>

import { ChangeDetectionStrategy, Component, HostBinding, Input, SimpleChanges, OnChanges } from '@angular/core';

export type ChipType = 'small' | 'medium' | 'large' | 'property' | 'hashtag';

@Component({
  selector: 'zef-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Chip implements OnChanges {
  @Input()
  color?: string;

  @Input()
  type: ChipType = 'medium';

  @Input()
  class?: string;

  @Input()
  @HostBinding('class.chip-disabled')
  disabled: boolean;

  @HostBinding('class')
  classNames: string = '';

  @HostBinding('tabindex')
  tabindex: number = -1;

  private typeToTextClass: Record<ChipType, string> = {
    small: 'zef-text-p3',
    medium: 'zef-text-p3-11',
    large: 'zef-text-p1',
    property: 'zef-text-p1',
    hashtag: 'zef-text-p1-muted',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.shouldUpdateWithChanges(changes)) {
      this.classNames = this.getClasses().join(' ');
    }
  }

  protected getClasses(): string[] {
    let color = this.color;

    if (this.type === 'property') {
      color = 'primary-light';
    }

    if (this.type === 'hashtag') {
      color = 'space-darkest';
    }

    return [
      ...(color ? [`zef-bgcolor-${color}`] : []),
      ...(this.type ? [`zef-chip-${this.type}`] : []),
      ...(this.type ? [this.typeToTextClass[this.type]] : []),
      ...(this.class?.split(' ') || []),
    ];
  }

  protected shouldUpdateWithChanges(changes: SimpleChanges): boolean {
    return !!(changes.class || changes.type || changes.color);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthError } from '@auth/auth.enum';
import { Select, Store } from '@ngxs/store';
import { ActionsState } from '@shared/states/actions.state';
import { AuthenticationError, SendPasswordResetEmail } from '@shared/states/auth.actions';
import { isEmail } from 'class-validator';
import { Observable } from 'rxjs';

@Component({
  selector: 'auth-signin-method',
  templateUrl: './signin-method.component.html',
})
export class SigninMethod {
  public email: string = '';
  public password: string = '';
  public isResetEmailSent: boolean = false;

  readonly AuthError = AuthError;
  @Input() authError: { code: AuthError; message: string } | null = null;
  @Output() select = new EventEmitter();

  @Select(ActionsState.whileAction(SendPasswordResetEmail))
  readonly sending$!: Observable<boolean>;

  @Select(ActionsState.completedAction(SendPasswordResetEmail))
  readonly sent$!: Observable<boolean>;

  constructor(private store: Store) {}

  onDialogClose() {
    this.select.next({ method: 'init' });
    this.isResetEmailSent = false;
  }

  clearError() {
    this.store.dispatch(new AuthenticationError(null));
    this.authError = null;
  }

  signInWithSSO() {
    this.select.next({ method: 'sso' });
  }

  signInWithGoogle() {
    this.select.next({ method: 'google' });
  }

  signInWithEmail(email: string, password: string) {
    this.select.next({ method: 'password', email, password });
  }

  changeMethod() {
    this.select.next({ method: 'change' });
  }
}

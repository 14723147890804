/**
 * Shareable thumbs module.
 *
 * @unstable
 */

import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';

import { CreateThumb } from '@home/shared/components/create-thumb/create-thumb.component';
import { SurveyThumb } from '@home/shared/components/survey-thumb/survey-thumb.component';
import { FolderThumb } from '@home/shared/components/folder-thumb/folder-thumb.component';
import { DiscoverThumb } from '@home/shared/components/discover-thumb/discover-thumb.component';

import { IntegrationSharedModule } from '@home/+settings/+integrations/integration-shared.module';

@NgModule({
  imports: [SharedModule, IntegrationSharedModule],
  declarations: [CreateThumb, DiscoverThumb, SurveyThumb, FolderThumb],
  exports: [CreateThumb, DiscoverThumb, SurveyThumb, FolderThumb],
})
export class ThumbsModule {}

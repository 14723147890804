<div
  class="z-fx-col z-fx-auto report-card"
  [ngClass]="{ group: settings.isGroup }"
  (elementResize)="onCardHeaderResize($event)"
>
  <div #header class="card-header" [style.max-height.px]="headerTextArea">
    <div class="z-fx z-fx-start-start">
      <div #headerText *ngIf="!cardSettings.editChart.display" class="z-fx-auto header">
        <ng-content></ng-content>
        <mat-icon *ngIf="showLessButton" fontSet="md" class="show-less-button" (click)="shrinkHeader()">
          expand_less
        </mat-icon>
      </div>
      <mat-icon
        *ngIf="!cardSettings.editChart.display && showMoreButton"
        class="show-more-button"
        [class.mobile]="mobileView"
        (click)="expandHeader()"
      >
        more
      </mat-icon>
      <mat-form-field *ngIf="cardSettings.editChart.display">
        <input matInput [value]="settings.title" (input)="renameChart($event.target.value)" />
      </mat-form-field>
      <div class="z-fx z-fx-start-center z-fx-gap-8">
        <button
          *ngIf="!fullscreen && !mobileView"
          color="secondary"
          mat-icon-button
          class="zef-icon-button"
          (click)="toggleFullScreen()"
          [matTooltip]="tooltip1"
        >
          <mat-icon>fullscreen</mat-icon>
        </button>
        <button
          *ngIf="fullscreen"
          color="secondary"
          mat-icon-button
          class="zef-icon-button"
          (click)="toggleFullScreen()"
          [matTooltip]="tooltip2"
        >
          <mat-icon>fullscreen_exit</mat-icon>
        </button>

        <chart-menu
          *ngIf="explorable"
          [pinned]="settings.isPinned"
          [editRights]="
            (Rights.hasRights(Rights.EDIT, surveyRights) || Rights.hasRights(Rights.ADMIN, userRights)) && !sampleData
          "
          [isCustomCard]="settings.isCustomCard"
          [isSharedReport]="isSharedReport"
          [isDataTableOpen]="cardSettings?.dataTable?.display"
          [showDataTableSwitch]="showDataTableSwitch(true)"
          (editChartMenu)="editChart($event)"
          (chartBuilder)="openChartBuilder($event)"
          (duplicateChart)="duplicateThisChart($event)"
          (removeChart)="removeChart($event)"
          (hideChart)="hideChart($event)"
          (pinChart)="pin($event)"
          (openDataTable)="dataTableSwitch()"
        ></chart-menu>
      </div>
    </div>
  </div>

  <div *ngIf="cardSettings.editChart.display" [style.flex]="'0 0 ' + cardSettings.editChart.height + 'px'">
    <div #editChartEl class="z-fx-col z-fx-gap-16 settings" (elementResize)="onEditChartResize($event)">
      <div class="settings-item">
        <span class="zef-text-p2" i18n>Select chart type:</span>
        <br />
        <mat-icon
          *ngFor="let type of chartTypes.CHARTTYPES"
          class="settings-icon"
          [class.hidden]="!isRecommended(type)"
          [class.selected]="settings.chartSettings?.type === type"
          (click)="changeChartType(type)"
          [matTooltip]="chartTypes.ChartNames[type]"
        >
          {{ chartTypes.ChartIcons[type] }}
        </mat-icon>
      </div>

      <div *ngIf="scaleTypeSelector" class="settings-item z-fx-col z-fx-center-start">
        <span class="zef-text-p2" i18n>Scale of values:</span>
        <div class="setting-item-options z-fx z-fx-start-center">
          <mat-icon
            class="settings-icon"
            [class.selected]="settings.chartSettings?.scale !== 'percentage'"
            (click)="changeScaleType('absolute')"
            [matTooltip]="tooltip3"
          >
            {{ chartTypes.ChartIcons['absolute'] }}
          </mat-icon>
          <mat-icon
            class="settings-icon"
            [class.selected]="settings.chartSettings?.scale === 'percentage'"
            (click)="changeScaleType('percentage')"
            [matTooltip]="tooltip4"
          >
            {{ chartTypes.ChartIcons['percentage'] }}
          </mat-icon>
          <mat-divider [vertical]="true" class="zef-divider-24 divider"></mat-divider>
          <mat-slide-toggle
            class="toggle-slider"
            [checked]="settings.chartSettings?.showNumbers"
            (change)="showValuesSwitch($event)"
          ></mat-slide-toggle>
          <span class="zef-text-p2" i18n>Show values</span>
        </div>
      </div>

      <div *ngIf="summaryChartComparisonModeSelector" class="settings-item">
        <span class="zef-text-p2" i18n>Comparison format:</span>
        <br />
        <mat-icon
          class="settings-icon"
          fontSet="md"
          [class.selected]="settings.chartSettings?.summaryChartComparisonMode === 'grouped'"
          (click)="changesummaryChartComparisonMode('grouped')"
          [matTooltip]="tooltip5"
        >
          view_comfy
        </mat-icon>
        <mat-icon
          class="settings-icon"
          fontSet="md"
          [class.selected]="settings.chartSettings?.summaryChartComparisonMode === 'joined'"
          (click)="changesummaryChartComparisonMode('joined')"
          [matTooltip]="tooltip6"
        >
          view_compact
        </mat-icon>
      </div>

      <div *ngIf="zScoredValuesSelector" class="settings-item">
        <ng-container i18n>Use z-scored values:</ng-container>
        <br />
        <mat-slide-toggle
          [checked]="settings.chartSettings?.zScoredValues"
          (change)="useZScoredValuesSwitch($event)"
        ></mat-slide-toggle>
      </div>

      <div class="settings-item">
        <div class="z-fx z-fx-end-center z-fx-gap-8 action-buttons">
          <button mat-raised-button color="secondary" class="cancel-button" i18n (click)="cancelEditChart()">
            Cancel
          </button>
          <button mat-raised-button color="primary" class="ok-button" i18n (click)="editChart(false)">OK</button>
        </div>
      </div>
    </div>
  </div>

  <chart
    class="z-fx-col z-fx-auto chart"
    [content]="chartContent"
    [settings]="settings.chartSettings"
    [transition]="transition"
    [update]="chartContent?.timestamp"
    [filtering]="explorable"
    [touchDevice]="touchDevice"
    [filtersDemo]="filtersDemo"
    [trendHoverInput]="trendHoverThrottled"
    [isSharedReport]="isSharedReport"
    [anonymityTreshold]="anonymityTreshold"
    [anonymityStatus]="anonymityStatus"
    [extraAnonymityProtection]="extraAnonymityProtection"
    [crossfilter]="crossfilter"
    (trendHoverOutput)="trendHoverOutput.emit($event)"
    (settingsChange)="settingsChange($event)"
    (changeTimePeriod)="changeTimePeriod($event)"
  ></chart>

  <chart
    *ngIf="exportingOn"
    class="export-chart"
    [style.width]="'1000px'"
    [content]="chartContent"
    [settings]="settings.chartSettings"
    [anonymityTreshold]="anonymityTreshold"
    [anonymityStatus]="anonymityStatus"
    [transition]="transition"
    [update]="chartContent?.timestamp"
    [size]="exportSize"
  ></chart>

  <div *ngIf="showDataTableSwitch()" class="z-fx-none data-table">
    <div class="dt-switch" (click)="dataTableSwitch()">{{ cardSettings.dataTable.text }}</div>
  </div>

  <div
    #dataTable
    *ngIf="cardSettings.dataTable.display && showDataTableSwitch() && !anonymityStatus.hide && !anonymityStatus.trend"
    [style.padding-bottom]="dataTablePadding"
    chartDataTable
    [data]="chartDataTableData.data"
    [transitionDuration]="transition"
  ></div>
</div>

<div class="nav-menu zef-text-p1">
  <a class="nav-menu-item large no-margin" routerLink="/profile">
    <div class="z-fx z-fx-start-center z-fx-gap-16">
      <zef-avatar class="z-fx-none" [emblem]="true" [identity]="identity$ | async"></zef-avatar>

      <div class="z-fx-col z-fx-gap-4">
        <div class="zef-text-h3 ellipsis">
          {{ (identity$ | async)?.name || (userInfo$ | async)?.displayName }}
        </div>

        <div class="zef-text-lighter zef-text-p2 ellipsis">
          <span class="show-on-hover" i18n>My settings</span>

          <span class="hide-on-hover">
            {{ (identity$ | async)?.email || (userInfo$ | async)?.email }}
          </span>
        </div>
      </div>
    </div>

    <mat-icon>keyboard_arrow_right</mat-icon>
  </a>

  <mat-divider></mat-divider>

  <div *ngIf="(invites$ | async) || [] as invites" class="nav-menu-org" [class.with-padding]="hasOrgActions$ | async">
    <a *ngIf="team$ | async as team" class="nav-menu-item large" routerLink="/settings/org">
      <div class="z-fx z-fx-start-center z-fx-gap-16">
        <div
          class="z-fx-none zef-logo-image"
          [style.background-image]="teamLogo(team) | encodeURI : 'url' | safeStyle"
        ></div>

        <div class="z-fx-col z-fx-gap-4">
          <div class="zef-text-h3 ellipsis" [class.zef-color-primary]="!team.name">
            <ng-container *ngIf="team.name">
              {{ team.name }}
            </ng-container>

            <ng-container *ngIf="!team.name" i18n>Set up organization</ng-container>
          </div>

          <div class="zef-text-lighter zef-text-p2 ellipsis" i18n>Organization settings</div>
        </div>
      </div>

      <mat-icon>keyboard_arrow_right</mat-icon>
    </a>

    <mat-divider *ngIf="hasOrgActions$ | async" class="shift-right"></mat-divider>

    <div *ngIf="isAdmin$ | async" class="nav-menu-item" (click)="onInvitePeopleClick()">
      <mat-icon>contact_new</mat-icon>

      <span i18n>Invite people</span>
    </div>

    <div *ngIf="canSwitch$ | async" class="nav-menu-item" (click)="onSwitchTeamClick()">
      <mat-icon>organization</mat-icon>

      <span i18n>Switch organization</span>

      <span *ngIf="invites.length" class="nav-menu-indicator">
        {{ invites.length }}
      </span>
    </div>

    <mat-divider [class.shift-bottom]="hasOrgActions$ | async"></mat-divider>
  </div>

  <div class="nav-menu-actions">
    <div class="nav-menu-item" [@highlight]="menuOptions?.highlightHelp" (click)="onHelpClick()">
      <mat-icon>action_academy</mat-icon>

      <span i18n>Help</span>
    </div>

    <a *ngIf="isZefAdmin$ | async" class="nav-menu-item ink" routerLink="/admin">
      <mat-icon>zef_logo_console</mat-icon>

      <span i18n>Switch to Console</span>
    </a>

    <div class="nav-menu-item alert" routerLink="/logout">
      <mat-icon>power</mat-icon>

      <span i18n>Sign out</span>
    </div>
  </div>
</div>

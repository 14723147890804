import { Component, Input } from '@angular/core';

@Component({
  selector: 'slider-images',
  templateUrl: './slider-images.component.html',
  styleUrls: ['./slider-images.component.scss'],
})
export class SliderImages {
  @Input() images: string[] = [];
}

import { first } from 'rxjs/operators';

import { environment } from '@env/environment';

import { Injectable } from '@angular/core';

import { QuestionsManager } from '@shared/services/questions-manager.service';
import { LocalesManager } from '@shared/services/locales-manager.service';

import { WordCounter } from '@report/shared/services/word-counter.service';

import { QuestionData, SliderLabelsData, SliderValuesData } from '@shared/models/survey.model';
import { AnswerData, AnswererData } from '@shared/models/answer.model';
import {
  DimensionData,
  DimensionDataItem,
  LocaleString,
  LocaleStrings,
  PreparedAnswersData,
  TextData,
} from '@shared/models/report.model';

import { Questions } from '@shared/enums/questions.enum';
import { Emotions } from '@report/shared/enums/emotions.enum';
import { isContactEntry } from '@report/shared/utilities/report.utilities';

/**
 * This service will calculate z-scores for the individual users
 */
@Injectable({
  providedIn: 'root',
})
export class DataPreparation {
  public dimensions: DimensionData = {};

  public textLabels: TextData = {};
  public textAnswers: TextData = {};
  public textContacts: TextData = {};

  public timeValues: (string | number)[] = [];

  public zLocalGroups: { [survey: string]: any } = {};
  public zGlobalGroups: { [survey: string]: any } = {};

  private sliderObj: { [survey: string]: any } = {};
  private reducedSteps: { [survey: string]: { [s: string]: { x: number; y: number } } } = {};

  private questions: { [survey: string]: QuestionData[] } = {};
  private answers: any = [];
  private mappings: { [key: string]: any } = {};

  private twod: any = {};
  private dimensionList: any[] = [];
  private answererList: number[] = [];
  private answerDateList: number[] = [];
  private otherDatesList: { [s: string]: number[] } = {};
  private dimensionMap: Map<string, number>;

  private rounder: number = Math.pow(10, 5);

  private timePeriod: string = 'day';

  constructor(public qm: QuestionsManager, public wc: WordCounter, private lm: LocalesManager) {}

  /**
   * Prepares data to be used in crossfilter.
   *
   * @param oldData       Old already prepared data.
   * @param newData       New data from backend.
   * @param sampleData    Is it just sample data?
   * @param newestRecord  Latest answer record from backend.
   * @param questions     Questions used for z-scoring purposes.
   * @param dimensions    Dimensions used for preparing data.
   * @param contacts    Contacts used for preparing data.
   * @param timePeriod    Time period used for preparing data.
   * @returns             Prepared data for crossfilter.
   */
  public prepare(
    oldData: PreparedAnswersData | null,
    newData: AnswererData[],
    sampleData: boolean,
    newestRecord: number | null,
    questions: { [survey: string]: QuestionData[] } = {},
    dimensions: DimensionData = {},
    timePeriod: string = 'day',
    mappings: { [key: string]: any } = {},
  ): PreparedAnswersData {
    this.timePeriod = timePeriod;
    this.questions = Object.assign({}, questions);
    this.mappings = mappings;

    if (oldData !== null) {
      this.answers = oldData.answers;
      this.answererList = oldData.answerers;
      this.answerDateList = oldData.answerDates || [];
      this.dimensionList = oldData.indexes;
      this.textLabels = oldData.textLabels || {};
      this.textContacts = oldData.textContacts || {};
      this.textAnswers = oldData.textAnswers || {};
      this.timeValues = dimensions['time'].values ? dimensions['time'].values : [];
    } else {
      this.answers = [];
      this.answererList = [];
      this.answerDateList = [];
      this.dimensionList = [];
      this.textLabels = {};
      this.textContacts = {};
      this.textAnswers = {};
      this.dimensionList[0] = 'id';
      this.dimensionList[1] = 'time';
      this.dimensionList[2] = 'outcome';
      this.dimensionList[3] = 'lang';
      this.dimensionList[4] = 'shareLink';
      this.dimensionList[5] = 'hashtags';
      this.timeValues = [];
    }

    for (let i = 0, len = newData.length; i < len; i++) {
      const time = this.parseTime(newData[i] && newData[i]['time'], this.timePeriod);

      if (time) {
        this.timeValues.push(time);
      }

      this.parseFields(newData[i], dimensions);
    }

    this.dimensions = Object.assign({}, dimensions);

    if (this.dimensions['time']) {
      this.dimensions['time'].values = Array.from(new Set(this.timeValues));
    }

    for (const dim in this.dimensions) {
      if (
        dim !== 'id' &&
        dim !== 'time' &&
        dim !== 'outcome' &&
        dim !== 'lang' &&
        dim !== 'shareLink' &&
        dim !== 'hashtags' &&
        (oldData === null || this.dimensionList.indexOf(dim) < 0)
      ) {
        this.dimensionList.push(dim);
      }
      if (oldData === null && this.dimensions[dim].originalTypeSpecifier === 'text-words') {
        this.dimensions[dim]['values'] = [];
        this.dimensions[dim]['valueGroupKeys'] = ['NOUN', 'ADJ', 'VERB', 'ADV'];
        this.dimensions[dim]['valueGroupTypes'] = ['PoS', 'PoS', 'PoS', 'PoS'];
        this.dimensions[dim]['valueGroupValues'] = [[], [], [], []];

        if (!this.dimensions[dim]['labelsValueGroup']) {
          this.dimensions[dim]['labelsValueGroup'] = {};
        }

        this.dimensions[dim]['labelsValueGroup']['NOUN'] = $localize`:@@zef-i18n-00527:Nouns`;
        this.dimensions[dim]['labelsValueGroup']['ADJ'] = $localize`:@@zef-i18n-00528:Adjectives`;
        this.dimensions[dim]['labelsValueGroup']['VERB'] = $localize`:@@zef-i18n-00529:Verbs`;
        this.dimensions[dim]['labelsValueGroup']['ADV'] = $localize`:@@zef-i18n-00530:Adverbs`;
      } else if (
        oldData === null &&
        this.dimensions[dim].originalTypeSpecifier !== 'text-sentiment' &&
        (this.dimensions[dim].originalType === Questions.FREE_TEXT ||
          this.dimensions[dim].originalType === Questions.INPUT_STRING)
      ) {
        this.dimensions[dim]['values'] = [];
        this.dimensions[dim]['valueGroupKeys'] = [];
        this.dimensions[dim]['valueGroupTypes'] = [];
        this.dimensions[dim]['valueGroupValues'] = [];

        if (!this.dimensions[dim]['labelsValueGroup']) {
          this.dimensions[dim]['labelsValueGroup'] = {};
        }
      }
      if (
        this.dimensions[dim].originalType === Questions.SLIDER_2D ||
        this.dimensions[dim].originalType === Questions.SLIDER_1R
      ) {
        if (!this.twod[dim.split(':')[0]]) {
          this.twod[dim.split(':')[0]] = [];
        }
        const itemIndex: number = this.twod[dim.split(':')[0]].findIndex((item) => item.key === dim);

        if (itemIndex >= 0) {
          this.twod[dim.split(':')[0]][itemIndex] = { key: dim, index: this.dimensionList.indexOf(dim) };
        } else {
          this.twod[dim.split(':')[0]].push({ key: dim, index: this.dimensionList.indexOf(dim) });
        }
      }
    }
    this.dimensionMap = new Map(this.dimensionList.map((val, index) => [val, index]));

    this.zLocalGroups = {};
    this.zGlobalGroups = {};
    this.sliderObj = {};
    this.reducedSteps = {};

    for (const survey in this.questions) {
      this.sliderObj[survey] = this.generateSliderObj(this.questions[survey]);
      this.zLocalGroups[survey] = this.generateLocalZScoreGroups(this.questions[survey]);
      this.zGlobalGroups[survey] = this.generateGlobalZScoreGroups(this.questions[survey]);
      this.reducedSteps[survey] = this.generateSliderStepReducer(this.questions[survey]);
    }

    const indexMap = new Map();
    for (let i = 0, len = this.answererList?.length; i < len; i++) {
      indexMap.set(this.answererList[i], i);
    }
    const indexes: Set<any> = new Set(this.answererList || []);

    for (let i = 0, len = newData?.length; i < len; i++) {
      if (indexMap.get(newData[i]['id']) == null) {
        indexMap.set(newData[i]['id'], indexMap.size);
      }

      indexes.add(newData[i]['id']);
    }

    this.answererList = Array.from(indexes);

    for (let i = 0, len = newData.length; i < len; i++) {
      const index = indexMap.get(newData[i]['id']);

      this.parseAnswerData(newData[i], index);
      this.calculateLocalZScores(newData[i], index);
      this.calculateGlobalZScores(newData[i], index);
    }

    return {
      answers: this.answers,
      indexes: this.dimensionList,
      answerers: this.answererList,
      answerDates: this.answerDateList,
      otherDates: this.otherDatesList,
      sampleData,
      newestRecord,
      textAnswers: this.textAnswers,
      textContacts: this.textContacts,
      textLabels: this.textLabels,
    };
  }

  public changeTimePeriod(
    data: PreparedAnswersData,
    period: string = 'day',
    dimensions: DimensionData = {},
  ): PreparedAnswersData {
    const timeIndex = data.indexes.indexOf('time');
    const idIndex = data.indexes.indexOf('id');
    let answerDates;

    if (data.answerDates && data.answerDates.length === data.answers.length) {
      answerDates = data.answerDates;
    } else {
      answerDates = data.answers.map((answer) =>
        answer[timeIndex][0] > -1 &&
        dimensions['time'] &&
        dimensions['time']['values'] &&
        dimensions['time']['values'].length > 0 &&
        dimensions['time']['values'][answer[timeIndex][0]] &&
        Number(dimensions['time']['values'][answer[timeIndex][0]]) >= 0
          ? Number(
              this.parseTime(new Date(Number(dimensions['time']['values'][answer[timeIndex][0]])).toISOString(), 'day'),
            )
          : null,
      );
      data.answerDates = answerDates;
    }

    this.timeValues = answerDates.map((date) => this.parseTime(new Date(date).toISOString(), period));

    if (this.dimensions['time']) {
      this.dimensions['time'].values = Array.from(new Set(this.timeValues));
    }

    dimensions['time'].values = Array.from(new Set(this.timeValues));

    // parsing other time dimensions
    for (const dim in dimensions) {
      if (dimensions[dim]?.scale === 'time' && dim !== 'time' && data.otherDates?.[dim]?.length) {
        const timeValues = data.otherDates[dim]
          .filter((date) => date)
          .map((date) => this.parseTime(new Date(date).toISOString(), period));

        if (this.dimensions[dim]) {
          this.dimensions[dim].values = Array.from(new Set(timeValues));
        }

        dimensions[dim].values = Array.from(new Set(timeValues));
      }
    }

    for (let a = 0, lena = data.answers.length; a < lena; a++) {
      if (data.answers[a][idIndex] === data.answerers[a]) {
        const rawDate = new Date(answerDates[a]).toISOString();
        const newDate = this.parseTime(rawDate, period);
        data.answers[a][timeIndex] = [[dimensions['time'] ? dimensions['time']['values'].indexOf(newDate) : '']];

        // parsing other time dimensions
        for (const dim in dimensions) {
          if (
            dimensions[dim]?.scale === 'time' &&
            dim !== 'time' &&
            data.otherDates?.[dim]?.[a] != null &&
            !isNaN(data.otherDates?.[dim]?.[a]) &&
            data.indexes.indexOf(dim) >= 0
          ) {
            const rawOtherDate = new Date(data.otherDates?.[dim]?.[a]).toISOString();
            const newOtherDate = this.parseTime(rawOtherDate, period);
            data.answers[a][data.indexes.indexOf(dim)] = [[dimensions[dim]['values'].indexOf(newOtherDate)]];
          }
        }
      }
    }

    return data;
  }

  /**
   * Detects if there is major changes in Dimensions.
   *
   * @param oldData       Previous dimensions.
   * @param newData       New dimensions.
   * @returns             Boolean value if there is major changes.
   */
  public detectMajorDimensionChanges(oldData: DimensionData, newData: DimensionData) {
    const oldItems = Object.keys(oldData);
    const newItems = Object.keys(newData);

    if (oldItems.length !== newItems.length) {
      return true;
    }

    for (const item in newData) {
      const stringData = (dim) => {
        let string = '';
        string += dim.group;
        string += dim.scale;
        string += JSON.stringify(dim.values);
        string += dim.valueScaleLinear ? JSON.stringify(dim.valueScaleLinear) : '';
        string += dim.localZComparison ? JSON.stringify(dim.localZComparison) : '';
        string += dim.globalZComparison ? JSON.stringify(dim.globalZComparison) : '';

        return string;
      };

      if (newData[item].scale === 'categorical' || newData[item].scale === 'linear') {
        if (stringData(newData[item]) !== stringData(oldData[item])) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Trying to find survey key for an answer.
   *
   * @param questionKey      Question key.
   * @returns Survey key string.
   */
  fallbackSurvey(questionKey: string): string {
    for (const dim in this.dimensions) {
      if (dim.indexOf(questionKey) >= 0) {
        if (this.dimensions[dim] && this.dimensions[dim]['survey']) {
          return this.dimensions[dim]['survey'];
        }
      }
    }

    return '';
  }

  /**
   * Parsing all possible date values to one timeValues array.
   *
   * @param data      Data from answerer.
   * @param i         Index of answerer.
   */
  parseTime(date: string, period: string = 'day'): string {
    const timeArr = date ? date.match(/(\d+)/g) : [];
    const time = timeArr ? timeArr.map((val) => Number(val)) : [];
    const safariTime = new Date(Date.UTC(time[0], time[1] - 1, time[2], time[3], time[4], time[5]));

    if (period === 'week') {
      const startDate = safariTime.getDate();
      const startDay = safariTime.getDay();
      const startDiff = startDate - startDay + (startDay === 0 ? -6 : 1);
      const safariWeek = new Date(Date.UTC(safariTime.getFullYear(), safariTime.getMonth(), startDiff, 0, 0, 0));

      return new Date(safariWeek.toDateString()).valueOf().toString();
    } else if (period === 'month') {
      const safariMonth = new Date(Date.UTC(safariTime.getFullYear(), safariTime.getMonth(), 1, 0, 0, 0));

      return new Date(safariMonth.toDateString()).valueOf().toString();
    } else if (period === 'year') {
      const safariYear = new Date(Date.UTC(safariTime.getFullYear(), 0, 1, 0, 0, 0));

      return new Date(safariYear.toDateString()).valueOf().toString();
    } else {
      return new Date(safariTime.toDateString()).valueOf().toString();
    }
  }

  /**
   * Parsing different respondent fields to dimensions.
   *
   * @param data      Data from answerer.
   * @param i         Index of answerer.
   */
  parseFields(data: AnswererData, dimensions: DimensionData) {
    if (data.fields) {
      for (const field in data.fields) {
        if (!isContactEntry(field)) {
          const dimKey = 'fields-' + field;

          if (!dimensions[dimKey]) {
            dimensions[dimKey] = {} as DimensionDataItem;
            dimensions[dimKey].scale = 'categorical';
            dimensions[dimKey].title = field;
            dimensions[dimKey].originalType = 'respondent-field';
            dimensions[dimKey].key = dimKey;
            dimensions[dimKey].survey = data.survey;
            dimensions[dimKey].values = [];
            dimensions[dimKey].labelsCategorical = {};
          }

          if (dimensions[dimKey]['scale'] !== 'contact-text') {
            const values: string[] = Array.isArray(data.fields[field])
              ? (data.fields[field] as string[])
              : [data.fields[field] as string];

            for (let v = 0, len = values.length; v < len; v++) {
              const val: number | string =
                dimensions[dimKey].scale === 'linear'
                  ? !isNaN(Number(values[v]))
                    ? Number(values[v])
                    : null
                  : dimensions[dimKey].scale === 'time'
                  ? this.parseTime(values[v], this.timePeriod)
                  : values[v];

              if (val != null && dimensions[dimKey].values.indexOf(val) < 0 && val !== '__hidden__') {
                dimensions[dimKey].values.push(val);
                if (val.toString() !== '__masked__') {
                  dimensions[dimKey]['labelsCategorical'][val] = val.toString();
                } else if (!dimensions[dimKey]['labelsCategorical'][val]) {
                  dimensions[dimKey]['labelsCategorical']['__masked__'] = $localize`Small groups`;
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Parsing answers to crossfilter format and adding answers to answers array and answererList array.
   * In crossfilter we use only dimension indexes and answer value in dimension indexes.
   * On answers array absolute value is first item. [absolute, local z-score, global z-score]
   *
   * @param data      Data from answerer.
   * @param i         Index of answerer.
   */
  parseAnswerData(data: AnswererData, i: number) {
    const an: any[] = this.answers[i] ? this.answers[i] : [];

    const date = this.parseTime(data && data['time'], this.timePeriod);

    const mappedOutcome: string =
      this.dimensions['outcome'] &&
      data.result &&
      data.result.outcome &&
      this.mappings &&
      this.mappings[data.survey] &&
      this.mappings[data.survey]['outcomes'] &&
      this.mappings[data.survey]['outcomes']['outcomes'] &&
      this.mappings[data.survey]['outcomes']['outcomes'].find(
        (oc) => oc && oc.orig && oc.orig.$key === data.result.outcome && oc.mappedTo && !oc.mappedTo.manualMerge,
      )
        ? this.mappings[data.survey]['outcomes']['outcomes'].find(
            (ch) => ch && ch.orig && ch.orig.$key === data.result.outcome,
          )['mappedTo']['$key']
        : '';
    const outcomeKey: string = !mappedOutcome
      ? this.dimensions['outcome'] && data.result && data.result.outcome
        ? `${data.survey}/${data.result.outcome}`
        : ''
      : `${this.mappings[data.survey]['outcomes']['survey']}/${mappedOutcome}`;

    an[0] = data.id;
    an[1] = [[this.dimensions['time'] ? this.dimensions['time'].values.indexOf(date) : '']];
    an[2] = [[this.dimensions['outcome'] && outcomeKey ? this.dimensions['outcome'].values.indexOf(outcomeKey) : null]];
    an[3] = [[this.dimensions['lang'] && data.language ? this.dimensions['lang'].values.indexOf(data.language) : null]];
    // TODO: We could/should use indexOf, but as far as there is old reports
    //       stored with urls instead of share keys we need to use findIndex
    an[4] = [
      [
        this.dimensions['shareLink'] && (data.link || data.location)
          ? this.dimensions['shareLink'].values.findIndex((value: string) =>
              value.includes(
                data.link ||
                  data.location
                    .replace(/(^\w+:|^)/, '')
                    .replace('index.html', '')
                    .split('?')[0]
                    .split('&')[0]
                    .split('#')[0]
                    .replace(/\/$/, '')
                    .replace(`${environment.publicUrl}/s/`, '')
                    .replace(`${environment.surveyAddress}/`, ''),
              ),
            )
          : null,
      ],
    ];
    if (data.hashtags && data.hashtags.length > 0 && this.dimensions['hashtags']?.values) {
      an[5] = [[]];

      for (let h = 0, lenh = data.hashtags.length; h < lenh; h++) {
        const tag: string = data.hashtags[h];
        const index: number = this.dimensions['hashtags'].values.indexOf(tag);

        if (tag && index >= 0) {
          an[5][0].push(index);
        } else if (tag) {
          an[5][0].push(this.dimensions['hashtags'].values.length);
          this.dimensions['hashtags'].values.push(tag);
          this.dimensions['hashtags'].labelsCategorical[tag] = tag;
        }
      }
    } else {
      an[5] = [[null]];
    }

    const surveyIndex: number = this.dimensionMap.get('survey');
    if (surveyIndex != null && this.dimensions['survey'] && data.survey) {
      an[surveyIndex] = [[this.dimensions['survey']['values'].indexOf(data.survey)]];
    }

    const userRatingIndex: number = this.dimensionMap.get('zefSurveyUserRating');
    if (
      userRatingIndex != null &&
      this.dimensions['zefSurveyUserRating'] &&
      data.answers &&
      data.answers['zefSurveyUserRating']
    ) {
      const val = data.answers['zefSurveyUserRating']['value'];

      an[userRatingIndex] = [[this.dimensions['zefSurveyUserRating']['values'].indexOf(val)]];
    }

    this.parseAnswers(data, i, an);

    if (data.fields) {
      for (const field in data.fields || []) {
        const fieldKey = 'fields-' + field;
        const index = this.dimensionMap.get(fieldKey);
        const values: string[] = Array.isArray(data.fields[field])
          ? (data.fields[field] as string[])
          : [data.fields[field] as string];

        if (index != null && this.dimensions[fieldKey] && data.fields[field] !== '__hidden__') {
          an[index] = [[]];

          for (let v = 0, len = values.length; v < len; v++) {
            if (this.dimensions[fieldKey].scale === 'contact-text') {
              if (!this.textContacts[fieldKey]) {
                this.textContacts[fieldKey] = [];
              }
              if (an[index][1]) {
                this.textContacts[fieldKey][an[index][1]] = values[v];
              } else {
                an[index][1] = [this.textContacts[fieldKey].length];
                this.textContacts[fieldKey].push(values[v]);
              }
            } else if (this.dimensions[fieldKey].scale === 'categorical') {
              an[index] = [[]];

              an[index][0].push(this.dimensions[fieldKey].values.indexOf(values[v]));
            } else if (values[v] != null && this.dimensions[fieldKey].scale === 'linear') {
              an[index] = [[]];

              if (!isNaN(Number(values[v]))) {
                an[index][0].push(this.dimensions[fieldKey].values.indexOf(Number(values[v])));
              }
            } else if (data.fields[field] && this.dimensions[fieldKey].scale === 'time') {
              if (!this.otherDatesList[fieldKey]) {
                this.otherDatesList[fieldKey] = [];
              }

              this.otherDatesList[fieldKey][i] = Number(this.parseTime(values[v], 'day'));

              an[index] = [[]];

              an[index][0].push(this.dimensions[fieldKey].values.indexOf(this.parseTime(values[v], this.timePeriod)));
            }
          }
        }
      }
    }

    this.answers[i] = an;
    this.answerDateList[i] = Number(this.parseTime(data && data['time'], 'day'));
    this.parseUserSegments(i);
  }

  /**
   * Parsing answers to crossfilter format.
   * In crossfilter we use only dimension indexes and answer value in dimension indexes.
   * On answers array absolute value is first item. [absolute, local z-score, global z-score]
   *
   * @param data      Data from answerer.
   * @param i         Index of answerer.
   * @param an        Answer array to be passed into crossfilter.
   */
  parseAnswers(data, i, an) {
    const answers = data.answers || {};
    for (const origA in answers) {
      const origSurvey = answers[origA].survey || data.survey || this.fallbackSurvey(origA);
      const isMapped =
        this.mappings &&
        this.mappings[origSurvey] &&
        this.mappings[origSurvey][origA] &&
        this.mappings[origSurvey][origA]['question'] &&
        this.mappings[origSurvey][origA]['question']['$key'];
      const survey = isMapped ? this.mappings[origSurvey][origA]['survey'] : origSurvey;
      const a = isMapped ? this.mappings[origSurvey][origA]['question']['$key'] : origA;
      const dimKey = survey + '/' + a;
      const index: number = this.dimensionMap.get(dimKey);
      const twod: any[] = index != null ? [] : this.twod[dimKey] ? this.twod[dimKey] : [];
      const oned: boolean =
        this.dimensions[dimKey] &&
        (this.dimensions[dimKey].originalType === Questions.SLIDER_1D ||
          this.dimensions[dimKey].originalType === Questions.SLIDER_1V ||
          this.dimensions[dimKey].originalType === Questions.SLIDER_NPS ||
          this.dimensions[dimKey].originalType === Questions.INPUT_NUMERIC);
      const text: boolean = this.dimensions[dimKey] && this.dimensions[dimKey].scale === 'text';
      const choice: boolean =
        Questions.choices.includes(this.dimensions[dimKey]?.originalType as Questions) ||
        this.dimensions[dimKey]?.originalType === Questions.INPUT_DROPDOWN;
      const phone: boolean = this.dimensions[dimKey]?.originalType === Questions.INPUT_PHONE;
      const valueProp: string = this.dimensions[dimKey]?.originalType === Questions.SLIDER_NPS ? 'scaled' : 'value';

      if (answers[origA][valueProp] && !text) {
        if (index >= 0) {
          an[index] = [[]];
        }

        let xIndex: number | null = null;
        let yIndex: number | null = null;
        const val = answers[origA][valueProp].split(';') || [];

        for (let v = 0, len = val.length; v < len; v++) {
          if (twod.length > 0) {
            if (v === 0 && this.reducedSteps?.[survey]?.[a] && this.reducedSteps[survey][a].x != null) {
              xIndex = this.dimensions[twod[v].key].values.indexOf(
                this.scaleReducedValue(
                  Number(val[v]),
                  this.reducedSteps[survey][a].x,
                  this.dimensions[twod[v].key].valueScaleLinear,
                ),
              );
              if (xIndex >= 0) {
                an[twod[v].index] = [[xIndex]];
              }
            } else if (
              v === 1 &&
              this.reducedSteps?.[survey]?.[a] &&
              this.reducedSteps[survey][a].y != null &&
              xIndex >= 0
            ) {
              yIndex = this.dimensions[twod[v].key].values.indexOf(
                this.scaleReducedValue(
                  Number(val[v]),
                  this.reducedSteps[survey][a].y,
                  this.dimensions[twod[v].key].valueScaleLinear,
                ),
              );

              if (yIndex >= 0) {
                an[twod[v].index] = [[yIndex]];
              } else {
                an[twod[0].index] = [[]];
              }
            } else if (
              v === 1 &&
              this.dimensions[twod[v].key].originalType === Questions.SLIDER_1R &&
              this.reducedSteps?.[survey]?.[a] &&
              this.reducedSteps[survey][a].x != null &&
              xIndex >= 0
            ) {
              yIndex = this.dimensions[twod[v].key].values.indexOf(
                this.scaleReducedValue(
                  Number(val[v]),
                  this.reducedSteps[survey][a].x,
                  this.dimensions[twod[v].key].valueScaleLinear,
                ),
              );
              if (yIndex >= 0) {
                an[twod[v].index] = [[yIndex]];
              } else {
                an[twod[0].index] = [[]];
              }
            } else {
              if (v === 0) {
                xIndex = this.dimensions[twod[v].key].values.indexOf(Number(val[v]));
                if (xIndex >= 0) {
                  an[twod[v].index] = [[xIndex]];
                }
              } else if (v === 1 && xIndex >= 0) {
                yIndex = this.dimensions[twod[v].key].values.indexOf(Number(val[v]));
                if (yIndex >= 0) {
                  an[twod[v].index] = [[yIndex]];
                } else {
                  an[twod[0].index] = [[]];
                }
              }
            }
          } else if (oned) {
            if (this.reducedSteps?.[survey]?.[a] && this.reducedSteps[survey][a].x != null) {
              const onedIndex: number | null = this.dimensions[dimKey]
                ? this.dimensions[dimKey].values.indexOf(
                    this.scaleReducedValue(
                      Number(val[v]),
                      this.reducedSteps[survey][a].x,
                      this.dimensions[dimKey].valueScaleLinear,
                    ),
                  )
                : null;
              if (onedIndex >= 0) {
                an[index][0].push(onedIndex);
              }
            } else {
              const onedIndex: number | null = this.dimensions[dimKey]
                ? this.dimensions[dimKey].values.indexOf(Number(val[v]))
                : null;
              if (onedIndex >= 0) {
                an[index][0].push(onedIndex);
              }
            }
          } else if (index >= 0 && choice && val[v]) {
            const parsedValues: string[] = val[v].match(/[^=]+/g);
            const mappedKey: string = this.mappings?.[origSurvey]?.[origA]?.['choices']?.find(
              (ch) => ch && ch.orig && ch.orig.$key === parsedValues[0] && ch.mappedTo && !ch.mappedTo.manualMerge,
            )
              ? this.mappings[origSurvey][origA]['choices'].find(
                  (ch) => ch && ch.orig && ch.orig.$key === parsedValues[0],
                )['mappedTo']['$key']
              : '';

            const choiceKey: string = !mappedKey ? `${data.survey}/${parsedValues[0]}` : `${survey}/${mappedKey}`;
            const otherKey: string = `${survey}/${a}:${parsedValues[0]}`;
            const otherIndex: number = this.dimensionMap.get(otherKey);

            an[index][0].push(this.dimensions[dimKey] ? this.dimensions[dimKey].values.indexOf(choiceKey) : '');

            if (otherIndex != null) {
              if (!an[otherIndex]) {
                an[otherIndex] = [[]];
              }

              if (parsedValues[1]) {
                // Word counter
                const rawAnswer = JSON.parse(JSON.stringify(answers[origA]));
                rawAnswer.value = parsedValues[1];
                if (!this.textLabels[otherKey]) {
                  this.textLabels[otherKey] = [];
                  this.dimensions[otherKey].values = [];
                }
                an[otherIndex][0] = this.wc.countWords(
                  rawAnswer,
                  this.dimensions[otherKey].values,
                  this.textLabels[otherKey],
                  this.dimensions[otherKey].locales,
                );

                // Saving of input texts
                if (!this.textAnswers[otherKey]) {
                  this.textAnswers[otherKey] = [];
                }
                if (an[otherIndex][1]) {
                  this.textAnswers[otherKey][this.answers[i][otherIndex][1]] = parsedValues[1];
                } else {
                  an[otherIndex][1] = [this.textAnswers[otherKey].length];
                  this.textAnswers[otherKey].push(parsedValues[1]);
                }
              } else if (
                !parsedValues[1] &&
                this.textAnswers[otherKey] &&
                this.answers[i] &&
                this.answers[i][otherIndex] &&
                this.answers[i][otherIndex][1] != null &&
                this.textAnswers[otherKey][this.answers[i][otherIndex][1]]
              ) {
                // Removing deleted free answers
                this.textAnswers[otherKey][this.answers[i][otherIndex][1]] = null;
              }
            }
          } else if (index >= 0) {
            an[index][0].push(this.dimensions[dimKey] ? this.dimensions[dimKey].values.indexOf(val[v]) : '');
          }
        }
      } else if (data.answers[origA].value || an[index]) {
        if (index >= 0 && !an[index]) {
          an[index] = [[]];
        }

        // Word counter
        const wordsDimKey: string = dimKey + ':words';
        const wordsIndex: number = this.dimensionMap.get(wordsDimKey);

        if (
          data.answers[origA].value &&
          wordsIndex != null &&
          (this.dimensions[dimKey].originalType === Questions.FREE_TEXT ||
            this.dimensions[dimKey].originalType === Questions.INPUT_STRING)
        ) {
          if (!an[wordsIndex]) {
            an[wordsIndex] = [[]];
          }

          if (!this.textLabels[wordsDimKey]) {
            this.textLabels[wordsDimKey] = [];
            this.dimensions[wordsDimKey].values = [];
          }
          an[wordsIndex][0] = this.wc.countWords(
            data.answers[origA],
            this.dimensions[wordsDimKey].values,
            this.textLabels[wordsDimKey],
            this.dimensions[wordsDimKey].locales,
            this.dimensions[wordsDimKey].valueGroupKeys,
            this.dimensions[wordsDimKey].valueGroupTypes,
            this.dimensions[wordsDimKey].valueGroupValues,
            this.dimensions[wordsDimKey].labelsValueGroup,
          );
        }

        // Sentiment
        const sentimentKey: string = dimKey + ':sentiment';
        const sentimentIndex: number = this.dimensionMap.get(sentimentKey);

        if (
          data.answers[origA].sentimentValue != null &&
          (this.dimensions[dimKey].originalType === Questions.FREE_TEXT ||
            this.dimensions[dimKey].originalType === Questions.INPUT_STRING) &&
          this.dimensions[sentimentKey] &&
          sentimentIndex != null
        ) {
          if (!an[sentimentIndex]) {
            an[sentimentIndex] = [[]];
          }
          an[sentimentIndex][0] = [
            this.dimensions[sentimentKey].values.indexOf(Math.round(data.answers[origA].sentimentValue * 10)),
          ];
        }

        // Text emotions
        const emotionsDimKey: string = dimKey + ':emotions';
        const emotionsIndex: number = this.dimensionMap.get(emotionsDimKey);

        if (
          data.answers[origA].value &&
          emotionsIndex != null &&
          (this.dimensions[dimKey].originalType === Questions.FREE_TEXT ||
            this.dimensions[dimKey].originalType === Questions.INPUT_STRING)
        ) {
          if (!an[emotionsIndex]) {
            an[emotionsIndex] = [[]];
          }

          if (!this.dimensions[emotionsDimKey].values) {
            this.dimensions[emotionsDimKey].values = [];
          }
          if (!this.dimensions[emotionsDimKey].labelsCategorical) {
            this.dimensions[emotionsDimKey].labelsCategorical = {};
          }
          an[emotionsIndex][0] = this.parseEmotions(
            data.answers[origA],
            this.dimensions[emotionsDimKey].values,
            this.dimensions[emotionsDimKey].labelsCategorical,
            this.dimensions[emotionsDimKey].locales,
            this.dimensions[emotionsDimKey].localeStrings,
          );
        }

        // Saving of input texts
        if (
          data.answers[origA].value &&
          (this.dimensions[dimKey].originalType.split('-')[0].replace('free', 'input') === Questions.INPUT ||
            this.dimensions[dimKey].originalType === Questions.FILE_UPLOAD)
        ) {
          if (!this.textAnswers[dimKey]) {
            this.textAnswers[dimKey] = [];
          }
          if (an[index][1]) {
            this.textAnswers[dimKey][this.answers[i][index][1]] = data.answers[origA][!phone ? 'value' : 'scaled'];
          } else {
            const answerIndex = this.textAnswers[dimKey].length;
            an[index][1] = [answerIndex];

            const answerLanguage: string =
              data.answers[origA]['tags'] &&
              data.answers[origA]['tags'].find((tag) => tag.type === 'language') &&
              data.answers[origA]['tags'].find((tag) => tag.type === 'language')['value'];

            if (answerLanguage) {
              if (!this.dimensions[dimKey]['valueGroupKeys']) {
                this.dimensions[dimKey]['valueGroupKeys'] = [];
              }
              if (!this.dimensions[dimKey]['valueGroupTypes']) {
                this.dimensions[dimKey]['valueGroupTypes'] = [];
              }
              if (!this.dimensions[dimKey]['valueGroupValues']) {
                this.dimensions[dimKey]['valueGroupValues'] = [];
              }
              if (!this.dimensions[dimKey]['labelsValueGroup']) {
                this.dimensions[dimKey]['labelsValueGroup'] = {};
              }
              let answerLanguageIndex = this.dimensions[dimKey]['valueGroupKeys'].indexOf(answerLanguage);

              if (answerLanguageIndex < 0) {
                answerLanguageIndex = this.dimensions[dimKey]['valueGroupKeys'].length;
                this.dimensions[dimKey]['valueGroupKeys'].push(answerLanguage);

                this.dimensions[dimKey]['valueGroupTypes'].push('language');

                this.lm
                  .isoLocales()
                  .pipe(first())
                  .subscribe((languages) => {
                    const langObj = languages.find((lang) => lang.code === answerLanguage);

                    if (langObj) {
                      this.dimensions[dimKey]['labelsValueGroup'][
                        answerLanguage
                      ] = `${langObj.name} (${langObj.native})`;
                    }
                  });
              }

              if (answerLanguageIndex >= 0) {
                if (!this.dimensions[dimKey]['valueGroupValues'][answerLanguageIndex]) {
                  this.dimensions[dimKey]['valueGroupValues'][answerLanguageIndex] = [];
                }
                if (this.dimensions[dimKey]['valueGroupValues'][answerLanguageIndex].indexOf(answerIndex) < 0) {
                  this.dimensions[dimKey]['valueGroupValues'][answerLanguageIndex].push(answerIndex);
                }
              }
            }

            this.textAnswers[dimKey].push(data.answers[origA][!phone ? 'value' : 'scaled']);
          }
        }

        // Removing deleted free answers
        if (
          !data.answers[origA].value &&
          (this.dimensions[dimKey].originalType.split('-')[0].replace('free', 'input') === Questions.INPUT ||
            this.dimensions[dimKey].originalType === Questions.FILE_UPLOAD)
        ) {
          this.textAnswers[dimKey][this.answers[i][index][1]] = null;
        }
      } else {
      }
    }
  }

  /**
   * Parsing emotions to crossfilter format. Emotions are saved into answer tags.
   *
   * @param rawAnswer           Raw answer data.
   * @param dimensionValues     Emotions array on dimension data.
   * @param labelsCategorical   Emotions labels on dimension data.
   */
  public parseEmotions(
    rawAnswer: AnswerData,
    dimensionValues: (string | number)[],
    labelsCategorical: { [key: string]: string },
    locales: string[],
    localeStrings: LocaleStrings,
  ): number[] {
    const answer: number[] = [];
    const emotions: any[] =
      (rawAnswer &&
        rawAnswer.tags &&
        rawAnswer.tags
          .filter((tag) => tag && tag.type === 'emotion')
          .sort((a, b) => b.score - a.score)
          .filter((tag) => tag.score > 0.05)
          .filter((tag, i, arr) => tag.value !== 'neutral' || arr.length === 1)
          .slice(0, 3)) ||
      [];

    for (let i = 0, len = emotions.length; i < len; i++) {
      const emotion = emotions[i] && emotions[i].value && emotions[i].value.toLowerCase();

      let index = dimensionValues.indexOf(emotion);

      if (index < 0) {
        index = dimensionValues.length;
        dimensionValues.push(emotion);
        labelsCategorical[emotion] = emotion;
        for (const locale of locales) {
          if (!localeStrings[locale]) {
            localeStrings[locale] = {} as LocaleString;
          }
          if (!localeStrings[locale]['labelsCategorical']) {
            localeStrings[locale]['labelsCategorical'] = {};
          }
          localeStrings[locale]['labelsCategorical'][emotion] = Emotions[Emotions[locale] ? locale : 'en'][emotion];
        }
      }

      answer.push(index);
    }

    return answer;
  }

  /**
   * Parsing values to user segments.
   *
   * @param i         Index of answerer.
   */
  parseUserSegments(i: number) {
    if (this.dimensions['userSegments'] && this.dimensions['userSegments'].values.length > 0) {
      this.updateUserSegmentValues(
        this.answers[i],
        this.dimensionMap.get('userSegments'),
        this.dimensionList,
        this.dimensions,
      );
    }
  }

  /**
   * Adding them to answers.
   *
   * @param data      Prepared data from answerer.
   * @param i         Index of answerer.
   */
  public updateUserSegmentValues(answers, userSegmentIndex, indexes, dimensions) {
    if (answers && userSegmentIndex != null && answers[userSegmentIndex] != null) {
      delete answers[userSegmentIndex];
    }
    for (const id of dimensions['userSegments'].values) {
      if (id !== null) {
        const index = dimensions['userSegments'].values.indexOf(id);
        let match = false;

        const fitsToFilter = (key, scale, answer, filter) => {
          let filterArr: (number | string)[] = [];
          const valueArr: (number | string)[] = filter.textFilter ? filter.values : dimensions[key].values;

          if (scale === 'linear') {
            for (const val of filter.filter) {
              filterArr = filterArr.concat(
                this.classToValue(Number(val), dimensions[key].valueScaleLinear, filter.values.length),
              );
            }
          } else if (
            scale === 'text' ||
            scale === 'contact-text' ||
            dimensions[key].originalTypeSpecifier === 'text-words'
          ) {
            filterArr = filter.filter.map((v) => v);
          } else {
            filterArr = filter.filter.map((v) => v.toString());
          }

          if (filterArr && filterArr.length > 0) {
            let checkMatch: boolean = false;
            for (const item of answer || []) {
              if (filterArr.indexOf(valueArr[item]) >= 0) {
                checkMatch = true;
              }
            }

            return checkMatch;
          } else {
            return true;
          }
        };

        for (const filter in dimensions['userSegments']['customOrigins'][id]['filters']) {
          const values = dimensions['userSegments']['customOrigins'][id]['filters'][filter];
          const scale = dimensions['userSegments']['customOrigins'][id]['filters'][filter]['dimensionData']['scale'];

          const answer = values.textFilter
            ? answers[indexes.indexOf(filter)]
              ? answers[indexes.indexOf(filter)][1]
              : null
            : answers[indexes.indexOf(filter)]
            ? answers[indexes.indexOf(filter)][0]
            : null;

          if (answer !== null && fitsToFilter(filter, scale, answer, values)) {
            match = true;
          } else {
            match = false;
            break;
          }
        }

        if (match && userSegmentIndex != null) {
          if (!answers[userSegmentIndex]) {
            answers[userSegmentIndex] = [[]];
          }
          if (answers[userSegmentIndex][0].indexOf(index) < 0) {
            answers[userSegmentIndex][0].push(index);
          }
        }
      }
    }
  }

  /**
   * Calculating local zScored values and adding them to answers.
   * Local z-score means z-scored value from all similar scales inside question group.
   * On answers array local z-score is second value. [absolute, local z-score, global z-score]
   *
   * @param data      Data from answerer.
   * @param i         Index of answerer.
   */
  calculateLocalZScores(data: AnswererData, i: number) {
    // Calculating local z-scores
    const answerData = data.answers ? data.answers : {};
    for (const survey in this.zLocalGroups) {
      for (const group in this.zLocalGroups[survey]) {
        for (const subGroup in this.zLocalGroups[survey][group]) {
          const answers = {};
          const dimensions = {};
          let sum = 0;
          let n = 0;
          let varianceSum = 0;

          const answersY = {};
          let sumY = 0;
          let nY = 0;
          let varianceSumY = 0;

          for (const question of this.zLocalGroups[survey][group][subGroup]) {
            if (answerData[question] && answerData[question]['value']) {
              const origSurvey = data.survey || this.fallbackSurvey(question);

              if (origSurvey) {
                const isMapped =
                  origSurvey &&
                  this.mappings &&
                  this.mappings[origSurvey] &&
                  this.mappings[origSurvey][question] &&
                  this.mappings[origSurvey][question]['question'] &&
                  this.mappings[origSurvey][question]['question']['$key'];
                const surveyA = isMapped ? this.mappings[origSurvey][question]['survey'] : origSurvey;
                const a = isMapped ? this.mappings[origSurvey][question]['question']['$key'] : question;
                const dimKey = surveyA + '/' + a;

                const rawAnswer = answerData[question]['value'].split(';');

                dimensions[dimKey] = rawAnswer.length;
                let index: number;
                let indexX: number;
                let indexY: number;
                if (this.twod[dimKey]) {
                  indexX = this.twod[dimKey][0].index;
                  indexY = this.twod[dimKey][1].index;
                } else {
                  index = this.dimensionMap.get(dimKey);
                }

                let answer;
                let answerY;
                if (rawAnswer && rawAnswer.length > 1 && this.answers[i][indexX] && this.answers[i][indexY]) {
                  answer = Number(rawAnswer[0]);
                  answers[question] = answer;
                  sum += answer;
                  n += 1;

                  answerY = Number(rawAnswer[1]);
                  answersY[question] = answerY;
                  sumY += answerY;
                  nY += 1;
                } else if (rawAnswer?.length === 1 && this.answers[i]?.[index]?.[0]?.length > 0) {
                  answer = Number(rawAnswer[0]);
                  answers[question] = answer;
                  sum += answer;
                  n += 1;
                } else {
                }
              }
            }
          }

          const average = sum / n;
          const averageY = sumY / nY;

          for (const a in answers) {
            varianceSum += Math.pow(answers[a] - average, 2);
          }

          for (const a in answersY) {
            varianceSumY += Math.pow(answersY[a] - averageY, 2);
          }

          const variance = varianceSum / n;
          const varianceY = varianceSumY / nY;

          const std = Math.sqrt(variance);
          const stdY = Math.sqrt(varianceY);

          for (const origA in answers) {
            const origSurvey = data.survey || this.fallbackSurvey(origA);

            if (origSurvey) {
              const isMapped =
                origSurvey &&
                this.mappings &&
                this.mappings[origSurvey] &&
                this.mappings[origSurvey][origA] &&
                this.mappings[origSurvey][origA]['question'] &&
                this.mappings[origSurvey][origA]['question']['$key'];
              const surveyA = isMapped ? this.mappings[origSurvey][origA]['survey'] : origSurvey;
              const a = isMapped ? this.mappings[origSurvey][origA]['question']['$key'] : origA;
              const dimKey = surveyA + '/' + a;
              const zValue = std > 0 ? (answers[origA] - average) / std : 0;
              const origin =
                dimensions[dimKey] === 2
                  ? this.sliderObj[surveyA][a].values.sliderValuesX
                  : this.sliderObj[surveyA][a].values.sliderValues;
              const scaledValue = this.scaleZAnswer(
                zValue,
                origin,
                this.reducedSteps[surveyA][a] ? this.reducedSteps[surveyA][a].x : null,
              );

              if (!isNaN(answersY[origA])) {
                const zValueY = stdY > 0 ? (answersY[origA] - averageY) / stdY : 0;
                const originY = this.sliderObj[surveyA][a].values.sliderValuesY;
                const scaledValueY = this.scaleZAnswer(
                  zValueY,
                  originY,
                  this.reducedSteps[surveyA][a] ? this.reducedSteps[surveyA][a].y : null,
                );

                const indexX = this.twod[dimKey][0].index;
                const keyX = this.twod[dimKey][0].key;
                const indexY = this.twod[dimKey][1].index;
                const keyY = this.twod[dimKey][1].key;

                if (this.answers[i][indexX] && this.answers[i][indexY]) {
                  this.answers[i][indexX][1] = [this.dimensions[keyX].values.indexOf(scaledValue)];
                  this.answers[i][indexY][1] = [this.dimensions[keyY].values.indexOf(scaledValueY)];
                }
              } else if (this.dimensions[dimKey]) {
                const index = this.dimensionMap.get(dimKey);
                if (this.answers[i]?.[index]?.[0]?.length) {
                  this.answers[i][index][1] = [this.dimensions[dimKey].values.indexOf(scaledValue)];
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * Calculating global zScored values and adding them to answers.
   * Global z-score means z-scored value from all similar scales inside whole survey.
   * On answers array global z-score is third value. [absolute, local z-score, global z-score]
   *
   * @param data      Data from answerer.
   * @param i         Index of answerer.
   */
  calculateGlobalZScores(data: AnswererData, i: number) {
    const answerData = data.answers ? data.answers : {};
    for (const survey in this.zGlobalGroups) {
      for (const group in this.zGlobalGroups[survey]) {
        const answers = {};
        const dimensions = {};
        let sum = 0;
        let n = 0;
        let varianceSum = 0;

        const answersY = {};
        let sumY = 0;
        let nY = 0;
        let varianceSumY = 0;

        for (const question of this.zGlobalGroups[survey][group]) {
          if (answerData[question] && answerData[question]['value']) {
            const origSurvey = data.survey || this.fallbackSurvey(question);

            if (origSurvey) {
              const isMapped =
                origSurvey &&
                this.mappings &&
                this.mappings[origSurvey] &&
                this.mappings[origSurvey][question] &&
                this.mappings[origSurvey][question]['question'] &&
                this.mappings[origSurvey][question]['question']['$key'];
              const surveyA = isMapped ? this.mappings[origSurvey][question]['survey'] : origSurvey;
              const a = isMapped ? this.mappings[origSurvey][question]['question']['$key'] : question;
              const dimKey = surveyA + '/' + a;
              const rawAnswer = answerData[question]['value'].split(';');

              dimensions[dimKey] = rawAnswer.length;
              let index: number;
              let indexX: number;
              let indexY: number;

              if (this.twod[dimKey]) {
                indexX = this.twod[dimKey][0].index;
                indexY = this.twod[dimKey][1].index;
              } else {
                index = this.dimensionMap.get(dimKey);
              }

              let answer;
              let answerY;
              if (rawAnswer && rawAnswer.length > 1 && this.answers[i][indexX] && this.answers[i][indexY]) {
                answer = Number(rawAnswer[0]);
                answers[question] = answer;
                sum += answer;
                n += 1;

                answerY = Number(rawAnswer[1]);
                answersY[question] = answerY;
                sumY += answerY;
                nY += 1;
              } else if (rawAnswer?.length === 1 && this.answers[i]?.[index]?.[0]?.length) {
                answer = Number(rawAnswer[0]);
                answers[question] = answer;
                sum += answer;
                n += 1;
              } else {
              }
            }
          }
        }

        const average = sum / n;
        const averageY = sumY / nY;

        for (const a in answers) {
          varianceSum += Math.pow(answers[a] - average, 2);
        }

        for (const a in answersY) {
          varianceSumY += Math.pow(answersY[a] - averageY, 2);
        }

        const variance = varianceSum / n;
        const varianceY = varianceSumY / nY;

        const std = Math.sqrt(variance);
        const stdY = Math.sqrt(varianceY);

        for (const origA in answers) {
          const origSurvey = data.survey || this.fallbackSurvey(origA);

          if (origSurvey) {
            const isMapped =
              this.mappings &&
              this.mappings[origSurvey] &&
              this.mappings[origSurvey][origA] &&
              this.mappings[origSurvey][origA]['question'] &&
              this.mappings[origSurvey][origA]['question']['$key'];
            const surveyA = isMapped ? this.mappings[origSurvey][origA]['survey'] : origSurvey;
            const a = isMapped ? this.mappings[origSurvey][origA]['question']['$key'] : origA;
            const dimKey = surveyA + '/' + a;
            const zValue = std > 0 ? (answers[origA] - average) / std : 0;
            const origin =
              dimensions[dimKey] === 2
                ? this.sliderObj[surveyA][a].values.sliderValuesX
                : this.sliderObj[surveyA][a].values.sliderValues;
            const scaledValue = this.scaleZAnswer(
              zValue,
              origin,
              this.reducedSteps[surveyA][a] ? this.reducedSteps[surveyA][a].x : null,
            );

            if (!isNaN(answersY[origA])) {
              const zValueY = stdY > 0 ? (answersY[origA] - averageY) / stdY : 0;
              const originY = this.sliderObj[surveyA][a].values.sliderValuesY;
              const scaledValueY = this.scaleZAnswer(
                zValueY,
                originY,
                this.reducedSteps[surveyA][a] ? this.reducedSteps[surveyA][a].y : null,
              );

              const indexX = this.twod[dimKey][0].index;
              const keyX = this.twod[dimKey][0].key;
              const indexY = this.twod[dimKey][1].index;
              const keyY = this.twod[dimKey][1].key;

              if (this.answers[i][indexX] && this.answers[i][indexY]) {
                this.answers[i][indexX][2] = [this.dimensions[keyX].values.indexOf(scaledValue)];
                this.answers[i][indexY][2] = [this.dimensions[keyY].values.indexOf(scaledValueY)];
              }
            } else if (this.dimensions[dimKey]) {
              const index = this.dimensionMap.get(dimKey);
              if (this.answers[i][index] && this.answers[i][index][0] && this.answers[i][index][0].length > 0) {
                this.answers[i][index][2] = [this.dimensions[dimKey].values.indexOf(scaledValue)];
              }
            }
          }
        }
      }
    }
  }

  /**
   * Generating local z-score group objects for z-scorable groups.
   *
   * @param questions  Questions array where groups are parsed.
   * @returns          Group containing different z-scoring groups.
   */
  public generateLocalZScoreGroups(questions: QuestionData[]) {
    // Generating group object
    const zGroups = {};
    for (const question of questions) {
      const details = question;
      const group = question.group && Questions.category(question, Questions.GROUP) === false ? question.group : null;

      if (Questions.category(question, Questions.GROUP)) {
        zGroups[question.$key] = {};
      }
      let groupId;
      if (group && zGroups[group]) {
        if (Questions.category(question, Questions.SCORED)) {
          groupId = this.generateGroupId(details, questions);
        }

        if (!zGroups[group][groupId]) {
          zGroups[group][groupId] = [];
        }

        if (groupId) {
          zGroups[group][groupId].push(question.$key);
        }
      }
    }

    // removing non z-scorable items
    for (const group in zGroups) {
      for (const subGroup in zGroups[group]) {
        if (zGroups[group][subGroup].length < 3) {
          delete zGroups[group][subGroup];
        }
      }
    }

    return zGroups;
  }

  /**
   * Generating global z-score group objects for z-scorable groups.
   *
   * @param questions  Questions array where groups are parsed.
   * @returns          Group containing different z-scoring groups.
   */
  public generateGlobalZScoreGroups(questions: QuestionData[]) {
    // Generating group object
    const zGlobalGroups = {};
    for (const question of questions) {
      const details = question;

      let groupId;

      if (Questions.category(question, Questions.SCORED)) {
        groupId = this.generateGroupId(details, questions);
      }

      if (groupId) {
        if (!zGlobalGroups[groupId]) {
          zGlobalGroups[groupId] = [];
        }
        zGlobalGroups[groupId].push(question.$key);
      }
    }

    // removing non z-scorable items
    for (const group in zGlobalGroups) {
      if (zGlobalGroups[group].length < 3) {
        delete zGlobalGroups[group];
      }
    }
    return zGlobalGroups;
  }

  /**
   * Generating group id used for grouping questions.
   *
   * @param question   Question object. (this is used for list & card groups)
   * @param questions  Questions array. (this is used for zscored groups)
   * @returns          Group id as string.
   */
  public generateGroupId(details: QuestionData, questions: QuestionData[]) {
    // Generate group Id based on sliderLabels
    let groupId;

    const sliderValues = this.parseSliderData(questions, details).values;
    const sliderLabels = this.parseSliderData(questions, details).labels;

    if (details.type === Questions.SLIDER_2D) {
      groupId = `${sliderLabels.sliderLabelsX.axis}: ${sliderLabels.sliderLabelsX.min} - ${
        sliderLabels.sliderLabelsX.max
      } (${sliderValues.sliderValuesX ? sliderValues.sliderValuesX.min : ''}-${sliderValues.sliderValuesX.max}) - ${
        sliderLabels.sliderLabelsY.axis
      }: ${sliderLabels.sliderLabelsY.min} - ${sliderLabels.sliderLabelsY.max} (${sliderValues.sliderValuesY.min}-${
        sliderValues.sliderValuesY.max
      })`;
    } else if (details.type === Questions.SLIDER_1D || details.type === Questions.SLIDER_1V) {
      groupId =
        `${sliderLabels.sliderLabels.axis}: ${sliderLabels.sliderLabels.min} - ` +
        `${sliderLabels.sliderLabels.max} (${sliderValues.sliderValues.min}-${sliderValues.sliderValues.max})`;
    }
    return groupId;
  }

  /**
   * Generating Slider object. Parses values to all sliders.
   *
   * @param questions  Questions array.
   * @returns          Object containing all slider information.
   */
  public generateSliderObj(questions: QuestionData[]) {
    const questionObj = {};
    for (const question of questions) {
      if (Questions.category(question, Questions.SCORED)) {
        questionObj[question.$key] = this.parseSliderData(questions, question);
      }
    }
    return questionObj;
  }

  /**
   * Generating Slider step reducers. Parses reducers to all sliders when step count is > 100.
   *
   * @param questions  Questions array.
   * @returns          Object containing step reducers for all reducable scales.
   */
  public generateSliderStepReducer(questions: QuestionData[]) {
    const reducerObj = {};
    for (const question of questions) {
      if (Questions.category(question, Questions.SLIDER)) {
        const origin = this.parseSliderData(questions, question).values;
        let minX;
        let maxX;
        let stepX;
        let minY;
        let maxY;
        let stepY;
        if (question.type === Questions.SLIDER_2D) {
          minX = origin.sliderValuesX.min == null ? 0 : origin.sliderValuesX.min;
          maxX = origin.sliderValuesX.max == null ? 100 : origin.sliderValuesX.max;
          stepX = origin.sliderValuesX.step == null ? 1 : origin.sliderValuesX.step;

          minY = origin.sliderValuesY.min == null ? 0 : origin.sliderValuesY.min;
          maxY = origin.sliderValuesY.max == null ? 100 : origin.sliderValuesY.max;
          stepY = origin.sliderValuesY.step == null ? 1 : origin.sliderValuesY.step;
        } else if (question.type === Questions.SLIDER_1R) {
          minX = origin.sliderValues.min == null ? 0 : origin.sliderValues.min;
          maxX = origin.sliderValues.max == null ? 100 : origin.sliderValues.max;
          stepX = origin.sliderValues.step == null ? 1 : origin.sliderValues.step;

          minY = origin.sliderValues.min == null ? 0 : origin.sliderValues.min;
          maxY = origin.sliderValues.max == null ? 100 : origin.sliderValues.max;
          stepY = origin.sliderValues.step == null ? 1 : origin.sliderValues.step;
        } else {
          minX = origin.sliderValues.min == null ? 0 : origin.sliderValues.min;
          maxX = origin.sliderValues.max == null ? 100 : origin.sliderValues.max;
          stepX = origin.sliderValues.step == null ? 1 : origin.sliderValues.step;
        }

        const stepCountX = minX != null && maxX != null && stepX != null ? (maxX - minX) / stepX : 0;
        const stepCountY = minY != null && maxY != null && stepY != null ? (maxY - minY) / stepY : 0;

        if (stepCountX > 100 || stepCountY > 100) {
          let x = stepCountX > 100 ? (stepCountX / 100) * stepX : stepX;
          let y = stepCountY > 100 ? (stepCountY / 100) * stepY : stepY;

          x = Math.round(x * this.rounder) / this.rounder;
          y = Math.round(y * this.rounder) / this.rounder;

          reducerObj[question.$key] = { x, y };
        }
      }
    }
    return reducerObj;
  }

  /**
   * Reducing scale's step count.
   *
   * @param value    Value.
   * @param reducer  Divider for value.
   * @returns       Rounded value.
   */
  scaleReducedValue(value: number, reducer: number, origin: SliderValuesData) {
    const min = origin.min == null ? 0 : origin.min;
    const max = origin.max == null ? 100 : origin.max;
    const classCalc = Math.floor(
      Math.round(((Number(value) - min) / ((max + reducer - min) / 101)) * this.rounder) / this.rounder,
    );

    const convertedVal = Math.round(classCalc * reducer * this.rounder) / this.rounder + min;

    return Math.round(convertedVal * this.rounder) / this.rounder;
  }

  /**
   * Scaling z-scored answers to original answer scale.
   *
   * @param value   z-scored value.
   * @param origin  Dimension origins for scaling.
   * @returns       Scaled z-scored value that fits in to the original slider scale.
   */
  scaleZAnswer(value: number, origin: SliderValuesData, reducedScale: number | null = null) {
    const min = origin.min == null ? 0 : origin.min;
    const max = origin.max == null ? 100 : origin.max;
    const step = origin.step == null ? 1 : origin.step;

    const stepCount = (max - min) / step;

    let step1;
    if (value > 1.5) {
      step1 = 1.5;
    } else if (value < -1.5) {
      step1 = -1.5;
    } else {
      step1 = value;
    }
    const step2 = step1 + 1.5;
    const step3 = min + Math.round(Math.round(step2 * (stepCount / 3)) * step * this.rounder) / this.rounder;

    return reducedScale != null ? this.scaleReducedValue(step3, reducedScale, origin) : step3;
  }

  /**
   * Scaling z-scored average to original answer scale.
   *
   * @param value   z-scored average.
   * @param origin  Dimension origins for scaling.
   * @returns       Scaled z-scored value that fits in to the original slider scale.
   */
  scaleZAverage(value: number, origin: SliderValuesData) {
    const min = origin.min == null ? 0 : origin.min;
    const max = origin.max == null ? 100 : origin.max;
    const step = origin.step == null ? 1 : origin.step;

    const stepCount = (max - min) / step;

    let step1;
    if (value > 1.5) {
      step1 = 1.5;
    } else if (value < -1.5) {
      step1 = -1.5;
    } else {
      step1 = value;
    }
    const step2 = step1 + 1.5;
    const step3 = min + step2 * (stepCount / 3) * step;

    return isNaN(step3) || value == null ? null : step3;
  }

  /**
   * Parses slider labels and values to selected question.
   *
   * @param  questionsList
   * @param  target       Target question key.
   * @returns  object         Object that contains labels and values.
   */
  public parseSliderData(questions: QuestionData[], question: QuestionData) {
    const groupIndex = questions.findIndex((x) => x.$key === question.group);

    let values: any;
    let labels: any;

    if (
      groupIndex >= 0 &&
      questions[groupIndex].type === Questions.GROUP_SCORED &&
      question.type !== Questions.SLIDER_NPS &&
      question.type !== Questions.SLIDER_1R &&
      question.type !== Questions.INPUT_NUMERIC
    ) {
      labels = {
        sliderLabels: questions[groupIndex].sliderLabels ? questions[groupIndex].sliderLabels : new SliderLabelsData(),
        sliderLabelsX: questions[groupIndex].sliderLabelsX
          ? questions[groupIndex].sliderLabelsX
          : new SliderLabelsData(),
        sliderLabelsY: questions[groupIndex].sliderLabelsY
          ? questions[groupIndex].sliderLabelsY
          : new SliderLabelsData(),
      };

      values = {
        sliderValues: questions[groupIndex].sliderValues ? questions[groupIndex].sliderValues : new SliderValuesData(),
        sliderValuesX: questions[groupIndex].sliderValuesX
          ? questions[groupIndex].sliderValuesX
          : new SliderValuesData(),
        sliderValuesY: questions[groupIndex].sliderValuesY
          ? questions[groupIndex].sliderValuesY
          : new SliderValuesData(),
      };
    } else {
      labels = {
        sliderLabels: question.sliderLabels ? question.sliderLabels : new SliderLabelsData(),
        sliderLabelsX: question.sliderLabelsX ? question.sliderLabelsX : new SliderLabelsData(),
        sliderLabelsY: question.sliderLabelsY ? question.sliderLabelsY : new SliderLabelsData(),
      };

      values = {
        sliderValues: question.sliderValues ? question.sliderValues : new SliderValuesData(),
        sliderValuesX: question.sliderValuesX ? question.sliderValuesX : new SliderValuesData(),
        sliderValuesY: question.sliderValuesY ? question.sliderValuesY : new SliderValuesData(),
      };
    }

    return { labels, values };
  }

  /**
   * Convert class to array of possible values in that class.
   *
   * @param classVal  Original class value.
   * @param origin    Scale origins for the answer.
   * @param classes   Number of classes in dimension.
   * @returns         Array of values in class.
   */
  public classToValue(classVal: number, origin: SliderValuesData, classes: number) {
    const values: number[] = [];

    const min = !origin || origin.min == null ? 0 : origin.min;
    const max = !origin || origin.max == null ? 100 : origin.max;
    const step = !origin || origin.step == null ? 1 : origin.step;
    const defaulRounder = Math.pow(10, 5);

    const smallerStep =
      step > 1 && step % 1 === 0
        ? 1
        : Math.round((step % 1) * defaulRounder) / defaulRounder > 0
        ? Number('1e-' + step.toString().split('.')[1].length)
        : step;
    const originalClasses = (max + step - min) / step;
    const rounder = step % 1 > 0 ? Math.pow(10, step.toString().split('.')[1].length) : Math.pow(10, 0);

    const classCalc = (answer) => Math.floor((Number(answer) - min) / ((max + smallerStep - min) / classes));

    if (classes === originalClasses) {
      const val = min + classVal * step;
      values.push(Math.round(val * defaulRounder) / defaulRounder);
    } else {
      let valMin = Math.floor((classVal * ((max - min) / classes) + min) * rounder) / rounder;
      let valMax = Math.floor(((classVal + 1) * ((max - min) / classes) + min) * rounder) / rounder;

      valMin = Math.round(valMin * defaulRounder) / defaulRounder;
      valMax = Math.round(valMax * defaulRounder) / defaulRounder;

      valMin =
        classCalc(valMin) < classVal
          ? classCalc(valMin + smallerStep) < classVal
            ? classCalc(valMin + 2 * smallerStep) === classVal
              ? valMin + 2 * smallerStep
              : valMin
            : valMin + smallerStep
          : valMin;
      valMax =
        classCalc(valMax + smallerStep) === classVal
          ? valMax + smallerStep
          : classCalc(valMax) > classVal
          ? classCalc(valMin - smallerStep) === classVal
            ? valMax - smallerStep
            : valMax
          : valMax;

      valMin = Math.round(valMin * defaulRounder) / defaulRounder;
      valMax = Math.round(valMax * defaulRounder) / defaulRounder;

      for (let val = valMin; val <= valMax; val += step) {
        values.push(Math.round(val * defaulRounder) / defaulRounder);
      }
    }

    return values;
  }
}

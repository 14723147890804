import { Injectable } from '@angular/core';
import { ApiPromotion } from '@home/+settings/+integrations/api-promotion/api-promotion.dialog';
import { IntegrationsUpgradeDialog } from '@home/shared/dialogs/integration-upgrade/integrations-upgrade.dialog';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Commands } from '@shared/enums/commands.enum';
import { LicenseFeature } from '@shared/enums/license-feature.enum';
import {
  ApiKey,
  CustomIntegration,
  IntegrationConfig,
  IntegrationLabels,
  IntegrationListLink,
  IntegrationPropertyLink,
  IntegrationRequestForm,
  IntegrationService,
  IntegrationSurveyFeature,
  IntegrationSurveyLink,
  IntegrationSurveySyncParams,
  ServiceIntegration,
  ServiceIntegrationList,
  ServiceIntegrationProperty,
} from '@shared/models/integrations.model';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { shareRef } from '@shared/operators/share-ref.operator';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { AccountState } from '@shared/states/account.state';
import { BillingState } from '@shared/states/billing.state';
import { OpenDialog } from '@shared/states/dialog.actions';
import {
  GetApiKeys,
  GetIntegrationLists,
  GetIntegrationProperties,
  SetToken,
} from '@shared/states/integrations.actions';
import { IntegrationsState } from '@shared/states/integrations.state';
import { getPrefixedListName, parseIntegrationData } from '@shared/utilities/integration.utilities';
import { arrayToFirebaseObject, firebaseSafe } from '@shared/utilities/object.utilities';
import { LocalStorageService } from 'ngx-webstorage';
import { combineLatest, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, combineLatestAll, concatMap, delay, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsManager {
  readonly command = Commands.ApiManagement;

  constructor(
    private store: Store,
    private cf: CloudFunctions,
    private db: DatabaseWrapper,
    private za: ZefApi,
    private ls: LocalStorageService,
  ) {}

  getApiKeys(): Observable<ApiKey[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.cf.get(this.command, teamKey).pipe(
      map((apiKeys) => (!Array.isArray(apiKeys) ? [] : apiKeys)),
      catchError(() => of([])),
    );
  }

  generateApiKey(): Observable<ApiKey> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const uid = this.store.selectSnapshot(AccountState.userKey);

    return this.assertAccess('api').pipe(
      switchMap(() => this.cf.post<ApiKey>(this.command, teamKey, { uid })),
      catchError(() => of(null)),
    );
  }

  syncCustomService(integrationId: string) {
    return this.za.post(`integrations/sync/${integrationId}`).pipe(catchError(() => of(null).pipe(delay(200))));
  }

  connectPartnerService(service: CustomIntegration): Observable<any> {
    const name = service.name + ' Custom Integration Api Key';
    return this.generateApiKey().pipe(
      switchMap(({ $key, token }: ApiKey) =>
        this.updateApiKey($key, { name }).pipe(
          switchMap(() => (service.$key ? of(service.$key) : this.createCustomIntegration(service.type))),
          switchMap((integrationKey) =>
            this.updateCustomIntegration(integrationKey, { custom: { apiKey: $key } }).pipe(
              tap(() =>
                this.store.dispatch([
                  new SetToken(token),
                  new GetApiKeys(),
                  new Navigate([`settings/integrations/${service}/${integrationKey}`]),
                ]),
              ),
            ),
          ),
        ),
      ),
    );
  }

  disconnectPartnerService(service: CustomIntegration): Observable<any> {
    const cleanup = [];

    if (service?.config?.custom?.apiKey) {
      cleanup.push(this.deleteApiKey(service.config.custom.apiKey));
    }

    if (service?.$key) {
      cleanup.push(this.deleteIntegration(service.$key));
    }

    return forkJoin(cleanup).pipe(
      tap(() => this.store.dispatch([new Navigate(['/settings/integrations']), new GetApiKeys()])),
    );
  }

  recreateApiKey(service: CustomIntegration): Observable<any> {
    const name = service.name + ' Custom Integration Api Key';
    const apiKey = service?.config?.custom?.apiKey;
    const integrationKey = service.$key;

    if (integrationKey && apiKey) {
      return this.deleteApiKey(apiKey).pipe(
        switchMap(() =>
          this.generateApiKey().pipe(
            switchMap(({ $key, token }: ApiKey) =>
              this.updateApiKey($key, { name }).pipe(
                switchMap(() =>
                  this.updateCustomIntegration(integrationKey, { custom: { apiKey: $key } }).pipe(
                    tap(() => this.store.dispatch([new SetToken(token), new GetApiKeys()])),
                    concatMap(() => this.syncCustomService(integrationKey)),
                  ),
                ),
              ),
            ),
          ),
        ),
      );
    } else {
      return of(null);
    }
  }

  updateApiKey(apiKey: string, update: Partial<ApiKey>): Observable<boolean> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.assertAccess('api').pipe(
      switchMap(() =>
        this.db
          .object<ApiKey>(`api/${teamKey}/${apiKey}/data`)
          .update(update)
          .then(() => true),
      ),
      catchError(() => of(false)),
    );
  }

  deleteApiKey(apiKey: string): Observable<boolean> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.assertAccess('api').pipe(
      concatMap(() => {
        const services = this.store.selectSnapshot(IntegrationsState.services);
        const integrationKey = services.find((service) => service.config?.custom?.apiKey === apiKey)?.$key;
        return !integrationKey ? of(null) : this.syncCustomService(integrationKey);
      }),
      switchMap(() => this.cf.delete(this.command, `${teamKey}/${apiKey}`)),
      catchError(() => of(false)),
    );
  }

  getIntegrationsTemplates(): Observable<CustomIntegration[]> {
    return this.db
      .list<CustomIntegration>(`/templates/integrations`)
      .snapshotChanges()
      .pipe(
        mapListKeys('type'),
        map((integrations) =>
          integrations.map((integration) => ({
            ...integration,
            type: integration.type.toLowerCase() as IntegrationService,
          })),
        ),
      );
  }

  getServiceIntegrations(): Observable<ServiceIntegration[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return !teamKey
      ? of([])
      : this.db
          .list<ServiceIntegration>(`/integrations/${teamKey}`)
          .snapshotChanges()
          .pipe(
            mapListKeys(),
            map((integrations) => integrations.filter((integration) => !!integration.type)),
            switchMap((integrations) =>
              integrations.length
                ? from(integrations).pipe(
                    map((integration: ServiceIntegration | CustomIntegration) =>
                      this.db
                        .object(`/statuses/integration/${teamKey}/${integration.$key}`)
                        .valueChanges()
                        .pipe(
                          map((status) => ({
                            ...integration,
                            type: integration.type.toLowerCase(),
                            status,
                          })),
                        ),
                    ),
                    combineLatestAll(),
                  )
                : of([]),
            ),
          )
          .pipe(
            map((integrations: any) => integrations.filter((integration) => !!integration).map(parseIntegrationData)),
            shareRef(),
            catchError(() => of([])),
          );
  }

  requestIntegration(data: IntegrationRequestForm): Observable<any> {
    return this.za.post('integrations/request_new', data).pipe(catchError(() => of(void 0)));
  }

  authenticateIntegration(service: string): Observable<any> {
    const integrationId = this.db.createPushId();
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const userKey = this.store.selectSnapshot(AccountState.userKey) || null;
    const redirectUrl = `${location.protocol}//${location.host}/settings/integrations`;
    this.ls.store('integrationAuth', JSON.stringify({ integrationId, service }));

    return this.assertAccess().pipe(
      switchMap(() => {
        const name = IntegrationLabels[service] ? `${IntegrationLabels[service]} integration` : null;

        return this.db.object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}`).set({
          type: service,
          creator: userKey,
          name,
        } as ServiceIntegration);
      }),
      concatMap(() => this.za.post<{ url: string }>(`integrations/authenticate/${integrationId}`, { redirectUrl })),
      tap(({ url }) => (window.location.href = url)),
      catchError(() => of(void 0)),
    );
  }

  authenticateCustomIntegration(service: string, integrationId: string): Observable<any> {
    this.ls.store('integrationAuth', JSON.stringify({ integrationId, service }));
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const redirectUrl = `${location.protocol}//${location.host}/settings/integrations`;

    return this.assertAccess().pipe(
      concatMap(() => this.za.post<{ url: string }>(`integrations/authenticate/${integrationId}`, { redirectUrl })),
      tap(({ url }) => (window.location.href = url)),
      catchError(() => of(void 0)),
    );
  }

  authorizeCustomIntegration(integrationId: string, update: Record<string, any>): Observable<any> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const redirectUrl = `${location.protocol}//${location.host}/settings/integrations`;

    return this.assertAccess().pipe(
      switchMap(() =>
        this.db
          .object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}/config`)
          .update(firebaseSafe(update)),
      ),

      concatMap(() => this.za.put<{ url: string }>(`integrations/authenticate/${integrationId}`, { redirectUrl })),
      concatMap(() =>
        this.store.dispatch([new GetIntegrationLists(integrationId), new GetIntegrationProperties(integrationId)]),
      ),
      catchError(() => of(void 0)),
    );
  }

  connectCustomIntegration(service: IntegrationService): Observable<any> {
    return this.assertAccess().pipe(
      switchMap(() => this.createCustomIntegration(service)),
      tap((integrationKey) =>
        this.store.dispatch(new Navigate([`settings/integrations/${service}/${integrationKey}`])),
      ),
      catchError(() => of(void 0)),
    );
  }

  createCustomIntegration(type: IntegrationService) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const creator = this.store.selectSnapshot(AccountState.userKey);
    const integrationKey = this.db.createPushId();
    const templates = this.store.selectSnapshot(IntegrationsState.integrationTemplates);
    const template = templates.find((t) => t.type === type);
    const name = `${template?.name} integration`;

    return from(
      this.db
        .object<CustomIntegration>(`/integrations/${teamKey}/${integrationKey}`)
        .set({ type, name, creator } as CustomIntegration),
    ).pipe(
      map(() => integrationKey),
      catchError(() => of(void 0)),
    );
  }

  finalizeAuthentication(integrationId: string, code: string): Observable<Partial<ServiceIntegration>> {
    const update: Partial<ServiceIntegration> = {
      authCode: code,
      connected: true,
    };

    const config = {
      properties: [
        {
          $key: this.db.createPushId(),
          itemId: 'email',
          integrationId: 'email',
          readonly: true,
        },
      ],
      lists: [],
      conflictResolution: 'useIntegration',
    } as IntegrationConfig;

    return this.updateIntegration(integrationId, update).pipe(
      switchMap(() => this.saveSyncIntegration(integrationId, config)),
      mapTo({
        ...update,
        config,
      }),
    );
  }

  updateIntegration(integrationId: string, update: Partial<ServiceIntegration>) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.assertAccess().pipe(
      switchMap(() =>
        this.db.object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}`).update(firebaseSafe(update)),
      ),
      concatMap(() => this.syncCustomService(integrationId)),
      catchError(() => of(void 0)),
    );
  }

  updateCustomIntegration(integrationId: string, update: Partial<IntegrationConfig>) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const config =
      this.store.selectSnapshot(IntegrationsState.integration(integrationId))?.config || ({} as IntegrationConfig);

    const updateCustom = !update.custom
      ? Promise.resolve()
      : this.db
          .object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}/config/custom`)
          .update(firebaseSafe(update.custom));

    const propertiesToUpdate = (properties: IntegrationPropertyLink[]) => {
      const existing = config?.properties || [];

      return [...existing, ...properties].reduce((dict, property) => {
        const key = property.$key || this.db.createPushId();
        if (property.$key && Object.keys(property).length === 1) {
          dict[key] = null;
        } else {
          dict[key] = firebaseSafe(property);
        }
        return dict;
      }, {});
    };

    const updateProperites = !update.properties
      ? Promise.resolve()
      : this.db
          .object(`/integrations/${teamKey}/${integrationId}/config/properties`)
          .set(propertiesToUpdate(update.properties));

    const listsToUpdate = (lists: IntegrationListLink[]) => {
      const existing = config?.lists || [];

      return [...existing, ...lists].reduce((dict, list) => {
        const key = list.$key || this.db.createPushId();
        if (list.$key && Object.keys(list).length === 1) {
          dict[key] = null;
        } else {
          dict[key] = firebaseSafe(list);
        }
        return dict;
      }, {});
    };

    const updateLists = !update.lists
      ? Promise.resolve()
      : this.db
          .object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}/config/lists`)
          .update(listsToUpdate(update.lists));

    return this.assertAccess().pipe(
      switchMap(() => Promise.all([updateCustom, updateProperites, updateLists])),
      // concatMap(() => this.syncCustomService(integrationId)),
      concatMap(() =>
        this.store.dispatch([new GetIntegrationLists(integrationId), new GetIntegrationProperties(integrationId)]),
      ),
      catchError(() => of(void 0)),
    );
  }

  deleteIntegration(integrationId: string) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(this.db.object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}`).remove()).pipe(
      concatMap(() => this.syncCustomService(integrationId)),
    );
  }

  getIntegrationLists(integrationId: string): Observable<ServiceIntegrationList[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return combineLatest([
      this.db.object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}`).valueChanges(),
      this.za
        .get<{ lists: ServiceIntegrationList[] }>(`integrations/lists/${integrationId}`)
        .pipe(catchError(() => of({ lists: [] }))),
    ]).pipe(
      map(([{ type }, { lists }]) =>
        lists
          .map((list) => ({
            ...list,
            name: getPrefixedListName(list),
            source: type,
            sourceKey: integrationId,
          }))
          .sort((a, b) => (a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase() ? -1 : 1)),
      ),
      take(1),
      catchError(() => of([])),
    );
  }

  getIntegrationProperties(integrationId: string): Observable<ServiceIntegrationProperty[]> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return combineLatest([
      this.db.object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}`).valueChanges(),
      this.za
        .get<{ properties: ServiceIntegrationProperty[] }>(`integrations/properties/${integrationId}`)
        .pipe(catchError(() => of({ properties: [] }))),
    ]).pipe(
      map(([{ type }, { properties }]) =>
        properties
          .map((property) => ({
            ...property,
            name: property.name || property.id,
            source: type,
            sourceKey: integrationId,
          }))
          .sort((a, b) => (a.name?.toLocaleLowerCase() < b.name?.toLocaleLowerCase() ? -1 : 1)),
      ),
      take(1),
      catchError(() => of([])),
    );
  }

  saveSyncIntegration(integrationId: string, config: IntegrationConfig): Observable<Partial<ServiceIntegration>> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const update: Partial<ServiceIntegration> = {
      connected: true,
      interrupted: false,
    };

    const dataConfig: any = {
      ...config,
      lists: arrayToFirebaseObject(config.lists, this.db),
      properties: arrayToFirebaseObject(config.properties, this.db),
    };

    if (config.surveys) {
      dataConfig.surveys = Object.entries(config.surveys || {}).reduce((surveys, [key, link]) => {
        const propertyLinks = link?.features?.[IntegrationSurveyFeature.Property]?.propertyLinks;

        if (propertyLinks?.length) {
          link = {
            ...link,
            features: {
              ...link.features,
              [IntegrationSurveyFeature.Property]: {
                ...link.features[IntegrationSurveyFeature.Property],
                propertyLinks: arrayToFirebaseObject(propertyLinks, this.db) as any,
              },
            },
          };
        }

        return {
          ...surveys,
          [key]: link,
        };
      }, {});
    }

    return this.assertAccess().pipe(
      switchMap(() =>
        Promise.all([
          this.db.object(`/integrations/${teamKey}/${integrationId}/config`).set(dataConfig),
          this.db.object(`/integrations/${teamKey}/${integrationId}`).update(update),
        ]),
      ),
      switchMap(() => this.za.post(`integrations/sync/${integrationId}`)),
      mapTo(update),
      catchError(() => of({})),
    );
  }

  cancelSyncIntegration(integrationId: string): Observable<Partial<ServiceIntegration>> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    const update: Partial<ServiceIntegration> = {
      connected: false,
      interrupted: true,
    };

    return forkJoin([
      this.db.object<ServiceIntegration>(`/integrations/${teamKey}/${integrationId}`).update(update),
      this.za.delete(`integrations/sync/${integrationId}`),
    ]).pipe(
      mapTo(update),
      catchError(() => of({})),
    );
  }

  importListFromIntegration(integrationId: string, listLinkId: string): Observable<void> {
    return this.za.post(`integrations/sync/${integrationId}/import/${listLinkId}`);
  }

  exportListToIntegration(integrationId: string, listLinkId: string): Observable<void> {
    return this.za.post(`integrations/sync/${integrationId}/export/${listLinkId}`);
  }

  importDynamicPropertyOptions(integrationId: string, optionName: string) {
    return this.za
      .get<{ properties: ServiceIntegrationProperty[] }>(`/integrations/properties/${integrationId}/${optionName}`)
      .pipe(
        map(({ properties }) => properties),
        catchError(() => of([] as ServiceIntegrationProperty[])),
      );
  }

  saveSurveyIntegration(integrationId: string, surveyKey: string, link: IntegrationSurveyLink): Observable<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const propertyLinks = link.features?.[IntegrationSurveyFeature.Property]?.propertyLinks;
    const obj = this.db.object<IntegrationSurveyLink>(
      `/integrations/${teamKey}/${integrationId}/config/surveys/${surveyKey}`,
    );
    const dataLink = firebaseSafe(
      propertyLinks
        ? {
            ...link,
            features: {
              ...link.features,
              [IntegrationSurveyFeature.Property]: {
                ...link.features[IntegrationSurveyFeature.Property],
                propertyLinks: arrayToFirebaseObject(propertyLinks, this.db),
              },
            },
          }
        : link,
    );

    const noteSettings = link.features[IntegrationSurveyFeature.Note] || { active: false };
    const propSettings = link.features[IntegrationSurveyFeature.Property] || { active: false };

    return obj.valueChanges().pipe(
      take(1),
      switchMap((currentLink) =>
        from(obj.set(dataLink)).pipe(
          switchMap(() =>
            this.syncSurveyIntegration(integrationId, surveyKey, {
              createNotes: !currentLink && noteSettings.active && noteSettings.createNotes,
              exportCurrentRespondents: !currentLink && propSettings.active && propSettings.exportCurrentRespondents,
            }),
          ),
        ),
      ),
      mapTo(void 0),
    );
  }

  syncSurveyIntegration(
    integrationId: string,
    surveyKey: string,
    params?: IntegrationSurveySyncParams,
  ): Observable<void> {
    return this.za.post(`/integrations/survey/${integrationId}/${surveyKey}`, params);
  }

  toggleSurveyIntegration(integrationId: string, surveyKey: string, active: boolean): Observable<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(
      this.db.object(`/integrations/${teamKey}/${integrationId}/config/surveys/${surveyKey}/active`).set(active),
    ).pipe(switchMap(() => this.syncSurveyIntegration(integrationId, surveyKey)));
  }

  deleteSurveyIntegration(integrationId: string, surveyKey: string): Observable<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return from(this.db.object(`/integrations/${teamKey}/${integrationId}/config/surveys/${surveyKey}`).remove()).pipe(
      switchMap(() => this.za.delete(`/integrations/survey/${integrationId}/${surveyKey}`)),
      mapTo(void 0),
    );
  }

  deleteSurveyIntegrationPropertyLink(integrationId: string, surveyKey: string, linkKey: string): Observable<void> {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);
    const surveyPath = `/integrations/${teamKey}/${integrationId}/config/surveys/${surveyKey}`;
    const featurePath = `/features/${IntegrationSurveyFeature.Property}/propertyLinks/${linkKey}`;

    return from(this.db.object(`${surveyPath}${featurePath}`).remove()).pipe(mapTo(void 0));
  }

  compareIntegrationListLink(
    integrationId: string,
    link: IntegrationListLink,
  ): Observable<{ missingFromList: number; missingFromIntegration: number }> {
    return this.za
      .get<{ lists: ServiceIntegrationList[] }>(`integrations/lists/${integrationId}`, {
        externalIds: link.integrationId,
        compareTo: link.itemId,
      })
      .pipe(
        map(({ lists }) =>
          lists
            .filter((list) => list.id === link.integrationId)
            .reduce((a, b) => {
              a.missingFromList = b.importable ?? 0;
              a.missingFromIntegration = b.exportable ?? 0;

              return a;
            }, {} as { missingFromList: number; missingFromIntegration: number }),
        ),
        catchError(() => of({ missingFromList: 0, missingFromIntegration: 0 })),
      );
  }

  assertAccess(accessTo: 'integration' | 'api' = 'integration'): Observable<void> {
    return forkJoin([
      this.store.selectOnce(AccountState.isTeamAdmin),
      this.store.selectOnce(BillingState.featureActive(LicenseFeature.Integrations)),
    ]).pipe(
      switchMap(([hasAccess, hasFeature]) => {
        if (!hasAccess || !hasFeature) {
          if (!hasFeature) {
            const dialog = accessTo === 'integration' ? IntegrationsUpgradeDialog : ApiPromotion;

            this.store.dispatch(new OpenDialog(dialog));
          }

          return throwError(new Error());
        } else {
          return of(void 0);
        }
      }),
    );
  }
}

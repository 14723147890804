/**
 * Manages account data of the user.
 *
 * @stable
 */

import { forkJoin, Observable, of, tap } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, retry, shareReplay, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { InviteData, TeamAdminSettings, TeamData, UserData } from '@shared/models/account.model';

import { DatabaseWrapper } from '@shared/services/database-wrapper.service';

import { mapObjectKey } from '@shared/operators/map-object-key.operator';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { Commands } from '@shared/enums/commands.enum';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { AuthWrapper } from '@shared/services/auth-wrapper.service';
import { AuditLogService } from '@shared/services/audit-log.service';
import { IdentityData } from '@shared/models/prefs.model';
import { parseDefaultAdminSettings } from '@shared/utilities/team.utilities';
import { shareRef } from '@shared/operators/share-ref.operator';

@Injectable({
  providedIn: 'root',
})
export class AccountManager {
  constructor(
    private db: DatabaseWrapper,
    private cf: CloudFunctions,
    private auth: AuthWrapper,
    private al: AuditLogService,
  ) {}

  listUserInvites(): Observable<InviteData[]> {
    return this.auth.user.pipe(
      map((user) => user?.email),
      distinctUntilChanged(),
      switchMap((email) =>
        email
          ? this.db
              .list<InviteData[]>(`/invites`, (ref) => ref.orderByChild('email').equalTo(email))
              .snapshotChanges()
              .pipe(
                retry(),
                catchError(() => of([])),
                mapListKeys(),
              )
          : of([]),
      ),
    );
  }

  defaultTeamKey(userKey: string) {
    return this.db
      .list(`/users/${userKey}/teams`, (ref) => ref.orderByValue().equalTo(5).limitToFirst(1))
      .snapshotChanges()
      .pipe(map((teams) => teams?.[0]?.key));
  }

  public loadUser(userKey: string): Observable<UserData> {
    return !userKey
      ? of(null)
      : this.db
          .object<UserData>(`/users/${userKey}/`)
          .snapshotChanges()
          .pipe(
            catchError((error) => of(null)),
            mapObjectKey(),
            shareReplay({ refCount: true, bufferSize: 1 }),
          );
  }

  public loadTeam(teamKey: string): Observable<TeamData | null> {
    return !teamKey
      ? of(null)
      : this.db
          .object<TeamAdminSettings>(`/admin/teams/${teamKey}`)
          .valueChanges()
          .pipe(
            map((settings) => parseDefaultAdminSettings(settings)),
            switchMap((settings) =>
              this.db
                .object<TeamData>(`/teams/${teamKey}`)
                .valueChanges()
                .pipe(
                  tap(() =>
                    settings.auditLogging
                      ? this.al.queueDataLog({ status: 'SUCCESS', access: 'READ', path: `/teams/${teamKey}` })
                      : void 0,
                  ),
                  map((team) => ({ ...team, adminSettings: settings, $key: teamKey } as TeamData)),
                ),
            ),
            catchError((error) => of(null).pipe(filter(() => error?.code === 'PERMISSION_DENIED'))),
            shareRef(1),
          );
  }

  public loadIdentity(userKey: string, teamKey: string): Observable<IdentityData> {
    return !teamKey || !userKey
      ? of(null)
      : this.db
          .object(`/users/${userKey}/identities/${teamKey}`)
          .snapshotChanges()
          .pipe(
            catchError((error) => of(null).pipe(filter(() => error && error.code === 'PERMISSION_DENIED'))),
            mapObjectKey(),
          );
  }

  public updateTeam(teamKey: string, newData: Object) {
    this.db.object(`/teams/${teamKey}`).update(newData);
  }

  public switchTeam(userKey: string, teamKey: string) {
    return !userKey || !teamKey
      ? of(null)
      : forkJoin([
          this.db.object(`/users/${userKey}/identities/${teamKey}/lastLogin`).set(Date.now()),
          this.db.object(`/users/${userKey}/team`).set(teamKey),
        ]);
  }

  public acceptTeamInvite(teamKey: string, inviteKey: string) {
    return this.cf.post(Commands.InviteUser, `${teamKey}/${inviteKey}`);
  }

  public rejectTeamInvite(inviteKey: string) {
    return this.cf.delete(Commands.InviteUser, inviteKey);
  }
}

import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, take } from 'rxjs/operators';

import tinycolor from 'tinycolor2';

import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { SurveyStore } from '@player/shared/services/survey-store.service';
import { ViewState } from '@shared/models/survey.model';
import { Questions } from '@shared/enums/questions.enum';
import { DomSanitizer } from '@angular/platform-browser';
import { isShallowEqual } from '@shared/utilities/object.utilities';

@Component({
  selector: 'basic-button',
  templateUrl: './basic-button.component.html',
  styleUrls: ['./basic-button.component.scss'],
})
export class BasicButton {
  readonly needLoading: Questions[] = [Questions.INPUT_PHONE];
  readonly ghost$ = new BehaviorSubject<boolean>(false);
  readonly waitNext$ = new BehaviorSubject<string>('');

  readonly loading$ = combineLatest([this.ss.activeKey, this.waitNext$]).pipe(
    map(([key, waitNext]) => !!waitNext && waitNext === key),
  );

  @Input() set ghostCta(ghost: boolean) {
    this.ghost$.next(ghost);
  }

  @Input() set waitNext(waitNext: string) {
    this.waitNext$.next(waitNext);
  }

  @Output()
  readonly next = new EventEmitter<MouseEvent>();

  readonly hover = new BehaviorSubject<boolean>(false);

  readonly subStyles = combineLatest([
    this.ss.colors,
    this.ghost$,
    this.hover.pipe(distinctUntilChanged()),
    this.loading$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([{ button, buttonContrast, mood, moodContrast }, ghost, hover, loading]) => {
      let backgroundImage = null;

      if (loading) {
        const fg = tinycolor(ghost ? mood : buttonContrast).setAlpha(0.9);
        const bg = ghost ? mood : button;

        backgroundImage = `repeating-linear-gradient(90deg, ${fg}, ${fg} 5px, ${bg} 6px, ${bg} 20px)`;
      }

      return {
        backgroundColor: !hover && ghost ? mood : button,
        color: !hover && ghost ? moodContrast : buttonContrast,
        borderColor: !hover && ghost ? moodContrast : button,
        backgroundImage,
      };
    }),
    distinctUntilChanged(isShallowEqual),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  readonly styles = this.subStyles.pipe(
    map((styles) => {
      styles = { ...styles };
      delete styles.backgroundImage;
      return styles;
    }),
  );

  readonly backgroundImage = this.subStyles.pipe(
    map(({ backgroundImage }) => (backgroundImage ? this.sanitizer.bypassSecurityTrustStyle(backgroundImage) : null)),
  );

  constructor(readonly ss: SurveyStore, readonly sanitizer: DomSanitizer) {}

  onClick(event: MouseEvent): void {
    combineLatest([this.ss.viewState, this.ss.activeIdx, this.ss.cardQuestions])
      .pipe(
        map(([view, idx, questions]) => (view === ViewState.Questions && questions[idx]) || void 0),
        take(1),
        mergeMap((question) => {
          this.ss.buttonClick.next();

          if (question && this.needLoading.includes(question.type)) {
            return timer(1).pipe(mergeMap(() => this.waitNext$));
          } else {
            return of(0);
          }
        }),
        filter((waitNext) => waitNext !== -1),
        take(1),
      )
      .subscribe((waitNext) => {
        if (!waitNext) {
          this.next.emit(event);
        }
      });
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.hover.next(true);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hover.next(false);
  }
}

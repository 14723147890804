import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { Sidenav } from '@shared/modules/sidenav/components/sidenav/sidenav.component';
import { SidenavTitle } from '@shared/modules/sidenav/directives/sidenav-title.directive';
import { SidenavContent } from '@shared/modules/sidenav/directives/sidenav-content.directive';
import { SidenavMenu } from '@shared/modules/sidenav/directives/sidenav-menu.directive';
import { SidenavButtonPrimary } from '@shared/modules/sidenav/directives/sidenav-button-primary.directive';
import { SidenavButtonSecondary } from '@shared/modules/sidenav/directives/sidenav-button-secondary.directive';

import { InlineDialogModule } from '@shared/components/inline-dialog/inline-dialog.component';
import { SidenavDirective } from '@shared/modules/sidenav/directives/sidenav.directive';
import { SidenavContainer } from '@shared/modules/sidenav/components/sidenav-container/sidenav-container.component';
import { SidenavDiscardDialog } from '@shared/modules/sidenav/directives/sidenav-discard-dialog.directive';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatProgressBarModule,
    NgScrollbarModule,
    InlineDialogModule,
  ],
  declarations: [
    Sidenav,
    SidenavTitle,
    SidenavContent,
    SidenavContainer,
    SidenavMenu,
    SidenavDirective,
    SidenavDiscardDialog,
    SidenavButtonPrimary,
    SidenavButtonSecondary,
  ],
  exports: [
    Sidenav,
    SidenavTitle,
    SidenavContent,
    SidenavMenu,
    SidenavDirective,
    SidenavDiscardDialog,
    SidenavButtonPrimary,
    SidenavButtonSecondary,
  ],
})
export class SidenavModule {}

<div class="z-fx z-fx-between-center mode-selector">
  <div class="zef-tabs-icon">
    <button mat-icon-button [class.active]="chartMode === 'cloud'" (click)="changeChartMode('cloud')">
      <mat-icon>chart_wordcloud</mat-icon>
    </button>

    <button mat-icon-button [class.active]="chartMode === 'bar'" (click)="changeChartMode('bar')">
      <mat-icon>chart_bar_horizontal</mat-icon>
    </button>
  </div>

  <zef-select [value]="langFilter || ''" (change)="filterByLanguage($event)">
    <zef-select-option value="">
      <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
        <mat-icon>world</mat-icon>
        <strong class="zef-text-p2" i18n>All</strong>
      </div>
    </zef-select-option>

    <ng-container *ngFor="let lang of languages">
      <zef-select-option [value]="lang">
        <div *selectOptionLabel class="z-fx z-fx-gap-4 z-fx-start-center">
          <figure class="zef-flag-small zef-lang-{{ lang }}"></figure>
          <strong class="zef-text-p2">
            {{ details?.[wordsIndex]?.['labelsValueGroup']?.[lang] }}
          </strong>
        </div>
      </zef-select-option>
    </ng-container>
  </zef-select>
</div>

<div *ngIf="hasGroups" class="z-fx z-fx-none z-fx-center-center z-values-toggle">
  <mat-slide-toggle [checked]="grouped" (change)="groupWords($event)" i18n>
    <span class="group-by-text">Group by word types</span>
  </mat-slide-toggle>
</div>

<div class="chart">
  <ng-container *ngIf="chartMode === 'cloud'">
    <ng-container *ngIf="!(comparison && comparisonData.length); else cloudComparisonTemplate">
      <ng-container *ngIf="!grouped; else cloudGroupTemplate">
        <div
          class="wordCloud"
          [style.height.px]="availableHeight - 34 - (hasGroups ? 48 : 0) - (isPoweredBy ? 32 : 0)"
          wordCloud
          [data]="words"
          [scale]=""
          [filterInput]="filterInput ? filterInput[wordsIndex] : null"
          [domain]="domain[wordsIndex]"
          [transitionDuration]="transitionDuration"
          [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
          [hideRespondentCounts]="!!langFilter"
          [filtering]="filtering"
          [anonymityLock]="anonymityLock"
          [title]="''"
          [totalAnswers]="responses"
          [crossfilter]="crossfilter"
          [touchDevice]="touchDevice"
          [isSharedReport]="isSharedReport"
        ></div>
      </ng-container>
      <ng-template #cloudGroupTemplate>
        <div class="z-fx z-fx-wrap cloud-group-container">
          <div *ngFor="let group of groups; let i = index" class="z-fx-col z-fx-start-center cloud-group-wrapper">
            <div class="z-fx z-fx-center-center group-label">{{ groupObjs[i].label }}</div>
            <div
              class="wordCloudGroup"
              [style.height.px]="(availableHeight - 34 - (hasGroups ? 48 : 0) - 48 - (isPoweredBy ? 32 : 0)) / 2"
              wordCloud
              [data]="groupObjs[i].distribution"
              [scale]=""
              [filterInput]="filterInput ? filterInput[wordsIndex] : null"
              [domain]="domain[wordsIndex]"
              [transitionDuration]="transitionDuration"
              [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
              [hideRespondentCounts]="!!langFilter"
              [filtering]="filtering"
              [anonymityLock]="anonymityLock"
              [title]="''"
              [totalAnswers]="responses"
              [crossfilter]="crossfilter"
              [touchDevice]="touchDevice"
              [isSharedReport]="isSharedReport"
              [wordCloudSizes]="wordCloudSizes"
            ></div>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #cloudComparisonTemplate>
      <ng-container *ngIf="!grouped; else cloudComparisonGroupTemplate">
        <ng-container *ngFor="let compItem of comparisonData">
          <div
            class="wordCloud"
            [style.height.px]="
              (availableHeight - 34 - (hasGroups ? 48 : 0) - (isPoweredBy ? 32 : 0)) / comparisonData.length
            "
            wordCloud
            [data]="compItem.distributions"
            [scale]=""
            [filterInput]="filterInput ? filterInput[wordsIndex] : null"
            [domain]="domain[wordsIndex]"
            [transitionDuration]="transitionDuration"
            [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
            [hideRespondentCounts]="false"
            [filtering]="filtering"
            [anonymityLock]="anonymityLock"
            [title]="compItem.label"
            [color]="compItem.color"
            [totalAnswers]="compItem.responses"
            [crossfilter]="crossfilter"
            [touchDevice]="touchDevice"
            [isSharedReport]="isSharedReport"
            [wordCloudSizes]="wordCloudSizes"
          ></div>
        </ng-container>
      </ng-container>

      <ng-template #cloudComparisonGroupTemplate>
        <ng-container *ngFor="let group of groups; let i = index">
          <div class="z-fx z-fx-center-center group-label">{{ groupObjs[i].label }}</div>
          <div class="z-fx z-fx-wrap z-fx-gap-8">
            <ng-container *ngFor="let compItem of comparisonData">
              <div
                class="wordCloudGroupComparison"
                [style.height.px]="
                  (availableHeight -
                    34 -
                    (hasGroups ? 48 : 0) -
                    (isPoweredBy ? 32 : 0) -
                    groups.length * (24 + Math.ceil(comparisonData.length / 2) * 16)) /
                  groups.length /
                  Math.ceil(comparisonData.length / 2)
                "
                wordCloud
                [data]="compItem.groups[group]"
                [scale]=""
                [filterInput]="filterInput ? filterInput[wordsIndex] : null"
                [domain]="domain[wordsIndex]"
                [transitionDuration]="transitionDuration"
                [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
                [hideRespondentCounts]="false"
                [filtering]="filtering"
                [anonymityLock]="anonymityLock"
                [title]="compItem.label"
                [color]="compItem.color"
                [totalAnswers]="compItem.responses"
                [crossfilter]="crossfilter"
                [touchDevice]="touchDevice"
                [isSharedReport]="isSharedReport"
                [wordCloudSizes]="wordCloudSizes"
              ></div>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </ng-template>

    <div
      *ngIf="anonymityLock && chartMode === 'cloud'"
      class="anonymity-lock"
      matTooltip="Chart is locked to protect anonymity of the respondent"
      matTooltipClass="zef-tooltip-ink-lighter"
      i18n-matTooltip
      matTooltipPosition="above"
    >
      <mat-icon class="zef-icon-small lock-icon">lock</mat-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="chartMode === 'bar'">
    <ng-container *ngIf="!grouped; else barGroupTemplate">
      <bar-chart-table
        class="z-fx-col barChartH"
        [style.height.px]="availableHeight - 34 - (hasGroups ? 48 : 0) - (isPoweredBy ? 32 : 0)"
        [data]="distributions"
        [dataIndex]="wordsIndex"
        [stats]="stats"
        [scale]="chartSettings.scale"
        [filterInput]="filterInput"
        [domain]="domain[wordsIndex]"
        [transitionDuration]="transitionDuration"
        [showNumbers]="chartSettings.showNumbers"
        [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
        [filtering]="isNotFreezed() && filtering"
        [filterRows]="rows"
        [anonymityLock]="anonymityLock"
        [title]="''"
        [chartType]="!comparison || !isNotFreezed() ? chartTypes.BARCHARTH : chartTypes.MULTIBARCHARTH"
        [totalAnswers]="totalAnswers"
        [selectionExists]="selectionExists"
        [filtersDemo]="filtersDemo"
        [touchDevice]="touchDevice"
        [chartSettings]="forcedChartSettings"
        [crossfilter]="crossfilter"
        (settingsChange)="settingsChange.emit($event)"
      ></bar-chart-table>
    </ng-container>
    <ng-template #barGroupTemplate>
      <div class="z-fx z-fx-wrap z-fx-gap-8">
        <ng-container *ngFor="let group of groups; let i = index">
          <bar-chart-table
            class="z-fx-col barChartHGroup"
            [style.height.px]="(availableHeight - 34 - (hasGroups ? 48 : 0) - (isPoweredBy ? 32 : 0)) / 2 - 16"
            [data]="distributions"
            [dataIndex]="wordsIndex"
            [stats]="stats"
            [scale]="chartSettings.scale"
            [filterInput]="filterInput"
            [domain]="domain[wordsIndex]"
            [transitionDuration]="transitionDuration"
            [showNumbers]="chartSettings.showNumbers"
            [update]="Math.max(update?.valueOf(), sizeUpdate?.valueOf())"
            [hideToolbar]="true"
            [filtering]="isNotFreezed() && filtering"
            [filterRows]="groupObjs[i].rows"
            [anonymityLock]="anonymityLock"
            [title]="groupObjs[i].label"
            [chartType]="!comparison || !isNotFreezed() ? chartTypes.BARCHARTH : chartTypes.MULTIBARCHARTH"
            [totalAnswers]="totalAnswers"
            [selectionExists]="selectionExists"
            [filtersDemo]="filtersDemo"
            [touchDevice]="touchDevice"
            [chartSettings]="forcedChartSettings"
            [crossfilter]="crossfilter"
            (settingsChange)="settingsChange.emit($event)"
          ></bar-chart-table>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>

<div
  *ngIf="
    isPoweredBy &&
    !(langFilter && langFilter !== 'fi' && langFilter !== 'sv' && langFilter !== 'da' && langFilter !== 'no')
  "
  class="z-fx z-fx-end-end powered-by"
>
  <span i18n>Text analysis powered by</span>
  <a class="zef-text-p2-link link" href="https://www.lingsoft.fi/" rel="noopener noreferrer" target="_blank">
    Lingsoft
  </a>
</div>
